import React from 'react';
import { faPlusSquare } from '@fortawesome/pro-regular-svg-icons';
import { GetWebCaseAdminData, GetSingleCase_case, GetWebDrivingSlips_drivingSlips } from '../../../GraphQL';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { CaseStatus } from '@ssg/common/GraphQL';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import Box from '../../../Components/Layout/Box';
import DrivingSlipModal from './DrivingSlipModal';
import DrivingSlipSeries from './DrivingSlipSeries';
import DeleteDrivingSlipModal from './DeleteDrivingSlipModal';
import UserContext from '../../../UserContext';
import classNames from 'classnames';

const GET_ADMIN_DATA = loader('src/GraphQL/Cases/GetWebCaseAdminData.gql');

interface SumOfDrivenKM {
	actualDrivenKM: number;
	starDrivenKM: number;
}

interface Props {
	caseData: GetSingleCase_case;
	caseViewExternal: boolean;
}

const sumReducer = (accumulator: number, currentValue: number) => accumulator + currentValue;

const DrivingSlipBox: React.FC<Props> = ({ caseData, caseViewExternal }): React.ReactElement => {
	const { t } = useTranslation();
	const mobileTrackLocationDataFlag = useFlag(FeatureFlagEnums.MOBILE_TRACK_LOCATION_DATA);

	const { data: adminData } = useQuery<GetWebCaseAdminData>(GET_ADMIN_DATA);
	const userContext = React.useContext(UserContext);

	const [createDrivingSlip, setCreateDrivingSlip] = React.useState(false);
	const [copyDrivingSlip, setCopyDrivingSlip] = React.useState(false);
	const [updateDrivingSlip, setUpdateDrivingSlip] = React.useState(false);
	const [deleteDrivingSlip, setDeleteDrivingSlip] = React.useState(false);
	const [drivingSlipData, setDrivingSlipData] = React.useState<GetWebDrivingSlips_drivingSlips>();

	const drivingSlipSeries = React.useMemo(() => [...caseData.drivingSlipSeries], [caseData.drivingSlipSeries]);

	const canCreate = caseData.status === CaseStatus.OPEN && !caseViewExternal && caseData.damage.category.id !== '612492fe47539486eacded68';

	const sumOfDrivenKm: SumOfDrivenKM = React.useMemo(() => {
		const starDrivenKmArr = drivingSlipSeries.flatMap(d => d.drivingSlips.filter(ds => !ds.passenger).map(d => d.starDrivenKM ?? 0));
		const actualDrivenKmArr = drivingSlipSeries.flatMap(d => d.drivingSlips.filter(ds => !ds.passenger).map(d => d.actualDrivenKM ?? 0));

		return {
			actualDrivenKM: actualDrivenKmArr.reduce(sumReducer, 0),
			starDrivenKM: starDrivenKmArr.reduce(sumReducer, 0),
		};
	}, [drivingSlipSeries]);

	return (
		<>
			<Box title="drivingSlips.overviewTitle" icon={canCreate ? faPlusSquare : undefined} full onClick={() => setCreateDrivingSlip(true)} className="mb-8">
				{!caseViewExternal && mobileTrackLocationDataFlag && !userContext.user?.external &&
					<div className="text-sm text-blue">
						<p className={classNames({ 'font-bold': !caseData.debitor.actualKilometers })}><span>{t('drivingSlips.starDrivenKMtotal')}</span>{sumOfDrivenKm.starDrivenKM > 0 ? `${sumOfDrivenKm.starDrivenKM} km` : '-'}</p>
						<p className={classNames({ 'font-bold': caseData.debitor.actualKilometers })}><span>{t('drivingSlips.actualDrivenKMtotal')}</span>{sumOfDrivenKm.actualDrivenKM > 0 ? `${sumOfDrivenKm.actualDrivenKM} km` : '-'}</p>
					</div>
				}
				<div className="mt-4 mb-4 h-96 space-y-4 overflow-auto pr-2">
					{drivingSlipSeries.length > 0 ? (
						drivingSlipSeries
							.sort((a, b) => {
								// const aUrgent = a.drivingSlips.some(ds => ds.urgent);
								// const bUrgent = b.drivingSlips.some(ds => ds.urgent);
								// if (aUrgent !== bUrgent) {
								//     return Number(bUrgent) - Number(aUrgent);
								// }

								const aMaxTime = Math.max(...a.drivingSlips.map(ds => new Date(ds.createdAt).getTime()));
								const bMaxTime = Math.max(...b.drivingSlips.map(ds => new Date(ds.createdAt).getTime()));

								return bMaxTime - aMaxTime;
							})
							.map(dss => {
								// if (dss.drivingSlips.length > 1) {
								if (dss.drivingSlips.length === 0) return <React.Fragment key={dss.id}></React.Fragment>;

								return (
									<DrivingSlipSeries
										key={dss.id}
										drivingSlips={dss.drivingSlips}
										caseData={caseData}
										setCopy={() => setCopyDrivingSlip(true)}
										setEdit={() => setUpdateDrivingSlip(true)}
										setDelete={() => setDeleteDrivingSlip(true)}
										setData={setDrivingSlipData}
										categories={adminData?.drivingSlipCategories ?? []}
									/>
								);
							})
					) : (
						<div className="text-blue text-center font-medium">{t('drivingSlips.none')}</div>
					)}
				</div>
			</Box>

			{createDrivingSlip && (
				<DrivingSlipModal
					caseData={caseData}
					open={createDrivingSlip}
					close={() => setCreateDrivingSlip(false)}
					locationsData={adminData?.locations ?? []}
					departmentsData={adminData?.departments ?? []}
					categories={adminData?.drivingSlipCategories ?? []}
				/>
			)}

			{copyDrivingSlip && (
				<DrivingSlipModal
					copy
					caseData={caseData}
					data={drivingSlipData}
					open={copyDrivingSlip}
					close={() => setCopyDrivingSlip(false)}
					locationsData={adminData?.locations ?? []}
					departmentsData={adminData?.departments ?? []}
					categories={adminData?.drivingSlipCategories ?? []}
				/>
			)}

			{updateDrivingSlip && (
				<DrivingSlipModal
					edit
					caseData={caseData}
					data={drivingSlipData}
					open={updateDrivingSlip}
					close={() => setUpdateDrivingSlip(false)}
					locationsData={adminData?.locations ?? []}
					departmentsData={adminData?.departments ?? []}
					categories={adminData?.drivingSlipCategories ?? []}
				/>
			)}

			{deleteDrivingSlip && typeof drivingSlipData !== 'undefined' && (
				<DeleteDrivingSlipModal caseId={caseData.id} drivingSlip={drivingSlipData} visible={deleteDrivingSlip} close={() => setDeleteDrivingSlip(false)} />
			)}
		</>
	);
};

export default DrivingSlipBox;
