import React from 'react';
import { useTranslation } from 'react-i18next';
import { fileToInput } from '@ssg/common/Helpers/fileTypeHelper';
import { toggleArrayValue } from '@ssg/common/Helpers/toggleArrayValue';
import { useHistory, useParams } from 'react-router-dom';
import { useStorageState } from '@ssg/common/Hooks/useLocalStorage';
import { useCaseNo } from './useCaseNo';
import { ESDHFileFragment } from '@ssg/common/GraphQL';
import { removeTypenameGeneric } from '@ssg/common/Helpers/typenameHelper';
import { faCheck, faFilePdf, faFileWord, faPlus, faSync, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { checkFilesExist, tryGetFile } from './checkFilesExist';
import AirMeassurementDetailsForm from './Inputs/AirMeassurementDetailsForm';
import TextAreaInput from './Inputs/TextAreaInput';
import ReportStaticInfo from './ReportStaticInfo';
import Button from '@ssg/common/Components/Button';
import Header from '@ssg/common/Components/Header';
import CaseImageInput from './Inputs/CaseImageInput';
import Box from '../../../Components/Layout/Box';
import Loading from '@ssg/common/Components/Loading';
import FormErrorText from '@ssg/common/Components/FormErrorText';
import TrygMoistureMeassurementForm from './Inputs/TrygMoistureMeassurementForm';
import TextButton from '@ssg/common/Components/TextButton';
import TextInput from './Inputs/TextInput';
import useCaseImages from './useCaseImages';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import Datepicker from '@ssg/common/Components/Datepicker';
import { AirMeassurementDetailsInput, EsdhFileFragmentFragment, FloorMoistureTrygReportFormDataInput, ReportFileInput, ReportFormatType, useCreateWebMoistureTrygReportMutation, useGetCaseReportsAndDocumentsQuery } from '@ssg/common/GraphQL/indexV2';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';

interface Params {
	id: string;
}

interface ReportError {
	key: keyof FloorMoistureTrygReportFormDataInput;
	index: number;
}

const EMPTY_AIR_MEASSURMENT = (): AirMeassurementDetailsInput => ({
	temperatureInside: 0,
	temperatureOutside: 0,
	relativeHumidityInside: 0,
	relativeHumidityOutside: 0,
	waterAmountInside: 0,
	waterAmountOutside: 0,
});
const tempImage: ReportFileInput = {
	fileName: '',
	description: '',
};

const EMPTY_ROW = (damageCause = '', multipleDamageCauses = '', moistureDamageCause = ''): FloorMoistureTrygReportFormDataInput => ({
	name: '',
	floorplanImage: tempImage,
	damageCause,
	damageDescription: '',
	damageDescriptionImage: [],
	damageDrawingImage: tempImage,
	moistureDamageCause,
	moistureMeassurements: [],
	multipleDamageCauses,
	otherImages: [],
	recommendations: undefined,
	urgentActions: undefined,
	airMeassurements: EMPTY_AIR_MEASSURMENT(),
});

const TrygWaterDamageReport: React.FC = () => {
	const { id: caseId } = useParams<Params>();
	const caseNo = useCaseNo(caseId);
	const { t } = useTranslation();
	const history = useHistory();
	const saveButtonReportsFlag = useFlag(FeatureFlagEnums.SAVE_BUTTON_REPORTS);
	const optionalRoomFloorInReports = useFlag(FeatureFlagEnums.OPTIONAL_ROOM_FLOOR_IN_REPORTS);

	const { images } = useCaseImages(caseNo ?? '');

	const [loadetKey, setLoadetKey] = React.useState<'loadet' | 'new'>('new');

	const { loading: caseReportsAndDocumentsLoading } = useGetCaseReportsAndDocumentsQuery({
		variables: {
			id: caseId,
		},
		skip: !caseId || caseId === '',
		onCompleted(data) {
			const formData = removeTypenameGeneric(data.case.caseReports?.moistureTrygReportFormData);
			if (formData) {
				if (formData.facadeImage) {
					const sanitizedFacadeImage = checkFilesExist([formData.facadeImage], data.case.documents)[0];
					if (sanitizedFacadeImage) {
						setFacadeImage(tryGetFile(sanitizedFacadeImage.fileName, data.case.documents));
					}
				}
				if (formData.inspectionDate) setInspectionDate(formatDateForInput(new Date(formData.inspectionDate)));

				if (formData.floorReport) {
					const floorsData = removeTypenameGeneric(formData.floorReport);
					if (floorsData) setFloors(floorsData);
				}

				if (formData.floorReport) {
					const floorsData = removeTypenameGeneric(formData.floorReport);
					const sanitizedFloorsData = floorsData.map(f => {
						return {
							...f,
							damageDescriptionImage: checkFilesExist(f.damageDescriptionImage ?? [], data.case.documents),
							damageDrawingImage: checkFilesExist(f.damageDrawingImage ? [f.damageDrawingImage] : [], data.case.documents)[0],
							otherImages: checkFilesExist(f.otherImages ?? [], data.case.documents),
							floorplanImage: checkFilesExist(f.floorplanImage ? [f.floorplanImage] : [], data.case.documents)[0],
						};
					});
					if (sanitizedFloorsData) setFloors(sanitizedFloorsData);
				}
			}
			setLoadetKey('loadet');
		},
	});

	const useLocalStorage = <T,>(name: string, initialValue: T) => useStorageState<T>(window.localStorage, `${caseId}-trygwater-${name}`, initialValue);

	const [floors, setFloors] = useLocalStorage<FloorMoistureTrygReportFormDataInput[]>('floors', [EMPTY_ROW()]);

	const [facadeImage, setFacadeImage] = useLocalStorage<EsdhFileFragmentFragment | null>('facadeImage', null);
	const [inspectionDate, setInspectionDate] = useLocalStorage('inspectionDate-indoorClimate', formatDateForInput(new Date()));

	const [activeTab, setActiveTab] = React.useState<number>(0);

	const [submitted, setSubmitted] = React.useState(false);
	const [saved, setSaved] = React.useState(false);
	const [loadingType, setLoadingType] = React.useState<'CREATE' | 'SAVE'>('CREATE');
	const [reportErrors, setReportErrors] = React.useState<ReportError[]>([]);
	const [facadeImageError, setFacadeImageError] = React.useState(false);
	const reportFormat = React.useRef<ReportFormatType>(ReportFormatType.Pdf);
	
	const [showChooseFloorModal, setShowChooseFloorModal] = React.useState(false);
	const [selectedFloors, setSelectedFloors] = React.useState<string[]>([]);

	// Setup mutation to create water damage report
	const [createMoistureTrygReport, { loading: submitting }] = useCreateWebMoistureTrygReportMutation();

	const getDescription = (desciptionMap: ReportFileInput[], fileName: string) => {
		return desciptionMap.find(dm => dm.fileName === fileName);
	};

	async function submitReport(reportFormatType: ReportFormatType) {
		reportFormat.current = reportFormatType;

		setSubmitted(true);
		if (facadeImage == null) {
			setFacadeImageError(true);
		}

		const errors = errorCheck(floors);
		setReportErrors(errors);

		if (errors.length > 0 || facadeImage == null) {
			return;
		}

		const descriptionMap: ReportFileInput[] = images.map(image => ({
			fileName: image.name,
			description: image.comments,
		}));
		const thisFacadeImage = images.find(image => image.name === facadeImage.name) ?? facadeImage;

		const theseFloors = floors.slice();
		theseFloors.forEach((floor, i) => {
			theseFloors[i].floorplanImage = getDescription(descriptionMap, theseFloors[i].floorplanImage!.fileName) ?? theseFloors[i].floorplanImage;
			theseFloors[i].damageDescriptionImage = floor.damageDescriptionImage?.map(oi => getDescription(descriptionMap, oi.fileName) ?? oi);
			theseFloors[i].damageDrawingImage = getDescription(descriptionMap, theseFloors[i].damageDrawingImage!.fileName) ?? theseFloors[i].damageDrawingImage;
			theseFloors[i].otherImages = floor.otherImages?.map(oi => getDescription(descriptionMap, oi.fileName) ?? oi);
		});

		try {
			setLoadingType('CREATE');
			await createMoistureTrygReport({
				variables: {
					caseId,
					formData: {
						inspectionDate: formatDateForInput(new Date(inspectionDate)),
						facadeImage: fileToInput(thisFacadeImage),
						floorMoistureTrygReportData: theseFloors,
					},
					reportFormat: reportFormat.current,
					floorsToInclude: selectedFloors.length > 0 ? selectedFloors : null,
					onlySave: false,
				},
			});

			history.goBack();
		} catch (e) {
			console.error(e);
		}
	}

	React.useEffect(() => {
		setSaved(false);
	}, [inspectionDate, facadeImage, floors]);

	async function saveReport() {
		const descriptionMap: ReportFileInput[] = images.map(image => ({
			fileName: image.name,
			description: image.comments,
		}));
		const thisFacadeImage = images.find(image => image.name === facadeImage?.name) ?? facadeImage;

		const theseFloors = floors.slice();
		theseFloors.forEach((floor, i) => {
			theseFloors[i].floorplanImage = getDescription(descriptionMap, theseFloors[i].floorplanImage?.fileName ?? '') ?? theseFloors[i].floorplanImage;
			theseFloors[i].damageDescriptionImage = floor.damageDescriptionImage?.map(oi => getDescription(descriptionMap, oi.fileName) ?? oi);
			theseFloors[i].damageDrawingImage = getDescription(descriptionMap, theseFloors[i].damageDrawingImage?.fileName ?? '') ?? theseFloors[i].damageDrawingImage;
			theseFloors[i].otherImages = floor.otherImages?.map(oi => getDescription(descriptionMap, oi.fileName) ?? oi);
		});

		try {
			setLoadingType('SAVE');
			await createMoistureTrygReport({
				variables: {
					caseId,
					formData: {
						inspectionDate: formatDateForInput(new Date(inspectionDate)),
						facadeImage: thisFacadeImage ? fileToInput(thisFacadeImage) : null,
						floorMoistureTrygReportData: theseFloors,
					},
					reportFormat: reportFormat.current,
					onlySave: true,
				},
			});
			setSaved(true);
		} catch (e) {
			console.error(e);
		}
	}

	const handleChange = (idx: number, column: keyof FloorMoistureTrygReportFormDataInput, value: any) =>
		setFloors(current => {
			let originalValue = value;
			if (column === 'floorplanImage') {
				originalValue = fileToInput(value as ESDHFileFragment);
			}

			if (column === 'damageDescriptionImage') {
				const fileValue = value as ESDHFileFragment;
				const imageArrToggles = toggleArrayValue(current[idx].damageDescriptionImage || [], { fileName: fileValue.name }, img => img.fileName);

				originalValue = imageArrToggles;
			}

			if (column === 'damageDrawingImage') {
				originalValue = fileToInput(value as ESDHFileFragment);
			}

			if (column === 'otherImages') {
				const fileValue = value as ESDHFileFragment;
				const imageArrToggles = toggleArrayValue(current[idx].otherImages || [], { fileName: fileValue.name }, img => img.fileName);

				originalValue = imageArrToggles;
			}

			return [
				...current.slice(0, idx),
				{
					...current[idx],
					[column]: originalValue,
				},
				...current.slice(idx + 1),
			];
		});

	function addRow() {
		setFloors(current => [...current, EMPTY_ROW(current[0].damageCause ?? '', current[0].multipleDamageCauses ?? '', current[0].moistureDamageCause ?? '')]);
		setActiveTab(floors.length);
	}

	function removeRow(idx: number) {
		setFloors(floors.filter((_, i) => i !== idx));
		setActiveTab(0);
	}

	React.useEffect(() => {
		if (submitted) {
			setFacadeImageError(facadeImage == null);
			const errors = errorCheck(floors);
			setReportErrors(errors);
		}
	}, [facadeImage, floors, submitted]);

	const errorCheck = (flrs: FloorMoistureTrygReportFormDataInput[]) => {
		const errors: ReportError[] = [];
		flrs.forEach((f, i) => {
			if (!f.damageCause || f.damageCause.length < 1) {
				errors.push({ index: i, key: 'damageCause' });
			}
			if (!f.moistureDamageCause || f.moistureDamageCause.length < 1) {
				errors.push({ index: i, key: 'moistureDamageCause' });
			}
			if (!f.damageDescription || f.damageDescription.length < 1) {
				errors.push({ index: i, key: 'damageDescription' });
			}
			if (!f.floorplanImage || f.floorplanImage?.fileName.length < 1) {
				errors.push({ index: i, key: 'floorplanImage' });
			}
			if (f.airMeassurements.relativeHumidityInside === 0 && f.airMeassurements.temperatureInside === 0) {
				errors.push({ index: i, key: 'airMeassurements' });
			}
			if (!f.damageDescriptionImage || f.damageDescriptionImage.length < 1) {
				errors.push({ index: i, key: 'damageDescriptionImage' });
			}
			if (!f.damageDrawingImage || f.damageDrawingImage?.fileName.length < 1) {
				errors.push({ index: i, key: 'damageDrawingImage' });
			}
		});

		return errors;
	};

	const resetForm = () => {
		setFacadeImage(null);
		setFloors([EMPTY_ROW()]);
	};

	const throwErrorMessage = (index: number, key: keyof FloorMoistureTrygReportFormDataInput): string | undefined => {
		const isError = reportErrors.filter(re => re.index === index && re.key === key).length > 0;
		if (isError) return 'error.required';
		return;
	};

	React.useEffect(() => {
		setSelectedFloors(floors.map(f => f.name).filter((name): name is string => name !== undefined));
	}, [floors]);

	if (caseReportsAndDocumentsLoading) return <Loading />;

	return (
		<div>
			<Header title="reports.reportTypes.trygWater" backButton />

			<div className="space-y-8 px-10 pb-10 text-sm">
				<ReportStaticInfo caseId={caseId} />

				<Box full icon={faSync} iconText="reports.reset" title="" onClick={() => resetForm()}>
					{caseNo != null && (
						<>
							<CaseImageInput
								caseNo={caseNo}
								headerTitle="reports.facadeImage"
								title={t('reports.uploadFacadeImage')}
								noSelectedImageText={t('reports.noFacadeImage')}
								//selectedImages={[facadeImage].filter(notNull)}
								selectedImagesNames={facadeImage !== null ? [facadeImage.name] : []}
								onImageSelect={img => setFacadeImage(img)}
							/>
							{facadeImageError && <FormErrorText text="reports.error.facadeImage" />}
						</>
					)}

					<Datepicker title="reports.inspectionDate" value={inspectionDate} onChange={e => setInspectionDate(e.target.value)} name="reports.inspectionDate" />
					<div className="flex flex-wrap">
						<div className="w-full">
							<ul className="mb-0 flex list-none flex-row flex-wrap space-x-2 pt-3 pb-4" role="tablist">
								{floors.map((floor, idx) => (
									<li key={idx}>
										<button
											className={'px-5 py-3 text-xs font-bold uppercase leading-normal shadow-lg ' + (activeTab === idx ? 'bg-blue-calm text-white' : 'text-blue-calm bg-white')}
											style={{ minWidth: '20rem' }}
											onClick={e => {
												e.preventDefault();
												setActiveTab(idx);
											}}
											type="button"
										>
											{typeof reportErrors.find(re => re.index === idx) !== 'undefined' && <FontAwesomeIcon icon={faExclamationTriangle} className="text-red mr-1" size="lg" />}
											{t('reports.floor')} - {floor.name}
										</button>
										{idx > 0 && (
											<TextButton
												text="reports.removeFloor"
												className="mx-auto"
												icon={faTimes}
												iconClassName="text-red"
												onClick={() => {
													if (window.confirm(t('reports.confirmRemoveFloor'))) {
														removeRow(idx);
													}
												}}
											/>
										)}
									</li>
								))}

								<li className="-mb-px mr-2 flex-auto text-center last:mr-0">
									<Button primary icon={faPlus} onClick={addRow}>
										{t('reports.addFloor')}
									</Button>
								</li>
							</ul>
							<div className="relative mb-6 flex w-full min-w-0 flex-col break-words rounded bg-white shadow-lg">
								<div className="flex-auto px-4 py-5">
									<div className="tab-content tab-space">
										{floors.map((floor, idx) => (
											<div key={'#link' + idx} className={activeTab === idx ? 'block' : 'hidden'} id={'#link' + idx}>
												<TextInput label="common.name" value={floor.name} onChange={value => handleChange(idx, 'name', value)} errorMessage={throwErrorMessage(idx, 'name')} />
												<TextAreaInput
													label={t('reports.damageCause')}
													placeholder={t('reports.damageCausePlaceholder')}
													value={floor.damageCause ?? undefined}
													onChange={value => handleChange(idx, 'damageCause', value)}
													errorMessage={throwErrorMessage(idx, 'damageCause')}
													required
												/>

												<TextAreaInput
													label={t('reports.multipleDamageCauses')}
													placeholder={t('reports.multipleDamageCausesPlaceholder')}
													value={floor.multipleDamageCauses ?? undefined}
													onChange={value => handleChange(idx, 'multipleDamageCauses', value)}
												/>

												<TextAreaInput
													label={t('reports.moistureDamageCause')}
													placeholder={t('reports.moistureDamageCausePlaceholder')}
													value={floor.moistureDamageCause ?? undefined}
													onChange={value => handleChange(idx, 'moistureDamageCause', value)}
													required
													errorMessage={throwErrorMessage(idx, 'moistureDamageCause')}
												/>

												<TextAreaInput
													label={t('reports.damageDescription')}
													placeholder={t('reports.damageDescriptionPlaceholder')}
													value={floor.damageDescription ?? undefined}
													onChange={value => handleChange(idx, 'damageDescription', value)}
													required
													errorMessage={throwErrorMessage(idx, 'damageDescription')}
												/>

												{caseNo != null && (
													<>
														<CaseImageInput
															caseNo={caseNo}
															headerTitle="reports.floorPlanImage"
															title="reports.uploadFloorplanImage"
															noSelectedImageText="reports.noFloorplanImage"
															onImageSelect={e => {
																handleChange(idx, 'floorplanImage', e);
															}}
															//selectedImages={images.filter(img => img.name === floor.floorplanImage.fileName)}
															selectedImagesNames={[floor.floorplanImage?.fileName ?? '']}
														/>
														{typeof throwErrorMessage(idx, 'floorplanImage') !== 'undefined' && <FormErrorText text="reports.error.floorplanImage" />}
													</>
												)}

												{/* <MoistureMeassurementForm meassurements={moistureMeassurements} onChange={setMoistureMeassurements} /> */}

												<TrygMoistureMeassurementForm meassurements={floor.moistureMeassurements ?? []} onChange={e => handleChange(idx, 'moistureMeassurements', e)} />

												<AirMeassurementDetailsForm key={loadetKey} meassurements={floor.airMeassurements} onChange={e => handleChange(idx, 'airMeassurements', e)} />
												{typeof throwErrorMessage(idx, 'airMeassurements') !== 'undefined' && <FormErrorText text="reports.error.airMeassurements" />}

												{caseNo != null && (
													<>
														<CaseImageInput
															caseNo={caseNo}
															headerTitle="reports.damageDescriptionImage"
															title="reports.uploadDamageDescriptionImage"
															noSelectedImageText="reports.noDamageDescriptionImage"
															//selectedImages={images.filter(img => floor.damageDescriptionImage.map(oi => oi.fileName).includes(img.name))}
															selectedImagesNames={(floor.damageDescriptionImage ?? []).map(oi => oi.fileName)}
															onImageSelect={image => handleChange(idx, 'damageDescriptionImage', image)}
														/>
														{typeof throwErrorMessage(idx, 'damageDescriptionImage') !== 'undefined' && <FormErrorText text="reports.error.damageDescriptionImage" />}
													</>
												)}

												{caseNo != null && (
													<>
														<CaseImageInput
															caseNo={caseNo}
															headerTitle="reports.damageDrawingImage"
															title="reports.uploadDamageDrawingImage"
															noSelectedImageText="reports.noDamageDrawingImage"
															selectedImagesNames={[floor.damageDrawingImage?.fileName ?? '']}
															onImageSelect={e => handleChange(idx, 'damageDrawingImage', e)}
														/>
														{typeof throwErrorMessage(idx, 'damageDrawingImage') !== 'undefined' && <FormErrorText text="reports.error.damageDrawingImage" />}
													</>
												)}

												<TextAreaInput
													label={t('reports.recommendations')}
													placeholder={t('reports.recommendationsPlaceholder')}
													value={floor.recommendations ?? undefined}
													onChange={value => handleChange(idx, 'recommendations', value)}
												/>

												<TextAreaInput
													label={t('reports.urgentActions')}
													placeholder={t('reports.urgentActionsPlaceholder')}
													value={floor.urgentActions ?? undefined}
													onChange={value => handleChange(idx, 'urgentActions', value)}
												/>

												{caseNo != null && (
													<CaseImageInput
														caseNo={caseNo}
														headerTitle="reports.extraImages"
														title="reports.uploadExtraImages"
														noSelectedImageText="reports.noExtraImages"
														onImageSelect={image => handleChange(idx, 'otherImages', image)}
														//selectedImages={images.filter(img => (floor.otherImages || []).map(oi => oi.fileName).includes(img.name))}
														selectedImagesNames={(floor.otherImages ?? []).map(oi => oi.fileName)}
													/>
												)}
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</Box>

				<div className="ml-4 my-2 mr-6 flex flex-row space-x-2">
					<div className="my-2 flex flex-row space-x-2">
						{optionalRoomFloorInReports
							? <Button success text="Generer rapport" onClick={() => setShowChooseFloorModal(true)} />
							: (
								<>
									<Button
										icon={faFilePdf}
										onClick={() => submitReport(ReportFormatType.Pdf)}
										disabled={submitting}
										loading={submitting && reportFormat.current === ReportFormatType.Pdf && loadingType === 'CREATE'}
										success
										text="reports.createPdfReport"
									/>

									<Button
										icon={faFileWord}
										onClick={() => submitReport(ReportFormatType.Word)}
										disabled={submitting}
										loading={submitting && reportFormat.current === ReportFormatType.Word && loadingType === 'CREATE'}
										success
										text="reports.createWordReport"
									/>
								</>
							)
						}

						{saveButtonReportsFlag &&
							<Button disabled={submitting} loading={submitting && loadingType === 'SAVE'} primary onClick={() => saveReport()} text={saved ? '' : 'reports.saveReport'} icon={saved ? faCheck : undefined} />
						}
					</div>
				</div>
			</div>

			<Modal
				title={t('case.chooseRoomFloor')}
				size={ModalSize.MEDIUM}
				visible={showChooseFloorModal}
				close={() => setShowChooseFloorModal(false)}
				body={
					<div>
						<div className="space-y-2 mb-4">
							{floors.map((floor, idx) => (
								<label key={idx} className="flex items-center space-x-2">
									<input
										type="checkbox"
										checked={selectedFloors.includes(floor?.name ?? '')}
										onChange={e => {
											if (e.target.checked) {
												setSelectedFloors(prev => [...prev, floor?.name ?? '']);
											} else {
												setSelectedFloors(prev => prev.filter(floorName => floorName !== floor?.name));
											}
										}}
									/>
									<span>
										{floor.name}
									</span>
								</label>
							))}
						</div>

						<div className="flex flex-row space-x-2">
							<Button
								icon={faFilePdf}
								onClick={() => {
									submitReport(ReportFormatType.Pdf);
									setSelectedFloors([]);
									setShowChooseFloorModal(false);
								}}
								disabled={submitting}
								loading={submitting && reportFormat.current === ReportFormatType.Pdf && loadingType === 'CREATE'}
								success
								text="reports.createPdfReport"
							/>

							<Button
								icon={faFileWord}
								onClick={() => {
									submitReport(ReportFormatType.Word);
									setSelectedFloors([]);
									setShowChooseFloorModal(false);
									
								}}
								disabled={submitting}
								loading={submitting && reportFormat.current === ReportFormatType.Word && loadingType === 'CREATE'}
								success
								text="reports.createWordReport"
							/>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default TrygWaterDamageReport;
