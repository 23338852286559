import React from 'react';
import { tokenizeStringWithQuotesBySpaces } from '../../helper';
import { useTranslation } from 'react-i18next';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import { formatDate } from '@ssg/common/Helpers/Helpers';
import { useGetTimeTrackingDaysQueryData } from '@ssg/common/TimeRegistration/TimeRegHelpers';
import { BaseTimeTrackingEntryFragment, SupplementType, TimeTrackingDayStatus, TimeTrackingEntry } from '@ssg/common/GraphQL/indexV2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faMedkit, faUmbrellaBeach, faWalking } from '@fortawesome/pro-regular-svg-icons';
import { faBell, faBellPlus, faUtensils } from '@fortawesome/pro-solid-svg-icons';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import Table from '@ssg/common/Components/Table';
import SummaryCard from '@ssg/common/Components/SummaryCard';
import BoxContainer from '@ssg/common/Components/BoxContainer';
import Box from '../../Components/Layout/Box';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import Fuse from 'fuse.js';
import UserContext from '../../UserContext';
import AllRegsOverviewFilterNew from './AllRegsOverviewFilterNew';
import { countOnCallHours } from '@ssg/common/TimeRegistration/onCallTimeHelpers';

export interface TrackingFilters {
	minDate: string;
	maxDate: string;
	view: 'OWN' | 'ALL';
	user: string | undefined;
}

const AllRegistrationsOverviewNew: React.FC = () => {
	const { t } = useTranslation();
	const userContext = React.useContext(UserContext);

	const showOnCallHours = useFlag(FeatureFlagEnums.SHOW_ONCALL_HOURS);
	const onCallSummarize = useFlag(FeatureFlagEnums.ON_CALL_SUMMARIZE);

	const [searchTerm, setSearchTerm] = useDebouncedState('', 100);

	const lastMonth = new Date();
	lastMonth.setDate(lastMonth.getDate() - 30);

	const [activeFilters, setActiveFilters] = React.useState<TrackingFilters>({
		minDate: formatDateForInput(lastMonth),
		maxDate: formatDateForInput(new Date()),
		view: 'OWN',
		user: undefined,
	});

	// const users = activeFilters.view === 'OWN' ? [userContext.user?.id ?? 'error'] : activeFilters.user;
	const users = activeFilters.user ? activeFilters.user : [userContext.user?.id ?? 'error'];
	
	const { timeTrackingDaysDataRaw, timeTrackingDaysLoading } = useGetTimeTrackingDaysQueryData(activeFilters.minDate, activeFilters.maxDate, users, TimeTrackingDayStatus.Closed);
	const timeTrackingDays = React.useMemo(() => {
		return timeTrackingDaysDataRaw?.timeTrackingDays ?? [];
	}, [timeTrackingDaysDataRaw]);

	const timeTrackingEntries = React.useMemo(() => {
		return timeTrackingDaysDataRaw?.timeTrackingDays ?? [];
	}, [timeTrackingDaysDataRaw?.timeTrackingDays]);

	const fuse = React.useMemo(
		() =>
			new Fuse(timeTrackingEntries.flatMap(e => e.timeTrackingEntries), {
				shouldSort: true,
				threshold: 0.1,
				keys: ['user.name', 'user.email', 'case.erpNo', 'case.debitor.company', 'jobTaskName', 'addonLines.paymentSupplementName', 'addonLines.paymentSupplementCode'],
			}),
		[timeTrackingEntries],
	);

	const filteredTrackings =
		searchTerm.length > 0
			? fuse
					.search({
						$and: tokenizeStringWithQuotesBySpaces(searchTerm).map((searchToken: string) => {
							const orFields: Fuse.Expression[] = [
								{ 'user.name': searchToken },
								{ 'user.email': searchToken },
								{ 'case.erpNo': searchToken },
								{ 'case.debitor.company': searchToken },
								{ 'jobTaskName': searchToken },
								{ 'addonLines.paymentSupplementName': searchToken },
								{ 'addonLines.paymentSupplementCode': searchToken },
								{ jobTaskNo: searchToken },
							];

							return {
								$or: orFields,
							};
						}),
					})
					.sort((a, b) => (a.score ?? Number.MAX_SAFE_INTEGER) - (b.score ?? Number.MAX_SAFE_INTEGER))
					.map(v => v.item)
			: timeTrackingEntries.flatMap(e => e.timeTrackingEntries) as TimeTrackingEntry[];

	// Totale timer Behold
	// '009', // 'Kursus, internt'
	// '010', // 'Kursus, externt'
	// '047', // 'Mentorarbejde'
	// '011', // 'Møde aktivitet.'
	// '012', // 'Juleaftens dag d. 24/ 12'
	// '016', // 'Sikkerhedsarbejde'
	// '031', // 'Administration'
	// '045', // 'Introforløb'
	// '046', // 'Sidemandsoplæring'
	// '049', // 'Kundebesøg'
	// '065', // 'Vagtcentral timer'
	// '9515', // 'Datamigrering'
	// '0038', // 'Tillidsrepræsentant arbejde'
	// '037', // 'Tillidsrepræsentant arbejde'
	// '021', // 'Skole ophold - IT student'

	// Ekskluder
	// '1902', // Akkord
	// '1900', // Akkord (Antal timer)
	// '1901', // Akkord (Beløb Kr.)
	// '018', // Flex
	// '082', // Rådighedsvagt, Sanitør
	// '083', // Rådighedsvagt, Vagtleder
	// '8011', // Kørselstid (supplement)
	const exludeArray = ['1902', '1900', '1901', '018', '082', '083'];

	// TODO: Implement the following when Sanne return
	// '014', // Nytårsaftens dag d. 31/ 12
	// '020', // Grundlovsdag (3,75 Timer)

	//{ jobTaskNo: '015', description: 'Fravær pga. COVID-19' }, // Deaktiver i BC

	// Ferie/feriefri
	// '007', // Feriefridag
	// '008', // Feriedag
	const vacationArray = ['007', '008'];
	
	// Fravær
	// '004', // Fri for egen regning
	// '017', // Hjemsendelse
	// '018', // Flex
	const absenceArray = ['004', '017', '018'];
	
	// Sygdom/Barsel
	// '002', // Syg
	// '003', // Barn syg
	// '005', // Barsel
	// '013', // Fravær pga. arbejdsskade.
	// '019', // Graviditetsbetinget sygdom
	// '022', // Syg - Langtidssydom
	// '0034', // §56 sygdom
	const sickLeaveArray = ['002', '003', '005', '013', '019', '022', '0034'];

	const totalHours = timeTrackingDays
		.flatMap(d => d.timeTrackingEntries)
		.filter(entry => !exludeArray.includes(entry.jobTaskNo))
		.filter(entry => !vacationArray.includes(entry.jobTaskNo))
		.filter(entry => !absenceArray.includes(entry.jobTaskNo))
		.filter(entry => !sickLeaveArray.includes(entry.jobTaskNo))
		.filter(entry => !entry.addonLines.some(line => line.paymentSupplementCode === '8011'))
		.reduce((acc, curr) => acc + curr.hours, 0) ?? 0;

	const timeTrakingDaysEntries = timeTrackingDays.flatMap(e => e.timeTrackingEntries);

	const calculateTotalHours = (taskArray: string[]) => (
		timeTrakingDaysEntries.reduce((acc, curr) => {
			if (taskArray.includes(curr.jobTaskNo)) {
				return acc + curr.hours;
			}
			return acc;
		}, 0)
	);

	const totalVacationHours = calculateTotalHours(vacationArray);
	const totalAbsenceHours = calculateTotalHours(absenceArray);
	const totalSickLeaveHours = calculateTotalHours(sickLeaveArray);

	const destructuredEntries = timeTrakingDaysEntries.map(({ jobNo, jobTaskNo, hours, onCall, startTime, endTime, startDate }) => ({
		jobNo,
		jobTaskNo,
		hours,
		onCall,
		startTime,
		endTime,
		startDate,
	})) ?? [];

	const destructuredAddonLines = timeTrakingDaysEntries.flatMap(e => 
		e.addonLines.map(({ paymentSupplementCode, supplementType, jobNo, jobTaskNo, hours, onCall }) => ({
			paymentSupplementCode,
			supplementType,
			jobNo,
			jobTaskNo,
			hours,
			onCall,
			startTime: e.startTime,
			endTime: e.endTime,
	}))) ?? [];

	const onCallHours = countOnCallHours(destructuredEntries, destructuredAddonLines, '0601', 2.5);
	const onCallbackHours = countOnCallHours(destructuredEntries, destructuredAddonLines, '0600', 3);

	const accumulateSupplements = (
		entries: BaseTimeTrackingEntryFragment[],
		supplementCodesToGroup: string[],
		returnPieceworkAmount?: boolean,
	): number => {
		return entries.reduce((acc: number, curr: BaseTimeTrackingEntryFragment) => {
			const addonLines = curr.addonLines ?? [];
			
			addonLines.forEach((line) => {
				if (line.paymentSupplementCode && supplementCodesToGroup.includes(line.paymentSupplementCode)) {
					acc += returnPieceworkAmount ? (line.pieceworkAmount ?? 0) : line.hours;
				}
			});

			return acc;
		}, 0);
	};

	interface suppGroup {
		title: string;
		value: number;
		unit?: string;
	}

	const overtimeCodes = ['2100', '2101', '2105', '8599'];
	const accOvertimeSupps: suppGroup = {
		title: 'Overtid',
		value: accumulateSupplements(timeTrakingDaysEntries, overtimeCodes),
	};

	const eveningCodes = ['2051', '2052', '2096'];
	const accEveningSupps: suppGroup = {
		title: 'Aften',
		value: onCallSummarize ? (accumulateSupplements(timeTrakingDaysEntries, eveningCodes) + onCallHours.eveningHours + onCallbackHours.eveningHours) : accumulateSupplements(timeTrakingDaysEntries, eveningCodes),
	};

	const nightCodes = ['2054', '2055', '2097'];
	const accNightSupps: suppGroup = {
		title: 'Nat',
		value: onCallSummarize ? (accumulateSupplements(timeTrakingDaysEntries, nightCodes) + onCallHours.nightHours + onCallbackHours.nightHours) : accumulateSupplements(timeTrakingDaysEntries, nightCodes),
	};

	const saturdayCodes = ['2095'];
	const accSaturdaySupps: suppGroup = {
		title: 'Lørdag',
		value: accumulateSupplements(timeTrakingDaysEntries, saturdayCodes),
	};

	const sundayCodes = ['2056', '2057', '2098'];
	const accSundaySupps: suppGroup = {
		title: 'Søndag',
		value: onCallSummarize ? (accumulateSupplements(timeTrakingDaysEntries, sundayCodes) + onCallHours.sundayHours + onCallbackHours.sundayHours) : accumulateSupplements(timeTrakingDaysEntries, sundayCodes),
	};

	const asbestCodes = ['2180'];
	const accAsbestSupps: suppGroup = {
		title: 'Asbest',
		value: accumulateSupplements(timeTrakingDaysEntries, asbestCodes),
	};

	const drivingCodes = ['8010', '8011'];
	const accDrivingSupps: suppGroup = {
		title: 'Kørselstid',
		value: accumulateSupplements(timeTrakingDaysEntries, drivingCodes),
	};

	const ownKmCodes = ['8004'];
	const accOwnKmSupps: suppGroup = {
		title: 'Kørte km i egen vogn',
		value: accumulateSupplements(timeTrakingDaysEntries, ownKmCodes),
		unit: 'km',
	};

	const smudsCodes = ['2195'];
	const accSmudsSupps: suppGroup = {
		title: 'Smuds',
		value: accumulateSupplements(timeTrakingDaysEntries, smudsCodes),
	};

	const geneCodes = ['2197'];
	const accGeneSupps: suppGroup = {
		title: 'Gene',
		value: accumulateSupplements(timeTrakingDaysEntries, geneCodes),
	};

	const skadeserviceCodes = ['2198'];
	const accSkadeserviceSupps: suppGroup = {
		title: 'Skadeservice',
		value: accumulateSupplements(timeTrakingDaysEntries, skadeserviceCodes),
	};

	const specialCodes = ['2190'];
	const accSpecialSupps: suppGroup = {
		title: 'Særligt',
		value: accumulateSupplements(timeTrakingDaysEntries, specialCodes),
	};

	const vagtCodes = ['2094', '2150'];
	const accVagtSupps: suppGroup = {
		title: 'Vagt',
		value: accumulateSupplements(timeTrakingDaysEntries, vagtCodes),
		unit: 'Stk',
	};

	const beregnetCodes = ['2170', '2171'];
	const accBeregnetSupps: suppGroup = {
		title: 'Beregnet',
		value: accumulateSupplements(timeTrakingDaysEntries, beregnetCodes, true),
		unit: 'Kr',
	};

	const cleaningCodes = ['8610'];
	const accCleaningSupps: suppGroup = {
		title: 'Hovedrengøring',
		value: accumulateSupplements(timeTrakingDaysEntries, cleaningCodes),
	};

	const shedCodes = ['8620'];
	const accShedSupps: suppGroup = {
		title: 'Skur',
		value: accumulateSupplements(timeTrakingDaysEntries, shedCodes),
	};

	const varskoCodes = ['8630'];
	const accVarskoSupps: suppGroup = {
		title: 'Varsko',
		value: accumulateSupplements(timeTrakingDaysEntries, varskoCodes),
		unit: 'Stk',
	};

	const onCallCodes = ['8640'];
	const accOnCallSupps: suppGroup = {
		title: 'Tilkald',
		value: accumulateSupplements(timeTrakingDaysEntries, onCallCodes),
		unit: 'Stk',
	};

	const pieceworkHoursCodes = ['8401'];
	const accPieceworkHoursSupps: suppGroup = {
		title: 'Akkord (Timer)',
		value: accumulateSupplements(timeTrakingDaysEntries, pieceworkHoursCodes),
	};

	const pieceworkCodes = ['8402'];
	const accPieceworkSupps: suppGroup = {
		title: 'Akkord (Beløb)',
		value: accumulateSupplements(timeTrakingDaysEntries, pieceworkCodes, true),
		unit: 'Kr',
	};

	const afvarslingCodes = ['8600'];
	const accAfvarslingSupps: suppGroup = {
		title: 'Afvarsling',
		value: accumulateSupplements(timeTrakingDaysEntries, afvarslingCodes),
		unit: 'Stk',
	};

	const accumulatedSupplements = [accOvertimeSupps, accEveningSupps, accNightSupps, accSaturdaySupps, accSundaySupps, accAsbestSupps, accDrivingSupps, accOwnKmSupps, accSmudsSupps, accGeneSupps, accSkadeserviceSupps, accSpecialSupps, accVagtSupps, accBeregnetSupps, accCleaningSupps, accShedSupps, accVarskoSupps, accOnCallSupps, accPieceworkHoursSupps, accPieceworkSupps, accAfvarslingSupps];

	const internRegClasses = 'bg-cyan-calm border-green-dark border-b-1';
	const caseRegClasses = 'bg-blue-lightcalm border-blue-calm border-b-1';

	return (
		<div>
			<BoxContainer>
				<Box full>
					<AllRegsOverviewFilterNew setFilterTerm={setSearchTerm} activeFilters={activeFilters} setActiveFilters={setActiveFilters} />

					<div className='flex space-x-6'>
						<div>
							<h3>{t('timeRegistration.totalHoursInPeriod')}</h3>
							{showOnCallHours
								? (
									<>
										<div className='flex space-x-4 mb-4 mt-1'>
											<SummaryCard loading={timeTrackingDaysLoading} title="common.hours" data={totalHours} icon={faClock} iconSize='2x' />
											<SummaryCard loading={timeTrackingDaysLoading} title="timeRegistration.onCall" data={onCallHours.hours} subTitle='Overtid' subData={onCallHours.overtimeHours} icon={faBell} iconSize='2x' />
											<SummaryCard loading={timeTrackingDaysLoading} title="timeRegistration.onCallback" data={onCallbackHours.hours} subTitle='Overtid' subData={onCallbackHours.overtimeHours} icon={faBellPlus} iconSize='2x' />
										</div>
										<div className='flex space-x-4 mb-4 mt-1'>								
											<SummaryCard loading={timeTrackingDaysLoading} title="common.vacation" data={totalVacationHours} icon={faUmbrellaBeach} iconSize='2x' />
											<SummaryCard loading={timeTrackingDaysLoading} title="common.absence" data={totalAbsenceHours} icon={faWalking} iconSize='2x' />
											<SummaryCard loading={timeTrackingDaysLoading} title="common.sickLeave" data={totalSickLeaveHours} icon={faMedkit} iconSize='2x' />
										</div>
									</>
								)
								: (
									<div className='flex space-x-4 mb-4 mt-1'>
										<SummaryCard loading={timeTrackingDaysLoading} title="common.hours" data={totalHours} icon={faClock} iconSize='2x' />
										<SummaryCard loading={timeTrackingDaysLoading} title="common.vacation" data={totalVacationHours} icon={faUmbrellaBeach} iconSize='2x' />
										<SummaryCard loading={timeTrackingDaysLoading} title="common.absence" data={totalAbsenceHours} icon={faWalking} iconSize='2x' />
										<SummaryCard loading={timeTrackingDaysLoading} title="common.sickLeave" data={totalSickLeaveHours} icon={faMedkit} iconSize='2x' />
									</div>
								)
							}
						</div>

						<div>
							<h3>{t('timeRegistration.totalSuppsInPeriod')}</h3>
							<div className="flex flex-wrap mb-4">
								{accumulatedSupplements.every(supp => supp.value === 0) ? (
									<p className="text-xs">{t('timeRegistration.noSupplementsFound')}</p>
								) : (
									accumulatedSupplements.map(supp => (
										<SummaryCard key={supp.title} small loading={timeTrackingDaysLoading} title={supp.title} data={supp.value} unit={supp.unit} />
									))
								)}
							</div>
						</div>
					</div>

					<Table
						data={filteredTrackings ?? []}
						columns={[
							{
								label: 'common.date',
								numeric: true,
								selectFn: t => <p>{formatDate(new Date(t.startDate))}</p>,
								sortFn: (a, b) => a.startDate.localeCompare(b.startDate),
								dynamicClassNameTh: t => (t && t.jobNo === 'INTERN') ? internRegClasses : caseRegClasses,
							},
							{
								label: 'common.name',
								selectFn: t => <p>{t.user.name ? t.user.name : ''}</p>,
								sortFn: (a, b) => a.user.name.localeCompare(b.user.name),
								dynamicClassNameTh: t => (t && t.jobNo === 'INTERN') ? internRegClasses : caseRegClasses,
							},
							{
								label: 'common.case',
								selectFn: t => (
									<div>
										<div>
											<p>{t.case ? `${t.case.erpNo}` : ''}</p>
											<p>
												{t.case
													? `${t.case.damage.contact.address.road} ${t.case.damage.contact.address.houseNumber}, ${t.case.damage.contact.address.postalCode} ${t.case.damage.contact.address.city}`
													: ''}
											</p>
											<p>{t.case ? `${t.case.damage.category.name}/${t.case.damage.cause.name}` : ''}</p>
										</div>
										<div className="flex flex-row space-x-2 mb-1">
											{t.addonLines.map(supLine => (
												<div key={supLine.id} className="text-xs px-1 border-1 border-black border-opacity-50 rounded-default">
													{`${supLine.paymentSupplementName} (${supLine.paymentSupplementCode}) ${supLine.supplementType === SupplementType.Piecework ? supLine.pieceworkAmount : supLine.hours} ${supLine.supplementUom?.toLowerCase()}`}
												</div>
											))}
										</div>
									</div>
								),
								sortFn: (a, b) => (a.case?.erpNo ?? '').localeCompare(b.case?.erpNo ?? ''),
								dynamicClassNameTh: t => (t && t.jobNo === 'INTERN') ? internRegClasses : caseRegClasses,
							},
							{
								label: 'offer.jobTask',
								selectFn: t => <p>{t.jobTaskName}</p>,
								sortFn: (a, b) => (a.jobTaskName ?? '').localeCompare(b.jobTaskName ?? ''),
								dynamicClassNameTh: t => (t && t.jobNo === 'INTERN') ? internRegClasses : caseRegClasses,
							},
							{
								label: 'common.comment',
								noTruncate: true,
								classNameTh: 'w-1/3',
								selectFn: t => <p>{t.remark}</p>,
								dynamicClassNameTh: t => (t && t.jobNo === 'INTERN') ? internRegClasses : caseRegClasses,
							},
							{
								label: 'common.hours',
								selectFn: t => <p>{t.hours}</p>,
								sortFn: (a, b) => (a.hours.toString() ?? '').localeCompare(b.hours.toString() ?? ''),
								dynamicClassNameTh: t => (t && t.jobNo === 'INTERN') ? internRegClasses : caseRegClasses,
								numeric: true,
							},
							{
								label: 'common.indications',
								selectFn: t => (
									<div className="flex items-baseline">
										{t.includesLunch && <FontAwesomeIcon icon={faUtensils} size="sm" className="mr-2" />}
										{(t.onCall && !t.onCallback) && <FontAwesomeIcon icon={faBell} size="sm" className="mr-2" />}
										{t.onCallback && <FontAwesomeIcon icon={faBellPlus} size="sm" />}
									</div>
								),
								dynamicClassNameTh: t => (t && t.jobNo === 'INTERN') ? internRegClasses : caseRegClasses,
							},	
							{
								label: 'common.debitor',
								selectFn: t => <p>{t.case?.debitor.company}</p>,
								sortFn: (a, b) => (a.case?.debitor.company ?? '').localeCompare(b.case?.debitor.company ?? ''),
								dynamicClassNameTh: t => (t && t.jobNo === 'INTERN') ? internRegClasses : caseRegClasses,
							},
						]}
						keySelector={t => t.id}
						noDataFoundText="timeRegistration.noTimeRegFound"
						loading={timeTrackingDaysLoading}
					/>
				</Box>
			</BoxContainer>
		</div>
	);
};

export default AllRegistrationsOverviewNew;
