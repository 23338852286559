/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetInputFields
// ====================================================

export interface GetInputFields_inputFields {
  /**
   * Key of input field
   */
  key: string;
  /**
   * Input field permissions
   */
  permissions: Permissions[];
}

export interface GetInputFields {
  inputFields: GetInputFields_inputFields[];
}

export interface GetInputFieldsVariables {
  keys: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateIndoorClimateReport
// ====================================================

export interface CreateIndoorClimateReport_createIndoorClimateReport {
  /**
   * File name
   */
  name: string;
}

export interface CreateIndoorClimateReport {
  /**
   * Create indoor climate report
   */
  createIndoorClimateReport: CreateIndoorClimateReport_createIndoorClimateReport | null;
}

export interface CreateIndoorClimateReportVariables {
  caseId: string;
  formData: IndoorClimateReportFormDataInput;
  reportFormat: ReportFormatType;
  onlySave: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCommonCurrentUser
// ====================================================

export interface GetCommonCurrentUser_currentUser_gdpr_userId {
  /**
   * Unique id of user
   */
  id: string;
}

export interface GetCommonCurrentUser_currentUser_gdpr {
  /**
   * Unique id of gdpr accept
   */
  id: string;
  /**
   * Timestamp of acceptance
   */
  timestamp: GQL_DateTime;
  /**
   * User who accepted GDPR
   */
  userId: GetCommonCurrentUser_currentUser_gdpr_userId;
}

export interface GetCommonCurrentUser_currentUser_caseFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
  /**
   * Filter view type
   */
  view: UserCaseFilterViewType;
  /**
   * Case awaiting status toggle
   */
  awaiting: boolean | null;
  /**
   * Case damage service completed status toggle
   */
  damageServiceCompleted: boolean | null;
  /**
   * Case called back status toggle
   */
  calledBack: boolean | null;
  /**
   * Case machines on case status toggle
   */
  machinesOnCase: boolean | null;
  /**
   * Toggle to show economy fields
   */
  showEconomyField: boolean;
  /**
   * Toggle to show business area fields
   */
  showBusinessAreaField: boolean;
  /**
   * Toggle to show applied closed cases
   */
  showAppliedClosedCases: boolean;
  /**
   * Toggle to show closed cases
   */
  showClosedCases: boolean;
  /**
   * Debitors to filter cases by
   */
  debitors: string[];
  /**
   * Postal code input text
   */
  postalCodeText: string;
  /**
   * Postal codes to filter cases by
   */
  postalCodes: string[];
  /**
   * Track to filter cases by
   */
  track: number[];
  /**
   * Departments to filter cases by
   */
  departments: string[];
  /**
   * Locations to filter cases by
   */
  locations: string[];
  /**
   * Damage categories to filter cases by
   */
  damageCategories: string[];
  /**
   * Damage causes to filter cases by
   */
  damageCauses: string[];
  /**
   * Priority to filter cases by
   */
  priority: CasePriority[];
  /**
   * Case managers to filter cases by
   */
  caseManagers: string[];
  /**
   * Project managers to filter cases by
   */
  projectManagers: string[];
}

export interface GetCommonCurrentUser_currentUser_drivingSlipFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
  /**
   * Debitors to filter driving slips by
   */
  debitors: string[];
  /**
   * Postal code input text
   */
  postalCodeText: string;
  /**
   * Postal codes to filter driving slips by
   */
  postalCodes: string[];
  /**
   * Departments to filter driving slips by
   */
  departments: string[];
  /**
   * Locations to filter driving slips by
   */
  locations: string[];
  /**
   * Damage categories to filter driving slips by
   */
  damageCategories: string[];
  /**
   * Damage causes to filter driving slips by
   */
  damageCauses: string[];
  /**
   * ERP reference nos to filter driving slips by
   */
  erpNos: string[];
  /**
   * Project managers to filter driving slips by
   */
  projectManagers: string[];
}

export interface GetCommonCurrentUser_currentUser_drivingSlipOverviewFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
  /**
   * Status to filter by
   */
  status: DrivingSlipStatus | null;
  /**
   * Urgent filter toggle
   */
  urgent: boolean | null;
  /**
   * Exceeded filter toggle
   */
  exceeded: boolean | null;
  /**
   * Departments to filter by
   */
  departments: string[];
  /**
   * Locations to filter by
   */
  locations: string[];
  /**
   * Damage causes to filter by
   */
  damageCauses: string[];
  /**
   * Damage categories to filter by
   */
  damageCategories: string[];
  /**
   * Drivers to filter by
   */
  drivers: string[];
  /**
   * Filter for no drivers
   */
  noDriver: boolean | null;
}

export interface GetCommonCurrentUser_currentUser_sanitizerFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
  /**
   * Departments to filter sanitizers by
   */
  departments: string[];
  /**
   * Locations to filter sanitizers by
   */
  locations: string[];
  /**
   * Job functions to filter sanitizers by
   */
  jobFunctions: string[];
  /**
   * Pre selected sanitizers on filter
   */
  sanitizers: string[];
}

export interface GetCommonCurrentUser_currentUser_carFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
  /**
   * Locations to filter cars by
   */
  locations: string[];
  /**
   * Pre selected cars on filter
   */
  cars: string[];
}

export interface GetCommonCurrentUser_currentUser_movableFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
  /**
   * ERP reference no to filter movables by
   */
  erpNo: string;
  /**
   * Location to filter movables by
   */
  location: string;
  /**
   * Status to filter movables by
   */
  status: string;
  /**
   * Toggle to show completed movables
   */
  includeCompleted: boolean;
  /**
   * Debitors to filter movables by
   */
  debitors: string[] | null;
}

export interface GetCommonCurrentUser_currentUser_jobFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
  /**
   * Location to filter jobs by
   */
  location: string;
  /**
   * Toggle to show only personal jobs
   */
  personalOnly: boolean;
}

export interface GetCommonCurrentUser_currentUser_machineFilters {
  /**
   * Name of filter
   */
  name: string;
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Status to filter by
   */
  status: MachineStatus | null;
  /**
   * Cases to filter by
   */
  cases: string[] | null;
  /**
   * Locations to filter by
   */
  locations: string[] | null;
  /**
   * Types to filter by
   */
  types: string[] | null;
  /**
   * Include unavailable machines
   */
  unavailable: boolean | null;
  /**
   * Include hibernating machines
   */
  hibernation: boolean | null;
}

export interface GetCommonCurrentUser_currentUser {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
  /**
   * Users GDPR
   */
  gdpr: GetCommonCurrentUser_currentUser_gdpr | null;
  /**
   * permissions of user
   */
  permissions: Permissions[];
  /**
   * External user
   */
  external: boolean;
  /**
   * User saved case filters
   */
  caseFilters: GetCommonCurrentUser_currentUser_caseFilters[];
  /**
   * User saved driving slip filters
   */
  drivingSlipFilters: GetCommonCurrentUser_currentUser_drivingSlipFilters[];
  /**
   * User saved driving slip overview filters
   */
  drivingSlipOverviewFilters: GetCommonCurrentUser_currentUser_drivingSlipOverviewFilters[];
  /**
   * User saved sanitizer filters
   */
  sanitizerFilters: GetCommonCurrentUser_currentUser_sanitizerFilters[];
  /**
   * User saved car filters
   */
  carFilters: GetCommonCurrentUser_currentUser_carFilters[];
  /**
   * User saved movable filters
   */
  movableFilters: GetCommonCurrentUser_currentUser_movableFilters[];
  /**
   * User saved job filters
   */
  jobFilters: GetCommonCurrentUser_currentUser_jobFilters[];
  /**
   * User saved machine filters
   */
  machineFilters: GetCommonCurrentUser_currentUser_machineFilters[];
}

export interface GetCommonCurrentUser {
  currentUser: GetCommonCurrentUser_currentUser | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUser
// ====================================================

export interface GetUser_currentUser {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
  /**
   * permissions of user
   */
  permissions: Permissions[];
}

export interface GetUser {
  currentUser: GetUser_currentUser | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetVehicles
// ====================================================

export interface GetVehicles_vehicles_bookedDays_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetVehicles_vehicles_bookedDays {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetVehicles_vehicles_bookedDays_driver | null;
}

export interface GetVehicles_vehicles {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
  /**
   * Days where the vehicle is booked
   */
  bookedDays: GetVehicles_vehicles_bookedDays[];
}

export interface GetVehicles {
  vehicles: GetVehicles_vehicles[];
}

export interface GetVehiclesVariables {
  isCar: boolean;
  dateRange?: DateRangeInput | null;
  locations?: string[] | null;
  departments?: string[] | null;
  type?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CloseCases
// ====================================================

export interface CloseCases_closeCases_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface CloseCases_closeCases_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface CloseCases_closeCases_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: CloseCases_closeCases_debitor_shippingAddress_address;
}

export interface CloseCases_closeCases_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CloseCases_closeCases_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: CloseCases_closeCases_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: CloseCases_closeCases_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: CloseCases_closeCases_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface CloseCases_closeCases_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface CloseCases_closeCases_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface CloseCases_closeCases_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface CloseCases_closeCases_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface CloseCases_closeCases_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface CloseCases_closeCases_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: CloseCases_closeCases_damage_cause_departments_location;
}

export interface CloseCases_closeCases_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: CloseCases_closeCases_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: CloseCases_closeCases_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (CloseCases_closeCases_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface CloseCases_closeCases_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CloseCases_closeCases_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: CloseCases_closeCases_damage_contact_address;
}

export interface CloseCases_closeCases_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface CloseCases_closeCases_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: CloseCases_closeCases_damage_businessArea;
  /**
   * Damage category
   */
  category: CloseCases_closeCases_damage_category;
  /**
   * Cause behind damage
   */
  cause: CloseCases_closeCases_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: CloseCases_closeCases_damage_contact;
  /**
   * contacts for damage
   */
  contacts: CloseCases_closeCases_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface CloseCases_closeCases_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CloseCases_closeCases_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: CloseCases_closeCases_policyHolder_address;
}

export interface CloseCases_closeCases_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface CloseCases_closeCases_visitation_awaiting {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface CloseCases_closeCases_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
  /**
   * The question
   */
  title: string;
}

export interface CloseCases_closeCases_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: CloseCases_closeCases_visitation_calledBack;
  /**
   * If case is awaiting
   */
  awaiting: CloseCases_closeCases_visitation_awaiting;
  /**
   * A set of answers related to the damage cause
   */
  answers: CloseCases_closeCases_visitation_answers[] | null;
  /**
   * If case needs a moisture report
   */
  isMoistureReportRequired: boolean | null;
  /**
   * If case needs an SP phase one report
   */
  isSpPhaseOneReportCreated: boolean | null;
}

export interface CloseCases_closeCases_requisitioner {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Requisitioner relation
   */
  relation: string;
}

export interface CloseCases_closeCases_adviser {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface CloseCases_closeCases_referrer {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface CloseCases_closeCases_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CloseCases_closeCases_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CloseCases_closeCases_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CloseCases_closeCases_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: CloseCases_closeCases_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface CloseCases_closeCases_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CloseCases_closeCases_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: CloseCases_closeCases_ssgDepartment_location_address;
}

export interface CloseCases_closeCases_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: CloseCases_closeCases_ssgDepartment_location;
}

export interface CloseCases_closeCases_caseAgreements_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CloseCases_closeCases_caseAgreements {
  /**
   * Agreement content
   */
  content: string;
  /**
   * Agreement category
   */
  category: CaseAgreementCategory;
  /**
   * User who created agreement
   */
  user: CloseCases_closeCases_caseAgreements_user;
  /**
   * Timestamp of when agreement was created
   */
  timestamp: GQL_DateTime;
  /**
   * Whether case agreement should be sent to ScalePoint
   */
  shouldSaveInScalePoint: boolean;
}

export interface CloseCases_closeCases_caseEmails {
  /**
   * Email content
   */
  content: string;
  /**
   * List of email attachments
   */
  attachments: string[];
  /**
   * List of email receivers
   */
  receivers: string[];
  /**
   * Timestamp of when email was created
   */
  timestamp: GQL_DateTime;
}

export interface CloseCases_closeCases_userFavorite {
  /**
   * Unique user id
   */
  user: string;
  /**
   * Color code set by user
   */
  color: string | null;
  /**
   * Favorite active status
   */
  active: boolean;
}

export interface CloseCases_closeCases_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CloseCases_closeCases_jobs_assignedTo {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
}

export interface CloseCases_closeCases_jobs_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface CloseCases_closeCases_jobs_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
}

export interface CloseCases_closeCases_jobs_movable_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface CloseCases_closeCases_jobs_movable {
  /**
   * Unique id of movable
   */
  id: string;
  /**
   * Description of movable
   */
  description: string;
  /**
   * Movable status
   */
  status: string;
  /**
   * Movable placement
   */
  placement: CloseCases_closeCases_jobs_movable_placement;
}

export interface CloseCases_closeCases_jobs {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Type of case task
   */
  type: JobType;
  /**
   * Description of the case task
   */
  description: string;
  /**
   * Deadline of the case task
   */
  deadline: GQL_DateTime;
  /**
   * The user responsible for the task
   */
  assignedTo: CloseCases_closeCases_jobs_assignedTo | null;
  /**
   * The location responsible to the task
   */
  location: CloseCases_closeCases_jobs_location | null;
  /**
   * The case the task belongs to
   */
  case: CloseCases_closeCases_jobs_case | null;
  /**
   * The movable responsible to the task
   */
  movable: CloseCases_closeCases_jobs_movable | null;
  /**
   * Whether the task is completed
   */
  completed: boolean;
}

export interface CloseCases_closeCases_drivingSlipSeries_drivingSlips_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CloseCases_closeCases_drivingSlipSeries_drivingSlips_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CloseCases_closeCases_drivingSlipSeries_drivingSlips_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CloseCases_closeCases_drivingSlipSeries_drivingSlips_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface CloseCases_closeCases_drivingSlipSeries_drivingSlips_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: CloseCases_closeCases_drivingSlipSeries_drivingSlips_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface CloseCases_closeCases_drivingSlipSeries_drivingSlips_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: CloseCases_closeCases_drivingSlipSeries_drivingSlips_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface CloseCases_closeCases_drivingSlipSeries_drivingSlips_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: CloseCases_closeCases_drivingSlipSeries_drivingSlips_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface CloseCases_closeCases_drivingSlipSeries_drivingSlips_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CloseCases_closeCases_drivingSlipSeries_drivingSlips_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CloseCases_closeCases_drivingSlipSeries_drivingSlips_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CloseCases_closeCases_drivingSlipSeries_drivingSlips_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CloseCases_closeCases_drivingSlipSeries_drivingSlips_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: CloseCases_closeCases_drivingSlipSeries_drivingSlips_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: CloseCases_closeCases_drivingSlipSeries_drivingSlips_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: CloseCases_closeCases_drivingSlipSeries_drivingSlips_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface CloseCases_closeCases_drivingSlipSeries_drivingSlips_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CloseCases_closeCases_drivingSlipSeries_drivingSlips_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CloseCases_closeCases_drivingSlipSeries_drivingSlips_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CloseCases_closeCases_drivingSlipSeries_drivingSlips_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: CloseCases_closeCases_drivingSlipSeries_drivingSlips_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: CloseCases_closeCases_drivingSlipSeries_drivingSlips_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: CloseCases_closeCases_drivingSlipSeries_drivingSlips_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface CloseCases_closeCases_drivingSlipSeries_drivingSlips_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: CloseCases_closeCases_drivingSlipSeries_drivingSlips_changes_user;
  /**
   * Value before
   */
  before: CloseCases_closeCases_drivingSlipSeries_drivingSlips_changes_before;
  /**
   * Value after
   */
  after: CloseCases_closeCases_drivingSlipSeries_drivingSlips_changes_after;
}

export interface CloseCases_closeCases_drivingSlipSeries_drivingSlips_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface CloseCases_closeCases_drivingSlipSeries_drivingSlips_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface CloseCases_closeCases_drivingSlipSeries_drivingSlips {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: CloseCases_closeCases_drivingSlipSeries_drivingSlips_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: CloseCases_closeCases_drivingSlipSeries_drivingSlips_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: CloseCases_closeCases_drivingSlipSeries_drivingSlips_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: CloseCases_closeCases_drivingSlipSeries_drivingSlips_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: CloseCases_closeCases_drivingSlipSeries_drivingSlips_changes[];
  /**
   * Location of driving slip
   */
  location: CloseCases_closeCases_drivingSlipSeries_drivingSlips_location;
  /**
   * Department of driving slip
   */
  department: CloseCases_closeCases_drivingSlipSeries_drivingSlips_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
}

export interface CloseCases_closeCases_drivingSlipSeries {
  /**
   * Unique id of driving slip series
   */
  id: string;
  /**
   * Driving slip series completed status
   */
  completed: boolean;
  /**
   * Driving slips on series
   */
  drivingSlips: CloseCases_closeCases_drivingSlipSeries_drivingSlips[];
}

export interface CloseCases_closeCases_alternativeContact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Comment on person
   */
  comment: string | null;
}

export interface CloseCases_closeCases_riskEvaluationAnswers_question {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question
   */
  question: string;
}

export interface CloseCases_closeCases_riskEvaluationAnswers {
  /**
   * Id of question answer is linked to
   */
  question: CloseCases_closeCases_riskEvaluationAnswers_question;
  /**
   * Answer given
   */
  answer: boolean;
}

export interface CloseCases_closeCases_requisitions_case_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
}

export interface CloseCases_closeCases_requisitions_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
  /**
   * Id of damage category
   */
  id: string;
}

export interface CloseCases_closeCases_requisitions_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of damage cause
   */
  id: string;
}

export interface CloseCases_closeCases_requisitions_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CloseCases_closeCases_requisitions_case_damage_contact {
  /**
   * Contact address information
   */
  address: CloseCases_closeCases_requisitions_case_damage_contact_address;
}

export interface CloseCases_closeCases_requisitions_case_damage {
  /**
   * Damage category
   */
  category: CloseCases_closeCases_requisitions_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: CloseCases_closeCases_requisitions_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: CloseCases_closeCases_requisitions_case_damage_contact;
}

export interface CloseCases_closeCases_requisitions_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: CloseCases_closeCases_requisitions_case_debitor;
  /**
   * Case damage details
   */
  damage: CloseCases_closeCases_requisitions_case_damage;
}

export interface CloseCases_closeCases_requisitions_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
}

export interface CloseCases_closeCases_requisitions_drivingSlip_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
}

export interface CloseCases_closeCases_requisitions_drivingSlip_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
}

export interface CloseCases_closeCases_requisitions_drivingSlip_case_damage {
  /**
   * Damage category
   */
  category: CloseCases_closeCases_requisitions_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: CloseCases_closeCases_requisitions_drivingSlip_case_damage_cause;
}

export interface CloseCases_closeCases_requisitions_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: CloseCases_closeCases_requisitions_drivingSlip_case_damage;
}

export interface CloseCases_closeCases_requisitions_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Case which driving slip is related to
   */
  case: CloseCases_closeCases_requisitions_drivingSlip_case;
}

export interface CloseCases_closeCases_requisitions_vendor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface CloseCases_closeCases_requisitions_vendor {
  /**
   * ERP reference no for vendor
   */
  erpReferenceNo: string;
  /**
   * The name of the vendor
   */
  company: string | null;
  /**
   * The address of the vendor
   */
  address: CloseCases_closeCases_requisitions_vendor_address | null;
  /**
   * The email of the vendor
   */
  email: string | null;
  /**
   * The phone number of the vendor
   */
  phone: string | null;
  /**
   * The posting group of the vendor
   */
  vendorPostingGroup: string;
  /**
   * Attached SSG department of the vendor
   */
  ssgDepartment: string;
  /**
   * Attached SSG location of the vendor
   */
  ssgLocation: string;
  /**
   * Vendor type
   */
  type: string;
}

export interface CloseCases_closeCases_requisitions_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface CloseCases_closeCases_requisitions {
  /**
   * id of the requisition
   */
  id: string;
  /**
   * case owning the requisition
   */
  case: CloseCases_closeCases_requisitions_case | null;
  /**
   * location owning the requisition
   */
  department: CloseCases_closeCases_requisitions_department | null;
  /**
   * driving slip owning the requisition
   */
  drivingSlip: CloseCases_closeCases_requisitions_drivingSlip | null;
  /**
   * description of the requisition
   */
  description: string;
  /**
   * orderNumber of the requisition
   */
  orderNumber: string;
  /**
   * the type of the requisition
   */
  type: RequisitionType;
  /**
   * Requisition status
   */
  status: boolean;
  /**
   * the vendor of the requisition
   */
  vendor: CloseCases_closeCases_requisitions_vendor | null;
  /**
   * Timestamp of when the requisition was created
   */
  createdAt: GQL_DateTime;
  /**
   * User who created the requisition
   */
  createdBy: CloseCases_closeCases_requisitions_createdBy;
  /**
   * email of the vendor
   */
  vendorEmail: string | null;
  /**
   * phone number of the vendor
   */
  vendorPhoneNumber: string;
  /**
   * name of the vendor
   */
  vendorName: string;
}

export interface CloseCases_closeCases {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * ReportSent
   */
  reportSent: boolean;
  /**
   * Distance from ssg location to damage address
   */
  distance: string | null;
  /**
   * Whether notifications regarding machines on the case should be sent
   */
  machineNotification: boolean | null;
  /**
   * Whether time registration is allowed on the case
   */
  timeRegistration: boolean | null;
  /**
   * Case debitor details
   */
  debitor: CloseCases_closeCases_debitor;
  /**
   * Case damage details
   */
  damage: CloseCases_closeCases_damage;
  /**
   * Case policy holder details
   */
  policyHolder: CloseCases_closeCases_policyHolder;
  /**
   * Case visitation details
   */
  visitation: CloseCases_closeCases_visitation;
  /**
   * Case requisitioner details
   */
  requisitioner: CloseCases_closeCases_requisitioner;
  /**
   * Case advisor details
   */
  adviser: CloseCases_closeCases_adviser | null;
  /**
   * Case referer details
   */
  referrer: CloseCases_closeCases_referrer | null;
  /**
   * Project manager attached to case
   */
  projectManager: CloseCases_closeCases_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: CloseCases_closeCases_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: CloseCases_closeCases_ssgLocation;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: CloseCases_closeCases_ssgDepartment;
  /**
   * Case ready for billing
   */
  billingReady: boolean;
  /**
   * Case work complete
   */
  workComplete: boolean;
  /**
   * List of case agreements
   */
  caseAgreements: CloseCases_closeCases_caseAgreements[];
  /**
   * List of case emails
   */
  caseEmails: CloseCases_closeCases_caseEmails[];
  /**
   * List of user favorite entries
   */
  userFavorite: CloseCases_closeCases_userFavorite | null;
  /**
   * User who created the case
   */
  createdBy: CloseCases_closeCases_createdBy | null;
  /**
   * Timestamp of when the case was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the case was created
   */
  createdAt: GQL_DateTime;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * The tasks associated with the task
   */
  jobs: CloseCases_closeCases_jobs[] | null;
  /**
   * The driving slips series associated with the case
   */
  drivingSlipSeries: CloseCases_closeCases_drivingSlipSeries[];
  /**
   * Important info on the case
   */
  importantInfo: string | null;
  /**
   * Alternative contact
   */
  alternativeContact: CloseCases_closeCases_alternativeContact | null;
  /**
   * Risk evaluation answers
   */
  riskEvaluationAnswers: CloseCases_closeCases_riskEvaluationAnswers[] | null;
  /**
   * Optional comment for risk evaluation
   */
  riskEvaluationComment: string | null;
  /**
   * Requisition of the case
   */
  requisitions: CloseCases_closeCases_requisitions[];
  scalePointStatus: ScalePointStatus;
  scalePointErrorMessage: string | null;
  arrived: boolean;
  arrivedDate: GQL_DateTime | null;
}

export interface CloseCases {
  closeCases: CloseCases_closeCases[];
}

export interface CloseCasesVariables {
  erpNos: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCase
// ====================================================

export interface CreateCase_createCase_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCase_createCase_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCase_createCase_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: CreateCase_createCase_debitor_shippingAddress_address;
}

export interface CreateCase_createCase_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCase_createCase_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: CreateCase_createCase_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: CreateCase_createCase_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: CreateCase_createCase_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface CreateCase_createCase_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface CreateCase_createCase_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface CreateCase_createCase_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface CreateCase_createCase_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface CreateCase_createCase_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface CreateCase_createCase_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: CreateCase_createCase_damage_cause_departments_location;
}

export interface CreateCase_createCase_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: CreateCase_createCase_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: CreateCase_createCase_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (CreateCase_createCase_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface CreateCase_createCase_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCase_createCase_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: CreateCase_createCase_damage_contact_address;
}

export interface CreateCase_createCase_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface CreateCase_createCase_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: CreateCase_createCase_damage_businessArea;
  /**
   * Damage category
   */
  category: CreateCase_createCase_damage_category;
  /**
   * Cause behind damage
   */
  cause: CreateCase_createCase_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: CreateCase_createCase_damage_contact;
  /**
   * contacts for damage
   */
  contacts: CreateCase_createCase_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface CreateCase_createCase_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCase_createCase_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: CreateCase_createCase_policyHolder_address;
}

export interface CreateCase_createCase_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface CreateCase_createCase_visitation_awaiting {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface CreateCase_createCase_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
  /**
   * The question
   */
  title: string;
}

export interface CreateCase_createCase_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: CreateCase_createCase_visitation_calledBack;
  /**
   * If case is awaiting
   */
  awaiting: CreateCase_createCase_visitation_awaiting;
  /**
   * A set of answers related to the damage cause
   */
  answers: CreateCase_createCase_visitation_answers[] | null;
  /**
   * If case needs a moisture report
   */
  isMoistureReportRequired: boolean | null;
  /**
   * If case needs an SP phase one report
   */
  isSpPhaseOneReportCreated: boolean | null;
}

export interface CreateCase_createCase_requisitioner {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Requisitioner relation
   */
  relation: string;
}

export interface CreateCase_createCase_adviser {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface CreateCase_createCase_referrer {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface CreateCase_createCase_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateCase_createCase_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateCase_createCase_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCase_createCase_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: CreateCase_createCase_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface CreateCase_createCase_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCase_createCase_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: CreateCase_createCase_ssgDepartment_location_address;
}

export interface CreateCase_createCase_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: CreateCase_createCase_ssgDepartment_location;
}

export interface CreateCase_createCase_caseAgreements_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateCase_createCase_caseAgreements {
  /**
   * Agreement content
   */
  content: string;
  /**
   * Agreement category
   */
  category: CaseAgreementCategory;
  /**
   * User who created agreement
   */
  user: CreateCase_createCase_caseAgreements_user;
  /**
   * Timestamp of when agreement was created
   */
  timestamp: GQL_DateTime;
  /**
   * Whether case agreement should be sent to ScalePoint
   */
  shouldSaveInScalePoint: boolean;
}

export interface CreateCase_createCase_caseEmails {
  /**
   * Email content
   */
  content: string;
  /**
   * List of email attachments
   */
  attachments: string[];
  /**
   * List of email receivers
   */
  receivers: string[];
  /**
   * Timestamp of when email was created
   */
  timestamp: GQL_DateTime;
}

export interface CreateCase_createCase_userFavorite {
  /**
   * Unique user id
   */
  user: string;
  /**
   * Color code set by user
   */
  color: string | null;
  /**
   * Favorite active status
   */
  active: boolean;
}

export interface CreateCase_createCase_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateCase_createCase_jobs_assignedTo {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
}

export interface CreateCase_createCase_jobs_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface CreateCase_createCase_jobs_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
}

export interface CreateCase_createCase_jobs_movable_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface CreateCase_createCase_jobs_movable {
  /**
   * Unique id of movable
   */
  id: string;
  /**
   * Description of movable
   */
  description: string;
  /**
   * Movable status
   */
  status: string;
  /**
   * Movable placement
   */
  placement: CreateCase_createCase_jobs_movable_placement;
}

export interface CreateCase_createCase_jobs {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Type of case task
   */
  type: JobType;
  /**
   * Description of the case task
   */
  description: string;
  /**
   * Deadline of the case task
   */
  deadline: GQL_DateTime;
  /**
   * The user responsible for the task
   */
  assignedTo: CreateCase_createCase_jobs_assignedTo | null;
  /**
   * The location responsible to the task
   */
  location: CreateCase_createCase_jobs_location | null;
  /**
   * The case the task belongs to
   */
  case: CreateCase_createCase_jobs_case | null;
  /**
   * The movable responsible to the task
   */
  movable: CreateCase_createCase_jobs_movable | null;
  /**
   * Whether the task is completed
   */
  completed: boolean;
}

export interface CreateCase_createCase_drivingSlipSeries_drivingSlips_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateCase_createCase_drivingSlipSeries_drivingSlips_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateCase_createCase_drivingSlipSeries_drivingSlips_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateCase_createCase_drivingSlipSeries_drivingSlips_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface CreateCase_createCase_drivingSlipSeries_drivingSlips_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: CreateCase_createCase_drivingSlipSeries_drivingSlips_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface CreateCase_createCase_drivingSlipSeries_drivingSlips_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: CreateCase_createCase_drivingSlipSeries_drivingSlips_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface CreateCase_createCase_drivingSlipSeries_drivingSlips_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: CreateCase_createCase_drivingSlipSeries_drivingSlips_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface CreateCase_createCase_drivingSlipSeries_drivingSlips_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateCase_createCase_drivingSlipSeries_drivingSlips_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateCase_createCase_drivingSlipSeries_drivingSlips_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateCase_createCase_drivingSlipSeries_drivingSlips_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateCase_createCase_drivingSlipSeries_drivingSlips_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: CreateCase_createCase_drivingSlipSeries_drivingSlips_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: CreateCase_createCase_drivingSlipSeries_drivingSlips_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: CreateCase_createCase_drivingSlipSeries_drivingSlips_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface CreateCase_createCase_drivingSlipSeries_drivingSlips_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateCase_createCase_drivingSlipSeries_drivingSlips_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateCase_createCase_drivingSlipSeries_drivingSlips_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateCase_createCase_drivingSlipSeries_drivingSlips_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: CreateCase_createCase_drivingSlipSeries_drivingSlips_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: CreateCase_createCase_drivingSlipSeries_drivingSlips_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: CreateCase_createCase_drivingSlipSeries_drivingSlips_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface CreateCase_createCase_drivingSlipSeries_drivingSlips_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: CreateCase_createCase_drivingSlipSeries_drivingSlips_changes_user;
  /**
   * Value before
   */
  before: CreateCase_createCase_drivingSlipSeries_drivingSlips_changes_before;
  /**
   * Value after
   */
  after: CreateCase_createCase_drivingSlipSeries_drivingSlips_changes_after;
}

export interface CreateCase_createCase_drivingSlipSeries_drivingSlips_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface CreateCase_createCase_drivingSlipSeries_drivingSlips_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface CreateCase_createCase_drivingSlipSeries_drivingSlips {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: CreateCase_createCase_drivingSlipSeries_drivingSlips_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: CreateCase_createCase_drivingSlipSeries_drivingSlips_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: CreateCase_createCase_drivingSlipSeries_drivingSlips_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: CreateCase_createCase_drivingSlipSeries_drivingSlips_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: CreateCase_createCase_drivingSlipSeries_drivingSlips_changes[];
  /**
   * Location of driving slip
   */
  location: CreateCase_createCase_drivingSlipSeries_drivingSlips_location;
  /**
   * Department of driving slip
   */
  department: CreateCase_createCase_drivingSlipSeries_drivingSlips_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
}

export interface CreateCase_createCase_drivingSlipSeries {
  /**
   * Unique id of driving slip series
   */
  id: string;
  /**
   * Driving slip series completed status
   */
  completed: boolean;
  /**
   * Driving slips on series
   */
  drivingSlips: CreateCase_createCase_drivingSlipSeries_drivingSlips[];
}

export interface CreateCase_createCase_alternativeContact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Comment on person
   */
  comment: string | null;
}

export interface CreateCase_createCase_riskEvaluationAnswers_question {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question
   */
  question: string;
}

export interface CreateCase_createCase_riskEvaluationAnswers {
  /**
   * Id of question answer is linked to
   */
  question: CreateCase_createCase_riskEvaluationAnswers_question;
  /**
   * Answer given
   */
  answer: boolean;
}

export interface CreateCase_createCase_requisitions_case_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
}

export interface CreateCase_createCase_requisitions_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
  /**
   * Id of damage category
   */
  id: string;
}

export interface CreateCase_createCase_requisitions_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of damage cause
   */
  id: string;
}

export interface CreateCase_createCase_requisitions_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCase_createCase_requisitions_case_damage_contact {
  /**
   * Contact address information
   */
  address: CreateCase_createCase_requisitions_case_damage_contact_address;
}

export interface CreateCase_createCase_requisitions_case_damage {
  /**
   * Damage category
   */
  category: CreateCase_createCase_requisitions_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: CreateCase_createCase_requisitions_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: CreateCase_createCase_requisitions_case_damage_contact;
}

export interface CreateCase_createCase_requisitions_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: CreateCase_createCase_requisitions_case_debitor;
  /**
   * Case damage details
   */
  damage: CreateCase_createCase_requisitions_case_damage;
}

export interface CreateCase_createCase_requisitions_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
}

export interface CreateCase_createCase_requisitions_drivingSlip_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
}

export interface CreateCase_createCase_requisitions_drivingSlip_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
}

export interface CreateCase_createCase_requisitions_drivingSlip_case_damage {
  /**
   * Damage category
   */
  category: CreateCase_createCase_requisitions_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: CreateCase_createCase_requisitions_drivingSlip_case_damage_cause;
}

export interface CreateCase_createCase_requisitions_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: CreateCase_createCase_requisitions_drivingSlip_case_damage;
}

export interface CreateCase_createCase_requisitions_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Case which driving slip is related to
   */
  case: CreateCase_createCase_requisitions_drivingSlip_case;
}

export interface CreateCase_createCase_requisitions_vendor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCase_createCase_requisitions_vendor {
  /**
   * ERP reference no for vendor
   */
  erpReferenceNo: string;
  /**
   * The name of the vendor
   */
  company: string | null;
  /**
   * The address of the vendor
   */
  address: CreateCase_createCase_requisitions_vendor_address | null;
  /**
   * The email of the vendor
   */
  email: string | null;
  /**
   * The phone number of the vendor
   */
  phone: string | null;
  /**
   * The posting group of the vendor
   */
  vendorPostingGroup: string;
  /**
   * Attached SSG department of the vendor
   */
  ssgDepartment: string;
  /**
   * Attached SSG location of the vendor
   */
  ssgLocation: string;
  /**
   * Vendor type
   */
  type: string;
}

export interface CreateCase_createCase_requisitions_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface CreateCase_createCase_requisitions {
  /**
   * id of the requisition
   */
  id: string;
  /**
   * case owning the requisition
   */
  case: CreateCase_createCase_requisitions_case | null;
  /**
   * location owning the requisition
   */
  department: CreateCase_createCase_requisitions_department | null;
  /**
   * driving slip owning the requisition
   */
  drivingSlip: CreateCase_createCase_requisitions_drivingSlip | null;
  /**
   * description of the requisition
   */
  description: string;
  /**
   * orderNumber of the requisition
   */
  orderNumber: string;
  /**
   * the type of the requisition
   */
  type: RequisitionType;
  /**
   * Requisition status
   */
  status: boolean;
  /**
   * the vendor of the requisition
   */
  vendor: CreateCase_createCase_requisitions_vendor | null;
  /**
   * Timestamp of when the requisition was created
   */
  createdAt: GQL_DateTime;
  /**
   * User who created the requisition
   */
  createdBy: CreateCase_createCase_requisitions_createdBy;
  /**
   * email of the vendor
   */
  vendorEmail: string | null;
  /**
   * phone number of the vendor
   */
  vendorPhoneNumber: string;
  /**
   * name of the vendor
   */
  vendorName: string;
}

export interface CreateCase_createCase {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * ReportSent
   */
  reportSent: boolean;
  /**
   * Distance from ssg location to damage address
   */
  distance: string | null;
  /**
   * Whether notifications regarding machines on the case should be sent
   */
  machineNotification: boolean | null;
  /**
   * Whether time registration is allowed on the case
   */
  timeRegistration: boolean | null;
  /**
   * Case debitor details
   */
  debitor: CreateCase_createCase_debitor;
  /**
   * Case damage details
   */
  damage: CreateCase_createCase_damage;
  /**
   * Case policy holder details
   */
  policyHolder: CreateCase_createCase_policyHolder;
  /**
   * Case visitation details
   */
  visitation: CreateCase_createCase_visitation;
  /**
   * Case requisitioner details
   */
  requisitioner: CreateCase_createCase_requisitioner;
  /**
   * Case advisor details
   */
  adviser: CreateCase_createCase_adviser | null;
  /**
   * Case referer details
   */
  referrer: CreateCase_createCase_referrer | null;
  /**
   * Project manager attached to case
   */
  projectManager: CreateCase_createCase_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: CreateCase_createCase_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: CreateCase_createCase_ssgLocation;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: CreateCase_createCase_ssgDepartment;
  /**
   * Case ready for billing
   */
  billingReady: boolean;
  /**
   * Case work complete
   */
  workComplete: boolean;
  /**
   * List of case agreements
   */
  caseAgreements: CreateCase_createCase_caseAgreements[];
  /**
   * List of case emails
   */
  caseEmails: CreateCase_createCase_caseEmails[];
  /**
   * List of user favorite entries
   */
  userFavorite: CreateCase_createCase_userFavorite | null;
  /**
   * User who created the case
   */
  createdBy: CreateCase_createCase_createdBy | null;
  /**
   * Timestamp of when the case was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the case was created
   */
  createdAt: GQL_DateTime;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * The tasks associated with the task
   */
  jobs: CreateCase_createCase_jobs[] | null;
  /**
   * The driving slips series associated with the case
   */
  drivingSlipSeries: CreateCase_createCase_drivingSlipSeries[];
  /**
   * Important info on the case
   */
  importantInfo: string | null;
  /**
   * Alternative contact
   */
  alternativeContact: CreateCase_createCase_alternativeContact | null;
  /**
   * Risk evaluation answers
   */
  riskEvaluationAnswers: CreateCase_createCase_riskEvaluationAnswers[] | null;
  /**
   * Optional comment for risk evaluation
   */
  riskEvaluationComment: string | null;
  /**
   * Requisition of the case
   */
  requisitions: CreateCase_createCase_requisitions[];
  scalePointStatus: ScalePointStatus;
  scalePointErrorMessage: string | null;
  arrived: boolean;
  arrivedDate: GQL_DateTime | null;
}

export interface CreateCase {
  /**
   * Create a case
   */
  createCase: CreateCase_createCase;
}

export interface CreateCaseVariables {
  case: CaseInput;
  drivingSlip?: DrivingSlipHelperInput | null;
  saveInScalePoint: boolean;
  migrationInput?: CaseMigration | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCaseAgreement
// ====================================================

export interface CreateCaseAgreement_createCaseAgreement_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCaseAgreement_createCaseAgreement_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCaseAgreement_createCaseAgreement_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: CreateCaseAgreement_createCaseAgreement_debitor_shippingAddress_address;
}

export interface CreateCaseAgreement_createCaseAgreement_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCaseAgreement_createCaseAgreement_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: CreateCaseAgreement_createCaseAgreement_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: CreateCaseAgreement_createCaseAgreement_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: CreateCaseAgreement_createCaseAgreement_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface CreateCaseAgreement_createCaseAgreement_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface CreateCaseAgreement_createCaseAgreement_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface CreateCaseAgreement_createCaseAgreement_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface CreateCaseAgreement_createCaseAgreement_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface CreateCaseAgreement_createCaseAgreement_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface CreateCaseAgreement_createCaseAgreement_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: CreateCaseAgreement_createCaseAgreement_damage_cause_departments_location;
}

export interface CreateCaseAgreement_createCaseAgreement_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: CreateCaseAgreement_createCaseAgreement_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: CreateCaseAgreement_createCaseAgreement_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (CreateCaseAgreement_createCaseAgreement_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface CreateCaseAgreement_createCaseAgreement_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCaseAgreement_createCaseAgreement_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: CreateCaseAgreement_createCaseAgreement_damage_contact_address;
}

export interface CreateCaseAgreement_createCaseAgreement_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface CreateCaseAgreement_createCaseAgreement_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: CreateCaseAgreement_createCaseAgreement_damage_businessArea;
  /**
   * Damage category
   */
  category: CreateCaseAgreement_createCaseAgreement_damage_category;
  /**
   * Cause behind damage
   */
  cause: CreateCaseAgreement_createCaseAgreement_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: CreateCaseAgreement_createCaseAgreement_damage_contact;
  /**
   * contacts for damage
   */
  contacts: CreateCaseAgreement_createCaseAgreement_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface CreateCaseAgreement_createCaseAgreement_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCaseAgreement_createCaseAgreement_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: CreateCaseAgreement_createCaseAgreement_policyHolder_address;
}

export interface CreateCaseAgreement_createCaseAgreement_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface CreateCaseAgreement_createCaseAgreement_visitation_awaiting {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface CreateCaseAgreement_createCaseAgreement_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
  /**
   * The question
   */
  title: string;
}

export interface CreateCaseAgreement_createCaseAgreement_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: CreateCaseAgreement_createCaseAgreement_visitation_calledBack;
  /**
   * If case is awaiting
   */
  awaiting: CreateCaseAgreement_createCaseAgreement_visitation_awaiting;
  /**
   * A set of answers related to the damage cause
   */
  answers: CreateCaseAgreement_createCaseAgreement_visitation_answers[] | null;
  /**
   * If case needs a moisture report
   */
  isMoistureReportRequired: boolean | null;
  /**
   * If case needs an SP phase one report
   */
  isSpPhaseOneReportCreated: boolean | null;
}

export interface CreateCaseAgreement_createCaseAgreement_requisitioner {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Requisitioner relation
   */
  relation: string;
}

export interface CreateCaseAgreement_createCaseAgreement_adviser {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface CreateCaseAgreement_createCaseAgreement_referrer {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface CreateCaseAgreement_createCaseAgreement_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateCaseAgreement_createCaseAgreement_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateCaseAgreement_createCaseAgreement_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCaseAgreement_createCaseAgreement_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: CreateCaseAgreement_createCaseAgreement_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface CreateCaseAgreement_createCaseAgreement_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCaseAgreement_createCaseAgreement_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: CreateCaseAgreement_createCaseAgreement_ssgDepartment_location_address;
}

export interface CreateCaseAgreement_createCaseAgreement_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: CreateCaseAgreement_createCaseAgreement_ssgDepartment_location;
}

export interface CreateCaseAgreement_createCaseAgreement_caseAgreements_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateCaseAgreement_createCaseAgreement_caseAgreements {
  /**
   * Agreement content
   */
  content: string;
  /**
   * Agreement category
   */
  category: CaseAgreementCategory;
  /**
   * User who created agreement
   */
  user: CreateCaseAgreement_createCaseAgreement_caseAgreements_user;
  /**
   * Timestamp of when agreement was created
   */
  timestamp: GQL_DateTime;
  /**
   * Whether case agreement should be sent to ScalePoint
   */
  shouldSaveInScalePoint: boolean;
}

export interface CreateCaseAgreement_createCaseAgreement_caseEmails {
  /**
   * Email content
   */
  content: string;
  /**
   * List of email attachments
   */
  attachments: string[];
  /**
   * List of email receivers
   */
  receivers: string[];
  /**
   * Timestamp of when email was created
   */
  timestamp: GQL_DateTime;
}

export interface CreateCaseAgreement_createCaseAgreement_userFavorite {
  /**
   * Unique user id
   */
  user: string;
  /**
   * Color code set by user
   */
  color: string | null;
  /**
   * Favorite active status
   */
  active: boolean;
}

export interface CreateCaseAgreement_createCaseAgreement_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateCaseAgreement_createCaseAgreement_jobs_assignedTo {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
}

export interface CreateCaseAgreement_createCaseAgreement_jobs_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface CreateCaseAgreement_createCaseAgreement_jobs_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
}

export interface CreateCaseAgreement_createCaseAgreement_jobs_movable_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface CreateCaseAgreement_createCaseAgreement_jobs_movable {
  /**
   * Unique id of movable
   */
  id: string;
  /**
   * Description of movable
   */
  description: string;
  /**
   * Movable status
   */
  status: string;
  /**
   * Movable placement
   */
  placement: CreateCaseAgreement_createCaseAgreement_jobs_movable_placement;
}

export interface CreateCaseAgreement_createCaseAgreement_jobs {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Type of case task
   */
  type: JobType;
  /**
   * Description of the case task
   */
  description: string;
  /**
   * Deadline of the case task
   */
  deadline: GQL_DateTime;
  /**
   * The user responsible for the task
   */
  assignedTo: CreateCaseAgreement_createCaseAgreement_jobs_assignedTo | null;
  /**
   * The location responsible to the task
   */
  location: CreateCaseAgreement_createCaseAgreement_jobs_location | null;
  /**
   * The case the task belongs to
   */
  case: CreateCaseAgreement_createCaseAgreement_jobs_case | null;
  /**
   * The movable responsible to the task
   */
  movable: CreateCaseAgreement_createCaseAgreement_jobs_movable | null;
  /**
   * Whether the task is completed
   */
  completed: boolean;
}

export interface CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_changes_user;
  /**
   * Value before
   */
  before: CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_changes_before;
  /**
   * Value after
   */
  after: CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_changes_after;
}

export interface CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_changes[];
  /**
   * Location of driving slip
   */
  location: CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_location;
  /**
   * Department of driving slip
   */
  department: CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
}

export interface CreateCaseAgreement_createCaseAgreement_drivingSlipSeries {
  /**
   * Unique id of driving slip series
   */
  id: string;
  /**
   * Driving slip series completed status
   */
  completed: boolean;
  /**
   * Driving slips on series
   */
  drivingSlips: CreateCaseAgreement_createCaseAgreement_drivingSlipSeries_drivingSlips[];
}

export interface CreateCaseAgreement_createCaseAgreement_alternativeContact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Comment on person
   */
  comment: string | null;
}

export interface CreateCaseAgreement_createCaseAgreement_riskEvaluationAnswers_question {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question
   */
  question: string;
}

export interface CreateCaseAgreement_createCaseAgreement_riskEvaluationAnswers {
  /**
   * Id of question answer is linked to
   */
  question: CreateCaseAgreement_createCaseAgreement_riskEvaluationAnswers_question;
  /**
   * Answer given
   */
  answer: boolean;
}

export interface CreateCaseAgreement_createCaseAgreement_requisitions_case_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
}

export interface CreateCaseAgreement_createCaseAgreement_requisitions_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
  /**
   * Id of damage category
   */
  id: string;
}

export interface CreateCaseAgreement_createCaseAgreement_requisitions_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of damage cause
   */
  id: string;
}

export interface CreateCaseAgreement_createCaseAgreement_requisitions_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCaseAgreement_createCaseAgreement_requisitions_case_damage_contact {
  /**
   * Contact address information
   */
  address: CreateCaseAgreement_createCaseAgreement_requisitions_case_damage_contact_address;
}

export interface CreateCaseAgreement_createCaseAgreement_requisitions_case_damage {
  /**
   * Damage category
   */
  category: CreateCaseAgreement_createCaseAgreement_requisitions_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: CreateCaseAgreement_createCaseAgreement_requisitions_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: CreateCaseAgreement_createCaseAgreement_requisitions_case_damage_contact;
}

export interface CreateCaseAgreement_createCaseAgreement_requisitions_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: CreateCaseAgreement_createCaseAgreement_requisitions_case_debitor;
  /**
   * Case damage details
   */
  damage: CreateCaseAgreement_createCaseAgreement_requisitions_case_damage;
}

export interface CreateCaseAgreement_createCaseAgreement_requisitions_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
}

export interface CreateCaseAgreement_createCaseAgreement_requisitions_drivingSlip_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
}

export interface CreateCaseAgreement_createCaseAgreement_requisitions_drivingSlip_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
}

export interface CreateCaseAgreement_createCaseAgreement_requisitions_drivingSlip_case_damage {
  /**
   * Damage category
   */
  category: CreateCaseAgreement_createCaseAgreement_requisitions_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: CreateCaseAgreement_createCaseAgreement_requisitions_drivingSlip_case_damage_cause;
}

export interface CreateCaseAgreement_createCaseAgreement_requisitions_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: CreateCaseAgreement_createCaseAgreement_requisitions_drivingSlip_case_damage;
}

export interface CreateCaseAgreement_createCaseAgreement_requisitions_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Case which driving slip is related to
   */
  case: CreateCaseAgreement_createCaseAgreement_requisitions_drivingSlip_case;
}

export interface CreateCaseAgreement_createCaseAgreement_requisitions_vendor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCaseAgreement_createCaseAgreement_requisitions_vendor {
  /**
   * ERP reference no for vendor
   */
  erpReferenceNo: string;
  /**
   * The name of the vendor
   */
  company: string | null;
  /**
   * The address of the vendor
   */
  address: CreateCaseAgreement_createCaseAgreement_requisitions_vendor_address | null;
  /**
   * The email of the vendor
   */
  email: string | null;
  /**
   * The phone number of the vendor
   */
  phone: string | null;
  /**
   * The posting group of the vendor
   */
  vendorPostingGroup: string;
  /**
   * Attached SSG department of the vendor
   */
  ssgDepartment: string;
  /**
   * Attached SSG location of the vendor
   */
  ssgLocation: string;
  /**
   * Vendor type
   */
  type: string;
}

export interface CreateCaseAgreement_createCaseAgreement_requisitions_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface CreateCaseAgreement_createCaseAgreement_requisitions {
  /**
   * id of the requisition
   */
  id: string;
  /**
   * case owning the requisition
   */
  case: CreateCaseAgreement_createCaseAgreement_requisitions_case | null;
  /**
   * location owning the requisition
   */
  department: CreateCaseAgreement_createCaseAgreement_requisitions_department | null;
  /**
   * driving slip owning the requisition
   */
  drivingSlip: CreateCaseAgreement_createCaseAgreement_requisitions_drivingSlip | null;
  /**
   * description of the requisition
   */
  description: string;
  /**
   * orderNumber of the requisition
   */
  orderNumber: string;
  /**
   * the type of the requisition
   */
  type: RequisitionType;
  /**
   * Requisition status
   */
  status: boolean;
  /**
   * the vendor of the requisition
   */
  vendor: CreateCaseAgreement_createCaseAgreement_requisitions_vendor | null;
  /**
   * Timestamp of when the requisition was created
   */
  createdAt: GQL_DateTime;
  /**
   * User who created the requisition
   */
  createdBy: CreateCaseAgreement_createCaseAgreement_requisitions_createdBy;
  /**
   * email of the vendor
   */
  vendorEmail: string | null;
  /**
   * phone number of the vendor
   */
  vendorPhoneNumber: string;
  /**
   * name of the vendor
   */
  vendorName: string;
}

export interface CreateCaseAgreement_createCaseAgreement {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * ReportSent
   */
  reportSent: boolean;
  /**
   * Distance from ssg location to damage address
   */
  distance: string | null;
  /**
   * Whether notifications regarding machines on the case should be sent
   */
  machineNotification: boolean | null;
  /**
   * Whether time registration is allowed on the case
   */
  timeRegistration: boolean | null;
  /**
   * Case debitor details
   */
  debitor: CreateCaseAgreement_createCaseAgreement_debitor;
  /**
   * Case damage details
   */
  damage: CreateCaseAgreement_createCaseAgreement_damage;
  /**
   * Case policy holder details
   */
  policyHolder: CreateCaseAgreement_createCaseAgreement_policyHolder;
  /**
   * Case visitation details
   */
  visitation: CreateCaseAgreement_createCaseAgreement_visitation;
  /**
   * Case requisitioner details
   */
  requisitioner: CreateCaseAgreement_createCaseAgreement_requisitioner;
  /**
   * Case advisor details
   */
  adviser: CreateCaseAgreement_createCaseAgreement_adviser | null;
  /**
   * Case referer details
   */
  referrer: CreateCaseAgreement_createCaseAgreement_referrer | null;
  /**
   * Project manager attached to case
   */
  projectManager: CreateCaseAgreement_createCaseAgreement_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: CreateCaseAgreement_createCaseAgreement_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: CreateCaseAgreement_createCaseAgreement_ssgLocation;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: CreateCaseAgreement_createCaseAgreement_ssgDepartment;
  /**
   * Case ready for billing
   */
  billingReady: boolean;
  /**
   * Case work complete
   */
  workComplete: boolean;
  /**
   * List of case agreements
   */
  caseAgreements: CreateCaseAgreement_createCaseAgreement_caseAgreements[];
  /**
   * List of case emails
   */
  caseEmails: CreateCaseAgreement_createCaseAgreement_caseEmails[];
  /**
   * List of user favorite entries
   */
  userFavorite: CreateCaseAgreement_createCaseAgreement_userFavorite | null;
  /**
   * User who created the case
   */
  createdBy: CreateCaseAgreement_createCaseAgreement_createdBy | null;
  /**
   * Timestamp of when the case was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the case was created
   */
  createdAt: GQL_DateTime;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * The tasks associated with the task
   */
  jobs: CreateCaseAgreement_createCaseAgreement_jobs[] | null;
  /**
   * The driving slips series associated with the case
   */
  drivingSlipSeries: CreateCaseAgreement_createCaseAgreement_drivingSlipSeries[];
  /**
   * Important info on the case
   */
  importantInfo: string | null;
  /**
   * Alternative contact
   */
  alternativeContact: CreateCaseAgreement_createCaseAgreement_alternativeContact | null;
  /**
   * Risk evaluation answers
   */
  riskEvaluationAnswers: CreateCaseAgreement_createCaseAgreement_riskEvaluationAnswers[] | null;
  /**
   * Optional comment for risk evaluation
   */
  riskEvaluationComment: string | null;
  /**
   * Requisition of the case
   */
  requisitions: CreateCaseAgreement_createCaseAgreement_requisitions[];
  scalePointStatus: ScalePointStatus;
  scalePointErrorMessage: string | null;
  arrived: boolean;
  arrivedDate: GQL_DateTime | null;
}

export interface CreateCaseAgreement {
  createCaseAgreement: CreateCaseAgreement_createCaseAgreement;
}

export interface CreateCaseAgreementVariables {
  id: string;
  caseAgreement: CaseAgreementInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FavoriteCase
// ====================================================

export interface FavoriteCase_favoriteCase_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface FavoriteCase_favoriteCase_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface FavoriteCase_favoriteCase_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: FavoriteCase_favoriteCase_debitor_shippingAddress_address;
}

export interface FavoriteCase_favoriteCase_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface FavoriteCase_favoriteCase_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: FavoriteCase_favoriteCase_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: FavoriteCase_favoriteCase_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: FavoriteCase_favoriteCase_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface FavoriteCase_favoriteCase_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface FavoriteCase_favoriteCase_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface FavoriteCase_favoriteCase_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface FavoriteCase_favoriteCase_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface FavoriteCase_favoriteCase_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface FavoriteCase_favoriteCase_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: FavoriteCase_favoriteCase_damage_cause_departments_location;
}

export interface FavoriteCase_favoriteCase_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: FavoriteCase_favoriteCase_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: FavoriteCase_favoriteCase_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (FavoriteCase_favoriteCase_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface FavoriteCase_favoriteCase_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface FavoriteCase_favoriteCase_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: FavoriteCase_favoriteCase_damage_contact_address;
}

export interface FavoriteCase_favoriteCase_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface FavoriteCase_favoriteCase_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: FavoriteCase_favoriteCase_damage_businessArea;
  /**
   * Damage category
   */
  category: FavoriteCase_favoriteCase_damage_category;
  /**
   * Cause behind damage
   */
  cause: FavoriteCase_favoriteCase_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: FavoriteCase_favoriteCase_damage_contact;
  /**
   * contacts for damage
   */
  contacts: FavoriteCase_favoriteCase_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface FavoriteCase_favoriteCase_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface FavoriteCase_favoriteCase_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: FavoriteCase_favoriteCase_policyHolder_address;
}

export interface FavoriteCase_favoriteCase_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface FavoriteCase_favoriteCase_visitation_awaiting {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface FavoriteCase_favoriteCase_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
  /**
   * The question
   */
  title: string;
}

export interface FavoriteCase_favoriteCase_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: FavoriteCase_favoriteCase_visitation_calledBack;
  /**
   * If case is awaiting
   */
  awaiting: FavoriteCase_favoriteCase_visitation_awaiting;
  /**
   * A set of answers related to the damage cause
   */
  answers: FavoriteCase_favoriteCase_visitation_answers[] | null;
  /**
   * If case needs a moisture report
   */
  isMoistureReportRequired: boolean | null;
  /**
   * If case needs an SP phase one report
   */
  isSpPhaseOneReportCreated: boolean | null;
}

export interface FavoriteCase_favoriteCase_requisitioner {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Requisitioner relation
   */
  relation: string;
}

export interface FavoriteCase_favoriteCase_adviser {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface FavoriteCase_favoriteCase_referrer {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface FavoriteCase_favoriteCase_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface FavoriteCase_favoriteCase_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface FavoriteCase_favoriteCase_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface FavoriteCase_favoriteCase_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: FavoriteCase_favoriteCase_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface FavoriteCase_favoriteCase_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface FavoriteCase_favoriteCase_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: FavoriteCase_favoriteCase_ssgDepartment_location_address;
}

export interface FavoriteCase_favoriteCase_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: FavoriteCase_favoriteCase_ssgDepartment_location;
}

export interface FavoriteCase_favoriteCase_caseAgreements_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface FavoriteCase_favoriteCase_caseAgreements {
  /**
   * Agreement content
   */
  content: string;
  /**
   * Agreement category
   */
  category: CaseAgreementCategory;
  /**
   * User who created agreement
   */
  user: FavoriteCase_favoriteCase_caseAgreements_user;
  /**
   * Timestamp of when agreement was created
   */
  timestamp: GQL_DateTime;
  /**
   * Whether case agreement should be sent to ScalePoint
   */
  shouldSaveInScalePoint: boolean;
}

export interface FavoriteCase_favoriteCase_caseEmails {
  /**
   * Email content
   */
  content: string;
  /**
   * List of email attachments
   */
  attachments: string[];
  /**
   * List of email receivers
   */
  receivers: string[];
  /**
   * Timestamp of when email was created
   */
  timestamp: GQL_DateTime;
}

export interface FavoriteCase_favoriteCase_userFavorite {
  /**
   * Unique user id
   */
  user: string;
  /**
   * Color code set by user
   */
  color: string | null;
  /**
   * Favorite active status
   */
  active: boolean;
}

export interface FavoriteCase_favoriteCase_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface FavoriteCase_favoriteCase_jobs_assignedTo {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
}

export interface FavoriteCase_favoriteCase_jobs_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface FavoriteCase_favoriteCase_jobs_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
}

export interface FavoriteCase_favoriteCase_jobs_movable_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface FavoriteCase_favoriteCase_jobs_movable {
  /**
   * Unique id of movable
   */
  id: string;
  /**
   * Description of movable
   */
  description: string;
  /**
   * Movable status
   */
  status: string;
  /**
   * Movable placement
   */
  placement: FavoriteCase_favoriteCase_jobs_movable_placement;
}

export interface FavoriteCase_favoriteCase_jobs {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Type of case task
   */
  type: JobType;
  /**
   * Description of the case task
   */
  description: string;
  /**
   * Deadline of the case task
   */
  deadline: GQL_DateTime;
  /**
   * The user responsible for the task
   */
  assignedTo: FavoriteCase_favoriteCase_jobs_assignedTo | null;
  /**
   * The location responsible to the task
   */
  location: FavoriteCase_favoriteCase_jobs_location | null;
  /**
   * The case the task belongs to
   */
  case: FavoriteCase_favoriteCase_jobs_case | null;
  /**
   * The movable responsible to the task
   */
  movable: FavoriteCase_favoriteCase_jobs_movable | null;
  /**
   * Whether the task is completed
   */
  completed: boolean;
}

export interface FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_changes_user;
  /**
   * Value before
   */
  before: FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_changes_before;
  /**
   * Value after
   */
  after: FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_changes_after;
}

export interface FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_changes[];
  /**
   * Location of driving slip
   */
  location: FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_location;
  /**
   * Department of driving slip
   */
  department: FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
}

export interface FavoriteCase_favoriteCase_drivingSlipSeries {
  /**
   * Unique id of driving slip series
   */
  id: string;
  /**
   * Driving slip series completed status
   */
  completed: boolean;
  /**
   * Driving slips on series
   */
  drivingSlips: FavoriteCase_favoriteCase_drivingSlipSeries_drivingSlips[];
}

export interface FavoriteCase_favoriteCase_alternativeContact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Comment on person
   */
  comment: string | null;
}

export interface FavoriteCase_favoriteCase_riskEvaluationAnswers_question {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question
   */
  question: string;
}

export interface FavoriteCase_favoriteCase_riskEvaluationAnswers {
  /**
   * Id of question answer is linked to
   */
  question: FavoriteCase_favoriteCase_riskEvaluationAnswers_question;
  /**
   * Answer given
   */
  answer: boolean;
}

export interface FavoriteCase_favoriteCase_requisitions_case_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
}

export interface FavoriteCase_favoriteCase_requisitions_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
  /**
   * Id of damage category
   */
  id: string;
}

export interface FavoriteCase_favoriteCase_requisitions_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of damage cause
   */
  id: string;
}

export interface FavoriteCase_favoriteCase_requisitions_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface FavoriteCase_favoriteCase_requisitions_case_damage_contact {
  /**
   * Contact address information
   */
  address: FavoriteCase_favoriteCase_requisitions_case_damage_contact_address;
}

export interface FavoriteCase_favoriteCase_requisitions_case_damage {
  /**
   * Damage category
   */
  category: FavoriteCase_favoriteCase_requisitions_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: FavoriteCase_favoriteCase_requisitions_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: FavoriteCase_favoriteCase_requisitions_case_damage_contact;
}

export interface FavoriteCase_favoriteCase_requisitions_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: FavoriteCase_favoriteCase_requisitions_case_debitor;
  /**
   * Case damage details
   */
  damage: FavoriteCase_favoriteCase_requisitions_case_damage;
}

export interface FavoriteCase_favoriteCase_requisitions_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
}

export interface FavoriteCase_favoriteCase_requisitions_drivingSlip_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
}

export interface FavoriteCase_favoriteCase_requisitions_drivingSlip_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
}

export interface FavoriteCase_favoriteCase_requisitions_drivingSlip_case_damage {
  /**
   * Damage category
   */
  category: FavoriteCase_favoriteCase_requisitions_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: FavoriteCase_favoriteCase_requisitions_drivingSlip_case_damage_cause;
}

export interface FavoriteCase_favoriteCase_requisitions_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: FavoriteCase_favoriteCase_requisitions_drivingSlip_case_damage;
}

export interface FavoriteCase_favoriteCase_requisitions_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Case which driving slip is related to
   */
  case: FavoriteCase_favoriteCase_requisitions_drivingSlip_case;
}

export interface FavoriteCase_favoriteCase_requisitions_vendor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface FavoriteCase_favoriteCase_requisitions_vendor {
  /**
   * ERP reference no for vendor
   */
  erpReferenceNo: string;
  /**
   * The name of the vendor
   */
  company: string | null;
  /**
   * The address of the vendor
   */
  address: FavoriteCase_favoriteCase_requisitions_vendor_address | null;
  /**
   * The email of the vendor
   */
  email: string | null;
  /**
   * The phone number of the vendor
   */
  phone: string | null;
  /**
   * The posting group of the vendor
   */
  vendorPostingGroup: string;
  /**
   * Attached SSG department of the vendor
   */
  ssgDepartment: string;
  /**
   * Attached SSG location of the vendor
   */
  ssgLocation: string;
  /**
   * Vendor type
   */
  type: string;
}

export interface FavoriteCase_favoriteCase_requisitions_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface FavoriteCase_favoriteCase_requisitions {
  /**
   * id of the requisition
   */
  id: string;
  /**
   * case owning the requisition
   */
  case: FavoriteCase_favoriteCase_requisitions_case | null;
  /**
   * location owning the requisition
   */
  department: FavoriteCase_favoriteCase_requisitions_department | null;
  /**
   * driving slip owning the requisition
   */
  drivingSlip: FavoriteCase_favoriteCase_requisitions_drivingSlip | null;
  /**
   * description of the requisition
   */
  description: string;
  /**
   * orderNumber of the requisition
   */
  orderNumber: string;
  /**
   * the type of the requisition
   */
  type: RequisitionType;
  /**
   * Requisition status
   */
  status: boolean;
  /**
   * the vendor of the requisition
   */
  vendor: FavoriteCase_favoriteCase_requisitions_vendor | null;
  /**
   * Timestamp of when the requisition was created
   */
  createdAt: GQL_DateTime;
  /**
   * User who created the requisition
   */
  createdBy: FavoriteCase_favoriteCase_requisitions_createdBy;
  /**
   * email of the vendor
   */
  vendorEmail: string | null;
  /**
   * phone number of the vendor
   */
  vendorPhoneNumber: string;
  /**
   * name of the vendor
   */
  vendorName: string;
}

export interface FavoriteCase_favoriteCase {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * ReportSent
   */
  reportSent: boolean;
  /**
   * Distance from ssg location to damage address
   */
  distance: string | null;
  /**
   * Whether notifications regarding machines on the case should be sent
   */
  machineNotification: boolean | null;
  /**
   * Whether time registration is allowed on the case
   */
  timeRegistration: boolean | null;
  /**
   * Case debitor details
   */
  debitor: FavoriteCase_favoriteCase_debitor;
  /**
   * Case damage details
   */
  damage: FavoriteCase_favoriteCase_damage;
  /**
   * Case policy holder details
   */
  policyHolder: FavoriteCase_favoriteCase_policyHolder;
  /**
   * Case visitation details
   */
  visitation: FavoriteCase_favoriteCase_visitation;
  /**
   * Case requisitioner details
   */
  requisitioner: FavoriteCase_favoriteCase_requisitioner;
  /**
   * Case advisor details
   */
  adviser: FavoriteCase_favoriteCase_adviser | null;
  /**
   * Case referer details
   */
  referrer: FavoriteCase_favoriteCase_referrer | null;
  /**
   * Project manager attached to case
   */
  projectManager: FavoriteCase_favoriteCase_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: FavoriteCase_favoriteCase_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: FavoriteCase_favoriteCase_ssgLocation;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: FavoriteCase_favoriteCase_ssgDepartment;
  /**
   * Case ready for billing
   */
  billingReady: boolean;
  /**
   * Case work complete
   */
  workComplete: boolean;
  /**
   * List of case agreements
   */
  caseAgreements: FavoriteCase_favoriteCase_caseAgreements[];
  /**
   * List of case emails
   */
  caseEmails: FavoriteCase_favoriteCase_caseEmails[];
  /**
   * List of user favorite entries
   */
  userFavorite: FavoriteCase_favoriteCase_userFavorite | null;
  /**
   * User who created the case
   */
  createdBy: FavoriteCase_favoriteCase_createdBy | null;
  /**
   * Timestamp of when the case was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the case was created
   */
  createdAt: GQL_DateTime;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * The tasks associated with the task
   */
  jobs: FavoriteCase_favoriteCase_jobs[] | null;
  /**
   * The driving slips series associated with the case
   */
  drivingSlipSeries: FavoriteCase_favoriteCase_drivingSlipSeries[];
  /**
   * Important info on the case
   */
  importantInfo: string | null;
  /**
   * Alternative contact
   */
  alternativeContact: FavoriteCase_favoriteCase_alternativeContact | null;
  /**
   * Risk evaluation answers
   */
  riskEvaluationAnswers: FavoriteCase_favoriteCase_riskEvaluationAnswers[] | null;
  /**
   * Optional comment for risk evaluation
   */
  riskEvaluationComment: string | null;
  /**
   * Requisition of the case
   */
  requisitions: FavoriteCase_favoriteCase_requisitions[];
  scalePointStatus: ScalePointStatus;
  scalePointErrorMessage: string | null;
  arrived: boolean;
  arrivedDate: GQL_DateTime | null;
}

export interface FavoriteCase {
  favoriteCase: FavoriteCase_favoriteCase;
}

export interface FavoriteCaseVariables {
  id: string;
  color?: string | null;
  active: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCaseAdminDataPlanner
// ====================================================

export interface GetCaseAdminDataPlanner_damageCategories {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetCaseAdminDataPlanner_damageCauses_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetCaseAdminDataPlanner_damageCauses_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetCaseAdminDataPlanner_damageCauses_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetCaseAdminDataPlanner_damageCauses_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: GetCaseAdminDataPlanner_damageCauses_departments_location;
}

export interface GetCaseAdminDataPlanner_damageCauses {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: GetCaseAdminDataPlanner_damageCauses_businessArea;
  /**
   * Id of associated category
   */
  category: GetCaseAdminDataPlanner_damageCauses_category;
  /**
   * Departments associated to damage cause
   */
  departments: (GetCaseAdminDataPlanner_damageCauses_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface GetCaseAdminDataPlanner_departments_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetCaseAdminDataPlanner_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: GetCaseAdminDataPlanner_departments_location_address;
}

export interface GetCaseAdminDataPlanner_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: GetCaseAdminDataPlanner_departments_location;
}

export interface GetCaseAdminDataPlanner_locations_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetCaseAdminDataPlanner_locations {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: GetCaseAdminDataPlanner_locations_address;
  /**
   * Email attached to location
   */
  email: string;
  /**
   * Input string to resolve attached postal codes
   */
  attachedPostalCodesInputString: string;
  /**
   * Attached postal codes on location
   */
  attachedPostalCodes: string[];
}

export interface GetCaseAdminDataPlanner_usersWithCalendars {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
  /**
   * User planner help text
   */
  plannerHelpText: string | null;
  /**
   * User planner help text updated at timestamp
   */
  plannerHelpTextUpdatedAt: GQL_DateTime | null;
}

export interface GetCaseAdminDataPlanner_drivingSlipCategories {
  /**
   * Category code
   */
  code: string;
  /**
   * Category name
   */
  name: string;
}

export interface GetCaseAdminDataPlanner {
  damageCategories: GetCaseAdminDataPlanner_damageCategories[];
  damageCauses: GetCaseAdminDataPlanner_damageCauses[];
  departments: GetCaseAdminDataPlanner_departments[];
  locations: GetCaseAdminDataPlanner_locations[];
  usersWithCalendars: GetCaseAdminDataPlanner_usersWithCalendars[];
  drivingSlipCategories: GetCaseAdminDataPlanner_drivingSlipCategories[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CaseEconomicsOverview
// ====================================================

export interface CaseEconomicsOverview_caseEconomicsOverview {
  /**
   * JobNo
   */
  jobNo: string;
  /**
   * costTotal
   */
  costTotal: GQL_Decimal;
  /**
   * salesTotal
   */
  salesTotal: GQL_Decimal;
  /**
   * invoicedTotal
   */
  invoicedTotal: GQL_Decimal;
  /**
   * quote
   */
  quote: GQL_Decimal;
  /**
   * screening
   */
  screening: GQL_Decimal;
  /**
   * profit
   */
  profit: GQL_Decimal;
  /**
   * deltaNotInvoiced
   */
  deltaNotInvoiced: GQL_Decimal;
  /**
   * coverageRatio
   */
  coverageRatio: GQL_Decimal;
  /**
   * totalContractAmountLCY
   */
  totalContractAmountLCY: GQL_Decimal;
  /**
   * SSG Price / WIP Limit
   */
  wipLimit: GQL_Decimal;
  /**
   * Cost estimate
   */
  costEstimate: GQL_Decimal;
  /**
   * percentageOfCompletion
   */
  percentageOfCompletion: GQL_Decimal;
}

export interface CaseEconomicsOverview {
  caseEconomicsOverview: CaseEconomicsOverview_caseEconomicsOverview;
}

export interface CaseEconomicsOverviewVariables {
  caseERPReferenceNo: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCaseEconomySpec
// ====================================================

export interface GetCaseEconomySpec_caseEconomicsSpec_cost {
  /**
   * name of keyvaluepair
   */
  name: string;
  /**
   * Case economics value object
   */
  value: GQL_Decimal;
}

export interface GetCaseEconomySpec_caseEconomicsSpec_sale {
  /**
   * name of keyvaluepair
   */
  name: string;
  /**
   * Case economics value object
   */
  value: GQL_Decimal;
}

export interface GetCaseEconomySpec_caseEconomicsSpec {
  /**
   * hours Cost
   */
  cost: GetCaseEconomySpec_caseEconomicsSpec_cost[];
  /**
   * Hours posted sales
   */
  sale: GetCaseEconomySpec_caseEconomicsSpec_sale[];
}

export interface GetCaseEconomySpec {
  caseEconomicsSpec: GetCaseEconomySpec_caseEconomicsSpec;
}

export interface GetCaseEconomySpecVariables {
  erpReferenceId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCaseESDHFiles
// ====================================================

export interface GetCaseESDHFiles_case_documents_metadata {
  /**
   * KVP key
   */
  key: string;
  /**
   * KVP value
   */
  value: string;
}

export interface GetCaseESDHFiles_case_documents {
  /**
   * File name
   */
  name: string;
  /**
   * File url from ESDH
   */
  url: string;
  /**
   * File thumbnail URL
   */
  thumbnail: string;
  /**
   * File extension
   */
  extension: string;
  /**
   * File grouping key
   */
  groupingKey: string;
  /**
   * File created
   */
  created: GQL_DateTime;
  /**
   * File metadata from ESDH
   */
  metadata: GetCaseESDHFiles_case_documents_metadata[];
  /**
   * File comments from ESDH
   */
  comments: string | null;
  /**
   * File extension
   */
  fileId: string;
}

export interface GetCaseESDHFiles_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * List of document file names and thumbnails
   */
  documents: GetCaseESDHFiles_case_documents[];
}

export interface GetCaseESDHFiles {
  case: GetCaseESDHFiles_case;
}

export interface GetCaseESDHFilesVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCaseForMovable
// ====================================================

export interface GetCaseForMovable_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference no
   */
  erpNo: string;
}

export interface GetCaseForMovable {
  case: GetCaseForMovable_case;
}

export interface GetCaseForMovableVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCaseForPrint
// ====================================================

export interface GetCaseForPrint_case_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
}

export interface GetCaseForPrint_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetCaseForPrint_case_debitor;
}

export interface GetCaseForPrint {
  case: GetCaseForPrint_case;
}

export interface GetCaseForPrintVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCaseMovables
// ====================================================

export interface GetCaseMovables_case_movables_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface GetCaseMovables_case_movables {
  /**
   * Unique id of movable
   */
  id: string;
  /**
   * Description of movable
   */
  description: string;
  /**
   * Movable status
   */
  status: string;
  /**
   * Movable placement
   */
  placement: GetCaseMovables_case_movables_placement;
}

export interface GetCaseMovables_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Movables assiciated with the task
   */
  movables: GetCaseMovables_case_movables[];
}

export interface GetCaseMovables {
  case: GetCaseMovables_case;
}

export interface GetCaseMovablesVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCasesForExport
// ====================================================

export interface GetCasesForExport_cases_debitor {
  /**
   * Company identifier
   */
  company: string;
  /**
   * Insurance police number
   */
  policeNumber: string;
}

export interface GetCasesForExport_cases_damage_businessArea {
  /**
   * Name of business area
   */
  name: string;
}

export interface GetCasesForExport_cases_damage_category {
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetCasesForExport_cases_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
}

export interface GetCasesForExport_cases_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetCasesForExport_cases_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetCasesForExport_cases_damage_contact_address;
}

export interface GetCasesForExport_cases_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: GetCasesForExport_cases_damage_businessArea;
  /**
   * Damage category
   */
  category: GetCasesForExport_cases_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetCasesForExport_cases_damage_cause;
  /**
   * Damage location contact information
   */
  contact: GetCasesForExport_cases_damage_contact;
}

export interface GetCasesForExport_cases_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetCasesForExport_cases_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetCasesForExport_cases_ssgLocation {
  /**
   * Name of location
   */
  name: string;
}

export interface GetCasesForExport_cases_ssgDepartment {
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface GetCasesForExport_cases_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetCasesForExport_cases_visitation_calledBack {
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetCasesForExport_cases_visitation_awaiting {
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetCasesForExport_cases_visitation {
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If there has been called back
   */
  calledBack: GetCasesForExport_cases_visitation_calledBack;
  /**
   * If case needs a moisture report
   */
  isMoistureReportRequired: boolean | null;
  /**
   * If case needs an SP phase one report
   */
  isSpPhaseOneReportCreated: boolean | null;
  /**
   * If case is awaiting
   */
  awaiting: GetCasesForExport_cases_visitation_awaiting;
}

export interface GetCasesForExport_cases_caseEconomics {
  /**
   * salesTotal
   */
  salesTotal: GQL_Decimal;
  /**
   * invoicedTotal
   */
  invoicedTotal: GQL_Decimal;
}

export interface GetCasesForExport_cases_caseReports_inspectionReportFormData {
  __typename: "InspectionReportFormData";
}

export interface GetCasesForExport_cases_caseReports_indoorClimateReportFormData {
  __typename: "IndoorClimateReportFormData";
}

export interface GetCasesForExport_cases_caseReports_moistureReportFormData {
  __typename: "MoistureReportFormData";
}

export interface GetCasesForExport_cases_caseReports_moistureTrygReportFormData {
  __typename: "MoistureTrygReportFormData";
}

export interface GetCasesForExport_cases_caseReports {
  /**
   * Inspection report form data populated by the user
   */
  inspectionReportFormData: GetCasesForExport_cases_caseReports_inspectionReportFormData | null;
  /**
   * Indooor climate report form data populated by the user
   */
  indoorClimateReportFormData: GetCasesForExport_cases_caseReports_indoorClimateReportFormData | null;
  /**
   * Moisture report form data populated by the user
   */
  moistureReportFormData: GetCasesForExport_cases_caseReports_moistureReportFormData | null;
  /**
   * Moisture TRYG report form data populated by the user
   */
  moistureTrygReportFormData: GetCasesForExport_cases_caseReports_moistureTrygReportFormData | null;
}

export interface GetCasesForExport_cases {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * Case debitor details
   */
  debitor: GetCasesForExport_cases_debitor;
  /**
   * Case damage details
   */
  damage: GetCasesForExport_cases_damage;
  /**
   * Project manager attached to case
   */
  projectManager: GetCasesForExport_cases_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: GetCasesForExport_cases_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: GetCasesForExport_cases_ssgLocation;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: GetCasesForExport_cases_ssgDepartment;
  /**
   * User who created the case
   */
  createdBy: GetCasesForExport_cases_createdBy | null;
  /**
   * Timestamp of when the case was created
   */
  createdAt: GQL_DateTime;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * Case visitation details
   */
  visitation: GetCasesForExport_cases_visitation;
  caseEconomics: GetCasesForExport_cases_caseEconomics | null;
  arrived: boolean;
  arrivedDate: GQL_DateTime | null;
  /**
   * Active requisitions on case
   */
  activeRequisitions: boolean;
  caseReports: GetCasesForExport_cases_caseReports | null;
}

export interface GetCasesForExport {
  cases: GetCasesForExport_cases[];
}

export interface GetCasesForExportVariables {
  address?: AddressInput | null;
  awaiting?: boolean | null;
  damageServiceCompleted?: boolean | null;
  calledBack?: boolean | null;
  machinesOnCase?: boolean | null;
  ownCases?: boolean | null;
  favoriteCases?: boolean | null;
  appliedClosed?: boolean | null;
  closedCases?: boolean | null;
  debitor?: string[] | null;
  postalCode?: string[] | null;
  track?: number[] | null;
  department?: string[] | null;
  location?: string[] | null;
  damageCategory?: string[] | null;
  damageCause?: string[] | null;
  priority?: CasePriority[] | null;
  caseManager?: string[] | null;
  projectManager?: string[] | null;
  anyManager?: string | null;
  minDate?: GQL_Date | null;
  maxDate?: GQL_Date | null;
  erpReferenceNo?: string | null;
  erpReferenceNos?: string[] | null;
  favoriteColors?: string[] | null;
  includeEconomy: boolean;
  offset?: number | null;
  limit?: number | null;
  searchString?: string | null;
  excludeTotalCount?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCasesWithChanges
// ====================================================

export interface GetCasesWithChanges_cases_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetCasesWithChanges_cases_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetCasesWithChanges_cases_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: GetCasesWithChanges_cases_debitor_shippingAddress_address;
}

export interface GetCasesWithChanges_cases_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetCasesWithChanges_cases_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: GetCasesWithChanges_cases_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: GetCasesWithChanges_cases_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: GetCasesWithChanges_cases_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface GetCasesWithChanges_cases_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetCasesWithChanges_cases_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetCasesWithChanges_cases_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetCasesWithChanges_cases_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetCasesWithChanges_cases_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetCasesWithChanges_cases_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: GetCasesWithChanges_cases_damage_cause_departments_location;
}

export interface GetCasesWithChanges_cases_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: GetCasesWithChanges_cases_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: GetCasesWithChanges_cases_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (GetCasesWithChanges_cases_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface GetCasesWithChanges_cases_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetCasesWithChanges_cases_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetCasesWithChanges_cases_damage_contact_address;
}

export interface GetCasesWithChanges_cases_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface GetCasesWithChanges_cases_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: GetCasesWithChanges_cases_damage_businessArea;
  /**
   * Damage category
   */
  category: GetCasesWithChanges_cases_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetCasesWithChanges_cases_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: GetCasesWithChanges_cases_damage_contact;
  /**
   * contacts for damage
   */
  contacts: GetCasesWithChanges_cases_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface GetCasesWithChanges_cases_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetCasesWithChanges_cases_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetCasesWithChanges_cases_policyHolder_address;
}

export interface GetCasesWithChanges_cases_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetCasesWithChanges_cases_visitation_awaiting {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetCasesWithChanges_cases_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
  /**
   * The question
   */
  title: string;
}

export interface GetCasesWithChanges_cases_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: GetCasesWithChanges_cases_visitation_calledBack;
  /**
   * If case is awaiting
   */
  awaiting: GetCasesWithChanges_cases_visitation_awaiting;
  /**
   * A set of answers related to the damage cause
   */
  answers: GetCasesWithChanges_cases_visitation_answers[] | null;
  /**
   * If case needs a moisture report
   */
  isMoistureReportRequired: boolean | null;
  /**
   * If case needs an SP phase one report
   */
  isSpPhaseOneReportCreated: boolean | null;
}

export interface GetCasesWithChanges_cases_requisitioner {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Requisitioner relation
   */
  relation: string;
}

export interface GetCasesWithChanges_cases_adviser {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface GetCasesWithChanges_cases_referrer {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface GetCasesWithChanges_cases_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetCasesWithChanges_cases_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetCasesWithChanges_cases_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetCasesWithChanges_cases_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: GetCasesWithChanges_cases_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface GetCasesWithChanges_cases_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetCasesWithChanges_cases_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: GetCasesWithChanges_cases_ssgDepartment_location_address;
}

export interface GetCasesWithChanges_cases_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: GetCasesWithChanges_cases_ssgDepartment_location;
}

export interface GetCasesWithChanges_cases_caseAgreements_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetCasesWithChanges_cases_caseAgreements {
  /**
   * Agreement content
   */
  content: string;
  /**
   * Agreement category
   */
  category: CaseAgreementCategory;
  /**
   * User who created agreement
   */
  user: GetCasesWithChanges_cases_caseAgreements_user;
  /**
   * Timestamp of when agreement was created
   */
  timestamp: GQL_DateTime;
  /**
   * Whether case agreement should be sent to ScalePoint
   */
  shouldSaveInScalePoint: boolean;
}

export interface GetCasesWithChanges_cases_caseEmails {
  /**
   * Email content
   */
  content: string;
  /**
   * List of email attachments
   */
  attachments: string[];
  /**
   * List of email receivers
   */
  receivers: string[];
  /**
   * Timestamp of when email was created
   */
  timestamp: GQL_DateTime;
}

export interface GetCasesWithChanges_cases_userFavorite {
  /**
   * Unique user id
   */
  user: string;
  /**
   * Color code set by user
   */
  color: string | null;
  /**
   * Favorite active status
   */
  active: boolean;
}

export interface GetCasesWithChanges_cases_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetCasesWithChanges_cases_jobs_assignedTo {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
}

export interface GetCasesWithChanges_cases_jobs_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetCasesWithChanges_cases_jobs_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
}

export interface GetCasesWithChanges_cases_jobs_movable_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface GetCasesWithChanges_cases_jobs_movable {
  /**
   * Unique id of movable
   */
  id: string;
  /**
   * Description of movable
   */
  description: string;
  /**
   * Movable status
   */
  status: string;
  /**
   * Movable placement
   */
  placement: GetCasesWithChanges_cases_jobs_movable_placement;
}

export interface GetCasesWithChanges_cases_jobs {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Type of case task
   */
  type: JobType;
  /**
   * Description of the case task
   */
  description: string;
  /**
   * Deadline of the case task
   */
  deadline: GQL_DateTime;
  /**
   * The user responsible for the task
   */
  assignedTo: GetCasesWithChanges_cases_jobs_assignedTo | null;
  /**
   * The location responsible to the task
   */
  location: GetCasesWithChanges_cases_jobs_location | null;
  /**
   * The case the task belongs to
   */
  case: GetCasesWithChanges_cases_jobs_case | null;
  /**
   * The movable responsible to the task
   */
  movable: GetCasesWithChanges_cases_jobs_movable | null;
  /**
   * Whether the task is completed
   */
  completed: boolean;
}

export interface GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_changes_user;
  /**
   * Value before
   */
  before: GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_changes_before;
  /**
   * Value after
   */
  after: GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_changes_after;
}

export interface GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_changes[];
  /**
   * Location of driving slip
   */
  location: GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_location;
  /**
   * Department of driving slip
   */
  department: GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
}

export interface GetCasesWithChanges_cases_drivingSlipSeries {
  /**
   * Unique id of driving slip series
   */
  id: string;
  /**
   * Driving slip series completed status
   */
  completed: boolean;
  /**
   * Driving slips on series
   */
  drivingSlips: GetCasesWithChanges_cases_drivingSlipSeries_drivingSlips[];
}

export interface GetCasesWithChanges_cases_alternativeContact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Comment on person
   */
  comment: string | null;
}

export interface GetCasesWithChanges_cases_riskEvaluationAnswers_question {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question
   */
  question: string;
}

export interface GetCasesWithChanges_cases_riskEvaluationAnswers {
  /**
   * Id of question answer is linked to
   */
  question: GetCasesWithChanges_cases_riskEvaluationAnswers_question;
  /**
   * Answer given
   */
  answer: boolean;
}

export interface GetCasesWithChanges_cases_requisitions_case_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
}

export interface GetCasesWithChanges_cases_requisitions_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
  /**
   * Id of damage category
   */
  id: string;
}

export interface GetCasesWithChanges_cases_requisitions_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of damage cause
   */
  id: string;
}

export interface GetCasesWithChanges_cases_requisitions_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetCasesWithChanges_cases_requisitions_case_damage_contact {
  /**
   * Contact address information
   */
  address: GetCasesWithChanges_cases_requisitions_case_damage_contact_address;
}

export interface GetCasesWithChanges_cases_requisitions_case_damage {
  /**
   * Damage category
   */
  category: GetCasesWithChanges_cases_requisitions_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetCasesWithChanges_cases_requisitions_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: GetCasesWithChanges_cases_requisitions_case_damage_contact;
}

export interface GetCasesWithChanges_cases_requisitions_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetCasesWithChanges_cases_requisitions_case_debitor;
  /**
   * Case damage details
   */
  damage: GetCasesWithChanges_cases_requisitions_case_damage;
}

export interface GetCasesWithChanges_cases_requisitions_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
}

export interface GetCasesWithChanges_cases_requisitions_drivingSlip_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetCasesWithChanges_cases_requisitions_drivingSlip_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
}

export interface GetCasesWithChanges_cases_requisitions_drivingSlip_case_damage {
  /**
   * Damage category
   */
  category: GetCasesWithChanges_cases_requisitions_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetCasesWithChanges_cases_requisitions_drivingSlip_case_damage_cause;
}

export interface GetCasesWithChanges_cases_requisitions_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: GetCasesWithChanges_cases_requisitions_drivingSlip_case_damage;
}

export interface GetCasesWithChanges_cases_requisitions_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Case which driving slip is related to
   */
  case: GetCasesWithChanges_cases_requisitions_drivingSlip_case;
}

export interface GetCasesWithChanges_cases_requisitions_vendor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetCasesWithChanges_cases_requisitions_vendor {
  /**
   * ERP reference no for vendor
   */
  erpReferenceNo: string;
  /**
   * The name of the vendor
   */
  company: string | null;
  /**
   * The address of the vendor
   */
  address: GetCasesWithChanges_cases_requisitions_vendor_address | null;
  /**
   * The email of the vendor
   */
  email: string | null;
  /**
   * The phone number of the vendor
   */
  phone: string | null;
  /**
   * The posting group of the vendor
   */
  vendorPostingGroup: string;
  /**
   * Attached SSG department of the vendor
   */
  ssgDepartment: string;
  /**
   * Attached SSG location of the vendor
   */
  ssgLocation: string;
  /**
   * Vendor type
   */
  type: string;
}

export interface GetCasesWithChanges_cases_requisitions_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface GetCasesWithChanges_cases_requisitions {
  /**
   * id of the requisition
   */
  id: string;
  /**
   * case owning the requisition
   */
  case: GetCasesWithChanges_cases_requisitions_case | null;
  /**
   * location owning the requisition
   */
  department: GetCasesWithChanges_cases_requisitions_department | null;
  /**
   * driving slip owning the requisition
   */
  drivingSlip: GetCasesWithChanges_cases_requisitions_drivingSlip | null;
  /**
   * description of the requisition
   */
  description: string;
  /**
   * orderNumber of the requisition
   */
  orderNumber: string;
  /**
   * the type of the requisition
   */
  type: RequisitionType;
  /**
   * Requisition status
   */
  status: boolean;
  /**
   * the vendor of the requisition
   */
  vendor: GetCasesWithChanges_cases_requisitions_vendor | null;
  /**
   * Timestamp of when the requisition was created
   */
  createdAt: GQL_DateTime;
  /**
   * User who created the requisition
   */
  createdBy: GetCasesWithChanges_cases_requisitions_createdBy;
  /**
   * email of the vendor
   */
  vendorEmail: string | null;
  /**
   * phone number of the vendor
   */
  vendorPhoneNumber: string;
  /**
   * name of the vendor
   */
  vendorName: string;
}

export interface GetCasesWithChanges_cases_changes_after {
  /**
   * Case status
   */
  status: CaseStatus | null;
}

export interface GetCasesWithChanges_cases_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetCasesWithChanges_cases_changes {
  /**
   * Value after
   */
  after: GetCasesWithChanges_cases_changes_after;
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: GetCasesWithChanges_cases_changes_user | null;
}

export interface GetCasesWithChanges_cases_caseEconomics {
  /**
   * JobNo
   */
  jobNo: string;
  /**
   * costTotal
   */
  costTotal: GQL_Decimal;
  /**
   * salesTotal
   */
  salesTotal: GQL_Decimal;
  /**
   * invoicedTotal
   */
  invoicedTotal: GQL_Decimal;
  /**
   * quote
   */
  quote: GQL_Decimal;
  /**
   * screening
   */
  screening: GQL_Decimal;
  /**
   * profit
   */
  profit: GQL_Decimal;
  /**
   * deltaNotInvoiced
   */
  deltaNotInvoiced: GQL_Decimal;
  /**
   * coverageRatio
   */
  coverageRatio: GQL_Decimal;
}

export interface GetCasesWithChanges_cases {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * ReportSent
   */
  reportSent: boolean;
  /**
   * Distance from ssg location to damage address
   */
  distance: string | null;
  /**
   * Whether notifications regarding machines on the case should be sent
   */
  machineNotification: boolean | null;
  /**
   * Whether time registration is allowed on the case
   */
  timeRegistration: boolean | null;
  /**
   * Case debitor details
   */
  debitor: GetCasesWithChanges_cases_debitor;
  /**
   * Case damage details
   */
  damage: GetCasesWithChanges_cases_damage;
  /**
   * Case policy holder details
   */
  policyHolder: GetCasesWithChanges_cases_policyHolder;
  /**
   * Case visitation details
   */
  visitation: GetCasesWithChanges_cases_visitation;
  /**
   * Case requisitioner details
   */
  requisitioner: GetCasesWithChanges_cases_requisitioner;
  /**
   * Case advisor details
   */
  adviser: GetCasesWithChanges_cases_adviser | null;
  /**
   * Case referer details
   */
  referrer: GetCasesWithChanges_cases_referrer | null;
  /**
   * Project manager attached to case
   */
  projectManager: GetCasesWithChanges_cases_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: GetCasesWithChanges_cases_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: GetCasesWithChanges_cases_ssgLocation;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: GetCasesWithChanges_cases_ssgDepartment;
  /**
   * Case ready for billing
   */
  billingReady: boolean;
  /**
   * Case work complete
   */
  workComplete: boolean;
  /**
   * List of case agreements
   */
  caseAgreements: GetCasesWithChanges_cases_caseAgreements[];
  /**
   * List of case emails
   */
  caseEmails: GetCasesWithChanges_cases_caseEmails[];
  /**
   * List of user favorite entries
   */
  userFavorite: GetCasesWithChanges_cases_userFavorite | null;
  /**
   * User who created the case
   */
  createdBy: GetCasesWithChanges_cases_createdBy | null;
  /**
   * Timestamp of when the case was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the case was created
   */
  createdAt: GQL_DateTime;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * The tasks associated with the task
   */
  jobs: GetCasesWithChanges_cases_jobs[] | null;
  /**
   * The driving slips series associated with the case
   */
  drivingSlipSeries: GetCasesWithChanges_cases_drivingSlipSeries[];
  /**
   * Important info on the case
   */
  importantInfo: string | null;
  /**
   * Alternative contact
   */
  alternativeContact: GetCasesWithChanges_cases_alternativeContact | null;
  /**
   * Risk evaluation answers
   */
  riskEvaluationAnswers: GetCasesWithChanges_cases_riskEvaluationAnswers[] | null;
  /**
   * Optional comment for risk evaluation
   */
  riskEvaluationComment: string | null;
  /**
   * Requisition of the case
   */
  requisitions: GetCasesWithChanges_cases_requisitions[];
  scalePointStatus: ScalePointStatus;
  scalePointErrorMessage: string | null;
  arrived: boolean;
  arrivedDate: GQL_DateTime | null;
  /**
   * Case change log
   */
  changes: GetCasesWithChanges_cases_changes[];
  caseEconomics: GetCasesWithChanges_cases_caseEconomics | null;
}

export interface GetCasesWithChanges {
  cases: GetCasesWithChanges_cases[];
}

export interface GetCasesWithChangesVariables {
  address?: AddressInput | null;
  awaiting?: boolean | null;
  damageServiceCompleted?: boolean | null;
  calledBack?: boolean | null;
  machinesOnCase?: boolean | null;
  ownCases?: boolean | null;
  appliedClosed?: boolean | null;
  closedCases?: boolean | null;
  debitor?: string[] | null;
  postalCode?: string[] | null;
  track?: number[] | null;
  department?: string[] | null;
  location?: string[] | null;
  damageCategory?: string[] | null;
  damageCause?: string[] | null;
  priority?: CasePriority[] | null;
  caseManager?: string[] | null;
  projectManager?: string[] | null;
  anyManager?: string | null;
  minDate?: GQL_Date | null;
  maxDate?: GQL_Date | null;
  erpReferenceNo?: string | null;
  erpReferenceNos?: string[] | null;
  includeEconomy: boolean;
  offset?: number | null;
  limit?: number | null;
  searchString?: string | null;
  excludeTotalCount?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCaseUsage
// ====================================================

export interface GetCaseUsage_caseUsage_subcontracts {
  /**
   * ERP system reference id
   */
  erpId: string;
  /**
   * ERP system reference key
   */
  erpKey: string;
  /**
   * Usage line title
   */
  title: string;
  /**
   * Usage line quantity
   */
  quantity: GQL_Decimal;
  /**
   * Usage line unit
   */
  unit: string;
  /**
   * Usage line cost per unit
   */
  lineCost: GQL_Decimal;
  /**
   * Usage line total cost
   */
  totalCost: GQL_Decimal;
  /**
   * Usage line attachments
   */
  attachments: string[];
  /**
   * Name of ressource
   */
  ressourceName: string;
  /**
   * Date of posting
   */
  postingDate: string;
  /**
   * Original date of posting
   */
  originalPostingDate: string;
}

export interface GetCaseUsage_caseUsage_items {
  /**
   * ERP system reference id
   */
  erpId: string;
  /**
   * ERP system reference key
   */
  erpKey: string;
  /**
   * Usage line title
   */
  title: string;
  /**
   * Usage line quantity
   */
  quantity: GQL_Decimal;
  /**
   * Usage line unit
   */
  unit: string;
  /**
   * Usage line cost per unit
   */
  lineCost: GQL_Decimal;
  /**
   * Usage line total cost
   */
  totalCost: GQL_Decimal;
  /**
   * Usage line attachments
   */
  attachments: string[];
  /**
   * Name of ressource
   */
  ressourceName: string;
  /**
   * Date of posting
   */
  postingDate: string;
  /**
   * Original date of posting
   */
  originalPostingDate: string;
}

export interface GetCaseUsage_caseUsage_machines {
  /**
   * ERP system reference id
   */
  erpId: string;
  /**
   * ERP system reference key
   */
  erpKey: string;
  /**
   * Usage line title
   */
  title: string;
  /**
   * Usage line quantity
   */
  quantity: GQL_Decimal;
  /**
   * Usage line unit
   */
  unit: string;
  /**
   * Usage line cost per unit
   */
  lineCost: GQL_Decimal;
  /**
   * Usage line total cost
   */
  totalCost: GQL_Decimal;
  /**
   * Usage line attachments
   */
  attachments: string[];
  /**
   * Name of ressource
   */
  ressourceName: string;
  /**
   * Date of posting
   */
  postingDate: string;
  /**
   * Original date of posting
   */
  originalPostingDate: string;
}

export interface GetCaseUsage_caseUsage_movables {
  /**
   * ERP system reference id
   */
  erpId: string;
  /**
   * ERP system reference key
   */
  erpKey: string;
  /**
   * Usage line title
   */
  title: string;
  /**
   * Usage line quantity
   */
  quantity: GQL_Decimal;
  /**
   * Usage line unit
   */
  unit: string;
  /**
   * Usage line cost per unit
   */
  lineCost: GQL_Decimal;
  /**
   * Usage line total cost
   */
  totalCost: GQL_Decimal;
  /**
   * Usage line attachments
   */
  attachments: string[];
  /**
   * Name of ressource
   */
  ressourceName: string;
  /**
   * Date of posting
   */
  postingDate: string;
  /**
   * Original date of posting
   */
  originalPostingDate: string;
}

export interface GetCaseUsage_caseUsage_persons {
  /**
   * ERP system reference id
   */
  erpId: string;
  /**
   * ERP system reference key
   */
  erpKey: string;
  /**
   * Usage line title
   */
  title: string;
  /**
   * Usage line quantity
   */
  quantity: GQL_Decimal;
  /**
   * Usage line unit
   */
  unit: string;
  /**
   * Usage line cost per unit
   */
  lineCost: GQL_Decimal;
  /**
   * Usage line total cost
   */
  totalCost: GQL_Decimal;
  /**
   * Usage line attachments
   */
  attachments: string[];
  /**
   * Name of ressource
   */
  ressourceName: string;
  /**
   * Date of posting
   */
  postingDate: string;
  /**
   * Original date of posting
   */
  originalPostingDate: string;
}

export interface GetCaseUsage_caseUsage_trash {
  /**
   * ERP system reference id
   */
  erpId: string;
  /**
   * ERP system reference key
   */
  erpKey: string;
  /**
   * Usage line title
   */
  title: string;
  /**
   * Usage line quantity
   */
  quantity: GQL_Decimal;
  /**
   * Usage line unit
   */
  unit: string;
  /**
   * Usage line cost per unit
   */
  lineCost: GQL_Decimal;
  /**
   * Usage line total cost
   */
  totalCost: GQL_Decimal;
  /**
   * Usage line attachments
   */
  attachments: string[];
  /**
   * Name of ressource
   */
  ressourceName: string;
  /**
   * Date of posting
   */
  postingDate: string;
  /**
   * Original date of posting
   */
  originalPostingDate: string;
}

export interface GetCaseUsage_caseUsage {
  /**
   * Case subcontracts usage
   */
  subcontracts: GetCaseUsage_caseUsage_subcontracts[];
  /**
   * Case items usage
   */
  items: GetCaseUsage_caseUsage_items[];
  /**
   * Case machines usage
   */
  machines: GetCaseUsage_caseUsage_machines[];
  /**
   * Case movables usage
   */
  movables: GetCaseUsage_caseUsage_movables[];
  /**
   * Case person/time usage
   */
  persons: GetCaseUsage_caseUsage_persons[];
  /**
   * Case trash usage
   */
  trash: GetCaseUsage_caseUsage_trash[];
}

export interface GetCaseUsage {
  /**
   * Case usage
   */
  caseUsage: GetCaseUsage_caseUsage;
}

export interface GetCaseUsageVariables {
  erpNo: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCaseUsageExcelOverview
// ====================================================

export interface GetCaseUsageExcelOverview {
  caseUsageExcelOverview: GQL_Byte[];
}

export interface GetCaseUsageExcelOverviewVariables {
  erpNo: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDamageAdminData
// ====================================================

export interface GetDamageAdminData_damageCategories {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetDamageAdminData_damageCauses_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetDamageAdminData_damageCauses_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetDamageAdminData_damageCauses_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetDamageAdminData_damageCauses_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: GetDamageAdminData_damageCauses_departments_location;
}

export interface GetDamageAdminData_damageCauses {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: GetDamageAdminData_damageCauses_businessArea;
  /**
   * Id of associated category
   */
  category: GetDamageAdminData_damageCauses_category;
  /**
   * Departments associated to damage cause
   */
  departments: (GetDamageAdminData_damageCauses_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface GetDamageAdminData {
  damageCategories: GetDamageAdminData_damageCategories[];
  damageCauses: GetDamageAdminData_damageCauses[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOperationManagerCaseData
// ====================================================

export interface GetOperationManagerCaseData_cases_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetOperationManagerCaseData_cases_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetOperationManagerCaseData_cases_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: GetOperationManagerCaseData_cases_debitor_shippingAddress_address;
}

export interface GetOperationManagerCaseData_cases_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetOperationManagerCaseData_cases_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: GetOperationManagerCaseData_cases_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: GetOperationManagerCaseData_cases_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: GetOperationManagerCaseData_cases_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface GetOperationManagerCaseData_cases_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetOperationManagerCaseData_cases_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetOperationManagerCaseData_cases_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetOperationManagerCaseData_cases_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetOperationManagerCaseData_cases_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetOperationManagerCaseData_cases_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: GetOperationManagerCaseData_cases_damage_cause_departments_location;
}

export interface GetOperationManagerCaseData_cases_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: GetOperationManagerCaseData_cases_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: GetOperationManagerCaseData_cases_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (GetOperationManagerCaseData_cases_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface GetOperationManagerCaseData_cases_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetOperationManagerCaseData_cases_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetOperationManagerCaseData_cases_damage_contact_address;
}

export interface GetOperationManagerCaseData_cases_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface GetOperationManagerCaseData_cases_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: GetOperationManagerCaseData_cases_damage_businessArea;
  /**
   * Damage category
   */
  category: GetOperationManagerCaseData_cases_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetOperationManagerCaseData_cases_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: GetOperationManagerCaseData_cases_damage_contact;
  /**
   * contacts for damage
   */
  contacts: GetOperationManagerCaseData_cases_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface GetOperationManagerCaseData_cases_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetOperationManagerCaseData_cases_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetOperationManagerCaseData_cases_policyHolder_address;
}

export interface GetOperationManagerCaseData_cases_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetOperationManagerCaseData_cases_visitation_awaiting {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetOperationManagerCaseData_cases_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
  /**
   * The question
   */
  title: string;
}

export interface GetOperationManagerCaseData_cases_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: GetOperationManagerCaseData_cases_visitation_calledBack;
  /**
   * If case is awaiting
   */
  awaiting: GetOperationManagerCaseData_cases_visitation_awaiting;
  /**
   * A set of answers related to the damage cause
   */
  answers: GetOperationManagerCaseData_cases_visitation_answers[] | null;
  /**
   * If case needs a moisture report
   */
  isMoistureReportRequired: boolean | null;
  /**
   * If case needs an SP phase one report
   */
  isSpPhaseOneReportCreated: boolean | null;
}

export interface GetOperationManagerCaseData_cases_requisitioner {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Requisitioner relation
   */
  relation: string;
}

export interface GetOperationManagerCaseData_cases_adviser {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface GetOperationManagerCaseData_cases_referrer {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface GetOperationManagerCaseData_cases_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetOperationManagerCaseData_cases_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetOperationManagerCaseData_cases_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetOperationManagerCaseData_cases_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: GetOperationManagerCaseData_cases_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface GetOperationManagerCaseData_cases_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetOperationManagerCaseData_cases_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: GetOperationManagerCaseData_cases_ssgDepartment_location_address;
}

export interface GetOperationManagerCaseData_cases_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: GetOperationManagerCaseData_cases_ssgDepartment_location;
}

export interface GetOperationManagerCaseData_cases_caseAgreements_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetOperationManagerCaseData_cases_caseAgreements {
  /**
   * Agreement content
   */
  content: string;
  /**
   * Agreement category
   */
  category: CaseAgreementCategory;
  /**
   * User who created agreement
   */
  user: GetOperationManagerCaseData_cases_caseAgreements_user;
  /**
   * Timestamp of when agreement was created
   */
  timestamp: GQL_DateTime;
  /**
   * Whether case agreement should be sent to ScalePoint
   */
  shouldSaveInScalePoint: boolean;
}

export interface GetOperationManagerCaseData_cases_caseEmails {
  /**
   * Email content
   */
  content: string;
  /**
   * List of email attachments
   */
  attachments: string[];
  /**
   * List of email receivers
   */
  receivers: string[];
  /**
   * Timestamp of when email was created
   */
  timestamp: GQL_DateTime;
}

export interface GetOperationManagerCaseData_cases_userFavorite {
  /**
   * Unique user id
   */
  user: string;
  /**
   * Color code set by user
   */
  color: string | null;
  /**
   * Favorite active status
   */
  active: boolean;
}

export interface GetOperationManagerCaseData_cases_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetOperationManagerCaseData_cases_jobs_assignedTo {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
}

export interface GetOperationManagerCaseData_cases_jobs_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetOperationManagerCaseData_cases_jobs_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
}

export interface GetOperationManagerCaseData_cases_jobs_movable_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface GetOperationManagerCaseData_cases_jobs_movable {
  /**
   * Unique id of movable
   */
  id: string;
  /**
   * Description of movable
   */
  description: string;
  /**
   * Movable status
   */
  status: string;
  /**
   * Movable placement
   */
  placement: GetOperationManagerCaseData_cases_jobs_movable_placement;
}

export interface GetOperationManagerCaseData_cases_jobs {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Type of case task
   */
  type: JobType;
  /**
   * Description of the case task
   */
  description: string;
  /**
   * Deadline of the case task
   */
  deadline: GQL_DateTime;
  /**
   * The user responsible for the task
   */
  assignedTo: GetOperationManagerCaseData_cases_jobs_assignedTo | null;
  /**
   * The location responsible to the task
   */
  location: GetOperationManagerCaseData_cases_jobs_location | null;
  /**
   * The case the task belongs to
   */
  case: GetOperationManagerCaseData_cases_jobs_case | null;
  /**
   * The movable responsible to the task
   */
  movable: GetOperationManagerCaseData_cases_jobs_movable | null;
  /**
   * Whether the task is completed
   */
  completed: boolean;
}

export interface GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_changes_user;
  /**
   * Value before
   */
  before: GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_changes_before;
  /**
   * Value after
   */
  after: GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_changes_after;
}

export interface GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_changes[];
  /**
   * Location of driving slip
   */
  location: GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_location;
  /**
   * Department of driving slip
   */
  department: GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
}

export interface GetOperationManagerCaseData_cases_drivingSlipSeries {
  /**
   * Unique id of driving slip series
   */
  id: string;
  /**
   * Driving slip series completed status
   */
  completed: boolean;
  /**
   * Driving slips on series
   */
  drivingSlips: GetOperationManagerCaseData_cases_drivingSlipSeries_drivingSlips[];
}

export interface GetOperationManagerCaseData_cases_alternativeContact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Comment on person
   */
  comment: string | null;
}

export interface GetOperationManagerCaseData_cases_riskEvaluationAnswers_question {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question
   */
  question: string;
}

export interface GetOperationManagerCaseData_cases_riskEvaluationAnswers {
  /**
   * Id of question answer is linked to
   */
  question: GetOperationManagerCaseData_cases_riskEvaluationAnswers_question;
  /**
   * Answer given
   */
  answer: boolean;
}

export interface GetOperationManagerCaseData_cases_requisitions_case_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
}

export interface GetOperationManagerCaseData_cases_requisitions_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
  /**
   * Id of damage category
   */
  id: string;
}

export interface GetOperationManagerCaseData_cases_requisitions_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of damage cause
   */
  id: string;
}

export interface GetOperationManagerCaseData_cases_requisitions_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetOperationManagerCaseData_cases_requisitions_case_damage_contact {
  /**
   * Contact address information
   */
  address: GetOperationManagerCaseData_cases_requisitions_case_damage_contact_address;
}

export interface GetOperationManagerCaseData_cases_requisitions_case_damage {
  /**
   * Damage category
   */
  category: GetOperationManagerCaseData_cases_requisitions_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetOperationManagerCaseData_cases_requisitions_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: GetOperationManagerCaseData_cases_requisitions_case_damage_contact;
}

export interface GetOperationManagerCaseData_cases_requisitions_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetOperationManagerCaseData_cases_requisitions_case_debitor;
  /**
   * Case damage details
   */
  damage: GetOperationManagerCaseData_cases_requisitions_case_damage;
}

export interface GetOperationManagerCaseData_cases_requisitions_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
}

export interface GetOperationManagerCaseData_cases_requisitions_drivingSlip_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetOperationManagerCaseData_cases_requisitions_drivingSlip_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
}

export interface GetOperationManagerCaseData_cases_requisitions_drivingSlip_case_damage {
  /**
   * Damage category
   */
  category: GetOperationManagerCaseData_cases_requisitions_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetOperationManagerCaseData_cases_requisitions_drivingSlip_case_damage_cause;
}

export interface GetOperationManagerCaseData_cases_requisitions_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: GetOperationManagerCaseData_cases_requisitions_drivingSlip_case_damage;
}

export interface GetOperationManagerCaseData_cases_requisitions_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Case which driving slip is related to
   */
  case: GetOperationManagerCaseData_cases_requisitions_drivingSlip_case;
}

export interface GetOperationManagerCaseData_cases_requisitions_vendor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetOperationManagerCaseData_cases_requisitions_vendor {
  /**
   * ERP reference no for vendor
   */
  erpReferenceNo: string;
  /**
   * The name of the vendor
   */
  company: string | null;
  /**
   * The address of the vendor
   */
  address: GetOperationManagerCaseData_cases_requisitions_vendor_address | null;
  /**
   * The email of the vendor
   */
  email: string | null;
  /**
   * The phone number of the vendor
   */
  phone: string | null;
  /**
   * The posting group of the vendor
   */
  vendorPostingGroup: string;
  /**
   * Attached SSG department of the vendor
   */
  ssgDepartment: string;
  /**
   * Attached SSG location of the vendor
   */
  ssgLocation: string;
  /**
   * Vendor type
   */
  type: string;
}

export interface GetOperationManagerCaseData_cases_requisitions_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface GetOperationManagerCaseData_cases_requisitions {
  /**
   * id of the requisition
   */
  id: string;
  /**
   * case owning the requisition
   */
  case: GetOperationManagerCaseData_cases_requisitions_case | null;
  /**
   * location owning the requisition
   */
  department: GetOperationManagerCaseData_cases_requisitions_department | null;
  /**
   * driving slip owning the requisition
   */
  drivingSlip: GetOperationManagerCaseData_cases_requisitions_drivingSlip | null;
  /**
   * description of the requisition
   */
  description: string;
  /**
   * orderNumber of the requisition
   */
  orderNumber: string;
  /**
   * the type of the requisition
   */
  type: RequisitionType;
  /**
   * Requisition status
   */
  status: boolean;
  /**
   * the vendor of the requisition
   */
  vendor: GetOperationManagerCaseData_cases_requisitions_vendor | null;
  /**
   * Timestamp of when the requisition was created
   */
  createdAt: GQL_DateTime;
  /**
   * User who created the requisition
   */
  createdBy: GetOperationManagerCaseData_cases_requisitions_createdBy;
  /**
   * email of the vendor
   */
  vendorEmail: string | null;
  /**
   * phone number of the vendor
   */
  vendorPhoneNumber: string;
  /**
   * name of the vendor
   */
  vendorName: string;
}

export interface GetOperationManagerCaseData_cases_changes_after {
  /**
   * Case status
   */
  status: CaseStatus | null;
}

export interface GetOperationManagerCaseData_cases_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetOperationManagerCaseData_cases_changes {
  /**
   * Value after
   */
  after: GetOperationManagerCaseData_cases_changes_after;
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: GetOperationManagerCaseData_cases_changes_user | null;
}

export interface GetOperationManagerCaseData_cases_caseEconomics {
  /**
   * JobNo
   */
  jobNo: string;
  /**
   * costTotal
   */
  costTotal: GQL_Decimal;
  /**
   * salesTotal
   */
  salesTotal: GQL_Decimal;
  /**
   * invoicedTotal
   */
  invoicedTotal: GQL_Decimal;
  /**
   * quote
   */
  quote: GQL_Decimal;
  /**
   * screening
   */
  screening: GQL_Decimal;
  /**
   * profit
   */
  profit: GQL_Decimal;
  /**
   * deltaNotInvoiced
   */
  deltaNotInvoiced: GQL_Decimal;
  /**
   * coverageRatio
   */
  coverageRatio: GQL_Decimal;
}

export interface GetOperationManagerCaseData_cases {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * ReportSent
   */
  reportSent: boolean;
  /**
   * Distance from ssg location to damage address
   */
  distance: string | null;
  /**
   * Whether notifications regarding machines on the case should be sent
   */
  machineNotification: boolean | null;
  /**
   * Whether time registration is allowed on the case
   */
  timeRegistration: boolean | null;
  /**
   * Case debitor details
   */
  debitor: GetOperationManagerCaseData_cases_debitor;
  /**
   * Case damage details
   */
  damage: GetOperationManagerCaseData_cases_damage;
  /**
   * Case policy holder details
   */
  policyHolder: GetOperationManagerCaseData_cases_policyHolder;
  /**
   * Case visitation details
   */
  visitation: GetOperationManagerCaseData_cases_visitation;
  /**
   * Case requisitioner details
   */
  requisitioner: GetOperationManagerCaseData_cases_requisitioner;
  /**
   * Case advisor details
   */
  adviser: GetOperationManagerCaseData_cases_adviser | null;
  /**
   * Case referer details
   */
  referrer: GetOperationManagerCaseData_cases_referrer | null;
  /**
   * Project manager attached to case
   */
  projectManager: GetOperationManagerCaseData_cases_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: GetOperationManagerCaseData_cases_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: GetOperationManagerCaseData_cases_ssgLocation;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: GetOperationManagerCaseData_cases_ssgDepartment;
  /**
   * Case ready for billing
   */
  billingReady: boolean;
  /**
   * Case work complete
   */
  workComplete: boolean;
  /**
   * List of case agreements
   */
  caseAgreements: GetOperationManagerCaseData_cases_caseAgreements[];
  /**
   * List of case emails
   */
  caseEmails: GetOperationManagerCaseData_cases_caseEmails[];
  /**
   * List of user favorite entries
   */
  userFavorite: GetOperationManagerCaseData_cases_userFavorite | null;
  /**
   * User who created the case
   */
  createdBy: GetOperationManagerCaseData_cases_createdBy | null;
  /**
   * Timestamp of when the case was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the case was created
   */
  createdAt: GQL_DateTime;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * The tasks associated with the task
   */
  jobs: GetOperationManagerCaseData_cases_jobs[] | null;
  /**
   * The driving slips series associated with the case
   */
  drivingSlipSeries: GetOperationManagerCaseData_cases_drivingSlipSeries[];
  /**
   * Important info on the case
   */
  importantInfo: string | null;
  /**
   * Alternative contact
   */
  alternativeContact: GetOperationManagerCaseData_cases_alternativeContact | null;
  /**
   * Risk evaluation answers
   */
  riskEvaluationAnswers: GetOperationManagerCaseData_cases_riskEvaluationAnswers[] | null;
  /**
   * Optional comment for risk evaluation
   */
  riskEvaluationComment: string | null;
  /**
   * Requisition of the case
   */
  requisitions: GetOperationManagerCaseData_cases_requisitions[];
  scalePointStatus: ScalePointStatus;
  scalePointErrorMessage: string | null;
  arrived: boolean;
  arrivedDate: GQL_DateTime | null;
  /**
   * Case change log
   */
  changes: GetOperationManagerCaseData_cases_changes[];
  /**
   * Bool indicating if case have active Machines
   */
  machineOnCase: boolean;
  openMovables: boolean;
  /**
   * Active movables on case
   */
  activeMovables: boolean;
  openTimeRegistrations: boolean;
  /**
   * Active requisitions on case
   */
  activeRequisitions: boolean;
  /**
   * Open purchase documents on case
   */
  activePurchaseDocuments: boolean;
  /**
   * Status of active  (always empty if user lacks permissions)
   */
  activeInvoiceStatus: InvoiceStatus | null;
  caseEconomics: GetOperationManagerCaseData_cases_caseEconomics | null;
}

export interface GetOperationManagerCaseData {
  cases: GetOperationManagerCaseData_cases[];
}

export interface GetOperationManagerCaseDataVariables {
  address?: AddressInput | null;
  awaiting?: boolean | null;
  damageServiceCompleted?: boolean | null;
  calledBack?: boolean | null;
  machinesOnCase?: boolean | null;
  ownCases?: boolean | null;
  appliedClosed?: boolean | null;
  closedCases?: boolean | null;
  debitor?: string[] | null;
  postalCode?: string[] | null;
  track?: number[] | null;
  department?: string[] | null;
  location?: string[] | null;
  damageCategory?: string[] | null;
  damageCause?: string[] | null;
  priority?: CasePriority[] | null;
  caseManager?: string[] | null;
  projectManager?: string[] | null;
  anyManager?: string | null;
  minDate?: GQL_Date | null;
  maxDate?: GQL_Date | null;
  erpReferenceNo?: string | null;
  erpReferenceNos?: string[] | null;
  includeEconomy: boolean;
  offset?: number | null;
  limit?: number | null;
  searchString?: string | null;
  excludeTotalCount?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSingleCase
// ====================================================

export interface GetSingleCase_case_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetSingleCase_case_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetSingleCase_case_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: GetSingleCase_case_debitor_shippingAddress_address;
}

export interface GetSingleCase_case_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetSingleCase_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: GetSingleCase_case_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: GetSingleCase_case_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: GetSingleCase_case_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
  /**
   * Indicates if debitor has automatic invoicing enabled for track one cases (null if unknown)
   */
  automaticTrackOneInvoicing: boolean | null;
  /**
   * Allow Invoicing Without ReqNo
   */
  allowInvoicingWithoutReqNo: boolean;
  /**
   * Debitor is blocked
   */
  blocked: boolean;
  /**
   * Indicates if customer should pay for actual kilometers driven
   */
  actualKilometers: boolean;
}

export interface GetSingleCase_case_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetSingleCase_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetSingleCase_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetSingleCase_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetSingleCase_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetSingleCase_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: GetSingleCase_case_damage_cause_departments_location;
}

export interface GetSingleCase_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: GetSingleCase_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: GetSingleCase_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (GetSingleCase_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface GetSingleCase_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetSingleCase_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetSingleCase_case_damage_contact_address;
}

export interface GetSingleCase_case_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface GetSingleCase_case_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: GetSingleCase_case_damage_businessArea;
  /**
   * Damage category
   */
  category: GetSingleCase_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetSingleCase_case_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: GetSingleCase_case_damage_contact;
  /**
   * contacts for damage
   */
  contacts: GetSingleCase_case_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface GetSingleCase_case_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetSingleCase_case_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetSingleCase_case_policyHolder_address;
}

export interface GetSingleCase_case_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetSingleCase_case_visitation_awaiting {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetSingleCase_case_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
  /**
   * The question
   */
  title: string;
}

export interface GetSingleCase_case_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: GetSingleCase_case_visitation_calledBack;
  /**
   * If case is awaiting
   */
  awaiting: GetSingleCase_case_visitation_awaiting;
  /**
   * A set of answers related to the damage cause
   */
  answers: GetSingleCase_case_visitation_answers[] | null;
  /**
   * If case needs a moisture report
   */
  isMoistureReportRequired: boolean | null;
  /**
   * If case needs an SP phase one report
   */
  isSpPhaseOneReportCreated: boolean | null;
}

export interface GetSingleCase_case_requisitioner {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Requisitioner relation
   */
  relation: string;
}

export interface GetSingleCase_case_adviser {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface GetSingleCase_case_referrer {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface GetSingleCase_case_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetSingleCase_case_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetSingleCase_case_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetSingleCase_case_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: GetSingleCase_case_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface GetSingleCase_case_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetSingleCase_case_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: GetSingleCase_case_ssgDepartment_location_address;
}

export interface GetSingleCase_case_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: GetSingleCase_case_ssgDepartment_location;
}

export interface GetSingleCase_case_caseAgreements_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetSingleCase_case_caseAgreements {
  /**
   * Agreement content
   */
  content: string;
  /**
   * Agreement category
   */
  category: CaseAgreementCategory;
  /**
   * User who created agreement
   */
  user: GetSingleCase_case_caseAgreements_user;
  /**
   * Timestamp of when agreement was created
   */
  timestamp: GQL_DateTime;
  /**
   * Whether case agreement should be sent to ScalePoint
   */
  shouldSaveInScalePoint: boolean;
}

export interface GetSingleCase_case_caseEmails {
  /**
   * Email content
   */
  content: string;
  /**
   * List of email attachments
   */
  attachments: string[];
  /**
   * List of email receivers
   */
  receivers: string[];
  /**
   * Timestamp of when email was created
   */
  timestamp: GQL_DateTime;
}

export interface GetSingleCase_case_userFavorite {
  /**
   * Unique user id
   */
  user: string;
  /**
   * Color code set by user
   */
  color: string | null;
  /**
   * Favorite active status
   */
  active: boolean;
}

export interface GetSingleCase_case_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetSingleCase_case_jobs_assignedTo {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
}

export interface GetSingleCase_case_jobs_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetSingleCase_case_jobs_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
}

export interface GetSingleCase_case_jobs_movable_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface GetSingleCase_case_jobs_movable {
  /**
   * Unique id of movable
   */
  id: string;
  /**
   * Description of movable
   */
  description: string;
  /**
   * Movable status
   */
  status: string;
  /**
   * Movable placement
   */
  placement: GetSingleCase_case_jobs_movable_placement;
}

export interface GetSingleCase_case_jobs {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Type of case task
   */
  type: JobType;
  /**
   * Description of the case task
   */
  description: string;
  /**
   * Deadline of the case task
   */
  deadline: GQL_DateTime;
  /**
   * The user responsible for the task
   */
  assignedTo: GetSingleCase_case_jobs_assignedTo | null;
  /**
   * The location responsible to the task
   */
  location: GetSingleCase_case_jobs_location | null;
  /**
   * The case the task belongs to
   */
  case: GetSingleCase_case_jobs_case | null;
  /**
   * The movable responsible to the task
   */
  movable: GetSingleCase_case_jobs_movable | null;
  /**
   * Whether the task is completed
   */
  completed: boolean;
}

export interface GetSingleCase_case_drivingSlipSeries_drivingSlips_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetSingleCase_case_drivingSlipSeries_drivingSlips_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetSingleCase_case_drivingSlipSeries_drivingSlips_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetSingleCase_case_drivingSlipSeries_drivingSlips_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface GetSingleCase_case_drivingSlipSeries_drivingSlips_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: GetSingleCase_case_drivingSlipSeries_drivingSlips_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface GetSingleCase_case_drivingSlipSeries_drivingSlips_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: GetSingleCase_case_drivingSlipSeries_drivingSlips_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface GetSingleCase_case_drivingSlipSeries_drivingSlips_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: GetSingleCase_case_drivingSlipSeries_drivingSlips_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface GetSingleCase_case_drivingSlipSeries_drivingSlips_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetSingleCase_case_drivingSlipSeries_drivingSlips_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetSingleCase_case_drivingSlipSeries_drivingSlips_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetSingleCase_case_drivingSlipSeries_drivingSlips_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetSingleCase_case_drivingSlipSeries_drivingSlips_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetSingleCase_case_drivingSlipSeries_drivingSlips_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: GetSingleCase_case_drivingSlipSeries_drivingSlips_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: GetSingleCase_case_drivingSlipSeries_drivingSlips_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface GetSingleCase_case_drivingSlipSeries_drivingSlips_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetSingleCase_case_drivingSlipSeries_drivingSlips_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetSingleCase_case_drivingSlipSeries_drivingSlips_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetSingleCase_case_drivingSlipSeries_drivingSlips_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetSingleCase_case_drivingSlipSeries_drivingSlips_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: GetSingleCase_case_drivingSlipSeries_drivingSlips_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: GetSingleCase_case_drivingSlipSeries_drivingSlips_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface GetSingleCase_case_drivingSlipSeries_drivingSlips_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: GetSingleCase_case_drivingSlipSeries_drivingSlips_changes_user;
  /**
   * Value before
   */
  before: GetSingleCase_case_drivingSlipSeries_drivingSlips_changes_before;
  /**
   * Value after
   */
  after: GetSingleCase_case_drivingSlipSeries_drivingSlips_changes_after;
}

export interface GetSingleCase_case_drivingSlipSeries_drivingSlips_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetSingleCase_case_drivingSlipSeries_drivingSlips_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface GetSingleCase_case_drivingSlipSeries_drivingSlips {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: GetSingleCase_case_drivingSlipSeries_drivingSlips_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: GetSingleCase_case_drivingSlipSeries_drivingSlips_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetSingleCase_case_drivingSlipSeries_drivingSlips_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: GetSingleCase_case_drivingSlipSeries_drivingSlips_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: GetSingleCase_case_drivingSlipSeries_drivingSlips_changes[];
  /**
   * Location of driving slip
   */
  location: GetSingleCase_case_drivingSlipSeries_drivingSlips_location;
  /**
   * Department of driving slip
   */
  department: GetSingleCase_case_drivingSlipSeries_drivingSlips_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
}

export interface GetSingleCase_case_drivingSlipSeries {
  /**
   * Unique id of driving slip series
   */
  id: string;
  /**
   * Driving slip series completed status
   */
  completed: boolean;
  /**
   * Driving slips on series
   */
  drivingSlips: GetSingleCase_case_drivingSlipSeries_drivingSlips[];
}

export interface GetSingleCase_case_alternativeContact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Comment on person
   */
  comment: string | null;
}

export interface GetSingleCase_case_riskEvaluationAnswers_question {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question
   */
  question: string;
}

export interface GetSingleCase_case_riskEvaluationAnswers {
  /**
   * Id of question answer is linked to
   */
  question: GetSingleCase_case_riskEvaluationAnswers_question;
  /**
   * Answer given
   */
  answer: boolean;
}

export interface GetSingleCase_case_requisitions_case_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
}

export interface GetSingleCase_case_requisitions_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
  /**
   * Id of damage category
   */
  id: string;
}

export interface GetSingleCase_case_requisitions_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of damage cause
   */
  id: string;
}

export interface GetSingleCase_case_requisitions_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetSingleCase_case_requisitions_case_damage_contact {
  /**
   * Contact address information
   */
  address: GetSingleCase_case_requisitions_case_damage_contact_address;
}

export interface GetSingleCase_case_requisitions_case_damage {
  /**
   * Damage category
   */
  category: GetSingleCase_case_requisitions_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetSingleCase_case_requisitions_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: GetSingleCase_case_requisitions_case_damage_contact;
}

export interface GetSingleCase_case_requisitions_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetSingleCase_case_requisitions_case_debitor;
  /**
   * Case damage details
   */
  damage: GetSingleCase_case_requisitions_case_damage;
}

export interface GetSingleCase_case_requisitions_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
}

export interface GetSingleCase_case_requisitions_drivingSlip_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetSingleCase_case_requisitions_drivingSlip_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
}

export interface GetSingleCase_case_requisitions_drivingSlip_case_damage {
  /**
   * Damage category
   */
  category: GetSingleCase_case_requisitions_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetSingleCase_case_requisitions_drivingSlip_case_damage_cause;
}

export interface GetSingleCase_case_requisitions_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: GetSingleCase_case_requisitions_drivingSlip_case_damage;
}

export interface GetSingleCase_case_requisitions_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Case which driving slip is related to
   */
  case: GetSingleCase_case_requisitions_drivingSlip_case;
}

export interface GetSingleCase_case_requisitions_vendor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetSingleCase_case_requisitions_vendor {
  /**
   * ERP reference no for vendor
   */
  erpReferenceNo: string;
  /**
   * The name of the vendor
   */
  company: string | null;
  /**
   * The address of the vendor
   */
  address: GetSingleCase_case_requisitions_vendor_address | null;
  /**
   * The email of the vendor
   */
  email: string | null;
  /**
   * The phone number of the vendor
   */
  phone: string | null;
  /**
   * The posting group of the vendor
   */
  vendorPostingGroup: string;
  /**
   * Attached SSG department of the vendor
   */
  ssgDepartment: string;
  /**
   * Attached SSG location of the vendor
   */
  ssgLocation: string;
  /**
   * Vendor type
   */
  type: string;
}

export interface GetSingleCase_case_requisitions_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface GetSingleCase_case_requisitions {
  /**
   * id of the requisition
   */
  id: string;
  /**
   * case owning the requisition
   */
  case: GetSingleCase_case_requisitions_case | null;
  /**
   * location owning the requisition
   */
  department: GetSingleCase_case_requisitions_department | null;
  /**
   * driving slip owning the requisition
   */
  drivingSlip: GetSingleCase_case_requisitions_drivingSlip | null;
  /**
   * description of the requisition
   */
  description: string;
  /**
   * orderNumber of the requisition
   */
  orderNumber: string;
  /**
   * the type of the requisition
   */
  type: RequisitionType;
  /**
   * Requisition status
   */
  status: boolean;
  /**
   * the vendor of the requisition
   */
  vendor: GetSingleCase_case_requisitions_vendor | null;
  /**
   * Timestamp of when the requisition was created
   */
  createdAt: GQL_DateTime;
  /**
   * User who created the requisition
   */
  createdBy: GetSingleCase_case_requisitions_createdBy;
  /**
   * email of the vendor
   */
  vendorEmail: string | null;
  /**
   * phone number of the vendor
   */
  vendorPhoneNumber: string;
  /**
   * name of the vendor
   */
  vendorName: string;
}

export interface GetSingleCase_case_invoices {
  /**
   * Reference no invoice
   */
  erpReferenceNo: string;
  /**
   * Invoice status
   */
  status: InvoiceStatus;
  /**
   * Category of invoice
   */
  category: InvoiceCategory;
}

export interface GetSingleCase_case_projectManagerFavorite {
  /**
   * Unique user id
   */
  user: string;
  /**
   * Color code set by user
   */
  color: string | null;
  /**
   * Favorite active status
   */
  active: boolean;
}

export interface GetSingleCase_case_caseManagerFavorite {
  /**
   * Unique user id
   */
  user: string;
  /**
   * Color code set by user
   */
  color: string | null;
  /**
   * Favorite active status
   */
  active: boolean;
}

export interface GetSingleCase_case_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetSingleCase_case_changes_before_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetSingleCase_case_changes_before_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor billing address
   */
  billingAddress: GetSingleCase_case_changes_before_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
}

export interface GetSingleCase_case_changes_before_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetSingleCase_case_changes_before_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetSingleCase_case_changes_before_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetSingleCase_case_changes_before_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetSingleCase_case_changes_before_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetSingleCase_case_changes_before_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: GetSingleCase_case_changes_before_damage_cause_departments_location;
}

export interface GetSingleCase_case_changes_before_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: GetSingleCase_case_changes_before_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: GetSingleCase_case_changes_before_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (GetSingleCase_case_changes_before_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface GetSingleCase_case_changes_before_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetSingleCase_case_changes_before_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetSingleCase_case_changes_before_damage_contact_address;
}

export interface GetSingleCase_case_changes_before_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface GetSingleCase_case_changes_before_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: GetSingleCase_case_changes_before_damage_businessArea;
  /**
   * Damage category
   */
  category: GetSingleCase_case_changes_before_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetSingleCase_case_changes_before_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: GetSingleCase_case_changes_before_damage_contact;
  /**
   * contacts for damage
   */
  contacts: GetSingleCase_case_changes_before_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface GetSingleCase_case_changes_before_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetSingleCase_case_changes_before_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetSingleCase_case_changes_before_policyHolder_address;
}

export interface GetSingleCase_case_changes_before_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetSingleCase_case_changes_before_visitation_awaiting {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetSingleCase_case_changes_before_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
}

export interface GetSingleCase_case_changes_before_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: GetSingleCase_case_changes_before_visitation_calledBack;
  /**
   * If case is awaiting
   */
  awaiting: GetSingleCase_case_changes_before_visitation_awaiting;
  /**
   * A set of answers related to the damage cause
   */
  answers: GetSingleCase_case_changes_before_visitation_answers[] | null;
}

export interface GetSingleCase_case_changes_before_requisitioner {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Requisitioner relation
   */
  relation: string;
}

export interface GetSingleCase_case_changes_before_drivingSlips_driver {
  /**
   * Name of user
   */
  name: string;
}

export interface GetSingleCase_case_changes_before_drivingSlips_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
}

export interface GetSingleCase_case_changes_before_drivingSlips {
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetSingleCase_case_changes_before_drivingSlips_driver | null;
  /**
   * Car added to the drivingslip
   */
  car: GetSingleCase_case_changes_before_drivingSlips_car | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
}

export interface GetSingleCase_case_changes_before_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetSingleCase_case_changes_before_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetSingleCase_case_changes_before_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetSingleCase_case_changes_before_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: GetSingleCase_case_changes_before_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface GetSingleCase_case_changes_before_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetSingleCase_case_changes_before_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: GetSingleCase_case_changes_before_ssgDepartment_location_address;
}

export interface GetSingleCase_case_changes_before_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: GetSingleCase_case_changes_before_ssgDepartment_location;
}

export interface GetSingleCase_case_changes_before_caseEmails {
  /**
   * Email content
   */
  content: string;
  /**
   * List of email attachments
   */
  attachments: string[];
  /**
   * List of email receivers
   */
  receivers: string[];
  /**
   * Timestamp of when email was created
   */
  timestamp: GQL_DateTime;
}

export interface GetSingleCase_case_changes_before_caseMachineUsages {
  /**
   * Machine identifier
   */
  machine: string;
  /**
   * Start time
   */
  startTime: GQL_DateTime;
  /**
   * End time
   */
  endTime: GQL_DateTime | null;
  /**
   * Meter reading at start time
   */
  startMeterReading: GQL_Decimal;
  /**
   * Meter reading at end time
   */
  endMeterReading: GQL_Decimal | null;
}

export interface GetSingleCase_case_changes_before_alternativeContact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Comment on person
   */
  comment: string | null;
}

export interface GetSingleCase_case_changes_before_riskEvaluationAnswers_question {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question
   */
  question: string;
}

export interface GetSingleCase_case_changes_before_riskEvaluationAnswers {
  /**
   * Id of question answer is linked to
   */
  question: GetSingleCase_case_changes_before_riskEvaluationAnswers_question;
  /**
   * Answer given
   */
  answer: boolean;
}

export interface GetSingleCase_case_changes_before {
  /**
   * Case status
   */
  status: CaseStatus | null;
  /**
   * Case track
   */
  track: number | null;
  /**
   * Case debitor details
   */
  debitor: GetSingleCase_case_changes_before_debitor | null;
  /**
   * Case damage details
   */
  damage: GetSingleCase_case_changes_before_damage | null;
  /**
   * Case policy holder details
   */
  policyHolder: GetSingleCase_case_changes_before_policyHolder | null;
  /**
   * Case visitation details
   */
  visitation: GetSingleCase_case_changes_before_visitation | null;
  /**
   * Case requisitioner details
   */
  requisitioner: GetSingleCase_case_changes_before_requisitioner | null;
  /**
   * The driving slip changed on case
   */
  drivingSlips: GetSingleCase_case_changes_before_drivingSlips | null;
  /**
   * Project manager attached to case
   */
  projectManager: GetSingleCase_case_changes_before_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: GetSingleCase_case_changes_before_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: GetSingleCase_case_changes_before_ssgLocation | null;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: GetSingleCase_case_changes_before_ssgDepartment | null;
  /**
   * List of case emails
   */
  caseEmails: GetSingleCase_case_changes_before_caseEmails[] | null;
  /**
   * List of case machine usages
   */
  caseMachineUsages: GetSingleCase_case_changes_before_caseMachineUsages[] | null;
  /**
   * Important info on case
   */
  importantInfo: string | null;
  /**
   * Requisition from fire department
   */
  skafor: boolean | null;
  /**
   * Alternative contact person
   */
  alternativeContact: GetSingleCase_case_changes_before_alternativeContact | null;
  /**
   * Risk evaluation answers
   */
  riskEvaluationAnswers: GetSingleCase_case_changes_before_riskEvaluationAnswers[] | null;
  /**
   * Name of files
   */
  fileNames: string | null;
}

export interface GetSingleCase_case_changes_after_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetSingleCase_case_changes_after_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor billing address
   */
  billingAddress: GetSingleCase_case_changes_after_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
}

export interface GetSingleCase_case_changes_after_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetSingleCase_case_changes_after_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetSingleCase_case_changes_after_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetSingleCase_case_changes_after_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetSingleCase_case_changes_after_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetSingleCase_case_changes_after_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: GetSingleCase_case_changes_after_damage_cause_departments_location;
}

export interface GetSingleCase_case_changes_after_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: GetSingleCase_case_changes_after_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: GetSingleCase_case_changes_after_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (GetSingleCase_case_changes_after_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface GetSingleCase_case_changes_after_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetSingleCase_case_changes_after_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetSingleCase_case_changes_after_damage_contact_address;
}

export interface GetSingleCase_case_changes_after_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface GetSingleCase_case_changes_after_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: GetSingleCase_case_changes_after_damage_businessArea;
  /**
   * Damage category
   */
  category: GetSingleCase_case_changes_after_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetSingleCase_case_changes_after_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: GetSingleCase_case_changes_after_damage_contact;
  /**
   * contacts for damage
   */
  contacts: GetSingleCase_case_changes_after_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface GetSingleCase_case_changes_after_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetSingleCase_case_changes_after_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetSingleCase_case_changes_after_policyHolder_address;
}

export interface GetSingleCase_case_changes_after_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetSingleCase_case_changes_after_visitation_awaiting {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetSingleCase_case_changes_after_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
}

export interface GetSingleCase_case_changes_after_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: GetSingleCase_case_changes_after_visitation_calledBack;
  /**
   * If case is awaiting
   */
  awaiting: GetSingleCase_case_changes_after_visitation_awaiting;
  /**
   * A set of answers related to the damage cause
   */
  answers: GetSingleCase_case_changes_after_visitation_answers[] | null;
}

export interface GetSingleCase_case_changes_after_requisitioner {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Requisitioner relation
   */
  relation: string;
}

export interface GetSingleCase_case_changes_after_drivingSlips_driver {
  /**
   * Name of user
   */
  name: string;
}

export interface GetSingleCase_case_changes_after_drivingSlips_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
}

export interface GetSingleCase_case_changes_after_drivingSlips {
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetSingleCase_case_changes_after_drivingSlips_driver | null;
  /**
   * Car added to the drivingslip
   */
  car: GetSingleCase_case_changes_after_drivingSlips_car | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
}

export interface GetSingleCase_case_changes_after_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetSingleCase_case_changes_after_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetSingleCase_case_changes_after_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetSingleCase_case_changes_after_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: GetSingleCase_case_changes_after_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface GetSingleCase_case_changes_after_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetSingleCase_case_changes_after_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: GetSingleCase_case_changes_after_ssgDepartment_location_address;
}

export interface GetSingleCase_case_changes_after_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: GetSingleCase_case_changes_after_ssgDepartment_location;
}

export interface GetSingleCase_case_changes_after_caseEmails {
  /**
   * Email content
   */
  content: string;
  /**
   * List of email attachments
   */
  attachments: string[];
  /**
   * List of email receivers
   */
  receivers: string[];
  /**
   * Timestamp of when email was created
   */
  timestamp: GQL_DateTime;
}

export interface GetSingleCase_case_changes_after_caseMachineUsages {
  /**
   * Machine identifier
   */
  machine: string;
  /**
   * Start time
   */
  startTime: GQL_DateTime;
  /**
   * End time
   */
  endTime: GQL_DateTime | null;
  /**
   * Meter reading at start time
   */
  startMeterReading: GQL_Decimal;
  /**
   * Meter reading at end time
   */
  endMeterReading: GQL_Decimal | null;
}

export interface GetSingleCase_case_changes_after_alternativeContact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Comment on person
   */
  comment: string | null;
}

export interface GetSingleCase_case_changes_after_riskEvaluationAnswers_question {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question
   */
  question: string;
}

export interface GetSingleCase_case_changes_after_riskEvaluationAnswers {
  /**
   * Id of question answer is linked to
   */
  question: GetSingleCase_case_changes_after_riskEvaluationAnswers_question;
  /**
   * Answer given
   */
  answer: boolean;
}

export interface GetSingleCase_case_changes_after {
  /**
   * Case status
   */
  status: CaseStatus | null;
  /**
   * Case track
   */
  track: number | null;
  /**
   * Case debitor details
   */
  debitor: GetSingleCase_case_changes_after_debitor | null;
  /**
   * Case damage details
   */
  damage: GetSingleCase_case_changes_after_damage | null;
  /**
   * Case policy holder details
   */
  policyHolder: GetSingleCase_case_changes_after_policyHolder | null;
  /**
   * Case visitation details
   */
  visitation: GetSingleCase_case_changes_after_visitation | null;
  /**
   * Case requisitioner details
   */
  requisitioner: GetSingleCase_case_changes_after_requisitioner | null;
  /**
   * The driving slip changed on case
   */
  drivingSlips: GetSingleCase_case_changes_after_drivingSlips | null;
  /**
   * Project manager attached to case
   */
  projectManager: GetSingleCase_case_changes_after_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: GetSingleCase_case_changes_after_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: GetSingleCase_case_changes_after_ssgLocation | null;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: GetSingleCase_case_changes_after_ssgDepartment | null;
  /**
   * List of case emails
   */
  caseEmails: GetSingleCase_case_changes_after_caseEmails[] | null;
  /**
   * List of case machine usages
   */
  caseMachineUsages: GetSingleCase_case_changes_after_caseMachineUsages[] | null;
  /**
   * Important info on case
   */
  importantInfo: string | null;
  /**
   * Requisition from fire department
   */
  skafor: boolean | null;
  /**
   * Alternative contact person
   */
  alternativeContact: GetSingleCase_case_changes_after_alternativeContact | null;
  /**
   * Risk evaluation answers
   */
  riskEvaluationAnswers: GetSingleCase_case_changes_after_riskEvaluationAnswers[] | null;
  /**
   * Name of files
   */
  fileNames: string | null;
}

export interface GetSingleCase_case_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: GetSingleCase_case_changes_user | null;
  /**
   * Value before
   */
  before: GetSingleCase_case_changes_before;
  /**
   * Value after
   */
  after: GetSingleCase_case_changes_after;
}

export interface GetSingleCase_case_caseEconomics {
  /**
   * JobNo
   */
  jobNo: string;
  /**
   * costTotal
   */
  costTotal: GQL_Decimal;
  /**
   * salesTotal
   */
  salesTotal: GQL_Decimal;
  /**
   * invoicedTotal
   */
  invoicedTotal: GQL_Decimal;
  /**
   * quote
   */
  quote: GQL_Decimal;
  /**
   * screening
   */
  screening: GQL_Decimal;
  /**
   * profit
   */
  profit: GQL_Decimal;
  /**
   * deltaNotInvoiced
   */
  deltaNotInvoiced: GQL_Decimal;
  /**
   * coverageRatio
   */
  coverageRatio: GQL_Decimal;
}

export interface GetSingleCase_case_caseReports_inspectionReportFormData {
  __typename: "InspectionReportFormData";
}

export interface GetSingleCase_case_caseReports_indoorClimateReportFormData {
  __typename: "IndoorClimateReportFormData";
}

export interface GetSingleCase_case_caseReports_moistureReportFormData {
  __typename: "MoistureReportFormData";
}

export interface GetSingleCase_case_caseReports_moistureTrygReportFormData {
  __typename: "MoistureTrygReportFormData";
}

export interface GetSingleCase_case_caseReports {
  /**
   * Inspection report form data populated by the user
   */
  inspectionReportFormData: GetSingleCase_case_caseReports_inspectionReportFormData | null;
  /**
   * Indooor climate report form data populated by the user
   */
  indoorClimateReportFormData: GetSingleCase_case_caseReports_indoorClimateReportFormData | null;
  /**
   * Moisture report form data populated by the user
   */
  moistureReportFormData: GetSingleCase_case_caseReports_moistureReportFormData | null;
  /**
   * Moisture TRYG report form data populated by the user
   */
  moistureTrygReportFormData: GetSingleCase_case_caseReports_moistureTrygReportFormData | null;
}

export interface GetSingleCase_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * ReportSent
   */
  reportSent: boolean;
  /**
   * Distance from ssg location to damage address
   */
  distance: string | null;
  /**
   * Whether notifications regarding machines on the case should be sent
   */
  machineNotification: boolean | null;
  /**
   * Whether time registration is allowed on the case
   */
  timeRegistration: boolean | null;
  /**
   * Case debitor details
   */
  debitor: GetSingleCase_case_debitor;
  /**
   * Case damage details
   */
  damage: GetSingleCase_case_damage;
  /**
   * Case policy holder details
   */
  policyHolder: GetSingleCase_case_policyHolder;
  /**
   * Case visitation details
   */
  visitation: GetSingleCase_case_visitation;
  /**
   * Case requisitioner details
   */
  requisitioner: GetSingleCase_case_requisitioner;
  /**
   * Case advisor details
   */
  adviser: GetSingleCase_case_adviser | null;
  /**
   * Case referer details
   */
  referrer: GetSingleCase_case_referrer | null;
  /**
   * Project manager attached to case
   */
  projectManager: GetSingleCase_case_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: GetSingleCase_case_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: GetSingleCase_case_ssgLocation;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: GetSingleCase_case_ssgDepartment;
  /**
   * Case ready for billing
   */
  billingReady: boolean;
  /**
   * Case work complete
   */
  workComplete: boolean;
  /**
   * List of case agreements
   */
  caseAgreements: GetSingleCase_case_caseAgreements[];
  /**
   * List of case emails
   */
  caseEmails: GetSingleCase_case_caseEmails[];
  /**
   * List of user favorite entries
   */
  userFavorite: GetSingleCase_case_userFavorite | null;
  /**
   * User who created the case
   */
  createdBy: GetSingleCase_case_createdBy | null;
  /**
   * Timestamp of when the case was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the case was created
   */
  createdAt: GQL_DateTime;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * The tasks associated with the task
   */
  jobs: GetSingleCase_case_jobs[] | null;
  /**
   * The driving slips series associated with the case
   */
  drivingSlipSeries: GetSingleCase_case_drivingSlipSeries[];
  /**
   * Important info on the case
   */
  importantInfo: string | null;
  /**
   * Alternative contact
   */
  alternativeContact: GetSingleCase_case_alternativeContact | null;
  /**
   * Risk evaluation answers
   */
  riskEvaluationAnswers: GetSingleCase_case_riskEvaluationAnswers[] | null;
  /**
   * Optional comment for risk evaluation
   */
  riskEvaluationComment: string | null;
  /**
   * Requisition of the case
   */
  requisitions: GetSingleCase_case_requisitions[];
  scalePointStatus: ScalePointStatus;
  scalePointErrorMessage: string | null;
  arrived: boolean;
  arrivedDate: GQL_DateTime | null;
  /**
   * Active movables on case
   */
  activeMovables: boolean;
  /**
   * Bool indicating if case have active Machines
   */
  machineOnCase: boolean;
  scalePointUrl: string | null;
  /**
   * Active requisitions on case
   */
  activeRequisitions: boolean;
  /**
   * Status of active  (always empty if user lacks permissions)
   */
  activeInvoiceStatus: InvoiceStatus | null;
  /**
   * Open purchase documents on case
   */
  activePurchaseDocuments: boolean;
  /**
   * List of invoices related to case (always empty if user lacks permissions)
   */
  invoices: GetSingleCase_case_invoices[];
  openMovables: boolean;
  openTimeRegistrations: boolean;
  /**
   * Case change version number
   */
  version: number;
  fixedPriceOnCase: GQL_Decimal;
  /**
   * List of projectmanager favorite entries
   */
  projectManagerFavorite: GetSingleCase_case_projectManagerFavorite | null;
  /**
   * List of casemanager favorite entries
   */
  caseManagerFavorite: GetSingleCase_case_caseManagerFavorite | null;
  /**
   * Case change log
   */
  changes: GetSingleCase_case_changes[];
  caseEconomics: GetSingleCase_case_caseEconomics | null;
  caseReports: GetSingleCase_case_caseReports | null;
}

export interface GetSingleCase {
  case: GetSingleCase_case;
}

export interface GetSingleCaseVariables {
  id: string;
  includeEconomy: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSSGInfoAdminData
// ====================================================

export interface GetSSGInfoAdminData_departments_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetSSGInfoAdminData_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: GetSSGInfoAdminData_departments_location_address;
}

export interface GetSSGInfoAdminData_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: GetSSGInfoAdminData_departments_location;
}

export interface GetSSGInfoAdminData_locations_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetSSGInfoAdminData_locations {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: GetSSGInfoAdminData_locations_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface GetSSGInfoAdminData {
  departments: GetSSGInfoAdminData_departments[];
  locations: GetSSGInfoAdminData_locations[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWebCase
// ====================================================

export interface GetWebCase_case_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebCase_case_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebCase_case_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: GetWebCase_case_debitor_shippingAddress_address;
}

export interface GetWebCase_case_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebCase_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: GetWebCase_case_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: GetWebCase_case_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: GetWebCase_case_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
  /**
   * Indicates if debitor has automatic invoicing enabled for track one cases (null if unknown)
   */
  automaticTrackOneInvoicing: boolean | null;
  /**
   * Allow Invoicing Without ReqNo
   */
  allowInvoicingWithoutReqNo: boolean;
}

export interface GetWebCase_case_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetWebCase_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetWebCase_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetWebCase_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetWebCase_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetWebCase_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: GetWebCase_case_damage_cause_departments_location;
}

export interface GetWebCase_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: GetWebCase_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: GetWebCase_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (GetWebCase_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface GetWebCase_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebCase_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetWebCase_case_damage_contact_address;
}

export interface GetWebCase_case_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface GetWebCase_case_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: GetWebCase_case_damage_businessArea;
  /**
   * Damage category
   */
  category: GetWebCase_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetWebCase_case_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: GetWebCase_case_damage_contact;
  /**
   * contacts for damage
   */
  contacts: GetWebCase_case_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface GetWebCase_case_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebCase_case_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetWebCase_case_policyHolder_address;
}

export interface GetWebCase_case_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetWebCase_case_visitation_awaiting {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetWebCase_case_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
  /**
   * The question
   */
  title: string;
}

export interface GetWebCase_case_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: GetWebCase_case_visitation_calledBack;
  /**
   * If case is awaiting
   */
  awaiting: GetWebCase_case_visitation_awaiting;
  /**
   * A set of answers related to the damage cause
   */
  answers: GetWebCase_case_visitation_answers[] | null;
  /**
   * If case needs a moisture report
   */
  isMoistureReportRequired: boolean | null;
  /**
   * If case needs an SP phase one report
   */
  isSpPhaseOneReportCreated: boolean | null;
}

export interface GetWebCase_case_requisitioner {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Requisitioner relation
   */
  relation: string;
}

export interface GetWebCase_case_adviser {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface GetWebCase_case_referrer {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface GetWebCase_case_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetWebCase_case_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetWebCase_case_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebCase_case_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: GetWebCase_case_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface GetWebCase_case_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebCase_case_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: GetWebCase_case_ssgDepartment_location_address;
}

export interface GetWebCase_case_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: GetWebCase_case_ssgDepartment_location;
}

export interface GetWebCase_case_caseAgreements_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetWebCase_case_caseAgreements {
  /**
   * Agreement content
   */
  content: string;
  /**
   * Agreement category
   */
  category: CaseAgreementCategory;
  /**
   * User who created agreement
   */
  user: GetWebCase_case_caseAgreements_user;
  /**
   * Timestamp of when agreement was created
   */
  timestamp: GQL_DateTime;
  /**
   * Whether case agreement should be sent to ScalePoint
   */
  shouldSaveInScalePoint: boolean;
}

export interface GetWebCase_case_caseEmails {
  /**
   * Email content
   */
  content: string;
  /**
   * List of email attachments
   */
  attachments: string[];
  /**
   * List of email receivers
   */
  receivers: string[];
  /**
   * Timestamp of when email was created
   */
  timestamp: GQL_DateTime;
}

export interface GetWebCase_case_userFavorite {
  /**
   * Unique user id
   */
  user: string;
  /**
   * Color code set by user
   */
  color: string | null;
  /**
   * Favorite active status
   */
  active: boolean;
}

export interface GetWebCase_case_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetWebCase_case_jobs_assignedTo {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
}

export interface GetWebCase_case_jobs_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetWebCase_case_jobs_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
}

export interface GetWebCase_case_jobs_movable_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface GetWebCase_case_jobs_movable {
  /**
   * Unique id of movable
   */
  id: string;
  /**
   * Description of movable
   */
  description: string;
  /**
   * Movable status
   */
  status: string;
  /**
   * Movable placement
   */
  placement: GetWebCase_case_jobs_movable_placement;
}

export interface GetWebCase_case_jobs {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Type of case task
   */
  type: JobType;
  /**
   * Description of the case task
   */
  description: string;
  /**
   * Deadline of the case task
   */
  deadline: GQL_DateTime;
  /**
   * The user responsible for the task
   */
  assignedTo: GetWebCase_case_jobs_assignedTo | null;
  /**
   * The location responsible to the task
   */
  location: GetWebCase_case_jobs_location | null;
  /**
   * The case the task belongs to
   */
  case: GetWebCase_case_jobs_case | null;
  /**
   * The movable responsible to the task
   */
  movable: GetWebCase_case_jobs_movable | null;
  /**
   * Whether the task is completed
   */
  completed: boolean;
}

export interface GetWebCase_case_drivingSlipSeries_drivingSlips_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetWebCase_case_drivingSlipSeries_drivingSlips_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetWebCase_case_drivingSlipSeries_drivingSlips_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetWebCase_case_drivingSlipSeries_drivingSlips_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface GetWebCase_case_drivingSlipSeries_drivingSlips_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: GetWebCase_case_drivingSlipSeries_drivingSlips_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface GetWebCase_case_drivingSlipSeries_drivingSlips_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: GetWebCase_case_drivingSlipSeries_drivingSlips_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface GetWebCase_case_drivingSlipSeries_drivingSlips_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: GetWebCase_case_drivingSlipSeries_drivingSlips_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface GetWebCase_case_drivingSlipSeries_drivingSlips_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetWebCase_case_drivingSlipSeries_drivingSlips_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetWebCase_case_drivingSlipSeries_drivingSlips_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetWebCase_case_drivingSlipSeries_drivingSlips_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetWebCase_case_drivingSlipSeries_drivingSlips_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetWebCase_case_drivingSlipSeries_drivingSlips_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: GetWebCase_case_drivingSlipSeries_drivingSlips_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: GetWebCase_case_drivingSlipSeries_drivingSlips_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface GetWebCase_case_drivingSlipSeries_drivingSlips_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetWebCase_case_drivingSlipSeries_drivingSlips_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetWebCase_case_drivingSlipSeries_drivingSlips_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetWebCase_case_drivingSlipSeries_drivingSlips_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetWebCase_case_drivingSlipSeries_drivingSlips_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: GetWebCase_case_drivingSlipSeries_drivingSlips_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: GetWebCase_case_drivingSlipSeries_drivingSlips_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface GetWebCase_case_drivingSlipSeries_drivingSlips_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: GetWebCase_case_drivingSlipSeries_drivingSlips_changes_user;
  /**
   * Value before
   */
  before: GetWebCase_case_drivingSlipSeries_drivingSlips_changes_before;
  /**
   * Value after
   */
  after: GetWebCase_case_drivingSlipSeries_drivingSlips_changes_after;
}

export interface GetWebCase_case_drivingSlipSeries_drivingSlips_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetWebCase_case_drivingSlipSeries_drivingSlips_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface GetWebCase_case_drivingSlipSeries_drivingSlips {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: GetWebCase_case_drivingSlipSeries_drivingSlips_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: GetWebCase_case_drivingSlipSeries_drivingSlips_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetWebCase_case_drivingSlipSeries_drivingSlips_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: GetWebCase_case_drivingSlipSeries_drivingSlips_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: GetWebCase_case_drivingSlipSeries_drivingSlips_changes[];
  /**
   * Location of driving slip
   */
  location: GetWebCase_case_drivingSlipSeries_drivingSlips_location;
  /**
   * Department of driving slip
   */
  department: GetWebCase_case_drivingSlipSeries_drivingSlips_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
}

export interface GetWebCase_case_drivingSlipSeries {
  /**
   * Unique id of driving slip series
   */
  id: string;
  /**
   * Driving slip series completed status
   */
  completed: boolean;
  /**
   * Driving slips on series
   */
  drivingSlips: GetWebCase_case_drivingSlipSeries_drivingSlips[];
}

export interface GetWebCase_case_alternativeContact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Comment on person
   */
  comment: string | null;
}

export interface GetWebCase_case_riskEvaluationAnswers_question {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question
   */
  question: string;
}

export interface GetWebCase_case_riskEvaluationAnswers {
  /**
   * Id of question answer is linked to
   */
  question: GetWebCase_case_riskEvaluationAnswers_question;
  /**
   * Answer given
   */
  answer: boolean;
}

export interface GetWebCase_case_requisitions_case_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
}

export interface GetWebCase_case_requisitions_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
  /**
   * Id of damage category
   */
  id: string;
}

export interface GetWebCase_case_requisitions_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of damage cause
   */
  id: string;
}

export interface GetWebCase_case_requisitions_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebCase_case_requisitions_case_damage_contact {
  /**
   * Contact address information
   */
  address: GetWebCase_case_requisitions_case_damage_contact_address;
}

export interface GetWebCase_case_requisitions_case_damage {
  /**
   * Damage category
   */
  category: GetWebCase_case_requisitions_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetWebCase_case_requisitions_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: GetWebCase_case_requisitions_case_damage_contact;
}

export interface GetWebCase_case_requisitions_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetWebCase_case_requisitions_case_debitor;
  /**
   * Case damage details
   */
  damage: GetWebCase_case_requisitions_case_damage;
}

export interface GetWebCase_case_requisitions_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
}

export interface GetWebCase_case_requisitions_drivingSlip_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetWebCase_case_requisitions_drivingSlip_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
}

export interface GetWebCase_case_requisitions_drivingSlip_case_damage {
  /**
   * Damage category
   */
  category: GetWebCase_case_requisitions_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetWebCase_case_requisitions_drivingSlip_case_damage_cause;
}

export interface GetWebCase_case_requisitions_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: GetWebCase_case_requisitions_drivingSlip_case_damage;
}

export interface GetWebCase_case_requisitions_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Case which driving slip is related to
   */
  case: GetWebCase_case_requisitions_drivingSlip_case;
}

export interface GetWebCase_case_requisitions_vendor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebCase_case_requisitions_vendor {
  /**
   * ERP reference no for vendor
   */
  erpReferenceNo: string;
  /**
   * The name of the vendor
   */
  company: string | null;
  /**
   * The address of the vendor
   */
  address: GetWebCase_case_requisitions_vendor_address | null;
  /**
   * The email of the vendor
   */
  email: string | null;
  /**
   * The phone number of the vendor
   */
  phone: string | null;
  /**
   * The posting group of the vendor
   */
  vendorPostingGroup: string;
  /**
   * Attached SSG department of the vendor
   */
  ssgDepartment: string;
  /**
   * Attached SSG location of the vendor
   */
  ssgLocation: string;
  /**
   * Vendor type
   */
  type: string;
}

export interface GetWebCase_case_requisitions_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface GetWebCase_case_requisitions {
  /**
   * id of the requisition
   */
  id: string;
  /**
   * case owning the requisition
   */
  case: GetWebCase_case_requisitions_case | null;
  /**
   * location owning the requisition
   */
  department: GetWebCase_case_requisitions_department | null;
  /**
   * driving slip owning the requisition
   */
  drivingSlip: GetWebCase_case_requisitions_drivingSlip | null;
  /**
   * description of the requisition
   */
  description: string;
  /**
   * orderNumber of the requisition
   */
  orderNumber: string;
  /**
   * the type of the requisition
   */
  type: RequisitionType;
  /**
   * Requisition status
   */
  status: boolean;
  /**
   * the vendor of the requisition
   */
  vendor: GetWebCase_case_requisitions_vendor | null;
  /**
   * Timestamp of when the requisition was created
   */
  createdAt: GQL_DateTime;
  /**
   * User who created the requisition
   */
  createdBy: GetWebCase_case_requisitions_createdBy;
  /**
   * email of the vendor
   */
  vendorEmail: string | null;
  /**
   * phone number of the vendor
   */
  vendorPhoneNumber: string;
  /**
   * name of the vendor
   */
  vendorName: string;
}

export interface GetWebCase_case_projectManagerFavorite {
  /**
   * Unique user id
   */
  user: string;
  /**
   * Color code set by user
   */
  color: string | null;
  /**
   * Favorite active status
   */
  active: boolean;
}

export interface GetWebCase_case_caseManagerFavorite {
  /**
   * Unique user id
   */
  user: string;
  /**
   * Color code set by user
   */
  color: string | null;
  /**
   * Favorite active status
   */
  active: boolean;
}

export interface GetWebCase_case_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetWebCase_case_changes_before_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebCase_case_changes_before_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor billing address
   */
  billingAddress: GetWebCase_case_changes_before_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
}

export interface GetWebCase_case_changes_before_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetWebCase_case_changes_before_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetWebCase_case_changes_before_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetWebCase_case_changes_before_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetWebCase_case_changes_before_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetWebCase_case_changes_before_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: GetWebCase_case_changes_before_damage_cause_departments_location;
}

export interface GetWebCase_case_changes_before_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: GetWebCase_case_changes_before_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: GetWebCase_case_changes_before_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (GetWebCase_case_changes_before_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface GetWebCase_case_changes_before_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebCase_case_changes_before_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetWebCase_case_changes_before_damage_contact_address;
}

export interface GetWebCase_case_changes_before_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface GetWebCase_case_changes_before_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: GetWebCase_case_changes_before_damage_businessArea;
  /**
   * Damage category
   */
  category: GetWebCase_case_changes_before_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetWebCase_case_changes_before_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: GetWebCase_case_changes_before_damage_contact;
  /**
   * contacts for damage
   */
  contacts: GetWebCase_case_changes_before_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface GetWebCase_case_changes_before_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebCase_case_changes_before_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetWebCase_case_changes_before_policyHolder_address;
}

export interface GetWebCase_case_changes_before_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetWebCase_case_changes_before_visitation_awaiting {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetWebCase_case_changes_before_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
}

export interface GetWebCase_case_changes_before_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: GetWebCase_case_changes_before_visitation_calledBack;
  /**
   * If case is awaiting
   */
  awaiting: GetWebCase_case_changes_before_visitation_awaiting;
  /**
   * A set of answers related to the damage cause
   */
  answers: GetWebCase_case_changes_before_visitation_answers[] | null;
}

export interface GetWebCase_case_changes_before_requisitioner {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Requisitioner relation
   */
  relation: string;
}

export interface GetWebCase_case_changes_before_drivingSlips_driver {
  /**
   * Name of user
   */
  name: string;
}

export interface GetWebCase_case_changes_before_drivingSlips_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
}

export interface GetWebCase_case_changes_before_drivingSlips {
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetWebCase_case_changes_before_drivingSlips_driver | null;
  /**
   * Car added to the drivingslip
   */
  car: GetWebCase_case_changes_before_drivingSlips_car | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
}

export interface GetWebCase_case_changes_before_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetWebCase_case_changes_before_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetWebCase_case_changes_before_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebCase_case_changes_before_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: GetWebCase_case_changes_before_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface GetWebCase_case_changes_before_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebCase_case_changes_before_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: GetWebCase_case_changes_before_ssgDepartment_location_address;
}

export interface GetWebCase_case_changes_before_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: GetWebCase_case_changes_before_ssgDepartment_location;
}

export interface GetWebCase_case_changes_before_caseEmails {
  /**
   * Email content
   */
  content: string;
  /**
   * List of email attachments
   */
  attachments: string[];
  /**
   * List of email receivers
   */
  receivers: string[];
  /**
   * Timestamp of when email was created
   */
  timestamp: GQL_DateTime;
}

export interface GetWebCase_case_changes_before_caseMachineUsages {
  /**
   * Machine identifier
   */
  machine: string;
  /**
   * Start time
   */
  startTime: GQL_DateTime;
  /**
   * End time
   */
  endTime: GQL_DateTime | null;
  /**
   * Meter reading at start time
   */
  startMeterReading: GQL_Decimal;
  /**
   * Meter reading at end time
   */
  endMeterReading: GQL_Decimal | null;
}

export interface GetWebCase_case_changes_before_alternativeContact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Comment on person
   */
  comment: string | null;
}

export interface GetWebCase_case_changes_before_riskEvaluationAnswers_question {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question
   */
  question: string;
}

export interface GetWebCase_case_changes_before_riskEvaluationAnswers {
  /**
   * Id of question answer is linked to
   */
  question: GetWebCase_case_changes_before_riskEvaluationAnswers_question;
  /**
   * Answer given
   */
  answer: boolean;
}

export interface GetWebCase_case_changes_before {
  /**
   * Case status
   */
  status: CaseStatus | null;
  /**
   * Case track
   */
  track: number | null;
  /**
   * Case debitor details
   */
  debitor: GetWebCase_case_changes_before_debitor | null;
  /**
   * Case damage details
   */
  damage: GetWebCase_case_changes_before_damage | null;
  /**
   * Case policy holder details
   */
  policyHolder: GetWebCase_case_changes_before_policyHolder | null;
  /**
   * Case visitation details
   */
  visitation: GetWebCase_case_changes_before_visitation | null;
  /**
   * Case requisitioner details
   */
  requisitioner: GetWebCase_case_changes_before_requisitioner | null;
  /**
   * The driving slip changed on case
   */
  drivingSlips: GetWebCase_case_changes_before_drivingSlips | null;
  /**
   * Project manager attached to case
   */
  projectManager: GetWebCase_case_changes_before_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: GetWebCase_case_changes_before_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: GetWebCase_case_changes_before_ssgLocation | null;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: GetWebCase_case_changes_before_ssgDepartment | null;
  /**
   * List of case emails
   */
  caseEmails: GetWebCase_case_changes_before_caseEmails[] | null;
  /**
   * List of case machine usages
   */
  caseMachineUsages: GetWebCase_case_changes_before_caseMachineUsages[] | null;
  /**
   * Important info on case
   */
  importantInfo: string | null;
  /**
   * Requisition from fire department
   */
  skafor: boolean | null;
  /**
   * Alternative contact person
   */
  alternativeContact: GetWebCase_case_changes_before_alternativeContact | null;
  /**
   * Risk evaluation answers
   */
  riskEvaluationAnswers: GetWebCase_case_changes_before_riskEvaluationAnswers[] | null;
  /**
   * Name of files
   */
  fileNames: string | null;
}

export interface GetWebCase_case_changes_after_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebCase_case_changes_after_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor billing address
   */
  billingAddress: GetWebCase_case_changes_after_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
}

export interface GetWebCase_case_changes_after_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetWebCase_case_changes_after_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetWebCase_case_changes_after_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetWebCase_case_changes_after_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetWebCase_case_changes_after_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetWebCase_case_changes_after_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: GetWebCase_case_changes_after_damage_cause_departments_location;
}

export interface GetWebCase_case_changes_after_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: GetWebCase_case_changes_after_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: GetWebCase_case_changes_after_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (GetWebCase_case_changes_after_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface GetWebCase_case_changes_after_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebCase_case_changes_after_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetWebCase_case_changes_after_damage_contact_address;
}

export interface GetWebCase_case_changes_after_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface GetWebCase_case_changes_after_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: GetWebCase_case_changes_after_damage_businessArea;
  /**
   * Damage category
   */
  category: GetWebCase_case_changes_after_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetWebCase_case_changes_after_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: GetWebCase_case_changes_after_damage_contact;
  /**
   * contacts for damage
   */
  contacts: GetWebCase_case_changes_after_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface GetWebCase_case_changes_after_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebCase_case_changes_after_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetWebCase_case_changes_after_policyHolder_address;
}

export interface GetWebCase_case_changes_after_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetWebCase_case_changes_after_visitation_awaiting {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetWebCase_case_changes_after_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
}

export interface GetWebCase_case_changes_after_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: GetWebCase_case_changes_after_visitation_calledBack;
  /**
   * If case is awaiting
   */
  awaiting: GetWebCase_case_changes_after_visitation_awaiting;
  /**
   * A set of answers related to the damage cause
   */
  answers: GetWebCase_case_changes_after_visitation_answers[] | null;
}

export interface GetWebCase_case_changes_after_requisitioner {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Requisitioner relation
   */
  relation: string;
}

export interface GetWebCase_case_changes_after_drivingSlips_driver {
  /**
   * Name of user
   */
  name: string;
}

export interface GetWebCase_case_changes_after_drivingSlips_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
}

export interface GetWebCase_case_changes_after_drivingSlips {
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetWebCase_case_changes_after_drivingSlips_driver | null;
  /**
   * Car added to the drivingslip
   */
  car: GetWebCase_case_changes_after_drivingSlips_car | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
}

export interface GetWebCase_case_changes_after_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetWebCase_case_changes_after_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetWebCase_case_changes_after_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebCase_case_changes_after_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: GetWebCase_case_changes_after_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface GetWebCase_case_changes_after_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebCase_case_changes_after_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: GetWebCase_case_changes_after_ssgDepartment_location_address;
}

export interface GetWebCase_case_changes_after_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: GetWebCase_case_changes_after_ssgDepartment_location;
}

export interface GetWebCase_case_changes_after_caseEmails {
  /**
   * Email content
   */
  content: string;
  /**
   * List of email attachments
   */
  attachments: string[];
  /**
   * List of email receivers
   */
  receivers: string[];
  /**
   * Timestamp of when email was created
   */
  timestamp: GQL_DateTime;
}

export interface GetWebCase_case_changes_after_caseMachineUsages {
  /**
   * Machine identifier
   */
  machine: string;
  /**
   * Start time
   */
  startTime: GQL_DateTime;
  /**
   * End time
   */
  endTime: GQL_DateTime | null;
  /**
   * Meter reading at start time
   */
  startMeterReading: GQL_Decimal;
  /**
   * Meter reading at end time
   */
  endMeterReading: GQL_Decimal | null;
}

export interface GetWebCase_case_changes_after_alternativeContact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Comment on person
   */
  comment: string | null;
}

export interface GetWebCase_case_changes_after_riskEvaluationAnswers_question {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question
   */
  question: string;
}

export interface GetWebCase_case_changes_after_riskEvaluationAnswers {
  /**
   * Id of question answer is linked to
   */
  question: GetWebCase_case_changes_after_riskEvaluationAnswers_question;
  /**
   * Answer given
   */
  answer: boolean;
}

export interface GetWebCase_case_changes_after {
  /**
   * Case status
   */
  status: CaseStatus | null;
  /**
   * Case track
   */
  track: number | null;
  /**
   * Case debitor details
   */
  debitor: GetWebCase_case_changes_after_debitor | null;
  /**
   * Case damage details
   */
  damage: GetWebCase_case_changes_after_damage | null;
  /**
   * Case policy holder details
   */
  policyHolder: GetWebCase_case_changes_after_policyHolder | null;
  /**
   * Case visitation details
   */
  visitation: GetWebCase_case_changes_after_visitation | null;
  /**
   * Case requisitioner details
   */
  requisitioner: GetWebCase_case_changes_after_requisitioner | null;
  /**
   * The driving slip changed on case
   */
  drivingSlips: GetWebCase_case_changes_after_drivingSlips | null;
  /**
   * Project manager attached to case
   */
  projectManager: GetWebCase_case_changes_after_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: GetWebCase_case_changes_after_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: GetWebCase_case_changes_after_ssgLocation | null;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: GetWebCase_case_changes_after_ssgDepartment | null;
  /**
   * List of case emails
   */
  caseEmails: GetWebCase_case_changes_after_caseEmails[] | null;
  /**
   * List of case machine usages
   */
  caseMachineUsages: GetWebCase_case_changes_after_caseMachineUsages[] | null;
  /**
   * Important info on case
   */
  importantInfo: string | null;
  /**
   * Requisition from fire department
   */
  skafor: boolean | null;
  /**
   * Alternative contact person
   */
  alternativeContact: GetWebCase_case_changes_after_alternativeContact | null;
  /**
   * Risk evaluation answers
   */
  riskEvaluationAnswers: GetWebCase_case_changes_after_riskEvaluationAnswers[] | null;
  /**
   * Name of files
   */
  fileNames: string | null;
}

export interface GetWebCase_case_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: GetWebCase_case_changes_user | null;
  /**
   * Value before
   */
  before: GetWebCase_case_changes_before;
  /**
   * Value after
   */
  after: GetWebCase_case_changes_after;
}

export interface GetWebCase_case_caseReports_inspectionReportFormData {
  __typename: "InspectionReportFormData";
}

export interface GetWebCase_case_caseReports_indoorClimateReportFormData {
  __typename: "IndoorClimateReportFormData";
}

export interface GetWebCase_case_caseReports_moistureReportFormData {
  __typename: "MoistureReportFormData";
}

export interface GetWebCase_case_caseReports_moistureTrygReportFormData {
  __typename: "MoistureTrygReportFormData";
}

export interface GetWebCase_case_caseReports {
  /**
   * Inspection report form data populated by the user
   */
  inspectionReportFormData: GetWebCase_case_caseReports_inspectionReportFormData | null;
  /**
   * Indooor climate report form data populated by the user
   */
  indoorClimateReportFormData: GetWebCase_case_caseReports_indoorClimateReportFormData | null;
  /**
   * Moisture report form data populated by the user
   */
  moistureReportFormData: GetWebCase_case_caseReports_moistureReportFormData | null;
  /**
   * Moisture TRYG report form data populated by the user
   */
  moistureTrygReportFormData: GetWebCase_case_caseReports_moistureTrygReportFormData | null;
}

export interface GetWebCase_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * ReportSent
   */
  reportSent: boolean;
  /**
   * Distance from ssg location to damage address
   */
  distance: string | null;
  /**
   * Whether notifications regarding machines on the case should be sent
   */
  machineNotification: boolean | null;
  /**
   * Whether time registration is allowed on the case
   */
  timeRegistration: boolean | null;
  /**
   * Case debitor details
   */
  debitor: GetWebCase_case_debitor;
  /**
   * Case damage details
   */
  damage: GetWebCase_case_damage;
  /**
   * Case policy holder details
   */
  policyHolder: GetWebCase_case_policyHolder;
  /**
   * Case visitation details
   */
  visitation: GetWebCase_case_visitation;
  /**
   * Case requisitioner details
   */
  requisitioner: GetWebCase_case_requisitioner;
  /**
   * Case advisor details
   */
  adviser: GetWebCase_case_adviser | null;
  /**
   * Case referer details
   */
  referrer: GetWebCase_case_referrer | null;
  /**
   * Project manager attached to case
   */
  projectManager: GetWebCase_case_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: GetWebCase_case_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: GetWebCase_case_ssgLocation;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: GetWebCase_case_ssgDepartment;
  /**
   * Case ready for billing
   */
  billingReady: boolean;
  /**
   * Case work complete
   */
  workComplete: boolean;
  /**
   * List of case agreements
   */
  caseAgreements: GetWebCase_case_caseAgreements[];
  /**
   * List of case emails
   */
  caseEmails: GetWebCase_case_caseEmails[];
  /**
   * List of user favorite entries
   */
  userFavorite: GetWebCase_case_userFavorite | null;
  /**
   * User who created the case
   */
  createdBy: GetWebCase_case_createdBy | null;
  /**
   * Timestamp of when the case was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the case was created
   */
  createdAt: GQL_DateTime;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * The tasks associated with the task
   */
  jobs: GetWebCase_case_jobs[] | null;
  /**
   * The driving slips series associated with the case
   */
  drivingSlipSeries: GetWebCase_case_drivingSlipSeries[];
  /**
   * Important info on the case
   */
  importantInfo: string | null;
  /**
   * Alternative contact
   */
  alternativeContact: GetWebCase_case_alternativeContact | null;
  /**
   * Risk evaluation answers
   */
  riskEvaluationAnswers: GetWebCase_case_riskEvaluationAnswers[] | null;
  /**
   * Optional comment for risk evaluation
   */
  riskEvaluationComment: string | null;
  /**
   * Requisition of the case
   */
  requisitions: GetWebCase_case_requisitions[];
  scalePointStatus: ScalePointStatus;
  scalePointErrorMessage: string | null;
  arrived: boolean;
  arrivedDate: GQL_DateTime | null;
  /**
   * Active movables on case
   */
  activeMovables: boolean;
  /**
   * Bool indicating if case have active Machines
   */
  machineOnCase: boolean;
  scalePointUrl: string | null;
  /**
   * Active requisitions on case
   */
  activeRequisitions: boolean;
  /**
   * Status of active  (always empty if user lacks permissions)
   */
  activeInvoiceStatus: InvoiceStatus | null;
  /**
   * Case change version number
   */
  version: number;
  /**
   * List of projectmanager favorite entries
   */
  projectManagerFavorite: GetWebCase_case_projectManagerFavorite | null;
  /**
   * List of casemanager favorite entries
   */
  caseManagerFavorite: GetWebCase_case_caseManagerFavorite | null;
  /**
   * Case change log
   */
  changes: GetWebCase_case_changes[];
  caseReports: GetWebCase_case_caseReports | null;
}

export interface GetWebCase {
  case: GetWebCase_case;
}

export interface GetWebCaseVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWebCaseAdminData
// ====================================================

export interface GetWebCaseAdminData_damageCategories {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetWebCaseAdminData_damageCauses_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetWebCaseAdminData_damageCauses_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetWebCaseAdminData_damageCauses_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetWebCaseAdminData_damageCauses_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: GetWebCaseAdminData_damageCauses_departments_location;
}

export interface GetWebCaseAdminData_damageCauses {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: GetWebCaseAdminData_damageCauses_businessArea;
  /**
   * Id of associated category
   */
  category: GetWebCaseAdminData_damageCauses_category;
  /**
   * Departments associated to damage cause
   */
  departments: (GetWebCaseAdminData_damageCauses_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface GetWebCaseAdminData_departments_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebCaseAdminData_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: GetWebCaseAdminData_departments_location_address;
}

export interface GetWebCaseAdminData_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: GetWebCaseAdminData_departments_location;
}

export interface GetWebCaseAdminData_locations_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebCaseAdminData_locations {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: GetWebCaseAdminData_locations_address;
  /**
   * Email attached to location
   */
  email: string;
  /**
   * Input string to resolve attached postal codes
   */
  attachedPostalCodesInputString: string;
  /**
   * Attached postal codes on location
   */
  attachedPostalCodes: string[];
}

export interface GetWebCaseAdminData_drivingSlipCategories {
  /**
   * Category code
   */
  code: string;
  /**
   * Category name
   */
  name: string;
}

export interface GetWebCaseAdminData {
  damageCategories: GetWebCaseAdminData_damageCategories[];
  damageCauses: GetWebCaseAdminData_damageCauses[];
  departments: GetWebCaseAdminData_departments[];
  locations: GetWebCaseAdminData_locations[];
  drivingSlipCategories: GetWebCaseAdminData_drivingSlipCategories[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWebCases
// ====================================================

export interface GetWebCases_cases_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebCases_cases_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebCases_cases_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: GetWebCases_cases_debitor_shippingAddress_address;
}

export interface GetWebCases_cases_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebCases_cases_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: GetWebCases_cases_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: GetWebCases_cases_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: GetWebCases_cases_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface GetWebCases_cases_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetWebCases_cases_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetWebCases_cases_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetWebCases_cases_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetWebCases_cases_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetWebCases_cases_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: GetWebCases_cases_damage_cause_departments_location;
}

export interface GetWebCases_cases_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: GetWebCases_cases_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: GetWebCases_cases_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (GetWebCases_cases_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface GetWebCases_cases_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebCases_cases_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetWebCases_cases_damage_contact_address;
}

export interface GetWebCases_cases_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface GetWebCases_cases_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: GetWebCases_cases_damage_businessArea;
  /**
   * Damage category
   */
  category: GetWebCases_cases_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetWebCases_cases_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: GetWebCases_cases_damage_contact;
  /**
   * contacts for damage
   */
  contacts: GetWebCases_cases_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface GetWebCases_cases_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebCases_cases_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetWebCases_cases_policyHolder_address;
}

export interface GetWebCases_cases_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetWebCases_cases_visitation_awaiting {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetWebCases_cases_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
}

export interface GetWebCases_cases_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: GetWebCases_cases_visitation_calledBack;
  /**
   * If case is awaiting
   */
  awaiting: GetWebCases_cases_visitation_awaiting;
  /**
   * A set of answers related to the damage cause
   */
  answers: GetWebCases_cases_visitation_answers[] | null;
  /**
   * If case needs an SP phase one report
   */
  isSpPhaseOneReportCreated: boolean | null;
}

export interface GetWebCases_cases_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetWebCases_cases_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetWebCases_cases_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebCases_cases_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: GetWebCases_cases_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface GetWebCases_cases_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebCases_cases_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: GetWebCases_cases_ssgDepartment_location_address;
}

export interface GetWebCases_cases_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: GetWebCases_cases_ssgDepartment_location;
}

export interface GetWebCases_cases_userFavorite {
  /**
   * Unique user id
   */
  user: string;
  /**
   * Color code set by user
   */
  color: string | null;
  /**
   * Favorite active status
   */
  active: boolean;
}

export interface GetWebCases_cases_projectManagerFavorite {
  /**
   * Unique user id
   */
  user: string;
  /**
   * Color code set by user
   */
  color: string | null;
  /**
   * Favorite active status
   */
  active: boolean;
}

export interface GetWebCases_cases_caseManagerFavorite {
  /**
   * Unique user id
   */
  user: string;
  /**
   * Color code set by user
   */
  color: string | null;
  /**
   * Favorite active status
   */
  active: boolean;
}

export interface GetWebCases_cases_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetWebCases_cases_jobs_assignedTo {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
}

export interface GetWebCases_cases_jobs_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetWebCases_cases_jobs_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
}

export interface GetWebCases_cases_jobs_movable_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface GetWebCases_cases_jobs_movable {
  /**
   * Unique id of movable
   */
  id: string;
  /**
   * Description of movable
   */
  description: string;
  /**
   * Movable status
   */
  status: string;
  /**
   * Movable placement
   */
  placement: GetWebCases_cases_jobs_movable_placement;
}

export interface GetWebCases_cases_jobs {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Type of case task
   */
  type: JobType;
  /**
   * Description of the case task
   */
  description: string;
  /**
   * Deadline of the case task
   */
  deadline: GQL_DateTime;
  /**
   * The user responsible for the task
   */
  assignedTo: GetWebCases_cases_jobs_assignedTo | null;
  /**
   * The location responsible to the task
   */
  location: GetWebCases_cases_jobs_location | null;
  /**
   * The case the task belongs to
   */
  case: GetWebCases_cases_jobs_case | null;
  /**
   * The movable responsible to the task
   */
  movable: GetWebCases_cases_jobs_movable | null;
  /**
   * Whether the task is completed
   */
  completed: boolean;
}

export interface GetWebCases_cases_drivingSlipSeries_drivingSlips_driver {
  /**
   * Name of user
   */
  name: string;
}

export interface GetWebCases_cases_drivingSlipSeries_drivingSlips {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetWebCases_cases_drivingSlipSeries_drivingSlips_driver | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
}

export interface GetWebCases_cases_drivingSlipSeries {
  /**
   * Unique id of driving slip series
   */
  id: string;
  /**
   * Driving slip series completed status
   */
  completed: boolean;
  /**
   * Driving slips on series
   */
  drivingSlips: GetWebCases_cases_drivingSlipSeries_drivingSlips[];
}

export interface GetWebCases_cases_caseReports_inspectionReportFormData {
  __typename: "InspectionReportFormData";
}

export interface GetWebCases_cases_caseReports_indoorClimateReportFormData {
  __typename: "IndoorClimateReportFormData";
}

export interface GetWebCases_cases_caseReports_moistureReportFormData {
  __typename: "MoistureReportFormData";
}

export interface GetWebCases_cases_caseReports_moistureTrygReportFormData {
  __typename: "MoistureTrygReportFormData";
}

export interface GetWebCases_cases_caseReports {
  /**
   * Inspection report form data populated by the user
   */
  inspectionReportFormData: GetWebCases_cases_caseReports_inspectionReportFormData | null;
  /**
   * Indooor climate report form data populated by the user
   */
  indoorClimateReportFormData: GetWebCases_cases_caseReports_indoorClimateReportFormData | null;
  /**
   * Moisture report form data populated by the user
   */
  moistureReportFormData: GetWebCases_cases_caseReports_moistureReportFormData | null;
  /**
   * Moisture TRYG report form data populated by the user
   */
  moistureTrygReportFormData: GetWebCases_cases_caseReports_moistureTrygReportFormData | null;
}

export interface GetWebCases_cases_invoices {
  /**
   * Reference no invoice
   */
  erpReferenceNo: string;
  /**
   * Invoice status
   */
  status: InvoiceStatus;
  /**
   * Category of invoice
   */
  category: InvoiceCategory;
}

export interface GetWebCases_cases_caseEconomics {
  /**
   * JobNo
   */
  jobNo: string;
  /**
   * costTotal
   */
  costTotal: GQL_Decimal;
  /**
   * salesTotal
   */
  salesTotal: GQL_Decimal;
  /**
   * invoicedTotal
   */
  invoicedTotal: GQL_Decimal;
  /**
   * quote
   */
  quote: GQL_Decimal;
  /**
   * screening
   */
  screening: GQL_Decimal;
  /**
   * profit
   */
  profit: GQL_Decimal;
  /**
   * deltaNotInvoiced
   */
  deltaNotInvoiced: GQL_Decimal;
  /**
   * coverageRatio
   */
  coverageRatio: GQL_Decimal;
}

export interface GetWebCases_cases {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * ReportSent
   */
  reportSent: boolean;
  /**
   * Distance from ssg location to damage address
   */
  distance: string | null;
  /**
   * Whether notifications regarding machines on the case should be sent
   */
  machineNotification: boolean | null;
  /**
   * Whether time registration is allowed on the case
   */
  timeRegistration: boolean | null;
  /**
   * Case debitor details
   */
  debitor: GetWebCases_cases_debitor;
  /**
   * Case damage details
   */
  damage: GetWebCases_cases_damage;
  /**
   * Case policy holder details
   */
  policyHolder: GetWebCases_cases_policyHolder;
  /**
   * Case visitation details
   */
  visitation: GetWebCases_cases_visitation;
  /**
   * Project manager attached to case
   */
  projectManager: GetWebCases_cases_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: GetWebCases_cases_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: GetWebCases_cases_ssgLocation;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: GetWebCases_cases_ssgDepartment;
  /**
   * Case ready for billing
   */
  billingReady: boolean;
  /**
   * Case work complete
   */
  workComplete: boolean;
  /**
   * List of user favorite entries
   */
  userFavorite: GetWebCases_cases_userFavorite | null;
  /**
   * List of projectmanager favorite entries
   */
  projectManagerFavorite: GetWebCases_cases_projectManagerFavorite | null;
  /**
   * List of casemanager favorite entries
   */
  caseManagerFavorite: GetWebCases_cases_caseManagerFavorite | null;
  /**
   * User who created the case
   */
  createdBy: GetWebCases_cases_createdBy | null;
  /**
   * Timestamp of when the case was created
   */
  createdAt: GQL_DateTime;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * The tasks associated with the task
   */
  jobs: GetWebCases_cases_jobs[] | null;
  /**
   * The driving slips series associated with the case
   */
  drivingSlipSeries: GetWebCases_cases_drivingSlipSeries[];
  caseReports: GetWebCases_cases_caseReports | null;
  /**
   * Active movables on case
   */
  activeMovables: boolean;
  /**
   * Bool indicating if case have active Machines
   */
  machineOnCase: boolean;
  /**
   * Active requisitions on case
   */
  activeRequisitions: boolean;
  /**
   * Status of active  (always empty if user lacks permissions)
   */
  activeInvoiceStatus: InvoiceStatus | null;
  /**
   * List of invoices related to case (always empty if user lacks permissions)
   */
  invoices: GetWebCases_cases_invoices[];
  scalePointStatus: ScalePointStatus;
  scalePointErrorMessage: string | null;
  caseEconomics: GetWebCases_cases_caseEconomics | null;
  arrived: boolean;
  arrivedDate: GQL_DateTime | null;
}

export interface GetWebCases {
  cases: GetWebCases_cases[];
}

export interface GetWebCasesVariables {
  address?: AddressInput | null;
  awaiting?: boolean | null;
  damageServiceCompleted?: boolean | null;
  calledBack?: boolean | null;
  machinesOnCase?: boolean | null;
  ownCases?: boolean | null;
  favoriteCases?: boolean | null;
  readyForInvoice?: boolean | null;
  appliedClosed?: boolean | null;
  closedCases?: boolean | null;
  priorityCustomers?: boolean | null;
  debitor?: string[] | null;
  postalCode?: string[] | null;
  track?: number[] | null;
  department?: string[] | null;
  location?: string[] | null;
  damageCategory?: string[] | null;
  damageCause?: string[] | null;
  priority?: CasePriority[] | null;
  caseManager?: string[] | null;
  projectManager?: string[] | null;
  anyManager?: string | null;
  minDate?: GQL_Date | null;
  maxDate?: GQL_Date | null;
  erpReferenceNo?: string | null;
  erpReferenceNos?: string[] | null;
  favoriteColors?: string[] | null;
  includeEconomy: boolean;
  offset?: number | null;
  limit?: number | null;
  searchString?: string | null;
  excludeTotalCount?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWebCasesByErpNo
// ====================================================

export interface GetWebCasesByErpNo_cases_debitor {
  /**
   * Company identifier
   */
  company: string;
}

export interface GetWebCasesByErpNo_cases_damage_category {
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetWebCasesByErpNo_cases_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
}

export interface GetWebCasesByErpNo_cases_damage_businessArea {
  /**
   * Name of business area
   */
  name: string;
}

export interface GetWebCasesByErpNo_cases_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebCasesByErpNo_cases_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetWebCasesByErpNo_cases_damage_contact_address;
}

export interface GetWebCasesByErpNo_cases_damage {
  /**
   * Damage category
   */
  category: GetWebCasesByErpNo_cases_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetWebCasesByErpNo_cases_damage_cause;
  /**
   * BusinessArea for damage
   */
  businessArea: GetWebCasesByErpNo_cases_damage_businessArea;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: GetWebCasesByErpNo_cases_damage_contact;
}

export interface GetWebCasesByErpNo_cases_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetWebCasesByErpNo_cases_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetWebCasesByErpNo_cases {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * Whether time registration is allowed on the case
   */
  timeRegistration: boolean | null;
  /**
   * Case debitor details
   */
  debitor: GetWebCasesByErpNo_cases_debitor;
  /**
   * Case damage details
   */
  damage: GetWebCasesByErpNo_cases_damage;
  /**
   * Project manager attached to case
   */
  projectManager: GetWebCasesByErpNo_cases_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: GetWebCasesByErpNo_cases_caseManager | null;
}

export interface GetWebCasesByErpNo {
  cases: GetWebCasesByErpNo_cases[];
}

export interface GetWebCasesByErpNoVariables {
  erpReferenceNo: string;
  closedCases?: boolean | null;
  appliedClosed?: boolean | null;
  searchString?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWebCasesForCatalogSuggestions
// ====================================================

export interface GetWebCasesForCatalogSuggestions_cases_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
}

export interface GetWebCasesForCatalogSuggestions_cases_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebCasesForCatalogSuggestions_cases_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetWebCasesForCatalogSuggestions_cases_damage_contact_address;
}

export interface GetWebCasesForCatalogSuggestions_cases_damage {
  /**
   * Damage location contact information
   */
  contact: GetWebCasesForCatalogSuggestions_cases_damage_contact;
}

export interface GetWebCasesForCatalogSuggestions_cases {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetWebCasesForCatalogSuggestions_cases_debitor;
  /**
   * Case damage details
   */
  damage: GetWebCasesForCatalogSuggestions_cases_damage;
}

export interface GetWebCasesForCatalogSuggestions {
  cases: GetWebCasesForCatalogSuggestions_cases[];
}

export interface GetWebCasesForCatalogSuggestionsVariables {
  erpReferenceNo: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RejectCaseClosing
// ====================================================

export interface RejectCaseClosing_rejectCaseClosing {
  /**
   * Case status
   */
  status: CaseStatus;
}

export interface RejectCaseClosing {
  rejectCaseClosing: RejectCaseClosing_rejectCaseClosing;
}

export interface RejectCaseClosingVariables {
  id: string;
  email: string;
  rejectionComment: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SearchCasesByERPNo
// ====================================================

export interface SearchCasesByERPNo_searchCasesByERPNo {
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Unique id of case
   */
  id: string;
}

export interface SearchCasesByERPNo {
  searchCasesByERPNo: SearchCasesByERPNo_searchCasesByERPNo[];
}

export interface SearchCasesByERPNoVariables {
  erpNo: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendCaseEmail
// ====================================================

export interface SendCaseEmail_sendCaseEmail {
  /**
   * This value is not used for anything.
   */
  _: string;
}

export interface SendCaseEmail {
  sendCaseEmail: SendCaseEmail_sendCaseEmail | null;
}

export interface SendCaseEmailVariables {
  caseId: string;
  to: string[];
  cc: string[];
  bcc: string[];
  subject: string;
  body: string;
  includeCaseAgreements: boolean;
  quote: boolean;
  attachements?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetCostEstimate
// ====================================================

export interface SetCostEstimate {
  setCostEstimate: GQL_Decimal;
}

export interface SetCostEstimateVariables {
  erpNo: string;
  price: GQL_Decimal;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetSSGPrice
// ====================================================

export interface SetSSGPrice {
  setSSGPrice: GQL_Decimal;
}

export interface SetSSGPriceVariables {
  erpNo: string;
  price: GQL_Decimal;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCase
// ====================================================

export interface UpdateCase_updateCase_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateCase_updateCase_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateCase_updateCase_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: UpdateCase_updateCase_debitor_shippingAddress_address;
}

export interface UpdateCase_updateCase_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateCase_updateCase_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: UpdateCase_updateCase_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: UpdateCase_updateCase_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: UpdateCase_updateCase_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface UpdateCase_updateCase_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface UpdateCase_updateCase_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface UpdateCase_updateCase_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface UpdateCase_updateCase_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface UpdateCase_updateCase_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface UpdateCase_updateCase_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: UpdateCase_updateCase_damage_cause_departments_location;
}

export interface UpdateCase_updateCase_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: UpdateCase_updateCase_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: UpdateCase_updateCase_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (UpdateCase_updateCase_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface UpdateCase_updateCase_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateCase_updateCase_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: UpdateCase_updateCase_damage_contact_address;
}

export interface UpdateCase_updateCase_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface UpdateCase_updateCase_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: UpdateCase_updateCase_damage_businessArea;
  /**
   * Damage category
   */
  category: UpdateCase_updateCase_damage_category;
  /**
   * Cause behind damage
   */
  cause: UpdateCase_updateCase_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: UpdateCase_updateCase_damage_contact;
  /**
   * contacts for damage
   */
  contacts: UpdateCase_updateCase_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface UpdateCase_updateCase_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateCase_updateCase_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: UpdateCase_updateCase_policyHolder_address;
}

export interface UpdateCase_updateCase_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface UpdateCase_updateCase_visitation_awaiting {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface UpdateCase_updateCase_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
  /**
   * The question
   */
  title: string;
}

export interface UpdateCase_updateCase_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: UpdateCase_updateCase_visitation_calledBack;
  /**
   * If case is awaiting
   */
  awaiting: UpdateCase_updateCase_visitation_awaiting;
  /**
   * A set of answers related to the damage cause
   */
  answers: UpdateCase_updateCase_visitation_answers[] | null;
  /**
   * If case needs a moisture report
   */
  isMoistureReportRequired: boolean | null;
  /**
   * If case needs an SP phase one report
   */
  isSpPhaseOneReportCreated: boolean | null;
}

export interface UpdateCase_updateCase_requisitioner {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Requisitioner relation
   */
  relation: string;
}

export interface UpdateCase_updateCase_adviser {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface UpdateCase_updateCase_referrer {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface UpdateCase_updateCase_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateCase_updateCase_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateCase_updateCase_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateCase_updateCase_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: UpdateCase_updateCase_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface UpdateCase_updateCase_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateCase_updateCase_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: UpdateCase_updateCase_ssgDepartment_location_address;
}

export interface UpdateCase_updateCase_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: UpdateCase_updateCase_ssgDepartment_location;
}

export interface UpdateCase_updateCase_caseAgreements_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateCase_updateCase_caseAgreements {
  /**
   * Agreement content
   */
  content: string;
  /**
   * Agreement category
   */
  category: CaseAgreementCategory;
  /**
   * User who created agreement
   */
  user: UpdateCase_updateCase_caseAgreements_user;
  /**
   * Timestamp of when agreement was created
   */
  timestamp: GQL_DateTime;
  /**
   * Whether case agreement should be sent to ScalePoint
   */
  shouldSaveInScalePoint: boolean;
}

export interface UpdateCase_updateCase_caseEmails {
  /**
   * Email content
   */
  content: string;
  /**
   * List of email attachments
   */
  attachments: string[];
  /**
   * List of email receivers
   */
  receivers: string[];
  /**
   * Timestamp of when email was created
   */
  timestamp: GQL_DateTime;
}

export interface UpdateCase_updateCase_userFavorite {
  /**
   * Unique user id
   */
  user: string;
  /**
   * Color code set by user
   */
  color: string | null;
  /**
   * Favorite active status
   */
  active: boolean;
}

export interface UpdateCase_updateCase_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateCase_updateCase_jobs_assignedTo {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
}

export interface UpdateCase_updateCase_jobs_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface UpdateCase_updateCase_jobs_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
}

export interface UpdateCase_updateCase_jobs_movable_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface UpdateCase_updateCase_jobs_movable {
  /**
   * Unique id of movable
   */
  id: string;
  /**
   * Description of movable
   */
  description: string;
  /**
   * Movable status
   */
  status: string;
  /**
   * Movable placement
   */
  placement: UpdateCase_updateCase_jobs_movable_placement;
}

export interface UpdateCase_updateCase_jobs {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Type of case task
   */
  type: JobType;
  /**
   * Description of the case task
   */
  description: string;
  /**
   * Deadline of the case task
   */
  deadline: GQL_DateTime;
  /**
   * The user responsible for the task
   */
  assignedTo: UpdateCase_updateCase_jobs_assignedTo | null;
  /**
   * The location responsible to the task
   */
  location: UpdateCase_updateCase_jobs_location | null;
  /**
   * The case the task belongs to
   */
  case: UpdateCase_updateCase_jobs_case | null;
  /**
   * The movable responsible to the task
   */
  movable: UpdateCase_updateCase_jobs_movable | null;
  /**
   * Whether the task is completed
   */
  completed: boolean;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_user;
  /**
   * Value before
   */
  before: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_before;
  /**
   * Value after
   */
  after: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_after;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes[];
  /**
   * Location of driving slip
   */
  location: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_location;
  /**
   * Department of driving slip
   */
  department: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
}

export interface UpdateCase_updateCase_drivingSlipSeries {
  /**
   * Unique id of driving slip series
   */
  id: string;
  /**
   * Driving slip series completed status
   */
  completed: boolean;
  /**
   * Driving slips on series
   */
  drivingSlips: UpdateCase_updateCase_drivingSlipSeries_drivingSlips[];
}

export interface UpdateCase_updateCase_alternativeContact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Comment on person
   */
  comment: string | null;
}

export interface UpdateCase_updateCase_riskEvaluationAnswers_question {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question
   */
  question: string;
}

export interface UpdateCase_updateCase_riskEvaluationAnswers {
  /**
   * Id of question answer is linked to
   */
  question: UpdateCase_updateCase_riskEvaluationAnswers_question;
  /**
   * Answer given
   */
  answer: boolean;
}

export interface UpdateCase_updateCase_requisitions_case_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
}

export interface UpdateCase_updateCase_requisitions_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
  /**
   * Id of damage category
   */
  id: string;
}

export interface UpdateCase_updateCase_requisitions_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of damage cause
   */
  id: string;
}

export interface UpdateCase_updateCase_requisitions_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateCase_updateCase_requisitions_case_damage_contact {
  /**
   * Contact address information
   */
  address: UpdateCase_updateCase_requisitions_case_damage_contact_address;
}

export interface UpdateCase_updateCase_requisitions_case_damage {
  /**
   * Damage category
   */
  category: UpdateCase_updateCase_requisitions_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: UpdateCase_updateCase_requisitions_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: UpdateCase_updateCase_requisitions_case_damage_contact;
}

export interface UpdateCase_updateCase_requisitions_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: UpdateCase_updateCase_requisitions_case_debitor;
  /**
   * Case damage details
   */
  damage: UpdateCase_updateCase_requisitions_case_damage;
}

export interface UpdateCase_updateCase_requisitions_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
}

export interface UpdateCase_updateCase_requisitions_drivingSlip_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
}

export interface UpdateCase_updateCase_requisitions_drivingSlip_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
}

export interface UpdateCase_updateCase_requisitions_drivingSlip_case_damage {
  /**
   * Damage category
   */
  category: UpdateCase_updateCase_requisitions_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: UpdateCase_updateCase_requisitions_drivingSlip_case_damage_cause;
}

export interface UpdateCase_updateCase_requisitions_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: UpdateCase_updateCase_requisitions_drivingSlip_case_damage;
}

export interface UpdateCase_updateCase_requisitions_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Case which driving slip is related to
   */
  case: UpdateCase_updateCase_requisitions_drivingSlip_case;
}

export interface UpdateCase_updateCase_requisitions_vendor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateCase_updateCase_requisitions_vendor {
  /**
   * ERP reference no for vendor
   */
  erpReferenceNo: string;
  /**
   * The name of the vendor
   */
  company: string | null;
  /**
   * The address of the vendor
   */
  address: UpdateCase_updateCase_requisitions_vendor_address | null;
  /**
   * The email of the vendor
   */
  email: string | null;
  /**
   * The phone number of the vendor
   */
  phone: string | null;
  /**
   * The posting group of the vendor
   */
  vendorPostingGroup: string;
  /**
   * Attached SSG department of the vendor
   */
  ssgDepartment: string;
  /**
   * Attached SSG location of the vendor
   */
  ssgLocation: string;
  /**
   * Vendor type
   */
  type: string;
}

export interface UpdateCase_updateCase_requisitions_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface UpdateCase_updateCase_requisitions {
  /**
   * id of the requisition
   */
  id: string;
  /**
   * case owning the requisition
   */
  case: UpdateCase_updateCase_requisitions_case | null;
  /**
   * location owning the requisition
   */
  department: UpdateCase_updateCase_requisitions_department | null;
  /**
   * driving slip owning the requisition
   */
  drivingSlip: UpdateCase_updateCase_requisitions_drivingSlip | null;
  /**
   * description of the requisition
   */
  description: string;
  /**
   * orderNumber of the requisition
   */
  orderNumber: string;
  /**
   * the type of the requisition
   */
  type: RequisitionType;
  /**
   * Requisition status
   */
  status: boolean;
  /**
   * the vendor of the requisition
   */
  vendor: UpdateCase_updateCase_requisitions_vendor | null;
  /**
   * Timestamp of when the requisition was created
   */
  createdAt: GQL_DateTime;
  /**
   * User who created the requisition
   */
  createdBy: UpdateCase_updateCase_requisitions_createdBy;
  /**
   * email of the vendor
   */
  vendorEmail: string | null;
  /**
   * phone number of the vendor
   */
  vendorPhoneNumber: string;
  /**
   * name of the vendor
   */
  vendorName: string;
}

export interface UpdateCase_updateCase {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * ReportSent
   */
  reportSent: boolean;
  /**
   * Distance from ssg location to damage address
   */
  distance: string | null;
  /**
   * Whether notifications regarding machines on the case should be sent
   */
  machineNotification: boolean | null;
  /**
   * Whether time registration is allowed on the case
   */
  timeRegistration: boolean | null;
  /**
   * Case debitor details
   */
  debitor: UpdateCase_updateCase_debitor;
  /**
   * Case damage details
   */
  damage: UpdateCase_updateCase_damage;
  /**
   * Case policy holder details
   */
  policyHolder: UpdateCase_updateCase_policyHolder;
  /**
   * Case visitation details
   */
  visitation: UpdateCase_updateCase_visitation;
  /**
   * Case requisitioner details
   */
  requisitioner: UpdateCase_updateCase_requisitioner;
  /**
   * Case advisor details
   */
  adviser: UpdateCase_updateCase_adviser | null;
  /**
   * Case referer details
   */
  referrer: UpdateCase_updateCase_referrer | null;
  /**
   * Project manager attached to case
   */
  projectManager: UpdateCase_updateCase_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: UpdateCase_updateCase_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: UpdateCase_updateCase_ssgLocation;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: UpdateCase_updateCase_ssgDepartment;
  /**
   * Case ready for billing
   */
  billingReady: boolean;
  /**
   * Case work complete
   */
  workComplete: boolean;
  /**
   * List of case agreements
   */
  caseAgreements: UpdateCase_updateCase_caseAgreements[];
  /**
   * List of case emails
   */
  caseEmails: UpdateCase_updateCase_caseEmails[];
  /**
   * List of user favorite entries
   */
  userFavorite: UpdateCase_updateCase_userFavorite | null;
  /**
   * User who created the case
   */
  createdBy: UpdateCase_updateCase_createdBy | null;
  /**
   * Timestamp of when the case was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the case was created
   */
  createdAt: GQL_DateTime;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * The tasks associated with the task
   */
  jobs: UpdateCase_updateCase_jobs[] | null;
  /**
   * The driving slips series associated with the case
   */
  drivingSlipSeries: UpdateCase_updateCase_drivingSlipSeries[];
  /**
   * Important info on the case
   */
  importantInfo: string | null;
  /**
   * Alternative contact
   */
  alternativeContact: UpdateCase_updateCase_alternativeContact | null;
  /**
   * Risk evaluation answers
   */
  riskEvaluationAnswers: UpdateCase_updateCase_riskEvaluationAnswers[] | null;
  /**
   * Optional comment for risk evaluation
   */
  riskEvaluationComment: string | null;
  /**
   * Requisition of the case
   */
  requisitions: UpdateCase_updateCase_requisitions[];
  scalePointStatus: ScalePointStatus;
  scalePointErrorMessage: string | null;
  arrived: boolean;
  arrivedDate: GQL_DateTime | null;
}

export interface UpdateCase {
  updateCase: UpdateCase_updateCase;
}

export interface UpdateCaseVariables {
  id: string;
  status?: CaseStatus | null;
  damage?: DamageInput | null;
  policyHolder?: ContactInput | null;
  debitor?: DebitorInput | null;
  requisitioner?: RequisitionerInput | null;
  adviser?: CompanyContactInput | null;
  referrer?: CompanyContactInput | null;
  visitation?: VisitationInput | null;
  projectManager?: string | null;
  caseManager?: string | null;
  ssgLocation?: string | null;
  ssgDepartment?: string | null;
  billingReady?: boolean | null;
  workComplete?: boolean | null;
  importantInfo?: string | null;
  skafor?: boolean | null;
  alternativeContact?: PersonInput | null;
  track?: number | null;
  riskEvaluationAnswers?: RiskEvaluationAnswerInput[] | null;
  machineNotification?: boolean | null;
  timeRegistration?: boolean | null;
  fixedPriceOnCase?: GQL_Decimal | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePictureComment
// ====================================================

export interface UpdatePictureComment_updatePictureComment {
  /**
   * This value is not used for anything.
   */
  _: string;
}

export interface UpdatePictureComment {
  updatePictureComment: UpdatePictureComment_updatePictureComment | null;
}

export interface UpdatePictureCommentVariables {
  fileID: string;
  comment: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddCatalogToContact
// ====================================================

export interface AddCatalogToContact_addCatalogToContact_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface AddCatalogToContact_addCatalogToContact_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface AddCatalogToContact_addCatalogToContact {
  /**
   * Id for catalog contact
   */
  id: string;
  /**
   * Customer
   */
  customer: AddCatalogToContact_addCatalogToContact_customer;
  /**
   * Contact changed by
   */
  changedBy: string;
  /**
   * Contact name
   */
  contactName: string;
  /**
   * Contact type
   */
  type: string | null;
  /**
   * Contact collection type
   */
  contactType: CatalogContacts;
  /**
   * Contact email
   */
  email: string | null;
  /**
   * Informations for Contacts
   */
  informations: (AddCatalogToContact_addCatalogToContact_informations | null)[];
  /**
   * Is contact global
   */
  global: boolean;
  /**
   * Global type for contact
   */
  globalType: CatalogGlobalType;
  /**
   * Is contact active
   */
  active: boolean;
  /**
   * Catalog ids
   */
  catalogIds: string[];
  /**
   * Version for catalog contact
   */
  version: number;
}

export interface AddCatalogToContact {
  addCatalogToContact: AddCatalogToContact_addCatalogToContact;
}

export interface AddCatalogToContactVariables {
  contactId: string;
  catalogId: string;
  changedBy: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCatalogContact
// ====================================================

export interface CreateCatalogContact_createCatalogContact_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface CreateCatalogContact_createCatalogContact_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface CreateCatalogContact_createCatalogContact_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface CreateCatalogContact_createCatalogContact_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: CreateCatalogContact_createCatalogContact_catalogs_addresses[];
}

export interface CreateCatalogContact_createCatalogContact {
  /**
   * Id for catalog contact
   */
  id: string;
  /**
   * Customer
   */
  customer: CreateCatalogContact_createCatalogContact_customer;
  /**
   * Contact changed by
   */
  changedBy: string;
  /**
   * Contact name
   */
  contactName: string;
  /**
   * Contact type
   */
  type: string | null;
  /**
   * Contact collection type
   */
  contactType: CatalogContacts;
  /**
   * Contact email
   */
  email: string | null;
  /**
   * Informations for Contacts
   */
  informations: (CreateCatalogContact_createCatalogContact_informations | null)[];
  /**
   * Is contact global
   */
  global: boolean;
  /**
   * Global type for contact
   */
  globalType: CatalogGlobalType;
  /**
   * Is contact active
   */
  active: boolean;
  /**
   * Catalog ids
   */
  catalogIds: string[];
  /**
   * Version for catalog contact
   */
  version: number;
  /**
   * Catalogs that uses contact
   */
  catalogs: CreateCatalogContact_createCatalogContact_catalogs[] | null;
}

export interface CreateCatalogContact {
  createCatalogContact: CreateCatalogContact_createCatalogContact;
}

export interface CreateCatalogContactVariables {
  catalogContact: CatalogContactInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteCatalogContact
// ====================================================

export interface DeleteCatalogContact_deleteCatalogContact {
  /**
   * This value is not used for anything.
   */
  _: string;
}

export interface DeleteCatalogContact {
  deleteCatalogContact: DeleteCatalogContact_deleteCatalogContact | null;
}

export interface DeleteCatalogContactVariables {
  id: string;
  changedBy: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCatalogContactChanges
// ====================================================

export interface GetCatalogContactChanges_catalogContact_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetCatalogContactChanges_catalogContact_changes_before_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface GetCatalogContactChanges_catalogContact_changes_before_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface GetCatalogContactChanges_catalogContact_changes_before_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface GetCatalogContactChanges_catalogContact_changes_before_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: GetCatalogContactChanges_catalogContact_changes_before_catalogs_addresses[];
}

export interface GetCatalogContactChanges_catalogContact_changes_before {
  customer: GetCatalogContactChanges_catalogContact_changes_before_customer | null;
  changedBy: string | null;
  /**
   * Contact name
   */
  contactName: string | null;
  type: string | null;
  contactType: CatalogContacts | null;
  /**
   * Contact email
   */
  email: string | null;
  /**
   * Informations for Contacts
   */
  informations: GetCatalogContactChanges_catalogContact_changes_before_informations[] | null;
  /**
   * Is contact global
   */
  global: boolean | null;
  /**
   * Global type for contact
   */
  globalType: CatalogGlobalType | null;
  /**
   * Is contact active
   */
  active: boolean | null;
  catalogs: GetCatalogContactChanges_catalogContact_changes_before_catalogs[] | null;
}

export interface GetCatalogContactChanges_catalogContact_changes_after_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface GetCatalogContactChanges_catalogContact_changes_after_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface GetCatalogContactChanges_catalogContact_changes_after_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface GetCatalogContactChanges_catalogContact_changes_after_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: GetCatalogContactChanges_catalogContact_changes_after_catalogs_addresses[];
}

export interface GetCatalogContactChanges_catalogContact_changes_after {
  customer: GetCatalogContactChanges_catalogContact_changes_after_customer | null;
  changedBy: string | null;
  /**
   * Contact name
   */
  contactName: string | null;
  type: string | null;
  contactType: CatalogContacts | null;
  /**
   * Contact email
   */
  email: string | null;
  /**
   * Informations for Contacts
   */
  informations: GetCatalogContactChanges_catalogContact_changes_after_informations[] | null;
  /**
   * Is contact global
   */
  global: boolean | null;
  /**
   * Global type for contact
   */
  globalType: CatalogGlobalType | null;
  /**
   * Is contact active
   */
  active: boolean | null;
  catalogs: GetCatalogContactChanges_catalogContact_changes_after_catalogs[] | null;
}

export interface GetCatalogContactChanges_catalogContact_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: GetCatalogContactChanges_catalogContact_changes_user;
  /**
   * Value before
   */
  before: GetCatalogContactChanges_catalogContact_changes_before;
  /**
   * Value after
   */
  after: GetCatalogContactChanges_catalogContact_changes_after;
}

export interface GetCatalogContactChanges_catalogContact {
  /**
   * Id for catalog contact
   */
  id: string;
  /**
   * Catalog contact Change log
   */
  changes: GetCatalogContactChanges_catalogContact_changes[];
}

export interface GetCatalogContactChanges {
  catalogContact: GetCatalogContactChanges_catalogContact;
}

export interface GetCatalogContactChangesVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCatalogContactForAddExcisting
// ====================================================

export interface GetCatalogContactForAddExcisting_catalogContacts_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface GetCatalogContactForAddExcisting_catalogContacts {
  /**
   * Id for catalog contact
   */
  id: string;
  /**
   * Contact name
   */
  contactName: string;
  /**
   * Contact type
   */
  type: string | null;
  /**
   * Contact collection type
   */
  contactType: CatalogContacts;
  /**
   * Contact email
   */
  email: string | null;
  /**
   * Informations for Contacts
   */
  informations: (GetCatalogContactForAddExcisting_catalogContacts_informations | null)[];
}

export interface GetCatalogContactForAddExcisting {
  catalogContacts: GetCatalogContactForAddExcisting_catalogContacts[] | null;
}

export interface GetCatalogContactForAddExcistingVariables {
  customerIds?: string[] | null;
  notGlobalOnly?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCatalogContacts
// ====================================================

export interface GetCatalogContacts_catalogContacts_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface GetCatalogContacts_catalogContacts_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface GetCatalogContacts_catalogContacts_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface GetCatalogContacts_catalogContacts_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: GetCatalogContacts_catalogContacts_catalogs_addresses[];
}

export interface GetCatalogContacts_catalogContacts {
  /**
   * Id for catalog contact
   */
  id: string;
  /**
   * Customer
   */
  customer: GetCatalogContacts_catalogContacts_customer;
  /**
   * Contact changed by
   */
  changedBy: string;
  /**
   * Contact name
   */
  contactName: string;
  /**
   * Contact type
   */
  type: string | null;
  /**
   * Contact collection type
   */
  contactType: CatalogContacts;
  /**
   * Contact email
   */
  email: string | null;
  /**
   * Informations for Contacts
   */
  informations: (GetCatalogContacts_catalogContacts_informations | null)[];
  /**
   * Is contact global
   */
  global: boolean;
  /**
   * Global type for contact
   */
  globalType: CatalogGlobalType;
  /**
   * Is contact active
   */
  active: boolean;
  /**
   * Catalog ids
   */
  catalogIds: string[];
  /**
   * Version for catalog contact
   */
  version: number;
  /**
   * Catalogs that uses contact
   */
  catalogs: GetCatalogContacts_catalogContacts_catalogs[] | null;
}

export interface GetCatalogContacts {
  catalogContacts: GetCatalogContacts_catalogContacts[] | null;
}

export interface GetCatalogContactsVariables {
  globalOnly?: boolean | null;
  catalogId?: string | null;
  customerIds?: string[] | null;
  contactType?: CatalogContacts | null;
  thisCustomerOnly?: boolean | null;
  offset?: number | null;
  limit?: number | null;
  searchString?: string | null;
  excludeTotalCount?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCatalogContact
// ====================================================

export interface UpdateCatalogContact_updateCatalogContact_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface UpdateCatalogContact_updateCatalogContact_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface UpdateCatalogContact_updateCatalogContact {
  /**
   * Id for catalog contact
   */
  id: string;
  /**
   * Customer
   */
  customer: UpdateCatalogContact_updateCatalogContact_customer;
  /**
   * Contact changed by
   */
  changedBy: string;
  /**
   * Contact name
   */
  contactName: string;
  /**
   * Contact type
   */
  type: string | null;
  /**
   * Contact collection type
   */
  contactType: CatalogContacts;
  /**
   * Contact email
   */
  email: string | null;
  /**
   * Informations for Contacts
   */
  informations: (UpdateCatalogContact_updateCatalogContact_informations | null)[];
  /**
   * Is contact global
   */
  global: boolean;
  /**
   * Global type for contact
   */
  globalType: CatalogGlobalType;
  /**
   * Is contact active
   */
  active: boolean;
  /**
   * Catalog ids
   */
  catalogIds: string[];
  /**
   * Version for catalog contact
   */
  version: number;
}

export interface UpdateCatalogContact {
  updateCatalogContact: UpdateCatalogContact_updateCatalogContact;
}

export interface UpdateCatalogContactVariables {
  id: string;
  customer: string;
  changedBy: string;
  contactName: string;
  email?: string | null;
  type?: string | null;
  contactType: CatalogContacts;
  globalType: CatalogGlobalType;
  informations: (CatalogPhoneInformationInput | null)[];
  global: boolean;
  active: boolean;
  catalogs?: string[] | null;
  action: FileActionType;
  catalogsBefore?: (string | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddCatalogToCraftsman
// ====================================================

export interface AddCatalogToCraftsman_addCatalogToCraftsman_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface AddCatalogToCraftsman_addCatalogToCraftsman_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface AddCatalogToCraftsman_addCatalogToCraftsman {
  /**
   * Id for catalog call contact
   */
  id: string;
  /**
   * Customer
   */
  customer: AddCatalogToCraftsman_addCatalogToCraftsman_customer;
  /**
   * Craftsman changed by
   */
  changedBy: string;
  /**
   * Craftsman type
   */
  type: string;
  /**
   * Craftsman name
   */
  contactName: string;
  /**
   * Informations for Craftsman
   */
  informations: AddCatalogToCraftsman_addCatalogToCraftsman_informations[];
  /**
   * Craftsman email
   */
  email: string | null;
  /**
   * Craftsman priority
   */
  priority: string;
  /**
   * Is contact global
   */
  global: boolean;
  /**
   * Global type for craftsman
   */
  globalType: CatalogGlobalType;
  /**
   * Is contact active
   */
  active: boolean;
  /**
   * Version for catalog craftsman
   */
  version: number;
  /**
   * Catalog ids
   */
  catalogIds: string[];
}

export interface AddCatalogToCraftsman {
  addCatalogToCraftsman: AddCatalogToCraftsman_addCatalogToCraftsman;
}

export interface AddCatalogToCraftsmanVariables {
  craftsmanId: string;
  catalogId: string;
  changedBy: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCatalogCraftsman
// ====================================================

export interface CreateCatalogCraftsman_createCatalogCraftsman_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface CreateCatalogCraftsman_createCatalogCraftsman_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface CreateCatalogCraftsman_createCatalogCraftsman_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface CreateCatalogCraftsman_createCatalogCraftsman_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: CreateCatalogCraftsman_createCatalogCraftsman_catalogs_addresses[];
}

export interface CreateCatalogCraftsman_createCatalogCraftsman {
  /**
   * Id for catalog call contact
   */
  id: string;
  /**
   * Customer
   */
  customer: CreateCatalogCraftsman_createCatalogCraftsman_customer;
  /**
   * Craftsman changed by
   */
  changedBy: string;
  /**
   * Craftsman type
   */
  type: string;
  /**
   * Craftsman name
   */
  contactName: string;
  /**
   * Informations for Craftsman
   */
  informations: CreateCatalogCraftsman_createCatalogCraftsman_informations[];
  /**
   * Craftsman email
   */
  email: string | null;
  /**
   * Craftsman priority
   */
  priority: string;
  /**
   * Is contact global
   */
  global: boolean;
  /**
   * Global type for craftsman
   */
  globalType: CatalogGlobalType;
  /**
   * Is contact active
   */
  active: boolean;
  /**
   * Version for catalog craftsman
   */
  version: number;
  /**
   * Catalog ids
   */
  catalogIds: string[];
  /**
   * Catalogs that uses craftsman
   */
  catalogs: CreateCatalogCraftsman_createCatalogCraftsman_catalogs[] | null;
}

export interface CreateCatalogCraftsman {
  createCatalogCraftsman: CreateCatalogCraftsman_createCatalogCraftsman;
}

export interface CreateCatalogCraftsmanVariables {
  catalogCraftsman: CatalogCraftsmanInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteCatalogCraftsman
// ====================================================

export interface DeleteCatalogCraftsman {
  deleteCatalogCraftsman: string;
}

export interface DeleteCatalogCraftsmanVariables {
  id: string;
  changedBy: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCatalogCraftsmanChanges
// ====================================================

export interface GetCatalogCraftsmanChanges_catalogCraftsman_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetCatalogCraftsmanChanges_catalogCraftsman_changes_before_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface GetCatalogCraftsmanChanges_catalogCraftsman_changes_before_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface GetCatalogCraftsmanChanges_catalogCraftsman_changes_before_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface GetCatalogCraftsmanChanges_catalogCraftsman_changes_before_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: GetCatalogCraftsmanChanges_catalogCraftsman_changes_before_catalogs_addresses[];
}

export interface GetCatalogCraftsmanChanges_catalogCraftsman_changes_before {
  customer: GetCatalogCraftsmanChanges_catalogCraftsman_changes_before_customer | null;
  changedBy: string | null;
  /**
   * Type for call contact
   */
  type: string | null;
  /**
   * Call contact name
   */
  contactName: string | null;
  /**
   * Informations for Craftsman
   */
  informations: GetCatalogCraftsmanChanges_catalogCraftsman_changes_before_informations[] | null;
  /**
   * Call contact email
   */
  email: string | null;
  /**
   * Priority for call contact
   */
  priority: string | null;
  /**
   * Is call contact global
   */
  global: boolean | null;
  /**
   * Global type for craftsman
   */
  globalType: CatalogGlobalType | null;
  /**
   * Is call contact active
   */
  active: boolean | null;
  catalogs: GetCatalogCraftsmanChanges_catalogCraftsman_changes_before_catalogs[] | null;
}

export interface GetCatalogCraftsmanChanges_catalogCraftsman_changes_after_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface GetCatalogCraftsmanChanges_catalogCraftsman_changes_after_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface GetCatalogCraftsmanChanges_catalogCraftsman_changes_after_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface GetCatalogCraftsmanChanges_catalogCraftsman_changes_after_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: GetCatalogCraftsmanChanges_catalogCraftsman_changes_after_catalogs_addresses[];
}

export interface GetCatalogCraftsmanChanges_catalogCraftsman_changes_after {
  customer: GetCatalogCraftsmanChanges_catalogCraftsman_changes_after_customer | null;
  changedBy: string | null;
  /**
   * Type for call contact
   */
  type: string | null;
  /**
   * Call contact name
   */
  contactName: string | null;
  /**
   * Informations for Craftsman
   */
  informations: GetCatalogCraftsmanChanges_catalogCraftsman_changes_after_informations[] | null;
  /**
   * Call contact email
   */
  email: string | null;
  /**
   * Priority for call contact
   */
  priority: string | null;
  /**
   * Is call contact global
   */
  global: boolean | null;
  /**
   * Global type for craftsman
   */
  globalType: CatalogGlobalType | null;
  /**
   * Is call contact active
   */
  active: boolean | null;
  catalogs: GetCatalogCraftsmanChanges_catalogCraftsman_changes_after_catalogs[] | null;
}

export interface GetCatalogCraftsmanChanges_catalogCraftsman_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: GetCatalogCraftsmanChanges_catalogCraftsman_changes_user;
  /**
   * Value before
   */
  before: GetCatalogCraftsmanChanges_catalogCraftsman_changes_before;
  /**
   * Value after
   */
  after: GetCatalogCraftsmanChanges_catalogCraftsman_changes_after;
}

export interface GetCatalogCraftsmanChanges_catalogCraftsman {
  /**
   * Id for catalog call contact
   */
  id: string;
  /**
   * Catalog craftsman Change log
   */
  changes: GetCatalogCraftsmanChanges_catalogCraftsman_changes[];
}

export interface GetCatalogCraftsmanChanges {
  catalogCraftsman: GetCatalogCraftsmanChanges_catalogCraftsman;
}

export interface GetCatalogCraftsmanChangesVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCatalogCraftsmen
// ====================================================

export interface GetCatalogCraftsmen_catalogCraftsmen_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface GetCatalogCraftsmen_catalogCraftsmen_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface GetCatalogCraftsmen_catalogCraftsmen_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface GetCatalogCraftsmen_catalogCraftsmen_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: GetCatalogCraftsmen_catalogCraftsmen_catalogs_addresses[];
}

export interface GetCatalogCraftsmen_catalogCraftsmen {
  /**
   * Id for catalog call contact
   */
  id: string;
  /**
   * Customer
   */
  customer: GetCatalogCraftsmen_catalogCraftsmen_customer;
  /**
   * Craftsman changed by
   */
  changedBy: string;
  /**
   * Craftsman type
   */
  type: string;
  /**
   * Craftsman name
   */
  contactName: string;
  /**
   * Informations for Craftsman
   */
  informations: GetCatalogCraftsmen_catalogCraftsmen_informations[];
  /**
   * Craftsman email
   */
  email: string | null;
  /**
   * Craftsman priority
   */
  priority: string;
  /**
   * Is contact global
   */
  global: boolean;
  /**
   * Global type for craftsman
   */
  globalType: CatalogGlobalType;
  /**
   * Is contact active
   */
  active: boolean;
  /**
   * Version for catalog craftsman
   */
  version: number;
  /**
   * Catalog ids
   */
  catalogIds: string[];
  /**
   * Catalogs that uses craftsman
   */
  catalogs: GetCatalogCraftsmen_catalogCraftsmen_catalogs[] | null;
}

export interface GetCatalogCraftsmen {
  catalogCraftsmen: GetCatalogCraftsmen_catalogCraftsmen[] | null;
}

export interface GetCatalogCraftsmenVariables {
  globalOnly?: boolean | null;
  catalogId?: string | null;
  customerIds?: string[] | null;
  thisCustomerOnly?: boolean | null;
  offset?: number | null;
  limit?: number | null;
  searchString?: string | null;
  excludeTotalCount?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCatalogCraftsmenForAddExcisting
// ====================================================

export interface GetCatalogCraftsmenForAddExcisting_catalogCraftsmen_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface GetCatalogCraftsmenForAddExcisting_catalogCraftsmen {
  /**
   * Id for catalog call contact
   */
  id: string;
  /**
   * Craftsman type
   */
  type: string;
  /**
   * Craftsman name
   */
  contactName: string;
  /**
   * Informations for Craftsman
   */
  informations: GetCatalogCraftsmenForAddExcisting_catalogCraftsmen_informations[];
  /**
   * Craftsman email
   */
  email: string | null;
  /**
   * Craftsman priority
   */
  priority: string;
}

export interface GetCatalogCraftsmenForAddExcisting {
  catalogCraftsmen: GetCatalogCraftsmenForAddExcisting_catalogCraftsmen[] | null;
}

export interface GetCatalogCraftsmenForAddExcistingVariables {
  customerIds?: string[] | null;
  notGlobalOnly?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCatalogCraftsman
// ====================================================

export interface UpdateCatalogCraftsman_updateCatalogCraftsman_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface UpdateCatalogCraftsman_updateCatalogCraftsman_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface UpdateCatalogCraftsman_updateCatalogCraftsman {
  /**
   * Id for catalog call contact
   */
  id: string;
  /**
   * Customer
   */
  customer: UpdateCatalogCraftsman_updateCatalogCraftsman_customer;
  /**
   * Craftsman changed by
   */
  changedBy: string;
  /**
   * Craftsman type
   */
  type: string;
  /**
   * Craftsman name
   */
  contactName: string;
  /**
   * Informations for Craftsman
   */
  informations: UpdateCatalogCraftsman_updateCatalogCraftsman_informations[];
  /**
   * Craftsman email
   */
  email: string | null;
  /**
   * Craftsman priority
   */
  priority: string;
  /**
   * Is contact global
   */
  global: boolean;
  /**
   * Global type for craftsman
   */
  globalType: CatalogGlobalType;
  /**
   * Is contact active
   */
  active: boolean;
  /**
   * Version for catalog craftsman
   */
  version: number;
  /**
   * Catalog ids
   */
  catalogIds: string[];
}

export interface UpdateCatalogCraftsman {
  updateCatalogCraftsman: UpdateCatalogCraftsman_updateCatalogCraftsman;
}

export interface UpdateCatalogCraftsmanVariables {
  id: string;
  customer: string;
  changedBy: string;
  type: string;
  priority: string;
  contactName: string;
  globalType: CatalogGlobalType;
  email?: string | null;
  informations: (CatalogPhoneInformationInput | null)[];
  global: boolean;
  active: boolean;
  catalogs?: string[] | null;
  action: FileActionType;
  catalogsBefore?: (string | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCatalogCustomerByAdId
// ====================================================

export interface GetCatalogCustomerByAdId_catalogCustomerByAdId_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetCatalogCustomerByAdId_catalogCustomerByAdId_users {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
}

export interface GetCatalogCustomerByAdId_catalogCustomerByAdId {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
  /**
   * AD user
   */
  user: GetCatalogCustomerByAdId_catalogCustomerByAdId_user | null;
  /**
   * AD users connected to the customer
   */
  users: GetCatalogCustomerByAdId_catalogCustomerByAdId_users[];
}

export interface GetCatalogCustomerByAdId {
  catalogCustomerByAdId: GetCatalogCustomerByAdId_catalogCustomerByAdId | null;
}

export interface GetCatalogCustomerByAdIdVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWebCatalogCustomers
// ====================================================

export interface GetWebCatalogCustomers_catalogCustomers {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface GetWebCatalogCustomers {
  catalogCustomers: GetWebCatalogCustomers_catalogCustomers[];
}

export interface GetWebCatalogCustomersVariables {
  ownCustomers?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCatalog
// ====================================================

export interface CreateCatalog_createCatalog_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface CreateCatalog_createCatalog_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface CreateCatalog_createCatalog_debitor {
  /**
   * Company identifier
   */
  company: string;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Sales Perseon Code
   */
  salesPersonCode: string;
  /**
   * Sales Perseon Name
   */
  salesPersonName: string;
}

export interface CreateCatalog_createCatalog_craftsmen_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface CreateCatalog_createCatalog_craftsmen_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface CreateCatalog_createCatalog_craftsmen {
  /**
   * Id for catalog call contact
   */
  id: string;
  /**
   * Customer
   */
  customer: CreateCatalog_createCatalog_craftsmen_customer;
  /**
   * Craftsman changed by
   */
  changedBy: string;
  /**
   * Craftsman type
   */
  type: string;
  /**
   * Craftsman name
   */
  contactName: string;
  /**
   * Informations for Craftsman
   */
  informations: CreateCatalog_createCatalog_craftsmen_informations[];
  /**
   * Craftsman email
   */
  email: string | null;
  /**
   * Craftsman priority
   */
  priority: string;
  /**
   * Is contact global
   */
  global: boolean;
  /**
   * Global type for craftsman
   */
  globalType: CatalogGlobalType;
  /**
   * Is contact active
   */
  active: boolean;
  /**
   * Version for catalog craftsman
   */
  version: number;
  /**
   * Catalog ids
   */
  catalogIds: string[];
}

export interface CreateCatalog_createCatalog_contacts_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface CreateCatalog_createCatalog_contacts_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface CreateCatalog_createCatalog_contacts {
  /**
   * Id for catalog contact
   */
  id: string;
  /**
   * Customer
   */
  customer: CreateCatalog_createCatalog_contacts_customer;
  /**
   * Contact changed by
   */
  changedBy: string;
  /**
   * Contact name
   */
  contactName: string;
  /**
   * Contact type
   */
  type: string | null;
  /**
   * Contact collection type
   */
  contactType: CatalogContacts;
  /**
   * Contact email
   */
  email: string | null;
  /**
   * Informations for Contacts
   */
  informations: (CreateCatalog_createCatalog_contacts_informations | null)[];
  /**
   * Is contact global
   */
  global: boolean;
  /**
   * Global type for contact
   */
  globalType: CatalogGlobalType;
  /**
   * Is contact active
   */
  active: boolean;
  /**
   * Catalog ids
   */
  catalogIds: string[];
  /**
   * Version for catalog contact
   */
  version: number;
}

export interface CreateCatalog_createCatalog_timedMessages_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface CreateCatalog_createCatalog_timedMessages {
  /**
   * id to display
   */
  id: string;
  /**
   * Customer
   */
  customer: CreateCatalog_createCatalog_timedMessages_customer;
  /**
   * Craftsman changed by
   */
  changedBy: string;
  /**
   * Time at which the message should show from
   */
  startTime: GQL_DateTime;
  /**
   * Time at which the message should stop showing
   */
  endTime: GQL_DateTime;
  /**
   * message to display
   */
  message: string;
  /**
   * Is timed message global
   */
  global: boolean;
  /**
   * Global type for timed message
   */
  globalType: CatalogGlobalType;
  /**
   * Is timed message active
   */
  active: boolean;
  /**
   * Version for timed message
   */
  version: number;
  /**
   * Catalog ids
   */
  catalogIds: string[];
}

export interface CreateCatalog_createCatalog {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Contact number for the Catalog
   */
  contactNumber: string | null;
  /**
   * Hours contact number can be reached
   */
  contactHours: string | null;
  /**
   * Customer
   */
  customer: CreateCatalog_createCatalog_customer;
  /**
   * Catalog changed by
   */
  changedBy: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: CreateCatalog_createCatalog_addresses[];
  /**
   * Is sms service used for Catalog
   */
  smsService: boolean;
  /**
   * Catalog debitor details
   */
  debitor: CreateCatalog_createCatalog_debitor | null;
  /**
   * Is catalog verified
   */
  verified: boolean;
  /**
   * Is catalog debitor verified
   */
  debitorVerified: boolean;
  /**
   * Version for catalog
   */
  version: number;
  /**
   * Timestamp of when the catalog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the catalog was created
   */
  createdAt: GQL_DateTime;
  /**
   * SGGAccountable for catalog
   */
  ssgAccountable: string | null;
  /**
   * Description for catalog
   */
  description: string | null;
  /**
   * Craftsmen attached to catalog
   */
  craftsmen: CreateCatalog_createCatalog_craftsmen[];
  /**
   * Contacs attached to catalog
   */
  contacts: CreateCatalog_createCatalog_contacts[];
  /**
   * Timed messages attached to catalog
   */
  timedMessages: CreateCatalog_createCatalog_timedMessages[];
}

export interface CreateCatalog {
  createCatalog: CreateCatalog_createCatalog;
}

export interface CreateCatalogVariables {
  catalog: CatalogInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCatalogs
// ====================================================

export interface CreateCatalogs_createCatalogs_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface CreateCatalogs_createCatalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface CreateCatalogs_createCatalogs_debitor {
  /**
   * Company identifier
   */
  company: string;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Sales Perseon Code
   */
  salesPersonCode: string;
  /**
   * Sales Perseon Name
   */
  salesPersonName: string;
}

export interface CreateCatalogs_createCatalogs_craftsmen_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface CreateCatalogs_createCatalogs_craftsmen_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface CreateCatalogs_createCatalogs_craftsmen {
  /**
   * Id for catalog call contact
   */
  id: string;
  /**
   * Customer
   */
  customer: CreateCatalogs_createCatalogs_craftsmen_customer;
  /**
   * Craftsman changed by
   */
  changedBy: string;
  /**
   * Craftsman type
   */
  type: string;
  /**
   * Craftsman name
   */
  contactName: string;
  /**
   * Informations for Craftsman
   */
  informations: CreateCatalogs_createCatalogs_craftsmen_informations[];
  /**
   * Craftsman email
   */
  email: string | null;
  /**
   * Craftsman priority
   */
  priority: string;
  /**
   * Is contact global
   */
  global: boolean;
  /**
   * Global type for craftsman
   */
  globalType: CatalogGlobalType;
  /**
   * Is contact active
   */
  active: boolean;
  /**
   * Version for catalog craftsman
   */
  version: number;
  /**
   * Catalog ids
   */
  catalogIds: string[];
}

export interface CreateCatalogs_createCatalogs_contacts_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface CreateCatalogs_createCatalogs_contacts_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface CreateCatalogs_createCatalogs_contacts {
  /**
   * Id for catalog contact
   */
  id: string;
  /**
   * Customer
   */
  customer: CreateCatalogs_createCatalogs_contacts_customer;
  /**
   * Contact changed by
   */
  changedBy: string;
  /**
   * Contact name
   */
  contactName: string;
  /**
   * Contact type
   */
  type: string | null;
  /**
   * Contact collection type
   */
  contactType: CatalogContacts;
  /**
   * Contact email
   */
  email: string | null;
  /**
   * Informations for Contacts
   */
  informations: (CreateCatalogs_createCatalogs_contacts_informations | null)[];
  /**
   * Is contact global
   */
  global: boolean;
  /**
   * Global type for contact
   */
  globalType: CatalogGlobalType;
  /**
   * Is contact active
   */
  active: boolean;
  /**
   * Catalog ids
   */
  catalogIds: string[];
  /**
   * Version for catalog contact
   */
  version: number;
}

export interface CreateCatalogs_createCatalogs_timedMessages_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface CreateCatalogs_createCatalogs_timedMessages {
  /**
   * id to display
   */
  id: string;
  /**
   * Customer
   */
  customer: CreateCatalogs_createCatalogs_timedMessages_customer;
  /**
   * Craftsman changed by
   */
  changedBy: string;
  /**
   * Time at which the message should show from
   */
  startTime: GQL_DateTime;
  /**
   * Time at which the message should stop showing
   */
  endTime: GQL_DateTime;
  /**
   * message to display
   */
  message: string;
  /**
   * Is timed message global
   */
  global: boolean;
  /**
   * Global type for timed message
   */
  globalType: CatalogGlobalType;
  /**
   * Is timed message active
   */
  active: boolean;
  /**
   * Version for timed message
   */
  version: number;
  /**
   * Catalog ids
   */
  catalogIds: string[];
}

export interface CreateCatalogs_createCatalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Contact number for the Catalog
   */
  contactNumber: string | null;
  /**
   * Hours contact number can be reached
   */
  contactHours: string | null;
  /**
   * Customer
   */
  customer: CreateCatalogs_createCatalogs_customer;
  /**
   * Catalog changed by
   */
  changedBy: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: CreateCatalogs_createCatalogs_addresses[];
  /**
   * Is sms service used for Catalog
   */
  smsService: boolean;
  /**
   * Catalog debitor details
   */
  debitor: CreateCatalogs_createCatalogs_debitor | null;
  /**
   * Is catalog verified
   */
  verified: boolean;
  /**
   * Is catalog debitor verified
   */
  debitorVerified: boolean;
  /**
   * Version for catalog
   */
  version: number;
  /**
   * Timestamp of when the catalog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the catalog was created
   */
  createdAt: GQL_DateTime;
  /**
   * SGGAccountable for catalog
   */
  ssgAccountable: string | null;
  /**
   * Description for catalog
   */
  description: string | null;
  /**
   * Craftsmen attached to catalog
   */
  craftsmen: CreateCatalogs_createCatalogs_craftsmen[];
  /**
   * Contacs attached to catalog
   */
  contacts: CreateCatalogs_createCatalogs_contacts[];
  /**
   * Timed messages attached to catalog
   */
  timedMessages: CreateCatalogs_createCatalogs_timedMessages[];
}

export interface CreateCatalogs {
  createCatalogs: CreateCatalogs_createCatalogs[];
}

export interface CreateCatalogsVariables {
  catalogs: CatalogInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteCatalog
// ====================================================

export interface DeleteCatalog_deleteCatalog {
  /**
   * This value is not used for anything.
   */
  _: string;
}

export interface DeleteCatalog {
  deleteCatalog: DeleteCatalog_deleteCatalog | null;
}

export interface DeleteCatalogVariables {
  id: string;
  changedBy: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCatalog
// ====================================================

export interface GetCatalog_catalog_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface GetCatalog_catalog_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface GetCatalog_catalog_debitor {
  /**
   * Company identifier
   */
  company: string;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Sales Perseon Code
   */
  salesPersonCode: string;
  /**
   * Sales Perseon Name
   */
  salesPersonName: string;
}

export interface GetCatalog_catalog_craftsmen_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface GetCatalog_catalog_craftsmen_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface GetCatalog_catalog_craftsmen {
  /**
   * Id for catalog call contact
   */
  id: string;
  /**
   * Customer
   */
  customer: GetCatalog_catalog_craftsmen_customer;
  /**
   * Craftsman changed by
   */
  changedBy: string;
  /**
   * Craftsman type
   */
  type: string;
  /**
   * Craftsman name
   */
  contactName: string;
  /**
   * Informations for Craftsman
   */
  informations: GetCatalog_catalog_craftsmen_informations[];
  /**
   * Craftsman email
   */
  email: string | null;
  /**
   * Craftsman priority
   */
  priority: string;
  /**
   * Is contact global
   */
  global: boolean;
  /**
   * Global type for craftsman
   */
  globalType: CatalogGlobalType;
  /**
   * Is contact active
   */
  active: boolean;
  /**
   * Version for catalog craftsman
   */
  version: number;
  /**
   * Catalog ids
   */
  catalogIds: string[];
}

export interface GetCatalog_catalog_contacts_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface GetCatalog_catalog_contacts_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface GetCatalog_catalog_contacts {
  /**
   * Id for catalog contact
   */
  id: string;
  /**
   * Customer
   */
  customer: GetCatalog_catalog_contacts_customer;
  /**
   * Contact changed by
   */
  changedBy: string;
  /**
   * Contact name
   */
  contactName: string;
  /**
   * Contact type
   */
  type: string | null;
  /**
   * Contact collection type
   */
  contactType: CatalogContacts;
  /**
   * Contact email
   */
  email: string | null;
  /**
   * Informations for Contacts
   */
  informations: (GetCatalog_catalog_contacts_informations | null)[];
  /**
   * Is contact global
   */
  global: boolean;
  /**
   * Global type for contact
   */
  globalType: CatalogGlobalType;
  /**
   * Is contact active
   */
  active: boolean;
  /**
   * Catalog ids
   */
  catalogIds: string[];
  /**
   * Version for catalog contact
   */
  version: number;
}

export interface GetCatalog_catalog_timedMessages_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface GetCatalog_catalog_timedMessages {
  /**
   * id to display
   */
  id: string;
  /**
   * Customer
   */
  customer: GetCatalog_catalog_timedMessages_customer;
  /**
   * Craftsman changed by
   */
  changedBy: string;
  /**
   * Time at which the message should show from
   */
  startTime: GQL_DateTime;
  /**
   * Time at which the message should stop showing
   */
  endTime: GQL_DateTime;
  /**
   * message to display
   */
  message: string;
  /**
   * Is timed message global
   */
  global: boolean;
  /**
   * Global type for timed message
   */
  globalType: CatalogGlobalType;
  /**
   * Is timed message active
   */
  active: boolean;
  /**
   * Version for timed message
   */
  version: number;
  /**
   * Catalog ids
   */
  catalogIds: string[];
}

export interface GetCatalog_catalog {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Contact number for the Catalog
   */
  contactNumber: string | null;
  /**
   * Hours contact number can be reached
   */
  contactHours: string | null;
  /**
   * Customer
   */
  customer: GetCatalog_catalog_customer;
  /**
   * Catalog changed by
   */
  changedBy: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: GetCatalog_catalog_addresses[];
  /**
   * Is sms service used for Catalog
   */
  smsService: boolean;
  /**
   * Catalog debitor details
   */
  debitor: GetCatalog_catalog_debitor | null;
  /**
   * Is catalog verified
   */
  verified: boolean;
  /**
   * Is catalog debitor verified
   */
  debitorVerified: boolean;
  /**
   * Version for catalog
   */
  version: number;
  /**
   * Timestamp of when the catalog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the catalog was created
   */
  createdAt: GQL_DateTime;
  /**
   * SGGAccountable for catalog
   */
  ssgAccountable: string | null;
  /**
   * Description for catalog
   */
  description: string | null;
  /**
   * Craftsmen attached to catalog
   */
  craftsmen: GetCatalog_catalog_craftsmen[];
  /**
   * Contacs attached to catalog
   */
  contacts: GetCatalog_catalog_contacts[];
  /**
   * Timed messages attached to catalog
   */
  timedMessages: GetCatalog_catalog_timedMessages[];
}

export interface GetCatalog {
  catalog: GetCatalog_catalog;
}

export interface GetCatalogVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCatalogCaseCreation
// ====================================================

export interface GetCatalogCaseCreation_catalog_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
}

export interface GetCatalogCaseCreation_catalog_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
}

export interface GetCatalogCaseCreation_catalog {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Catalog debitor details
   */
  debitor: GetCatalogCaseCreation_catalog_debitor | null;
  /**
   * Addressse affiliated with catalog
   */
  addresses: GetCatalogCaseCreation_catalog_addresses[];
}

export interface GetCatalogCaseCreation {
  catalog: GetCatalogCaseCreation_catalog;
}

export interface GetCatalogCaseCreationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCatalogChanges
// ====================================================

export interface GetCatalogChanges_catalog_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetCatalogChanges_catalog_changes_before_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface GetCatalogChanges_catalog_changes_before_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface GetCatalogChanges_catalog_changes_before_contacts_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface GetCatalogChanges_catalog_changes_before_contacts_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface GetCatalogChanges_catalog_changes_before_contacts_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface GetCatalogChanges_catalog_changes_before_contacts_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: GetCatalogChanges_catalog_changes_before_contacts_catalogs_addresses[];
}

export interface GetCatalogChanges_catalog_changes_before_contacts {
  customer: GetCatalogChanges_catalog_changes_before_contacts_customer | null;
  changedBy: string | null;
  /**
   * Contact name
   */
  contactName: string | null;
  type: string | null;
  contactType: CatalogContacts | null;
  /**
   * Contact email
   */
  email: string | null;
  /**
   * Informations for Contacts
   */
  informations: GetCatalogChanges_catalog_changes_before_contacts_informations[] | null;
  /**
   * Is contact global
   */
  global: boolean | null;
  /**
   * Global type for contact
   */
  globalType: CatalogGlobalType | null;
  /**
   * Is contact active
   */
  active: boolean | null;
  catalogs: GetCatalogChanges_catalog_changes_before_contacts_catalogs[] | null;
}

export interface GetCatalogChanges_catalog_changes_before_craftsmen_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface GetCatalogChanges_catalog_changes_before_craftsmen_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface GetCatalogChanges_catalog_changes_before_craftsmen_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface GetCatalogChanges_catalog_changes_before_craftsmen_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: GetCatalogChanges_catalog_changes_before_craftsmen_catalogs_addresses[];
}

export interface GetCatalogChanges_catalog_changes_before_craftsmen {
  customer: GetCatalogChanges_catalog_changes_before_craftsmen_customer | null;
  changedBy: string | null;
  /**
   * Type for call contact
   */
  type: string | null;
  /**
   * Call contact name
   */
  contactName: string | null;
  /**
   * Informations for Craftsman
   */
  informations: GetCatalogChanges_catalog_changes_before_craftsmen_informations[] | null;
  /**
   * Call contact email
   */
  email: string | null;
  /**
   * Priority for call contact
   */
  priority: string | null;
  /**
   * Is call contact global
   */
  global: boolean | null;
  /**
   * Global type for craftsman
   */
  globalType: CatalogGlobalType | null;
  /**
   * Is call contact active
   */
  active: boolean | null;
  catalogs: GetCatalogChanges_catalog_changes_before_craftsmen_catalogs[] | null;
}

export interface GetCatalogChanges_catalog_changes_before_timedMessages_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface GetCatalogChanges_catalog_changes_before_timedMessages_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface GetCatalogChanges_catalog_changes_before_timedMessages_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: GetCatalogChanges_catalog_changes_before_timedMessages_catalogs_addresses[];
}

export interface GetCatalogChanges_catalog_changes_before_timedMessages {
  customer: GetCatalogChanges_catalog_changes_before_timedMessages_customer | null;
  changedBy: string | null;
  startTime: GQL_DateTime | null;
  endTime: GQL_DateTime | null;
  message: string | null;
  global: boolean | null;
  globalType: CatalogGlobalType | null;
  active: boolean | null;
  catalogs: GetCatalogChanges_catalog_changes_before_timedMessages_catalogs[] | null;
}

export interface GetCatalogChanges_catalog_changes_before_debitor {
  /**
   * Company identifier
   */
  company: string;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
}

export interface GetCatalogChanges_catalog_changes_before {
  ssgAccountable: string | null;
  propertyNumber: string | null;
  customer: GetCatalogChanges_catalog_changes_before_customer | null;
  changedBy: string | null;
  description: string | null;
  contactNumber: string | null;
  contactHours: string | null;
  addresses: GetCatalogChanges_catalog_changes_before_addresses[] | null;
  contacts: GetCatalogChanges_catalog_changes_before_contacts | null;
  craftsmen: GetCatalogChanges_catalog_changes_before_craftsmen | null;
  timedMessages: GetCatalogChanges_catalog_changes_before_timedMessages | null;
  filenames: string | null;
  smsService: boolean | null;
  /**
   * Is catalog verified
   */
  verified: boolean;
  /**
   * Is catalog debitor verified
   */
  debitorVerified: boolean;
  /**
   * Catalog debitor details
   */
  debitor: GetCatalogChanges_catalog_changes_before_debitor | null;
}

export interface GetCatalogChanges_catalog_changes_after_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface GetCatalogChanges_catalog_changes_after_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface GetCatalogChanges_catalog_changes_after_contacts_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface GetCatalogChanges_catalog_changes_after_contacts_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface GetCatalogChanges_catalog_changes_after_contacts_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface GetCatalogChanges_catalog_changes_after_contacts_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: GetCatalogChanges_catalog_changes_after_contacts_catalogs_addresses[];
}

export interface GetCatalogChanges_catalog_changes_after_contacts {
  customer: GetCatalogChanges_catalog_changes_after_contacts_customer | null;
  changedBy: string | null;
  /**
   * Contact name
   */
  contactName: string | null;
  type: string | null;
  contactType: CatalogContacts | null;
  /**
   * Contact email
   */
  email: string | null;
  /**
   * Informations for Contacts
   */
  informations: GetCatalogChanges_catalog_changes_after_contacts_informations[] | null;
  /**
   * Is contact global
   */
  global: boolean | null;
  /**
   * Global type for contact
   */
  globalType: CatalogGlobalType | null;
  /**
   * Is contact active
   */
  active: boolean | null;
  catalogs: GetCatalogChanges_catalog_changes_after_contacts_catalogs[] | null;
}

export interface GetCatalogChanges_catalog_changes_after_craftsmen_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface GetCatalogChanges_catalog_changes_after_craftsmen_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface GetCatalogChanges_catalog_changes_after_craftsmen_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface GetCatalogChanges_catalog_changes_after_craftsmen_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: GetCatalogChanges_catalog_changes_after_craftsmen_catalogs_addresses[];
}

export interface GetCatalogChanges_catalog_changes_after_craftsmen {
  customer: GetCatalogChanges_catalog_changes_after_craftsmen_customer | null;
  changedBy: string | null;
  /**
   * Type for call contact
   */
  type: string | null;
  /**
   * Call contact name
   */
  contactName: string | null;
  /**
   * Informations for Craftsman
   */
  informations: GetCatalogChanges_catalog_changes_after_craftsmen_informations[] | null;
  /**
   * Call contact email
   */
  email: string | null;
  /**
   * Priority for call contact
   */
  priority: string | null;
  /**
   * Is call contact global
   */
  global: boolean | null;
  /**
   * Global type for craftsman
   */
  globalType: CatalogGlobalType | null;
  /**
   * Is call contact active
   */
  active: boolean | null;
  catalogs: GetCatalogChanges_catalog_changes_after_craftsmen_catalogs[] | null;
}

export interface GetCatalogChanges_catalog_changes_after_timedMessages_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface GetCatalogChanges_catalog_changes_after_timedMessages_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface GetCatalogChanges_catalog_changes_after_timedMessages_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: GetCatalogChanges_catalog_changes_after_timedMessages_catalogs_addresses[];
}

export interface GetCatalogChanges_catalog_changes_after_timedMessages {
  customer: GetCatalogChanges_catalog_changes_after_timedMessages_customer | null;
  changedBy: string | null;
  startTime: GQL_DateTime | null;
  endTime: GQL_DateTime | null;
  message: string | null;
  global: boolean | null;
  globalType: CatalogGlobalType | null;
  active: boolean | null;
  catalogs: GetCatalogChanges_catalog_changes_after_timedMessages_catalogs[] | null;
}

export interface GetCatalogChanges_catalog_changes_after_debitor {
  /**
   * Company identifier
   */
  company: string;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
}

export interface GetCatalogChanges_catalog_changes_after {
  ssgAccountable: string | null;
  propertyNumber: string | null;
  customer: GetCatalogChanges_catalog_changes_after_customer | null;
  changedBy: string | null;
  description: string | null;
  contactNumber: string | null;
  contactHours: string | null;
  addresses: GetCatalogChanges_catalog_changes_after_addresses[] | null;
  contacts: GetCatalogChanges_catalog_changes_after_contacts | null;
  craftsmen: GetCatalogChanges_catalog_changes_after_craftsmen | null;
  timedMessages: GetCatalogChanges_catalog_changes_after_timedMessages | null;
  filenames: string | null;
  smsService: boolean | null;
  /**
   * Is catalog verified
   */
  verified: boolean;
  /**
   * Is catalog debitor verified
   */
  debitorVerified: boolean;
  /**
   * Catalog debitor details
   */
  debitor: GetCatalogChanges_catalog_changes_after_debitor | null;
}

export interface GetCatalogChanges_catalog_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: GetCatalogChanges_catalog_changes_user;
  /**
   * Changed by
   */
  changedBy: string | null;
  /**
   * Value before
   */
  before: GetCatalogChanges_catalog_changes_before;
  /**
   * Value after
   */
  after: GetCatalogChanges_catalog_changes_after;
}

export interface GetCatalogChanges_catalog {
  /**
   * Version for catalog
   */
  version: number;
  /**
   * Catalog Change log
   */
  changes: GetCatalogChanges_catalog_changes[];
}

export interface GetCatalogChanges {
  catalog: GetCatalogChanges_catalog;
}

export interface GetCatalogChangesVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCatalogsForDropdown
// ====================================================

export interface GetCatalogsForDropdown_catalogs_customer {
  /**
   * Id of catalog customer
   */
  id: string;
}

export interface GetCatalogsForDropdown_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Customer
   */
  customer: GetCatalogsForDropdown_catalogs_customer;
}

export interface GetCatalogsForDropdown {
  catalogs: GetCatalogsForDropdown_catalogs[];
}

export interface GetCatalogsForDropdownVariables {
  thisCustomerOnly?: boolean | null;
  customerIds?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCatalogsWithCraftsmen
// ====================================================

export interface GetCatalogsWithCraftsmen_catalogs_craftsmen_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface GetCatalogsWithCraftsmen_catalogs_craftsmen_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface GetCatalogsWithCraftsmen_catalogs_craftsmen {
  /**
   * Id for catalog call contact
   */
  id: string;
  /**
   * Customer
   */
  customer: GetCatalogsWithCraftsmen_catalogs_craftsmen_customer;
  /**
   * Craftsman changed by
   */
  changedBy: string;
  /**
   * Craftsman type
   */
  type: string;
  /**
   * Craftsman name
   */
  contactName: string;
  /**
   * Informations for Craftsman
   */
  informations: GetCatalogsWithCraftsmen_catalogs_craftsmen_informations[];
  /**
   * Craftsman email
   */
  email: string | null;
  /**
   * Craftsman priority
   */
  priority: string;
  /**
   * Is contact global
   */
  global: boolean;
  /**
   * Global type for craftsman
   */
  globalType: CatalogGlobalType;
  /**
   * Is contact active
   */
  active: boolean;
  /**
   * Version for catalog craftsman
   */
  version: number;
  /**
   * Catalog ids
   */
  catalogIds: string[];
}

export interface GetCatalogsWithCraftsmen_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Craftsmen attached to catalog
   */
  craftsmen: GetCatalogsWithCraftsmen_catalogs_craftsmen[];
}

export interface GetCatalogsWithCraftsmen {
  catalogs: GetCatalogsWithCraftsmen_catalogs[];
}

export interface GetCatalogsWithCraftsmenVariables {
  debitors?: string[] | null;
  smsService?: boolean | null;
  verified?: boolean | null;
  debitorVerified?: boolean | null;
  address?: AddressInput | null;
  noDebitor?: boolean | null;
  thisCustomerOnly?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLatestCatalog
// ====================================================

export interface GetLatestCatalog_latestCatalog_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface GetLatestCatalog_latestCatalog_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface GetLatestCatalog_latestCatalog_debitor {
  /**
   * Company identifier
   */
  company: string;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Sales Perseon Code
   */
  salesPersonCode: string;
  /**
   * Sales Perseon Name
   */
  salesPersonName: string;
}

export interface GetLatestCatalog_latestCatalog {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Contact number for the Catalog
   */
  contactNumber: string | null;
  /**
   * Hours contact number can be reached
   */
  contactHours: string | null;
  /**
   * Customer
   */
  customer: GetLatestCatalog_latestCatalog_customer;
  /**
   * Catalog changed by
   */
  changedBy: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: GetLatestCatalog_latestCatalog_addresses[];
  /**
   * Is sms service used for Catalog
   */
  smsService: boolean;
  /**
   * Catalog debitor details
   */
  debitor: GetLatestCatalog_latestCatalog_debitor | null;
  /**
   * Is catalog verified
   */
  verified: boolean;
  /**
   * Is catalog debitor verified
   */
  debitorVerified: boolean;
  /**
   * Version for catalog
   */
  version: number;
  /**
   * Timestamp of when the catalog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the catalog was created
   */
  createdAt: GQL_DateTime;
}

export interface GetLatestCatalog {
  latestCatalog: GetLatestCatalog_latestCatalog[];
}

export interface GetLatestCatalogVariables {
  date: GQL_Date;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWebCatalogs
// ====================================================

export interface GetWebCatalogs_catalogs_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface GetWebCatalogs_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface GetWebCatalogs_catalogs_debitor {
  /**
   * Company identifier
   */
  company: string;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Sales Perseon Code
   */
  salesPersonCode: string;
  /**
   * Sales Perseon Name
   */
  salesPersonName: string;
}

export interface GetWebCatalogs_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Contact number for the Catalog
   */
  contactNumber: string | null;
  /**
   * Hours contact number can be reached
   */
  contactHours: string | null;
  /**
   * Customer
   */
  customer: GetWebCatalogs_catalogs_customer;
  /**
   * Catalog changed by
   */
  changedBy: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: GetWebCatalogs_catalogs_addresses[];
  /**
   * Is sms service used for Catalog
   */
  smsService: boolean;
  /**
   * Catalog debitor details
   */
  debitor: GetWebCatalogs_catalogs_debitor | null;
  /**
   * Is catalog verified
   */
  verified: boolean;
  /**
   * Is catalog debitor verified
   */
  debitorVerified: boolean;
  /**
   * Version for catalog
   */
  version: number;
  /**
   * Timestamp of when the catalog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the catalog was created
   */
  createdAt: GQL_DateTime;
}

export interface GetWebCatalogs {
  catalogs: GetWebCatalogs_catalogs[];
}

export interface GetWebCatalogsVariables {
  debitors?: string[] | null;
  smsService?: boolean | null;
  verified?: boolean | null;
  debitorVerified?: boolean | null;
  address?: AddressInput | null;
  noDebitor?: boolean | null;
  thisCustomerOnly?: boolean | null;
  offset?: number | null;
  limit?: number | null;
  searchString?: string | null;
  customerIds?: string[] | null;
  excludeTotalCount?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCatalog
// ====================================================

export interface UpdateCatalog_updateCatalog_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface UpdateCatalog_updateCatalog_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface UpdateCatalog_updateCatalog_debitor {
  /**
   * Company identifier
   */
  company: string;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Sales Perseon Code
   */
  salesPersonCode: string;
  /**
   * Sales Perseon Name
   */
  salesPersonName: string;
}

export interface UpdateCatalog_updateCatalog_craftsmen_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface UpdateCatalog_updateCatalog_craftsmen_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface UpdateCatalog_updateCatalog_craftsmen {
  /**
   * Id for catalog call contact
   */
  id: string;
  /**
   * Customer
   */
  customer: UpdateCatalog_updateCatalog_craftsmen_customer;
  /**
   * Craftsman changed by
   */
  changedBy: string;
  /**
   * Craftsman type
   */
  type: string;
  /**
   * Craftsman name
   */
  contactName: string;
  /**
   * Informations for Craftsman
   */
  informations: UpdateCatalog_updateCatalog_craftsmen_informations[];
  /**
   * Craftsman email
   */
  email: string | null;
  /**
   * Craftsman priority
   */
  priority: string;
  /**
   * Is contact global
   */
  global: boolean;
  /**
   * Global type for craftsman
   */
  globalType: CatalogGlobalType;
  /**
   * Is contact active
   */
  active: boolean;
  /**
   * Version for catalog craftsman
   */
  version: number;
  /**
   * Catalog ids
   */
  catalogIds: string[];
}

export interface UpdateCatalog_updateCatalog_contacts_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface UpdateCatalog_updateCatalog_contacts_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface UpdateCatalog_updateCatalog_contacts {
  /**
   * Id for catalog contact
   */
  id: string;
  /**
   * Customer
   */
  customer: UpdateCatalog_updateCatalog_contacts_customer;
  /**
   * Contact changed by
   */
  changedBy: string;
  /**
   * Contact name
   */
  contactName: string;
  /**
   * Contact type
   */
  type: string | null;
  /**
   * Contact collection type
   */
  contactType: CatalogContacts;
  /**
   * Contact email
   */
  email: string | null;
  /**
   * Informations for Contacts
   */
  informations: (UpdateCatalog_updateCatalog_contacts_informations | null)[];
  /**
   * Is contact global
   */
  global: boolean;
  /**
   * Global type for contact
   */
  globalType: CatalogGlobalType;
  /**
   * Is contact active
   */
  active: boolean;
  /**
   * Catalog ids
   */
  catalogIds: string[];
  /**
   * Version for catalog contact
   */
  version: number;
}

export interface UpdateCatalog_updateCatalog_timedMessages_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface UpdateCatalog_updateCatalog_timedMessages {
  /**
   * id to display
   */
  id: string;
  /**
   * Customer
   */
  customer: UpdateCatalog_updateCatalog_timedMessages_customer;
  /**
   * Craftsman changed by
   */
  changedBy: string;
  /**
   * Time at which the message should show from
   */
  startTime: GQL_DateTime;
  /**
   * Time at which the message should stop showing
   */
  endTime: GQL_DateTime;
  /**
   * message to display
   */
  message: string;
  /**
   * Is timed message global
   */
  global: boolean;
  /**
   * Global type for timed message
   */
  globalType: CatalogGlobalType;
  /**
   * Is timed message active
   */
  active: boolean;
  /**
   * Version for timed message
   */
  version: number;
  /**
   * Catalog ids
   */
  catalogIds: string[];
}

export interface UpdateCatalog_updateCatalog {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Contact number for the Catalog
   */
  contactNumber: string | null;
  /**
   * Hours contact number can be reached
   */
  contactHours: string | null;
  /**
   * Customer
   */
  customer: UpdateCatalog_updateCatalog_customer;
  /**
   * Catalog changed by
   */
  changedBy: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: UpdateCatalog_updateCatalog_addresses[];
  /**
   * Is sms service used for Catalog
   */
  smsService: boolean;
  /**
   * Catalog debitor details
   */
  debitor: UpdateCatalog_updateCatalog_debitor | null;
  /**
   * Is catalog verified
   */
  verified: boolean;
  /**
   * Is catalog debitor verified
   */
  debitorVerified: boolean;
  /**
   * Version for catalog
   */
  version: number;
  /**
   * Timestamp of when the catalog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the catalog was created
   */
  createdAt: GQL_DateTime;
  /**
   * SGGAccountable for catalog
   */
  ssgAccountable: string | null;
  /**
   * Description for catalog
   */
  description: string | null;
  /**
   * Craftsmen attached to catalog
   */
  craftsmen: UpdateCatalog_updateCatalog_craftsmen[];
  /**
   * Contacs attached to catalog
   */
  contacts: UpdateCatalog_updateCatalog_contacts[];
  /**
   * Timed messages attached to catalog
   */
  timedMessages: UpdateCatalog_updateCatalog_timedMessages[];
}

export interface UpdateCatalog {
  updateCatalog: UpdateCatalog_updateCatalog;
}

export interface UpdateCatalogVariables {
  id: string;
  propertyNumber: string;
  customer: string;
  changedBy: string;
  description?: string | null;
  contactNumber?: string | null;
  contactHours?: string | null;
  addresses?: CatalogAddressInput[] | null;
  smsService: boolean;
  debitor?: string | null;
  ssgAccountable?: string | null;
  verified: boolean;
  debitorVerified: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCatalogs
// ====================================================

export interface UpdateCatalogs_updateCatalogs_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface UpdateCatalogs_updateCatalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface UpdateCatalogs_updateCatalogs_debitor {
  /**
   * Company identifier
   */
  company: string;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Sales Perseon Code
   */
  salesPersonCode: string;
  /**
   * Sales Perseon Name
   */
  salesPersonName: string;
}

export interface UpdateCatalogs_updateCatalogs_craftsmen_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface UpdateCatalogs_updateCatalogs_craftsmen_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface UpdateCatalogs_updateCatalogs_craftsmen {
  /**
   * Id for catalog call contact
   */
  id: string;
  /**
   * Customer
   */
  customer: UpdateCatalogs_updateCatalogs_craftsmen_customer;
  /**
   * Craftsman changed by
   */
  changedBy: string;
  /**
   * Craftsman type
   */
  type: string;
  /**
   * Craftsman name
   */
  contactName: string;
  /**
   * Informations for Craftsman
   */
  informations: UpdateCatalogs_updateCatalogs_craftsmen_informations[];
  /**
   * Craftsman email
   */
  email: string | null;
  /**
   * Craftsman priority
   */
  priority: string;
  /**
   * Is contact global
   */
  global: boolean;
  /**
   * Global type for craftsman
   */
  globalType: CatalogGlobalType;
  /**
   * Is contact active
   */
  active: boolean;
  /**
   * Version for catalog craftsman
   */
  version: number;
  /**
   * Catalog ids
   */
  catalogIds: string[];
}

export interface UpdateCatalogs_updateCatalogs_contacts_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface UpdateCatalogs_updateCatalogs_contacts_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface UpdateCatalogs_updateCatalogs_contacts {
  /**
   * Id for catalog contact
   */
  id: string;
  /**
   * Customer
   */
  customer: UpdateCatalogs_updateCatalogs_contacts_customer;
  /**
   * Contact changed by
   */
  changedBy: string;
  /**
   * Contact name
   */
  contactName: string;
  /**
   * Contact type
   */
  type: string | null;
  /**
   * Contact collection type
   */
  contactType: CatalogContacts;
  /**
   * Contact email
   */
  email: string | null;
  /**
   * Informations for Contacts
   */
  informations: (UpdateCatalogs_updateCatalogs_contacts_informations | null)[];
  /**
   * Is contact global
   */
  global: boolean;
  /**
   * Global type for contact
   */
  globalType: CatalogGlobalType;
  /**
   * Is contact active
   */
  active: boolean;
  /**
   * Catalog ids
   */
  catalogIds: string[];
  /**
   * Version for catalog contact
   */
  version: number;
}

export interface UpdateCatalogs_updateCatalogs_timedMessages_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface UpdateCatalogs_updateCatalogs_timedMessages {
  /**
   * id to display
   */
  id: string;
  /**
   * Customer
   */
  customer: UpdateCatalogs_updateCatalogs_timedMessages_customer;
  /**
   * Craftsman changed by
   */
  changedBy: string;
  /**
   * Time at which the message should show from
   */
  startTime: GQL_DateTime;
  /**
   * Time at which the message should stop showing
   */
  endTime: GQL_DateTime;
  /**
   * message to display
   */
  message: string;
  /**
   * Is timed message global
   */
  global: boolean;
  /**
   * Global type for timed message
   */
  globalType: CatalogGlobalType;
  /**
   * Is timed message active
   */
  active: boolean;
  /**
   * Version for timed message
   */
  version: number;
  /**
   * Catalog ids
   */
  catalogIds: string[];
}

export interface UpdateCatalogs_updateCatalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Contact number for the Catalog
   */
  contactNumber: string | null;
  /**
   * Hours contact number can be reached
   */
  contactHours: string | null;
  /**
   * Customer
   */
  customer: UpdateCatalogs_updateCatalogs_customer;
  /**
   * Catalog changed by
   */
  changedBy: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: UpdateCatalogs_updateCatalogs_addresses[];
  /**
   * Is sms service used for Catalog
   */
  smsService: boolean;
  /**
   * Catalog debitor details
   */
  debitor: UpdateCatalogs_updateCatalogs_debitor | null;
  /**
   * Is catalog verified
   */
  verified: boolean;
  /**
   * Is catalog debitor verified
   */
  debitorVerified: boolean;
  /**
   * Version for catalog
   */
  version: number;
  /**
   * Timestamp of when the catalog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the catalog was created
   */
  createdAt: GQL_DateTime;
  /**
   * SGGAccountable for catalog
   */
  ssgAccountable: string | null;
  /**
   * Description for catalog
   */
  description: string | null;
  /**
   * Craftsmen attached to catalog
   */
  craftsmen: UpdateCatalogs_updateCatalogs_craftsmen[];
  /**
   * Contacs attached to catalog
   */
  contacts: UpdateCatalogs_updateCatalogs_contacts[];
  /**
   * Timed messages attached to catalog
   */
  timedMessages: UpdateCatalogs_updateCatalogs_timedMessages[];
}

export interface UpdateCatalogs {
  /**
   * Must contain all properties on object - properties not included will be overridden by the type's default value
   */
  updateCatalogs: UpdateCatalogs_updateCatalogs[];
}

export interface UpdateCatalogsVariables {
  catalogs: (CatalogBatchInput | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCollectionItems
// ====================================================

export interface GetCollectionItems_collectionItems {
  /**
   * Id of collection item
   */
  id: string;
  /**
   * Name of collection item
   */
  name: string;
  /**
   * Type of collection item
   */
  type: CollectionItemType;
}

export interface GetCollectionItems {
  collectionItems: GetCollectionItems_collectionItems[];
}

export interface GetCollectionItemsVariables {
  type?: CollectionItemType | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWebDamageCategories
// ====================================================

export interface GetWebDamageCategories_damageCategories {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetWebDamageCategories {
  damageCategories: GetWebDamageCategories_damageCategories[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWebDamageCauses
// ====================================================

export interface GetWebDamageCauses_damageCauses_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetWebDamageCauses_damageCauses_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetWebDamageCauses_damageCauses_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetWebDamageCauses_damageCauses_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: GetWebDamageCauses_damageCauses_departments_location;
}

export interface GetWebDamageCauses_damageCauses {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: GetWebDamageCauses_damageCauses_businessArea;
  /**
   * Id of associated category
   */
  category: GetWebDamageCauses_damageCauses_category;
  /**
   * Departments associated to damage cause
   */
  departments: (GetWebDamageCauses_damageCauses_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface GetWebDamageCauses {
  damageCauses: GetWebDamageCauses_damageCauses[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDebitor
// ====================================================

export interface GetDebitor_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
}

export interface GetDebitor_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Debitor address
   */
  address: GetDebitor_debitor_address | null;
  /**
   * Company identifier
   */
  company: string;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface GetDebitor {
  debitor: GetDebitor_debitor;
}

export interface GetDebitorVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDebitorShipmentAddresses
// ====================================================

export interface GetDebitorShipmentAddresses_debitorShipmentAddresses_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
}

export interface GetDebitorShipmentAddresses_debitorShipmentAddresses {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Name of debitor
   */
  name: string;
  /**
   * Shipping address
   */
  address: GetDebitorShipmentAddresses_debitorShipmentAddresses_address;
}

export interface GetDebitorShipmentAddresses {
  debitorShipmentAddresses: GetDebitorShipmentAddresses_debitorShipmentAddresses[];
}

export interface GetDebitorShipmentAddressesVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWebDebitors
// ====================================================

export interface GetWebDebitors_debitors_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
}

export interface GetWebDebitors_debitors {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Debitor address
   */
  address: GetWebDebitors_debitors_address | null;
  /**
   * Company identifier
   */
  company: string;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
}

export interface GetWebDebitors {
  debitors: GetWebDebitors_debitors[];
}

export interface GetWebDebitorsVariables {
  searchText: string;
  catalogUser?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateDebitor
// ====================================================

export interface UpdateDebitor {
  updateDebitor: string | null;
}

export interface UpdateDebitorVariables {
  no: string;
  company?: string | null;
  addressLine?: string | null;
  addressLineAlt?: string | null;
  postalCode?: string | null;
  city?: string | null;
  country?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRequisitionDepartments
// ====================================================

export interface GetRequisitionDepartments_departmentsRequisition_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetRequisitionDepartments_departmentsRequisition_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: GetRequisitionDepartments_departmentsRequisition_location_address;
}

export interface GetRequisitionDepartments_departmentsRequisition {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: GetRequisitionDepartments_departmentsRequisition_location;
}

export interface GetRequisitionDepartments {
  departmentsRequisition: GetRequisitionDepartments_departmentsRequisition[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWebDepartments
// ====================================================

export interface GetWebDepartments_departments_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebDepartments_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: GetWebDepartments_departments_location_address;
}

export interface GetWebDepartments_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: GetWebDepartments_departments_location;
}

export interface GetWebDepartments {
  departments: GetWebDepartments_departments[];
}

export interface GetWebDepartmentsVariables {
  operationManager?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CompleteDrivingSlips
// ====================================================

export interface CompleteDrivingSlips_completeDrivingSlips {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
}

export interface CompleteDrivingSlips {
  completeDrivingSlips: CompleteDrivingSlips_completeDrivingSlips[];
}

export interface CompleteDrivingSlipsVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateDrivingSlip
// ====================================================

export interface CreateDrivingSlip_createDrivingSlip_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateDrivingSlip_createDrivingSlip_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateDrivingSlip_createDrivingSlip_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateDrivingSlip_createDrivingSlip_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface CreateDrivingSlip_createDrivingSlip_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: CreateDrivingSlip_createDrivingSlip_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface CreateDrivingSlip_createDrivingSlip_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: CreateDrivingSlip_createDrivingSlip_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface CreateDrivingSlip_createDrivingSlip_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: CreateDrivingSlip_createDrivingSlip_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface CreateDrivingSlip_createDrivingSlip_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateDrivingSlip_createDrivingSlip_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateDrivingSlip_createDrivingSlip_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateDrivingSlip_createDrivingSlip_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateDrivingSlip_createDrivingSlip_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: CreateDrivingSlip_createDrivingSlip_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: CreateDrivingSlip_createDrivingSlip_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: CreateDrivingSlip_createDrivingSlip_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface CreateDrivingSlip_createDrivingSlip_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateDrivingSlip_createDrivingSlip_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateDrivingSlip_createDrivingSlip_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateDrivingSlip_createDrivingSlip_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: CreateDrivingSlip_createDrivingSlip_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: CreateDrivingSlip_createDrivingSlip_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: CreateDrivingSlip_createDrivingSlip_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface CreateDrivingSlip_createDrivingSlip_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: CreateDrivingSlip_createDrivingSlip_changes_user;
  /**
   * Value before
   */
  before: CreateDrivingSlip_createDrivingSlip_changes_before;
  /**
   * Value after
   */
  after: CreateDrivingSlip_createDrivingSlip_changes_after;
}

export interface CreateDrivingSlip_createDrivingSlip_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface CreateDrivingSlip_createDrivingSlip_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface CreateDrivingSlip_createDrivingSlip_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateDrivingSlip_createDrivingSlip_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: CreateDrivingSlip_createDrivingSlip_case_damage_contact_address;
}

export interface CreateDrivingSlip_createDrivingSlip_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface CreateDrivingSlip_createDrivingSlip_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface CreateDrivingSlip_createDrivingSlip_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface CreateDrivingSlip_createDrivingSlip_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface CreateDrivingSlip_createDrivingSlip_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: CreateDrivingSlip_createDrivingSlip_case_damage_cause_departments_location;
}

export interface CreateDrivingSlip_createDrivingSlip_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: CreateDrivingSlip_createDrivingSlip_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: CreateDrivingSlip_createDrivingSlip_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (CreateDrivingSlip_createDrivingSlip_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface CreateDrivingSlip_createDrivingSlip_case_damage {
  /**
   * Damage location contact information
   */
  contact: CreateDrivingSlip_createDrivingSlip_case_damage_contact;
  /**
   * Damage category
   */
  category: CreateDrivingSlip_createDrivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: CreateDrivingSlip_createDrivingSlip_case_damage_cause;
}

export interface CreateDrivingSlip_createDrivingSlip_case_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateDrivingSlip_createDrivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: CreateDrivingSlip_createDrivingSlip_case_damage;
  /**
   * Project manager attached to case
   */
  projectManager: CreateDrivingSlip_createDrivingSlip_case_projectManager | null;
}

export interface CreateDrivingSlip_createDrivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: CreateDrivingSlip_createDrivingSlip_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: CreateDrivingSlip_createDrivingSlip_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: CreateDrivingSlip_createDrivingSlip_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: CreateDrivingSlip_createDrivingSlip_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: CreateDrivingSlip_createDrivingSlip_changes[];
  /**
   * Location of driving slip
   */
  location: CreateDrivingSlip_createDrivingSlip_location;
  /**
   * Department of driving slip
   */
  department: CreateDrivingSlip_createDrivingSlip_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
  /**
   * Case which driving slip is related to
   */
  case: CreateDrivingSlip_createDrivingSlip_case;
}

export interface CreateDrivingSlip {
  createDrivingSlip: CreateDrivingSlip_createDrivingSlip[];
}

export interface CreateDrivingSlipVariables {
  drivingSlip: DrivingSlipHelperInput;
  caseId: string;
  includeCase?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteDrivingSlip
// ====================================================

export interface DeleteDrivingSlip_deleteDrivingSlip {
  /**
   * This value is not used for anything.
   */
  _: string;
}

export interface DeleteDrivingSlip {
  deleteDrivingSlip: DeleteDrivingSlip_deleteDrivingSlip | null;
}

export interface DeleteDrivingSlipVariables {
  id: string;
  caseId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDistance
// ====================================================

export interface GetDistance_getDistance {
  /**
   * Distance between addresses
   */
  distance: string;
  /**
   * Duration of travel
   */
  duration: string;
}

export interface GetDistance {
  getDistance: GetDistance_getDistance;
}

export interface GetDistanceVariables {
  fromAddress: AddressInput;
  toAddress: AddressInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDrivingSlipCategories
// ====================================================

export interface GetDrivingSlipCategories_drivingSlipCategories {
  /**
   * Category code
   */
  code: string;
  /**
   * Category name
   */
  name: string;
  /**
   * Category ERP tasks to be used as filter
   */
  erpReferenceTasks: string[];
}

export interface GetDrivingSlipCategories {
  drivingSlipCategories: GetDrivingSlipCategories_drivingSlipCategories[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDrivingSlipIDs
// ====================================================

export interface GetDrivingSlipIDs_drivingSlips {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface GetDrivingSlipIDs {
  drivingSlips: GetDrivingSlipIDs_drivingSlips[];
}

export interface GetDrivingSlipIDsVariables {
  personalOnly?: boolean | null;
  dateRange?: DateRangeInput | null;
  status?: DrivingSlipStatus | null;
  urgent?: boolean | null;
  exceeded?: boolean | null;
  departments?: string[] | null;
  locations?: string[] | null;
  damageCauses?: string[] | null;
  damageCategories?: string[] | null;
  drivers?: string[] | null;
  minStartDate?: GQL_Date | null;
  maxStartDate?: GQL_Date | null;
  minEndDate?: GQL_Date | null;
  maxEndDate?: GQL_Date | null;
  noDriver?: boolean | null;
  case?: string | null;
  searchString?: string | null;
  excludeTotalCount?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDrivingSlipsOverview
// ====================================================

export interface GetDrivingSlipsOverview_drivingSlips_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
}

export interface GetDrivingSlipsOverview_drivingSlips_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetDrivingSlipsOverview_drivingSlips_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetDrivingSlipsOverview_drivingSlips_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetDrivingSlipsOverview_drivingSlips_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetDrivingSlipsOverview_drivingSlips_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetDrivingSlipsOverview_drivingSlips_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: GetDrivingSlipsOverview_drivingSlips_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: GetDrivingSlipsOverview_drivingSlips_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface GetDrivingSlipsOverview_drivingSlips_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetDrivingSlipsOverview_drivingSlips_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetDrivingSlipsOverview_drivingSlips_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetDrivingSlipsOverview_drivingSlips_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetDrivingSlipsOverview_drivingSlips_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: GetDrivingSlipsOverview_drivingSlips_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: GetDrivingSlipsOverview_drivingSlips_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface GetDrivingSlipsOverview_drivingSlips_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: GetDrivingSlipsOverview_drivingSlips_changes_user;
  /**
   * Value before
   */
  before: GetDrivingSlipsOverview_drivingSlips_changes_before;
  /**
   * Value after
   */
  after: GetDrivingSlipsOverview_drivingSlips_changes_after;
}

export interface GetDrivingSlipsOverview_drivingSlips_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetDrivingSlipsOverview_drivingSlips_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface GetDrivingSlipsOverview_drivingSlips_case_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetDrivingSlipsOverview_drivingSlips_case_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetDrivingSlipsOverview_drivingSlips_case_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: GetDrivingSlipsOverview_drivingSlips_case_debitor_shippingAddress_address;
}

export interface GetDrivingSlipsOverview_drivingSlips_case_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetDrivingSlipsOverview_drivingSlips_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: GetDrivingSlipsOverview_drivingSlips_case_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: GetDrivingSlipsOverview_drivingSlips_case_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: GetDrivingSlipsOverview_drivingSlips_case_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface GetDrivingSlipsOverview_drivingSlips_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetDrivingSlipsOverview_drivingSlips_case_damage_contact {
  /**
   * Contact address information
   */
  address: GetDrivingSlipsOverview_drivingSlips_case_damage_contact_address;
}

export interface GetDrivingSlipsOverview_drivingSlips_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetDrivingSlipsOverview_drivingSlips_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetDrivingSlipsOverview_drivingSlips_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetDrivingSlipsOverview_drivingSlips_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetDrivingSlipsOverview_drivingSlips_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: GetDrivingSlipsOverview_drivingSlips_case_damage_cause_departments_location;
}

export interface GetDrivingSlipsOverview_drivingSlips_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: GetDrivingSlipsOverview_drivingSlips_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: GetDrivingSlipsOverview_drivingSlips_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (GetDrivingSlipsOverview_drivingSlips_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface GetDrivingSlipsOverview_drivingSlips_case_damage {
  /**
   * Damage location contact information
   */
  contact: GetDrivingSlipsOverview_drivingSlips_case_damage_contact;
  /**
   * Damage category
   */
  category: GetDrivingSlipsOverview_drivingSlips_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetDrivingSlipsOverview_drivingSlips_case_damage_cause;
}

export interface GetDrivingSlipsOverview_drivingSlips_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetDrivingSlipsOverview_drivingSlips_case_debitor;
  /**
   * Case damage details
   */
  damage: GetDrivingSlipsOverview_drivingSlips_case_damage;
}

export interface GetDrivingSlipsOverview_drivingSlips {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetDrivingSlipsOverview_drivingSlips_driver | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change log
   */
  changes: GetDrivingSlipsOverview_drivingSlips_changes[];
  /**
   * Location of driving slip
   */
  location: GetDrivingSlipsOverview_drivingSlips_location;
  /**
   * Department of driving slip
   */
  department: GetDrivingSlipsOverview_drivingSlips_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Case which driving slip is related to
   */
  case: GetDrivingSlipsOverview_drivingSlips_case;
}

export interface GetDrivingSlipsOverview {
  drivingSlips: GetDrivingSlipsOverview_drivingSlips[];
}

export interface GetDrivingSlipsOverviewVariables {
  personalOnly?: boolean | null;
  dateRange?: DateRangeInput | null;
  status?: DrivingSlipStatus | null;
  urgent?: boolean | null;
  exceeded?: boolean | null;
  departments?: string[] | null;
  locations?: string[] | null;
  damageCauses?: string[] | null;
  damageCategories?: string[] | null;
  drivers?: string[] | null;
  debitor?: string[] | null;
  priorityCustomers?: boolean | null;
  minStartDate?: GQL_Date | null;
  maxStartDate?: GQL_Date | null;
  minEndDate?: GQL_Date | null;
  maxEndDate?: GQL_Date | null;
  noDriver?: boolean | null;
  case?: string | null;
  offset?: number | null;
  limit?: number | null;
  searchString?: string | null;
  excludeTotalCount?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDrivingSlipsPlanner
// ====================================================

export interface GetDrivingSlipsPlanner_drivingSlips_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetDrivingSlipsPlanner_drivingSlips_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetDrivingSlipsPlanner_drivingSlips_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface GetDrivingSlipsPlanner_drivingSlips_case_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetDrivingSlipsPlanner_drivingSlips_case_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetDrivingSlipsPlanner_drivingSlips_case_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: GetDrivingSlipsPlanner_drivingSlips_case_debitor_shippingAddress_address;
}

export interface GetDrivingSlipsPlanner_drivingSlips_case_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetDrivingSlipsPlanner_drivingSlips_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: GetDrivingSlipsPlanner_drivingSlips_case_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: GetDrivingSlipsPlanner_drivingSlips_case_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: GetDrivingSlipsPlanner_drivingSlips_case_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface GetDrivingSlipsPlanner_drivingSlips_case_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetDrivingSlipsPlanner_drivingSlips_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetDrivingSlipsPlanner_drivingSlips_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetDrivingSlipsPlanner_drivingSlips_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetDrivingSlipsPlanner_drivingSlips_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetDrivingSlipsPlanner_drivingSlips_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: GetDrivingSlipsPlanner_drivingSlips_case_damage_cause_departments_location;
}

export interface GetDrivingSlipsPlanner_drivingSlips_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: GetDrivingSlipsPlanner_drivingSlips_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: GetDrivingSlipsPlanner_drivingSlips_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (GetDrivingSlipsPlanner_drivingSlips_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface GetDrivingSlipsPlanner_drivingSlips_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetDrivingSlipsPlanner_drivingSlips_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetDrivingSlipsPlanner_drivingSlips_case_damage_contact_address;
}

export interface GetDrivingSlipsPlanner_drivingSlips_case_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface GetDrivingSlipsPlanner_drivingSlips_case_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: GetDrivingSlipsPlanner_drivingSlips_case_damage_businessArea;
  /**
   * Damage category
   */
  category: GetDrivingSlipsPlanner_drivingSlips_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetDrivingSlipsPlanner_drivingSlips_case_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: GetDrivingSlipsPlanner_drivingSlips_case_damage_contact;
  /**
   * contacts for damage
   */
  contacts: GetDrivingSlipsPlanner_drivingSlips_case_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface GetDrivingSlipsPlanner_drivingSlips_case_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetDrivingSlipsPlanner_drivingSlips_case_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
}

export interface GetDrivingSlipsPlanner_drivingSlips_case_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: GetDrivingSlipsPlanner_drivingSlips_case_visitation_calledBack;
  /**
   * A set of answers related to the damage cause
   */
  answers: GetDrivingSlipsPlanner_drivingSlips_case_visitation_answers[] | null;
}

export interface GetDrivingSlipsPlanner_drivingSlips_case_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetDrivingSlipsPlanner_drivingSlips_case_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetDrivingSlipsPlanner_drivingSlips_case_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetDrivingSlipsPlanner_drivingSlips_case_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetDrivingSlipsPlanner_drivingSlips_case_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetDrivingSlipsPlanner_drivingSlips_case_policyHolder_address;
}

export interface GetDrivingSlipsPlanner_drivingSlips_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * Case debitor details
   */
  debitor: GetDrivingSlipsPlanner_drivingSlips_case_debitor;
  /**
   * Case damage details
   */
  damage: GetDrivingSlipsPlanner_drivingSlips_case_damage;
  /**
   * Case visitation details
   */
  visitation: GetDrivingSlipsPlanner_drivingSlips_case_visitation;
  /**
   * Project manager attached to case
   */
  projectManager: GetDrivingSlipsPlanner_drivingSlips_case_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: GetDrivingSlipsPlanner_drivingSlips_case_caseManager | null;
  /**
   * User who created the case
   */
  createdBy: GetDrivingSlipsPlanner_drivingSlips_case_createdBy | null;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * Case policy holder details
   */
  policyHolder: GetDrivingSlipsPlanner_drivingSlips_case_policyHolder;
}

export interface GetDrivingSlipsPlanner_drivingSlips {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetDrivingSlipsPlanner_drivingSlips_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Location of driving slip
   */
  location: GetDrivingSlipsPlanner_drivingSlips_location;
  /**
   * Department of driving slip
   */
  department: GetDrivingSlipsPlanner_drivingSlips_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Case which driving slip is related to
   */
  case: GetDrivingSlipsPlanner_drivingSlips_case;
}

export interface GetDrivingSlipsPlanner {
  drivingSlips: GetDrivingSlipsPlanner_drivingSlips[];
}

export interface GetDrivingSlipsPlannerVariables {
  personalOnly?: boolean | null;
  dateRange?: DateRangeInput | null;
  status?: DrivingSlipStatus | null;
  urgent?: boolean | null;
  exceeded?: boolean | null;
  departments?: string[] | null;
  locations?: string[] | null;
  damageCauses?: string[] | null;
  damageCategories?: string[] | null;
  drivers?: string[] | null;
  minStartDate?: GQL_Date | null;
  maxStartDate?: GQL_Date | null;
  minEndDate?: GQL_Date | null;
  maxEndDate?: GQL_Date | null;
  noDriver?: boolean | null;
  case?: string | null;
  offset?: number | null;
  limit?: number | null;
  searchString?: string | null;
  excludeTotalCount?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPersonalDrivingSlips
// ====================================================

export interface GetPersonalDrivingSlips_drivingSlips_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetPersonalDrivingSlips_drivingSlips_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetPersonalDrivingSlips_drivingSlips_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetPersonalDrivingSlips_drivingSlips_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface GetPersonalDrivingSlips_drivingSlips_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: GetPersonalDrivingSlips_drivingSlips_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface GetPersonalDrivingSlips_drivingSlips_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: GetPersonalDrivingSlips_drivingSlips_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface GetPersonalDrivingSlips_drivingSlips_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: GetPersonalDrivingSlips_drivingSlips_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface GetPersonalDrivingSlips_drivingSlips_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetPersonalDrivingSlips_drivingSlips_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetPersonalDrivingSlips_drivingSlips_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetPersonalDrivingSlips_drivingSlips_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetPersonalDrivingSlips_drivingSlips_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetPersonalDrivingSlips_drivingSlips_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: GetPersonalDrivingSlips_drivingSlips_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: GetPersonalDrivingSlips_drivingSlips_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface GetPersonalDrivingSlips_drivingSlips_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetPersonalDrivingSlips_drivingSlips_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetPersonalDrivingSlips_drivingSlips_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetPersonalDrivingSlips_drivingSlips_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetPersonalDrivingSlips_drivingSlips_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: GetPersonalDrivingSlips_drivingSlips_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: GetPersonalDrivingSlips_drivingSlips_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface GetPersonalDrivingSlips_drivingSlips_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: GetPersonalDrivingSlips_drivingSlips_changes_user;
  /**
   * Value before
   */
  before: GetPersonalDrivingSlips_drivingSlips_changes_before;
  /**
   * Value after
   */
  after: GetPersonalDrivingSlips_drivingSlips_changes_after;
}

export interface GetPersonalDrivingSlips_drivingSlips_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetPersonalDrivingSlips_drivingSlips_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface GetPersonalDrivingSlips_drivingSlips {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: GetPersonalDrivingSlips_drivingSlips_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: GetPersonalDrivingSlips_drivingSlips_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetPersonalDrivingSlips_drivingSlips_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: GetPersonalDrivingSlips_drivingSlips_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: GetPersonalDrivingSlips_drivingSlips_changes[];
  /**
   * Location of driving slip
   */
  location: GetPersonalDrivingSlips_drivingSlips_location;
  /**
   * Department of driving slip
   */
  department: GetPersonalDrivingSlips_drivingSlips_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
}

export interface GetPersonalDrivingSlips {
  drivingSlips: GetPersonalDrivingSlips_drivingSlips[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWebDrivingSlip
// ====================================================

export interface GetWebDrivingSlip_drivingSlip_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetWebDrivingSlip_drivingSlip_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetWebDrivingSlip_drivingSlip_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetWebDrivingSlip_drivingSlip_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface GetWebDrivingSlip_drivingSlip_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: GetWebDrivingSlip_drivingSlip_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface GetWebDrivingSlip_drivingSlip_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: GetWebDrivingSlip_drivingSlip_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface GetWebDrivingSlip_drivingSlip_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: GetWebDrivingSlip_drivingSlip_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface GetWebDrivingSlip_drivingSlip_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetWebDrivingSlip_drivingSlip_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetWebDrivingSlip_drivingSlip_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetWebDrivingSlip_drivingSlip_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetWebDrivingSlip_drivingSlip_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetWebDrivingSlip_drivingSlip_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: GetWebDrivingSlip_drivingSlip_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: GetWebDrivingSlip_drivingSlip_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface GetWebDrivingSlip_drivingSlip_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetWebDrivingSlip_drivingSlip_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetWebDrivingSlip_drivingSlip_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetWebDrivingSlip_drivingSlip_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetWebDrivingSlip_drivingSlip_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: GetWebDrivingSlip_drivingSlip_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: GetWebDrivingSlip_drivingSlip_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface GetWebDrivingSlip_drivingSlip_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: GetWebDrivingSlip_drivingSlip_changes_user;
  /**
   * Value before
   */
  before: GetWebDrivingSlip_drivingSlip_changes_before;
  /**
   * Value after
   */
  after: GetWebDrivingSlip_drivingSlip_changes_after;
}

export interface GetWebDrivingSlip_drivingSlip_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetWebDrivingSlip_drivingSlip_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface GetWebDrivingSlip_drivingSlip_case_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebDrivingSlip_drivingSlip_case_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebDrivingSlip_drivingSlip_case_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: GetWebDrivingSlip_drivingSlip_case_debitor_shippingAddress_address;
}

export interface GetWebDrivingSlip_drivingSlip_case_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebDrivingSlip_drivingSlip_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: GetWebDrivingSlip_drivingSlip_case_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: GetWebDrivingSlip_drivingSlip_case_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: GetWebDrivingSlip_drivingSlip_case_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface GetWebDrivingSlip_drivingSlip_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebDrivingSlip_drivingSlip_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetWebDrivingSlip_drivingSlip_case_damage_contact_address;
}

export interface GetWebDrivingSlip_drivingSlip_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetWebDrivingSlip_drivingSlip_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetWebDrivingSlip_drivingSlip_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetWebDrivingSlip_drivingSlip_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetWebDrivingSlip_drivingSlip_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: GetWebDrivingSlip_drivingSlip_case_damage_cause_departments_location;
}

export interface GetWebDrivingSlip_drivingSlip_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: GetWebDrivingSlip_drivingSlip_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: GetWebDrivingSlip_drivingSlip_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (GetWebDrivingSlip_drivingSlip_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface GetWebDrivingSlip_drivingSlip_case_damage {
  /**
   * Damage location contact information
   */
  contact: GetWebDrivingSlip_drivingSlip_case_damage_contact;
  /**
   * Damage category
   */
  category: GetWebDrivingSlip_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetWebDrivingSlip_drivingSlip_case_damage_cause;
}

export interface GetWebDrivingSlip_drivingSlip_case_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebDrivingSlip_drivingSlip_case_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetWebDrivingSlip_drivingSlip_case_policyHolder_address;
}

export interface GetWebDrivingSlip_drivingSlip_case_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetWebDrivingSlip_drivingSlip_case_caseManager {
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface GetWebDrivingSlip_drivingSlip_case_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebDrivingSlip_drivingSlip_case_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: GetWebDrivingSlip_drivingSlip_case_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface GetWebDrivingSlip_drivingSlip_case_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebDrivingSlip_drivingSlip_case_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: GetWebDrivingSlip_drivingSlip_case_ssgDepartment_location_address;
}

export interface GetWebDrivingSlip_drivingSlip_case_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: GetWebDrivingSlip_drivingSlip_case_ssgDepartment_location;
}

export interface GetWebDrivingSlip_drivingSlip_case_riskEvaluationAnswers_question {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question
   */
  question: string;
}

export interface GetWebDrivingSlip_drivingSlip_case_riskEvaluationAnswers {
  /**
   * Id of question answer is linked to
   */
  question: GetWebDrivingSlip_drivingSlip_case_riskEvaluationAnswers_question;
  /**
   * Answer given
   */
  answer: boolean;
}

export interface GetWebDrivingSlip_drivingSlip_case_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetWebDrivingSlip_drivingSlip_case_visitation_awaiting {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetWebDrivingSlip_drivingSlip_case_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
  /**
   * The question
   */
  title: string;
}

export interface GetWebDrivingSlip_drivingSlip_case_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: GetWebDrivingSlip_drivingSlip_case_visitation_calledBack;
  /**
   * If case is awaiting
   */
  awaiting: GetWebDrivingSlip_drivingSlip_case_visitation_awaiting;
  /**
   * A set of answers related to the damage cause
   */
  answers: GetWebDrivingSlip_drivingSlip_case_visitation_answers[] | null;
}

export interface GetWebDrivingSlip_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * Case debitor details
   */
  debitor: GetWebDrivingSlip_drivingSlip_case_debitor;
  /**
   * Case damage details
   */
  damage: GetWebDrivingSlip_drivingSlip_case_damage;
  /**
   * Case policy holder details
   */
  policyHolder: GetWebDrivingSlip_drivingSlip_case_policyHolder;
  /**
   * Project manager attached to case
   */
  projectManager: GetWebDrivingSlip_drivingSlip_case_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: GetWebDrivingSlip_drivingSlip_case_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: GetWebDrivingSlip_drivingSlip_case_ssgLocation;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: GetWebDrivingSlip_drivingSlip_case_ssgDepartment;
  /**
   * Risk evaluation answers
   */
  riskEvaluationAnswers: GetWebDrivingSlip_drivingSlip_case_riskEvaluationAnswers[] | null;
  /**
   * Optional comment for risk evaluation
   */
  riskEvaluationComment: string | null;
  /**
   * Case visitation details
   */
  visitation: GetWebDrivingSlip_drivingSlip_case_visitation;
  /**
   * Whether time registration is allowed on the case
   */
  timeRegistration: boolean | null;
}

export interface GetWebDrivingSlip_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: GetWebDrivingSlip_drivingSlip_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: GetWebDrivingSlip_drivingSlip_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetWebDrivingSlip_drivingSlip_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: GetWebDrivingSlip_drivingSlip_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: GetWebDrivingSlip_drivingSlip_changes[];
  /**
   * Location of driving slip
   */
  location: GetWebDrivingSlip_drivingSlip_location;
  /**
   * Department of driving slip
   */
  department: GetWebDrivingSlip_drivingSlip_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
  /**
   * Case which driving slip is related to
   */
  case: GetWebDrivingSlip_drivingSlip_case;
}

export interface GetWebDrivingSlip {
  drivingSlip: GetWebDrivingSlip_drivingSlip;
}

export interface GetWebDrivingSlipVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWebDrivingSlips
// ====================================================

export interface GetWebDrivingSlips_drivingSlips_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetWebDrivingSlips_drivingSlips_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetWebDrivingSlips_drivingSlips_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetWebDrivingSlips_drivingSlips_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface GetWebDrivingSlips_drivingSlips_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: GetWebDrivingSlips_drivingSlips_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface GetWebDrivingSlips_drivingSlips_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: GetWebDrivingSlips_drivingSlips_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface GetWebDrivingSlips_drivingSlips_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: GetWebDrivingSlips_drivingSlips_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface GetWebDrivingSlips_drivingSlips_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetWebDrivingSlips_drivingSlips_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetWebDrivingSlips_drivingSlips_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetWebDrivingSlips_drivingSlips_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetWebDrivingSlips_drivingSlips_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetWebDrivingSlips_drivingSlips_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: GetWebDrivingSlips_drivingSlips_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: GetWebDrivingSlips_drivingSlips_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface GetWebDrivingSlips_drivingSlips_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetWebDrivingSlips_drivingSlips_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetWebDrivingSlips_drivingSlips_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetWebDrivingSlips_drivingSlips_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetWebDrivingSlips_drivingSlips_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: GetWebDrivingSlips_drivingSlips_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: GetWebDrivingSlips_drivingSlips_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface GetWebDrivingSlips_drivingSlips_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: GetWebDrivingSlips_drivingSlips_changes_user;
  /**
   * Value before
   */
  before: GetWebDrivingSlips_drivingSlips_changes_before;
  /**
   * Value after
   */
  after: GetWebDrivingSlips_drivingSlips_changes_after;
}

export interface GetWebDrivingSlips_drivingSlips_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetWebDrivingSlips_drivingSlips_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface GetWebDrivingSlips_drivingSlips_case_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebDrivingSlips_drivingSlips_case_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebDrivingSlips_drivingSlips_case_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: GetWebDrivingSlips_drivingSlips_case_debitor_shippingAddress_address;
}

export interface GetWebDrivingSlips_drivingSlips_case_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebDrivingSlips_drivingSlips_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: GetWebDrivingSlips_drivingSlips_case_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: GetWebDrivingSlips_drivingSlips_case_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: GetWebDrivingSlips_drivingSlips_case_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface GetWebDrivingSlips_drivingSlips_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebDrivingSlips_drivingSlips_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetWebDrivingSlips_drivingSlips_case_damage_contact_address;
}

export interface GetWebDrivingSlips_drivingSlips_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetWebDrivingSlips_drivingSlips_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetWebDrivingSlips_drivingSlips_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetWebDrivingSlips_drivingSlips_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetWebDrivingSlips_drivingSlips_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: GetWebDrivingSlips_drivingSlips_case_damage_cause_departments_location;
}

export interface GetWebDrivingSlips_drivingSlips_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: GetWebDrivingSlips_drivingSlips_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: GetWebDrivingSlips_drivingSlips_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (GetWebDrivingSlips_drivingSlips_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface GetWebDrivingSlips_drivingSlips_case_damage {
  /**
   * Damage location contact information
   */
  contact: GetWebDrivingSlips_drivingSlips_case_damage_contact;
  /**
   * Damage category
   */
  category: GetWebDrivingSlips_drivingSlips_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetWebDrivingSlips_drivingSlips_case_damage_cause;
}

export interface GetWebDrivingSlips_drivingSlips_case_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebDrivingSlips_drivingSlips_case_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetWebDrivingSlips_drivingSlips_case_policyHolder_address;
}

export interface GetWebDrivingSlips_drivingSlips_case_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetWebDrivingSlips_drivingSlips_case_caseManager {
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface GetWebDrivingSlips_drivingSlips_case_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebDrivingSlips_drivingSlips_case_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: GetWebDrivingSlips_drivingSlips_case_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface GetWebDrivingSlips_drivingSlips_case_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebDrivingSlips_drivingSlips_case_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: GetWebDrivingSlips_drivingSlips_case_ssgDepartment_location_address;
}

export interface GetWebDrivingSlips_drivingSlips_case_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: GetWebDrivingSlips_drivingSlips_case_ssgDepartment_location;
}

export interface GetWebDrivingSlips_drivingSlips_case_riskEvaluationAnswers_question {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question
   */
  question: string;
}

export interface GetWebDrivingSlips_drivingSlips_case_riskEvaluationAnswers {
  /**
   * Id of question answer is linked to
   */
  question: GetWebDrivingSlips_drivingSlips_case_riskEvaluationAnswers_question;
  /**
   * Answer given
   */
  answer: boolean;
}

export interface GetWebDrivingSlips_drivingSlips_case_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetWebDrivingSlips_drivingSlips_case_visitation_awaiting {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetWebDrivingSlips_drivingSlips_case_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
  /**
   * The question
   */
  title: string;
}

export interface GetWebDrivingSlips_drivingSlips_case_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: GetWebDrivingSlips_drivingSlips_case_visitation_calledBack;
  /**
   * If case is awaiting
   */
  awaiting: GetWebDrivingSlips_drivingSlips_case_visitation_awaiting;
  /**
   * A set of answers related to the damage cause
   */
  answers: GetWebDrivingSlips_drivingSlips_case_visitation_answers[] | null;
}

export interface GetWebDrivingSlips_drivingSlips_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * Case debitor details
   */
  debitor: GetWebDrivingSlips_drivingSlips_case_debitor;
  /**
   * Case damage details
   */
  damage: GetWebDrivingSlips_drivingSlips_case_damage;
  /**
   * Case policy holder details
   */
  policyHolder: GetWebDrivingSlips_drivingSlips_case_policyHolder;
  /**
   * Project manager attached to case
   */
  projectManager: GetWebDrivingSlips_drivingSlips_case_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: GetWebDrivingSlips_drivingSlips_case_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: GetWebDrivingSlips_drivingSlips_case_ssgLocation;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: GetWebDrivingSlips_drivingSlips_case_ssgDepartment;
  /**
   * Risk evaluation answers
   */
  riskEvaluationAnswers: GetWebDrivingSlips_drivingSlips_case_riskEvaluationAnswers[] | null;
  /**
   * Optional comment for risk evaluation
   */
  riskEvaluationComment: string | null;
  /**
   * Case visitation details
   */
  visitation: GetWebDrivingSlips_drivingSlips_case_visitation;
  /**
   * Whether time registration is allowed on the case
   */
  timeRegistration: boolean | null;
}

export interface GetWebDrivingSlips_drivingSlips {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: GetWebDrivingSlips_drivingSlips_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: GetWebDrivingSlips_drivingSlips_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetWebDrivingSlips_drivingSlips_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: GetWebDrivingSlips_drivingSlips_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: GetWebDrivingSlips_drivingSlips_changes[];
  /**
   * Location of driving slip
   */
  location: GetWebDrivingSlips_drivingSlips_location;
  /**
   * Department of driving slip
   */
  department: GetWebDrivingSlips_drivingSlips_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
  /**
   * Case which driving slip is related to
   */
  case: GetWebDrivingSlips_drivingSlips_case;
}

export interface GetWebDrivingSlips {
  drivingSlips: GetWebDrivingSlips_drivingSlips[];
}

export interface GetWebDrivingSlipsVariables {
  personalOnly?: boolean | null;
  dateRange?: DateRangeInput | null;
  status?: DrivingSlipStatus | null;
  urgent?: boolean | null;
  exceeded?: boolean | null;
  departments?: string[] | null;
  locations?: string[] | null;
  damageCauses?: string[] | null;
  damageCategories?: string[] | null;
  drivers?: string[] | null;
  minStartDate?: GQL_Date | null;
  maxStartDate?: GQL_Date | null;
  minEndDate?: GQL_Date | null;
  maxEndDate?: GQL_Date | null;
  noDriver?: boolean | null;
  case?: string | null;
  offset?: number | null;
  limit?: number | null;
  searchString?: string | null;
  excludeTotalCount?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWebDrivingSlipsNoCase
// ====================================================

export interface GetWebDrivingSlipsNoCase_drivingSlips_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetWebDrivingSlipsNoCase_drivingSlips_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetWebDrivingSlipsNoCase_drivingSlips_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetWebDrivingSlipsNoCase_drivingSlips_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface GetWebDrivingSlipsNoCase_drivingSlips_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: GetWebDrivingSlipsNoCase_drivingSlips_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface GetWebDrivingSlipsNoCase_drivingSlips_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: GetWebDrivingSlipsNoCase_drivingSlips_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface GetWebDrivingSlipsNoCase_drivingSlips_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: GetWebDrivingSlipsNoCase_drivingSlips_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface GetWebDrivingSlipsNoCase_drivingSlips_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetWebDrivingSlipsNoCase_drivingSlips_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetWebDrivingSlipsNoCase_drivingSlips_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetWebDrivingSlipsNoCase_drivingSlips_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetWebDrivingSlipsNoCase_drivingSlips_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetWebDrivingSlipsNoCase_drivingSlips_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: GetWebDrivingSlipsNoCase_drivingSlips_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: GetWebDrivingSlipsNoCase_drivingSlips_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface GetWebDrivingSlipsNoCase_drivingSlips_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetWebDrivingSlipsNoCase_drivingSlips_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetWebDrivingSlipsNoCase_drivingSlips_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetWebDrivingSlipsNoCase_drivingSlips_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetWebDrivingSlipsNoCase_drivingSlips_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: GetWebDrivingSlipsNoCase_drivingSlips_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: GetWebDrivingSlipsNoCase_drivingSlips_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface GetWebDrivingSlipsNoCase_drivingSlips_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: GetWebDrivingSlipsNoCase_drivingSlips_changes_user;
  /**
   * Value before
   */
  before: GetWebDrivingSlipsNoCase_drivingSlips_changes_before;
  /**
   * Value after
   */
  after: GetWebDrivingSlipsNoCase_drivingSlips_changes_after;
}

export interface GetWebDrivingSlipsNoCase_drivingSlips_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetWebDrivingSlipsNoCase_drivingSlips_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface GetWebDrivingSlipsNoCase_drivingSlips {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: GetWebDrivingSlipsNoCase_drivingSlips_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: GetWebDrivingSlipsNoCase_drivingSlips_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetWebDrivingSlipsNoCase_drivingSlips_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: GetWebDrivingSlipsNoCase_drivingSlips_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: GetWebDrivingSlipsNoCase_drivingSlips_changes[];
  /**
   * Location of driving slip
   */
  location: GetWebDrivingSlipsNoCase_drivingSlips_location;
  /**
   * Department of driving slip
   */
  department: GetWebDrivingSlipsNoCase_drivingSlips_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
}

export interface GetWebDrivingSlipsNoCase {
  drivingSlips: GetWebDrivingSlipsNoCase_drivingSlips[];
}

export interface GetWebDrivingSlipsNoCaseVariables {
  personalOnly?: boolean | null;
  dateRange?: DateRangeInput | null;
  status?: DrivingSlipStatus | null;
  urgent?: boolean | null;
  exceeded?: boolean | null;
  departments?: string[] | null;
  locations?: string[] | null;
  damageCauses?: string[] | null;
  damageCategories?: string[] | null;
  drivers?: string[] | null;
  minStartDate?: GQL_Date | null;
  maxStartDate?: GQL_Date | null;
  minEndDate?: GQL_Date | null;
  maxEndDate?: GQL_Date | null;
  noDriver?: boolean | null;
  case?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetWebDrivingSlipStatus
// ====================================================

export interface SetWebDrivingSlipStatus_setDrivingSlipStatus_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetWebDrivingSlipStatus_setDrivingSlipStatus_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetWebDrivingSlipStatus_setDrivingSlipStatus_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetWebDrivingSlipStatus_setDrivingSlipStatus_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface SetWebDrivingSlipStatus_setDrivingSlipStatus_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: SetWebDrivingSlipStatus_setDrivingSlipStatus_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface SetWebDrivingSlipStatus_setDrivingSlipStatus_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: SetWebDrivingSlipStatus_setDrivingSlipStatus_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface SetWebDrivingSlipStatus_setDrivingSlipStatus_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: SetWebDrivingSlipStatus_setDrivingSlipStatus_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface SetWebDrivingSlipStatus_setDrivingSlipStatus_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetWebDrivingSlipStatus_setDrivingSlipStatus_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetWebDrivingSlipStatus_setDrivingSlipStatus_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetWebDrivingSlipStatus_setDrivingSlipStatus_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetWebDrivingSlipStatus_setDrivingSlipStatus_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: SetWebDrivingSlipStatus_setDrivingSlipStatus_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: SetWebDrivingSlipStatus_setDrivingSlipStatus_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: SetWebDrivingSlipStatus_setDrivingSlipStatus_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface SetWebDrivingSlipStatus_setDrivingSlipStatus_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetWebDrivingSlipStatus_setDrivingSlipStatus_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetWebDrivingSlipStatus_setDrivingSlipStatus_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetWebDrivingSlipStatus_setDrivingSlipStatus_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: SetWebDrivingSlipStatus_setDrivingSlipStatus_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: SetWebDrivingSlipStatus_setDrivingSlipStatus_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: SetWebDrivingSlipStatus_setDrivingSlipStatus_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface SetWebDrivingSlipStatus_setDrivingSlipStatus_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: SetWebDrivingSlipStatus_setDrivingSlipStatus_changes_user;
  /**
   * Value before
   */
  before: SetWebDrivingSlipStatus_setDrivingSlipStatus_changes_before;
  /**
   * Value after
   */
  after: SetWebDrivingSlipStatus_setDrivingSlipStatus_changes_after;
}

export interface SetWebDrivingSlipStatus_setDrivingSlipStatus_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface SetWebDrivingSlipStatus_setDrivingSlipStatus_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface SetWebDrivingSlipStatus_setDrivingSlipStatus {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: SetWebDrivingSlipStatus_setDrivingSlipStatus_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: SetWebDrivingSlipStatus_setDrivingSlipStatus_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: SetWebDrivingSlipStatus_setDrivingSlipStatus_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: SetWebDrivingSlipStatus_setDrivingSlipStatus_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: SetWebDrivingSlipStatus_setDrivingSlipStatus_changes[];
  /**
   * Location of driving slip
   */
  location: SetWebDrivingSlipStatus_setDrivingSlipStatus_location;
  /**
   * Department of driving slip
   */
  department: SetWebDrivingSlipStatus_setDrivingSlipStatus_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
}

export interface SetWebDrivingSlipStatus {
  setDrivingSlipStatus: SetWebDrivingSlipStatus_setDrivingSlipStatus;
}

export interface SetWebDrivingSlipStatusVariables {
  id: string;
  status: DrivingSlipStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateWebDrivingSlip
// ====================================================

export interface UpdateWebDrivingSlip_updateDrivingSlip_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: UpdateWebDrivingSlip_updateDrivingSlip_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: UpdateWebDrivingSlip_updateDrivingSlip_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: UpdateWebDrivingSlip_updateDrivingSlip_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: UpdateWebDrivingSlip_updateDrivingSlip_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: UpdateWebDrivingSlip_updateDrivingSlip_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: UpdateWebDrivingSlip_updateDrivingSlip_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: UpdateWebDrivingSlip_updateDrivingSlip_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: UpdateWebDrivingSlip_updateDrivingSlip_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: UpdateWebDrivingSlip_updateDrivingSlip_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: UpdateWebDrivingSlip_updateDrivingSlip_changes_user;
  /**
   * Value before
   */
  before: UpdateWebDrivingSlip_updateDrivingSlip_changes_before;
  /**
   * Value after
   */
  after: UpdateWebDrivingSlip_updateDrivingSlip_changes_after;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_case_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_case_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_case_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: UpdateWebDrivingSlip_updateDrivingSlip_case_debitor_shippingAddress_address;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_case_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: UpdateWebDrivingSlip_updateDrivingSlip_case_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: UpdateWebDrivingSlip_updateDrivingSlip_case_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: UpdateWebDrivingSlip_updateDrivingSlip_case_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: UpdateWebDrivingSlip_updateDrivingSlip_case_damage_contact_address;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: UpdateWebDrivingSlip_updateDrivingSlip_case_damage_cause_departments_location;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: UpdateWebDrivingSlip_updateDrivingSlip_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: UpdateWebDrivingSlip_updateDrivingSlip_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (UpdateWebDrivingSlip_updateDrivingSlip_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_case_damage {
  /**
   * Damage location contact information
   */
  contact: UpdateWebDrivingSlip_updateDrivingSlip_case_damage_contact;
  /**
   * Damage category
   */
  category: UpdateWebDrivingSlip_updateDrivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: UpdateWebDrivingSlip_updateDrivingSlip_case_damage_cause;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_case_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_case_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: UpdateWebDrivingSlip_updateDrivingSlip_case_policyHolder_address;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_case_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_case_caseManager {
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_case_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_case_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: UpdateWebDrivingSlip_updateDrivingSlip_case_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_case_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_case_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: UpdateWebDrivingSlip_updateDrivingSlip_case_ssgDepartment_location_address;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_case_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: UpdateWebDrivingSlip_updateDrivingSlip_case_ssgDepartment_location;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_case_riskEvaluationAnswers_question {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question
   */
  question: string;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_case_riskEvaluationAnswers {
  /**
   * Id of question answer is linked to
   */
  question: UpdateWebDrivingSlip_updateDrivingSlip_case_riskEvaluationAnswers_question;
  /**
   * Answer given
   */
  answer: boolean;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_case_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_case_visitation_awaiting {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_case_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
  /**
   * The question
   */
  title: string;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_case_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: UpdateWebDrivingSlip_updateDrivingSlip_case_visitation_calledBack;
  /**
   * If case is awaiting
   */
  awaiting: UpdateWebDrivingSlip_updateDrivingSlip_case_visitation_awaiting;
  /**
   * A set of answers related to the damage cause
   */
  answers: UpdateWebDrivingSlip_updateDrivingSlip_case_visitation_answers[] | null;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * Case debitor details
   */
  debitor: UpdateWebDrivingSlip_updateDrivingSlip_case_debitor;
  /**
   * Case damage details
   */
  damage: UpdateWebDrivingSlip_updateDrivingSlip_case_damage;
  /**
   * Case policy holder details
   */
  policyHolder: UpdateWebDrivingSlip_updateDrivingSlip_case_policyHolder;
  /**
   * Project manager attached to case
   */
  projectManager: UpdateWebDrivingSlip_updateDrivingSlip_case_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: UpdateWebDrivingSlip_updateDrivingSlip_case_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: UpdateWebDrivingSlip_updateDrivingSlip_case_ssgLocation;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: UpdateWebDrivingSlip_updateDrivingSlip_case_ssgDepartment;
  /**
   * Risk evaluation answers
   */
  riskEvaluationAnswers: UpdateWebDrivingSlip_updateDrivingSlip_case_riskEvaluationAnswers[] | null;
  /**
   * Optional comment for risk evaluation
   */
  riskEvaluationComment: string | null;
  /**
   * Case visitation details
   */
  visitation: UpdateWebDrivingSlip_updateDrivingSlip_case_visitation;
  /**
   * Whether time registration is allowed on the case
   */
  timeRegistration: boolean | null;
}

export interface UpdateWebDrivingSlip_updateDrivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: UpdateWebDrivingSlip_updateDrivingSlip_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: UpdateWebDrivingSlip_updateDrivingSlip_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: UpdateWebDrivingSlip_updateDrivingSlip_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: UpdateWebDrivingSlip_updateDrivingSlip_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: UpdateWebDrivingSlip_updateDrivingSlip_changes[];
  /**
   * Location of driving slip
   */
  location: UpdateWebDrivingSlip_updateDrivingSlip_location;
  /**
   * Department of driving slip
   */
  department: UpdateWebDrivingSlip_updateDrivingSlip_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
  /**
   * Case which driving slip is related to
   */
  case: UpdateWebDrivingSlip_updateDrivingSlip_case;
}

export interface UpdateWebDrivingSlip {
  updateDrivingSlip: UpdateWebDrivingSlip_updateDrivingSlip[];
}

export interface UpdateWebDrivingSlipVariables {
  id: string;
  urgent?: boolean | null;
  driver?: string | null;
  estimatedHours?: GQL_Decimal | null;
  start?: GQL_DateTime | null;
  end?: GQL_DateTime | null;
  comment?: string | null;
  deadline?: GQL_DateTime | null;
  car?: string | null;
  materials?: string[] | null;
  eventId?: string | null;
  additionalDays?: GQL_Date[] | null;
  additionalDrivers?: string[] | null;
  driverCarList?: DriverCarInput[] | null;
  location: string;
  department: string;
  category?: string | null;
  immediatelyPlan?: boolean | null;
  plannerMessage?: string | null;
  halfDay?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddCaseFilesToDrivingSlip
// ====================================================

export interface AddCaseFilesToDrivingSlip_addCaseFilesToDrivingSlip_metadata {
  /**
   * KVP key
   */
  key: string;
  /**
   * KVP value
   */
  value: string;
}

export interface AddCaseFilesToDrivingSlip_addCaseFilesToDrivingSlip {
  /**
   * File name
   */
  name: string;
  /**
   * File url from ESDH
   */
  url: string;
  /**
   * File thumbnail URL
   */
  thumbnail: string;
  /**
   * File extension
   */
  extension: string;
  /**
   * File grouping key
   */
  groupingKey: string;
  /**
   * File created
   */
  created: GQL_DateTime;
  /**
   * File metadata from ESDH
   */
  metadata: AddCaseFilesToDrivingSlip_addCaseFilesToDrivingSlip_metadata[];
  /**
   * File comments from ESDH
   */
  comments: string | null;
  /**
   * File extension
   */
  fileId: string;
}

export interface AddCaseFilesToDrivingSlip {
  addCaseFilesToDrivingSlip: AddCaseFilesToDrivingSlip_addCaseFilesToDrivingSlip[];
}

export interface AddCaseFilesToDrivingSlipVariables {
  filenames: string[];
  caseFolder: string;
  seriesFolder: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddCaseFileToDrivingSlip
// ====================================================

export interface AddCaseFileToDrivingSlip_addCaseFileToDrivingSlip_metadata {
  /**
   * KVP key
   */
  key: string;
  /**
   * KVP value
   */
  value: string;
}

export interface AddCaseFileToDrivingSlip_addCaseFileToDrivingSlip {
  /**
   * File name
   */
  name: string;
  /**
   * File url from ESDH
   */
  url: string;
  /**
   * File thumbnail URL
   */
  thumbnail: string;
  /**
   * File extension
   */
  extension: string;
  /**
   * File grouping key
   */
  groupingKey: string;
  /**
   * File created
   */
  created: GQL_DateTime;
  /**
   * File metadata from ESDH
   */
  metadata: AddCaseFileToDrivingSlip_addCaseFileToDrivingSlip_metadata[];
  /**
   * File comments from ESDH
   */
  comments: string | null;
  /**
   * File extension
   */
  fileId: string;
}

export interface AddCaseFileToDrivingSlip {
  addCaseFileToDrivingSlip: AddCaseFileToDrivingSlip_addCaseFileToDrivingSlip;
}

export interface AddCaseFileToDrivingSlipVariables {
  fileName: string;
  caseFolder: string;
  seriesFolder: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteCaseFile
// ====================================================

export interface DeleteCaseFile_deleteCaseFile {
  /**
   * This value is not used for anything.
   */
  _: string;
}

export interface DeleteCaseFile {
  deleteCaseFile: DeleteCaseFile_deleteCaseFile | null;
}

export interface DeleteCaseFileVariables {
  fileName: string;
  folder: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteCatalogFile
// ====================================================

export interface DeleteCatalogFile_deleteCatalogFile {
  /**
   * This value is not used for anything.
   */
  _: string;
}

export interface DeleteCatalogFile {
  deleteCatalogFile: DeleteCatalogFile_deleteCatalogFile | null;
}

export interface DeleteCatalogFileVariables {
  fileName: string;
  folder?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteDrivingSlipFile
// ====================================================

export interface DeleteDrivingSlipFile_deleteDrivingSlipFile {
  /**
   * This value is not used for anything.
   */
  _: string;
}

export interface DeleteDrivingSlipFile {
  deleteDrivingSlipFile: DeleteDrivingSlipFile_deleteDrivingSlipFile | null;
}

export interface DeleteDrivingSlipFileVariables {
  caseNo: string;
  drivingSlipId: string;
  fileName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteInvoiceFile
// ====================================================

export interface DeleteInvoiceFile_deleteInvoiceFile {
  /**
   * This value is not used for anything.
   */
  _: string;
}

export interface DeleteInvoiceFile {
  deleteInvoiceFile: DeleteInvoiceFile_deleteInvoiceFile | null;
}

export interface DeleteInvoiceFileVariables {
  caseNo: string;
  invoiceNo: string;
  fileName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCaseFiles
// ====================================================

export interface GetCaseFiles_caseFiles_metadata {
  /**
   * KVP key
   */
  key: string;
  /**
   * KVP value
   */
  value: string;
}

export interface GetCaseFiles_caseFiles {
  /**
   * File name
   */
  name: string;
  /**
   * File url from ESDH
   */
  url: string;
  /**
   * File thumbnail URL
   */
  thumbnail: string;
  /**
   * File extension
   */
  extension: string;
  /**
   * File grouping key
   */
  groupingKey: string;
  /**
   * File created
   */
  created: GQL_DateTime;
  /**
   * File metadata from ESDH
   */
  metadata: GetCaseFiles_caseFiles_metadata[];
  /**
   * File comments from ESDH
   */
  comments: string | null;
  /**
   * File extension
   */
  fileId: string;
}

export interface GetCaseFiles {
  caseFiles: GetCaseFiles_caseFiles[];
}

export interface GetCaseFilesVariables {
  folder?: string | null;
  caseErpNo?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCatalogESDHFiles
// ====================================================

export interface GetCatalogESDHFiles_catalog_documents_metadata {
  /**
   * KVP key
   */
  key: string;
  /**
   * KVP value
   */
  value: string;
}

export interface GetCatalogESDHFiles_catalog_documents {
  /**
   * File name
   */
  name: string;
  /**
   * File url from ESDH
   */
  url: string;
  /**
   * File thumbnail URL
   */
  thumbnail: string;
  /**
   * File extension
   */
  extension: string;
  /**
   * File grouping key
   */
  groupingKey: string;
  /**
   * File created
   */
  created: GQL_DateTime;
  /**
   * File metadata from ESDH
   */
  metadata: GetCatalogESDHFiles_catalog_documents_metadata[];
  /**
   * File comments from ESDH
   */
  comments: string | null;
  /**
   * File extension
   */
  fileId: string;
}

export interface GetCatalogESDHFiles_catalog {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * List of document file names and thumbnails
   */
  documents: GetCatalogESDHFiles_catalog_documents[];
}

export interface GetCatalogESDHFiles {
  catalog: GetCatalogESDHFiles_catalog;
}

export interface GetCatalogESDHFilesVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCatalogFile
// ====================================================

export interface GetCatalogFile_catalogFile {
  /**
   * File data as byte array
   */
  fileData: GQL_Byte[];
  /**
   * File name
   */
  filename: string;
}

export interface GetCatalogFile {
  catalogFile: GetCatalogFile_catalogFile;
}

export interface GetCatalogFileVariables {
  fileName: string;
  folder?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDrivingSlipFiles
// ====================================================

export interface GetDrivingSlipFiles_drivingSlipFiles_metadata {
  /**
   * KVP key
   */
  key: string;
  /**
   * KVP value
   */
  value: string;
}

export interface GetDrivingSlipFiles_drivingSlipFiles {
  /**
   * File name
   */
  name: string;
  /**
   * File url from ESDH
   */
  url: string;
  /**
   * File thumbnail URL
   */
  thumbnail: string;
  /**
   * File extension
   */
  extension: string;
  /**
   * File grouping key
   */
  groupingKey: string;
  /**
   * File created
   */
  created: GQL_DateTime;
  /**
   * File metadata from ESDH
   */
  metadata: GetDrivingSlipFiles_drivingSlipFiles_metadata[];
  /**
   * File comments from ESDH
   */
  comments: string | null;
  /**
   * File extension
   */
  fileId: string;
}

export interface GetDrivingSlipFiles {
  drivingSlipFiles: GetDrivingSlipFiles_drivingSlipFiles[] | null;
}

export interface GetDrivingSlipFilesVariables {
  caseNo: string;
  drivingSlipId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetGlobalCatalogESDHFiles
// ====================================================

export interface GetGlobalCatalogESDHFiles_globalCatalogFiles_metadata {
  /**
   * KVP key
   */
  key: string;
  /**
   * KVP value
   */
  value: string;
}

export interface GetGlobalCatalogESDHFiles_globalCatalogFiles {
  /**
   * File name
   */
  name: string;
  /**
   * File url from ESDH
   */
  url: string;
  /**
   * File thumbnail URL
   */
  thumbnail: string;
  /**
   * File extension
   */
  extension: string;
  /**
   * File grouping key
   */
  groupingKey: string;
  /**
   * File created
   */
  created: GQL_DateTime;
  /**
   * File metadata from ESDH
   */
  metadata: GetGlobalCatalogESDHFiles_globalCatalogFiles_metadata[];
  /**
   * File comments from ESDH
   */
  comments: string | null;
  /**
   * File extension
   */
  fileId: string;
}

export interface GetGlobalCatalogESDHFiles {
  globalCatalogFiles: GetGlobalCatalogESDHFiles_globalCatalogFiles[];
}

export interface GetGlobalCatalogESDHFilesVariables {
  thisCustomerOnly?: boolean | null;
  offset?: number | null;
  limit?: number | null;
  searchString?: string | null;
  customerIds?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWebCaseFile
// ====================================================

export interface GetWebCaseFile_caseFile {
  /**
   * File data as byte array
   */
  fileData: GQL_Byte[];
  /**
   * File name
   */
  filename: string;
}

export interface GetWebCaseFile {
  caseFile: GetWebCaseFile_caseFile;
}

export interface GetWebCaseFileVariables {
  fileName: string;
  folder?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWebDrivingSlipFile
// ====================================================

export interface GetWebDrivingSlipFile_drivingSlipFile {
  /**
   * File data as byte array
   */
  fileData: GQL_Byte[];
  /**
   * File name
   */
  filename: string;
}

export interface GetWebDrivingSlipFile {
  drivingSlipFile: GetWebDrivingSlipFile_drivingSlipFile;
}

export interface GetWebDrivingSlipFileVariables {
  caseNo: string;
  drivingSlipId: string;
  fileName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LogCaseFileChange
// ====================================================

export interface LogCaseFileChange_logCaseFileChange {
  /**
   * This value is not used for anything.
   */
  _: string;
}

export interface LogCaseFileChange {
  logCaseFileChange: LogCaseFileChange_logCaseFileChange | null;
}

export interface LogCaseFileChangeVariables {
  case: string;
  action: FileActionType;
  filename: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LogCatalogFileChange
// ====================================================

export interface LogCatalogFileChange_logCatalogFileChange {
  /**
   * This value is not used for anything.
   */
  _: string;
}

export interface LogCatalogFileChange {
  logCatalogFileChange: LogCatalogFileChange_logCatalogFileChange | null;
}

export interface LogCatalogFileChangeVariables {
  catalogId?: string | null;
  customerId: string;
  changedBy: string;
  action: FileActionType;
  globalFile: boolean;
  filename: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LogDrivingSlipFileChange
// ====================================================

export interface LogDrivingSlipFileChange_logDrivingSlipFileChange {
  /**
   * This value is not used for anything.
   */
  _: string;
}

export interface LogDrivingSlipFileChange {
  logDrivingSlipFileChange: LogDrivingSlipFileChange_logDrivingSlipFileChange | null;
}

export interface LogDrivingSlipFileChangeVariables {
  series: string;
  action: FileActionType;
  filename: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UploadCaseFile
// ====================================================

export interface UploadCaseFile_uploadCaseFile_metadata {
  /**
   * KVP key
   */
  key: string;
  /**
   * KVP value
   */
  value: string;
}

export interface UploadCaseFile_uploadCaseFile {
  /**
   * File name
   */
  name: string;
  /**
   * File url from ESDH
   */
  url: string;
  /**
   * File thumbnail URL
   */
  thumbnail: string;
  /**
   * File extension
   */
  extension: string;
  /**
   * File grouping key
   */
  groupingKey: string;
  /**
   * File created
   */
  created: GQL_DateTime;
  /**
   * File metadata from ESDH
   */
  metadata: UploadCaseFile_uploadCaseFile_metadata[];
  /**
   * File comments from ESDH
   */
  comments: string | null;
  /**
   * File extension
   */
  fileId: string;
}

export interface UploadCaseFile {
  uploadCaseFile: UploadCaseFile_uploadCaseFile;
}

export interface UploadCaseFileVariables {
  fileName: string;
  folder: string;
  file: GQL_Byte[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UploadCaseFiles
// ====================================================

export interface UploadCaseFiles_uploadCaseFiles_metadata {
  /**
   * KVP key
   */
  key: string;
  /**
   * KVP value
   */
  value: string;
}

export interface UploadCaseFiles_uploadCaseFiles {
  /**
   * File name
   */
  name: string;
  /**
   * File url from ESDH
   */
  url: string;
  /**
   * File thumbnail URL
   */
  thumbnail: string;
  /**
   * File extension
   */
  extension: string;
  /**
   * File grouping key
   */
  groupingKey: string;
  /**
   * File created
   */
  created: GQL_DateTime;
  /**
   * File metadata from ESDH
   */
  metadata: UploadCaseFiles_uploadCaseFiles_metadata[];
  /**
   * File comments from ESDH
   */
  comments: string | null;
  /**
   * File extension
   */
  fileId: string;
}

export interface UploadCaseFiles {
  uploadCaseFiles: UploadCaseFiles_uploadCaseFiles[];
}

export interface UploadCaseFilesVariables {
  folder: string;
  files: FileInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UploadCatalogFile
// ====================================================

export interface UploadCatalogFile_uploadCatalogFile_metadata {
  /**
   * KVP key
   */
  key: string;
  /**
   * KVP value
   */
  value: string;
}

export interface UploadCatalogFile_uploadCatalogFile {
  /**
   * File name
   */
  name: string;
  /**
   * File url from ESDH
   */
  url: string;
  /**
   * File thumbnail URL
   */
  thumbnail: string;
  /**
   * File extension
   */
  extension: string;
  /**
   * File grouping key
   */
  groupingKey: string;
  /**
   * File created
   */
  created: GQL_DateTime;
  /**
   * File metadata from ESDH
   */
  metadata: UploadCatalogFile_uploadCatalogFile_metadata[];
  /**
   * File comments from ESDH
   */
  comments: string | null;
  /**
   * File extension
   */
  fileId: string;
}

export interface UploadCatalogFile {
  uploadCatalogFile: UploadCatalogFile_uploadCatalogFile;
}

export interface UploadCatalogFileVariables {
  fileName: string;
  folder?: string | null;
  customerId: string;
  file: GQL_Byte[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UploadCatalogFiles
// ====================================================

export interface UploadCatalogFiles_uploadCatalogFiles_metadata {
  /**
   * KVP key
   */
  key: string;
  /**
   * KVP value
   */
  value: string;
}

export interface UploadCatalogFiles_uploadCatalogFiles {
  /**
   * File name
   */
  name: string;
  /**
   * File url from ESDH
   */
  url: string;
  /**
   * File thumbnail URL
   */
  thumbnail: string;
  /**
   * File extension
   */
  extension: string;
  /**
   * File grouping key
   */
  groupingKey: string;
  /**
   * File created
   */
  created: GQL_DateTime;
  /**
   * File metadata from ESDH
   */
  metadata: UploadCatalogFiles_uploadCatalogFiles_metadata[];
  /**
   * File comments from ESDH
   */
  comments: string | null;
  /**
   * File extension
   */
  fileId: string;
}

export interface UploadCatalogFiles {
  uploadCatalogFiles: UploadCatalogFiles_uploadCatalogFiles[];
}

export interface UploadCatalogFilesVariables {
  folder?: string | null;
  customerId: string;
  files: FileInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UploadDrivingSlipFile
// ====================================================

export interface UploadDrivingSlipFile_uploadDrivingSlipFile_metadata {
  /**
   * KVP key
   */
  key: string;
  /**
   * KVP value
   */
  value: string;
}

export interface UploadDrivingSlipFile_uploadDrivingSlipFile {
  /**
   * File name
   */
  name: string;
  /**
   * File url from ESDH
   */
  url: string;
  /**
   * File thumbnail URL
   */
  thumbnail: string;
  /**
   * File extension
   */
  extension: string;
  /**
   * File grouping key
   */
  groupingKey: string;
  /**
   * File created
   */
  created: GQL_DateTime;
  /**
   * File metadata from ESDH
   */
  metadata: UploadDrivingSlipFile_uploadDrivingSlipFile_metadata[];
  /**
   * File comments from ESDH
   */
  comments: string | null;
  /**
   * File extension
   */
  fileId: string;
}

export interface UploadDrivingSlipFile {
  uploadDrivingSlipFile: UploadDrivingSlipFile_uploadDrivingSlipFile;
}

export interface UploadDrivingSlipFileVariables {
  caseNo: string;
  drivingSlipId: string;
  fileName: string;
  file: GQL_Byte[];
  metadata?: FileMetadataInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UploadDrivingSlipFiles
// ====================================================

export interface UploadDrivingSlipFiles_uploadDrivingSlipFiles_metadata {
  /**
   * KVP key
   */
  key: string;
  /**
   * KVP value
   */
  value: string;
}

export interface UploadDrivingSlipFiles_uploadDrivingSlipFiles {
  /**
   * File name
   */
  name: string;
  /**
   * File url from ESDH
   */
  url: string;
  /**
   * File thumbnail URL
   */
  thumbnail: string;
  /**
   * File extension
   */
  extension: string;
  /**
   * File grouping key
   */
  groupingKey: string;
  /**
   * File created
   */
  created: GQL_DateTime;
  /**
   * File metadata from ESDH
   */
  metadata: UploadDrivingSlipFiles_uploadDrivingSlipFiles_metadata[];
  /**
   * File comments from ESDH
   */
  comments: string | null;
  /**
   * File extension
   */
  fileId: string;
}

export interface UploadDrivingSlipFiles {
  uploadDrivingSlipFiles: UploadDrivingSlipFiles_uploadDrivingSlipFiles[];
}

export interface UploadDrivingSlipFilesVariables {
  caseNo: string;
  drivingSlipId: string;
  files: FileInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UploadInvoiceFiles
// ====================================================

export interface UploadInvoiceFiles_uploadInvoiceFiles_metadata {
  /**
   * KVP key
   */
  key: string;
  /**
   * KVP value
   */
  value: string;
}

export interface UploadInvoiceFiles_uploadInvoiceFiles {
  /**
   * File name
   */
  name: string;
  /**
   * File url from ESDH
   */
  url: string;
  /**
   * File thumbnail URL
   */
  thumbnail: string;
  /**
   * File extension
   */
  extension: string;
  /**
   * File grouping key
   */
  groupingKey: string;
  /**
   * File created
   */
  created: GQL_DateTime;
  /**
   * File metadata from ESDH
   */
  metadata: UploadInvoiceFiles_uploadInvoiceFiles_metadata[];
  /**
   * File comments from ESDH
   */
  comments: string | null;
  /**
   * File extension
   */
  fileId: string;
}

export interface UploadInvoiceFiles {
  uploadInvoiceFiles: UploadInvoiceFiles_uploadInvoiceFiles[];
}

export interface UploadInvoiceFilesVariables {
  caseNo: string;
  invoiceNo: string;
  files: FileInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateGDPRAccept
// ====================================================

export interface CreateGDPRAccept_createGDPRAccept_userId {
  /**
   * Unique id of user
   */
  id: string;
}

export interface CreateGDPRAccept_createGDPRAccept {
  /**
   * Unique id of gdpr accept
   */
  id: string;
  /**
   * Timestamp of acceptance
   */
  timestamp: GQL_DateTime;
  /**
   * User who accepted GDPR
   */
  userId: CreateGDPRAccept_createGDPRAccept_userId;
}

export interface CreateGDPRAccept {
  createGDPRAccept: CreateGDPRAccept_createGDPRAccept;
}

export interface CreateGDPRAcceptVariables {
  gdpr: GDPRInputType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteGDPRAccept
// ====================================================

export interface DeleteGDPRAccept_deleteGDPRAccept {
  /**
   * This value is not used for anything.
   */
  _: string;
}

export interface DeleteGDPRAccept {
  deleteGDPRAccept: DeleteGDPRAccept_deleteGDPRAccept | null;
}

export interface DeleteGDPRAcceptVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetGDPRAccept
// ====================================================

export interface GetGDPRAccept_gdprAccept_userId {
  /**
   * Unique id of user
   */
  id: string;
}

export interface GetGDPRAccept_gdprAccept {
  /**
   * Unique id of gdpr accept
   */
  id: string;
  /**
   * Timestamp of acceptance
   */
  timestamp: GQL_DateTime;
  /**
   * User who accepted GDPR
   */
  userId: GetGDPRAccept_gdprAccept_userId;
}

export interface GetGDPRAccept {
  gdprAccept: GetGDPRAccept_gdprAccept | null;
}

export interface GetGDPRAcceptVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateGDPRAccept
// ====================================================

export interface UpdateGDPRAccept_updateGDPRAccept_userId {
  /**
   * Unique id of user
   */
  id: string;
}

export interface UpdateGDPRAccept_updateGDPRAccept {
  /**
   * Unique id of gdpr accept
   */
  id: string;
  /**
   * Timestamp of acceptance
   */
  timestamp: GQL_DateTime;
  /**
   * User who accepted GDPR
   */
  userId: UpdateGDPRAccept_updateGDPRAccept_userId;
}

export interface UpdateGDPRAccept {
  updateGDPRAccept: UpdateGDPRAccept_updateGDPRAccept;
}

export interface UpdateGDPRAcceptVariables {
  id: string;
  timestamp: GQL_DateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteInputField
// ====================================================

export interface DeleteInputField_deleteInputField {
  /**
   * This value is not used for anything.
   */
  _: string;
}

export interface DeleteInputField {
  deleteInputField: DeleteInputField_deleteInputField | null;
}

export interface DeleteInputFieldVariables {
  key: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateWebInputField
// ====================================================

export interface UpdateWebInputField_updateInputField {
  /**
   * Key of input field
   */
  key: string;
  /**
   * Input field permissions
   */
  permissions: Permissions[];
}

export interface UpdateWebInputField {
  updateInputField: UpdateWebInputField_updateInputField | null;
}

export interface UpdateWebInputFieldVariables {
  key: string;
  permissionsList: Permissions[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ApproveInvoice
// ====================================================

export interface ApproveInvoice_approveInvoice_validationRuleBypasses {
  /**
   * Rule that was bypassed
   */
  rule: string;
  /**
   * Reason code id
   */
  reasonCode: string;
  /**
   * User comment
   */
  comment: string | null;
}

export interface ApproveInvoice_approveInvoice_lines {
  /**
   * System Id
   */
  systemId: string;
  /**
   * Sort index
   */
  sortIndex: number;
  /**
   * Case number
   */
  caseNo: string;
  /**
   * Document number (planningline reference)
   */
  documentNo: string;
  /**
   * Unique identifier
   */
  no: string;
  /**
   * ERP Reference Task
   */
  erpReferenceTask: string;
  /**
   * Line amount
   */
  lineAmount: GQL_Decimal | null;
  /**
   * Line Type
   */
  lineType: string;
  /**
   * Type
   */
  type: string;
  /**
   * Tells ERP system to attach document for subcontractor lines
   */
  attachDocument: boolean | null;
  /**
   * If allow attach document checkbox should be shown
   */
  allowAttachDocument: boolean;
  /**
   * Description
   */
  description: string;
  /**
   * Quantity
   */
  quantity: GQL_Decimal | null;
  /**
   * Quote Line Id
   */
  quoteLineId: string;
  /**
   * Name of screening this line belongs to
   */
  screeningTitle: string;
  /**
   * Screening line
   */
  screeningLine: boolean;
  /**
   * Screening Line Id
   */
  screeningLineId: string;
  /**
   * Screening Line position no
   */
  screeningLinePosition: string | null;
  /**
   * Price of unit
   */
  unitPrice: GQL_Decimal | null;
  /**
   * Cost of unit
   */
  unitCost: GQL_Decimal | null;
  /**
   * New price of unit
   */
  newUnitPrice: GQL_Decimal | null;
  /**
   * Planning date
   */
  planningDate: GQL_Date;
  /**
   * Work type code
   */
  workTypeCode: string;
  /**
   * Created by
   */
  createdBy: string;
}

export interface ApproveInvoice_approveInvoice {
  /**
   * Reference no invoice
   */
  erpReferenceNo: string;
  /**
   * Category of invoice
   */
  category: InvoiceCategory;
  /**
   * Invoice status
   */
  status: InvoiceStatus;
  /**
   * Date the invoice is posted
   */
  invoicePostingDate: GQL_Date;
  /**
   * Start date of the invoicing period
   */
  invoiceStartDate: GQL_Date;
  /**
   * End date of the invoicing period
   */
  invoiceEndDate: GQL_Date;
  /**
   * Invoice validation rule bypasses
   */
  validationRuleBypasses: ApproveInvoice_approveInvoice_validationRuleBypasses[];
  /**
   * Invoice lines
   */
  lines: ApproveInvoice_approveInvoice_lines[];
  /**
   * Determines whether draft lines have been suggested for this invoice
   */
  hasSuggestedLines: boolean;
  /**
   * Description to put on the invoice
   */
  invoiceDescription: string;
  /**
   * Rejection reason from manager
   */
  rejectionReason: string | null;
}

export interface ApproveInvoice {
  approveInvoice: ApproveInvoice_approveInvoice;
}

export interface ApproveInvoiceVariables {
  caseId: string;
  invoiceERPReference: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateInvoice
// ====================================================

export interface CreateInvoice_createInvoice_validationRuleBypasses {
  /**
   * Rule that was bypassed
   */
  rule: string;
  /**
   * Reason code id
   */
  reasonCode: string;
  /**
   * User comment
   */
  comment: string | null;
}

export interface CreateInvoice_createInvoice_lines {
  /**
   * System Id
   */
  systemId: string;
  /**
   * Sort index
   */
  sortIndex: number;
  /**
   * Case number
   */
  caseNo: string;
  /**
   * Document number (planningline reference)
   */
  documentNo: string;
  /**
   * Unique identifier
   */
  no: string;
  /**
   * ERP Reference Task
   */
  erpReferenceTask: string;
  /**
   * Line amount
   */
  lineAmount: GQL_Decimal | null;
  /**
   * Line Type
   */
  lineType: string;
  /**
   * Type
   */
  type: string;
  /**
   * Tells ERP system to attach document for subcontractor lines
   */
  attachDocument: boolean | null;
  /**
   * If allow attach document checkbox should be shown
   */
  allowAttachDocument: boolean;
  /**
   * Description
   */
  description: string;
  /**
   * Quantity
   */
  quantity: GQL_Decimal | null;
  /**
   * Quote Line Id
   */
  quoteLineId: string;
  /**
   * Name of screening this line belongs to
   */
  screeningTitle: string;
  /**
   * Screening line
   */
  screeningLine: boolean;
  /**
   * Screening Line Id
   */
  screeningLineId: string;
  /**
   * Screening Line position no
   */
  screeningLinePosition: string | null;
  /**
   * Price of unit
   */
  unitPrice: GQL_Decimal | null;
  /**
   * Cost of unit
   */
  unitCost: GQL_Decimal | null;
  /**
   * New price of unit
   */
  newUnitPrice: GQL_Decimal | null;
  /**
   * Planning date
   */
  planningDate: GQL_Date;
  /**
   * Work type code
   */
  workTypeCode: string;
  /**
   * Created by
   */
  createdBy: string;
}

export interface CreateInvoice_createInvoice {
  /**
   * Reference no invoice
   */
  erpReferenceNo: string;
  /**
   * Category of invoice
   */
  category: InvoiceCategory;
  /**
   * Invoice status
   */
  status: InvoiceStatus;
  /**
   * Date the invoice is posted
   */
  invoicePostingDate: GQL_Date;
  /**
   * Start date of the invoicing period
   */
  invoiceStartDate: GQL_Date;
  /**
   * End date of the invoicing period
   */
  invoiceEndDate: GQL_Date;
  /**
   * Invoice validation rule bypasses
   */
  validationRuleBypasses: CreateInvoice_createInvoice_validationRuleBypasses[];
  /**
   * Invoice lines
   */
  lines: CreateInvoice_createInvoice_lines[];
  /**
   * Determines whether draft lines have been suggested for this invoice
   */
  hasSuggestedLines: boolean;
  /**
   * Description to put on the invoice
   */
  invoiceDescription: string;
  /**
   * Rejection reason from manager
   */
  rejectionReason: string | null;
}

export interface CreateInvoice {
  createInvoice: CreateInvoice_createInvoice;
}

export interface CreateInvoiceVariables {
  caseId: string;
  category: InvoiceCategory;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteInvoice
// ====================================================

export interface DeleteInvoice_deleteInvoice {
  /**
   * This value is not used for anything.
   */
  _: string;
}

export interface DeleteInvoice {
  deleteInvoice: DeleteInvoice_deleteInvoice | null;
}

export interface DeleteInvoiceVariables {
  caseId: string;
  invoiceERPReference: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllAssortments
// ====================================================

export interface AllAssortments_allAssortments {
  /**
   * item no
   */
  no: string;
  /**
   * Type of assortment
   */
  type: string;
  /**
   * description
   */
  description: string;
  /**
   * Base unit of messure
   */
  uom: string;
}

export interface AllAssortments {
  allAssortments: AllAssortments_allAssortments[] | null;
}

export interface AllAssortmentsVariables {
  assortmentCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDraftInvoiceLineTypes
// ====================================================

export interface GetDraftInvoiceLineTypes_draftInvoiceLineTypes {
  /**
   * Unique id of the type
   */
  id: number;
  /**
   * Name of the type
   */
  name: string;
}

export interface GetDraftInvoiceLineTypes {
  draftInvoiceLineTypes: GetDraftInvoiceLineTypes_draftInvoiceLineTypes[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetInvoiceApproval
// ====================================================

export interface GetInvoiceApproval_case_projectManager {
  /**
   * Name of user
   */
  name: string;
}

export interface GetInvoiceApproval_case_debitor {
  /**
   * Company identifier
   */
  company: string;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
}

export interface GetInvoiceApproval_case_ssgDepartment {
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface GetInvoiceApproval_case_invoices_createdBy {
  /**
   * Name of user
   */
  name: string;
}

export interface GetInvoiceApproval_case_invoices_validationRuleBypasses {
  /**
   * Rule that was bypassed
   */
  rule: string;
  /**
   * Reason code id
   */
  reasonCode: string;
  /**
   * User comment
   */
  comment: string | null;
}

export interface GetInvoiceApproval_case_invoices_lines {
  /**
   * System Id
   */
  systemId: string;
  /**
   * Sort index
   */
  sortIndex: number;
  /**
   * Case number
   */
  caseNo: string;
  /**
   * Document number (planningline reference)
   */
  documentNo: string;
  /**
   * Unique identifier
   */
  no: string;
  /**
   * ERP Reference Task
   */
  erpReferenceTask: string;
  /**
   * Line amount
   */
  lineAmount: GQL_Decimal | null;
  /**
   * Line Type
   */
  lineType: string;
  /**
   * Type
   */
  type: string;
  /**
   * Tells ERP system to attach document for subcontractor lines
   */
  attachDocument: boolean | null;
  /**
   * If allow attach document checkbox should be shown
   */
  allowAttachDocument: boolean;
  /**
   * Description
   */
  description: string;
  /**
   * Quantity
   */
  quantity: GQL_Decimal | null;
  /**
   * Quote Line Id
   */
  quoteLineId: string;
  /**
   * Name of screening this line belongs to
   */
  screeningTitle: string;
  /**
   * Screening line
   */
  screeningLine: boolean;
  /**
   * Screening Line Id
   */
  screeningLineId: string;
  /**
   * Screening Line position no
   */
  screeningLinePosition: string | null;
  /**
   * Price of unit
   */
  unitPrice: GQL_Decimal | null;
  /**
   * Cost of unit
   */
  unitCost: GQL_Decimal | null;
  /**
   * New price of unit
   */
  newUnitPrice: GQL_Decimal | null;
  /**
   * Planning date
   */
  planningDate: GQL_Date;
  /**
   * Work type code
   */
  workTypeCode: string;
  /**
   * Created by
   */
  createdBy: string;
}

export interface GetInvoiceApproval_case_invoices {
  /**
   * User who created the invoice draft
   */
  createdBy: GetInvoiceApproval_case_invoices_createdBy | null;
  /**
   * Reference no invoice
   */
  erpReferenceNo: string;
  /**
   * Category of invoice
   */
  category: InvoiceCategory;
  /**
   * Invoice status
   */
  status: InvoiceStatus;
  /**
   * Date the invoice is posted
   */
  invoicePostingDate: GQL_Date;
  /**
   * Start date of the invoicing period
   */
  invoiceStartDate: GQL_Date;
  /**
   * End date of the invoicing period
   */
  invoiceEndDate: GQL_Date;
  /**
   * Invoice validation rule bypasses
   */
  validationRuleBypasses: GetInvoiceApproval_case_invoices_validationRuleBypasses[];
  /**
   * Invoice lines
   */
  lines: GetInvoiceApproval_case_invoices_lines[];
  /**
   * Determines whether draft lines have been suggested for this invoice
   */
  hasSuggestedLines: boolean;
  /**
   * Description to put on the invoice
   */
  invoiceDescription: string;
  /**
   * Rejection reason from manager
   */
  rejectionReason: string | null;
}

export interface GetInvoiceApproval_case_screenings_lines {
  /**
   * Identifier used in ERP system
   */
  erpReferenceId: string;
  /**
   * Screening line unit of measure
   */
  uom: string;
}

export interface GetInvoiceApproval_case_screenings {
  /**
   * Screening template lines
   */
  lines: GetInvoiceApproval_case_screenings_lines[];
}

export interface GetInvoiceApproval_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Project manager attached to case
   */
  projectManager: GetInvoiceApproval_case_projectManager | null;
  /**
   * Case debitor details
   */
  debitor: GetInvoiceApproval_case_debitor;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: GetInvoiceApproval_case_ssgDepartment;
  /**
   * List of invoices related to case (always empty if user lacks permissions)
   */
  invoices: GetInvoiceApproval_case_invoices[];
  /**
   * The screening of the case
   */
  screenings: GetInvoiceApproval_case_screenings[];
}

export interface GetInvoiceApproval_invoiceReasonCodes {
  /**
   * Id of invoice reason code
   */
  id: string;
  /**
   * Name of invoice reason code
   */
  name: string;
}

export interface GetInvoiceApproval {
  case: GetInvoiceApproval_case;
  invoiceReasonCodes: GetInvoiceApproval_invoiceReasonCodes[];
}

export interface GetInvoiceApprovalVariables {
  caseId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetInvoiceAttachments
// ====================================================

export interface GetInvoiceAttachments_invoice_attachments {
  /**
   * File name
   */
  name: string;
  /**
   * File url from ESDH
   */
  url: string;
}

export interface GetInvoiceAttachments_invoice {
  /**
   * Invoice attachments
   */
  attachments: GetInvoiceAttachments_invoice_attachments[];
}

export interface GetInvoiceAttachments {
  invoice: GetInvoiceAttachments_invoice;
}

export interface GetInvoiceAttachmentsVariables {
  caseId: string;
  invoiceNo: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetInvoiceHeader
// ====================================================

export interface GetInvoiceHeader_invoiceHeaders {
  /**
   * Unique id of invoice
   */
  no: string | null;
  /**
   * Order number
   */
  orderNo: string;
  /**
   * Sales person of invoice
   */
  salesPerson: string | null;
  /**
   * identifier of custoner
   */
  customerNo: string | null;
  /**
   * Payment terms
   */
  paymentTerms: string | null;
  /**
   * Unique id of affilitated case
   */
  jobNo: string | null;
}

export interface GetInvoiceHeader {
  invoiceHeaders: GetInvoiceHeader_invoiceHeaders[];
}

export interface GetInvoiceHeaderVariables {
  caseERPReferenceNo: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetInvoicePredictions
// ====================================================

export interface GetInvoicePredictions_invoicePredictions_lineSuggestions {
  /**
   * System Id
   */
  systemId: string;
  /**
   * Unique identifier
   */
  no: string;
  /**
   * ERP Reference Task
   */
  erpReferenceTask: string;
  /**
   * Line amount
   */
  lineAmount: GQL_Decimal | null;
  /**
   * Line Type
   */
  lineType: string;
  /**
   * Type
   */
  type: string;
  /**
   * Tells ERP system to attach document for subcontractor lines
   */
  attachDocument: boolean | null;
  /**
   * If allow attach document checkbox should be shown
   */
  allowAttachDocument: boolean;
  /**
   * Description
   */
  description: string;
  /**
   * Quote Line Id
   */
  quoteLineId: string;
  /**
   * Name of screening this line belongs to
   */
  screeningTitle: string;
  /**
   * Screening line
   */
  screeningLine: boolean;
  /**
   * Screening Line Id
   */
  screeningLineId: string;
  /**
   * Screening Line position no
   */
  screeningLinePosition: string | null;
  /**
   * Quantity
   */
  quantity: GQL_Decimal | null;
  /**
   * Price of unit
   */
  unitPrice: GQL_Decimal | null;
  /**
   * Cost of unit
   */
  unitCost: GQL_Decimal | null;
  /**
   * New price of unit
   */
  newUnitPrice: GQL_Decimal | null;
  /**
   * Work type code
   */
  workTypeCode: string;
  /**
   * Screening line help text
   */
  screeningLinePositionText: string;
}

export interface GetInvoicePredictions_invoicePredictions {
  /**
   * Job Task Descriptions
   */
  jobTaskDescriptions: string[];
  /**
   * Job Task Descriptions
   */
  lineSuggestions: GetInvoicePredictions_invoicePredictions_lineSuggestions[];
}

export interface GetInvoicePredictions {
  invoicePredictions: GetInvoicePredictions_invoicePredictions;
}

export interface GetInvoicePredictionsVariables {
  invoicePredictionInput: AIInvoicePredictionInputType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetInvoiceReasonCodes
// ====================================================

export interface GetInvoiceReasonCodes_invoiceReasonCodes {
  /**
   * Id of invoice reason code
   */
  id: string;
  /**
   * Name of invoice reason code
   */
  name: string;
}

export interface GetInvoiceReasonCodes {
  invoiceReasonCodes: GetInvoiceReasonCodes_invoiceReasonCodes[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InvoiceResources
// ====================================================

export interface InvoiceResources_invoiceResources {
  /**
   * Resource No
   */
  no: string;
  /**
   * Resource Name
   */
  name: string;
  /**
   * Resource type
   */
  type: string;
  /**
   * baseUOM e.g Time 
   */
  baseUOM: string;
}

export interface InvoiceResources {
  invoiceResources: InvoiceResources_invoiceResources[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetInvoices
// ====================================================

export interface GetInvoices_invoices_validationRuleBypasses {
  /**
   * Rule that was bypassed
   */
  rule: string;
  /**
   * Reason code id
   */
  reasonCode: string;
  /**
   * User comment
   */
  comment: string | null;
}

export interface GetInvoices_invoices_lines {
  /**
   * System Id
   */
  systemId: string;
  /**
   * Sort index
   */
  sortIndex: number;
  /**
   * Case number
   */
  caseNo: string;
  /**
   * Document number (planningline reference)
   */
  documentNo: string;
  /**
   * Unique identifier
   */
  no: string;
  /**
   * ERP Reference Task
   */
  erpReferenceTask: string;
  /**
   * Line amount
   */
  lineAmount: GQL_Decimal | null;
  /**
   * Line Type
   */
  lineType: string;
  /**
   * Type
   */
  type: string;
  /**
   * Tells ERP system to attach document for subcontractor lines
   */
  attachDocument: boolean | null;
  /**
   * If allow attach document checkbox should be shown
   */
  allowAttachDocument: boolean;
  /**
   * Description
   */
  description: string;
  /**
   * Quantity
   */
  quantity: GQL_Decimal | null;
  /**
   * Quote Line Id
   */
  quoteLineId: string;
  /**
   * Name of screening this line belongs to
   */
  screeningTitle: string;
  /**
   * Screening line
   */
  screeningLine: boolean;
  /**
   * Screening Line Id
   */
  screeningLineId: string;
  /**
   * Screening Line position no
   */
  screeningLinePosition: string | null;
  /**
   * Price of unit
   */
  unitPrice: GQL_Decimal | null;
  /**
   * Cost of unit
   */
  unitCost: GQL_Decimal | null;
  /**
   * New price of unit
   */
  newUnitPrice: GQL_Decimal | null;
  /**
   * Planning date
   */
  planningDate: GQL_Date;
  /**
   * Work type code
   */
  workTypeCode: string;
  /**
   * Created by
   */
  createdBy: string;
}

export interface GetInvoices_invoices {
  /**
   * Reference no invoice
   */
  erpReferenceNo: string;
  /**
   * Category of invoice
   */
  category: InvoiceCategory;
  /**
   * Invoice status
   */
  status: InvoiceStatus;
  /**
   * Date the invoice is posted
   */
  invoicePostingDate: GQL_Date;
  /**
   * Start date of the invoicing period
   */
  invoiceStartDate: GQL_Date;
  /**
   * End date of the invoicing period
   */
  invoiceEndDate: GQL_Date;
  /**
   * Invoice validation rule bypasses
   */
  validationRuleBypasses: GetInvoices_invoices_validationRuleBypasses[];
  /**
   * Invoice lines
   */
  lines: GetInvoices_invoices_lines[];
  /**
   * Determines whether draft lines have been suggested for this invoice
   */
  hasSuggestedLines: boolean;
  /**
   * Description to put on the invoice
   */
  invoiceDescription: string;
  /**
   * Rejection reason from manager
   */
  rejectionReason: string | null;
}

export interface GetInvoices {
  invoices: GetInvoices_invoices[];
}

export interface GetInvoicesVariables {
  caseId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetInvoicesForUsersApproval
// ====================================================

export interface GetInvoicesForUsersApproval_invoicesForUsersApproval_case_debitor {
  /**
   * Company identifier
   */
  company: string;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
}

export interface GetInvoicesForUsersApproval_invoicesForUsersApproval_case_projectManager {
  /**
   * Name of user
   */
  name: string;
}

export interface GetInvoicesForUsersApproval_invoicesForUsersApproval_case_ssgDepartment {
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface GetInvoicesForUsersApproval_invoicesForUsersApproval_case_caseEconomics {
  /**
   * costTotal
   */
  costTotal: GQL_Decimal;
}

export interface GetInvoicesForUsersApproval_invoicesForUsersApproval_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetInvoicesForUsersApproval_invoicesForUsersApproval_case_debitor;
  /**
   * Project manager attached to case
   */
  projectManager: GetInvoicesForUsersApproval_invoicesForUsersApproval_case_projectManager | null;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: GetInvoicesForUsersApproval_invoicesForUsersApproval_case_ssgDepartment;
  caseEconomics: GetInvoicesForUsersApproval_invoicesForUsersApproval_case_caseEconomics | null;
}

export interface GetInvoicesForUsersApproval_invoicesForUsersApproval_invoiceToApprove_lines {
  /**
   * Quantity
   */
  quantity: GQL_Decimal | null;
  /**
   * Price of unit
   */
  unitPrice: GQL_Decimal | null;
  /**
   * Cost of unit
   */
  unitCost: GQL_Decimal | null;
  /**
   * Unique identifier
   */
  no: string;
  /**
   * New price of unit
   */
  newUnitPrice: GQL_Decimal | null;
}

export interface GetInvoicesForUsersApproval_invoicesForUsersApproval_invoiceToApprove_createdBy {
  /**
   * Name of user
   */
  name: string;
}

export interface GetInvoicesForUsersApproval_invoicesForUsersApproval_invoiceToApprove {
  /**
   * Reference no invoice
   */
  erpReferenceNo: string;
  /**
   * Category of invoice
   */
  category: InvoiceCategory;
  /**
   * Invoice lines
   */
  lines: GetInvoicesForUsersApproval_invoicesForUsersApproval_invoiceToApprove_lines[];
  /**
   * User who created the invoice draft
   */
  createdBy: GetInvoicesForUsersApproval_invoicesForUsersApproval_invoiceToApprove_createdBy | null;
}

export interface GetInvoicesForUsersApproval_invoicesForUsersApproval {
  /**
   * Case object
   */
  case: GetInvoicesForUsersApproval_invoicesForUsersApproval_case;
  /**
   * Invoice that should be approved or rejected by manager
   */
  invoiceToApprove: GetInvoicesForUsersApproval_invoicesForUsersApproval_invoiceToApprove;
}

export interface GetInvoicesForUsersApproval {
  invoicesForUsersApproval: GetInvoicesForUsersApproval_invoicesForUsersApproval[];
}

export interface GetInvoicesForUsersApprovalVariables {
  department?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSalesInvoiceLines
// ====================================================

export interface GetSalesInvoiceLines_salesInvoiceLines {
  /**
   * System Id
   */
  systemId: string;
  /**
   * Document number (planningline reference)
   */
  documentNo: string | null;
  /**
   * Line identifier
   */
  lineNo: number | null;
  /**
   * Unique identifier
   */
  no: string | null;
  /**
   * line type
   */
  type: string | null;
  /**
   * Description
   */
  description: string | null;
  /**
   * Quantity
   */
  quantity: GQL_Decimal | null;
  /**
   * Unit of measure
   */
  uom: string | null;
  /**
   * Price of unit
   */
  unitPrice: GQL_Decimal | null;
  /**
   * Line discount amount
   */
  lineDiscountAmount: GQL_Decimal | null;
  /**
   * Line discount percentage
   */
  lineDiscountPercentage: GQL_Decimal | null;
  /**
   * Amount including vat Tax
   */
  amountIncludingVAT: GQL_Decimal | null;
  /**
   * Vat tax base amount
   */
  vatBaseAmount: GQL_Decimal | null;
  /**
   * Vat tax percentage
   */
  vatPercentage: GQL_Decimal | null;
}

export interface GetSalesInvoiceLines {
  salesInvoiceLines: GetSalesInvoiceLines_salesInvoiceLines[];
}

export interface GetSalesInvoiceLinesVariables {
  offerERPReferenceNo: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GetSamplePriceForInvoiceLine
// ====================================================

export interface GetSamplePriceForInvoiceLine_samplePriceForInvoiceLine {
  /**
   * Total cost
   */
  totalCost: GQL_Decimal;
  /**
   * Total line amount
   */
  lineAmount: GQL_Decimal;
}

export interface GetSamplePriceForInvoiceLine {
  samplePriceForInvoiceLine: GetSamplePriceForInvoiceLine_samplePriceForInvoiceLine;
}

export interface GetSamplePriceForInvoiceLineVariables {
  invoiceLine: DraftInvoiceLineInputType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: OpenInvoiceForEditing
// ====================================================

export interface OpenInvoiceForEditing_openInvoiceForEditing {
  /**
   * Reference no invoice
   */
  erpReferenceNo: string;
}

export interface OpenInvoiceForEditing {
  /**
   * Open an invoice for editing by setting the status to Draft
   */
  openInvoiceForEditing: OpenInvoiceForEditing_openInvoiceForEditing;
}

export interface OpenInvoiceForEditingVariables {
  caseId: string;
  invoiceERPReference: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: OverrideInvoiceDates
// ====================================================

export interface OverrideInvoiceDates_overrideInvoiceDates {
  /**
   * This value is not used for anything.
   */
  _: string;
}

export interface OverrideInvoiceDates {
  overrideInvoiceDates: OverrideInvoiceDates_overrideInvoiceDates | null;
}

export interface OverrideInvoiceDatesVariables {
  caseId: string;
  invoiceERPReference: string;
  documentDate: GQL_Date;
  fromDate: GQL_Date;
  toDate: GQL_Date;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RejectInvoice
// ====================================================

export interface RejectInvoice_rejectInvoice_validationRuleBypasses {
  /**
   * Rule that was bypassed
   */
  rule: string;
  /**
   * Reason code id
   */
  reasonCode: string;
  /**
   * User comment
   */
  comment: string | null;
}

export interface RejectInvoice_rejectInvoice_lines {
  /**
   * System Id
   */
  systemId: string;
  /**
   * Sort index
   */
  sortIndex: number;
  /**
   * Case number
   */
  caseNo: string;
  /**
   * Document number (planningline reference)
   */
  documentNo: string;
  /**
   * Unique identifier
   */
  no: string;
  /**
   * ERP Reference Task
   */
  erpReferenceTask: string;
  /**
   * Line amount
   */
  lineAmount: GQL_Decimal | null;
  /**
   * Line Type
   */
  lineType: string;
  /**
   * Type
   */
  type: string;
  /**
   * Tells ERP system to attach document for subcontractor lines
   */
  attachDocument: boolean | null;
  /**
   * If allow attach document checkbox should be shown
   */
  allowAttachDocument: boolean;
  /**
   * Description
   */
  description: string;
  /**
   * Quantity
   */
  quantity: GQL_Decimal | null;
  /**
   * Quote Line Id
   */
  quoteLineId: string;
  /**
   * Name of screening this line belongs to
   */
  screeningTitle: string;
  /**
   * Screening line
   */
  screeningLine: boolean;
  /**
   * Screening Line Id
   */
  screeningLineId: string;
  /**
   * Screening Line position no
   */
  screeningLinePosition: string | null;
  /**
   * Price of unit
   */
  unitPrice: GQL_Decimal | null;
  /**
   * Cost of unit
   */
  unitCost: GQL_Decimal | null;
  /**
   * New price of unit
   */
  newUnitPrice: GQL_Decimal | null;
  /**
   * Planning date
   */
  planningDate: GQL_Date;
  /**
   * Work type code
   */
  workTypeCode: string;
  /**
   * Created by
   */
  createdBy: string;
}

export interface RejectInvoice_rejectInvoice {
  /**
   * Reference no invoice
   */
  erpReferenceNo: string;
  /**
   * Category of invoice
   */
  category: InvoiceCategory;
  /**
   * Invoice status
   */
  status: InvoiceStatus;
  /**
   * Date the invoice is posted
   */
  invoicePostingDate: GQL_Date;
  /**
   * Start date of the invoicing period
   */
  invoiceStartDate: GQL_Date;
  /**
   * End date of the invoicing period
   */
  invoiceEndDate: GQL_Date;
  /**
   * Invoice validation rule bypasses
   */
  validationRuleBypasses: RejectInvoice_rejectInvoice_validationRuleBypasses[];
  /**
   * Invoice lines
   */
  lines: RejectInvoice_rejectInvoice_lines[];
  /**
   * Determines whether draft lines have been suggested for this invoice
   */
  hasSuggestedLines: boolean;
  /**
   * Description to put on the invoice
   */
  invoiceDescription: string;
  /**
   * Rejection reason from manager
   */
  rejectionReason: string | null;
}

export interface RejectInvoice {
  rejectInvoice: RejectInvoice_rejectInvoice;
}

export interface RejectInvoiceVariables {
  caseId: string;
  invoiceERPReference: string;
  rejectionReason?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RequestInvoiceApproval
// ====================================================

export interface RequestInvoiceApproval_requestInvoiceApproval_validationRuleBypasses {
  /**
   * Rule that was bypassed
   */
  rule: string;
  /**
   * Reason code id
   */
  reasonCode: string;
  /**
   * User comment
   */
  comment: string | null;
}

export interface RequestInvoiceApproval_requestInvoiceApproval_lines {
  /**
   * System Id
   */
  systemId: string;
  /**
   * Sort index
   */
  sortIndex: number;
  /**
   * Case number
   */
  caseNo: string;
  /**
   * Document number (planningline reference)
   */
  documentNo: string;
  /**
   * Unique identifier
   */
  no: string;
  /**
   * ERP Reference Task
   */
  erpReferenceTask: string;
  /**
   * Line amount
   */
  lineAmount: GQL_Decimal | null;
  /**
   * Line Type
   */
  lineType: string;
  /**
   * Type
   */
  type: string;
  /**
   * Tells ERP system to attach document for subcontractor lines
   */
  attachDocument: boolean | null;
  /**
   * If allow attach document checkbox should be shown
   */
  allowAttachDocument: boolean;
  /**
   * Description
   */
  description: string;
  /**
   * Quantity
   */
  quantity: GQL_Decimal | null;
  /**
   * Quote Line Id
   */
  quoteLineId: string;
  /**
   * Name of screening this line belongs to
   */
  screeningTitle: string;
  /**
   * Screening line
   */
  screeningLine: boolean;
  /**
   * Screening Line Id
   */
  screeningLineId: string;
  /**
   * Screening Line position no
   */
  screeningLinePosition: string | null;
  /**
   * Price of unit
   */
  unitPrice: GQL_Decimal | null;
  /**
   * Cost of unit
   */
  unitCost: GQL_Decimal | null;
  /**
   * New price of unit
   */
  newUnitPrice: GQL_Decimal | null;
  /**
   * Planning date
   */
  planningDate: GQL_Date;
  /**
   * Work type code
   */
  workTypeCode: string;
  /**
   * Created by
   */
  createdBy: string;
}

export interface RequestInvoiceApproval_requestInvoiceApproval {
  /**
   * Reference no invoice
   */
  erpReferenceNo: string;
  /**
   * Category of invoice
   */
  category: InvoiceCategory;
  /**
   * Invoice status
   */
  status: InvoiceStatus;
  /**
   * Date the invoice is posted
   */
  invoicePostingDate: GQL_Date;
  /**
   * Start date of the invoicing period
   */
  invoiceStartDate: GQL_Date;
  /**
   * End date of the invoicing period
   */
  invoiceEndDate: GQL_Date;
  /**
   * Invoice validation rule bypasses
   */
  validationRuleBypasses: RequestInvoiceApproval_requestInvoiceApproval_validationRuleBypasses[];
  /**
   * Invoice lines
   */
  lines: RequestInvoiceApproval_requestInvoiceApproval_lines[];
  /**
   * Determines whether draft lines have been suggested for this invoice
   */
  hasSuggestedLines: boolean;
  /**
   * Description to put on the invoice
   */
  invoiceDescription: string;
  /**
   * Rejection reason from manager
   */
  rejectionReason: string | null;
}

export interface RequestInvoiceApproval {
  requestInvoiceApproval: RequestInvoiceApproval_requestInvoiceApproval;
}

export interface RequestInvoiceApprovalVariables {
  caseId: string;
  invoiceERPReference: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetInvoiceReasonCodes
// ====================================================

export interface SetInvoiceReasonCodes_setInvoiceReasonCodes_validationRuleBypasses {
  /**
   * Rule that was bypassed
   */
  rule: string;
  /**
   * Reason code id
   */
  reasonCode: string;
  /**
   * User comment
   */
  comment: string | null;
}

export interface SetInvoiceReasonCodes_setInvoiceReasonCodes_lines {
  /**
   * System Id
   */
  systemId: string;
  /**
   * Sort index
   */
  sortIndex: number;
  /**
   * Case number
   */
  caseNo: string;
  /**
   * Document number (planningline reference)
   */
  documentNo: string;
  /**
   * Unique identifier
   */
  no: string;
  /**
   * ERP Reference Task
   */
  erpReferenceTask: string;
  /**
   * Line amount
   */
  lineAmount: GQL_Decimal | null;
  /**
   * Line Type
   */
  lineType: string;
  /**
   * Type
   */
  type: string;
  /**
   * Tells ERP system to attach document for subcontractor lines
   */
  attachDocument: boolean | null;
  /**
   * If allow attach document checkbox should be shown
   */
  allowAttachDocument: boolean;
  /**
   * Description
   */
  description: string;
  /**
   * Quantity
   */
  quantity: GQL_Decimal | null;
  /**
   * Quote Line Id
   */
  quoteLineId: string;
  /**
   * Name of screening this line belongs to
   */
  screeningTitle: string;
  /**
   * Screening line
   */
  screeningLine: boolean;
  /**
   * Screening Line Id
   */
  screeningLineId: string;
  /**
   * Screening Line position no
   */
  screeningLinePosition: string | null;
  /**
   * Price of unit
   */
  unitPrice: GQL_Decimal | null;
  /**
   * Cost of unit
   */
  unitCost: GQL_Decimal | null;
  /**
   * New price of unit
   */
  newUnitPrice: GQL_Decimal | null;
  /**
   * Planning date
   */
  planningDate: GQL_Date;
  /**
   * Work type code
   */
  workTypeCode: string;
  /**
   * Created by
   */
  createdBy: string;
}

export interface SetInvoiceReasonCodes_setInvoiceReasonCodes {
  /**
   * Reference no invoice
   */
  erpReferenceNo: string;
  /**
   * Category of invoice
   */
  category: InvoiceCategory;
  /**
   * Invoice status
   */
  status: InvoiceStatus;
  /**
   * Date the invoice is posted
   */
  invoicePostingDate: GQL_Date;
  /**
   * Start date of the invoicing period
   */
  invoiceStartDate: GQL_Date;
  /**
   * End date of the invoicing period
   */
  invoiceEndDate: GQL_Date;
  /**
   * Invoice validation rule bypasses
   */
  validationRuleBypasses: SetInvoiceReasonCodes_setInvoiceReasonCodes_validationRuleBypasses[];
  /**
   * Invoice lines
   */
  lines: SetInvoiceReasonCodes_setInvoiceReasonCodes_lines[];
  /**
   * Determines whether draft lines have been suggested for this invoice
   */
  hasSuggestedLines: boolean;
  /**
   * Description to put on the invoice
   */
  invoiceDescription: string;
  /**
   * Rejection reason from manager
   */
  rejectionReason: string | null;
}

export interface SetInvoiceReasonCodes {
  setInvoiceReasonCodes: SetInvoiceReasonCodes_setInvoiceReasonCodes;
}

export interface SetInvoiceReasonCodesVariables {
  caseId: string;
  invoiceERPReference: string;
  validationRuleBypasses: ValidationRuleBypassInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SuggestInvoiceLines
// ====================================================

export interface SuggestInvoiceLines_suggestInvoiceLines {
  /**
   * System Id
   */
  systemId: string;
  /**
   * Sort index
   */
  sortIndex: number;
  /**
   * Case number
   */
  caseNo: string;
  /**
   * Document number (planningline reference)
   */
  documentNo: string;
  /**
   * Unique identifier
   */
  no: string;
  /**
   * ERP Reference Task
   */
  erpReferenceTask: string;
  /**
   * Line amount
   */
  lineAmount: GQL_Decimal | null;
  /**
   * Line Type
   */
  lineType: string;
  /**
   * Type
   */
  type: string;
  /**
   * Tells ERP system to attach document for subcontractor lines
   */
  attachDocument: boolean | null;
  /**
   * If allow attach document checkbox should be shown
   */
  allowAttachDocument: boolean;
  /**
   * Description
   */
  description: string;
  /**
   * Quantity
   */
  quantity: GQL_Decimal | null;
  /**
   * Quote Line Id
   */
  quoteLineId: string;
  /**
   * Name of screening this line belongs to
   */
  screeningTitle: string;
  /**
   * Screening line
   */
  screeningLine: boolean;
  /**
   * Screening Line Id
   */
  screeningLineId: string;
  /**
   * Screening Line position no
   */
  screeningLinePosition: string | null;
  /**
   * Price of unit
   */
  unitPrice: GQL_Decimal | null;
  /**
   * Cost of unit
   */
  unitCost: GQL_Decimal | null;
  /**
   * New price of unit
   */
  newUnitPrice: GQL_Decimal | null;
  /**
   * Planning date
   */
  planningDate: GQL_Date;
  /**
   * Work type code
   */
  workTypeCode: string;
  /**
   * Created by
   */
  createdBy: string;
}

export interface SuggestInvoiceLines {
  suggestInvoiceLines: SuggestInvoiceLines_suggestInvoiceLines[] | null;
}

export interface SuggestInvoiceLinesVariables {
  caseId: string;
  invoiceERPReference: string;
  includePreviousLines?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateInvoice
// ====================================================

export interface UpdateInvoice_updateInvoice_validationRuleBypasses {
  /**
   * Rule that was bypassed
   */
  rule: string;
  /**
   * Reason code id
   */
  reasonCode: string;
  /**
   * User comment
   */
  comment: string | null;
}

export interface UpdateInvoice_updateInvoice_lines {
  /**
   * System Id
   */
  systemId: string;
  /**
   * Sort index
   */
  sortIndex: number;
  /**
   * Case number
   */
  caseNo: string;
  /**
   * Document number (planningline reference)
   */
  documentNo: string;
  /**
   * Unique identifier
   */
  no: string;
  /**
   * ERP Reference Task
   */
  erpReferenceTask: string;
  /**
   * Line amount
   */
  lineAmount: GQL_Decimal | null;
  /**
   * Line Type
   */
  lineType: string;
  /**
   * Type
   */
  type: string;
  /**
   * Tells ERP system to attach document for subcontractor lines
   */
  attachDocument: boolean | null;
  /**
   * If allow attach document checkbox should be shown
   */
  allowAttachDocument: boolean;
  /**
   * Description
   */
  description: string;
  /**
   * Quantity
   */
  quantity: GQL_Decimal | null;
  /**
   * Quote Line Id
   */
  quoteLineId: string;
  /**
   * Name of screening this line belongs to
   */
  screeningTitle: string;
  /**
   * Screening line
   */
  screeningLine: boolean;
  /**
   * Screening Line Id
   */
  screeningLineId: string;
  /**
   * Screening Line position no
   */
  screeningLinePosition: string | null;
  /**
   * Price of unit
   */
  unitPrice: GQL_Decimal | null;
  /**
   * Cost of unit
   */
  unitCost: GQL_Decimal | null;
  /**
   * New price of unit
   */
  newUnitPrice: GQL_Decimal | null;
  /**
   * Planning date
   */
  planningDate: GQL_Date;
  /**
   * Work type code
   */
  workTypeCode: string;
  /**
   * Created by
   */
  createdBy: string;
}

export interface UpdateInvoice_updateInvoice {
  /**
   * Reference no invoice
   */
  erpReferenceNo: string;
  /**
   * Category of invoice
   */
  category: InvoiceCategory;
  /**
   * Invoice status
   */
  status: InvoiceStatus;
  /**
   * Date the invoice is posted
   */
  invoicePostingDate: GQL_Date;
  /**
   * Start date of the invoicing period
   */
  invoiceStartDate: GQL_Date;
  /**
   * End date of the invoicing period
   */
  invoiceEndDate: GQL_Date;
  /**
   * Invoice validation rule bypasses
   */
  validationRuleBypasses: UpdateInvoice_updateInvoice_validationRuleBypasses[];
  /**
   * Invoice lines
   */
  lines: UpdateInvoice_updateInvoice_lines[];
  /**
   * Determines whether draft lines have been suggested for this invoice
   */
  hasSuggestedLines: boolean;
  /**
   * Description to put on the invoice
   */
  invoiceDescription: string;
  /**
   * Rejection reason from manager
   */
  rejectionReason: string | null;
}

export interface UpdateInvoice {
  updateInvoice: UpdateInvoice_updateInvoice;
}

export interface UpdateInvoiceVariables {
  caseId: string;
  invoiceERPReference: string;
  lines: DraftInvoiceLineInputType[];
  invoiceDescription: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateScreeningInvoice
// ====================================================

export interface UpdateScreeningInvoice_updateScreeningInvoice_validationRuleBypasses {
  /**
   * Rule that was bypassed
   */
  rule: string;
  /**
   * Reason code id
   */
  reasonCode: string;
  /**
   * User comment
   */
  comment: string | null;
}

export interface UpdateScreeningInvoice_updateScreeningInvoice_lines {
  /**
   * System Id
   */
  systemId: string;
  /**
   * Sort index
   */
  sortIndex: number;
  /**
   * Case number
   */
  caseNo: string;
  /**
   * Document number (planningline reference)
   */
  documentNo: string;
  /**
   * Unique identifier
   */
  no: string;
  /**
   * ERP Reference Task
   */
  erpReferenceTask: string;
  /**
   * Line amount
   */
  lineAmount: GQL_Decimal | null;
  /**
   * Line Type
   */
  lineType: string;
  /**
   * Type
   */
  type: string;
  /**
   * Tells ERP system to attach document for subcontractor lines
   */
  attachDocument: boolean | null;
  /**
   * If allow attach document checkbox should be shown
   */
  allowAttachDocument: boolean;
  /**
   * Description
   */
  description: string;
  /**
   * Quantity
   */
  quantity: GQL_Decimal | null;
  /**
   * Quote Line Id
   */
  quoteLineId: string;
  /**
   * Name of screening this line belongs to
   */
  screeningTitle: string;
  /**
   * Screening line
   */
  screeningLine: boolean;
  /**
   * Screening Line Id
   */
  screeningLineId: string;
  /**
   * Screening Line position no
   */
  screeningLinePosition: string | null;
  /**
   * Price of unit
   */
  unitPrice: GQL_Decimal | null;
  /**
   * Cost of unit
   */
  unitCost: GQL_Decimal | null;
  /**
   * New price of unit
   */
  newUnitPrice: GQL_Decimal | null;
  /**
   * Planning date
   */
  planningDate: GQL_Date;
  /**
   * Work type code
   */
  workTypeCode: string;
  /**
   * Created by
   */
  createdBy: string;
}

export interface UpdateScreeningInvoice_updateScreeningInvoice {
  /**
   * Reference no invoice
   */
  erpReferenceNo: string;
  /**
   * Category of invoice
   */
  category: InvoiceCategory;
  /**
   * Invoice status
   */
  status: InvoiceStatus;
  /**
   * Date the invoice is posted
   */
  invoicePostingDate: GQL_Date;
  /**
   * Start date of the invoicing period
   */
  invoiceStartDate: GQL_Date;
  /**
   * End date of the invoicing period
   */
  invoiceEndDate: GQL_Date;
  /**
   * Invoice validation rule bypasses
   */
  validationRuleBypasses: UpdateScreeningInvoice_updateScreeningInvoice_validationRuleBypasses[];
  /**
   * Invoice lines
   */
  lines: UpdateScreeningInvoice_updateScreeningInvoice_lines[];
  /**
   * Determines whether draft lines have been suggested for this invoice
   */
  hasSuggestedLines: boolean;
  /**
   * Description to put on the invoice
   */
  invoiceDescription: string;
  /**
   * Rejection reason from manager
   */
  rejectionReason: string | null;
}

export interface UpdateScreeningInvoice {
  updateScreeningInvoice: UpdateScreeningInvoice_updateScreeningInvoice;
}

export interface UpdateScreeningInvoiceVariables {
  caseId: string;
  invoiceERPReference: string;
  invoiceDescription: string;
  lines: DraftInvoiceLineInputType[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ValidateInvoice
// ====================================================

export interface ValidateInvoice_validateInvoice_value {
  /**
   * Indicates if rule is valid
   */
  valid: boolean;
  /**
   * Indicates if rule can be bypassed with reason code
   */
  bypassAllowed: boolean;
  /**
   * Indicates what was expected or required for the validation rule
   */
  expectedResult: string | null;
  /**
   * Indicates the actual result of the validation rule
   */
  actualResult: string | null;
}

export interface ValidateInvoice_validateInvoice {
  /**
   * Validation rule key
   */
  key: string;
  /**
   * Validation rule result
   */
  value: ValidateInvoice_validateInvoice_value;
}

export interface ValidateInvoice {
  validateInvoice: ValidateInvoice_validateInvoice[];
}

export interface ValidateInvoiceVariables {
  caseId: string;
  invoiceERPReference: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateJob
// ====================================================

export interface CreateJob_createJob_assignedTo {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
}

export interface CreateJob_createJob_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface CreateJob_createJob_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
}

export interface CreateJob_createJob_movable_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface CreateJob_createJob_movable {
  /**
   * Unique id of movable
   */
  id: string;
  /**
   * Description of movable
   */
  description: string;
  /**
   * Movable status
   */
  status: string;
  /**
   * Movable placement
   */
  placement: CreateJob_createJob_movable_placement;
}

export interface CreateJob_createJob {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Type of case task
   */
  type: JobType;
  /**
   * Description of the case task
   */
  description: string;
  /**
   * Deadline of the case task
   */
  deadline: GQL_DateTime;
  /**
   * The user responsible for the task
   */
  assignedTo: CreateJob_createJob_assignedTo | null;
  /**
   * The location responsible to the task
   */
  location: CreateJob_createJob_location | null;
  /**
   * The case the task belongs to
   */
  case: CreateJob_createJob_case | null;
  /**
   * The movable responsible to the task
   */
  movable: CreateJob_createJob_movable | null;
  /**
   * Whether the task is completed
   */
  completed: boolean;
}

export interface CreateJob {
  /**
   * Create a case task
   */
  createJob: CreateJob_createJob;
}

export interface CreateJobVariables {
  job: JobInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetJobs
// ====================================================

export interface GetJobs_jobs_assignedTo {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
}

export interface GetJobs_jobs_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetJobs_jobs_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
}

export interface GetJobs_jobs_movable_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface GetJobs_jobs_movable {
  /**
   * Unique id of movable
   */
  id: string;
  /**
   * Description of movable
   */
  description: string;
  /**
   * Movable status
   */
  status: string;
  /**
   * Movable placement
   */
  placement: GetJobs_jobs_movable_placement;
}

export interface GetJobs_jobs {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Type of case task
   */
  type: JobType;
  /**
   * Description of the case task
   */
  description: string;
  /**
   * Deadline of the case task
   */
  deadline: GQL_DateTime;
  /**
   * The user responsible for the task
   */
  assignedTo: GetJobs_jobs_assignedTo | null;
  /**
   * The location responsible to the task
   */
  location: GetJobs_jobs_location | null;
  /**
   * The case the task belongs to
   */
  case: GetJobs_jobs_case | null;
  /**
   * The movable responsible to the task
   */
  movable: GetJobs_jobs_movable | null;
  /**
   * Whether the task is completed
   */
  completed: boolean;
}

export interface GetJobs {
  jobs: GetJobs_jobs[];
}

export interface GetJobsVariables {
  type?: JobType | null;
  personalOnly?: boolean | null;
  case?: string | null;
  location?: string | null;
  completed?: boolean | null;
  assignedTo?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateJob
// ====================================================

export interface UpdateJob_updateJob_assignedTo {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
}

export interface UpdateJob_updateJob_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface UpdateJob_updateJob_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
}

export interface UpdateJob_updateJob_movable_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface UpdateJob_updateJob_movable {
  /**
   * Unique id of movable
   */
  id: string;
  /**
   * Description of movable
   */
  description: string;
  /**
   * Movable status
   */
  status: string;
  /**
   * Movable placement
   */
  placement: UpdateJob_updateJob_movable_placement;
}

export interface UpdateJob_updateJob {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Type of case task
   */
  type: JobType;
  /**
   * Description of the case task
   */
  description: string;
  /**
   * Deadline of the case task
   */
  deadline: GQL_DateTime;
  /**
   * The user responsible for the task
   */
  assignedTo: UpdateJob_updateJob_assignedTo | null;
  /**
   * The location responsible to the task
   */
  location: UpdateJob_updateJob_location | null;
  /**
   * The case the task belongs to
   */
  case: UpdateJob_updateJob_case | null;
  /**
   * The movable responsible to the task
   */
  movable: UpdateJob_updateJob_movable | null;
  /**
   * Whether the task is completed
   */
  completed: boolean;
}

export interface UpdateJob {
  /**
   * Update a case task
   */
  updateJob: UpdateJob_updateJob | null;
}

export interface UpdateJobVariables {
  id: string;
  description?: string | null;
  deadline?: GQL_DateTime | null;
  assignedTo?: string | null;
  completed?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWebLocations
// ====================================================

export interface GetWebLocations_locations_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebLocations_locations {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: GetWebLocations_locations_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface GetWebLocations {
  locations: GetWebLocations_locations[];
}

export interface GetWebLocationsVariables {
  movableLocationsOnly?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMachineCaseOverview
// ====================================================

export interface GetMachineCaseOverview_machineCaseOverview {
  /**
   * Machine name
   */
  machine: string;
  /**
   * Start of usage of machine
   */
  dateStart: string;
  /**
   * Days active of machine
   */
  daysActive: string;
  /**
   * Usages of machine
   */
  usage: string;
}

export interface GetMachineCaseOverview {
  machineCaseOverview: GetMachineCaseOverview_machineCaseOverview[];
}

export interface GetMachineCaseOverviewVariables {
  caseERPReferenceNo: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMachineHistory
// ====================================================

export interface GetMachineHistory_machineHistory {
  /**
   * BC identifier
   */
  erpReferenceNo: string;
  /**
   * Name of machine
   */
  name: string;
  /**
   * Start of machineUsage
   */
  dateStart: GQL_Date;
  /**
   * end of machineUsage
   */
  dateEnd: GQL_Date;
  /**
   * Case number if case
   */
  eRPCaseReference: string | null;
  /**
   * location number if location
   */
  eRPLocationReference: string | null;
  /**
   * Consumption
   */
  consumption: GQL_Decimal;
  /**
   * Name of user
   */
  userName: string;
  /**
   * Ressource type/allocation Type
   */
  type: string;
  /**
   * bool to determine if machine is active
   */
  active: boolean;
  /**
   * Current placement of machine
   */
  placement: string;
  /**
   * Original location of machine
   */
  originLocation: string;
  /**
   * Hibernation state
   */
  hibernate: boolean;
  /**
   * Hibernation state
   */
  hibernationReason: string;
  /**
   * Does the machine require specific education
   */
  educationPrerequisit: string[] | null;
  /**
   * Start time
   */
  startTime: string;
  /**
   * Date of next service
   */
  serviceDate: GQL_DateTime;
  /**
   * Factor for date to kwh conversion
   */
  consumptionFactor: GQL_Decimal;
  /**
   * Whether the type is days, kwh
   */
  consumptionType: MachineConsumptionType;
  /**
   * machines model number
   */
  modelNumber: string;
  /**
   * Reservation Start
   */
  reservationStart: GQL_DateTime | null;
  /**
   * Reservation End
   */
  reservationEnd: GQL_DateTime | null;
  /**
   * reservation description
   */
  reservationDescription: string | null;
  /**
   * Reservation by ressource
   */
  reservationByRessource: string | null;
  /**
   * reservation on Case
   */
  reservationOnCase: string | null;
  /**
   * Is the machine blocked
   */
  blocked: boolean;
  /**
   * Where the machine has been placed, e.g. Basement, 1st floor bedroom etc.
   */
  placementDescription: string | null;
  /**
   * The total consumption after machine has been used
   */
  usageMeterEnd: GQL_Decimal;
  /**
   * Unavailable reason
   */
  unavailableReason: string;
  /**
   * Marks that machine has been discontinued
   */
  discontinued: boolean;
}

export interface GetMachineHistory {
  machineHistory: GetMachineHistory_machineHistory[];
}

export interface GetMachineHistoryVariables {
  machineERPReferenceNo: string;
  minDate?: GQL_Date | null;
  maxDate?: GQL_Date | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMachinesPreviouslyOnCase
// ====================================================

export interface GetMachinesPreviouslyOnCase_machinesPreviouslyOnCase {
  /**
   * BC identifier
   */
  erpReferenceNo: string;
  /**
   * Name of machine
   */
  name: string;
  /**
   * Start of machineUsage
   */
  dateStart: GQL_Date;
  /**
   * end of machineUsage
   */
  dateEnd: GQL_Date;
  /**
   * Case number if case
   */
  eRPCaseReference: string | null;
  /**
   * location number if location
   */
  eRPLocationReference: string | null;
  /**
   * Consumption
   */
  consumption: GQL_Decimal;
  /**
   * Name of user
   */
  userName: string;
  /**
   * Ressource type/allocation Type
   */
  type: string;
  /**
   * bool to determine if machine is active
   */
  active: boolean;
  /**
   * Current placement of machine
   */
  placement: string;
  /**
   * Original location of machine
   */
  originLocation: string;
  /**
   * Hibernation state
   */
  hibernate: boolean;
  /**
   * Hibernation state
   */
  hibernationReason: string;
  /**
   * Does the machine require specific education
   */
  educationPrerequisit: string[] | null;
  /**
   * Start time
   */
  startTime: string;
  /**
   * Date of next service
   */
  serviceDate: GQL_DateTime;
  /**
   * Factor for date to kwh conversion
   */
  consumptionFactor: GQL_Decimal;
  /**
   * Whether the type is days, kwh
   */
  consumptionType: MachineConsumptionType;
  /**
   * machines model number
   */
  modelNumber: string;
  /**
   * Reservation Start
   */
  reservationStart: GQL_DateTime | null;
  /**
   * Reservation End
   */
  reservationEnd: GQL_DateTime | null;
  /**
   * reservation description
   */
  reservationDescription: string | null;
  /**
   * Reservation by ressource
   */
  reservationByRessource: string | null;
  /**
   * reservation on Case
   */
  reservationOnCase: string | null;
  /**
   * Is the machine blocked
   */
  blocked: boolean;
  /**
   * Where the machine has been placed, e.g. Basement, 1st floor bedroom etc.
   */
  placementDescription: string | null;
  /**
   * The total consumption after machine has been used
   */
  usageMeterEnd: GQL_Decimal;
  /**
   * Unavailable reason
   */
  unavailableReason: string;
  /**
   * Marks that machine has been discontinued
   */
  discontinued: boolean;
}

export interface GetMachinesPreviouslyOnCase {
  machinesPreviouslyOnCase: GetMachinesPreviouslyOnCase_machinesPreviouslyOnCase[];
}

export interface GetMachinesPreviouslyOnCaseVariables {
  erpNo: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMachineTypes
// ====================================================

export interface GetMachineTypes {
  machineTypes: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWebMachine
// ====================================================

export interface GetWebMachine_machine {
  /**
   * BC identifier
   */
  erpReferenceNo: string;
  /**
   * Name of machine
   */
  name: string;
  /**
   * Start of machineUsage
   */
  dateStart: GQL_Date;
  /**
   * end of machineUsage
   */
  dateEnd: GQL_Date;
  /**
   * Case number if case
   */
  eRPCaseReference: string | null;
  /**
   * location number if location
   */
  eRPLocationReference: string | null;
  /**
   * Consumption
   */
  consumption: GQL_Decimal;
  /**
   * Name of user
   */
  userName: string;
  /**
   * Ressource type/allocation Type
   */
  type: string;
  /**
   * bool to determine if machine is active
   */
  active: boolean;
  /**
   * Current placement of machine
   */
  placement: string;
  /**
   * Original location of machine
   */
  originLocation: string;
  /**
   * Hibernation state
   */
  hibernate: boolean;
  /**
   * Hibernation state
   */
  hibernationReason: string;
  /**
   * Does the machine require specific education
   */
  educationPrerequisit: string[] | null;
  /**
   * Start time
   */
  startTime: string;
  /**
   * Date of next service
   */
  serviceDate: GQL_DateTime;
  /**
   * Factor for date to kwh conversion
   */
  consumptionFactor: GQL_Decimal;
  /**
   * Whether the type is days, kwh
   */
  consumptionType: MachineConsumptionType;
  /**
   * machines model number
   */
  modelNumber: string;
  /**
   * Reservation Start
   */
  reservationStart: GQL_DateTime | null;
  /**
   * Reservation End
   */
  reservationEnd: GQL_DateTime | null;
  /**
   * reservation description
   */
  reservationDescription: string | null;
  /**
   * Reservation by ressource
   */
  reservationByRessource: string | null;
  /**
   * reservation on Case
   */
  reservationOnCase: string | null;
  /**
   * Is the machine blocked
   */
  blocked: boolean;
  /**
   * Where the machine has been placed, e.g. Basement, 1st floor bedroom etc.
   */
  placementDescription: string | null;
  /**
   * The total consumption after machine has been used
   */
  usageMeterEnd: GQL_Decimal;
  /**
   * Unavailable reason
   */
  unavailableReason: string;
  /**
   * Marks that machine has been discontinued
   */
  discontinued: boolean;
}

export interface GetWebMachine {
  machine: GetWebMachine_machine | null;
}

export interface GetWebMachineVariables {
  machineERPReferenceNo: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWebMachineGuideFiles
// ====================================================

export interface GetWebMachineGuideFiles_machineGuideFiles_metadata {
  /**
   * KVP key
   */
  key: string;
  /**
   * KVP value
   */
  value: string;
}

export interface GetWebMachineGuideFiles_machineGuideFiles {
  /**
   * File name
   */
  name: string;
  /**
   * File thumbnail URL
   */
  thumbnail: string;
  /**
   * File metadata from ESDH
   */
  metadata: GetWebMachineGuideFiles_machineGuideFiles_metadata[];
  /**
   * File url from ESDH
   */
  url: string;
}

export interface GetWebMachineGuideFiles {
  machineGuideFiles: GetWebMachineGuideFiles_machineGuideFiles[];
}

export interface GetWebMachineGuideFilesVariables {
  filter?: FileFilterInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWebMachineLocations
// ====================================================

export interface GetWebMachineLocations_machineLocations {
  /**
   * Identifier of location
   */
  locationCode: string;
  /**
   * address line
   */
  address: string;
  /**
   * City
   */
  city: string;
  /**
   * Contact person
   */
  contact: string;
  /**
   * Name
   */
  name: string;
  /**
   * Post code
   */
  postCode: string;
  /**
   * True if location is unavaliable
   */
  unavaliable: boolean;
}

export interface GetWebMachineLocations {
  machineLocations: GetWebMachineLocations_machineLocations[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWebMachines
// ====================================================

export interface GetWebMachines_machines {
  /**
   * BC identifier
   */
  erpReferenceNo: string;
  /**
   * Name of machine
   */
  name: string;
  /**
   * Start of machineUsage
   */
  dateStart: GQL_Date;
  /**
   * end of machineUsage
   */
  dateEnd: GQL_Date;
  /**
   * Case number if case
   */
  eRPCaseReference: string | null;
  /**
   * location number if location
   */
  eRPLocationReference: string | null;
  /**
   * Consumption
   */
  consumption: GQL_Decimal;
  /**
   * Name of user
   */
  userName: string;
  /**
   * Ressource type/allocation Type
   */
  type: string;
  /**
   * bool to determine if machine is active
   */
  active: boolean;
  /**
   * Current placement of machine
   */
  placement: string;
  /**
   * Original location of machine
   */
  originLocation: string;
  /**
   * Hibernation state
   */
  hibernate: boolean;
  /**
   * Hibernation state
   */
  hibernationReason: string;
  /**
   * Does the machine require specific education
   */
  educationPrerequisit: string[] | null;
  /**
   * Start time
   */
  startTime: string;
  /**
   * Date of next service
   */
  serviceDate: GQL_DateTime;
  /**
   * Factor for date to kwh conversion
   */
  consumptionFactor: GQL_Decimal;
  /**
   * Whether the type is days, kwh
   */
  consumptionType: MachineConsumptionType;
  /**
   * machines model number
   */
  modelNumber: string;
  /**
   * Reservation Start
   */
  reservationStart: GQL_DateTime | null;
  /**
   * Reservation End
   */
  reservationEnd: GQL_DateTime | null;
  /**
   * reservation description
   */
  reservationDescription: string | null;
  /**
   * Reservation by ressource
   */
  reservationByRessource: string | null;
  /**
   * reservation on Case
   */
  reservationOnCase: string | null;
  /**
   * Is the machine blocked
   */
  blocked: boolean;
  /**
   * Where the machine has been placed, e.g. Basement, 1st floor bedroom etc.
   */
  placementDescription: string | null;
  /**
   * The total consumption after machine has been used
   */
  usageMeterEnd: GQL_Decimal;
  /**
   * Unavailable reason
   */
  unavailableReason: string;
  /**
   * Marks that machine has been discontinued
   */
  discontinued: boolean;
}

export interface GetWebMachines {
  machines: GetWebMachines_machines[];
}

export interface GetWebMachinesVariables {
  status?: MachineStatus | null;
  cases?: string[] | null;
  locations?: string[] | null;
  types?: string[] | null;
  unavailable?: boolean | null;
  hibernation?: boolean | null;
  reservationByRessource?: string | null;
  discontinued?: boolean | null;
  offset?: number | null;
  limit?: number | null;
  searchString?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWebMachinesOnCase
// ====================================================

export interface GetWebMachinesOnCase_machinesOnCase {
  /**
   * BC identifier
   */
  erpReferenceNo: string;
  /**
   * Name of machine
   */
  name: string;
  /**
   * Start of machineUsage
   */
  dateStart: GQL_Date;
  /**
   * end of machineUsage
   */
  dateEnd: GQL_Date;
  /**
   * Case number if case
   */
  eRPCaseReference: string | null;
  /**
   * location number if location
   */
  eRPLocationReference: string | null;
  /**
   * Consumption
   */
  consumption: GQL_Decimal;
  /**
   * Name of user
   */
  userName: string;
  /**
   * Ressource type/allocation Type
   */
  type: string;
  /**
   * bool to determine if machine is active
   */
  active: boolean;
  /**
   * Current placement of machine
   */
  placement: string;
  /**
   * Original location of machine
   */
  originLocation: string;
  /**
   * Hibernation state
   */
  hibernate: boolean;
  /**
   * Hibernation state
   */
  hibernationReason: string;
  /**
   * Does the machine require specific education
   */
  educationPrerequisit: string[] | null;
  /**
   * Start time
   */
  startTime: string;
  /**
   * Date of next service
   */
  serviceDate: GQL_DateTime;
  /**
   * Factor for date to kwh conversion
   */
  consumptionFactor: GQL_Decimal;
  /**
   * Whether the type is days, kwh
   */
  consumptionType: MachineConsumptionType;
  /**
   * machines model number
   */
  modelNumber: string;
  /**
   * Reservation Start
   */
  reservationStart: GQL_DateTime | null;
  /**
   * Reservation End
   */
  reservationEnd: GQL_DateTime | null;
  /**
   * reservation description
   */
  reservationDescription: string | null;
  /**
   * Reservation by ressource
   */
  reservationByRessource: string | null;
  /**
   * reservation on Case
   */
  reservationOnCase: string | null;
  /**
   * Is the machine blocked
   */
  blocked: boolean;
  /**
   * Where the machine has been placed, e.g. Basement, 1st floor bedroom etc.
   */
  placementDescription: string | null;
  /**
   * The total consumption after machine has been used
   */
  usageMeterEnd: GQL_Decimal;
  /**
   * Unavailable reason
   */
  unavailableReason: string;
  /**
   * Marks that machine has been discontinued
   */
  discontinued: boolean;
}

export interface GetWebMachinesOnCase {
  machinesOnCase: GetWebMachinesOnCase_machinesOnCase[];
}

export interface GetWebMachinesOnCaseVariables {
  erpNo: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MachineReserve
// ====================================================

export interface MachineReserve_machineReserve {
  /**
   * BC identifier
   */
  erpReferenceNo: string;
  /**
   * Name of machine
   */
  name: string;
  /**
   * Start of machineUsage
   */
  dateStart: GQL_Date;
  /**
   * end of machineUsage
   */
  dateEnd: GQL_Date;
  /**
   * Case number if case
   */
  eRPCaseReference: string | null;
  /**
   * location number if location
   */
  eRPLocationReference: string | null;
  /**
   * Consumption
   */
  consumption: GQL_Decimal;
  /**
   * Name of user
   */
  userName: string;
  /**
   * Ressource type/allocation Type
   */
  type: string;
  /**
   * bool to determine if machine is active
   */
  active: boolean;
  /**
   * Current placement of machine
   */
  placement: string;
  /**
   * Original location of machine
   */
  originLocation: string;
  /**
   * Hibernation state
   */
  hibernate: boolean;
  /**
   * Hibernation state
   */
  hibernationReason: string;
  /**
   * Does the machine require specific education
   */
  educationPrerequisit: string[] | null;
  /**
   * Start time
   */
  startTime: string;
  /**
   * Date of next service
   */
  serviceDate: GQL_DateTime;
  /**
   * Factor for date to kwh conversion
   */
  consumptionFactor: GQL_Decimal;
  /**
   * Whether the type is days, kwh
   */
  consumptionType: MachineConsumptionType;
  /**
   * machines model number
   */
  modelNumber: string;
  /**
   * Reservation Start
   */
  reservationStart: GQL_DateTime | null;
  /**
   * Reservation End
   */
  reservationEnd: GQL_DateTime | null;
  /**
   * reservation description
   */
  reservationDescription: string | null;
  /**
   * Reservation by ressource
   */
  reservationByRessource: string | null;
  /**
   * reservation on Case
   */
  reservationOnCase: string | null;
  /**
   * Is the machine blocked
   */
  blocked: boolean;
  /**
   * Where the machine has been placed, e.g. Basement, 1st floor bedroom etc.
   */
  placementDescription: string | null;
  /**
   * The total consumption after machine has been used
   */
  usageMeterEnd: GQL_Decimal;
  /**
   * Unavailable reason
   */
  unavailableReason: string;
  /**
   * Marks that machine has been discontinued
   */
  discontinued: boolean;
}

export interface MachineReserve {
  machineReserve: MachineReserve_machineReserve | null;
}

export interface MachineReserveVariables {
  machineInput: MachineInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MoveWebMachine
// ====================================================

export interface MoveWebMachine_moveMachine {
  /**
   * BC identifier
   */
  erpReferenceNo: string;
  /**
   * Name of machine
   */
  name: string;
  /**
   * Start of machineUsage
   */
  dateStart: GQL_Date;
  /**
   * end of machineUsage
   */
  dateEnd: GQL_Date;
  /**
   * Case number if case
   */
  eRPCaseReference: string | null;
  /**
   * location number if location
   */
  eRPLocationReference: string | null;
  /**
   * Consumption
   */
  consumption: GQL_Decimal;
  /**
   * Name of user
   */
  userName: string;
  /**
   * Ressource type/allocation Type
   */
  type: string;
  /**
   * bool to determine if machine is active
   */
  active: boolean;
  /**
   * Current placement of machine
   */
  placement: string;
  /**
   * Original location of machine
   */
  originLocation: string;
  /**
   * Hibernation state
   */
  hibernate: boolean;
  /**
   * Hibernation state
   */
  hibernationReason: string;
  /**
   * Does the machine require specific education
   */
  educationPrerequisit: string[] | null;
  /**
   * Start time
   */
  startTime: string;
  /**
   * Date of next service
   */
  serviceDate: GQL_DateTime;
  /**
   * Factor for date to kwh conversion
   */
  consumptionFactor: GQL_Decimal;
  /**
   * Whether the type is days, kwh
   */
  consumptionType: MachineConsumptionType;
  /**
   * machines model number
   */
  modelNumber: string;
  /**
   * Reservation Start
   */
  reservationStart: GQL_DateTime | null;
  /**
   * Reservation End
   */
  reservationEnd: GQL_DateTime | null;
  /**
   * reservation description
   */
  reservationDescription: string | null;
  /**
   * Reservation by ressource
   */
  reservationByRessource: string | null;
  /**
   * reservation on Case
   */
  reservationOnCase: string | null;
  /**
   * Is the machine blocked
   */
  blocked: boolean;
  /**
   * Where the machine has been placed, e.g. Basement, 1st floor bedroom etc.
   */
  placementDescription: string | null;
  /**
   * The total consumption after machine has been used
   */
  usageMeterEnd: GQL_Decimal;
  /**
   * Unavailable reason
   */
  unavailableReason: string;
  /**
   * Marks that machine has been discontinued
   */
  discontinued: boolean;
}

export interface MoveWebMachine {
  moveMachine: MoveWebMachine_moveMachine | null;
}

export interface MoveWebMachineVariables {
  machineInput: MachineInput;
  caseERPreferenceNo: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWorkEnvRegulationsManagerList
// ====================================================

export interface GetWorkEnvRegulationsManagerList_workEnvRegulationsManagerList_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * User employee id
   */
  employeeId: string | null;
}

export interface GetWorkEnvRegulationsManagerList_workEnvRegulationsManagerList {
  /**
   * AD user
   */
  user: GetWorkEnvRegulationsManagerList_workEnvRegulationsManagerList_user;
  /**
   * Id of time tracking
   */
  hours48Over4Months: GQL_Decimal;
  /**
   * Hours current day
   */
  hoursCurrentDay: GQL_Decimal;
  /**
   * Eleven hour rest
   */
  elevenHourRest: boolean | null;
  /**
   * Eight hour rest
   */
  eightHourRest: boolean | null;
  /**
   * Days since rest day
   */
  daysSinceRestDay: number;
  /**
   * Last work time
   */
  lastWorkTime: string;
  /**
   * Available again time
   */
  availableAgainTime: string | null;
  /**
   * Current task
   */
  currentTask: string;
  /**
   * Started task
   */
  startedTask: string;
}

export interface GetWorkEnvRegulationsManagerList {
  workEnvRegulationsManagerList: GetWorkEnvRegulationsManagerList_workEnvRegulationsManagerList[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeLabelAndStatusMovable
// ====================================================

export interface ChangeLabelAndStatusMovable_changeLabelAndStatusMovable_case_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
}

export interface ChangeLabelAndStatusMovable_changeLabelAndStatusMovable_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface ChangeLabelAndStatusMovable_changeLabelAndStatusMovable_case_damage_contact {
  /**
   * Contact address information
   */
  address: ChangeLabelAndStatusMovable_changeLabelAndStatusMovable_case_damage_contact_address;
}

export interface ChangeLabelAndStatusMovable_changeLabelAndStatusMovable_case_damage {
  /**
   * Damage location contact information
   */
  contact: ChangeLabelAndStatusMovable_changeLabelAndStatusMovable_case_damage_contact;
}

export interface ChangeLabelAndStatusMovable_changeLabelAndStatusMovable_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: ChangeLabelAndStatusMovable_changeLabelAndStatusMovable_case_debitor;
  /**
   * Case damage details
   */
  damage: ChangeLabelAndStatusMovable_changeLabelAndStatusMovable_case_damage;
}

export interface ChangeLabelAndStatusMovable_changeLabelAndStatusMovable_placement_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface ChangeLabelAndStatusMovable_changeLabelAndStatusMovable_placement_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: ChangeLabelAndStatusMovable_changeLabelAndStatusMovable_placement_location_address;
}

export interface ChangeLabelAndStatusMovable_changeLabelAndStatusMovable_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: ChangeLabelAndStatusMovable_changeLabelAndStatusMovable_placement_location;
}

export interface ChangeLabelAndStatusMovable_changeLabelAndStatusMovable_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
}

export interface ChangeLabelAndStatusMovable_changeLabelAndStatusMovable_changes_before_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface ChangeLabelAndStatusMovable_changeLabelAndStatusMovable_changes_before {
  /**
   * Description of movable
   */
  description: string | null;
  /**
   * Movable volume in m3
   */
  volume: GQL_Decimal | null;
  /**
   * Movable completed indicator
   */
  completed: boolean | null;
  /**
   * Movable status
   */
  status: string | null;
  /**
   * Movable placement
   */
  placement: ChangeLabelAndStatusMovable_changeLabelAndStatusMovable_changes_before_placement | null;
}

export interface ChangeLabelAndStatusMovable_changeLabelAndStatusMovable_changes_after_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface ChangeLabelAndStatusMovable_changeLabelAndStatusMovable_changes_after {
  /**
   * Description of movable
   */
  description: string | null;
  /**
   * Movable volume in m3
   */
  volume: GQL_Decimal | null;
  /**
   * Movable completed indicator
   */
  completed: boolean | null;
  /**
   * Movable status
   */
  status: string | null;
  /**
   * Movable placement
   */
  placement: ChangeLabelAndStatusMovable_changeLabelAndStatusMovable_changes_after_placement | null;
}

export interface ChangeLabelAndStatusMovable_changeLabelAndStatusMovable_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: ChangeLabelAndStatusMovable_changeLabelAndStatusMovable_changes_user;
  /**
   * Value before
   */
  before: ChangeLabelAndStatusMovable_changeLabelAndStatusMovable_changes_before;
  /**
   * Value after
   */
  after: ChangeLabelAndStatusMovable_changeLabelAndStatusMovable_changes_after;
}

export interface ChangeLabelAndStatusMovable_changeLabelAndStatusMovable {
  /**
   * Unique id of movable
   */
  id: string;
  /**
   * Description of movable
   */
  description: string;
  /**
   * Movable volume in m3
   */
  volume: GQL_Decimal;
  /**
   * Case which movable is attached to
   */
  case: ChangeLabelAndStatusMovable_changeLabelAndStatusMovable_case;
  /**
   * Movable completed indicator
   */
  completed: boolean;
  /**
   * Movable status
   */
  status: string;
  /**
   * Movable placement
   */
  placement: ChangeLabelAndStatusMovable_changeLabelAndStatusMovable_placement;
  /**
   * Movable change version number
   */
  version: number;
  /**
   * Movable change log
   */
  changes: ChangeLabelAndStatusMovable_changeLabelAndStatusMovable_changes[];
}

export interface ChangeLabelAndStatusMovable {
  changeLabelAndStatusMovable: ChangeLabelAndStatusMovable_changeLabelAndStatusMovable;
}

export interface ChangeLabelAndStatusMovableVariables {
  oldMovableId: string;
  newMovableId: string;
  newStatus: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CheckMovable
// ====================================================

export interface CheckMovable_checkMovable {
  /**
   * Unique id of movable
   */
  id: string;
  /**
   * Movable completed indicator
   */
  completed: boolean;
  /**
   * Movable status
   */
  status: string;
}

export interface CheckMovable {
  checkMovable: CheckMovable_checkMovable | null;
}

export interface CheckMovableVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CheckOpenMovable
// ====================================================

export interface CheckOpenMovable {
  checkOpenMovable: boolean;
}

export interface CheckOpenMovableVariables {
  caseERPReferenceNo: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CheckOpenMovablesOnCase
// ====================================================

export interface CheckOpenMovablesOnCase {
  checkOpenMovable: boolean;
}

export interface CheckOpenMovablesOnCaseVariables {
  caseERPReferenceNo: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateMovable
// ====================================================

export interface CreateMovable_createMovable_case_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
}

export interface CreateMovable_createMovable_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateMovable_createMovable_case_damage_contact {
  /**
   * Contact address information
   */
  address: CreateMovable_createMovable_case_damage_contact_address;
}

export interface CreateMovable_createMovable_case_damage {
  /**
   * Damage location contact information
   */
  contact: CreateMovable_createMovable_case_damage_contact;
}

export interface CreateMovable_createMovable_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: CreateMovable_createMovable_case_debitor;
  /**
   * Case damage details
   */
  damage: CreateMovable_createMovable_case_damage;
}

export interface CreateMovable_createMovable_placement_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateMovable_createMovable_placement_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: CreateMovable_createMovable_placement_location_address;
}

export interface CreateMovable_createMovable_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: CreateMovable_createMovable_placement_location;
}

export interface CreateMovable_createMovable_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
}

export interface CreateMovable_createMovable_changes_before_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface CreateMovable_createMovable_changes_before {
  /**
   * Description of movable
   */
  description: string | null;
  /**
   * Movable volume in m3
   */
  volume: GQL_Decimal | null;
  /**
   * Movable completed indicator
   */
  completed: boolean | null;
  /**
   * Movable status
   */
  status: string | null;
  /**
   * Movable placement
   */
  placement: CreateMovable_createMovable_changes_before_placement | null;
}

export interface CreateMovable_createMovable_changes_after_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface CreateMovable_createMovable_changes_after {
  /**
   * Description of movable
   */
  description: string | null;
  /**
   * Movable volume in m3
   */
  volume: GQL_Decimal | null;
  /**
   * Movable completed indicator
   */
  completed: boolean | null;
  /**
   * Movable status
   */
  status: string | null;
  /**
   * Movable placement
   */
  placement: CreateMovable_createMovable_changes_after_placement | null;
}

export interface CreateMovable_createMovable_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: CreateMovable_createMovable_changes_user;
  /**
   * Value before
   */
  before: CreateMovable_createMovable_changes_before;
  /**
   * Value after
   */
  after: CreateMovable_createMovable_changes_after;
}

export interface CreateMovable_createMovable {
  /**
   * Unique id of movable
   */
  id: string;
  /**
   * Description of movable
   */
  description: string;
  /**
   * Movable volume in m3
   */
  volume: GQL_Decimal;
  /**
   * Case which movable is attached to
   */
  case: CreateMovable_createMovable_case;
  /**
   * Movable completed indicator
   */
  completed: boolean;
  /**
   * Movable status
   */
  status: string;
  /**
   * Movable placement
   */
  placement: CreateMovable_createMovable_placement;
  /**
   * Movable change version number
   */
  version: number;
  /**
   * Movable change log
   */
  changes: CreateMovable_createMovable_changes[];
}

export interface CreateMovable {
  createMovable: CreateMovable_createMovable;
}

export interface CreateMovableVariables {
  movable: MovableInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExportMovableToExcel
// ====================================================

export interface ExportMovableToExcel {
  exportMovableToExcel: GQL_Byte[];
}

export interface ExportMovableToExcelVariables {
  filters: MovableSearchFiltersInputType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GenerateMovableIdList
// ====================================================

export interface GenerateMovableIdList {
  generateMovableIdList: string[];
}

export interface GenerateMovableIdListVariables {
  count: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMovable
// ====================================================

export interface GetMovable_movable_case_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
}

export interface GetMovable_movable_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMovable_movable_case_damage_contact {
  /**
   * Contact address information
   */
  address: GetMovable_movable_case_damage_contact_address;
}

export interface GetMovable_movable_case_damage {
  /**
   * Damage location contact information
   */
  contact: GetMovable_movable_case_damage_contact;
}

export interface GetMovable_movable_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetMovable_movable_case_debitor;
  /**
   * Case damage details
   */
  damage: GetMovable_movable_case_damage;
}

export interface GetMovable_movable_placement_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMovable_movable_placement_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: GetMovable_movable_placement_location_address;
}

export interface GetMovable_movable_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: GetMovable_movable_placement_location;
}

export interface GetMovable_movable_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
}

export interface GetMovable_movable_changes_before_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface GetMovable_movable_changes_before {
  /**
   * Description of movable
   */
  description: string | null;
  /**
   * Movable volume in m3
   */
  volume: GQL_Decimal | null;
  /**
   * Movable completed indicator
   */
  completed: boolean | null;
  /**
   * Movable status
   */
  status: string | null;
  /**
   * Movable placement
   */
  placement: GetMovable_movable_changes_before_placement | null;
}

export interface GetMovable_movable_changes_after_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface GetMovable_movable_changes_after {
  /**
   * Description of movable
   */
  description: string | null;
  /**
   * Movable volume in m3
   */
  volume: GQL_Decimal | null;
  /**
   * Movable completed indicator
   */
  completed: boolean | null;
  /**
   * Movable status
   */
  status: string | null;
  /**
   * Movable placement
   */
  placement: GetMovable_movable_changes_after_placement | null;
}

export interface GetMovable_movable_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: GetMovable_movable_changes_user;
  /**
   * Value before
   */
  before: GetMovable_movable_changes_before;
  /**
   * Value after
   */
  after: GetMovable_movable_changes_after;
}

export interface GetMovable_movable {
  /**
   * Unique id of movable
   */
  id: string;
  /**
   * Description of movable
   */
  description: string;
  /**
   * Movable volume in m3
   */
  volume: GQL_Decimal;
  /**
   * Case which movable is attached to
   */
  case: GetMovable_movable_case;
  /**
   * Movable completed indicator
   */
  completed: boolean;
  /**
   * Movable status
   */
  status: string;
  /**
   * Movable placement
   */
  placement: GetMovable_movable_placement;
  /**
   * Movable change version number
   */
  version: number;
  /**
   * Movable change log
   */
  changes: GetMovable_movable_changes[];
}

export interface GetMovable {
  movable: GetMovable_movable;
}

export interface GetMovableVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMovableInvoiceableStatusOptions
// ====================================================

export interface GetMovableInvoiceableStatusOptions_movableInvoiceableStatusOptions {
  /**
   * KVP key
   */
  key: string;
  /**
   * KVP value
   */
  value: boolean;
}

export interface GetMovableInvoiceableStatusOptions {
  movableInvoiceableStatusOptions: GetMovableInvoiceableStatusOptions_movableInvoiceableStatusOptions[];
}

export interface GetMovableInvoiceableStatusOptionsVariables {
  erpNo: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMovableLocations
// ====================================================

export interface GetMovableLocations_movablesLocations_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMovableLocations_movablesLocations_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: GetMovableLocations_movablesLocations_location_address;
}

export interface GetMovableLocations_movablesLocations {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
  /**
   * Movable location volume in m3
   */
  volume: GQL_Decimal;
  /**
   * Accumulated location volume in m3
   */
  accVolume: GQL_Decimal;
  /**
   * Location which movables location is attached to
   */
  location: GetMovableLocations_movablesLocations_location;
}

export interface GetMovableLocations {
  movablesLocations: GetMovableLocations_movablesLocations[];
}

export interface GetMovableLocationsVariables {
  location?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMovables
// ====================================================

export interface GetMovables_movables_case_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
}

export interface GetMovables_movables_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMovables_movables_case_damage_contact {
  /**
   * Contact address information
   */
  address: GetMovables_movables_case_damage_contact_address;
}

export interface GetMovables_movables_case_damage {
  /**
   * Damage location contact information
   */
  contact: GetMovables_movables_case_damage_contact;
}

export interface GetMovables_movables_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetMovables_movables_case_debitor;
  /**
   * Case damage details
   */
  damage: GetMovables_movables_case_damage;
}

export interface GetMovables_movables_placement_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMovables_movables_placement_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: GetMovables_movables_placement_location_address;
}

export interface GetMovables_movables_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: GetMovables_movables_placement_location;
}

export interface GetMovables_movables_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
}

export interface GetMovables_movables_changes_before_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface GetMovables_movables_changes_before {
  /**
   * Description of movable
   */
  description: string | null;
  /**
   * Movable volume in m3
   */
  volume: GQL_Decimal | null;
  /**
   * Movable completed indicator
   */
  completed: boolean | null;
  /**
   * Movable status
   */
  status: string | null;
  /**
   * Movable placement
   */
  placement: GetMovables_movables_changes_before_placement | null;
}

export interface GetMovables_movables_changes_after_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface GetMovables_movables_changes_after {
  /**
   * Description of movable
   */
  description: string | null;
  /**
   * Movable volume in m3
   */
  volume: GQL_Decimal | null;
  /**
   * Movable completed indicator
   */
  completed: boolean | null;
  /**
   * Movable status
   */
  status: string | null;
  /**
   * Movable placement
   */
  placement: GetMovables_movables_changes_after_placement | null;
}

export interface GetMovables_movables_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: GetMovables_movables_changes_user;
  /**
   * Value before
   */
  before: GetMovables_movables_changes_before;
  /**
   * Value after
   */
  after: GetMovables_movables_changes_after;
}

export interface GetMovables_movables {
  /**
   * Unique id of movable
   */
  id: string;
  /**
   * Description of movable
   */
  description: string;
  /**
   * Movable volume in m3
   */
  volume: GQL_Decimal;
  /**
   * Case which movable is attached to
   */
  case: GetMovables_movables_case;
  /**
   * Movable completed indicator
   */
  completed: boolean;
  /**
   * Movable status
   */
  status: string;
  /**
   * Movable placement
   */
  placement: GetMovables_movables_placement;
  /**
   * Movable change version number
   */
  version: number;
  /**
   * Movable change log
   */
  changes: GetMovables_movables_changes[];
}

export interface GetMovables {
  movables: GetMovables_movables[];
}

export interface GetMovablesVariables {
  case?: string | null;
  status?: string | null;
  location?: string | null;
  debitors?: string[] | null;
  includeCompleted?: boolean | null;
  fromDate?: GQL_Date | null;
  toDate?: GQL_Date | null;
  offset?: number | null;
  limit?: number | null;
  searchString?: string | null;
  excludeTotalCount?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMovablesOverviewExcel
// ====================================================

export interface GetMovablesOverviewExcel {
  movableExcelOverview: GQL_Byte[];
}

export interface GetMovablesOverviewExcelVariables {
  caseId: string;
  includeCompleted?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMovableStatusOptions
// ====================================================

export interface GetMovableStatusOptions {
  movableStatusOptions: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateMovable
// ====================================================

export interface UpdateMovable_updateMovable_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
}

export interface UpdateMovable_updateMovable_placement_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateMovable_updateMovable_placement_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: UpdateMovable_updateMovable_placement_location_address;
}

export interface UpdateMovable_updateMovable_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: UpdateMovable_updateMovable_placement_location;
}

export interface UpdateMovable_updateMovable_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
}

export interface UpdateMovable_updateMovable_changes_before_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface UpdateMovable_updateMovable_changes_before {
  /**
   * Description of movable
   */
  description: string | null;
  /**
   * Movable volume in m3
   */
  volume: GQL_Decimal | null;
  /**
   * Movable completed indicator
   */
  completed: boolean | null;
  /**
   * Movable status
   */
  status: string | null;
  /**
   * Movable placement
   */
  placement: UpdateMovable_updateMovable_changes_before_placement | null;
}

export interface UpdateMovable_updateMovable_changes_after_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface UpdateMovable_updateMovable_changes_after {
  /**
   * Description of movable
   */
  description: string | null;
  /**
   * Movable volume in m3
   */
  volume: GQL_Decimal | null;
  /**
   * Movable completed indicator
   */
  completed: boolean | null;
  /**
   * Movable status
   */
  status: string | null;
  /**
   * Movable placement
   */
  placement: UpdateMovable_updateMovable_changes_after_placement | null;
}

export interface UpdateMovable_updateMovable_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: UpdateMovable_updateMovable_changes_user;
  /**
   * Value before
   */
  before: UpdateMovable_updateMovable_changes_before;
  /**
   * Value after
   */
  after: UpdateMovable_updateMovable_changes_after;
}

export interface UpdateMovable_updateMovable {
  /**
   * Unique id of movable
   */
  id: string;
  /**
   * Description of movable
   */
  description: string;
  /**
   * Movable volume in m3
   */
  volume: GQL_Decimal;
  /**
   * Case which movable is attached to
   */
  case: UpdateMovable_updateMovable_case;
  /**
   * Movable completed indicator
   */
  completed: boolean;
  /**
   * Movable status
   */
  status: string;
  /**
   * Movable placement
   */
  placement: UpdateMovable_updateMovable_placement;
  /**
   * Movable change version number
   */
  version: number;
  /**
   * Movable change log
   */
  changes: UpdateMovable_updateMovable_changes[];
}

export interface UpdateMovable {
  updateMovable: UpdateMovable_updateMovable;
}

export interface UpdateMovableVariables {
  id: string;
  description?: string | null;
  completed?: boolean | null;
  status?: string | null;
  placement?: string | null;
  volume?: GQL_Decimal | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeOfferStatus
// ====================================================

export interface ChangeOfferStatus {
  changeOfferStatus: string | null;
}

export interface ChangeOfferStatusVariables {
  caseERPReferenceNo: string;
  offerERPReferenceNo: string;
  reasonCode: string;
  action: SalesHeaderEnum;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Assortments
// ====================================================

export interface Assortments_assortments {
  /**
   * item no
   */
  no: string;
  /**
   * Type of assortment
   */
  type: string;
  /**
   * description
   */
  description: string;
  /**
   * Base unit of messure
   */
  uom: string;
}

export interface Assortments {
  assortments: Assortments_assortments[] | null;
}

export interface AssortmentsVariables {
  assortmentCode: string;
  type: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CloseOppertunityCodes
// ====================================================

export interface CloseOppertunityCodes_closeOppertunityCodes {
  /**
   * Type
   */
  type: string;
  /**
   * description
   */
  description: string;
  /**
   * Code
   */
  code: string;
}

export interface CloseOppertunityCodes {
  closeOppertunityCodes: CloseOppertunityCodes_closeOppertunityCodes[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Items
// ====================================================

export interface Items_items {
  /**
   * item no
   */
  no: string;
  /**
   * description
   */
  description: string;
  /**
   * line type
   */
  type: string;
  /**
   * Base unit of messure
   */
  baseUOM: string;
}

export interface Items {
  items: Items_items[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JobTasks
// ====================================================

export interface JobTasks_jobTasks {
  /**
   * case no
   */
  erpRefrenceNo: string;
  /**
   * JobTask No
   */
  jobTaskNo: string;
  /**
   * Description
   */
  description: string;
  /**
   * Price of the JobTask
   */
  price: GQL_Decimal;
  /**
   * Work type for the job task
   */
  workTypeCode: string;
  /**
   * Exclude In the enviromental fee
   */
  excludeInEnvFee: boolean | null;
}

export interface JobTasks {
  jobTasks: (JobTasks_jobTasks | null)[] | null;
}

export interface JobTasksVariables {
  erpReferenceNo: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Offers
// ====================================================

export interface Offers_offers_lines {
  /**
   * document nr / e.g Screening
   */
  documentNo: string;
  /**
   * Item / resource reference id
   */
  no: string;
  /**
   * Date of planning
   */
  planningDate: string;
  /**
   * Description
   */
  description: string;
  /**
   * Quantity
   */
  quantity: GQL_Decimal;
  /**
   * Price of unit
   */
  unitPrice: GQL_Decimal;
  /**
   * new Price of unit
   */
  newUnitPrice: GQL_Decimal | null;
  /**
   * Line amount
   */
  lineAmount: GQL_Decimal;
  /**
   * Currencycode
   */
  currencyCode: string | null;
  /**
   * Type of work
   */
  workType: string | null;
  /**
   * Id of entity
   */
  systemId: string | null;
  /**
   * ERP Reference task
   */
  eRPReferenceTask: string;
  /**
   * Assortment type
   */
  type: OfferRessourceType;
  /**
   * option
   */
  option: boolean;
  /**
   * hideSum
   */
  hideSum: boolean;
  /**
   * is line a header
   */
  header: boolean;
  /**
   * Refrence id to line in screening template
   */
  screeningLineId: string | null;
  /**
   * Sorting index (header and lines are grouped by sorting index)
   */
  sortingIndex: number;
  /**
   * Line number
   */
  lineNo: number;
  /**
   * Unit Cost
   */
  unitCost: GQL_Decimal;
}

export interface Offers_offers {
  /**
   * BC job number
   */
  jobNo: string;
  /**
   * Offer lines
   */
  lines: Offers_offers_lines[];
  /**
   * current status on Quote
   */
  opportunityStatus: string | null;
  /**
   * header text
   */
  headerText: string | null;
  /**
   * footer text
   */
  footerText: string | null;
  /**
   * Quote Type
   */
  quoteType: OfferQuoteTypeEnum | null;
  /**
   * is offer competition offer
   */
  competitionOffer: boolean;
}

export interface Offers {
  offers: Offers_offers[] | null;
}

export interface OffersVariables {
  caseERPReferenceNo: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Resources
// ====================================================

export interface Resources_resources {
  /**
   * Resource No
   */
  no: string;
  /**
   * Resource Name
   */
  name: string;
  /**
   * Resource type
   */
  type: string;
  /**
   * baseUOM e.g Time 
   */
  baseUOM: string;
}

export interface Resources {
  resources: Resources_resources[] | null;
}

export interface ResourcesVariables {
  ressourceType: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSamplePriceForLine
// ====================================================

export interface GetSamplePriceForLine_samplePriceForLine {
  /**
   * Total cost
   */
  totalCost: GQL_Decimal;
  /**
   * Total line amount
   */
  lineAmount: GQL_Decimal;
}

export interface GetSamplePriceForLine {
  samplePriceForLine: GetSamplePriceForLine_samplePriceForLine;
}

export interface GetSamplePriceForLineVariables {
  offerLine: OfferLineInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSamplePriceForOffer
// ====================================================

export interface GetSamplePriceForOffer_samplePriceForOffer {
  /**
   * Total cost
   */
  totalCost: GQL_Decimal;
  /**
   * Total line amount
   */
  lineAmount: GQL_Decimal;
}

export interface GetSamplePriceForOffer {
  samplePriceForOffer: GetSamplePriceForOffer_samplePriceForOffer;
}

export interface GetSamplePriceForOfferVariables {
  offer: OfferInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: WorkTypes
// ====================================================

export interface WorkTypes_workTypes {
  /**
   * System id
   */
  systemId: string | null;
  /**
   * description
   */
  description: string;
  /**
   * code
   */
  code: string;
  /**
   * Unit of messure
   */
  uOM: string;
}

export interface WorkTypes {
  workTypes: WorkTypes_workTypes[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: HandleOffer
// ====================================================

export interface HandleOffer_handleOffer_lines {
  /**
   * document nr / e.g Screening
   */
  documentNo: string;
  /**
   * Item / resource reference id
   */
  no: string;
  /**
   * Date of planning
   */
  planningDate: string;
  /**
   * Description
   */
  description: string;
  /**
   * Quantity
   */
  quantity: GQL_Decimal;
  /**
   * Price of unit
   */
  unitPrice: GQL_Decimal;
  /**
   * new Price of unit
   */
  newUnitPrice: GQL_Decimal | null;
  /**
   * Line amount
   */
  lineAmount: GQL_Decimal;
  /**
   * Currencycode
   */
  currencyCode: string | null;
  /**
   * Type of work
   */
  workType: string | null;
  /**
   * Id of entity
   */
  systemId: string | null;
  /**
   * ERP Reference task
   */
  eRPReferenceTask: string;
  /**
   * Assortment type
   */
  type: OfferRessourceType;
  /**
   * option
   */
  option: boolean;
  /**
   * hideSum
   */
  hideSum: boolean;
  /**
   * is line a header
   */
  header: boolean;
  /**
   * Refrence id to line in screening template
   */
  screeningLineId: string | null;
  /**
   * Sorting index (header and lines are grouped by sorting index)
   */
  sortingIndex: number;
  /**
   * Line number
   */
  lineNo: number;
  /**
   * Unit Cost
   */
  unitCost: GQL_Decimal;
}

export interface HandleOffer_handleOffer {
  /**
   * BC job number
   */
  jobNo: string;
  /**
   * Offer lines
   */
  lines: HandleOffer_handleOffer_lines[];
  /**
   * current status on Quote
   */
  opportunityStatus: string | null;
  /**
   * header text
   */
  headerText: string | null;
  /**
   * footer text
   */
  footerText: string | null;
  /**
   * Quote Type
   */
  quoteType: OfferQuoteTypeEnum | null;
  /**
   * is offer competition offer
   */
  competitionOffer: boolean;
}

export interface HandleOffer {
  handleOffer: HandleOffer_handleOffer;
}

export interface HandleOfferVariables {
  offer: OfferInput;
  caseId: string;
  offerERPReferenceNo?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeCarOnCalendarEvent
// ====================================================

export interface ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_timeRange {
  /**
   * From date
   */
  from: GQL_DateTime;
  /**
   * To date
   */
  to: GQL_DateTime;
}

export interface ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_changes_user;
  /**
   * Value before
   */
  before: ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_changes_before;
  /**
   * Value after
   */
  after: ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_changes_after;
}

export interface ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_case_damage_contact_address;
}

export interface ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_case_damage_cause_departments_location;
}

export interface ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_case_damage {
  /**
   * Damage location contact information
   */
  contact: ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_case_damage_contact;
  /**
   * Damage category
   */
  category: ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_case_damage_cause;
}

export interface ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_case_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_case_damage;
  /**
   * Project manager attached to case
   */
  projectManager: ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_case_projectManager | null;
}

export interface ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_changes[];
  /**
   * Location of driving slip
   */
  location: ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_location;
  /**
   * Department of driving slip
   */
  department: ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
  /**
   * Case which driving slip is related to
   */
  case: ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip_case;
}

export interface ChangeCarOnCalendarEvent_changeCarOnCalendarEvent {
  /**
   * Unique calendar reference id
   */
  referenceId: string;
  /**
   * Calendar event title
   */
  title: string;
  /**
   * Type of calendar event
   */
  type: CalendarEventType;
  /**
   * Calendar event time range
   */
  timeRange: ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_timeRange;
  /**
   * Calendar event time range
   */
  isAllDay: boolean;
  /**
   * Indicates if calendar event was created through 5C
   */
  systemCreatedEvent: boolean;
  /**
   * Optional unique driving slip id
   */
  drivingSlip: ChangeCarOnCalendarEvent_changeCarOnCalendarEvent_drivingSlip | null;
  __typename: "CalendarEvent";
}

export interface ChangeCarOnCalendarEvent {
  changeCarOnCalendarEvent: ChangeCarOnCalendarEvent_changeCarOnCalendarEvent;
}

export interface ChangeCarOnCalendarEventVariables {
  carId: string;
  event: CalendarEventInput;
  originalEventId: string;
  originalCarId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeUserOnCalendarEvent
// ====================================================

export interface ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_timeRange {
  /**
   * From date
   */
  from: GQL_DateTime;
  /**
   * To date
   */
  to: GQL_DateTime;
}

export interface ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_changes_user;
  /**
   * Value before
   */
  before: ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_changes_before;
  /**
   * Value after
   */
  after: ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_changes_after;
}

export interface ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_contact_address;
}

export interface ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_cause_departments_location;
}

export interface ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_case_damage {
  /**
   * Damage location contact information
   */
  contact: ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_contact;
  /**
   * Damage category
   */
  category: ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_cause;
}

export interface ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_case_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_case_damage;
  /**
   * Project manager attached to case
   */
  projectManager: ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_case_projectManager | null;
}

export interface ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_changes[];
  /**
   * Location of driving slip
   */
  location: ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_location;
  /**
   * Department of driving slip
   */
  department: ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
  /**
   * Case which driving slip is related to
   */
  case: ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip_case;
}

export interface ChangeUserOnCalendarEvent_changeUserOnCalendarEvent {
  /**
   * Unique calendar reference id
   */
  referenceId: string;
  /**
   * Calendar event title
   */
  title: string;
  /**
   * Type of calendar event
   */
  type: CalendarEventType;
  /**
   * Calendar event time range
   */
  timeRange: ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_timeRange;
  /**
   * Calendar event time range
   */
  isAllDay: boolean;
  /**
   * Indicates if calendar event was created through 5C
   */
  systemCreatedEvent: boolean;
  /**
   * Optional unique driving slip id
   */
  drivingSlip: ChangeUserOnCalendarEvent_changeUserOnCalendarEvent_drivingSlip | null;
  __typename: "CalendarEvent";
}

export interface ChangeUserOnCalendarEvent {
  changeUserOnCalendarEvent: ChangeUserOnCalendarEvent_changeUserOnCalendarEvent;
}

export interface ChangeUserOnCalendarEventVariables {
  userId: string;
  event: CalendarEventInput;
  originalEventId: string;
  originalUserId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCarCalendarEvent
// ====================================================

export interface CreateCarCalendarEvent_createCarCalendarEvent_timeRange {
  /**
   * From date
   */
  from: GQL_DateTime;
  /**
   * To date
   */
  to: GQL_DateTime;
}

export interface CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_changes_user;
  /**
   * Value before
   */
  before: CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_changes_before;
  /**
   * Value after
   */
  after: CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_changes_after;
}

export interface CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_case_damage_contact_address;
}

export interface CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_case_damage_cause_departments_location;
}

export interface CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_case_damage {
  /**
   * Damage location contact information
   */
  contact: CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_case_damage_contact;
  /**
   * Damage category
   */
  category: CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_case_damage_cause;
}

export interface CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_case_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_case_damage;
  /**
   * Project manager attached to case
   */
  projectManager: CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_case_projectManager | null;
}

export interface CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_changes[];
  /**
   * Location of driving slip
   */
  location: CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_location;
  /**
   * Department of driving slip
   */
  department: CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
  /**
   * Case which driving slip is related to
   */
  case: CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip_case;
}

export interface CreateCarCalendarEvent_createCarCalendarEvent {
  /**
   * Unique calendar reference id
   */
  referenceId: string;
  /**
   * Calendar event title
   */
  title: string;
  /**
   * Type of calendar event
   */
  type: CalendarEventType;
  /**
   * Calendar event time range
   */
  timeRange: CreateCarCalendarEvent_createCarCalendarEvent_timeRange;
  /**
   * Calendar event time range
   */
  isAllDay: boolean;
  /**
   * Indicates if calendar event was created through 5C
   */
  systemCreatedEvent: boolean;
  /**
   * Optional unique driving slip id
   */
  drivingSlip: CreateCarCalendarEvent_createCarCalendarEvent_drivingSlip | null;
  __typename: "CalendarEvent";
}

export interface CreateCarCalendarEvent {
  createCarCalendarEvent: CreateCarCalendarEvent_createCarCalendarEvent;
}

export interface CreateCarCalendarEventVariables {
  carId: string;
  event: CalendarEventInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateUserCalendarEvent
// ====================================================

export interface CreateUserCalendarEvent_createUserCalendarEvent_timeRange {
  /**
   * From date
   */
  from: GQL_DateTime;
  /**
   * To date
   */
  to: GQL_DateTime;
}

export interface CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_changes_user;
  /**
   * Value before
   */
  before: CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_changes_before;
  /**
   * Value after
   */
  after: CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_changes_after;
}

export interface CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_contact_address;
}

export interface CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_cause_departments_location;
}

export interface CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage {
  /**
   * Damage location contact information
   */
  contact: CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_contact;
  /**
   * Damage category
   */
  category: CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_cause;
}

export interface CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_case_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage;
  /**
   * Project manager attached to case
   */
  projectManager: CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_case_projectManager | null;
}

export interface CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_changes[];
  /**
   * Location of driving slip
   */
  location: CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_location;
  /**
   * Department of driving slip
   */
  department: CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
  /**
   * Case which driving slip is related to
   */
  case: CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip_case;
}

export interface CreateUserCalendarEvent_createUserCalendarEvent {
  /**
   * Unique calendar reference id
   */
  referenceId: string;
  /**
   * Calendar event title
   */
  title: string;
  /**
   * Type of calendar event
   */
  type: CalendarEventType;
  /**
   * Calendar event time range
   */
  timeRange: CreateUserCalendarEvent_createUserCalendarEvent_timeRange;
  /**
   * Calendar event time range
   */
  isAllDay: boolean;
  /**
   * Indicates if calendar event was created through 5C
   */
  systemCreatedEvent: boolean;
  /**
   * Optional unique driving slip id
   */
  drivingSlip: CreateUserCalendarEvent_createUserCalendarEvent_drivingSlip | null;
  __typename: "CalendarEvent";
}

export interface CreateUserCalendarEvent {
  createUserCalendarEvent: CreateUserCalendarEvent_createUserCalendarEvent;
}

export interface CreateUserCalendarEventVariables {
  userId: string;
  event: CalendarEventInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteCarCalendarEvent
// ====================================================

export interface DeleteCarCalendarEvent_deleteCarCalendarEvent_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface DeleteCarCalendarEvent_deleteCarCalendarEvent_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface DeleteCarCalendarEvent_deleteCarCalendarEvent_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface DeleteCarCalendarEvent_deleteCarCalendarEvent_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface DeleteCarCalendarEvent_deleteCarCalendarEvent_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: DeleteCarCalendarEvent_deleteCarCalendarEvent_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface DeleteCarCalendarEvent_deleteCarCalendarEvent_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: DeleteCarCalendarEvent_deleteCarCalendarEvent_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface DeleteCarCalendarEvent_deleteCarCalendarEvent_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: DeleteCarCalendarEvent_deleteCarCalendarEvent_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface DeleteCarCalendarEvent_deleteCarCalendarEvent_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface DeleteCarCalendarEvent_deleteCarCalendarEvent_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface DeleteCarCalendarEvent_deleteCarCalendarEvent_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface DeleteCarCalendarEvent_deleteCarCalendarEvent_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface DeleteCarCalendarEvent_deleteCarCalendarEvent_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: DeleteCarCalendarEvent_deleteCarCalendarEvent_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: DeleteCarCalendarEvent_deleteCarCalendarEvent_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: DeleteCarCalendarEvent_deleteCarCalendarEvent_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface DeleteCarCalendarEvent_deleteCarCalendarEvent_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface DeleteCarCalendarEvent_deleteCarCalendarEvent_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface DeleteCarCalendarEvent_deleteCarCalendarEvent_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface DeleteCarCalendarEvent_deleteCarCalendarEvent_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: DeleteCarCalendarEvent_deleteCarCalendarEvent_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: DeleteCarCalendarEvent_deleteCarCalendarEvent_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: DeleteCarCalendarEvent_deleteCarCalendarEvent_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface DeleteCarCalendarEvent_deleteCarCalendarEvent_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: DeleteCarCalendarEvent_deleteCarCalendarEvent_changes_user;
  /**
   * Value before
   */
  before: DeleteCarCalendarEvent_deleteCarCalendarEvent_changes_before;
  /**
   * Value after
   */
  after: DeleteCarCalendarEvent_deleteCarCalendarEvent_changes_after;
}

export interface DeleteCarCalendarEvent_deleteCarCalendarEvent_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface DeleteCarCalendarEvent_deleteCarCalendarEvent_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface DeleteCarCalendarEvent_deleteCarCalendarEvent {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: DeleteCarCalendarEvent_deleteCarCalendarEvent_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: DeleteCarCalendarEvent_deleteCarCalendarEvent_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: DeleteCarCalendarEvent_deleteCarCalendarEvent_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: DeleteCarCalendarEvent_deleteCarCalendarEvent_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: DeleteCarCalendarEvent_deleteCarCalendarEvent_changes[];
  /**
   * Location of driving slip
   */
  location: DeleteCarCalendarEvent_deleteCarCalendarEvent_location;
  /**
   * Department of driving slip
   */
  department: DeleteCarCalendarEvent_deleteCarCalendarEvent_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
}

export interface DeleteCarCalendarEvent {
  deleteCarCalendarEvent: DeleteCarCalendarEvent_deleteCarCalendarEvent | null;
}

export interface DeleteCarCalendarEventVariables {
  carId: string;
  eventId: string;
  drivingSlipId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteUserCalendarEvent
// ====================================================

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: DeleteUserCalendarEvent_deleteUserCalendarEvent_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: DeleteUserCalendarEvent_deleteUserCalendarEvent_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: DeleteUserCalendarEvent_deleteUserCalendarEvent_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: DeleteUserCalendarEvent_deleteUserCalendarEvent_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: DeleteUserCalendarEvent_deleteUserCalendarEvent_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: DeleteUserCalendarEvent_deleteUserCalendarEvent_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: DeleteUserCalendarEvent_deleteUserCalendarEvent_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: DeleteUserCalendarEvent_deleteUserCalendarEvent_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: DeleteUserCalendarEvent_deleteUserCalendarEvent_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: DeleteUserCalendarEvent_deleteUserCalendarEvent_changes_user;
  /**
   * Value before
   */
  before: DeleteUserCalendarEvent_deleteUserCalendarEvent_changes_before;
  /**
   * Value after
   */
  after: DeleteUserCalendarEvent_deleteUserCalendarEvent_changes_after;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_case_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_case_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_case_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: DeleteUserCalendarEvent_deleteUserCalendarEvent_case_debitor_shippingAddress_address;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_case_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: DeleteUserCalendarEvent_deleteUserCalendarEvent_case_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: DeleteUserCalendarEvent_deleteUserCalendarEvent_case_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: DeleteUserCalendarEvent_deleteUserCalendarEvent_case_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: DeleteUserCalendarEvent_deleteUserCalendarEvent_case_damage_contact_address;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: DeleteUserCalendarEvent_deleteUserCalendarEvent_case_damage_cause_departments_location;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: DeleteUserCalendarEvent_deleteUserCalendarEvent_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: DeleteUserCalendarEvent_deleteUserCalendarEvent_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (DeleteUserCalendarEvent_deleteUserCalendarEvent_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_case_damage {
  /**
   * Damage location contact information
   */
  contact: DeleteUserCalendarEvent_deleteUserCalendarEvent_case_damage_contact;
  /**
   * Damage category
   */
  category: DeleteUserCalendarEvent_deleteUserCalendarEvent_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: DeleteUserCalendarEvent_deleteUserCalendarEvent_case_damage_cause;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_case_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_case_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: DeleteUserCalendarEvent_deleteUserCalendarEvent_case_policyHolder_address;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_case_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_case_caseManager {
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_case_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_case_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: DeleteUserCalendarEvent_deleteUserCalendarEvent_case_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_case_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_case_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: DeleteUserCalendarEvent_deleteUserCalendarEvent_case_ssgDepartment_location_address;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_case_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: DeleteUserCalendarEvent_deleteUserCalendarEvent_case_ssgDepartment_location;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_case_riskEvaluationAnswers_question {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question
   */
  question: string;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_case_riskEvaluationAnswers {
  /**
   * Id of question answer is linked to
   */
  question: DeleteUserCalendarEvent_deleteUserCalendarEvent_case_riskEvaluationAnswers_question;
  /**
   * Answer given
   */
  answer: boolean;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_case_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_case_visitation_awaiting {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_case_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
  /**
   * The question
   */
  title: string;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_case_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: DeleteUserCalendarEvent_deleteUserCalendarEvent_case_visitation_calledBack;
  /**
   * If case is awaiting
   */
  awaiting: DeleteUserCalendarEvent_deleteUserCalendarEvent_case_visitation_awaiting;
  /**
   * A set of answers related to the damage cause
   */
  answers: DeleteUserCalendarEvent_deleteUserCalendarEvent_case_visitation_answers[] | null;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * Case debitor details
   */
  debitor: DeleteUserCalendarEvent_deleteUserCalendarEvent_case_debitor;
  /**
   * Case damage details
   */
  damage: DeleteUserCalendarEvent_deleteUserCalendarEvent_case_damage;
  /**
   * Case policy holder details
   */
  policyHolder: DeleteUserCalendarEvent_deleteUserCalendarEvent_case_policyHolder;
  /**
   * Project manager attached to case
   */
  projectManager: DeleteUserCalendarEvent_deleteUserCalendarEvent_case_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: DeleteUserCalendarEvent_deleteUserCalendarEvent_case_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: DeleteUserCalendarEvent_deleteUserCalendarEvent_case_ssgLocation;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: DeleteUserCalendarEvent_deleteUserCalendarEvent_case_ssgDepartment;
  /**
   * Risk evaluation answers
   */
  riskEvaluationAnswers: DeleteUserCalendarEvent_deleteUserCalendarEvent_case_riskEvaluationAnswers[] | null;
  /**
   * Optional comment for risk evaluation
   */
  riskEvaluationComment: string | null;
  /**
   * Case visitation details
   */
  visitation: DeleteUserCalendarEvent_deleteUserCalendarEvent_case_visitation;
  /**
   * Whether time registration is allowed on the case
   */
  timeRegistration: boolean | null;
}

export interface DeleteUserCalendarEvent_deleteUserCalendarEvent {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: DeleteUserCalendarEvent_deleteUserCalendarEvent_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: DeleteUserCalendarEvent_deleteUserCalendarEvent_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: DeleteUserCalendarEvent_deleteUserCalendarEvent_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: DeleteUserCalendarEvent_deleteUserCalendarEvent_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: DeleteUserCalendarEvent_deleteUserCalendarEvent_changes[];
  /**
   * Location of driving slip
   */
  location: DeleteUserCalendarEvent_deleteUserCalendarEvent_location;
  /**
   * Department of driving slip
   */
  department: DeleteUserCalendarEvent_deleteUserCalendarEvent_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
  /**
   * Case which driving slip is related to
   */
  case: DeleteUserCalendarEvent_deleteUserCalendarEvent_case;
}

export interface DeleteUserCalendarEvent {
  deleteUserCalendarEvent: DeleteUserCalendarEvent_deleteUserCalendarEvent | null;
}

export interface DeleteUserCalendarEventVariables {
  userId: string;
  eventId: string;
  drivingSlipId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCarCalendars
// ====================================================

export interface GetCarCalendars_carCalendars_car {
  /**
   * Unique id of car
   */
  id: string;
  /**
   * Name of car
   */
  name: string;
  /**
   * Department car is attached to
   */
  department: string | null;
  /**
   * Email of car
   */
  email: string;
}

export interface GetCarCalendars_carCalendars_events_timeRange {
  /**
   * From date
   */
  from: GQL_DateTime;
  /**
   * To date
   */
  to: GQL_DateTime;
}

export interface GetCarCalendars_carCalendars_events_drivingSlip_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetCarCalendars_carCalendars_events_drivingSlip_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetCarCalendars_carCalendars_events_drivingSlip_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetCarCalendars_carCalendars_events_drivingSlip_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface GetCarCalendars_carCalendars_events_drivingSlip_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: GetCarCalendars_carCalendars_events_drivingSlip_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface GetCarCalendars_carCalendars_events_drivingSlip_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: GetCarCalendars_carCalendars_events_drivingSlip_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface GetCarCalendars_carCalendars_events_drivingSlip_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: GetCarCalendars_carCalendars_events_drivingSlip_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface GetCarCalendars_carCalendars_events_drivingSlip_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetCarCalendars_carCalendars_events_drivingSlip_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetCarCalendars_carCalendars_events_drivingSlip_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetCarCalendars_carCalendars_events_drivingSlip_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetCarCalendars_carCalendars_events_drivingSlip_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetCarCalendars_carCalendars_events_drivingSlip_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: GetCarCalendars_carCalendars_events_drivingSlip_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: GetCarCalendars_carCalendars_events_drivingSlip_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface GetCarCalendars_carCalendars_events_drivingSlip_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetCarCalendars_carCalendars_events_drivingSlip_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetCarCalendars_carCalendars_events_drivingSlip_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetCarCalendars_carCalendars_events_drivingSlip_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetCarCalendars_carCalendars_events_drivingSlip_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: GetCarCalendars_carCalendars_events_drivingSlip_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: GetCarCalendars_carCalendars_events_drivingSlip_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface GetCarCalendars_carCalendars_events_drivingSlip_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: GetCarCalendars_carCalendars_events_drivingSlip_changes_user;
  /**
   * Value before
   */
  before: GetCarCalendars_carCalendars_events_drivingSlip_changes_before;
  /**
   * Value after
   */
  after: GetCarCalendars_carCalendars_events_drivingSlip_changes_after;
}

export interface GetCarCalendars_carCalendars_events_drivingSlip_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetCarCalendars_carCalendars_events_drivingSlip_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface GetCarCalendars_carCalendars_events_drivingSlip_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetCarCalendars_carCalendars_events_drivingSlip_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetCarCalendars_carCalendars_events_drivingSlip_case_damage_contact_address;
}

export interface GetCarCalendars_carCalendars_events_drivingSlip_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetCarCalendars_carCalendars_events_drivingSlip_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetCarCalendars_carCalendars_events_drivingSlip_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetCarCalendars_carCalendars_events_drivingSlip_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetCarCalendars_carCalendars_events_drivingSlip_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: GetCarCalendars_carCalendars_events_drivingSlip_case_damage_cause_departments_location;
}

export interface GetCarCalendars_carCalendars_events_drivingSlip_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: GetCarCalendars_carCalendars_events_drivingSlip_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: GetCarCalendars_carCalendars_events_drivingSlip_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (GetCarCalendars_carCalendars_events_drivingSlip_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface GetCarCalendars_carCalendars_events_drivingSlip_case_damage {
  /**
   * Damage location contact information
   */
  contact: GetCarCalendars_carCalendars_events_drivingSlip_case_damage_contact;
  /**
   * Damage category
   */
  category: GetCarCalendars_carCalendars_events_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetCarCalendars_carCalendars_events_drivingSlip_case_damage_cause;
}

export interface GetCarCalendars_carCalendars_events_drivingSlip_case_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetCarCalendars_carCalendars_events_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: GetCarCalendars_carCalendars_events_drivingSlip_case_damage;
  /**
   * Project manager attached to case
   */
  projectManager: GetCarCalendars_carCalendars_events_drivingSlip_case_projectManager | null;
}

export interface GetCarCalendars_carCalendars_events_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: GetCarCalendars_carCalendars_events_drivingSlip_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: GetCarCalendars_carCalendars_events_drivingSlip_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetCarCalendars_carCalendars_events_drivingSlip_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: GetCarCalendars_carCalendars_events_drivingSlip_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: GetCarCalendars_carCalendars_events_drivingSlip_changes[];
  /**
   * Location of driving slip
   */
  location: GetCarCalendars_carCalendars_events_drivingSlip_location;
  /**
   * Department of driving slip
   */
  department: GetCarCalendars_carCalendars_events_drivingSlip_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
  /**
   * Case which driving slip is related to
   */
  case: GetCarCalendars_carCalendars_events_drivingSlip_case;
}

export interface GetCarCalendars_carCalendars_events {
  /**
   * Unique calendar reference id
   */
  referenceId: string;
  /**
   * Calendar event title
   */
  title: string;
  /**
   * Type of calendar event
   */
  type: CalendarEventType;
  /**
   * Calendar event time range
   */
  timeRange: GetCarCalendars_carCalendars_events_timeRange;
  /**
   * Calendar event time range
   */
  isAllDay: boolean;
  /**
   * Indicates if calendar event was created through 5C
   */
  systemCreatedEvent: boolean;
  /**
   * Optional unique driving slip id
   */
  drivingSlip: GetCarCalendars_carCalendars_events_drivingSlip | null;
  __typename: "CalendarEvent";
}

export interface GetCarCalendars_carCalendars {
  /**
   * Car
   */
  car: GetCarCalendars_carCalendars_car;
  /**
   * Car calendar events
   */
  events: GetCarCalendars_carCalendars_events[];
}

export interface GetCarCalendars {
  /**
   * Cars' calendar in specified date range
   */
  carCalendars: GetCarCalendars_carCalendars[];
}

export interface GetCarCalendarsVariables {
  carIdList: string[];
  dateRange: DateRangeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCarsWithCalendars
// ====================================================

export interface GetCarsWithCalendars_carsWithCalendars {
  /**
   * Unique id of car
   */
  id: string;
  /**
   * Name of car
   */
  name: string;
  /**
   * Department car is attached to
   */
  department: string | null;
  /**
   * Email of car
   */
  email: string;
}

export interface GetCarsWithCalendars {
  carsWithCalendars: GetCarsWithCalendars_carsWithCalendars[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserCalendars
// ====================================================

export interface GetUserCalendars_userCalendars_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
  /**
   * User planner help text
   */
  plannerHelpText: string | null;
  /**
   * User planner help text updated at timestamp
   */
  plannerHelpTextUpdatedAt: GQL_DateTime | null;
}

export interface GetUserCalendars_userCalendars_events_timeRange {
  /**
   * From date
   */
  from: GQL_DateTime;
  /**
   * To date
   */
  to: GQL_DateTime;
}

export interface GetUserCalendars_userCalendars_events_drivingSlip_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetUserCalendars_userCalendars_events_drivingSlip_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetUserCalendars_userCalendars_events_drivingSlip_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetUserCalendars_userCalendars_events_drivingSlip_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface GetUserCalendars_userCalendars_events_drivingSlip_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: GetUserCalendars_userCalendars_events_drivingSlip_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface GetUserCalendars_userCalendars_events_drivingSlip_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: GetUserCalendars_userCalendars_events_drivingSlip_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface GetUserCalendars_userCalendars_events_drivingSlip_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: GetUserCalendars_userCalendars_events_drivingSlip_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface GetUserCalendars_userCalendars_events_drivingSlip_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetUserCalendars_userCalendars_events_drivingSlip_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetUserCalendars_userCalendars_events_drivingSlip_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetUserCalendars_userCalendars_events_drivingSlip_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetUserCalendars_userCalendars_events_drivingSlip_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetUserCalendars_userCalendars_events_drivingSlip_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: GetUserCalendars_userCalendars_events_drivingSlip_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: GetUserCalendars_userCalendars_events_drivingSlip_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface GetUserCalendars_userCalendars_events_drivingSlip_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetUserCalendars_userCalendars_events_drivingSlip_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetUserCalendars_userCalendars_events_drivingSlip_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetUserCalendars_userCalendars_events_drivingSlip_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetUserCalendars_userCalendars_events_drivingSlip_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: GetUserCalendars_userCalendars_events_drivingSlip_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: GetUserCalendars_userCalendars_events_drivingSlip_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface GetUserCalendars_userCalendars_events_drivingSlip_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: GetUserCalendars_userCalendars_events_drivingSlip_changes_user;
  /**
   * Value before
   */
  before: GetUserCalendars_userCalendars_events_drivingSlip_changes_before;
  /**
   * Value after
   */
  after: GetUserCalendars_userCalendars_events_drivingSlip_changes_after;
}

export interface GetUserCalendars_userCalendars_events_drivingSlip_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetUserCalendars_userCalendars_events_drivingSlip_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface GetUserCalendars_userCalendars_events_drivingSlip_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetUserCalendars_userCalendars_events_drivingSlip_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetUserCalendars_userCalendars_events_drivingSlip_case_damage_contact_address;
}

export interface GetUserCalendars_userCalendars_events_drivingSlip_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetUserCalendars_userCalendars_events_drivingSlip_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetUserCalendars_userCalendars_events_drivingSlip_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetUserCalendars_userCalendars_events_drivingSlip_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetUserCalendars_userCalendars_events_drivingSlip_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: GetUserCalendars_userCalendars_events_drivingSlip_case_damage_cause_departments_location;
}

export interface GetUserCalendars_userCalendars_events_drivingSlip_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: GetUserCalendars_userCalendars_events_drivingSlip_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: GetUserCalendars_userCalendars_events_drivingSlip_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (GetUserCalendars_userCalendars_events_drivingSlip_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface GetUserCalendars_userCalendars_events_drivingSlip_case_damage {
  /**
   * Damage location contact information
   */
  contact: GetUserCalendars_userCalendars_events_drivingSlip_case_damage_contact;
  /**
   * Damage category
   */
  category: GetUserCalendars_userCalendars_events_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetUserCalendars_userCalendars_events_drivingSlip_case_damage_cause;
}

export interface GetUserCalendars_userCalendars_events_drivingSlip_case_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetUserCalendars_userCalendars_events_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: GetUserCalendars_userCalendars_events_drivingSlip_case_damage;
  /**
   * Project manager attached to case
   */
  projectManager: GetUserCalendars_userCalendars_events_drivingSlip_case_projectManager | null;
}

export interface GetUserCalendars_userCalendars_events_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: GetUserCalendars_userCalendars_events_drivingSlip_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: GetUserCalendars_userCalendars_events_drivingSlip_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetUserCalendars_userCalendars_events_drivingSlip_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: GetUserCalendars_userCalendars_events_drivingSlip_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: GetUserCalendars_userCalendars_events_drivingSlip_changes[];
  /**
   * Location of driving slip
   */
  location: GetUserCalendars_userCalendars_events_drivingSlip_location;
  /**
   * Department of driving slip
   */
  department: GetUserCalendars_userCalendars_events_drivingSlip_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
  /**
   * Case which driving slip is related to
   */
  case: GetUserCalendars_userCalendars_events_drivingSlip_case;
}

export interface GetUserCalendars_userCalendars_events {
  /**
   * Unique calendar reference id
   */
  referenceId: string;
  /**
   * Calendar event title
   */
  title: string;
  /**
   * Type of calendar event
   */
  type: CalendarEventType;
  /**
   * Calendar event time range
   */
  timeRange: GetUserCalendars_userCalendars_events_timeRange;
  /**
   * Calendar event time range
   */
  isAllDay: boolean;
  /**
   * Indicates if calendar event was created through 5C
   */
  systemCreatedEvent: boolean;
  /**
   * Optional unique driving slip id
   */
  drivingSlip: GetUserCalendars_userCalendars_events_drivingSlip | null;
  __typename: "CalendarEvent";
}

export interface GetUserCalendars_userCalendars {
  /**
   * User
   */
  user: GetUserCalendars_userCalendars_user;
  /**
   * User calendar events
   */
  events: GetUserCalendars_userCalendars_events[];
}

export interface GetUserCalendars {
  /**
   * Users' calendar in specified date range
   */
  userCalendars: GetUserCalendars_userCalendars[];
}

export interface GetUserCalendarsVariables {
  userIdList: string[];
  dateRange: DateRangeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUsersWithCalendars
// ====================================================

export interface GetUsersWithCalendars_usersWithCalendars {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
  /**
   * User planner help text
   */
  plannerHelpText: string | null;
  /**
   * User planner help text updated at timestamp
   */
  plannerHelpTextUpdatedAt: GQL_DateTime | null;
}

export interface GetUsersWithCalendars {
  usersWithCalendars: GetUsersWithCalendars_usersWithCalendars[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCarCalendarEvent
// ====================================================

export interface UpdateCarCalendarEvent_updateCarCalendarEvent_timeRange {
  /**
   * From date
   */
  from: GQL_DateTime;
  /**
   * To date
   */
  to: GQL_DateTime;
}

export interface UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_changes_user;
  /**
   * Value before
   */
  before: UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_changes_before;
  /**
   * Value after
   */
  after: UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_changes_after;
}

export interface UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_case_damage_contact_address;
}

export interface UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_case_damage_cause_departments_location;
}

export interface UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_case_damage {
  /**
   * Damage location contact information
   */
  contact: UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_case_damage_contact;
  /**
   * Damage category
   */
  category: UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_case_damage_cause;
}

export interface UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_case_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_case_damage;
  /**
   * Project manager attached to case
   */
  projectManager: UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_case_projectManager | null;
}

export interface UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_changes[];
  /**
   * Location of driving slip
   */
  location: UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_location;
  /**
   * Department of driving slip
   */
  department: UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
  /**
   * Case which driving slip is related to
   */
  case: UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip_case;
}

export interface UpdateCarCalendarEvent_updateCarCalendarEvent {
  /**
   * Unique calendar reference id
   */
  referenceId: string;
  /**
   * Calendar event title
   */
  title: string;
  /**
   * Type of calendar event
   */
  type: CalendarEventType;
  /**
   * Calendar event time range
   */
  timeRange: UpdateCarCalendarEvent_updateCarCalendarEvent_timeRange;
  /**
   * Calendar event time range
   */
  isAllDay: boolean;
  /**
   * Indicates if calendar event was created through 5C
   */
  systemCreatedEvent: boolean;
  /**
   * Optional unique driving slip id
   */
  drivingSlip: UpdateCarCalendarEvent_updateCarCalendarEvent_drivingSlip | null;
  __typename: "CalendarEvent";
}

export interface UpdateCarCalendarEvent {
  updateCarCalendarEvent: UpdateCarCalendarEvent_updateCarCalendarEvent;
}

export interface UpdateCarCalendarEventVariables {
  carId: string;
  eventId: string;
  event: CalendarEventInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUserCalendarEvent
// ====================================================

export interface UpdateUserCalendarEvent_updateUserCalendarEvent_timeRange {
  /**
   * From date
   */
  from: GQL_DateTime;
  /**
   * To date
   */
  to: GQL_DateTime;
}

export interface UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_changes_user;
  /**
   * Value before
   */
  before: UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_changes_before;
  /**
   * Value after
   */
  after: UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_changes_after;
}

export interface UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_contact_address;
}

export interface UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_cause_departments_location;
}

export interface UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage {
  /**
   * Damage location contact information
   */
  contact: UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_contact;
  /**
   * Damage category
   */
  category: UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_cause;
}

export interface UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_case_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage;
  /**
   * Project manager attached to case
   */
  projectManager: UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_case_projectManager | null;
}

export interface UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_changes[];
  /**
   * Location of driving slip
   */
  location: UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_location;
  /**
   * Department of driving slip
   */
  department: UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
  /**
   * Case which driving slip is related to
   */
  case: UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip_case;
}

export interface UpdateUserCalendarEvent_updateUserCalendarEvent {
  /**
   * Unique calendar reference id
   */
  referenceId: string;
  /**
   * Calendar event title
   */
  title: string;
  /**
   * Type of calendar event
   */
  type: CalendarEventType;
  /**
   * Calendar event time range
   */
  timeRange: UpdateUserCalendarEvent_updateUserCalendarEvent_timeRange;
  /**
   * Calendar event time range
   */
  isAllDay: boolean;
  /**
   * Indicates if calendar event was created through 5C
   */
  systemCreatedEvent: boolean;
  /**
   * Optional unique driving slip id
   */
  drivingSlip: UpdateUserCalendarEvent_updateUserCalendarEvent_drivingSlip | null;
  __typename: "CalendarEvent";
}

export interface UpdateUserCalendarEvent {
  updateUserCalendarEvent: UpdateUserCalendarEvent_updateUserCalendarEvent;
}

export interface UpdateUserCalendarEventVariables {
  userId: string;
  eventId: string;
  event: CalendarEventInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddCarOrMaterials
// ====================================================

export interface AddCarOrMaterials_addCarOrMaterials_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface AddCarOrMaterials_addCarOrMaterials_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface AddCarOrMaterials_addCarOrMaterials_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface AddCarOrMaterials_addCarOrMaterials_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface AddCarOrMaterials_addCarOrMaterials_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface AddCarOrMaterials_addCarOrMaterials_case_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface AddCarOrMaterials_addCarOrMaterials_case_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface AddCarOrMaterials_addCarOrMaterials_case_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: AddCarOrMaterials_addCarOrMaterials_case_debitor_shippingAddress_address;
}

export interface AddCarOrMaterials_addCarOrMaterials_case_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface AddCarOrMaterials_addCarOrMaterials_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: AddCarOrMaterials_addCarOrMaterials_case_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: AddCarOrMaterials_addCarOrMaterials_case_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: AddCarOrMaterials_addCarOrMaterials_case_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface AddCarOrMaterials_addCarOrMaterials_case_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface AddCarOrMaterials_addCarOrMaterials_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface AddCarOrMaterials_addCarOrMaterials_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface AddCarOrMaterials_addCarOrMaterials_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface AddCarOrMaterials_addCarOrMaterials_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface AddCarOrMaterials_addCarOrMaterials_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: AddCarOrMaterials_addCarOrMaterials_case_damage_cause_departments_location;
}

export interface AddCarOrMaterials_addCarOrMaterials_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: AddCarOrMaterials_addCarOrMaterials_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: AddCarOrMaterials_addCarOrMaterials_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (AddCarOrMaterials_addCarOrMaterials_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface AddCarOrMaterials_addCarOrMaterials_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface AddCarOrMaterials_addCarOrMaterials_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: AddCarOrMaterials_addCarOrMaterials_case_damage_contact_address;
}

export interface AddCarOrMaterials_addCarOrMaterials_case_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface AddCarOrMaterials_addCarOrMaterials_case_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: AddCarOrMaterials_addCarOrMaterials_case_damage_businessArea;
  /**
   * Damage category
   */
  category: AddCarOrMaterials_addCarOrMaterials_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: AddCarOrMaterials_addCarOrMaterials_case_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: AddCarOrMaterials_addCarOrMaterials_case_damage_contact;
  /**
   * contacts for damage
   */
  contacts: AddCarOrMaterials_addCarOrMaterials_case_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface AddCarOrMaterials_addCarOrMaterials_case_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface AddCarOrMaterials_addCarOrMaterials_case_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface AddCarOrMaterials_addCarOrMaterials_case_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface AddCarOrMaterials_addCarOrMaterials_case_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface AddCarOrMaterials_addCarOrMaterials_case_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: AddCarOrMaterials_addCarOrMaterials_case_policyHolder_address;
}

export interface AddCarOrMaterials_addCarOrMaterials_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * Case debitor details
   */
  debitor: AddCarOrMaterials_addCarOrMaterials_case_debitor;
  /**
   * Case damage details
   */
  damage: AddCarOrMaterials_addCarOrMaterials_case_damage;
  /**
   * Project manager attached to case
   */
  projectManager: AddCarOrMaterials_addCarOrMaterials_case_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: AddCarOrMaterials_addCarOrMaterials_case_caseManager | null;
  /**
   * User who created the case
   */
  createdBy: AddCarOrMaterials_addCarOrMaterials_case_createdBy | null;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * Case policy holder details
   */
  policyHolder: AddCarOrMaterials_addCarOrMaterials_case_policyHolder;
}

export interface AddCarOrMaterials_addCarOrMaterials {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: AddCarOrMaterials_addCarOrMaterials_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: AddCarOrMaterials_addCarOrMaterials_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: AddCarOrMaterials_addCarOrMaterials_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Location of driving slip
   */
  location: AddCarOrMaterials_addCarOrMaterials_location;
  /**
   * Department of driving slip
   */
  department: AddCarOrMaterials_addCarOrMaterials_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Case which driving slip is related to
   */
  case: AddCarOrMaterials_addCarOrMaterials_case;
}

export interface AddCarOrMaterials {
  addCarOrMaterials: AddCarOrMaterials_addCarOrMaterials;
}

export interface AddCarOrMaterialsVariables {
  id: string;
  car?: string | null;
  material?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeUserOnEvent
// ====================================================

export interface ChangeUserOnEvent_changeUserOnCalendarEvent_timeRange {
  /**
   * From date
   */
  from: GQL_DateTime;
  /**
   * To date
   */
  to: GQL_DateTime;
}

export interface ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_debitor_shippingAddress_address;
}

export interface ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_cause_departments_location;
}

export interface ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_contact_address;
}

export interface ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_businessArea;
  /**
   * Damage category
   */
  category: ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_contact;
  /**
   * contacts for damage
   */
  contacts: ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_policyHolder_address;
}

export interface ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * Case debitor details
   */
  debitor: ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_debitor;
  /**
   * Case damage details
   */
  damage: ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_damage;
  /**
   * Project manager attached to case
   */
  projectManager: ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_caseManager | null;
  /**
   * User who created the case
   */
  createdBy: ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_createdBy | null;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * Case policy holder details
   */
  policyHolder: ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case_policyHolder;
}

export interface ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Location of driving slip
   */
  location: ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_location;
  /**
   * Department of driving slip
   */
  department: ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Case which driving slip is related to
   */
  case: ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip_case;
}

export interface ChangeUserOnEvent_changeUserOnCalendarEvent {
  /**
   * Unique calendar reference id
   */
  referenceId: string;
  /**
   * Calendar event title
   */
  title: string;
  /**
   * Type of calendar event
   */
  type: CalendarEventType;
  /**
   * Calendar event time range
   */
  timeRange: ChangeUserOnEvent_changeUserOnCalendarEvent_timeRange;
  /**
   * Calendar event time range
   */
  isAllDay: boolean;
  /**
   * Indicates if calendar event was created through 5C
   */
  systemCreatedEvent: boolean;
  /**
   * Optional unique driving slip id
   */
  drivingSlip: ChangeUserOnEvent_changeUserOnCalendarEvent_drivingSlip | null;
}

export interface ChangeUserOnEvent {
  changeUserOnCalendarEvent: ChangeUserOnEvent_changeUserOnCalendarEvent;
}

export interface ChangeUserOnEventVariables {
  userId: string;
  event: CalendarEventInput;
  originalEventId: string;
  originalUserId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCalendarEvent
// ====================================================

export interface CreateCalendarEvent_createUserCalendarEvent_timeRange {
  /**
   * From date
   */
  from: GQL_DateTime;
  /**
   * To date
   */
  to: GQL_DateTime;
}

export interface CreateCalendarEvent_createUserCalendarEvent_drivingSlip_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateCalendarEvent_createUserCalendarEvent_drivingSlip_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateCalendarEvent_createUserCalendarEvent_drivingSlip_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateCalendarEvent_createUserCalendarEvent_drivingSlip_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface CreateCalendarEvent_createUserCalendarEvent_drivingSlip_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_debitor_shippingAddress_address;
}

export interface CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_cause_departments_location;
}

export interface CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_contact_address;
}

export interface CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_businessArea;
  /**
   * Damage category
   */
  category: CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_contact;
  /**
   * contacts for damage
   */
  contacts: CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_policyHolder_address;
}

export interface CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * Case debitor details
   */
  debitor: CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_debitor;
  /**
   * Case damage details
   */
  damage: CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_damage;
  /**
   * Project manager attached to case
   */
  projectManager: CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_caseManager | null;
  /**
   * User who created the case
   */
  createdBy: CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_createdBy | null;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * Case policy holder details
   */
  policyHolder: CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case_policyHolder;
}

export interface CreateCalendarEvent_createUserCalendarEvent_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: CreateCalendarEvent_createUserCalendarEvent_drivingSlip_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: CreateCalendarEvent_createUserCalendarEvent_drivingSlip_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: CreateCalendarEvent_createUserCalendarEvent_drivingSlip_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Location of driving slip
   */
  location: CreateCalendarEvent_createUserCalendarEvent_drivingSlip_location;
  /**
   * Department of driving slip
   */
  department: CreateCalendarEvent_createUserCalendarEvent_drivingSlip_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Case which driving slip is related to
   */
  case: CreateCalendarEvent_createUserCalendarEvent_drivingSlip_case;
}

export interface CreateCalendarEvent_createUserCalendarEvent {
  /**
   * Unique calendar reference id
   */
  referenceId: string;
  /**
   * Calendar event title
   */
  title: string;
  /**
   * Type of calendar event
   */
  type: CalendarEventType;
  /**
   * Calendar event time range
   */
  timeRange: CreateCalendarEvent_createUserCalendarEvent_timeRange;
  /**
   * Calendar event time range
   */
  isAllDay: boolean;
  /**
   * Indicates if calendar event was created through 5C
   */
  systemCreatedEvent: boolean;
  /**
   * Optional unique driving slip id
   */
  drivingSlip: CreateCalendarEvent_createUserCalendarEvent_drivingSlip | null;
}

export interface CreateCalendarEvent {
  createUserCalendarEvent: CreateCalendarEvent_createUserCalendarEvent;
}

export interface CreateCalendarEventVariables {
  userId: string;
  event: CalendarEventInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateDrivingSlipNewPlanner
// ====================================================

export interface CreateDrivingSlipNewPlanner_createDrivingSlip_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateDrivingSlipNewPlanner_createDrivingSlip_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateDrivingSlipNewPlanner_createDrivingSlip_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateDrivingSlipNewPlanner_createDrivingSlip_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface CreateDrivingSlipNewPlanner_createDrivingSlip_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface CreateDrivingSlipNewPlanner_createDrivingSlip_case_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateDrivingSlipNewPlanner_createDrivingSlip_case_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateDrivingSlipNewPlanner_createDrivingSlip_case_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: CreateDrivingSlipNewPlanner_createDrivingSlip_case_debitor_shippingAddress_address;
}

export interface CreateDrivingSlipNewPlanner_createDrivingSlip_case_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateDrivingSlipNewPlanner_createDrivingSlip_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: CreateDrivingSlipNewPlanner_createDrivingSlip_case_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: CreateDrivingSlipNewPlanner_createDrivingSlip_case_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: CreateDrivingSlipNewPlanner_createDrivingSlip_case_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface CreateDrivingSlipNewPlanner_createDrivingSlip_case_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface CreateDrivingSlipNewPlanner_createDrivingSlip_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface CreateDrivingSlipNewPlanner_createDrivingSlip_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface CreateDrivingSlipNewPlanner_createDrivingSlip_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface CreateDrivingSlipNewPlanner_createDrivingSlip_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface CreateDrivingSlipNewPlanner_createDrivingSlip_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: CreateDrivingSlipNewPlanner_createDrivingSlip_case_damage_cause_departments_location;
}

export interface CreateDrivingSlipNewPlanner_createDrivingSlip_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: CreateDrivingSlipNewPlanner_createDrivingSlip_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: CreateDrivingSlipNewPlanner_createDrivingSlip_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (CreateDrivingSlipNewPlanner_createDrivingSlip_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface CreateDrivingSlipNewPlanner_createDrivingSlip_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateDrivingSlipNewPlanner_createDrivingSlip_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: CreateDrivingSlipNewPlanner_createDrivingSlip_case_damage_contact_address;
}

export interface CreateDrivingSlipNewPlanner_createDrivingSlip_case_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface CreateDrivingSlipNewPlanner_createDrivingSlip_case_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: CreateDrivingSlipNewPlanner_createDrivingSlip_case_damage_businessArea;
  /**
   * Damage category
   */
  category: CreateDrivingSlipNewPlanner_createDrivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: CreateDrivingSlipNewPlanner_createDrivingSlip_case_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: CreateDrivingSlipNewPlanner_createDrivingSlip_case_damage_contact;
  /**
   * contacts for damage
   */
  contacts: CreateDrivingSlipNewPlanner_createDrivingSlip_case_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface CreateDrivingSlipNewPlanner_createDrivingSlip_case_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateDrivingSlipNewPlanner_createDrivingSlip_case_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateDrivingSlipNewPlanner_createDrivingSlip_case_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateDrivingSlipNewPlanner_createDrivingSlip_case_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateDrivingSlipNewPlanner_createDrivingSlip_case_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: CreateDrivingSlipNewPlanner_createDrivingSlip_case_policyHolder_address;
}

export interface CreateDrivingSlipNewPlanner_createDrivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * Case debitor details
   */
  debitor: CreateDrivingSlipNewPlanner_createDrivingSlip_case_debitor;
  /**
   * Case damage details
   */
  damage: CreateDrivingSlipNewPlanner_createDrivingSlip_case_damage;
  /**
   * Project manager attached to case
   */
  projectManager: CreateDrivingSlipNewPlanner_createDrivingSlip_case_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: CreateDrivingSlipNewPlanner_createDrivingSlip_case_caseManager | null;
  /**
   * User who created the case
   */
  createdBy: CreateDrivingSlipNewPlanner_createDrivingSlip_case_createdBy | null;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * Case policy holder details
   */
  policyHolder: CreateDrivingSlipNewPlanner_createDrivingSlip_case_policyHolder;
}

export interface CreateDrivingSlipNewPlanner_createDrivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: CreateDrivingSlipNewPlanner_createDrivingSlip_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: CreateDrivingSlipNewPlanner_createDrivingSlip_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: CreateDrivingSlipNewPlanner_createDrivingSlip_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Location of driving slip
   */
  location: CreateDrivingSlipNewPlanner_createDrivingSlip_location;
  /**
   * Department of driving slip
   */
  department: CreateDrivingSlipNewPlanner_createDrivingSlip_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Case which driving slip is related to
   */
  case: CreateDrivingSlipNewPlanner_createDrivingSlip_case;
}

export interface CreateDrivingSlipNewPlanner {
  createDrivingSlip: CreateDrivingSlipNewPlanner_createDrivingSlip[];
}

export interface CreateDrivingSlipNewPlannerVariables {
  drivingSlip: DrivingSlipHelperInput;
  caseId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteCalendarEvent
// ====================================================

export interface DeleteCalendarEvent_deleteUserCalendarEvent_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface DeleteCalendarEvent_deleteUserCalendarEvent_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface DeleteCalendarEvent_deleteUserCalendarEvent_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface DeleteCalendarEvent_deleteUserCalendarEvent_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface DeleteCalendarEvent_deleteUserCalendarEvent_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface DeleteCalendarEvent_deleteUserCalendarEvent_case_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface DeleteCalendarEvent_deleteUserCalendarEvent_case_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface DeleteCalendarEvent_deleteUserCalendarEvent_case_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: DeleteCalendarEvent_deleteUserCalendarEvent_case_debitor_shippingAddress_address;
}

export interface DeleteCalendarEvent_deleteUserCalendarEvent_case_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface DeleteCalendarEvent_deleteUserCalendarEvent_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: DeleteCalendarEvent_deleteUserCalendarEvent_case_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: DeleteCalendarEvent_deleteUserCalendarEvent_case_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: DeleteCalendarEvent_deleteUserCalendarEvent_case_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface DeleteCalendarEvent_deleteUserCalendarEvent_case_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface DeleteCalendarEvent_deleteUserCalendarEvent_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface DeleteCalendarEvent_deleteUserCalendarEvent_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface DeleteCalendarEvent_deleteUserCalendarEvent_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface DeleteCalendarEvent_deleteUserCalendarEvent_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface DeleteCalendarEvent_deleteUserCalendarEvent_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: DeleteCalendarEvent_deleteUserCalendarEvent_case_damage_cause_departments_location;
}

export interface DeleteCalendarEvent_deleteUserCalendarEvent_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: DeleteCalendarEvent_deleteUserCalendarEvent_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: DeleteCalendarEvent_deleteUserCalendarEvent_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (DeleteCalendarEvent_deleteUserCalendarEvent_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface DeleteCalendarEvent_deleteUserCalendarEvent_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface DeleteCalendarEvent_deleteUserCalendarEvent_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: DeleteCalendarEvent_deleteUserCalendarEvent_case_damage_contact_address;
}

export interface DeleteCalendarEvent_deleteUserCalendarEvent_case_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface DeleteCalendarEvent_deleteUserCalendarEvent_case_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: DeleteCalendarEvent_deleteUserCalendarEvent_case_damage_businessArea;
  /**
   * Damage category
   */
  category: DeleteCalendarEvent_deleteUserCalendarEvent_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: DeleteCalendarEvent_deleteUserCalendarEvent_case_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: DeleteCalendarEvent_deleteUserCalendarEvent_case_damage_contact;
  /**
   * contacts for damage
   */
  contacts: DeleteCalendarEvent_deleteUserCalendarEvent_case_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface DeleteCalendarEvent_deleteUserCalendarEvent_case_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface DeleteCalendarEvent_deleteUserCalendarEvent_case_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface DeleteCalendarEvent_deleteUserCalendarEvent_case_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface DeleteCalendarEvent_deleteUserCalendarEvent_case_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface DeleteCalendarEvent_deleteUserCalendarEvent_case_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: DeleteCalendarEvent_deleteUserCalendarEvent_case_policyHolder_address;
}

export interface DeleteCalendarEvent_deleteUserCalendarEvent_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * Case debitor details
   */
  debitor: DeleteCalendarEvent_deleteUserCalendarEvent_case_debitor;
  /**
   * Case damage details
   */
  damage: DeleteCalendarEvent_deleteUserCalendarEvent_case_damage;
  /**
   * Project manager attached to case
   */
  projectManager: DeleteCalendarEvent_deleteUserCalendarEvent_case_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: DeleteCalendarEvent_deleteUserCalendarEvent_case_caseManager | null;
  /**
   * User who created the case
   */
  createdBy: DeleteCalendarEvent_deleteUserCalendarEvent_case_createdBy | null;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * Case policy holder details
   */
  policyHolder: DeleteCalendarEvent_deleteUserCalendarEvent_case_policyHolder;
}

export interface DeleteCalendarEvent_deleteUserCalendarEvent {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: DeleteCalendarEvent_deleteUserCalendarEvent_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: DeleteCalendarEvent_deleteUserCalendarEvent_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: DeleteCalendarEvent_deleteUserCalendarEvent_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Location of driving slip
   */
  location: DeleteCalendarEvent_deleteUserCalendarEvent_location;
  /**
   * Department of driving slip
   */
  department: DeleteCalendarEvent_deleteUserCalendarEvent_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Case which driving slip is related to
   */
  case: DeleteCalendarEvent_deleteUserCalendarEvent_case;
}

export interface DeleteCalendarEvent {
  deleteUserCalendarEvent: DeleteCalendarEvent_deleteUserCalendarEvent | null;
}

export interface DeleteCalendarEventVariables {
  userId: string;
  eventId: string;
  drivingSlipId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCalendars
// ====================================================

export interface GetCalendars_userCalendars_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
  /**
   * User planner help text
   */
  plannerHelpText: string | null;
  /**
   * User planner help text updated at timestamp
   */
  plannerHelpTextUpdatedAt: GQL_DateTime | null;
}

export interface GetCalendars_userCalendars_events_timeRange {
  /**
   * From date
   */
  from: GQL_DateTime;
  /**
   * To date
   */
  to: GQL_DateTime;
}

export interface GetCalendars_userCalendars_events_drivingSlip_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetCalendars_userCalendars_events_drivingSlip_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetCalendars_userCalendars_events_drivingSlip_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetCalendars_userCalendars_events_drivingSlip_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetCalendars_userCalendars_events_drivingSlip_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface GetCalendars_userCalendars_events_drivingSlip_case_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetCalendars_userCalendars_events_drivingSlip_case_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetCalendars_userCalendars_events_drivingSlip_case_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: GetCalendars_userCalendars_events_drivingSlip_case_debitor_shippingAddress_address;
}

export interface GetCalendars_userCalendars_events_drivingSlip_case_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetCalendars_userCalendars_events_drivingSlip_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: GetCalendars_userCalendars_events_drivingSlip_case_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: GetCalendars_userCalendars_events_drivingSlip_case_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: GetCalendars_userCalendars_events_drivingSlip_case_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface GetCalendars_userCalendars_events_drivingSlip_case_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetCalendars_userCalendars_events_drivingSlip_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetCalendars_userCalendars_events_drivingSlip_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetCalendars_userCalendars_events_drivingSlip_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetCalendars_userCalendars_events_drivingSlip_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetCalendars_userCalendars_events_drivingSlip_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: GetCalendars_userCalendars_events_drivingSlip_case_damage_cause_departments_location;
}

export interface GetCalendars_userCalendars_events_drivingSlip_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: GetCalendars_userCalendars_events_drivingSlip_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: GetCalendars_userCalendars_events_drivingSlip_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (GetCalendars_userCalendars_events_drivingSlip_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface GetCalendars_userCalendars_events_drivingSlip_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetCalendars_userCalendars_events_drivingSlip_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetCalendars_userCalendars_events_drivingSlip_case_damage_contact_address;
}

export interface GetCalendars_userCalendars_events_drivingSlip_case_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface GetCalendars_userCalendars_events_drivingSlip_case_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: GetCalendars_userCalendars_events_drivingSlip_case_damage_businessArea;
  /**
   * Damage category
   */
  category: GetCalendars_userCalendars_events_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetCalendars_userCalendars_events_drivingSlip_case_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: GetCalendars_userCalendars_events_drivingSlip_case_damage_contact;
  /**
   * contacts for damage
   */
  contacts: GetCalendars_userCalendars_events_drivingSlip_case_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface GetCalendars_userCalendars_events_drivingSlip_case_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetCalendars_userCalendars_events_drivingSlip_case_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetCalendars_userCalendars_events_drivingSlip_case_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetCalendars_userCalendars_events_drivingSlip_case_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetCalendars_userCalendars_events_drivingSlip_case_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetCalendars_userCalendars_events_drivingSlip_case_policyHolder_address;
}

export interface GetCalendars_userCalendars_events_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * Case debitor details
   */
  debitor: GetCalendars_userCalendars_events_drivingSlip_case_debitor;
  /**
   * Case damage details
   */
  damage: GetCalendars_userCalendars_events_drivingSlip_case_damage;
  /**
   * Project manager attached to case
   */
  projectManager: GetCalendars_userCalendars_events_drivingSlip_case_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: GetCalendars_userCalendars_events_drivingSlip_case_caseManager | null;
  /**
   * User who created the case
   */
  createdBy: GetCalendars_userCalendars_events_drivingSlip_case_createdBy | null;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * Case policy holder details
   */
  policyHolder: GetCalendars_userCalendars_events_drivingSlip_case_policyHolder;
}

export interface GetCalendars_userCalendars_events_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: GetCalendars_userCalendars_events_drivingSlip_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: GetCalendars_userCalendars_events_drivingSlip_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetCalendars_userCalendars_events_drivingSlip_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Location of driving slip
   */
  location: GetCalendars_userCalendars_events_drivingSlip_location;
  /**
   * Department of driving slip
   */
  department: GetCalendars_userCalendars_events_drivingSlip_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Case which driving slip is related to
   */
  case: GetCalendars_userCalendars_events_drivingSlip_case;
}

export interface GetCalendars_userCalendars_events {
  /**
   * Unique calendar reference id
   */
  referenceId: string;
  /**
   * Calendar event title
   */
  title: string;
  /**
   * Type of calendar event
   */
  type: CalendarEventType;
  /**
   * Calendar event time range
   */
  timeRange: GetCalendars_userCalendars_events_timeRange;
  /**
   * Calendar event time range
   */
  isAllDay: boolean;
  /**
   * Indicates if calendar event was created through 5C
   */
  systemCreatedEvent: boolean;
  /**
   * Optional unique driving slip id
   */
  drivingSlip: GetCalendars_userCalendars_events_drivingSlip | null;
}

export interface GetCalendars_userCalendars {
  /**
   * User
   */
  user: GetCalendars_userCalendars_user;
  /**
   * User calendar events
   */
  events: GetCalendars_userCalendars_events[];
}

export interface GetCalendars {
  /**
   * Users' calendar in specified date range
   */
  userCalendars: GetCalendars_userCalendars[];
}

export interface GetCalendarsVariables {
  userIdList: string[];
  dateRange: DateRangeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDrivingSlipsNewPlanner
// ====================================================

export interface GetDrivingSlipsNewPlanner_plannerDrivingSlips_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetDrivingSlipsNewPlanner_plannerDrivingSlips_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetDrivingSlipsNewPlanner_plannerDrivingSlips_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetDrivingSlipsNewPlanner_plannerDrivingSlips_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetDrivingSlipsNewPlanner_plannerDrivingSlips_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_debitor_shippingAddress_address;
}

export interface GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_damage_cause_departments_location;
}

export interface GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_damage_contact_address;
}

export interface GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_damage_businessArea;
  /**
   * Damage category
   */
  category: GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_damage_contact;
  /**
   * contacts for damage
   */
  contacts: GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_policyHolder_address;
}

export interface GetDrivingSlipsNewPlanner_plannerDrivingSlips_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * Case debitor details
   */
  debitor: GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_debitor;
  /**
   * Case damage details
   */
  damage: GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_damage;
  /**
   * Project manager attached to case
   */
  projectManager: GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_caseManager | null;
  /**
   * User who created the case
   */
  createdBy: GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_createdBy | null;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * Case policy holder details
   */
  policyHolder: GetDrivingSlipsNewPlanner_plannerDrivingSlips_case_policyHolder;
}

export interface GetDrivingSlipsNewPlanner_plannerDrivingSlips {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: GetDrivingSlipsNewPlanner_plannerDrivingSlips_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: GetDrivingSlipsNewPlanner_plannerDrivingSlips_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetDrivingSlipsNewPlanner_plannerDrivingSlips_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Location of driving slip
   */
  location: GetDrivingSlipsNewPlanner_plannerDrivingSlips_location;
  /**
   * Department of driving slip
   */
  department: GetDrivingSlipsNewPlanner_plannerDrivingSlips_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Case which driving slip is related to
   */
  case: GetDrivingSlipsNewPlanner_plannerDrivingSlips_case;
}

export interface GetDrivingSlipsNewPlanner {
  plannerDrivingSlips: GetDrivingSlipsNewPlanner_plannerDrivingSlips[];
}

export interface GetDrivingSlipsNewPlannerVariables {
  personalOnly?: boolean | null;
  dateRange?: DateRangeInput | null;
  status?: DrivingSlipStatus | null;
  urgent?: boolean | null;
  exceeded?: boolean | null;
  departments?: string[] | null;
  locations?: string[] | null;
  damageCauses?: string[] | null;
  damageCategories?: string[] | null;
  drivers?: string[] | null;
  minStartDate?: GQL_Date | null;
  maxStartDate?: GQL_Date | null;
  minEndDate?: GQL_Date | null;
  maxEndDate?: GQL_Date | null;
  noDriver?: boolean | null;
  case?: string | null;
  offset?: number | null;
  limit?: number | null;
  searchString?: string | null;
  excludeTotalCount?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveCarFromDrivingSlip
// ====================================================

export interface RemoveCarFromDrivingSlip_removeCar_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface RemoveCarFromDrivingSlip_removeCar_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface RemoveCarFromDrivingSlip_removeCar_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface RemoveCarFromDrivingSlip_removeCar_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface RemoveCarFromDrivingSlip_removeCar_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface RemoveCarFromDrivingSlip_removeCar_case_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface RemoveCarFromDrivingSlip_removeCar_case_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface RemoveCarFromDrivingSlip_removeCar_case_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: RemoveCarFromDrivingSlip_removeCar_case_debitor_shippingAddress_address;
}

export interface RemoveCarFromDrivingSlip_removeCar_case_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface RemoveCarFromDrivingSlip_removeCar_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: RemoveCarFromDrivingSlip_removeCar_case_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: RemoveCarFromDrivingSlip_removeCar_case_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: RemoveCarFromDrivingSlip_removeCar_case_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface RemoveCarFromDrivingSlip_removeCar_case_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface RemoveCarFromDrivingSlip_removeCar_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface RemoveCarFromDrivingSlip_removeCar_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface RemoveCarFromDrivingSlip_removeCar_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface RemoveCarFromDrivingSlip_removeCar_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface RemoveCarFromDrivingSlip_removeCar_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: RemoveCarFromDrivingSlip_removeCar_case_damage_cause_departments_location;
}

export interface RemoveCarFromDrivingSlip_removeCar_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: RemoveCarFromDrivingSlip_removeCar_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: RemoveCarFromDrivingSlip_removeCar_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (RemoveCarFromDrivingSlip_removeCar_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface RemoveCarFromDrivingSlip_removeCar_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface RemoveCarFromDrivingSlip_removeCar_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: RemoveCarFromDrivingSlip_removeCar_case_damage_contact_address;
}

export interface RemoveCarFromDrivingSlip_removeCar_case_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface RemoveCarFromDrivingSlip_removeCar_case_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: RemoveCarFromDrivingSlip_removeCar_case_damage_businessArea;
  /**
   * Damage category
   */
  category: RemoveCarFromDrivingSlip_removeCar_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: RemoveCarFromDrivingSlip_removeCar_case_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: RemoveCarFromDrivingSlip_removeCar_case_damage_contact;
  /**
   * contacts for damage
   */
  contacts: RemoveCarFromDrivingSlip_removeCar_case_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface RemoveCarFromDrivingSlip_removeCar_case_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface RemoveCarFromDrivingSlip_removeCar_case_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface RemoveCarFromDrivingSlip_removeCar_case_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface RemoveCarFromDrivingSlip_removeCar_case_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface RemoveCarFromDrivingSlip_removeCar_case_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: RemoveCarFromDrivingSlip_removeCar_case_policyHolder_address;
}

export interface RemoveCarFromDrivingSlip_removeCar_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * Case debitor details
   */
  debitor: RemoveCarFromDrivingSlip_removeCar_case_debitor;
  /**
   * Case damage details
   */
  damage: RemoveCarFromDrivingSlip_removeCar_case_damage;
  /**
   * Project manager attached to case
   */
  projectManager: RemoveCarFromDrivingSlip_removeCar_case_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: RemoveCarFromDrivingSlip_removeCar_case_caseManager | null;
  /**
   * User who created the case
   */
  createdBy: RemoveCarFromDrivingSlip_removeCar_case_createdBy | null;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * Case policy holder details
   */
  policyHolder: RemoveCarFromDrivingSlip_removeCar_case_policyHolder;
}

export interface RemoveCarFromDrivingSlip_removeCar {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: RemoveCarFromDrivingSlip_removeCar_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: RemoveCarFromDrivingSlip_removeCar_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: RemoveCarFromDrivingSlip_removeCar_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Location of driving slip
   */
  location: RemoveCarFromDrivingSlip_removeCar_location;
  /**
   * Department of driving slip
   */
  department: RemoveCarFromDrivingSlip_removeCar_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Case which driving slip is related to
   */
  case: RemoveCarFromDrivingSlip_removeCar_case;
}

export interface RemoveCarFromDrivingSlip {
  removeCar: RemoveCarFromDrivingSlip_removeCar;
}

export interface RemoveCarFromDrivingSlipVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveMaterialFromDrivingSlip
// ====================================================

export interface RemoveMaterialFromDrivingSlip_removeMaterial_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface RemoveMaterialFromDrivingSlip_removeMaterial_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface RemoveMaterialFromDrivingSlip_removeMaterial_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface RemoveMaterialFromDrivingSlip_removeMaterial_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface RemoveMaterialFromDrivingSlip_removeMaterial_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface RemoveMaterialFromDrivingSlip_removeMaterial_case_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface RemoveMaterialFromDrivingSlip_removeMaterial_case_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface RemoveMaterialFromDrivingSlip_removeMaterial_case_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: RemoveMaterialFromDrivingSlip_removeMaterial_case_debitor_shippingAddress_address;
}

export interface RemoveMaterialFromDrivingSlip_removeMaterial_case_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface RemoveMaterialFromDrivingSlip_removeMaterial_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: RemoveMaterialFromDrivingSlip_removeMaterial_case_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: RemoveMaterialFromDrivingSlip_removeMaterial_case_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: RemoveMaterialFromDrivingSlip_removeMaterial_case_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface RemoveMaterialFromDrivingSlip_removeMaterial_case_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface RemoveMaterialFromDrivingSlip_removeMaterial_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface RemoveMaterialFromDrivingSlip_removeMaterial_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface RemoveMaterialFromDrivingSlip_removeMaterial_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface RemoveMaterialFromDrivingSlip_removeMaterial_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface RemoveMaterialFromDrivingSlip_removeMaterial_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: RemoveMaterialFromDrivingSlip_removeMaterial_case_damage_cause_departments_location;
}

export interface RemoveMaterialFromDrivingSlip_removeMaterial_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: RemoveMaterialFromDrivingSlip_removeMaterial_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: RemoveMaterialFromDrivingSlip_removeMaterial_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (RemoveMaterialFromDrivingSlip_removeMaterial_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface RemoveMaterialFromDrivingSlip_removeMaterial_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface RemoveMaterialFromDrivingSlip_removeMaterial_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: RemoveMaterialFromDrivingSlip_removeMaterial_case_damage_contact_address;
}

export interface RemoveMaterialFromDrivingSlip_removeMaterial_case_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface RemoveMaterialFromDrivingSlip_removeMaterial_case_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: RemoveMaterialFromDrivingSlip_removeMaterial_case_damage_businessArea;
  /**
   * Damage category
   */
  category: RemoveMaterialFromDrivingSlip_removeMaterial_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: RemoveMaterialFromDrivingSlip_removeMaterial_case_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: RemoveMaterialFromDrivingSlip_removeMaterial_case_damage_contact;
  /**
   * contacts for damage
   */
  contacts: RemoveMaterialFromDrivingSlip_removeMaterial_case_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface RemoveMaterialFromDrivingSlip_removeMaterial_case_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface RemoveMaterialFromDrivingSlip_removeMaterial_case_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface RemoveMaterialFromDrivingSlip_removeMaterial_case_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface RemoveMaterialFromDrivingSlip_removeMaterial_case_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface RemoveMaterialFromDrivingSlip_removeMaterial_case_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: RemoveMaterialFromDrivingSlip_removeMaterial_case_policyHolder_address;
}

export interface RemoveMaterialFromDrivingSlip_removeMaterial_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * Case debitor details
   */
  debitor: RemoveMaterialFromDrivingSlip_removeMaterial_case_debitor;
  /**
   * Case damage details
   */
  damage: RemoveMaterialFromDrivingSlip_removeMaterial_case_damage;
  /**
   * Project manager attached to case
   */
  projectManager: RemoveMaterialFromDrivingSlip_removeMaterial_case_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: RemoveMaterialFromDrivingSlip_removeMaterial_case_caseManager | null;
  /**
   * User who created the case
   */
  createdBy: RemoveMaterialFromDrivingSlip_removeMaterial_case_createdBy | null;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * Case policy holder details
   */
  policyHolder: RemoveMaterialFromDrivingSlip_removeMaterial_case_policyHolder;
}

export interface RemoveMaterialFromDrivingSlip_removeMaterial {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: RemoveMaterialFromDrivingSlip_removeMaterial_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: RemoveMaterialFromDrivingSlip_removeMaterial_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: RemoveMaterialFromDrivingSlip_removeMaterial_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Location of driving slip
   */
  location: RemoveMaterialFromDrivingSlip_removeMaterial_location;
  /**
   * Department of driving slip
   */
  department: RemoveMaterialFromDrivingSlip_removeMaterial_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Case which driving slip is related to
   */
  case: RemoveMaterialFromDrivingSlip_removeMaterial_case;
}

export interface RemoveMaterialFromDrivingSlip {
  removeMaterial: RemoveMaterialFromDrivingSlip_removeMaterial;
}

export interface RemoveMaterialFromDrivingSlipVariables {
  id: string;
  material: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetDrivingSlipStatusNewPlanner
// ====================================================

export interface SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_debitor_shippingAddress_address;
}

export interface SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_damage_cause_departments_location;
}

export interface SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_damage_contact_address;
}

export interface SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_damage_businessArea;
  /**
   * Damage category
   */
  category: SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_damage_contact;
  /**
   * contacts for damage
   */
  contacts: SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_policyHolder_address;
}

export interface SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * Case debitor details
   */
  debitor: SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_debitor;
  /**
   * Case damage details
   */
  damage: SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_damage;
  /**
   * Project manager attached to case
   */
  projectManager: SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_caseManager | null;
  /**
   * User who created the case
   */
  createdBy: SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_createdBy | null;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * Case policy holder details
   */
  policyHolder: SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case_policyHolder;
}

export interface SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Location of driving slip
   */
  location: SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_location;
  /**
   * Department of driving slip
   */
  department: SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Case which driving slip is related to
   */
  case: SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus_case;
}

export interface SetDrivingSlipStatusNewPlanner {
  setDrivingSlipStatus: SetDrivingSlipStatusNewPlanner_setDrivingSlipStatus;
}

export interface SetDrivingSlipStatusNewPlannerVariables {
  id: string;
  status: DrivingSlipStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCalendarEvent
// ====================================================

export interface UpdateCalendarEvent_updateUserCalendarEvent_timeRange {
  /**
   * From date
   */
  from: GQL_DateTime;
  /**
   * To date
   */
  to: GQL_DateTime;
}

export interface UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_debitor_shippingAddress_address;
}

export interface UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_cause_departments_location;
}

export interface UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_contact_address;
}

export interface UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_businessArea;
  /**
   * Damage category
   */
  category: UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_contact;
  /**
   * contacts for damage
   */
  contacts: UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_policyHolder_address;
}

export interface UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * Case debitor details
   */
  debitor: UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_debitor;
  /**
   * Case damage details
   */
  damage: UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_damage;
  /**
   * Project manager attached to case
   */
  projectManager: UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_caseManager | null;
  /**
   * User who created the case
   */
  createdBy: UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_createdBy | null;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * Case policy holder details
   */
  policyHolder: UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case_policyHolder;
}

export interface UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Location of driving slip
   */
  location: UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_location;
  /**
   * Department of driving slip
   */
  department: UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Case which driving slip is related to
   */
  case: UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip_case;
}

export interface UpdateCalendarEvent_updateUserCalendarEvent {
  /**
   * Unique calendar reference id
   */
  referenceId: string;
  /**
   * Calendar event title
   */
  title: string;
  /**
   * Type of calendar event
   */
  type: CalendarEventType;
  /**
   * Calendar event time range
   */
  timeRange: UpdateCalendarEvent_updateUserCalendarEvent_timeRange;
  /**
   * Calendar event time range
   */
  isAllDay: boolean;
  /**
   * Indicates if calendar event was created through 5C
   */
  systemCreatedEvent: boolean;
  /**
   * Optional unique driving slip id
   */
  drivingSlip: UpdateCalendarEvent_updateUserCalendarEvent_drivingSlip | null;
}

export interface UpdateCalendarEvent {
  updateUserCalendarEvent: UpdateCalendarEvent_updateUserCalendarEvent;
}

export interface UpdateCalendarEventVariables {
  userId: string;
  eventId: string;
  event: CalendarEventInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateWebDrivingSlipNewPlanner
// ====================================================

export interface UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_debitor_shippingAddress_address;
}

export interface UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_damage_cause_departments_location;
}

export interface UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_damage_contact_address;
}

export interface UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_damage_businessArea;
  /**
   * Damage category
   */
  category: UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_damage_contact;
  /**
   * contacts for damage
   */
  contacts: UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_policyHolder_address;
}

export interface UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * Case debitor details
   */
  debitor: UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_debitor;
  /**
   * Case damage details
   */
  damage: UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_damage;
  /**
   * Project manager attached to case
   */
  projectManager: UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_caseManager | null;
  /**
   * User who created the case
   */
  createdBy: UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_createdBy | null;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * Case policy holder details
   */
  policyHolder: UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case_policyHolder;
}

export interface UpdateWebDrivingSlipNewPlanner_updateDrivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Location of driving slip
   */
  location: UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_location;
  /**
   * Department of driving slip
   */
  department: UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Case which driving slip is related to
   */
  case: UpdateWebDrivingSlipNewPlanner_updateDrivingSlip_case;
}

export interface UpdateWebDrivingSlipNewPlanner {
  updateDrivingSlip: UpdateWebDrivingSlipNewPlanner_updateDrivingSlip[];
}

export interface UpdateWebDrivingSlipNewPlannerVariables {
  id: string;
  urgent?: boolean | null;
  driver?: string | null;
  estimatedHours?: GQL_Decimal | null;
  start?: GQL_DateTime | null;
  end?: GQL_DateTime | null;
  comment?: string | null;
  deadline?: GQL_DateTime | null;
  eventId?: string | null;
  additionalDays?: GQL_Date[] | null;
  additionalDrivers?: string[] | null;
  location: string;
  department: string;
  driverCarList?: DriverCarInput[] | null;
  category?: string | null;
  immediatelyPlan?: boolean | null;
  plannerMessage?: string | null;
  car?: string | null;
  materials?: string[] | null;
  halfDay?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateWebDamageReport
// ====================================================

export interface CreateWebDamageReport_createDamageReport {
  /**
   * File name
   */
  name: string;
  /**
   * File url from ESDH
   */
  url: string;
}

export interface CreateWebDamageReport {
  /**
   * Create damage report for case
   */
  createDamageReport: CreateWebDamageReport_createDamageReport;
}

export interface CreateWebDamageReportVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateWebInspectionMovablesReport
// ====================================================

export interface CreateWebInspectionMovablesReport_createInspectionMovablesReport {
  /**
   * File name
   */
  name: string;
}

export interface CreateWebInspectionMovablesReport {
  /**
   * Create inspection report for case
   */
  createInspectionMovablesReport: CreateWebInspectionMovablesReport_createInspectionMovablesReport | null;
}

export interface CreateWebInspectionMovablesReportVariables {
  caseId: string;
  formData: InspectionReportFormDataInput;
  onlySave: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateWebInspectionReport
// ====================================================

export interface CreateWebInspectionReport_createInspectionReport {
  /**
   * File name
   */
  name: string;
}

export interface CreateWebInspectionReport {
  /**
   * Create inspection report for case
   */
  createInspectionReport: CreateWebInspectionReport_createInspectionReport | null;
}

export interface CreateWebInspectionReportVariables {
  caseId: string;
  formData: InspectionReportFormDataInput;
  onlySave: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateWebMoistureReport
// ====================================================

export interface CreateWebMoistureReport_createMoistureReport {
  /**
   * File name
   */
  name: string;
}

export interface CreateWebMoistureReport {
  /**
   * Create moisture report for case
   */
  createMoistureReport: CreateWebMoistureReport_createMoistureReport | null;
}

export interface CreateWebMoistureReportVariables {
  caseId: string;
  formData: MoistureReportFormDataInput;
  reportFormat: ReportFormatType;
  floorsToInclude?: string[] | null;
  onlySave: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateWebMoistureTrygReport
// ====================================================

export interface CreateWebMoistureTrygReport_createMoistureTrygReport {
  /**
   * File name
   */
  name: string;
}

export interface CreateWebMoistureTrygReport {
  /**
   * Create moisture report for case
   */
  createMoistureTrygReport: CreateWebMoistureTrygReport_createMoistureTrygReport | null;
}

export interface CreateWebMoistureTrygReportVariables {
  caseId: string;
  formData: MoistureTrygReportFormDataInput;
  reportFormat: ReportFormatType;
  floorsToInclude?: string[] | null;
  onlySave: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCaseReportsAndDocuments
// ====================================================

export interface GetCaseReportsAndDocuments_case_documents_metadata {
  /**
   * KVP key
   */
  key: string;
  /**
   * KVP value
   */
  value: string;
}

export interface GetCaseReportsAndDocuments_case_documents {
  /**
   * File name
   */
  name: string;
  /**
   * File url from ESDH
   */
  url: string;
  /**
   * File thumbnail URL
   */
  thumbnail: string;
  /**
   * File extension
   */
  extension: string;
  /**
   * File grouping key
   */
  groupingKey: string;
  /**
   * File created
   */
  created: GQL_DateTime;
  /**
   * File metadata from ESDH
   */
  metadata: GetCaseReportsAndDocuments_case_documents_metadata[];
  /**
   * File comments from ESDH
   */
  comments: string | null;
  /**
   * File extension
   */
  fileId: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_inspectionReportFormData_facadeImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_inspectionReportFormData_floorplanImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_inspectionReportFormData_otherImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_inspectionReportFormData {
  /**
   * Famage image info
   */
  facadeImage: GetCaseReportsAndDocuments_case_caseReports_inspectionReportFormData_facadeImage | null;
  /**
   * Information about what has happened until now
   */
  backgroundStory: string | null;
  /**
   * Observations about the site
   */
  observations: string | null;
  /**
   * Assessment and conclusion
   */
  assessmentAndConclusion: string | null;
  /**
   * Suggested action plan
   */
  suggestedActionPlan: string | null;
  /**
   * Any other comments from inspection
   */
  comments: string | null;
  /**
   * Floorplan image info
   */
  floorplanImage: GetCaseReportsAndDocuments_case_caseReports_inspectionReportFormData_floorplanImage | null;
  /**
   * List of other relevant images
   */
  otherImages: GetCaseReportsAndDocuments_case_caseReports_inspectionReportFormData_otherImages[] | null;
}

export interface GetCaseReportsAndDocuments_case_caseReports_inspectionMovablesReportFormData_facadeImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_inspectionMovablesReportFormData_floorplanImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_inspectionMovablesReportFormData_otherImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_inspectionMovablesReportFormData {
  /**
   * Famage image info
   */
  facadeImage: GetCaseReportsAndDocuments_case_caseReports_inspectionMovablesReportFormData_facadeImage | null;
  /**
   * Information about what has happened until now
   */
  backgroundStory: string | null;
  /**
   * Observations about the site
   */
  observations: string | null;
  /**
   * Assessment and conclusion
   */
  assessmentAndConclusion: string | null;
  /**
   * Suggested action plan
   */
  suggestedActionPlan: string | null;
  /**
   * Any other comments from inspection
   */
  comments: string | null;
  /**
   * Floorplan image info
   */
  floorplanImage: GetCaseReportsAndDocuments_case_caseReports_inspectionMovablesReportFormData_floorplanImage | null;
  /**
   * List of other relevant images
   */
  otherImages: GetCaseReportsAndDocuments_case_caseReports_inspectionMovablesReportFormData_otherImages[] | null;
}

export interface GetCaseReportsAndDocuments_case_caseReports_moistureReportFormData_facadeImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_moistureReportFormData_damageCauseImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_moistureReportFormData_floorReport_observations {
  /**
   * Describes where the damage is
   */
  roomOrApartment: string;
  /**
   * Construction observations
   */
  construction: string | null;
  /**
   * Ceiling observations
   */
  ceiling: string | null;
  /**
   * Walls observations
   */
  walls: string | null;
  /**
   * Floor observations
   */
  floor: string | null;
  /**
   * Observed scope of damage
   */
  scope: string;
  /**
   * Recommendations
   */
  recommendations: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_moistureReportFormData_floorReport_moistureMeassurements {
  /**
   * Construction
   */
  construction: string | null;
  /**
   * Material
   */
  material: string | null;
  /**
   * Description
   */
  description: string | null;
  /**
   * Method
   */
  method: string | null;
  /**
   * Value
   */
  value: string;
  /**
   * Expected final value
   */
  expectedFinalValue: string | null;
  /**
   * Reference value
   */
  referenceValue: string | null;
  /**
   * Reference date
   */
  referenceDate: string | null;
  /**
   * Control value
   */
  controlValue: string | null;
  /**
   * Control date
   */
  controlDate: string | null;
}

export interface GetCaseReportsAndDocuments_case_caseReports_moistureReportFormData_floorReport_floorplanImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_moistureReportFormData_floorReport_otherImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_moistureReportFormData_floorReport {
  /**
   * Floor Name
   */
  name: string;
  /**
   * Report observations
   */
  observations: GetCaseReportsAndDocuments_case_caseReports_moistureReportFormData_floorReport_observations;
  /**
   * Moisture meassurements
   */
  moistureMeassurements: GetCaseReportsAndDocuments_case_caseReports_moistureReportFormData_floorReport_moistureMeassurements[] | null;
  /**
   * Floorplan image info
   */
  floorplanImage: GetCaseReportsAndDocuments_case_caseReports_moistureReportFormData_floorReport_floorplanImage | null;
  /**
   * List of other relevant images
   */
  otherImages: GetCaseReportsAndDocuments_case_caseReports_moistureReportFormData_floorReport_otherImages[] | null;
}

export interface GetCaseReportsAndDocuments_case_caseReports_moistureReportFormData {
  /**
   * Famage image info
   */
  facadeImage: GetCaseReportsAndDocuments_case_caseReports_moistureReportFormData_facadeImage | null;
  /**
   * Date of damage
   */
  inspectionDate: GQL_Date;
  /**
   * Conclusions
   */
  conclusion: string;
  /**
   * Damage cause description
   */
  damageCause: string;
  /**
   * Damage scope
   */
  scope: string;
  /**
   * Damage site contacts
   */
  contacts: string;
  /**
   * Images of damage cause
   */
  damageCauseImages: GetCaseReportsAndDocuments_case_caseReports_moistureReportFormData_damageCauseImages[] | null;
  /**
   * Gulvfugt info
   */
  floorReport: GetCaseReportsAndDocuments_case_caseReports_moistureReportFormData_floorReport[];
}

export interface GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData_facadeImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData_floorReport_floorplanImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData_floorReport_moistureMeassurements {
  /**
   * Construction
   */
  construction: string | null;
  /**
   * Material
   */
  material: string | null;
  /**
   * Description
   */
  description: string | null;
  /**
   * Method
   */
  method: string | null;
  /**
   * Paints
   */
  paints: string;
  /**
   * Paints Date
   */
  paintsDate: string | null;
  /**
   * Expected final value
   */
  expectedFinalValue: string | null;
  /**
   * Expected final date
   */
  expectedFinalDate: string | null;
  /**
   * Reference value
   */
  referenceValue: string | null;
  /**
   * Reference date
   */
  referenceDate: string | null;
  /**
   * Control value
   */
  controlValue: string | null;
  /**
   * Control date
   */
  controlDate: string | null;
}

export interface GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData_floorReport_airMeassurements {
  /**
   * Temperature inside (celcius)
   */
  temperatureInside: number;
  /**
   * Temperature outside (celcius)
   */
  temperatureOutside: number;
  /**
   * Relative humidity inside (percentage)
   */
  relativeHumidityInside: number;
  /**
   * Relative humidity outside (percentage)
   */
  relativeHumidityOutside: number;
  /**
   * Water amount inside (g/m3)
   */
  waterAmountInside: number;
  /**
   * Water amount outside (g/m3)
   */
  waterAmountOutside: number;
}

export interface GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData_floorReport_damageDescriptionImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData_floorReport_damageDrawingImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData_floorReport_otherImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData_floorReport {
  /**
   * Name of floor
   */
  name: string | null;
  /**
   * Damage cause description
   */
  damageCause: string | null;
  /**
   * Multiple damage causes description
   */
  multipleDamageCauses: string | null;
  /**
   * Description of relation between damage cause and moisture
   */
  moistureDamageCause: string | null;
  /**
   * Damage description
   */
  damageDescription: string | null;
  /**
   * Floorplan image info
   */
  floorplanImage: GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData_floorReport_floorplanImage | null;
  /**
   * Moisture meassurements
   */
  moistureMeassurements: GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData_floorReport_moistureMeassurements[] | null;
  /**
   * Air meassurements
   */
  airMeassurements: GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData_floorReport_airMeassurements;
  /**
   * Damage description image info
   */
  damageDescriptionImage: GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData_floorReport_damageDescriptionImage[] | null;
  /**
   * Damage drawing image info
   */
  damageDrawingImage: GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData_floorReport_damageDrawingImage | null;
  /**
   * Recommendations (if any)
   */
  recommendations: string | null;
  /**
   * Urgent actions (if any)
   */
  urgentActions: string | null;
  /**
   * List of other relevant images
   */
  otherImages: GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData_floorReport_otherImages[] | null;
}

export interface GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData {
  /**
   * Famage image info
   */
  facadeImage: GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData_facadeImage | null;
  /**
   * Date of damage
   */
  inspectionDate: GQL_Date | null;
  /**
   * Gulvfugt info
   */
  floorReport: GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData_floorReport[] | null;
}

export interface GetCaseReportsAndDocuments_case_caseReports_indoorClimateReportFormData_facadeImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_indoorClimateReportFormData_floorplanImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_indoorClimateReportFormData_otherImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_indoorClimateReportFormData_indoorClimateMeassurements {
  /**
   * Room
   */
  room: string | null;
  /**
   * Construction
   */
  construction: string | null;
  /**
   * Material
   */
  material: string | null;
  /**
   * Description
   */
  description: string | null;
  /**
   * Instrument
   */
  instrument: string | null;
  /**
   * Value
   */
  value: string | null;
  /**
   * Reference value
   */
  referenceValue: string | null;
  /**
   * Calculated value
   */
  calculatedValue: string | null;
}

export interface GetCaseReportsAndDocuments_case_caseReports_indoorClimateReportFormData {
  /**
   * Famage image info
   */
  facadeImage: GetCaseReportsAndDocuments_case_caseReports_indoorClimateReportFormData_facadeImage | null;
  /**
   * Date of damage
   */
  inspectionDate: GQL_Date | null;
  /**
   * Purpose of the report
   */
  purpose: string | null;
  /**
   * Year of the building being built
   */
  buildYear: string | null;
  /**
   * Square meters of the area
   */
  squareMeters: string | null;
  /**
   * Daily users of the building
   */
  dailyUsers: string | null;
  /**
   * Other participants on the scene
   */
  participants: string | null;
  /**
   * Information about what has happened until now
   */
  backgroundStory: string | null;
  /**
   * Observations
   */
  observations: string | null;
  /**
   * Refer to appendix for lab answers
   */
  showLabAnswers: boolean;
  /**
   * Refer to appendix for the data log
   */
  showDataLog: boolean | null;
  /**
   * Assessment and conclusion
   */
  assessmentAndConclusion: string | null;
  /**
   * Suggested action plan
   */
  suggestedActionPlan: string | null;
  /**
   * Floorplan image info
   */
  floorplanImage: GetCaseReportsAndDocuments_case_caseReports_indoorClimateReportFormData_floorplanImage | null;
  /**
   * List of other relevant images
   */
  otherImages: GetCaseReportsAndDocuments_case_caseReports_indoorClimateReportFormData_otherImages[] | null;
  /**
   * Meassurements of indoor climate
   */
  indoorClimateMeassurements: GetCaseReportsAndDocuments_case_caseReports_indoorClimateReportFormData_indoorClimateMeassurements[] | null;
}

export interface GetCaseReportsAndDocuments_case_caseReports {
  /**
   * Inspection report form data populated by the user
   */
  inspectionReportFormData: GetCaseReportsAndDocuments_case_caseReports_inspectionReportFormData | null;
  /**
   * Inspection report form data populated by the user
   */
  inspectionMovablesReportFormData: GetCaseReportsAndDocuments_case_caseReports_inspectionMovablesReportFormData | null;
  /**
   * Moisture report form data populated by the user
   */
  moistureReportFormData: GetCaseReportsAndDocuments_case_caseReports_moistureReportFormData | null;
  /**
   * Moisture TRYG report form data populated by the user
   */
  moistureTrygReportFormData: GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData | null;
  /**
   * Indooor climate report form data populated by the user
   */
  indoorClimateReportFormData: GetCaseReportsAndDocuments_case_caseReports_indoorClimateReportFormData | null;
}

export interface GetCaseReportsAndDocuments_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * List of document file names and thumbnails
   */
  documents: GetCaseReportsAndDocuments_case_documents[];
  caseReports: GetCaseReportsAndDocuments_case_caseReports | null;
}

export interface GetCaseReportsAndDocuments {
  case: GetCaseReportsAndDocuments_case;
}

export interface GetCaseReportsAndDocumentsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCaseRequisition
// ====================================================

export interface CreateCaseRequisition_createCaseRequisition_case_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
}

export interface CreateCaseRequisition_createCaseRequisition_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
  /**
   * Id of damage category
   */
  id: string;
}

export interface CreateCaseRequisition_createCaseRequisition_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of damage cause
   */
  id: string;
}

export interface CreateCaseRequisition_createCaseRequisition_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCaseRequisition_createCaseRequisition_case_damage_contact {
  /**
   * Contact address information
   */
  address: CreateCaseRequisition_createCaseRequisition_case_damage_contact_address;
}

export interface CreateCaseRequisition_createCaseRequisition_case_damage {
  /**
   * Damage category
   */
  category: CreateCaseRequisition_createCaseRequisition_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: CreateCaseRequisition_createCaseRequisition_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: CreateCaseRequisition_createCaseRequisition_case_damage_contact;
}

export interface CreateCaseRequisition_createCaseRequisition_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: CreateCaseRequisition_createCaseRequisition_case_debitor;
  /**
   * Case damage details
   */
  damage: CreateCaseRequisition_createCaseRequisition_case_damage;
}

export interface CreateCaseRequisition_createCaseRequisition_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
}

export interface CreateCaseRequisition_createCaseRequisition_drivingSlip_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
}

export interface CreateCaseRequisition_createCaseRequisition_drivingSlip_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
}

export interface CreateCaseRequisition_createCaseRequisition_drivingSlip_case_damage {
  /**
   * Damage category
   */
  category: CreateCaseRequisition_createCaseRequisition_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: CreateCaseRequisition_createCaseRequisition_drivingSlip_case_damage_cause;
}

export interface CreateCaseRequisition_createCaseRequisition_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: CreateCaseRequisition_createCaseRequisition_drivingSlip_case_damage;
}

export interface CreateCaseRequisition_createCaseRequisition_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Case which driving slip is related to
   */
  case: CreateCaseRequisition_createCaseRequisition_drivingSlip_case;
}

export interface CreateCaseRequisition_createCaseRequisition_vendor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCaseRequisition_createCaseRequisition_vendor {
  /**
   * ERP reference no for vendor
   */
  erpReferenceNo: string;
  /**
   * The name of the vendor
   */
  company: string | null;
  /**
   * The address of the vendor
   */
  address: CreateCaseRequisition_createCaseRequisition_vendor_address | null;
  /**
   * The email of the vendor
   */
  email: string | null;
  /**
   * The phone number of the vendor
   */
  phone: string | null;
  /**
   * The posting group of the vendor
   */
  vendorPostingGroup: string;
  /**
   * Attached SSG department of the vendor
   */
  ssgDepartment: string;
  /**
   * Attached SSG location of the vendor
   */
  ssgLocation: string;
  /**
   * Vendor type
   */
  type: string;
}

export interface CreateCaseRequisition_createCaseRequisition_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface CreateCaseRequisition_createCaseRequisition {
  /**
   * id of the requisition
   */
  id: string;
  /**
   * case owning the requisition
   */
  case: CreateCaseRequisition_createCaseRequisition_case | null;
  /**
   * location owning the requisition
   */
  department: CreateCaseRequisition_createCaseRequisition_department | null;
  /**
   * driving slip owning the requisition
   */
  drivingSlip: CreateCaseRequisition_createCaseRequisition_drivingSlip | null;
  /**
   * description of the requisition
   */
  description: string;
  /**
   * orderNumber of the requisition
   */
  orderNumber: string;
  /**
   * the type of the requisition
   */
  type: RequisitionType;
  /**
   * Requisition status
   */
  status: boolean;
  /**
   * the vendor of the requisition
   */
  vendor: CreateCaseRequisition_createCaseRequisition_vendor | null;
  /**
   * Timestamp of when the requisition was created
   */
  createdAt: GQL_DateTime;
  /**
   * User who created the requisition
   */
  createdBy: CreateCaseRequisition_createCaseRequisition_createdBy;
  /**
   * email of the vendor
   */
  vendorEmail: string | null;
  /**
   * phone number of the vendor
   */
  vendorPhoneNumber: string;
  /**
   * name of the vendor
   */
  vendorName: string;
}

export interface CreateCaseRequisition {
  createCaseRequisition: CreateCaseRequisition_createCaseRequisition;
}

export interface CreateCaseRequisitionVariables {
  caseId: string;
  requisition: RequisitionInput;
  shouldSendEmail: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateDepartmentRequisition
// ====================================================

export interface CreateDepartmentRequisition_createDepartmentRequisition_case_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
}

export interface CreateDepartmentRequisition_createDepartmentRequisition_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
  /**
   * Id of damage category
   */
  id: string;
}

export interface CreateDepartmentRequisition_createDepartmentRequisition_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of damage cause
   */
  id: string;
}

export interface CreateDepartmentRequisition_createDepartmentRequisition_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateDepartmentRequisition_createDepartmentRequisition_case_damage_contact {
  /**
   * Contact address information
   */
  address: CreateDepartmentRequisition_createDepartmentRequisition_case_damage_contact_address;
}

export interface CreateDepartmentRequisition_createDepartmentRequisition_case_damage {
  /**
   * Damage category
   */
  category: CreateDepartmentRequisition_createDepartmentRequisition_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: CreateDepartmentRequisition_createDepartmentRequisition_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: CreateDepartmentRequisition_createDepartmentRequisition_case_damage_contact;
}

export interface CreateDepartmentRequisition_createDepartmentRequisition_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: CreateDepartmentRequisition_createDepartmentRequisition_case_debitor;
  /**
   * Case damage details
   */
  damage: CreateDepartmentRequisition_createDepartmentRequisition_case_damage;
}

export interface CreateDepartmentRequisition_createDepartmentRequisition_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
}

export interface CreateDepartmentRequisition_createDepartmentRequisition_drivingSlip_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
}

export interface CreateDepartmentRequisition_createDepartmentRequisition_drivingSlip_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
}

export interface CreateDepartmentRequisition_createDepartmentRequisition_drivingSlip_case_damage {
  /**
   * Damage category
   */
  category: CreateDepartmentRequisition_createDepartmentRequisition_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: CreateDepartmentRequisition_createDepartmentRequisition_drivingSlip_case_damage_cause;
}

export interface CreateDepartmentRequisition_createDepartmentRequisition_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: CreateDepartmentRequisition_createDepartmentRequisition_drivingSlip_case_damage;
}

export interface CreateDepartmentRequisition_createDepartmentRequisition_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Case which driving slip is related to
   */
  case: CreateDepartmentRequisition_createDepartmentRequisition_drivingSlip_case;
}

export interface CreateDepartmentRequisition_createDepartmentRequisition_vendor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateDepartmentRequisition_createDepartmentRequisition_vendor {
  /**
   * ERP reference no for vendor
   */
  erpReferenceNo: string;
  /**
   * The name of the vendor
   */
  company: string | null;
  /**
   * The address of the vendor
   */
  address: CreateDepartmentRequisition_createDepartmentRequisition_vendor_address | null;
  /**
   * The email of the vendor
   */
  email: string | null;
  /**
   * The phone number of the vendor
   */
  phone: string | null;
  /**
   * The posting group of the vendor
   */
  vendorPostingGroup: string;
  /**
   * Attached SSG department of the vendor
   */
  ssgDepartment: string;
  /**
   * Attached SSG location of the vendor
   */
  ssgLocation: string;
  /**
   * Vendor type
   */
  type: string;
}

export interface CreateDepartmentRequisition_createDepartmentRequisition_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface CreateDepartmentRequisition_createDepartmentRequisition {
  /**
   * id of the requisition
   */
  id: string;
  /**
   * case owning the requisition
   */
  case: CreateDepartmentRequisition_createDepartmentRequisition_case | null;
  /**
   * location owning the requisition
   */
  department: CreateDepartmentRequisition_createDepartmentRequisition_department | null;
  /**
   * driving slip owning the requisition
   */
  drivingSlip: CreateDepartmentRequisition_createDepartmentRequisition_drivingSlip | null;
  /**
   * description of the requisition
   */
  description: string;
  /**
   * orderNumber of the requisition
   */
  orderNumber: string;
  /**
   * the type of the requisition
   */
  type: RequisitionType;
  /**
   * Requisition status
   */
  status: boolean;
  /**
   * the vendor of the requisition
   */
  vendor: CreateDepartmentRequisition_createDepartmentRequisition_vendor | null;
  /**
   * Timestamp of when the requisition was created
   */
  createdAt: GQL_DateTime;
  /**
   * User who created the requisition
   */
  createdBy: CreateDepartmentRequisition_createDepartmentRequisition_createdBy;
  /**
   * email of the vendor
   */
  vendorEmail: string | null;
  /**
   * phone number of the vendor
   */
  vendorPhoneNumber: string;
  /**
   * name of the vendor
   */
  vendorName: string;
}

export interface CreateDepartmentRequisition {
  createDepartmentRequisition: CreateDepartmentRequisition_createDepartmentRequisition;
}

export interface CreateDepartmentRequisitionVariables {
  departmentId: string;
  requisition: RequisitionInput;
  shouldSendEmail: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateDrivingSlipRequisition
// ====================================================

export interface CreateDrivingSlipRequisition_createDrivingSlipRequisition_case_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
}

export interface CreateDrivingSlipRequisition_createDrivingSlipRequisition_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
  /**
   * Id of damage category
   */
  id: string;
}

export interface CreateDrivingSlipRequisition_createDrivingSlipRequisition_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of damage cause
   */
  id: string;
}

export interface CreateDrivingSlipRequisition_createDrivingSlipRequisition_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateDrivingSlipRequisition_createDrivingSlipRequisition_case_damage_contact {
  /**
   * Contact address information
   */
  address: CreateDrivingSlipRequisition_createDrivingSlipRequisition_case_damage_contact_address;
}

export interface CreateDrivingSlipRequisition_createDrivingSlipRequisition_case_damage {
  /**
   * Damage category
   */
  category: CreateDrivingSlipRequisition_createDrivingSlipRequisition_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: CreateDrivingSlipRequisition_createDrivingSlipRequisition_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: CreateDrivingSlipRequisition_createDrivingSlipRequisition_case_damage_contact;
}

export interface CreateDrivingSlipRequisition_createDrivingSlipRequisition_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: CreateDrivingSlipRequisition_createDrivingSlipRequisition_case_debitor;
  /**
   * Case damage details
   */
  damage: CreateDrivingSlipRequisition_createDrivingSlipRequisition_case_damage;
}

export interface CreateDrivingSlipRequisition_createDrivingSlipRequisition_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
}

export interface CreateDrivingSlipRequisition_createDrivingSlipRequisition_drivingSlip_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
}

export interface CreateDrivingSlipRequisition_createDrivingSlipRequisition_drivingSlip_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
}

export interface CreateDrivingSlipRequisition_createDrivingSlipRequisition_drivingSlip_case_damage {
  /**
   * Damage category
   */
  category: CreateDrivingSlipRequisition_createDrivingSlipRequisition_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: CreateDrivingSlipRequisition_createDrivingSlipRequisition_drivingSlip_case_damage_cause;
}

export interface CreateDrivingSlipRequisition_createDrivingSlipRequisition_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: CreateDrivingSlipRequisition_createDrivingSlipRequisition_drivingSlip_case_damage;
}

export interface CreateDrivingSlipRequisition_createDrivingSlipRequisition_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Case which driving slip is related to
   */
  case: CreateDrivingSlipRequisition_createDrivingSlipRequisition_drivingSlip_case;
}

export interface CreateDrivingSlipRequisition_createDrivingSlipRequisition_vendor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateDrivingSlipRequisition_createDrivingSlipRequisition_vendor {
  /**
   * ERP reference no for vendor
   */
  erpReferenceNo: string;
  /**
   * The name of the vendor
   */
  company: string | null;
  /**
   * The address of the vendor
   */
  address: CreateDrivingSlipRequisition_createDrivingSlipRequisition_vendor_address | null;
  /**
   * The email of the vendor
   */
  email: string | null;
  /**
   * The phone number of the vendor
   */
  phone: string | null;
  /**
   * The posting group of the vendor
   */
  vendorPostingGroup: string;
  /**
   * Attached SSG department of the vendor
   */
  ssgDepartment: string;
  /**
   * Attached SSG location of the vendor
   */
  ssgLocation: string;
  /**
   * Vendor type
   */
  type: string;
}

export interface CreateDrivingSlipRequisition_createDrivingSlipRequisition_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface CreateDrivingSlipRequisition_createDrivingSlipRequisition {
  /**
   * id of the requisition
   */
  id: string;
  /**
   * case owning the requisition
   */
  case: CreateDrivingSlipRequisition_createDrivingSlipRequisition_case | null;
  /**
   * location owning the requisition
   */
  department: CreateDrivingSlipRequisition_createDrivingSlipRequisition_department | null;
  /**
   * driving slip owning the requisition
   */
  drivingSlip: CreateDrivingSlipRequisition_createDrivingSlipRequisition_drivingSlip | null;
  /**
   * description of the requisition
   */
  description: string;
  /**
   * orderNumber of the requisition
   */
  orderNumber: string;
  /**
   * the type of the requisition
   */
  type: RequisitionType;
  /**
   * Requisition status
   */
  status: boolean;
  /**
   * the vendor of the requisition
   */
  vendor: CreateDrivingSlipRequisition_createDrivingSlipRequisition_vendor | null;
  /**
   * Timestamp of when the requisition was created
   */
  createdAt: GQL_DateTime;
  /**
   * User who created the requisition
   */
  createdBy: CreateDrivingSlipRequisition_createDrivingSlipRequisition_createdBy;
  /**
   * email of the vendor
   */
  vendorEmail: string | null;
  /**
   * phone number of the vendor
   */
  vendorPhoneNumber: string;
  /**
   * name of the vendor
   */
  vendorName: string;
}

export interface CreateDrivingSlipRequisition {
  createDrivingSlipRequisition: CreateDrivingSlipRequisition_createDrivingSlipRequisition;
}

export interface CreateDrivingSlipRequisitionVariables {
  drivingSlipId: string;
  requisition: RequisitionInput;
  shouldSendEmail: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetVendors
// ====================================================

export interface GetVendors_vendors_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetVendors_vendors {
  /**
   * ERP reference no for vendor
   */
  erpReferenceNo: string;
  /**
   * The name of the vendor
   */
  company: string | null;
  /**
   * The address of the vendor
   */
  address: GetVendors_vendors_address | null;
  /**
   * The email of the vendor
   */
  email: string | null;
  /**
   * The phone number of the vendor
   */
  phone: string | null;
  /**
   * The posting group of the vendor
   */
  vendorPostingGroup: string;
  /**
   * Attached SSG department of the vendor
   */
  ssgDepartment: string;
  /**
   * Attached SSG location of the vendor
   */
  ssgLocation: string;
  /**
   * Vendor type
   */
  type: string;
}

export interface GetVendors {
  vendors: GetVendors_vendors[];
}

export interface GetVendorsVariables {
  company?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWebRequisitions
// ====================================================

export interface GetWebRequisitions_requisitions_case_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
}

export interface GetWebRequisitions_requisitions_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
  /**
   * Id of damage category
   */
  id: string;
}

export interface GetWebRequisitions_requisitions_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of damage cause
   */
  id: string;
}

export interface GetWebRequisitions_requisitions_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebRequisitions_requisitions_case_damage_contact {
  /**
   * Contact address information
   */
  address: GetWebRequisitions_requisitions_case_damage_contact_address;
}

export interface GetWebRequisitions_requisitions_case_damage {
  /**
   * Damage category
   */
  category: GetWebRequisitions_requisitions_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetWebRequisitions_requisitions_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: GetWebRequisitions_requisitions_case_damage_contact;
}

export interface GetWebRequisitions_requisitions_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetWebRequisitions_requisitions_case_debitor;
  /**
   * Case damage details
   */
  damage: GetWebRequisitions_requisitions_case_damage;
}

export interface GetWebRequisitions_requisitions_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
}

export interface GetWebRequisitions_requisitions_drivingSlip_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetWebRequisitions_requisitions_drivingSlip_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
}

export interface GetWebRequisitions_requisitions_drivingSlip_case_damage {
  /**
   * Damage category
   */
  category: GetWebRequisitions_requisitions_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetWebRequisitions_requisitions_drivingSlip_case_damage_cause;
}

export interface GetWebRequisitions_requisitions_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: GetWebRequisitions_requisitions_drivingSlip_case_damage;
}

export interface GetWebRequisitions_requisitions_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Case which driving slip is related to
   */
  case: GetWebRequisitions_requisitions_drivingSlip_case;
}

export interface GetWebRequisitions_requisitions_vendor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebRequisitions_requisitions_vendor {
  /**
   * ERP reference no for vendor
   */
  erpReferenceNo: string;
  /**
   * The name of the vendor
   */
  company: string | null;
  /**
   * The address of the vendor
   */
  address: GetWebRequisitions_requisitions_vendor_address | null;
  /**
   * The email of the vendor
   */
  email: string | null;
  /**
   * The phone number of the vendor
   */
  phone: string | null;
  /**
   * The posting group of the vendor
   */
  vendorPostingGroup: string;
  /**
   * Attached SSG department of the vendor
   */
  ssgDepartment: string;
  /**
   * Attached SSG location of the vendor
   */
  ssgLocation: string;
  /**
   * Vendor type
   */
  type: string;
}

export interface GetWebRequisitions_requisitions_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface GetWebRequisitions_requisitions {
  /**
   * id of the requisition
   */
  id: string;
  /**
   * case owning the requisition
   */
  case: GetWebRequisitions_requisitions_case | null;
  /**
   * location owning the requisition
   */
  department: GetWebRequisitions_requisitions_department | null;
  /**
   * driving slip owning the requisition
   */
  drivingSlip: GetWebRequisitions_requisitions_drivingSlip | null;
  /**
   * description of the requisition
   */
  description: string;
  /**
   * orderNumber of the requisition
   */
  orderNumber: string;
  /**
   * the type of the requisition
   */
  type: RequisitionType;
  /**
   * Requisition status
   */
  status: boolean;
  /**
   * the vendor of the requisition
   */
  vendor: GetWebRequisitions_requisitions_vendor | null;
  /**
   * Timestamp of when the requisition was created
   */
  createdAt: GQL_DateTime;
  /**
   * User who created the requisition
   */
  createdBy: GetWebRequisitions_requisitions_createdBy;
  /**
   * email of the vendor
   */
  vendorEmail: string | null;
  /**
   * phone number of the vendor
   */
  vendorPhoneNumber: string;
  /**
   * name of the vendor
   */
  vendorName: string;
}

export interface GetWebRequisitions {
  requisitions: GetWebRequisitions_requisitions[];
}

export interface GetWebRequisitionsVariables {
  myRequisitionsOnly?: boolean | null;
  caseId?: string | null;
  departments?: string[] | null;
  vendor?: string | null;
  type?: RequisitionType | null;
  damageCategories?: string[] | null;
  damageCauses?: string[] | null;
  fromDate?: GQL_Date | null;
  toDate?: GQL_Date | null;
  offset?: number | null;
  limit?: number | null;
  searchString?: string | null;
  excludeTotalCount?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRiskEvaluationQuestionsByCategoryId
// ====================================================

export interface GetRiskEvaluationQuestionsByCategoryId_riskEvaluationQuestionsByCategoryId_category {
  /**
   * Id of risk category
   */
  id: string;
  /**
   * Name of risk category
   */
  categoryName: string;
}

export interface GetRiskEvaluationQuestionsByCategoryId_riskEvaluationQuestionsByCategoryId {
  /**
   * Id of question
   */
  id: string;
  /**
   * Id of category question is linked to
   */
  category: GetRiskEvaluationQuestionsByCategoryId_riskEvaluationQuestionsByCategoryId_category;
  /**
   * Question
   */
  question: string;
  /**
   * Help text for question
   */
  helpText: string;
  /**
   * If a drivingslip is made during casecreation this question will be marked true on risk evaluation
   */
  defaultChecked: boolean;
}

export interface GetRiskEvaluationQuestionsByCategoryId {
  riskEvaluationQuestionsByCategoryId: GetRiskEvaluationQuestionsByCategoryId_riskEvaluationQuestionsByCategoryId[];
}

export interface GetRiskEvaluationQuestionsByCategoryIdVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRiskEvalutaionAnswers
// ====================================================

export interface GetRiskEvalutaionAnswers_case_riskEvaluationAnswers_question {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question
   */
  question: string;
}

export interface GetRiskEvalutaionAnswers_case_riskEvaluationAnswers {
  /**
   * Id of question answer is linked to
   */
  question: GetRiskEvalutaionAnswers_case_riskEvaluationAnswers_question;
  /**
   * Answer given
   */
  answer: boolean;
}

export interface GetRiskEvalutaionAnswers_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Risk evaluation answers
   */
  riskEvaluationAnswers: GetRiskEvalutaionAnswers_case_riskEvaluationAnswers[] | null;
}

export interface GetRiskEvalutaionAnswers {
  case: GetRiskEvalutaionAnswers_case;
}

export interface GetRiskEvalutaionAnswersVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRiskEvaluationCategories
// ====================================================

export interface GetRiskEvaluationCategories_riskEvaluationCategories {
  /**
   * Id of risk category
   */
  id: string;
  /**
   * Name of risk category
   */
  categoryName: string;
}

export interface GetRiskEvaluationCategories {
  riskEvaluationCategories: GetRiskEvaluationCategories_riskEvaluationCategories[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRiskEvaluationQuestion
// ====================================================

export interface GetRiskEvaluationQuestion_riskEvaluationQuestion_category {
  /**
   * Id of risk category
   */
  id: string;
  /**
   * Name of risk category
   */
  categoryName: string;
}

export interface GetRiskEvaluationQuestion_riskEvaluationQuestion {
  /**
   * Id of question
   */
  id: string;
  /**
   * Id of category question is linked to
   */
  category: GetRiskEvaluationQuestion_riskEvaluationQuestion_category;
  /**
   * Question
   */
  question: string;
  /**
   * Help text for question
   */
  helpText: string;
  /**
   * If a drivingslip is made during casecreation this question will be marked true on risk evaluation
   */
  defaultChecked: boolean;
}

export interface GetRiskEvaluationQuestion {
  riskEvaluationQuestion: GetRiskEvaluationQuestion_riskEvaluationQuestion;
}

export interface GetRiskEvaluationQuestionVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRiskEvaluationQuestions
// ====================================================

export interface GetRiskEvaluationQuestions_riskEvaluationQuestions_category {
  /**
   * Id of risk category
   */
  id: string;
  /**
   * Name of risk category
   */
  categoryName: string;
}

export interface GetRiskEvaluationQuestions_riskEvaluationQuestions {
  /**
   * Id of question
   */
  id: string;
  /**
   * Id of category question is linked to
   */
  category: GetRiskEvaluationQuestions_riskEvaluationQuestions_category;
  /**
   * Question
   */
  question: string;
  /**
   * Help text for question
   */
  helpText: string;
  /**
   * If a drivingslip is made during casecreation this question will be marked true on risk evaluation
   */
  defaultChecked: boolean;
}

export interface GetRiskEvaluationQuestions {
  riskEvaluationQuestions: GetRiskEvaluationQuestions_riskEvaluationQuestions[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateScreening
// ====================================================

export interface CreateScreening_createScreening {
  /**
   * This value is not used for anything.
   */
  _: string;
}

export interface CreateScreening {
  createScreening: CreateScreening_createScreening | null;
}

export interface CreateScreeningVariables {
  caseId: string;
  template: string;
  name: string;
  lines: ScreeningLineInputType[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteScreening
// ====================================================

export interface DeleteScreening_deleteScreening {
  /**
   * This value is not used for anything.
   */
  _: string;
}

export interface DeleteScreening {
  deleteScreening: DeleteScreening_deleteScreening | null;
}

export interface DeleteScreeningVariables {
  caseId: string;
  screeningERPReferenceNo: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetScreenings
// ====================================================

export interface GetScreenings_case_screenings_lines {
  /**
   * Identifier used in ERP system
   */
  erpReferenceId: string;
  /**
   * Screening line id
   */
  id: number;
  /**
   * Optional screening line parent id
   */
  parentId: number | null;
  /**
   * Optional screening line position number
   */
  position: string | null;
  /**
   * Screening line type
   */
  type: ScreeningLineType;
  /**
   * Screening line index
   */
  index: number;
  /**
   * Screening line title text
   */
  title: string;
  /**
   * Determines if user can override title
   */
  allowCustomTitle: boolean;
  /**
   * Boolean to indicate if calendar should be shown
   */
  showCalendar: boolean;
  /**
   * Screening line unit of measure
   */
  uom: string;
  /**
   * Screening price amount per unit
   */
  price: GQL_Decimal;
  /**
   * Optional screening line premium amount (in %)
   */
  linePremiumPercentage: GQL_Decimal | null;
  /**
   * Optional screening line premium amount (fixed amount)
   */
  linePremiumFixedAmount: GQL_Decimal | null;
  /**
   * Optional screening line reference id to fee line
   */
  linePremiumId: number | null;
  /**
   * Screening line value (when set)
   */
  value: GQL_Decimal | null;
  /**
   * Screening line title (when set)
   */
  customTitle: string | null;
  /**
   * Screening line movable volume (when set)
   */
  movableVolume: GQL_Decimal | null;
  /**
   * Screening line movable days (when set)
   */
  movableDays: GQL_Decimal | null;
  /**
   * Screening line movable date from value (when set)
   */
  movableDateFrom: string | null;
  /**
   * Screening line movable date to value (when set)
   */
  movableDateTo: string | null;
  /**
   * Determine whether to include/exclude from env. Fee calculation
   */
  excludeFromEnvFee: boolean | null;
}

export interface GetScreenings_case_screenings {
  /**
   * Screening reference id
   */
  erpReferenceId: string | null;
  /**
   * Screening reference number
   */
  erpReferenceNo: string | null;
  /**
   * Screening template id
   */
  templateId: string;
  /**
   * Screening template code
   */
  templateCode: string;
  /**
   * Screening template name
   */
  templateName: string;
  /**
   * User provided screening description
   */
  description: string;
  /**
   * Screening template lines
   */
  lines: GetScreenings_case_screenings_lines[];
  /**
   * Indicates if screening has been marked for invoicing
   */
  markedForInvoice: boolean;
  /**
   * Indicates if screening has been marked for invoicing and are allowed to reopen
   */
  reopenAllowed: boolean;
}

export interface GetScreenings_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Default screening template on case
   */
  caseDefaultScreeningTemplate: string;
  /**
   * The screening of the case
   */
  screenings: GetScreenings_case_screenings[];
}

export interface GetScreenings {
  case: GetScreenings_case;
}

export interface GetScreeningsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetScreeningTemplates
// ====================================================

export interface GetScreeningTemplates_screeningTemplates_lines {
  /**
   * Identifier used in ERP system
   */
  erpReferenceId: string;
  /**
   * Screening line id
   */
  id: number;
  /**
   * Optional screening line parent id
   */
  parentId: number | null;
  /**
   * Optional screening line position number
   */
  position: string | null;
  /**
   * Screening line ERP reference task
   */
  erpReferenceTask: string;
  /**
   * Screening line ERP reference account
   */
  erpReferenceAccount: string;
  /**
   * Determines if header section is required
   */
  required: boolean;
  /**
   * Screening line type
   */
  type: ScreeningLineType;
  /**
   * Screening line index
   */
  index: number;
  /**
   * Screening line title text
   */
  title: string;
  /**
   * Determines if user can override title
   */
  allowCustomTitle: boolean;
  /**
   * Screening line unit of measure
   */
  uom: string;
  /**
   * Screening price amount per unit
   */
  price: GQL_Decimal;
  /**
   * Optional screening line premium amount (in %)
   */
  linePremiumPercentage: GQL_Decimal | null;
  /**
   * Optional screening line premium amount (fixed amount)
   */
  linePremiumFixedAmount: GQL_Decimal | null;
  /**
   * Optional screening line reference id to fee line
   */
  linePremiumId: number | null;
  /**
   * Boolean to indicate if calendar should be shown
   */
  showCalendar: boolean;
  /**
   * Determine whether to include/exclude from env. Fee calculation
   */
  excludeFromEnvFee: boolean | null;
}

export interface GetScreeningTemplates_screeningTemplates {
  /**
   * Screening reference id
   */
  erpReferenceId: string | null;
  /**
   * Screening template code
   */
  templateCode: string;
  /**
   * Screening template name
   */
  templateName: string;
  /**
   * Screening template lines
   */
  lines: GetScreeningTemplates_screeningTemplates_lines[];
}

export interface GetScreeningTemplates {
  /**
   * Fetches all screening templates
   */
  screeningTemplates: GetScreeningTemplates_screeningTemplates[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWebScreeningPositionTexts
// ====================================================

export interface GetWebScreeningPositionTexts_screeningPositionTexts {
  /**
   * Unique id of entry
   */
  id: string;
  /**
   * Screening line position
   */
  position: string;
  /**
   * Screening line position help text
   */
  text: string;
}

export interface GetWebScreeningPositionTexts {
  /**
   * Fetches all screening position help texts
   */
  screeningPositionTexts: GetWebScreeningPositionTexts_screeningPositionTexts[];
}

export interface GetWebScreeningPositionTextsVariables {
  positions: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnlockScreening
// ====================================================

export interface UnlockScreening_unlockScreening {
  /**
   * This value is not used for anything.
   */
  _: string;
}

export interface UnlockScreening {
  unlockScreening: UnlockScreening_unlockScreening | null;
}

export interface UnlockScreeningVariables {
  caseId: string;
  screeningERPReferenceNo: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateScreening
// ====================================================

export interface UpdateScreening_updateScreening {
  /**
   * This value is not used for anything.
   */
  _: string;
}

export interface UpdateScreening {
  updateScreening: UpdateScreening_updateScreening | null;
}

export interface UpdateScreeningVariables {
  caseId: string;
  screeningERPReferenceNo: string;
  name: string;
  lines: ScreeningLineInputType[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAdminSettings
// ====================================================

export interface GetAdminSettings_setting_BCSettings {}

export interface GetAdminSettings_setting_DrivingSlipCategoryMappingSettings_drivingSlipCategoryMappings {
  /**
   * driving slip category
   */
  drivingSlipCategory: string;
  /**
   * Id of associated category
   */
  damageCategoryId: string;
  /**
   * Id of associated cause
   */
  damageCauseId: string;
}

export interface GetAdminSettings_setting_DrivingSlipCategoryMappingSettings {
  /**
   * Unique id of setting
   */
  id: string;
  /**
   * Settings type e.g: bc, smtp etc.
   */
  type: string;
  /**
   * Drivingslip CategoryMappings
   */
  drivingSlipCategoryMappings: GetAdminSettings_setting_DrivingSlipCategoryMappingSettings_drivingSlipCategoryMappings[];
}

export interface GetAdminSettings_setting_InvoiceSettings {
  /**
   * Unique id of setting
   */
  id: string;
  /**
   * For setting minimum hourly rate on case
   */
  minHourlyRate: number;
}

export type GetAdminSettings_setting = GetAdminSettings_setting_BCSettings | GetAdminSettings_setting_DrivingSlipCategoryMappingSettings | GetAdminSettings_setting_InvoiceSettings;

export interface GetAdminSettings {
  setting: GetAdminSettings_setting[];
}

export interface GetAdminSettingsVariables {
  type: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddCatalogToTimedMessage
// ====================================================

export interface AddCatalogToTimedMessage_addCatalogToTimedMessage_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface AddCatalogToTimedMessage_addCatalogToTimedMessage {
  /**
   * id to display
   */
  id: string;
  /**
   * Customer
   */
  customer: AddCatalogToTimedMessage_addCatalogToTimedMessage_customer;
  /**
   * Craftsman changed by
   */
  changedBy: string;
  /**
   * Time at which the message should show from
   */
  startTime: GQL_DateTime;
  /**
   * Time at which the message should stop showing
   */
  endTime: GQL_DateTime;
  /**
   * message to display
   */
  message: string;
  /**
   * Is timed message global
   */
  global: boolean;
  /**
   * Global type for timed message
   */
  globalType: CatalogGlobalType;
  /**
   * Is timed message active
   */
  active: boolean;
  /**
   * Version for timed message
   */
  version: number;
  /**
   * Catalog ids
   */
  catalogIds: string[];
}

export interface AddCatalogToTimedMessage {
  addCatalogToTimedMessage: AddCatalogToTimedMessage_addCatalogToTimedMessage;
}

export interface AddCatalogToTimedMessageVariables {
  timedMessageId: string;
  catalogId: string;
  changedBy: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTimedMessage
// ====================================================

export interface CreateTimedMessage_createTimedMessage_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface CreateTimedMessage_createTimedMessage_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface CreateTimedMessage_createTimedMessage_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: CreateTimedMessage_createTimedMessage_catalogs_addresses[];
}

export interface CreateTimedMessage_createTimedMessage {
  /**
   * id to display
   */
  id: string;
  /**
   * Customer
   */
  customer: CreateTimedMessage_createTimedMessage_customer;
  /**
   * Craftsman changed by
   */
  changedBy: string;
  /**
   * Time at which the message should show from
   */
  startTime: GQL_DateTime;
  /**
   * Time at which the message should stop showing
   */
  endTime: GQL_DateTime;
  /**
   * message to display
   */
  message: string;
  /**
   * Is timed message global
   */
  global: boolean;
  /**
   * Global type for timed message
   */
  globalType: CatalogGlobalType;
  /**
   * Is timed message active
   */
  active: boolean;
  /**
   * Version for timed message
   */
  version: number;
  /**
   * Catalog ids
   */
  catalogIds: string[];
  /**
   * Catalogs that uses timed message
   */
  catalogs: CreateTimedMessage_createTimedMessage_catalogs[] | null;
}

export interface CreateTimedMessage {
  createTimedMessage: CreateTimedMessage_createTimedMessage;
}

export interface CreateTimedMessageVariables {
  timedMessage: TimedMessageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteTimedMessage
// ====================================================

export interface DeleteTimedMessage {
  deleteTimedMessage: string;
}

export interface DeleteTimedMessageVariables {
  id: string;
  changedBy: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTimedMessageChanges
// ====================================================

export interface GetTimedMessageChanges_timedMessage_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetTimedMessageChanges_timedMessage_changes_before_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface GetTimedMessageChanges_timedMessage_changes_before_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface GetTimedMessageChanges_timedMessage_changes_before_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: GetTimedMessageChanges_timedMessage_changes_before_catalogs_addresses[];
}

export interface GetTimedMessageChanges_timedMessage_changes_before {
  customer: GetTimedMessageChanges_timedMessage_changes_before_customer | null;
  changedBy: string | null;
  startTime: GQL_DateTime | null;
  endTime: GQL_DateTime | null;
  message: string | null;
  global: boolean | null;
  globalType: CatalogGlobalType | null;
  active: boolean | null;
  catalogs: GetTimedMessageChanges_timedMessage_changes_before_catalogs[] | null;
}

export interface GetTimedMessageChanges_timedMessage_changes_after_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface GetTimedMessageChanges_timedMessage_changes_after_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface GetTimedMessageChanges_timedMessage_changes_after_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: GetTimedMessageChanges_timedMessage_changes_after_catalogs_addresses[];
}

export interface GetTimedMessageChanges_timedMessage_changes_after {
  customer: GetTimedMessageChanges_timedMessage_changes_after_customer | null;
  changedBy: string | null;
  startTime: GQL_DateTime | null;
  endTime: GQL_DateTime | null;
  message: string | null;
  global: boolean | null;
  globalType: CatalogGlobalType | null;
  active: boolean | null;
  catalogs: GetTimedMessageChanges_timedMessage_changes_after_catalogs[] | null;
}

export interface GetTimedMessageChanges_timedMessage_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: GetTimedMessageChanges_timedMessage_changes_user;
  /**
   * Value before
   */
  before: GetTimedMessageChanges_timedMessage_changes_before;
  /**
   * Value after
   */
  after: GetTimedMessageChanges_timedMessage_changes_after;
}

export interface GetTimedMessageChanges_timedMessage {
  /**
   * id to display
   */
  id: string;
  /**
   * Timed message Change log
   */
  changes: GetTimedMessageChanges_timedMessage_changes[];
}

export interface GetTimedMessageChanges {
  timedMessage: GetTimedMessageChanges_timedMessage;
}

export interface GetTimedMessageChangesVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTimedMessages
// ====================================================

export interface GetTimedMessages_timedMessages_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface GetTimedMessages_timedMessages_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface GetTimedMessages_timedMessages_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: GetTimedMessages_timedMessages_catalogs_addresses[];
}

export interface GetTimedMessages_timedMessages {
  /**
   * id to display
   */
  id: string;
  /**
   * Customer
   */
  customer: GetTimedMessages_timedMessages_customer;
  /**
   * Craftsman changed by
   */
  changedBy: string;
  /**
   * Time at which the message should show from
   */
  startTime: GQL_DateTime;
  /**
   * Time at which the message should stop showing
   */
  endTime: GQL_DateTime;
  /**
   * message to display
   */
  message: string;
  /**
   * Is timed message global
   */
  global: boolean;
  /**
   * Global type for timed message
   */
  globalType: CatalogGlobalType;
  /**
   * Is timed message active
   */
  active: boolean;
  /**
   * Version for timed message
   */
  version: number;
  /**
   * Catalog ids
   */
  catalogIds: string[];
  /**
   * Catalogs that uses timed message
   */
  catalogs: GetTimedMessages_timedMessages_catalogs[] | null;
}

export interface GetTimedMessages {
  timedMessages: GetTimedMessages_timedMessages[];
}

export interface GetTimedMessagesVariables {
  globalOnly?: boolean | null;
  fromDate?: GQL_Date | null;
  toDate?: GQL_Date | null;
  catalogId?: string | null;
  customerIds?: string[] | null;
  thisCustomerOnly?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTimedMessagesForAddExcisting
// ====================================================

export interface GetTimedMessagesForAddExcisting_timedMessages {
  /**
   * id to display
   */
  id: string;
  /**
   * Time at which the message should show from
   */
  startTime: GQL_DateTime;
  /**
   * Time at which the message should stop showing
   */
  endTime: GQL_DateTime;
  /**
   * message to display
   */
  message: string;
}

export interface GetTimedMessagesForAddExcisting {
  timedMessages: GetTimedMessagesForAddExcisting_timedMessages[];
}

export interface GetTimedMessagesForAddExcistingVariables {
  customerIds?: string[] | null;
  notGlobalOnly?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTimedMessage
// ====================================================

export interface UpdateTimedMessage_updateTimedMessage_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface UpdateTimedMessage_updateTimedMessage {
  /**
   * id to display
   */
  id: string;
  /**
   * Customer
   */
  customer: UpdateTimedMessage_updateTimedMessage_customer;
  /**
   * Craftsman changed by
   */
  changedBy: string;
  /**
   * Time at which the message should show from
   */
  startTime: GQL_DateTime;
  /**
   * Time at which the message should stop showing
   */
  endTime: GQL_DateTime;
  /**
   * message to display
   */
  message: string;
  /**
   * Is timed message global
   */
  global: boolean;
  /**
   * Global type for timed message
   */
  globalType: CatalogGlobalType;
  /**
   * Is timed message active
   */
  active: boolean;
  /**
   * Version for timed message
   */
  version: number;
  /**
   * Catalog ids
   */
  catalogIds: string[];
}

export interface UpdateTimedMessage {
  updateTimedMessage: UpdateTimedMessage_updateTimedMessage;
}

export interface UpdateTimedMessageVariables {
  id: string;
  customer: string;
  changedBy: string;
  startTime: GQL_DateTime;
  endTime: GQL_DateTime;
  message: string;
  global: boolean;
  active: boolean;
  globalType: CatalogGlobalType;
  catalogs: string[];
  action: FileActionType;
  catalogsBefore?: (string | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTimeTracking
// ====================================================

export interface CreateTimeTracking_createTimeTracking_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface CreateTimeTracking_createTimeTracking_case_debitor {
  /**
   * Company identifier
   */
  company: string;
}

export interface CreateTimeTracking_createTimeTracking_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface CreateTimeTracking_createTimeTracking_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface CreateTimeTracking_createTimeTracking_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
}

export interface CreateTimeTracking_createTimeTracking_case_damage_contact {
  /**
   * Contact address information
   */
  address: CreateTimeTracking_createTimeTracking_case_damage_contact_address;
}

export interface CreateTimeTracking_createTimeTracking_case_damage {
  /**
   * Damage category
   */
  category: CreateTimeTracking_createTimeTracking_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: CreateTimeTracking_createTimeTracking_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: CreateTimeTracking_createTimeTracking_case_damage_contact;
}

export interface CreateTimeTracking_createTimeTracking_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: CreateTimeTracking_createTimeTracking_case_debitor;
  /**
   * Case damage details
   */
  damage: CreateTimeTracking_createTimeTracking_case_damage;
}

export interface CreateTimeTracking_createTimeTracking_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface CreateTimeTracking_createTimeTracking_addonLines_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface CreateTimeTracking_createTimeTracking_addonLines_case_debitor {
  /**
   * Company identifier
   */
  company: string;
}

export interface CreateTimeTracking_createTimeTracking_addonLines_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: CreateTimeTracking_createTimeTracking_addonLines_case_debitor;
}

export interface CreateTimeTracking_createTimeTracking_addonLines_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface CreateTimeTracking_createTimeTracking_addonLines {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * AD user
   */
  user: CreateTimeTracking_createTimeTracking_addonLines_user;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: CreateTimeTracking_createTimeTracking_addonLines_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: CreateTimeTracking_createTimeTracking_addonLines_drivingSlip | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
}

export interface CreateTimeTracking_createTimeTracking {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: CreateTimeTracking_createTimeTracking_user;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Case for time tracking
   */
  case: CreateTimeTracking_createTimeTracking_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: CreateTimeTracking_createTimeTracking_drivingSlip | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
  /**
   * List of addon lines
   */
  addonLines: CreateTimeTracking_createTimeTracking_addonLines[] | null;
}

export interface CreateTimeTracking {
  createTimeTracking: CreateTimeTracking_createTimeTracking;
}

export interface CreateTimeTrackingVariables {
  timeTracking: TimeTrackingInputType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteTimeTracking
// ====================================================

export interface DeleteTimeTracking_deleteTimeTracking {
  /**
   * This value is not used for anything.
   */
  _: string;
}

export interface DeleteTimeTracking {
  deleteTimeTracking: DeleteTimeTracking_deleteTimeTracking | null;
}

export interface DeleteTimeTrackingVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetClosedDayByUser
// ====================================================

export interface GetClosedDayByUser_closedDayByUser_user {
  /**
   * Unique id of user
   */
  id: string;
}

export interface GetClosedDayByUser_closedDayByUser {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * AD user
   */
  user: GetClosedDayByUser_closedDayByUser_user;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
}

export interface GetClosedDayByUser {
  closedDayByUser: GetClosedDayByUser_closedDayByUser | null;
}

export interface GetClosedDayByUserVariables {
  user: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLatestDayByUser
// ====================================================

export interface GetLatestDayByUser_latestDayByUser {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
}

export interface GetLatestDayByUser {
  latestDayByUser: GetLatestDayByUser_latestDayByUser | null;
}

export interface GetLatestDayByUserVariables {
  user: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetManagerClosedDayUserList
// ====================================================

export interface GetManagerClosedDayUserList_closedDayManagerList_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
}

export interface GetManagerClosedDayUserList_closedDayManagerList {
  /**
   * User object
   */
  user: GetManagerClosedDayUserList_closedDayManagerList_user;
  /**
   * Last closed date for user
   */
  date: GQL_Date;
}

export interface GetManagerClosedDayUserList {
  closedDayManagerList: GetManagerClosedDayUserList_closedDayManagerList[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetManagerFlexTimeList
// ====================================================

export interface GetManagerFlexTimeList_flexTimeManagerList_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
}

export interface GetManagerFlexTimeList_flexTimeManagerList {
  /**
   * User object
   */
  user: GetManagerFlexTimeList_flexTimeManagerList_user;
  /**
   * Flex time for the user
   */
  flexTime: GQL_Decimal;
}

export interface GetManagerFlexTimeList {
  flexTimeManagerList: GetManagerFlexTimeList_flexTimeManagerList[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPaymentSupplements
// ====================================================

export interface GetPaymentSupplements_paymentSupplements {
  /**
   * Id of payment supplement
   */
  id: string;
  /**
   * Payment supplement code
   */
  code: string | null;
  /**
   * Payment supplement type
   */
  type: string | null;
  /**
   * Description
   */
  description: string;
  /**
   * Template JobNo indicates if supplement is internal or external
   */
  templateJobNo: string;
  /**
   * Blocked
   */
  blocked: boolean | null;
  /**
   * Unit of measure
   */
  uOM: string;
  /**
   * Work type for the supplement
   */
  workTypeCode: string;
}

export interface GetPaymentSupplements {
  paymentSupplements: (GetPaymentSupplements_paymentSupplements | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRejectedTimeTrackingsForUser
// ====================================================

export interface GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_case_debitor {
  /**
   * Company identifier
   */
  company: string;
}

export interface GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
}

export interface GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_case_damage_contact {
  /**
   * Contact address information
   */
  address: GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_case_damage_contact_address;
}

export interface GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_case_damage {
  /**
   * Damage category
   */
  category: GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_case_damage_contact;
}

export interface GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_case_debitor;
  /**
   * Case damage details
   */
  damage: GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_case_damage;
}

export interface GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_addonLines_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_addonLines_case_debitor {
  /**
   * Company identifier
   */
  company: string;
}

export interface GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_addonLines_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_addonLines_case_debitor;
}

export interface GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_addonLines_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_addonLines {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * AD user
   */
  user: GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_addonLines_user;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_addonLines_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_addonLines_drivingSlip | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
}

export interface GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_user;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Case for time tracking
   */
  case: GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_drivingSlip | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
  /**
   * List of addon lines
   */
  addonLines: GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_addonLines[] | null;
}

export interface GetRejectedTimeTrackingsForUser {
  /**
   * Gets all the rejected time trackings for a given user. It looks in the database and in BC to get all rejections
   */
  rejectedTimeTrackingsForUser: GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser[];
}

export interface GetRejectedTimeTrackingsForUserVariables {
  user: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTimeTracking
// ====================================================

export interface GetTimeTracking_timeTracking_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetTimeTracking_timeTracking_case_debitor {
  /**
   * Company identifier
   */
  company: string;
}

export interface GetTimeTracking_timeTracking_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetTimeTracking_timeTracking_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface GetTimeTracking_timeTracking_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
}

export interface GetTimeTracking_timeTracking_case_damage_contact {
  /**
   * Contact address information
   */
  address: GetTimeTracking_timeTracking_case_damage_contact_address;
}

export interface GetTimeTracking_timeTracking_case_damage {
  /**
   * Damage category
   */
  category: GetTimeTracking_timeTracking_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetTimeTracking_timeTracking_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: GetTimeTracking_timeTracking_case_damage_contact;
}

export interface GetTimeTracking_timeTracking_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetTimeTracking_timeTracking_case_debitor;
  /**
   * Case damage details
   */
  damage: GetTimeTracking_timeTracking_case_damage;
}

export interface GetTimeTracking_timeTracking_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface GetTimeTracking_timeTracking_addonLines_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetTimeTracking_timeTracking_addonLines_case_debitor {
  /**
   * Company identifier
   */
  company: string;
}

export interface GetTimeTracking_timeTracking_addonLines_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetTimeTracking_timeTracking_addonLines_case_debitor;
}

export interface GetTimeTracking_timeTracking_addonLines_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface GetTimeTracking_timeTracking_addonLines {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * AD user
   */
  user: GetTimeTracking_timeTracking_addonLines_user;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: GetTimeTracking_timeTracking_addonLines_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: GetTimeTracking_timeTracking_addonLines_drivingSlip | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
}

export interface GetTimeTracking_timeTracking {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: GetTimeTracking_timeTracking_user;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Case for time tracking
   */
  case: GetTimeTracking_timeTracking_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: GetTimeTracking_timeTracking_drivingSlip | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
  /**
   * List of addon lines
   */
  addonLines: GetTimeTracking_timeTracking_addonLines[] | null;
}

export interface GetTimeTracking {
  timeTracking: GetTimeTracking_timeTracking;
}

export interface GetTimeTrackingVariables {
  id: string;
  minDate?: GQL_Date | null;
  maxDate?: GQL_Date | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTimeTrackingsForUser
// ====================================================

export interface GetTimeTrackingsForUser_timeTrackingsForUser_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetTimeTrackingsForUser_timeTrackingsForUser_case_debitor {
  /**
   * Company identifier
   */
  company: string;
}

export interface GetTimeTrackingsForUser_timeTrackingsForUser_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetTimeTrackingsForUser_timeTrackingsForUser_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface GetTimeTrackingsForUser_timeTrackingsForUser_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
}

export interface GetTimeTrackingsForUser_timeTrackingsForUser_case_damage_contact {
  /**
   * Contact address information
   */
  address: GetTimeTrackingsForUser_timeTrackingsForUser_case_damage_contact_address;
}

export interface GetTimeTrackingsForUser_timeTrackingsForUser_case_damage {
  /**
   * Damage category
   */
  category: GetTimeTrackingsForUser_timeTrackingsForUser_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetTimeTrackingsForUser_timeTrackingsForUser_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: GetTimeTrackingsForUser_timeTrackingsForUser_case_damage_contact;
}

export interface GetTimeTrackingsForUser_timeTrackingsForUser_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetTimeTrackingsForUser_timeTrackingsForUser_case_debitor;
  /**
   * Case damage details
   */
  damage: GetTimeTrackingsForUser_timeTrackingsForUser_case_damage;
}

export interface GetTimeTrackingsForUser_timeTrackingsForUser_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface GetTimeTrackingsForUser_timeTrackingsForUser_addonLines_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetTimeTrackingsForUser_timeTrackingsForUser_addonLines_case_debitor {
  /**
   * Company identifier
   */
  company: string;
}

export interface GetTimeTrackingsForUser_timeTrackingsForUser_addonLines_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetTimeTrackingsForUser_timeTrackingsForUser_addonLines_case_debitor;
}

export interface GetTimeTrackingsForUser_timeTrackingsForUser_addonLines_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface GetTimeTrackingsForUser_timeTrackingsForUser_addonLines {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * AD user
   */
  user: GetTimeTrackingsForUser_timeTrackingsForUser_addonLines_user;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: GetTimeTrackingsForUser_timeTrackingsForUser_addonLines_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: GetTimeTrackingsForUser_timeTrackingsForUser_addonLines_drivingSlip | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
}

export interface GetTimeTrackingsForUser_timeTrackingsForUser {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: GetTimeTrackingsForUser_timeTrackingsForUser_user;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Case for time tracking
   */
  case: GetTimeTrackingsForUser_timeTrackingsForUser_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: GetTimeTrackingsForUser_timeTrackingsForUser_drivingSlip | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
  /**
   * List of addon lines
   */
  addonLines: GetTimeTrackingsForUser_timeTrackingsForUser_addonLines[] | null;
}

export interface GetTimeTrackingsForUser {
  timeTrackingsForUser: GetTimeTrackingsForUser_timeTrackingsForUser[];
}

export interface GetTimeTrackingsForUserVariables {
  user: string;
  minDate?: GQL_Date | null;
  maxDate?: GQL_Date | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTimeTrackings
// ====================================================

export interface GetTimeTrackings_timeTrackings_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetTimeTrackings_timeTrackings_case_debitor {
  /**
   * Company identifier
   */
  company: string;
}

export interface GetTimeTrackings_timeTrackings_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetTimeTrackings_timeTrackings_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface GetTimeTrackings_timeTrackings_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
}

export interface GetTimeTrackings_timeTrackings_case_damage_contact {
  /**
   * Contact address information
   */
  address: GetTimeTrackings_timeTrackings_case_damage_contact_address;
}

export interface GetTimeTrackings_timeTrackings_case_damage {
  /**
   * Damage category
   */
  category: GetTimeTrackings_timeTrackings_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetTimeTrackings_timeTrackings_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: GetTimeTrackings_timeTrackings_case_damage_contact;
}

export interface GetTimeTrackings_timeTrackings_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetTimeTrackings_timeTrackings_case_debitor;
  /**
   * Case damage details
   */
  damage: GetTimeTrackings_timeTrackings_case_damage;
}

export interface GetTimeTrackings_timeTrackings_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface GetTimeTrackings_timeTrackings_addonLines_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetTimeTrackings_timeTrackings_addonLines_case_debitor {
  /**
   * Company identifier
   */
  company: string;
}

export interface GetTimeTrackings_timeTrackings_addonLines_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetTimeTrackings_timeTrackings_addonLines_case_debitor;
}

export interface GetTimeTrackings_timeTrackings_addonLines_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface GetTimeTrackings_timeTrackings_addonLines {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * AD user
   */
  user: GetTimeTrackings_timeTrackings_addonLines_user;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: GetTimeTrackings_timeTrackings_addonLines_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: GetTimeTrackings_timeTrackings_addonLines_drivingSlip | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
}

export interface GetTimeTrackings_timeTrackings {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: GetTimeTrackings_timeTrackings_user;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Case for time tracking
   */
  case: GetTimeTrackings_timeTrackings_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: GetTimeTrackings_timeTrackings_drivingSlip | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
  /**
   * List of addon lines
   */
  addonLines: GetTimeTrackings_timeTrackings_addonLines[] | null;
}

export interface GetTimeTrackings {
  timeTrackings: GetTimeTrackings_timeTrackings[];
}

export interface GetTimeTrackingsVariables {
  trackingType?: TrackingType | null;
  minDate?: GQL_Date | null;
  maxDate?: GQL_Date | null;
  users?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWebCasesForTimeTracking
// ====================================================

export interface GetWebCasesForTimeTracking_cases_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebCasesForTimeTracking_cases_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebCasesForTimeTracking_cases_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: GetWebCasesForTimeTracking_cases_debitor_shippingAddress_address;
}

export interface GetWebCasesForTimeTracking_cases_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebCasesForTimeTracking_cases_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: GetWebCasesForTimeTracking_cases_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: GetWebCasesForTimeTracking_cases_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: GetWebCasesForTimeTracking_cases_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface GetWebCasesForTimeTracking_cases_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetWebCasesForTimeTracking_cases_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetWebCasesForTimeTracking_cases_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetWebCasesForTimeTracking_cases_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetWebCasesForTimeTracking_cases_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetWebCasesForTimeTracking_cases_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: GetWebCasesForTimeTracking_cases_damage_cause_departments_location;
}

export interface GetWebCasesForTimeTracking_cases_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: GetWebCasesForTimeTracking_cases_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: GetWebCasesForTimeTracking_cases_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (GetWebCasesForTimeTracking_cases_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface GetWebCasesForTimeTracking_cases_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetWebCasesForTimeTracking_cases_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetWebCasesForTimeTracking_cases_damage_contact_address;
}

export interface GetWebCasesForTimeTracking_cases_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface GetWebCasesForTimeTracking_cases_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: GetWebCasesForTimeTracking_cases_damage_businessArea;
  /**
   * Damage category
   */
  category: GetWebCasesForTimeTracking_cases_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetWebCasesForTimeTracking_cases_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: GetWebCasesForTimeTracking_cases_damage_contact;
  /**
   * contacts for damage
   */
  contacts: GetWebCasesForTimeTracking_cases_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface GetWebCasesForTimeTracking_cases_caseEconomics {
  /**
   * JobNo
   */
  jobNo: string;
  /**
   * costTotal
   */
  costTotal: GQL_Decimal;
  /**
   * salesTotal
   */
  salesTotal: GQL_Decimal;
  /**
   * invoicedTotal
   */
  invoicedTotal: GQL_Decimal;
  /**
   * quote
   */
  quote: GQL_Decimal;
  /**
   * screening
   */
  screening: GQL_Decimal;
  /**
   * profit
   */
  profit: GQL_Decimal;
  /**
   * deltaNotInvoiced
   */
  deltaNotInvoiced: GQL_Decimal;
  /**
   * coverageRatio
   */
  coverageRatio: GQL_Decimal;
}

export interface GetWebCasesForTimeTracking_cases {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * Whether time registration is allowed on the case
   */
  timeRegistration: boolean | null;
  /**
   * Case debitor details
   */
  debitor: GetWebCasesForTimeTracking_cases_debitor;
  /**
   * Case damage details
   */
  damage: GetWebCasesForTimeTracking_cases_damage;
  caseEconomics: GetWebCasesForTimeTracking_cases_caseEconomics | null;
}

export interface GetWebCasesForTimeTracking {
  cases: GetWebCasesForTimeTracking_cases[];
}

export interface GetWebCasesForTimeTrackingVariables {
  erpReferenceNos?: string[] | null;
  appliedClosed?: boolean | null;
  closedCases?: boolean | null;
  includeEconomy: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: OpenRejectedDay
// ====================================================

export interface OpenRejectedDay_openRejectedDay_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface OpenRejectedDay_openRejectedDay_case_debitor {
  /**
   * Company identifier
   */
  company: string;
}

export interface OpenRejectedDay_openRejectedDay_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface OpenRejectedDay_openRejectedDay_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface OpenRejectedDay_openRejectedDay_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
}

export interface OpenRejectedDay_openRejectedDay_case_damage_contact {
  /**
   * Contact address information
   */
  address: OpenRejectedDay_openRejectedDay_case_damage_contact_address;
}

export interface OpenRejectedDay_openRejectedDay_case_damage {
  /**
   * Damage category
   */
  category: OpenRejectedDay_openRejectedDay_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: OpenRejectedDay_openRejectedDay_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: OpenRejectedDay_openRejectedDay_case_damage_contact;
}

export interface OpenRejectedDay_openRejectedDay_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: OpenRejectedDay_openRejectedDay_case_debitor;
  /**
   * Case damage details
   */
  damage: OpenRejectedDay_openRejectedDay_case_damage;
}

export interface OpenRejectedDay_openRejectedDay_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface OpenRejectedDay_openRejectedDay_addonLines_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface OpenRejectedDay_openRejectedDay_addonLines_case_debitor {
  /**
   * Company identifier
   */
  company: string;
}

export interface OpenRejectedDay_openRejectedDay_addonLines_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: OpenRejectedDay_openRejectedDay_addonLines_case_debitor;
}

export interface OpenRejectedDay_openRejectedDay_addonLines_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface OpenRejectedDay_openRejectedDay_addonLines {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * AD user
   */
  user: OpenRejectedDay_openRejectedDay_addonLines_user;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: OpenRejectedDay_openRejectedDay_addonLines_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: OpenRejectedDay_openRejectedDay_addonLines_drivingSlip | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
}

export interface OpenRejectedDay_openRejectedDay {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: OpenRejectedDay_openRejectedDay_user;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Case for time tracking
   */
  case: OpenRejectedDay_openRejectedDay_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: OpenRejectedDay_openRejectedDay_drivingSlip | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
  /**
   * List of addon lines
   */
  addonLines: OpenRejectedDay_openRejectedDay_addonLines[] | null;
}

export interface OpenRejectedDay {
  openRejectedDay: OpenRejectedDay_openRejectedDay[];
}

export interface OpenRejectedDayVariables {
  userEmail: string;
  date: GQL_Date;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OpenTimeRegistrationsOnCase
// ====================================================

export interface OpenTimeRegistrationsOnCase {
  openTimeRegistrationsOnCase: boolean;
}

export interface OpenTimeRegistrationsOnCaseVariables {
  caseERPReferenceNo: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SubmitDay
// ====================================================

export interface SubmitDay_submitDay_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface SubmitDay_submitDay_case_debitor {
  /**
   * Company identifier
   */
  company: string;
}

export interface SubmitDay_submitDay_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface SubmitDay_submitDay_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface SubmitDay_submitDay_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
}

export interface SubmitDay_submitDay_case_damage_contact {
  /**
   * Contact address information
   */
  address: SubmitDay_submitDay_case_damage_contact_address;
}

export interface SubmitDay_submitDay_case_damage {
  /**
   * Damage category
   */
  category: SubmitDay_submitDay_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: SubmitDay_submitDay_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: SubmitDay_submitDay_case_damage_contact;
}

export interface SubmitDay_submitDay_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: SubmitDay_submitDay_case_debitor;
  /**
   * Case damage details
   */
  damage: SubmitDay_submitDay_case_damage;
}

export interface SubmitDay_submitDay_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface SubmitDay_submitDay_addonLines_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface SubmitDay_submitDay_addonLines_case_debitor {
  /**
   * Company identifier
   */
  company: string;
}

export interface SubmitDay_submitDay_addonLines_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: SubmitDay_submitDay_addonLines_case_debitor;
}

export interface SubmitDay_submitDay_addonLines_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface SubmitDay_submitDay_addonLines {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * AD user
   */
  user: SubmitDay_submitDay_addonLines_user;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: SubmitDay_submitDay_addonLines_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: SubmitDay_submitDay_addonLines_drivingSlip | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
}

export interface SubmitDay_submitDay {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: SubmitDay_submitDay_user;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Case for time tracking
   */
  case: SubmitDay_submitDay_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: SubmitDay_submitDay_drivingSlip | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
  /**
   * List of addon lines
   */
  addonLines: SubmitDay_submitDay_addonLines[] | null;
}

export interface SubmitDay {
  submitDay: SubmitDay_submitDay | null;
}

export interface SubmitDayVariables {
  timeTracking: TimeTrackingInputType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTimeTracking
// ====================================================

export interface UpdateTimeTracking_updateTimeTracking_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface UpdateTimeTracking_updateTimeTracking_case_debitor {
  /**
   * Company identifier
   */
  company: string;
}

export interface UpdateTimeTracking_updateTimeTracking_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface UpdateTimeTracking_updateTimeTracking_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface UpdateTimeTracking_updateTimeTracking_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
}

export interface UpdateTimeTracking_updateTimeTracking_case_damage_contact {
  /**
   * Contact address information
   */
  address: UpdateTimeTracking_updateTimeTracking_case_damage_contact_address;
}

export interface UpdateTimeTracking_updateTimeTracking_case_damage {
  /**
   * Damage category
   */
  category: UpdateTimeTracking_updateTimeTracking_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: UpdateTimeTracking_updateTimeTracking_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: UpdateTimeTracking_updateTimeTracking_case_damage_contact;
}

export interface UpdateTimeTracking_updateTimeTracking_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: UpdateTimeTracking_updateTimeTracking_case_debitor;
  /**
   * Case damage details
   */
  damage: UpdateTimeTracking_updateTimeTracking_case_damage;
}

export interface UpdateTimeTracking_updateTimeTracking_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface UpdateTimeTracking_updateTimeTracking_addonLines_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface UpdateTimeTracking_updateTimeTracking_addonLines_case_debitor {
  /**
   * Company identifier
   */
  company: string;
}

export interface UpdateTimeTracking_updateTimeTracking_addonLines_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: UpdateTimeTracking_updateTimeTracking_addonLines_case_debitor;
}

export interface UpdateTimeTracking_updateTimeTracking_addonLines_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface UpdateTimeTracking_updateTimeTracking_addonLines {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * AD user
   */
  user: UpdateTimeTracking_updateTimeTracking_addonLines_user;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: UpdateTimeTracking_updateTimeTracking_addonLines_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: UpdateTimeTracking_updateTimeTracking_addonLines_drivingSlip | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
}

export interface UpdateTimeTracking_updateTimeTracking {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: UpdateTimeTracking_updateTimeTracking_user;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Case for time tracking
   */
  case: UpdateTimeTracking_updateTimeTracking_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: UpdateTimeTracking_updateTimeTracking_drivingSlip | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
  /**
   * List of addon lines
   */
  addonLines: UpdateTimeTracking_updateTimeTracking_addonLines[] | null;
}

export interface UpdateTimeTracking {
  updateTimeTracking: UpdateTimeTracking_updateTimeTracking;
}

export interface UpdateTimeTrackingVariables {
  id: string;
  user: string;
  date: GQL_Date;
  hours: number;
  case: string;
  drivingSlip: string;
  remark: string;
  jobTaskNo: string;
  jobTaskName?: string | null;
  workTypeCode: string;
  timeTrackingStatus: TimeTrackingStatus;
  pieceworkAmount?: number | null;
  paymentSupplementCode?: string | null;
  paymentSupplementName?: string | null;
  supplementType?: string | null;
  supplementUom?: string | null;
  addonLines?: TimeTrackingInputType[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserBasisTime
// ====================================================

export interface UserBasisTime {
  userBasisTime: number;
}

export interface UserBasisTimeVariables {
  user: string;
  date: GQL_Date;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AppendTimeTrackingSupplement
// ====================================================

export interface AppendTimeTrackingSupplement_appendTimeTrackingSupplement_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User employee id
   */
  employeeId: string | null;
}

export interface AppendTimeTrackingSupplement_appendTimeTrackingSupplement_timeTrackingEntries_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface AppendTimeTrackingSupplement_appendTimeTrackingSupplement_timeTrackingEntries_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Company identifier
   */
  company: string;
}

export interface AppendTimeTrackingSupplement_appendTimeTrackingSupplement_timeTrackingEntries_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface AppendTimeTrackingSupplement_appendTimeTrackingSupplement_timeTrackingEntries_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface AppendTimeTrackingSupplement_appendTimeTrackingSupplement_timeTrackingEntries_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
}

export interface AppendTimeTrackingSupplement_appendTimeTrackingSupplement_timeTrackingEntries_case_damage_contact {
  /**
   * Contact address information
   */
  address: AppendTimeTrackingSupplement_appendTimeTrackingSupplement_timeTrackingEntries_case_damage_contact_address;
}

export interface AppendTimeTrackingSupplement_appendTimeTrackingSupplement_timeTrackingEntries_case_damage {
  /**
   * Damage category
   */
  category: AppendTimeTrackingSupplement_appendTimeTrackingSupplement_timeTrackingEntries_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: AppendTimeTrackingSupplement_appendTimeTrackingSupplement_timeTrackingEntries_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: AppendTimeTrackingSupplement_appendTimeTrackingSupplement_timeTrackingEntries_case_damage_contact;
}

export interface AppendTimeTrackingSupplement_appendTimeTrackingSupplement_timeTrackingEntries_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: AppendTimeTrackingSupplement_appendTimeTrackingSupplement_timeTrackingEntries_case_debitor;
  /**
   * Case damage details
   */
  damage: AppendTimeTrackingSupplement_appendTimeTrackingSupplement_timeTrackingEntries_case_damage;
}

export interface AppendTimeTrackingSupplement_appendTimeTrackingSupplement_timeTrackingEntries_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface AppendTimeTrackingSupplement_appendTimeTrackingSupplement_timeTrackingEntries_lastModifiedBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface AppendTimeTrackingSupplement_appendTimeTrackingSupplement_timeTrackingEntries_addonLines_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface AppendTimeTrackingSupplement_appendTimeTrackingSupplement_timeTrackingEntries_addonLines_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Company identifier
   */
  company: string;
}

export interface AppendTimeTrackingSupplement_appendTimeTrackingSupplement_timeTrackingEntries_addonLines_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: AppendTimeTrackingSupplement_appendTimeTrackingSupplement_timeTrackingEntries_addonLines_case_debitor;
}

export interface AppendTimeTrackingSupplement_appendTimeTrackingSupplement_timeTrackingEntries_addonLines {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: AppendTimeTrackingSupplement_appendTimeTrackingSupplement_timeTrackingEntries_addonLines_user;
  userId: string;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: AppendTimeTrackingSupplement_appendTimeTrackingSupplement_timeTrackingEntries_addonLines_case | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Was the task on callback
   */
  onCallback: boolean;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * bcStatus
   */
  bcStatus: TimeTrackingStatus;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
}

export interface AppendTimeTrackingSupplement_appendTimeTrackingSupplement_timeTrackingEntries {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: AppendTimeTrackingSupplement_appendTimeTrackingSupplement_timeTrackingEntries_user;
  userId: string;
  /**
   * Start date for time tracking
   */
  startDate: GQL_Date;
  /**
   * End date for time tracking
   */
  endDate: GQL_Date;
  /**
   * Start time for time tracking
   */
  startTime: string;
  /**
   * End time for time tracking
   */
  endTime: string;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: AppendTimeTrackingSupplement_appendTimeTrackingSupplement_timeTrackingEntries_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: AppendTimeTrackingSupplement_appendTimeTrackingSupplement_timeTrackingEntries_drivingSlip | null;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Was the task on callback
   */
  onCallback: boolean;
  /**
   * Does the entry include lunch
   */
  includesLunch: boolean;
  /**
   * task
   */
  task: TimeTrackingEntryTask;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * BC status
   */
  bcStatus: TimeTrackingStatus | null;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Timestamp of when the entry was last modified
   */
  lastModifiedAt: GQL_DateTime | null;
  /**
   * User who last modified the entry
   */
  lastModifiedBy: AppendTimeTrackingSupplement_appendTimeTrackingSupplement_timeTrackingEntries_lastModifiedBy | null;
  /**
   * Add on supplementLines
   */
  addonLines: AppendTimeTrackingSupplement_appendTimeTrackingSupplement_timeTrackingEntries_addonLines[];
}

export interface AppendTimeTrackingSupplement_appendTimeTrackingSupplement {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * Type of open, closed or rejected
   */
  timeTrackingDayStatus: TimeTrackingDayStatus;
  /**
   * AD user
   */
  user: AppendTimeTrackingSupplement_appendTimeTrackingSupplement_user;
  userId: string;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Total hours
   */
  totalHours: number;
  /**
   * Total Overtime hours
   */
  overtimeHours: number;
  /**
   * Indicates if day is part of range
   */
  isPartOfRange: boolean;
  /**
   * Start date for range
   */
  rangeStartDate: GQL_Date | null;
  /**
   * End date for range
   */
  rangeEndDate: GQL_Date | null;
  /**
   * Time Tracking Entries
   */
  timeTrackingEntries: AppendTimeTrackingSupplement_appendTimeTrackingSupplement_timeTrackingEntries[];
}

export interface AppendTimeTrackingSupplement {
  appendTimeTrackingSupplement: AppendTimeTrackingSupplement_appendTimeTrackingSupplement;
}

export interface AppendTimeTrackingSupplementVariables {
  timeTrackingDayId: string;
  timeTrackingEntryId: string;
  timeTrackingSupplement: TimeTrackingSupplementUpdateInputType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTimeTrackingDay
// ====================================================

export interface CreateTimeTrackingDay_createTimeTrackingDay_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User employee id
   */
  employeeId: string | null;
}

export interface CreateTimeTrackingDay_createTimeTrackingDay_timeTrackingEntries_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface CreateTimeTrackingDay_createTimeTrackingDay_timeTrackingEntries_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Company identifier
   */
  company: string;
}

export interface CreateTimeTrackingDay_createTimeTrackingDay_timeTrackingEntries_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface CreateTimeTrackingDay_createTimeTrackingDay_timeTrackingEntries_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface CreateTimeTrackingDay_createTimeTrackingDay_timeTrackingEntries_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
}

export interface CreateTimeTrackingDay_createTimeTrackingDay_timeTrackingEntries_case_damage_contact {
  /**
   * Contact address information
   */
  address: CreateTimeTrackingDay_createTimeTrackingDay_timeTrackingEntries_case_damage_contact_address;
}

export interface CreateTimeTrackingDay_createTimeTrackingDay_timeTrackingEntries_case_damage {
  /**
   * Damage category
   */
  category: CreateTimeTrackingDay_createTimeTrackingDay_timeTrackingEntries_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: CreateTimeTrackingDay_createTimeTrackingDay_timeTrackingEntries_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: CreateTimeTrackingDay_createTimeTrackingDay_timeTrackingEntries_case_damage_contact;
}

export interface CreateTimeTrackingDay_createTimeTrackingDay_timeTrackingEntries_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: CreateTimeTrackingDay_createTimeTrackingDay_timeTrackingEntries_case_debitor;
  /**
   * Case damage details
   */
  damage: CreateTimeTrackingDay_createTimeTrackingDay_timeTrackingEntries_case_damage;
}

export interface CreateTimeTrackingDay_createTimeTrackingDay_timeTrackingEntries_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface CreateTimeTrackingDay_createTimeTrackingDay_timeTrackingEntries_lastModifiedBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface CreateTimeTrackingDay_createTimeTrackingDay_timeTrackingEntries_addonLines_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface CreateTimeTrackingDay_createTimeTrackingDay_timeTrackingEntries_addonLines_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Company identifier
   */
  company: string;
}

export interface CreateTimeTrackingDay_createTimeTrackingDay_timeTrackingEntries_addonLines_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: CreateTimeTrackingDay_createTimeTrackingDay_timeTrackingEntries_addonLines_case_debitor;
}

export interface CreateTimeTrackingDay_createTimeTrackingDay_timeTrackingEntries_addonLines {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: CreateTimeTrackingDay_createTimeTrackingDay_timeTrackingEntries_addonLines_user;
  userId: string;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: CreateTimeTrackingDay_createTimeTrackingDay_timeTrackingEntries_addonLines_case | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Was the task on callback
   */
  onCallback: boolean;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * bcStatus
   */
  bcStatus: TimeTrackingStatus;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
}

export interface CreateTimeTrackingDay_createTimeTrackingDay_timeTrackingEntries {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: CreateTimeTrackingDay_createTimeTrackingDay_timeTrackingEntries_user;
  userId: string;
  /**
   * Start date for time tracking
   */
  startDate: GQL_Date;
  /**
   * End date for time tracking
   */
  endDate: GQL_Date;
  /**
   * Start time for time tracking
   */
  startTime: string;
  /**
   * End time for time tracking
   */
  endTime: string;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: CreateTimeTrackingDay_createTimeTrackingDay_timeTrackingEntries_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: CreateTimeTrackingDay_createTimeTrackingDay_timeTrackingEntries_drivingSlip | null;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Was the task on callback
   */
  onCallback: boolean;
  /**
   * Does the entry include lunch
   */
  includesLunch: boolean;
  /**
   * task
   */
  task: TimeTrackingEntryTask;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * BC status
   */
  bcStatus: TimeTrackingStatus | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Timestamp of when the entry was last modified
   */
  lastModifiedAt: GQL_DateTime | null;
  /**
   * User who last modified the entry
   */
  lastModifiedBy: CreateTimeTrackingDay_createTimeTrackingDay_timeTrackingEntries_lastModifiedBy | null;
  /**
   * Add on supplementLines
   */
  addonLines: CreateTimeTrackingDay_createTimeTrackingDay_timeTrackingEntries_addonLines[];
}

export interface CreateTimeTrackingDay_createTimeTrackingDay {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * Type of open, closed or rejected
   */
  timeTrackingDayStatus: TimeTrackingDayStatus;
  /**
   * AD user
   */
  user: CreateTimeTrackingDay_createTimeTrackingDay_user;
  userId: string;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Total hours
   */
  totalHours: number;
  /**
   * Total Overtime hours
   */
  overtimeHours: number;
  /**
   * Indicates if day is part of range
   */
  isPartOfRange: boolean;
  /**
   * Start date for range
   */
  rangeStartDate: GQL_Date | null;
  /**
   * End date for range
   */
  rangeEndDate: GQL_Date | null;
  /**
   * Time Tracking Entries
   */
  timeTrackingEntries: CreateTimeTrackingDay_createTimeTrackingDay_timeTrackingEntries[];
}

export interface CreateTimeTrackingDay {
  createTimeTrackingDay: CreateTimeTrackingDay_createTimeTrackingDay;
}

export interface CreateTimeTrackingDayVariables {
  timeTrackingDay: TimeTrackingDayInputType;
  timeTrackingEntry: TimeTrackingEntryInputType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteTimeTrackingDay
// ====================================================

export interface DeleteTimeTrackingDay {
  deleteTimeTrackingDay: string | null;
}

export interface DeleteTimeTrackingDayVariables {
  dayId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteTimeTrackingDayEntry
// ====================================================

export interface DeleteTimeTrackingDayEntry_deleteTimeTrackingDayEntry_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User employee id
   */
  employeeId: string | null;
}

export interface DeleteTimeTrackingDayEntry_deleteTimeTrackingDayEntry_timeTrackingEntries_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface DeleteTimeTrackingDayEntry_deleteTimeTrackingDayEntry_timeTrackingEntries_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Company identifier
   */
  company: string;
}

export interface DeleteTimeTrackingDayEntry_deleteTimeTrackingDayEntry_timeTrackingEntries_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface DeleteTimeTrackingDayEntry_deleteTimeTrackingDayEntry_timeTrackingEntries_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface DeleteTimeTrackingDayEntry_deleteTimeTrackingDayEntry_timeTrackingEntries_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
}

export interface DeleteTimeTrackingDayEntry_deleteTimeTrackingDayEntry_timeTrackingEntries_case_damage_contact {
  /**
   * Contact address information
   */
  address: DeleteTimeTrackingDayEntry_deleteTimeTrackingDayEntry_timeTrackingEntries_case_damage_contact_address;
}

export interface DeleteTimeTrackingDayEntry_deleteTimeTrackingDayEntry_timeTrackingEntries_case_damage {
  /**
   * Damage category
   */
  category: DeleteTimeTrackingDayEntry_deleteTimeTrackingDayEntry_timeTrackingEntries_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: DeleteTimeTrackingDayEntry_deleteTimeTrackingDayEntry_timeTrackingEntries_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: DeleteTimeTrackingDayEntry_deleteTimeTrackingDayEntry_timeTrackingEntries_case_damage_contact;
}

export interface DeleteTimeTrackingDayEntry_deleteTimeTrackingDayEntry_timeTrackingEntries_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: DeleteTimeTrackingDayEntry_deleteTimeTrackingDayEntry_timeTrackingEntries_case_debitor;
  /**
   * Case damage details
   */
  damage: DeleteTimeTrackingDayEntry_deleteTimeTrackingDayEntry_timeTrackingEntries_case_damage;
}

export interface DeleteTimeTrackingDayEntry_deleteTimeTrackingDayEntry_timeTrackingEntries_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface DeleteTimeTrackingDayEntry_deleteTimeTrackingDayEntry_timeTrackingEntries_lastModifiedBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface DeleteTimeTrackingDayEntry_deleteTimeTrackingDayEntry_timeTrackingEntries_addonLines_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface DeleteTimeTrackingDayEntry_deleteTimeTrackingDayEntry_timeTrackingEntries_addonLines_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Company identifier
   */
  company: string;
}

export interface DeleteTimeTrackingDayEntry_deleteTimeTrackingDayEntry_timeTrackingEntries_addonLines_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: DeleteTimeTrackingDayEntry_deleteTimeTrackingDayEntry_timeTrackingEntries_addonLines_case_debitor;
}

export interface DeleteTimeTrackingDayEntry_deleteTimeTrackingDayEntry_timeTrackingEntries_addonLines {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: DeleteTimeTrackingDayEntry_deleteTimeTrackingDayEntry_timeTrackingEntries_addonLines_user;
  userId: string;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: DeleteTimeTrackingDayEntry_deleteTimeTrackingDayEntry_timeTrackingEntries_addonLines_case | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Was the task on callback
   */
  onCallback: boolean;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * bcStatus
   */
  bcStatus: TimeTrackingStatus;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
}

export interface DeleteTimeTrackingDayEntry_deleteTimeTrackingDayEntry_timeTrackingEntries {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: DeleteTimeTrackingDayEntry_deleteTimeTrackingDayEntry_timeTrackingEntries_user;
  userId: string;
  /**
   * Start date for time tracking
   */
  startDate: GQL_Date;
  /**
   * End date for time tracking
   */
  endDate: GQL_Date;
  /**
   * Start time for time tracking
   */
  startTime: string;
  /**
   * End time for time tracking
   */
  endTime: string;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: DeleteTimeTrackingDayEntry_deleteTimeTrackingDayEntry_timeTrackingEntries_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: DeleteTimeTrackingDayEntry_deleteTimeTrackingDayEntry_timeTrackingEntries_drivingSlip | null;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Was the task on callback
   */
  onCallback: boolean;
  /**
   * Does the entry include lunch
   */
  includesLunch: boolean;
  /**
   * task
   */
  task: TimeTrackingEntryTask;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * BC status
   */
  bcStatus: TimeTrackingStatus | null;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Timestamp of when the entry was last modified
   */
  lastModifiedAt: GQL_DateTime | null;
  /**
   * User who last modified the entry
   */
  lastModifiedBy: DeleteTimeTrackingDayEntry_deleteTimeTrackingDayEntry_timeTrackingEntries_lastModifiedBy | null;
  /**
   * Add on supplementLines
   */
  addonLines: DeleteTimeTrackingDayEntry_deleteTimeTrackingDayEntry_timeTrackingEntries_addonLines[];
}

export interface DeleteTimeTrackingDayEntry_deleteTimeTrackingDayEntry {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * Type of open, closed or rejected
   */
  timeTrackingDayStatus: TimeTrackingDayStatus;
  /**
   * AD user
   */
  user: DeleteTimeTrackingDayEntry_deleteTimeTrackingDayEntry_user;
  userId: string;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Total hours
   */
  totalHours: number;
  /**
   * Total Overtime hours
   */
  overtimeHours: number;
  /**
   * Indicates if day is part of range
   */
  isPartOfRange: boolean;
  /**
   * Start date for range
   */
  rangeStartDate: GQL_Date | null;
  /**
   * End date for range
   */
  rangeEndDate: GQL_Date | null;
  /**
   * Time Tracking Entries
   */
  timeTrackingEntries: DeleteTimeTrackingDayEntry_deleteTimeTrackingDayEntry_timeTrackingEntries[];
}

export interface DeleteTimeTrackingDayEntry {
  deleteTimeTrackingDayEntry: DeleteTimeTrackingDayEntry_deleteTimeTrackingDayEntry | null;
}

export interface DeleteTimeTrackingDayEntryVariables {
  dayId: string;
  entryId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteTimeTrackingEntrySupplement
// ====================================================

export interface DeleteTimeTrackingEntrySupplement_deleteTimeTrackingEntrySupplement_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User employee id
   */
  employeeId: string | null;
}

export interface DeleteTimeTrackingEntrySupplement_deleteTimeTrackingEntrySupplement_timeTrackingEntries_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface DeleteTimeTrackingEntrySupplement_deleteTimeTrackingEntrySupplement_timeTrackingEntries_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Company identifier
   */
  company: string;
}

export interface DeleteTimeTrackingEntrySupplement_deleteTimeTrackingEntrySupplement_timeTrackingEntries_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface DeleteTimeTrackingEntrySupplement_deleteTimeTrackingEntrySupplement_timeTrackingEntries_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface DeleteTimeTrackingEntrySupplement_deleteTimeTrackingEntrySupplement_timeTrackingEntries_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
}

export interface DeleteTimeTrackingEntrySupplement_deleteTimeTrackingEntrySupplement_timeTrackingEntries_case_damage_contact {
  /**
   * Contact address information
   */
  address: DeleteTimeTrackingEntrySupplement_deleteTimeTrackingEntrySupplement_timeTrackingEntries_case_damage_contact_address;
}

export interface DeleteTimeTrackingEntrySupplement_deleteTimeTrackingEntrySupplement_timeTrackingEntries_case_damage {
  /**
   * Damage category
   */
  category: DeleteTimeTrackingEntrySupplement_deleteTimeTrackingEntrySupplement_timeTrackingEntries_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: DeleteTimeTrackingEntrySupplement_deleteTimeTrackingEntrySupplement_timeTrackingEntries_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: DeleteTimeTrackingEntrySupplement_deleteTimeTrackingEntrySupplement_timeTrackingEntries_case_damage_contact;
}

export interface DeleteTimeTrackingEntrySupplement_deleteTimeTrackingEntrySupplement_timeTrackingEntries_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: DeleteTimeTrackingEntrySupplement_deleteTimeTrackingEntrySupplement_timeTrackingEntries_case_debitor;
  /**
   * Case damage details
   */
  damage: DeleteTimeTrackingEntrySupplement_deleteTimeTrackingEntrySupplement_timeTrackingEntries_case_damage;
}

export interface DeleteTimeTrackingEntrySupplement_deleteTimeTrackingEntrySupplement_timeTrackingEntries_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface DeleteTimeTrackingEntrySupplement_deleteTimeTrackingEntrySupplement_timeTrackingEntries_lastModifiedBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface DeleteTimeTrackingEntrySupplement_deleteTimeTrackingEntrySupplement_timeTrackingEntries_addonLines_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface DeleteTimeTrackingEntrySupplement_deleteTimeTrackingEntrySupplement_timeTrackingEntries_addonLines_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Company identifier
   */
  company: string;
}

export interface DeleteTimeTrackingEntrySupplement_deleteTimeTrackingEntrySupplement_timeTrackingEntries_addonLines_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: DeleteTimeTrackingEntrySupplement_deleteTimeTrackingEntrySupplement_timeTrackingEntries_addonLines_case_debitor;
}

export interface DeleteTimeTrackingEntrySupplement_deleteTimeTrackingEntrySupplement_timeTrackingEntries_addonLines {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: DeleteTimeTrackingEntrySupplement_deleteTimeTrackingEntrySupplement_timeTrackingEntries_addonLines_user;
  userId: string;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: DeleteTimeTrackingEntrySupplement_deleteTimeTrackingEntrySupplement_timeTrackingEntries_addonLines_case | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Was the task on callback
   */
  onCallback: boolean;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * bcStatus
   */
  bcStatus: TimeTrackingStatus;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
}

export interface DeleteTimeTrackingEntrySupplement_deleteTimeTrackingEntrySupplement_timeTrackingEntries {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: DeleteTimeTrackingEntrySupplement_deleteTimeTrackingEntrySupplement_timeTrackingEntries_user;
  userId: string;
  /**
   * Start date for time tracking
   */
  startDate: GQL_Date;
  /**
   * End date for time tracking
   */
  endDate: GQL_Date;
  /**
   * Start time for time tracking
   */
  startTime: string;
  /**
   * End time for time tracking
   */
  endTime: string;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: DeleteTimeTrackingEntrySupplement_deleteTimeTrackingEntrySupplement_timeTrackingEntries_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: DeleteTimeTrackingEntrySupplement_deleteTimeTrackingEntrySupplement_timeTrackingEntries_drivingSlip | null;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Was the task on callback
   */
  onCallback: boolean;
  /**
   * Does the entry include lunch
   */
  includesLunch: boolean;
  /**
   * task
   */
  task: TimeTrackingEntryTask;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * BC status
   */
  bcStatus: TimeTrackingStatus | null;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Timestamp of when the entry was last modified
   */
  lastModifiedAt: GQL_DateTime | null;
  /**
   * User who last modified the entry
   */
  lastModifiedBy: DeleteTimeTrackingEntrySupplement_deleteTimeTrackingEntrySupplement_timeTrackingEntries_lastModifiedBy | null;
  /**
   * Add on supplementLines
   */
  addonLines: DeleteTimeTrackingEntrySupplement_deleteTimeTrackingEntrySupplement_timeTrackingEntries_addonLines[];
}

export interface DeleteTimeTrackingEntrySupplement_deleteTimeTrackingEntrySupplement {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * Type of open, closed or rejected
   */
  timeTrackingDayStatus: TimeTrackingDayStatus;
  /**
   * AD user
   */
  user: DeleteTimeTrackingEntrySupplement_deleteTimeTrackingEntrySupplement_user;
  userId: string;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Total hours
   */
  totalHours: number;
  /**
   * Total Overtime hours
   */
  overtimeHours: number;
  /**
   * Indicates if day is part of range
   */
  isPartOfRange: boolean;
  /**
   * Start date for range
   */
  rangeStartDate: GQL_Date | null;
  /**
   * End date for range
   */
  rangeEndDate: GQL_Date | null;
  /**
   * Time Tracking Entries
   */
  timeTrackingEntries: DeleteTimeTrackingEntrySupplement_deleteTimeTrackingEntrySupplement_timeTrackingEntries[];
}

export interface DeleteTimeTrackingEntrySupplement {
  deleteTimeTrackingEntrySupplement: DeleteTimeTrackingEntrySupplement_deleteTimeTrackingEntrySupplement | null;
}

export interface DeleteTimeTrackingEntrySupplementVariables {
  dayId: string;
  entryId: string;
  supplementId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetflexTimeManagerList
// ====================================================

export interface GetflexTimeManagerList_flexTimeManagerList_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
}

export interface GetflexTimeManagerList_flexTimeManagerList {
  /**
   * User object
   */
  user: GetflexTimeManagerList_flexTimeManagerList_user;
  /**
   * Flex time for the user
   */
  flexTime: GQL_Decimal;
}

export interface GetflexTimeManagerList {
  flexTimeManagerList: GetflexTimeManagerList_flexTimeManagerList[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTimeTrackingDayLatestByUser
// ====================================================

export interface GetTimeTrackingDayLatestByUser_timeTrackingDayLatestByUser {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Type of open, closed or rejected
   */
  timeTrackingDayStatus: TimeTrackingDayStatus;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
}

export interface GetTimeTrackingDayLatestByUser {
  timeTrackingDayLatestByUser: GetTimeTrackingDayLatestByUser_timeTrackingDayLatestByUser | null;
}

export interface GetTimeTrackingDayLatestByUserVariables {
  user: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetManagerTimeTrackingClosedDayUserList
// ====================================================

export interface GetManagerTimeTrackingClosedDayUserList_timeTrackingClosedDayManagerList_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
}

export interface GetManagerTimeTrackingClosedDayUserList_timeTrackingClosedDayManagerList {
  /**
   * User object
   */
  user: GetManagerTimeTrackingClosedDayUserList_timeTrackingClosedDayManagerList_user;
  /**
   * Last closed date for user
   */
  date: GQL_Date;
}

export interface GetManagerTimeTrackingClosedDayUserList {
  timeTrackingClosedDayManagerList: GetManagerTimeTrackingClosedDayUserList_timeTrackingClosedDayManagerList[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetManagerTimeTrackingRejectedDayUserList
// ====================================================

export interface GetManagerTimeTrackingRejectedDayUserList_timeTrackingRejectedDaysManagerList_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
}

export interface GetManagerTimeTrackingRejectedDayUserList_timeTrackingRejectedDaysManagerList {
  /**
   * AD user
   */
  user: GetManagerTimeTrackingRejectedDayUserList_timeTrackingRejectedDaysManagerList_user;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
}

export interface GetManagerTimeTrackingRejectedDayUserList {
  timeTrackingRejectedDaysManagerList: GetManagerTimeTrackingRejectedDayUserList_timeTrackingRejectedDaysManagerList[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetrejectedTimeTrackingsDayForUser
// ====================================================

export interface GetrejectedTimeTrackingsDayForUser_rejectedTimeTrackingsDayForUser_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User employee id
   */
  employeeId: string | null;
}

export interface GetrejectedTimeTrackingsDayForUser_rejectedTimeTrackingsDayForUser_timeTrackingEntries_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetrejectedTimeTrackingsDayForUser_rejectedTimeTrackingsDayForUser_timeTrackingEntries_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Company identifier
   */
  company: string;
}

export interface GetrejectedTimeTrackingsDayForUser_rejectedTimeTrackingsDayForUser_timeTrackingEntries_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetrejectedTimeTrackingsDayForUser_rejectedTimeTrackingsDayForUser_timeTrackingEntries_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface GetrejectedTimeTrackingsDayForUser_rejectedTimeTrackingsDayForUser_timeTrackingEntries_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
}

export interface GetrejectedTimeTrackingsDayForUser_rejectedTimeTrackingsDayForUser_timeTrackingEntries_case_damage_contact {
  /**
   * Contact address information
   */
  address: GetrejectedTimeTrackingsDayForUser_rejectedTimeTrackingsDayForUser_timeTrackingEntries_case_damage_contact_address;
}

export interface GetrejectedTimeTrackingsDayForUser_rejectedTimeTrackingsDayForUser_timeTrackingEntries_case_damage {
  /**
   * Damage category
   */
  category: GetrejectedTimeTrackingsDayForUser_rejectedTimeTrackingsDayForUser_timeTrackingEntries_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetrejectedTimeTrackingsDayForUser_rejectedTimeTrackingsDayForUser_timeTrackingEntries_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: GetrejectedTimeTrackingsDayForUser_rejectedTimeTrackingsDayForUser_timeTrackingEntries_case_damage_contact;
}

export interface GetrejectedTimeTrackingsDayForUser_rejectedTimeTrackingsDayForUser_timeTrackingEntries_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetrejectedTimeTrackingsDayForUser_rejectedTimeTrackingsDayForUser_timeTrackingEntries_case_debitor;
  /**
   * Case damage details
   */
  damage: GetrejectedTimeTrackingsDayForUser_rejectedTimeTrackingsDayForUser_timeTrackingEntries_case_damage;
}

export interface GetrejectedTimeTrackingsDayForUser_rejectedTimeTrackingsDayForUser_timeTrackingEntries_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface GetrejectedTimeTrackingsDayForUser_rejectedTimeTrackingsDayForUser_timeTrackingEntries_lastModifiedBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetrejectedTimeTrackingsDayForUser_rejectedTimeTrackingsDayForUser_timeTrackingEntries_addonLines_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetrejectedTimeTrackingsDayForUser_rejectedTimeTrackingsDayForUser_timeTrackingEntries_addonLines_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Company identifier
   */
  company: string;
}

export interface GetrejectedTimeTrackingsDayForUser_rejectedTimeTrackingsDayForUser_timeTrackingEntries_addonLines_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetrejectedTimeTrackingsDayForUser_rejectedTimeTrackingsDayForUser_timeTrackingEntries_addonLines_case_debitor;
}

export interface GetrejectedTimeTrackingsDayForUser_rejectedTimeTrackingsDayForUser_timeTrackingEntries_addonLines {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: GetrejectedTimeTrackingsDayForUser_rejectedTimeTrackingsDayForUser_timeTrackingEntries_addonLines_user;
  userId: string;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: GetrejectedTimeTrackingsDayForUser_rejectedTimeTrackingsDayForUser_timeTrackingEntries_addonLines_case | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Was the task on callback
   */
  onCallback: boolean;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * bcStatus
   */
  bcStatus: TimeTrackingStatus;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
}

export interface GetrejectedTimeTrackingsDayForUser_rejectedTimeTrackingsDayForUser_timeTrackingEntries {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: GetrejectedTimeTrackingsDayForUser_rejectedTimeTrackingsDayForUser_timeTrackingEntries_user;
  userId: string;
  /**
   * Start date for time tracking
   */
  startDate: GQL_Date;
  /**
   * End date for time tracking
   */
  endDate: GQL_Date;
  /**
   * Start time for time tracking
   */
  startTime: string;
  /**
   * End time for time tracking
   */
  endTime: string;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: GetrejectedTimeTrackingsDayForUser_rejectedTimeTrackingsDayForUser_timeTrackingEntries_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: GetrejectedTimeTrackingsDayForUser_rejectedTimeTrackingsDayForUser_timeTrackingEntries_drivingSlip | null;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Was the task on callback
   */
  onCallback: boolean;
  /**
   * Does the entry include lunch
   */
  includesLunch: boolean;
  /**
   * task
   */
  task: TimeTrackingEntryTask;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * BC status
   */
  bcStatus: TimeTrackingStatus | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Timestamp of when the entry was last modified
   */
  lastModifiedAt: GQL_DateTime | null;
  /**
   * User who last modified the entry
   */
  lastModifiedBy: GetrejectedTimeTrackingsDayForUser_rejectedTimeTrackingsDayForUser_timeTrackingEntries_lastModifiedBy | null;
  /**
   * Add on supplementLines
   */
  addonLines: GetrejectedTimeTrackingsDayForUser_rejectedTimeTrackingsDayForUser_timeTrackingEntries_addonLines[];
}

export interface GetrejectedTimeTrackingsDayForUser_rejectedTimeTrackingsDayForUser {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * Type of open, closed or rejected
   */
  timeTrackingDayStatus: TimeTrackingDayStatus;
  /**
   * AD user
   */
  user: GetrejectedTimeTrackingsDayForUser_rejectedTimeTrackingsDayForUser_user;
  userId: string;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Total hours
   */
  totalHours: number;
  /**
   * Total Overtime hours
   */
  overtimeHours: number;
  /**
   * Indicates if day is part of range
   */
  isPartOfRange: boolean;
  /**
   * Start date for range
   */
  rangeStartDate: GQL_Date | null;
  /**
   * End date for range
   */
  rangeEndDate: GQL_Date | null;
  /**
   * Time Tracking Entries
   */
  timeTrackingEntries: GetrejectedTimeTrackingsDayForUser_rejectedTimeTrackingsDayForUser_timeTrackingEntries[];
}

export interface GetrejectedTimeTrackingsDayForUser {
  /**
   * Gets all the rejected time trackings for a given user. It looks in the database and in BC to get all rejections
   */
  rejectedTimeTrackingsDayForUser: GetrejectedTimeTrackingsDayForUser_rejectedTimeTrackingsDayForUser[];
}

export interface GetrejectedTimeTrackingsDayForUserVariables {
  user: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTimeTrackingDayClosedByUser
// ====================================================

export interface GetTimeTrackingDayClosedByUser_timeTrackingDayClosedByUser_user {
  /**
   * Unique id of user
   */
  id: string;
}

export interface GetTimeTrackingDayClosedByUser_timeTrackingDayClosedByUser {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * AD user
   */
  user: GetTimeTrackingDayClosedByUser_timeTrackingDayClosedByUser_user;
  /**
   * Type of open, closed or rejected
   */
  timeTrackingDayStatus: TimeTrackingDayStatus;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
}

export interface GetTimeTrackingDayClosedByUser {
  timeTrackingDayClosedByUser: GetTimeTrackingDayClosedByUser_timeTrackingDayClosedByUser | null;
}

export interface GetTimeTrackingDayClosedByUserVariables {
  user: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTimeTrackingDays
// ====================================================

export interface GetTimeTrackingDays_timeTrackingDays_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User employee id
   */
  employeeId: string | null;
}

export interface GetTimeTrackingDays_timeTrackingDays_timeTrackingEntries_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetTimeTrackingDays_timeTrackingDays_timeTrackingEntries_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Company identifier
   */
  company: string;
}

export interface GetTimeTrackingDays_timeTrackingDays_timeTrackingEntries_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetTimeTrackingDays_timeTrackingDays_timeTrackingEntries_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface GetTimeTrackingDays_timeTrackingDays_timeTrackingEntries_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
}

export interface GetTimeTrackingDays_timeTrackingDays_timeTrackingEntries_case_damage_contact {
  /**
   * Contact address information
   */
  address: GetTimeTrackingDays_timeTrackingDays_timeTrackingEntries_case_damage_contact_address;
}

export interface GetTimeTrackingDays_timeTrackingDays_timeTrackingEntries_case_damage {
  /**
   * Damage category
   */
  category: GetTimeTrackingDays_timeTrackingDays_timeTrackingEntries_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetTimeTrackingDays_timeTrackingDays_timeTrackingEntries_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: GetTimeTrackingDays_timeTrackingDays_timeTrackingEntries_case_damage_contact;
}

export interface GetTimeTrackingDays_timeTrackingDays_timeTrackingEntries_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetTimeTrackingDays_timeTrackingDays_timeTrackingEntries_case_debitor;
  /**
   * Case damage details
   */
  damage: GetTimeTrackingDays_timeTrackingDays_timeTrackingEntries_case_damage;
}

export interface GetTimeTrackingDays_timeTrackingDays_timeTrackingEntries_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface GetTimeTrackingDays_timeTrackingDays_timeTrackingEntries_lastModifiedBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetTimeTrackingDays_timeTrackingDays_timeTrackingEntries_addonLines_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetTimeTrackingDays_timeTrackingDays_timeTrackingEntries_addonLines_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Company identifier
   */
  company: string;
}

export interface GetTimeTrackingDays_timeTrackingDays_timeTrackingEntries_addonLines_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetTimeTrackingDays_timeTrackingDays_timeTrackingEntries_addonLines_case_debitor;
}

export interface GetTimeTrackingDays_timeTrackingDays_timeTrackingEntries_addonLines {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: GetTimeTrackingDays_timeTrackingDays_timeTrackingEntries_addonLines_user;
  userId: string;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: GetTimeTrackingDays_timeTrackingDays_timeTrackingEntries_addonLines_case | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Was the task on callback
   */
  onCallback: boolean;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * bcStatus
   */
  bcStatus: TimeTrackingStatus;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
}

export interface GetTimeTrackingDays_timeTrackingDays_timeTrackingEntries {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: GetTimeTrackingDays_timeTrackingDays_timeTrackingEntries_user;
  userId: string;
  /**
   * Start date for time tracking
   */
  startDate: GQL_Date;
  /**
   * End date for time tracking
   */
  endDate: GQL_Date;
  /**
   * Start time for time tracking
   */
  startTime: string;
  /**
   * End time for time tracking
   */
  endTime: string;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: GetTimeTrackingDays_timeTrackingDays_timeTrackingEntries_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: GetTimeTrackingDays_timeTrackingDays_timeTrackingEntries_drivingSlip | null;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Was the task on callback
   */
  onCallback: boolean;
  /**
   * Does the entry include lunch
   */
  includesLunch: boolean;
  /**
   * task
   */
  task: TimeTrackingEntryTask;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * BC status
   */
  bcStatus: TimeTrackingStatus | null;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Timestamp of when the entry was last modified
   */
  lastModifiedAt: GQL_DateTime | null;
  /**
   * User who last modified the entry
   */
  lastModifiedBy: GetTimeTrackingDays_timeTrackingDays_timeTrackingEntries_lastModifiedBy | null;
  /**
   * Add on supplementLines
   */
  addonLines: GetTimeTrackingDays_timeTrackingDays_timeTrackingEntries_addonLines[];
}

export interface GetTimeTrackingDays_timeTrackingDays {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * Type of open, closed or rejected
   */
  timeTrackingDayStatus: TimeTrackingDayStatus;
  /**
   * AD user
   */
  user: GetTimeTrackingDays_timeTrackingDays_user;
  userId: string;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Total hours
   */
  totalHours: number;
  /**
   * Total Overtime hours
   */
  overtimeHours: number;
  /**
   * Indicates if day is part of range
   */
  isPartOfRange: boolean;
  /**
   * Start date for range
   */
  rangeStartDate: GQL_Date | null;
  /**
   * End date for range
   */
  rangeEndDate: GQL_Date | null;
  /**
   * Time Tracking Entries
   */
  timeTrackingEntries: GetTimeTrackingDays_timeTrackingDays_timeTrackingEntries[];
}

export interface GetTimeTrackingDays {
  timeTrackingDays: GetTimeTrackingDays_timeTrackingDays[];
}

export interface GetTimeTrackingDaysVariables {
  timeTrackingDayStatus?: TimeTrackingDayStatus | null;
  minDate: GQL_Date;
  maxDate: GQL_Date;
  users?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTimeTrackingEntryLog
// ====================================================

export interface GetTimeTrackingEntryLog_timeTrackingEntryLog_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetTimeTrackingEntryLog_timeTrackingEntryLog_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Company identifier
   */
  company: string;
}

export interface GetTimeTrackingEntryLog_timeTrackingEntryLog_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetTimeTrackingEntryLog_timeTrackingEntryLog_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface GetTimeTrackingEntryLog_timeTrackingEntryLog_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
}

export interface GetTimeTrackingEntryLog_timeTrackingEntryLog_case_damage_contact {
  /**
   * Contact address information
   */
  address: GetTimeTrackingEntryLog_timeTrackingEntryLog_case_damage_contact_address;
}

export interface GetTimeTrackingEntryLog_timeTrackingEntryLog_case_damage {
  /**
   * Damage category
   */
  category: GetTimeTrackingEntryLog_timeTrackingEntryLog_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetTimeTrackingEntryLog_timeTrackingEntryLog_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: GetTimeTrackingEntryLog_timeTrackingEntryLog_case_damage_contact;
}

export interface GetTimeTrackingEntryLog_timeTrackingEntryLog_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetTimeTrackingEntryLog_timeTrackingEntryLog_case_debitor;
  /**
   * Case damage details
   */
  damage: GetTimeTrackingEntryLog_timeTrackingEntryLog_case_damage;
}

export interface GetTimeTrackingEntryLog_timeTrackingEntryLog_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface GetTimeTrackingEntryLog_timeTrackingEntryLog_lastModifiedBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetTimeTrackingEntryLog_timeTrackingEntryLog_addonLines_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetTimeTrackingEntryLog_timeTrackingEntryLog_addonLines_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Company identifier
   */
  company: string;
}

export interface GetTimeTrackingEntryLog_timeTrackingEntryLog_addonLines_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetTimeTrackingEntryLog_timeTrackingEntryLog_addonLines_case_debitor;
}

export interface GetTimeTrackingEntryLog_timeTrackingEntryLog_addonLines {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: GetTimeTrackingEntryLog_timeTrackingEntryLog_addonLines_user;
  userId: string;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: GetTimeTrackingEntryLog_timeTrackingEntryLog_addonLines_case | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Was the task on callback
   */
  onCallback: boolean;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * bcStatus
   */
  bcStatus: TimeTrackingStatus;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
}

export interface GetTimeTrackingEntryLog_timeTrackingEntryLog {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: GetTimeTrackingEntryLog_timeTrackingEntryLog_user;
  userId: string;
  /**
   * Start date for time tracking
   */
  startDate: GQL_Date;
  /**
   * End date for time tracking
   */
  endDate: GQL_Date;
  /**
   * Start time for time tracking
   */
  startTime: string;
  /**
   * End time for time tracking
   */
  endTime: string;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: GetTimeTrackingEntryLog_timeTrackingEntryLog_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: GetTimeTrackingEntryLog_timeTrackingEntryLog_drivingSlip | null;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Was the task on callback
   */
  onCallback: boolean;
  /**
   * Does the entry include lunch
   */
  includesLunch: boolean;
  /**
   * task
   */
  task: TimeTrackingEntryTask;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * BC status
   */
  bcStatus: TimeTrackingStatus | null;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Timestamp of when the entry was last modified
   */
  lastModifiedAt: GQL_DateTime | null;
  /**
   * User who last modified the entry
   */
  lastModifiedBy: GetTimeTrackingEntryLog_timeTrackingEntryLog_lastModifiedBy | null;
  /**
   * Add on supplementLines
   */
  addonLines: GetTimeTrackingEntryLog_timeTrackingEntryLog_addonLines[];
}

export interface GetTimeTrackingEntryLog {
  timeTrackingEntryLog: GetTimeTrackingEntryLog_timeTrackingEntryLog[];
}

export interface GetTimeTrackingEntryLogVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTimeTrackingsDayForUser
// ====================================================

export interface GetTimeTrackingsDayForUser_timeTrackingsDayForUser_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User employee id
   */
  employeeId: string | null;
}

export interface GetTimeTrackingsDayForUser_timeTrackingsDayForUser_timeTrackingEntries_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetTimeTrackingsDayForUser_timeTrackingsDayForUser_timeTrackingEntries_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Company identifier
   */
  company: string;
}

export interface GetTimeTrackingsDayForUser_timeTrackingsDayForUser_timeTrackingEntries_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetTimeTrackingsDayForUser_timeTrackingsDayForUser_timeTrackingEntries_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface GetTimeTrackingsDayForUser_timeTrackingsDayForUser_timeTrackingEntries_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
}

export interface GetTimeTrackingsDayForUser_timeTrackingsDayForUser_timeTrackingEntries_case_damage_contact {
  /**
   * Contact address information
   */
  address: GetTimeTrackingsDayForUser_timeTrackingsDayForUser_timeTrackingEntries_case_damage_contact_address;
}

export interface GetTimeTrackingsDayForUser_timeTrackingsDayForUser_timeTrackingEntries_case_damage {
  /**
   * Damage category
   */
  category: GetTimeTrackingsDayForUser_timeTrackingsDayForUser_timeTrackingEntries_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetTimeTrackingsDayForUser_timeTrackingsDayForUser_timeTrackingEntries_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: GetTimeTrackingsDayForUser_timeTrackingsDayForUser_timeTrackingEntries_case_damage_contact;
}

export interface GetTimeTrackingsDayForUser_timeTrackingsDayForUser_timeTrackingEntries_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetTimeTrackingsDayForUser_timeTrackingsDayForUser_timeTrackingEntries_case_debitor;
  /**
   * Case damage details
   */
  damage: GetTimeTrackingsDayForUser_timeTrackingsDayForUser_timeTrackingEntries_case_damage;
}

export interface GetTimeTrackingsDayForUser_timeTrackingsDayForUser_timeTrackingEntries_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface GetTimeTrackingsDayForUser_timeTrackingsDayForUser_timeTrackingEntries_lastModifiedBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetTimeTrackingsDayForUser_timeTrackingsDayForUser_timeTrackingEntries_addonLines_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetTimeTrackingsDayForUser_timeTrackingsDayForUser_timeTrackingEntries_addonLines_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Company identifier
   */
  company: string;
}

export interface GetTimeTrackingsDayForUser_timeTrackingsDayForUser_timeTrackingEntries_addonLines_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetTimeTrackingsDayForUser_timeTrackingsDayForUser_timeTrackingEntries_addonLines_case_debitor;
}

export interface GetTimeTrackingsDayForUser_timeTrackingsDayForUser_timeTrackingEntries_addonLines {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: GetTimeTrackingsDayForUser_timeTrackingsDayForUser_timeTrackingEntries_addonLines_user;
  userId: string;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: GetTimeTrackingsDayForUser_timeTrackingsDayForUser_timeTrackingEntries_addonLines_case | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Was the task on callback
   */
  onCallback: boolean;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * bcStatus
   */
  bcStatus: TimeTrackingStatus;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
}

export interface GetTimeTrackingsDayForUser_timeTrackingsDayForUser_timeTrackingEntries {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: GetTimeTrackingsDayForUser_timeTrackingsDayForUser_timeTrackingEntries_user;
  userId: string;
  /**
   * Start date for time tracking
   */
  startDate: GQL_Date;
  /**
   * End date for time tracking
   */
  endDate: GQL_Date;
  /**
   * Start time for time tracking
   */
  startTime: string;
  /**
   * End time for time tracking
   */
  endTime: string;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: GetTimeTrackingsDayForUser_timeTrackingsDayForUser_timeTrackingEntries_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: GetTimeTrackingsDayForUser_timeTrackingsDayForUser_timeTrackingEntries_drivingSlip | null;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Was the task on callback
   */
  onCallback: boolean;
  /**
   * Does the entry include lunch
   */
  includesLunch: boolean;
  /**
   * task
   */
  task: TimeTrackingEntryTask;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * BC status
   */
  bcStatus: TimeTrackingStatus | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Timestamp of when the entry was last modified
   */
  lastModifiedAt: GQL_DateTime | null;
  /**
   * User who last modified the entry
   */
  lastModifiedBy: GetTimeTrackingsDayForUser_timeTrackingsDayForUser_timeTrackingEntries_lastModifiedBy | null;
  /**
   * Add on supplementLines
   */
  addonLines: GetTimeTrackingsDayForUser_timeTrackingsDayForUser_timeTrackingEntries_addonLines[];
}

export interface GetTimeTrackingsDayForUser_timeTrackingsDayForUser {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * Type of open, closed or rejected
   */
  timeTrackingDayStatus: TimeTrackingDayStatus;
  /**
   * AD user
   */
  user: GetTimeTrackingsDayForUser_timeTrackingsDayForUser_user;
  userId: string;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Total hours
   */
  totalHours: number;
  /**
   * Total Overtime hours
   */
  overtimeHours: number;
  /**
   * Indicates if day is part of range
   */
  isPartOfRange: boolean;
  /**
   * Start date for range
   */
  rangeStartDate: GQL_Date | null;
  /**
   * End date for range
   */
  rangeEndDate: GQL_Date | null;
  /**
   * Time Tracking Entries
   */
  timeTrackingEntries: GetTimeTrackingsDayForUser_timeTrackingsDayForUser_timeTrackingEntries[];
}

export interface GetTimeTrackingsDayForUser {
  timeTrackingsDayForUser: GetTimeTrackingsDayForUser_timeTrackingsDayForUser | null;
}

export interface GetTimeTrackingsDayForUserVariables {
  user: string;
  minDate?: GQL_Date | null;
  maxDate?: GQL_Date | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTimeTrackingsInBcWithoutApproval
// ====================================================

export interface GetTimeTrackingsInBcWithoutApproval_timeTrackingsInBcWithoutApproval_user {
  /**
   * Name of user
   */
  name: string;
}

export interface GetTimeTrackingsInBcWithoutApproval_timeTrackingsInBcWithoutApproval {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * AD user
   */
  user: GetTimeTrackingsInBcWithoutApproval_timeTrackingsInBcWithoutApproval_user;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Emploee responsible approval status
   */
  emploeeResponsible: TimeTrackingStatus;
  /**
   * Project approval status
   */
  projectApprovalStatus: TimeTrackingStatus;
}

export interface GetTimeTrackingsInBcWithoutApproval {
  timeTrackingsInBcWithoutApproval: GetTimeTrackingsInBcWithoutApproval_timeTrackingsInBcWithoutApproval[];
}

export interface GetTimeTrackingsInBcWithoutApprovalVariables {
  erpNo: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: OpenRejectedTimeTrackingDay
// ====================================================

export interface OpenRejectedTimeTrackingDay_openRejectedTimeTrackingDay_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User employee id
   */
  employeeId: string | null;
}

export interface OpenRejectedTimeTrackingDay_openRejectedTimeTrackingDay_timeTrackingEntries_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface OpenRejectedTimeTrackingDay_openRejectedTimeTrackingDay_timeTrackingEntries_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Company identifier
   */
  company: string;
}

export interface OpenRejectedTimeTrackingDay_openRejectedTimeTrackingDay_timeTrackingEntries_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface OpenRejectedTimeTrackingDay_openRejectedTimeTrackingDay_timeTrackingEntries_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface OpenRejectedTimeTrackingDay_openRejectedTimeTrackingDay_timeTrackingEntries_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
}

export interface OpenRejectedTimeTrackingDay_openRejectedTimeTrackingDay_timeTrackingEntries_case_damage_contact {
  /**
   * Contact address information
   */
  address: OpenRejectedTimeTrackingDay_openRejectedTimeTrackingDay_timeTrackingEntries_case_damage_contact_address;
}

export interface OpenRejectedTimeTrackingDay_openRejectedTimeTrackingDay_timeTrackingEntries_case_damage {
  /**
   * Damage category
   */
  category: OpenRejectedTimeTrackingDay_openRejectedTimeTrackingDay_timeTrackingEntries_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: OpenRejectedTimeTrackingDay_openRejectedTimeTrackingDay_timeTrackingEntries_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: OpenRejectedTimeTrackingDay_openRejectedTimeTrackingDay_timeTrackingEntries_case_damage_contact;
}

export interface OpenRejectedTimeTrackingDay_openRejectedTimeTrackingDay_timeTrackingEntries_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: OpenRejectedTimeTrackingDay_openRejectedTimeTrackingDay_timeTrackingEntries_case_debitor;
  /**
   * Case damage details
   */
  damage: OpenRejectedTimeTrackingDay_openRejectedTimeTrackingDay_timeTrackingEntries_case_damage;
}

export interface OpenRejectedTimeTrackingDay_openRejectedTimeTrackingDay_timeTrackingEntries_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface OpenRejectedTimeTrackingDay_openRejectedTimeTrackingDay_timeTrackingEntries_lastModifiedBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface OpenRejectedTimeTrackingDay_openRejectedTimeTrackingDay_timeTrackingEntries_addonLines_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface OpenRejectedTimeTrackingDay_openRejectedTimeTrackingDay_timeTrackingEntries_addonLines_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Company identifier
   */
  company: string;
}

export interface OpenRejectedTimeTrackingDay_openRejectedTimeTrackingDay_timeTrackingEntries_addonLines_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: OpenRejectedTimeTrackingDay_openRejectedTimeTrackingDay_timeTrackingEntries_addonLines_case_debitor;
}

export interface OpenRejectedTimeTrackingDay_openRejectedTimeTrackingDay_timeTrackingEntries_addonLines {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: OpenRejectedTimeTrackingDay_openRejectedTimeTrackingDay_timeTrackingEntries_addonLines_user;
  userId: string;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: OpenRejectedTimeTrackingDay_openRejectedTimeTrackingDay_timeTrackingEntries_addonLines_case | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Was the task on callback
   */
  onCallback: boolean;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * bcStatus
   */
  bcStatus: TimeTrackingStatus;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
}

export interface OpenRejectedTimeTrackingDay_openRejectedTimeTrackingDay_timeTrackingEntries {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: OpenRejectedTimeTrackingDay_openRejectedTimeTrackingDay_timeTrackingEntries_user;
  userId: string;
  /**
   * Start date for time tracking
   */
  startDate: GQL_Date;
  /**
   * End date for time tracking
   */
  endDate: GQL_Date;
  /**
   * Start time for time tracking
   */
  startTime: string;
  /**
   * End time for time tracking
   */
  endTime: string;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: OpenRejectedTimeTrackingDay_openRejectedTimeTrackingDay_timeTrackingEntries_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: OpenRejectedTimeTrackingDay_openRejectedTimeTrackingDay_timeTrackingEntries_drivingSlip | null;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Was the task on callback
   */
  onCallback: boolean;
  /**
   * Does the entry include lunch
   */
  includesLunch: boolean;
  /**
   * task
   */
  task: TimeTrackingEntryTask;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * BC status
   */
  bcStatus: TimeTrackingStatus | null;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Timestamp of when the entry was last modified
   */
  lastModifiedAt: GQL_DateTime | null;
  /**
   * User who last modified the entry
   */
  lastModifiedBy: OpenRejectedTimeTrackingDay_openRejectedTimeTrackingDay_timeTrackingEntries_lastModifiedBy | null;
  /**
   * Add on supplementLines
   */
  addonLines: OpenRejectedTimeTrackingDay_openRejectedTimeTrackingDay_timeTrackingEntries_addonLines[];
}

export interface OpenRejectedTimeTrackingDay_openRejectedTimeTrackingDay {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * Type of open, closed or rejected
   */
  timeTrackingDayStatus: TimeTrackingDayStatus;
  /**
   * AD user
   */
  user: OpenRejectedTimeTrackingDay_openRejectedTimeTrackingDay_user;
  userId: string;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Total hours
   */
  totalHours: number;
  /**
   * Total Overtime hours
   */
  overtimeHours: number;
  /**
   * Indicates if day is part of range
   */
  isPartOfRange: boolean;
  /**
   * Start date for range
   */
  rangeStartDate: GQL_Date | null;
  /**
   * End date for range
   */
  rangeEndDate: GQL_Date | null;
  /**
   * Time Tracking Entries
   */
  timeTrackingEntries: OpenRejectedTimeTrackingDay_openRejectedTimeTrackingDay_timeTrackingEntries[];
}

export interface OpenRejectedTimeTrackingDay {
  openRejectedTimeTrackingDay: OpenRejectedTimeTrackingDay_openRejectedTimeTrackingDay;
}

export interface OpenRejectedTimeTrackingDayVariables {
  userEmail: string;
  date: GQL_Date;
  dayId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReopenTimeTrackingDay
// ====================================================

export interface ReopenTimeTrackingDay_reopenTimeTrackingDay_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User employee id
   */
  employeeId: string | null;
}

export interface ReopenTimeTrackingDay_reopenTimeTrackingDay_timeTrackingEntries_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface ReopenTimeTrackingDay_reopenTimeTrackingDay_timeTrackingEntries_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Company identifier
   */
  company: string;
}

export interface ReopenTimeTrackingDay_reopenTimeTrackingDay_timeTrackingEntries_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface ReopenTimeTrackingDay_reopenTimeTrackingDay_timeTrackingEntries_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface ReopenTimeTrackingDay_reopenTimeTrackingDay_timeTrackingEntries_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
}

export interface ReopenTimeTrackingDay_reopenTimeTrackingDay_timeTrackingEntries_case_damage_contact {
  /**
   * Contact address information
   */
  address: ReopenTimeTrackingDay_reopenTimeTrackingDay_timeTrackingEntries_case_damage_contact_address;
}

export interface ReopenTimeTrackingDay_reopenTimeTrackingDay_timeTrackingEntries_case_damage {
  /**
   * Damage category
   */
  category: ReopenTimeTrackingDay_reopenTimeTrackingDay_timeTrackingEntries_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: ReopenTimeTrackingDay_reopenTimeTrackingDay_timeTrackingEntries_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: ReopenTimeTrackingDay_reopenTimeTrackingDay_timeTrackingEntries_case_damage_contact;
}

export interface ReopenTimeTrackingDay_reopenTimeTrackingDay_timeTrackingEntries_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: ReopenTimeTrackingDay_reopenTimeTrackingDay_timeTrackingEntries_case_debitor;
  /**
   * Case damage details
   */
  damage: ReopenTimeTrackingDay_reopenTimeTrackingDay_timeTrackingEntries_case_damage;
}

export interface ReopenTimeTrackingDay_reopenTimeTrackingDay_timeTrackingEntries_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface ReopenTimeTrackingDay_reopenTimeTrackingDay_timeTrackingEntries_lastModifiedBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface ReopenTimeTrackingDay_reopenTimeTrackingDay_timeTrackingEntries_addonLines_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface ReopenTimeTrackingDay_reopenTimeTrackingDay_timeTrackingEntries_addonLines_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Company identifier
   */
  company: string;
}

export interface ReopenTimeTrackingDay_reopenTimeTrackingDay_timeTrackingEntries_addonLines_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: ReopenTimeTrackingDay_reopenTimeTrackingDay_timeTrackingEntries_addonLines_case_debitor;
}

export interface ReopenTimeTrackingDay_reopenTimeTrackingDay_timeTrackingEntries_addonLines {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: ReopenTimeTrackingDay_reopenTimeTrackingDay_timeTrackingEntries_addonLines_user;
  userId: string;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: ReopenTimeTrackingDay_reopenTimeTrackingDay_timeTrackingEntries_addonLines_case | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Was the task on callback
   */
  onCallback: boolean;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * bcStatus
   */
  bcStatus: TimeTrackingStatus;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
}

export interface ReopenTimeTrackingDay_reopenTimeTrackingDay_timeTrackingEntries {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: ReopenTimeTrackingDay_reopenTimeTrackingDay_timeTrackingEntries_user;
  userId: string;
  /**
   * Start date for time tracking
   */
  startDate: GQL_Date;
  /**
   * End date for time tracking
   */
  endDate: GQL_Date;
  /**
   * Start time for time tracking
   */
  startTime: string;
  /**
   * End time for time tracking
   */
  endTime: string;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: ReopenTimeTrackingDay_reopenTimeTrackingDay_timeTrackingEntries_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: ReopenTimeTrackingDay_reopenTimeTrackingDay_timeTrackingEntries_drivingSlip | null;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Was the task on callback
   */
  onCallback: boolean;
  /**
   * Does the entry include lunch
   */
  includesLunch: boolean;
  /**
   * task
   */
  task: TimeTrackingEntryTask;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * BC status
   */
  bcStatus: TimeTrackingStatus | null;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Timestamp of when the entry was last modified
   */
  lastModifiedAt: GQL_DateTime | null;
  /**
   * User who last modified the entry
   */
  lastModifiedBy: ReopenTimeTrackingDay_reopenTimeTrackingDay_timeTrackingEntries_lastModifiedBy | null;
  /**
   * Add on supplementLines
   */
  addonLines: ReopenTimeTrackingDay_reopenTimeTrackingDay_timeTrackingEntries_addonLines[];
}

export interface ReopenTimeTrackingDay_reopenTimeTrackingDay {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * Type of open, closed or rejected
   */
  timeTrackingDayStatus: TimeTrackingDayStatus;
  /**
   * AD user
   */
  user: ReopenTimeTrackingDay_reopenTimeTrackingDay_user;
  userId: string;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Total hours
   */
  totalHours: number;
  /**
   * Total Overtime hours
   */
  overtimeHours: number;
  /**
   * Indicates if day is part of range
   */
  isPartOfRange: boolean;
  /**
   * Start date for range
   */
  rangeStartDate: GQL_Date | null;
  /**
   * End date for range
   */
  rangeEndDate: GQL_Date | null;
  /**
   * Time Tracking Entries
   */
  timeTrackingEntries: ReopenTimeTrackingDay_reopenTimeTrackingDay_timeTrackingEntries[];
}

export interface ReopenTimeTrackingDay {
  reopenTimeTrackingDay: ReopenTimeTrackingDay_reopenTimeTrackingDay | null;
}

export interface ReopenTimeTrackingDayVariables {
  timeTrackingDay: TimeTrackingDayInputType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SubmitTimeTrackingDay
// ====================================================

export interface SubmitTimeTrackingDay_submitTimeTrackingDay_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User employee id
   */
  employeeId: string | null;
}

export interface SubmitTimeTrackingDay_submitTimeTrackingDay_timeTrackingEntries_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface SubmitTimeTrackingDay_submitTimeTrackingDay_timeTrackingEntries_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Company identifier
   */
  company: string;
}

export interface SubmitTimeTrackingDay_submitTimeTrackingDay_timeTrackingEntries_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface SubmitTimeTrackingDay_submitTimeTrackingDay_timeTrackingEntries_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface SubmitTimeTrackingDay_submitTimeTrackingDay_timeTrackingEntries_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
}

export interface SubmitTimeTrackingDay_submitTimeTrackingDay_timeTrackingEntries_case_damage_contact {
  /**
   * Contact address information
   */
  address: SubmitTimeTrackingDay_submitTimeTrackingDay_timeTrackingEntries_case_damage_contact_address;
}

export interface SubmitTimeTrackingDay_submitTimeTrackingDay_timeTrackingEntries_case_damage {
  /**
   * Damage category
   */
  category: SubmitTimeTrackingDay_submitTimeTrackingDay_timeTrackingEntries_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: SubmitTimeTrackingDay_submitTimeTrackingDay_timeTrackingEntries_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: SubmitTimeTrackingDay_submitTimeTrackingDay_timeTrackingEntries_case_damage_contact;
}

export interface SubmitTimeTrackingDay_submitTimeTrackingDay_timeTrackingEntries_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: SubmitTimeTrackingDay_submitTimeTrackingDay_timeTrackingEntries_case_debitor;
  /**
   * Case damage details
   */
  damage: SubmitTimeTrackingDay_submitTimeTrackingDay_timeTrackingEntries_case_damage;
}

export interface SubmitTimeTrackingDay_submitTimeTrackingDay_timeTrackingEntries_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface SubmitTimeTrackingDay_submitTimeTrackingDay_timeTrackingEntries_lastModifiedBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface SubmitTimeTrackingDay_submitTimeTrackingDay_timeTrackingEntries_addonLines_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface SubmitTimeTrackingDay_submitTimeTrackingDay_timeTrackingEntries_addonLines_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Company identifier
   */
  company: string;
}

export interface SubmitTimeTrackingDay_submitTimeTrackingDay_timeTrackingEntries_addonLines_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: SubmitTimeTrackingDay_submitTimeTrackingDay_timeTrackingEntries_addonLines_case_debitor;
}

export interface SubmitTimeTrackingDay_submitTimeTrackingDay_timeTrackingEntries_addonLines {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: SubmitTimeTrackingDay_submitTimeTrackingDay_timeTrackingEntries_addonLines_user;
  userId: string;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: SubmitTimeTrackingDay_submitTimeTrackingDay_timeTrackingEntries_addonLines_case | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Was the task on callback
   */
  onCallback: boolean;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * bcStatus
   */
  bcStatus: TimeTrackingStatus;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
}

export interface SubmitTimeTrackingDay_submitTimeTrackingDay_timeTrackingEntries {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: SubmitTimeTrackingDay_submitTimeTrackingDay_timeTrackingEntries_user;
  userId: string;
  /**
   * Start date for time tracking
   */
  startDate: GQL_Date;
  /**
   * End date for time tracking
   */
  endDate: GQL_Date;
  /**
   * Start time for time tracking
   */
  startTime: string;
  /**
   * End time for time tracking
   */
  endTime: string;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: SubmitTimeTrackingDay_submitTimeTrackingDay_timeTrackingEntries_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: SubmitTimeTrackingDay_submitTimeTrackingDay_timeTrackingEntries_drivingSlip | null;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Was the task on callback
   */
  onCallback: boolean;
  /**
   * Does the entry include lunch
   */
  includesLunch: boolean;
  /**
   * task
   */
  task: TimeTrackingEntryTask;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * BC status
   */
  bcStatus: TimeTrackingStatus | null;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Timestamp of when the entry was last modified
   */
  lastModifiedAt: GQL_DateTime | null;
  /**
   * User who last modified the entry
   */
  lastModifiedBy: SubmitTimeTrackingDay_submitTimeTrackingDay_timeTrackingEntries_lastModifiedBy | null;
  /**
   * Add on supplementLines
   */
  addonLines: SubmitTimeTrackingDay_submitTimeTrackingDay_timeTrackingEntries_addonLines[];
}

export interface SubmitTimeTrackingDay_submitTimeTrackingDay {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * Type of open, closed or rejected
   */
  timeTrackingDayStatus: TimeTrackingDayStatus;
  /**
   * AD user
   */
  user: SubmitTimeTrackingDay_submitTimeTrackingDay_user;
  userId: string;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Total hours
   */
  totalHours: number;
  /**
   * Total Overtime hours
   */
  overtimeHours: number;
  /**
   * Indicates if day is part of range
   */
  isPartOfRange: boolean;
  /**
   * Start date for range
   */
  rangeStartDate: GQL_Date | null;
  /**
   * End date for range
   */
  rangeEndDate: GQL_Date | null;
  /**
   * Time Tracking Entries
   */
  timeTrackingEntries: SubmitTimeTrackingDay_submitTimeTrackingDay_timeTrackingEntries[];
}

export interface SubmitTimeTrackingDay {
  submitTimeTrackingDay: SubmitTimeTrackingDay_submitTimeTrackingDay | null;
}

export interface SubmitTimeTrackingDayVariables {
  timeTrackingDay: TimeTrackingDayInputType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTimeTrackingDayEntry
// ====================================================

export interface UpdateTimeTrackingDayEntry_updateTimeTrackingDayEntry_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User employee id
   */
  employeeId: string | null;
}

export interface UpdateTimeTrackingDayEntry_updateTimeTrackingDayEntry_timeTrackingEntries_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface UpdateTimeTrackingDayEntry_updateTimeTrackingDayEntry_timeTrackingEntries_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Company identifier
   */
  company: string;
}

export interface UpdateTimeTrackingDayEntry_updateTimeTrackingDayEntry_timeTrackingEntries_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface UpdateTimeTrackingDayEntry_updateTimeTrackingDayEntry_timeTrackingEntries_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface UpdateTimeTrackingDayEntry_updateTimeTrackingDayEntry_timeTrackingEntries_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
}

export interface UpdateTimeTrackingDayEntry_updateTimeTrackingDayEntry_timeTrackingEntries_case_damage_contact {
  /**
   * Contact address information
   */
  address: UpdateTimeTrackingDayEntry_updateTimeTrackingDayEntry_timeTrackingEntries_case_damage_contact_address;
}

export interface UpdateTimeTrackingDayEntry_updateTimeTrackingDayEntry_timeTrackingEntries_case_damage {
  /**
   * Damage category
   */
  category: UpdateTimeTrackingDayEntry_updateTimeTrackingDayEntry_timeTrackingEntries_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: UpdateTimeTrackingDayEntry_updateTimeTrackingDayEntry_timeTrackingEntries_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: UpdateTimeTrackingDayEntry_updateTimeTrackingDayEntry_timeTrackingEntries_case_damage_contact;
}

export interface UpdateTimeTrackingDayEntry_updateTimeTrackingDayEntry_timeTrackingEntries_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: UpdateTimeTrackingDayEntry_updateTimeTrackingDayEntry_timeTrackingEntries_case_debitor;
  /**
   * Case damage details
   */
  damage: UpdateTimeTrackingDayEntry_updateTimeTrackingDayEntry_timeTrackingEntries_case_damage;
}

export interface UpdateTimeTrackingDayEntry_updateTimeTrackingDayEntry_timeTrackingEntries_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface UpdateTimeTrackingDayEntry_updateTimeTrackingDayEntry_timeTrackingEntries_lastModifiedBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface UpdateTimeTrackingDayEntry_updateTimeTrackingDayEntry_timeTrackingEntries_addonLines_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface UpdateTimeTrackingDayEntry_updateTimeTrackingDayEntry_timeTrackingEntries_addonLines_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Company identifier
   */
  company: string;
}

export interface UpdateTimeTrackingDayEntry_updateTimeTrackingDayEntry_timeTrackingEntries_addonLines_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: UpdateTimeTrackingDayEntry_updateTimeTrackingDayEntry_timeTrackingEntries_addonLines_case_debitor;
}

export interface UpdateTimeTrackingDayEntry_updateTimeTrackingDayEntry_timeTrackingEntries_addonLines {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: UpdateTimeTrackingDayEntry_updateTimeTrackingDayEntry_timeTrackingEntries_addonLines_user;
  userId: string;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: UpdateTimeTrackingDayEntry_updateTimeTrackingDayEntry_timeTrackingEntries_addonLines_case | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Was the task on callback
   */
  onCallback: boolean;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * bcStatus
   */
  bcStatus: TimeTrackingStatus;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
}

export interface UpdateTimeTrackingDayEntry_updateTimeTrackingDayEntry_timeTrackingEntries {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: UpdateTimeTrackingDayEntry_updateTimeTrackingDayEntry_timeTrackingEntries_user;
  userId: string;
  /**
   * Start date for time tracking
   */
  startDate: GQL_Date;
  /**
   * End date for time tracking
   */
  endDate: GQL_Date;
  /**
   * Start time for time tracking
   */
  startTime: string;
  /**
   * End time for time tracking
   */
  endTime: string;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: UpdateTimeTrackingDayEntry_updateTimeTrackingDayEntry_timeTrackingEntries_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: UpdateTimeTrackingDayEntry_updateTimeTrackingDayEntry_timeTrackingEntries_drivingSlip | null;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Was the task on callback
   */
  onCallback: boolean;
  /**
   * Does the entry include lunch
   */
  includesLunch: boolean;
  /**
   * task
   */
  task: TimeTrackingEntryTask;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * BC status
   */
  bcStatus: TimeTrackingStatus | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Timestamp of when the entry was last modified
   */
  lastModifiedAt: GQL_DateTime | null;
  /**
   * User who last modified the entry
   */
  lastModifiedBy: UpdateTimeTrackingDayEntry_updateTimeTrackingDayEntry_timeTrackingEntries_lastModifiedBy | null;
  /**
   * Add on supplementLines
   */
  addonLines: UpdateTimeTrackingDayEntry_updateTimeTrackingDayEntry_timeTrackingEntries_addonLines[];
}

export interface UpdateTimeTrackingDayEntry_updateTimeTrackingDayEntry {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * Type of open, closed or rejected
   */
  timeTrackingDayStatus: TimeTrackingDayStatus;
  /**
   * AD user
   */
  user: UpdateTimeTrackingDayEntry_updateTimeTrackingDayEntry_user;
  userId: string;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Total hours
   */
  totalHours: number;
  /**
   * Total Overtime hours
   */
  overtimeHours: number;
  /**
   * Indicates if day is part of range
   */
  isPartOfRange: boolean;
  /**
   * Start date for range
   */
  rangeStartDate: GQL_Date | null;
  /**
   * End date for range
   */
  rangeEndDate: GQL_Date | null;
  /**
   * Time Tracking Entries
   */
  timeTrackingEntries: UpdateTimeTrackingDayEntry_updateTimeTrackingDayEntry_timeTrackingEntries[];
}

export interface UpdateTimeTrackingDayEntry {
  updateTimeTrackingDayEntry: UpdateTimeTrackingDayEntry_updateTimeTrackingDayEntry;
}

export interface UpdateTimeTrackingDayEntryVariables {
  timeTrackingDayId: string;
  timeTrackingEntry: TimeTrackingEntryUpdateInputType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserFlexTime
// ====================================================

export interface GetUserFlexTime {
  userFlexTime: GQL_Decimal | null;
}

export interface GetUserFlexTimeVariables {
  user: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWebCurrentUser
// ====================================================

export interface GetWebCurrentUser_currentUser_gdpr_userId {
  /**
   * Unique id of user
   */
  id: string;
}

export interface GetWebCurrentUser_currentUser_gdpr {
  /**
   * Unique id of gdpr accept
   */
  id: string;
  /**
   * Timestamp of acceptance
   */
  timestamp: GQL_DateTime;
  /**
   * User who accepted GDPR
   */
  userId: GetWebCurrentUser_currentUser_gdpr_userId;
}

export interface GetWebCurrentUser_currentUser_caseFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
  /**
   * Filter view type
   */
  view: UserCaseFilterViewType;
  /**
   * Case awaiting status toggle
   */
  awaiting: boolean | null;
  /**
   * Case damage service completed status toggle
   */
  damageServiceCompleted: boolean | null;
  /**
   * Case called back status toggle
   */
  calledBack: boolean | null;
  /**
   * Case machines on case status toggle
   */
  machinesOnCase: boolean | null;
  /**
   * Toggle to show economy fields
   */
  showEconomyField: boolean;
  /**
   * Toggle to show business area fields
   */
  showBusinessAreaField: boolean;
  /**
   * Toggle to show applied closed cases
   */
  showAppliedClosedCases: boolean;
  /**
   * Toggle to show closed cases
   */
  showClosedCases: boolean;
  /**
   * Debitors to filter cases by
   */
  debitors: string[];
  /**
   * Postal code input text
   */
  postalCodeText: string;
  /**
   * Postal codes to filter cases by
   */
  postalCodes: string[];
  /**
   * Track to filter cases by
   */
  track: number[];
  /**
   * Departments to filter cases by
   */
  departments: string[];
  /**
   * Locations to filter cases by
   */
  locations: string[];
  /**
   * Damage categories to filter cases by
   */
  damageCategories: string[];
  /**
   * Damage causes to filter cases by
   */
  damageCauses: string[];
  /**
   * Priority to filter cases by
   */
  priority: CasePriority[];
  /**
   * Case managers to filter cases by
   */
  caseManagers: string[];
  /**
   * Project managers to filter cases by
   */
  projectManagers: string[];
}

export interface GetWebCurrentUser_currentUser_drivingSlipFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
  /**
   * Debitors to filter driving slips by
   */
  debitors: string[];
  /**
   * Postal code input text
   */
  postalCodeText: string;
  /**
   * Postal codes to filter driving slips by
   */
  postalCodes: string[];
  /**
   * Departments to filter driving slips by
   */
  departments: string[];
  /**
   * Locations to filter driving slips by
   */
  locations: string[];
  /**
   * Damage categories to filter driving slips by
   */
  damageCategories: string[];
  /**
   * Damage causes to filter driving slips by
   */
  damageCauses: string[];
  /**
   * ERP reference nos to filter driving slips by
   */
  erpNos: string[];
  /**
   * Project managers to filter driving slips by
   */
  projectManagers: string[];
}

export interface GetWebCurrentUser_currentUser_drivingSlipOverviewFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
  /**
   * Status to filter by
   */
  status: DrivingSlipStatus | null;
  /**
   * Urgent filter toggle
   */
  urgent: boolean | null;
  /**
   * Exceeded filter toggle
   */
  exceeded: boolean | null;
  /**
   * Departments to filter by
   */
  departments: string[];
  /**
   * Locations to filter by
   */
  locations: string[];
  /**
   * Damage causes to filter by
   */
  damageCauses: string[];
  /**
   * Damage categories to filter by
   */
  damageCategories: string[];
  /**
   * Drivers to filter by
   */
  drivers: string[];
  /**
   * Filter for no drivers
   */
  noDriver: boolean | null;
}

export interface GetWebCurrentUser_currentUser_sanitizerFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
  /**
   * Departments to filter sanitizers by
   */
  departments: string[];
  /**
   * Locations to filter sanitizers by
   */
  locations: string[];
  /**
   * Job functions to filter sanitizers by
   */
  jobFunctions: string[];
  /**
   * Pre selected sanitizers on filter
   */
  sanitizers: string[];
}

export interface GetWebCurrentUser_currentUser_carFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
  /**
   * Locations to filter cars by
   */
  locations: string[];
  /**
   * Pre selected cars on filter
   */
  cars: string[];
  /**
   * Departments to filter cars by
   */
  departments: string[];
}

export interface GetWebCurrentUser_currentUser_materialFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
  /**
   * Locations to filter cars by
   */
  locations: string[];
  /**
   * Pre selected cars on filter
   */
  cars: string[];
  /**
   * Departments to filter cars by
   */
  departments: string[];
}

export interface GetWebCurrentUser_currentUser_movableFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
  /**
   * ERP reference no to filter movables by
   */
  erpNo: string;
  /**
   * Location to filter movables by
   */
  location: string;
  /**
   * Status to filter movables by
   */
  status: string;
  /**
   * Toggle to show completed movables
   */
  includeCompleted: boolean;
  /**
   * Debitors to filter movables by
   */
  debitors: string[] | null;
}

export interface GetWebCurrentUser_currentUser_jobFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
  /**
   * Location to filter jobs by
   */
  location: string;
  /**
   * Toggle to show only personal jobs
   */
  personalOnly: boolean;
}

export interface GetWebCurrentUser_currentUser_machineFilters {
  /**
   * Name of filter
   */
  name: string;
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Status to filter by
   */
  status: MachineStatus | null;
  /**
   * Cases to filter by
   */
  cases: string[] | null;
  /**
   * Locations to filter by
   */
  locations: string[] | null;
  /**
   * Types to filter by
   */
  types: string[] | null;
  /**
   * Include unavailable machines
   */
  unavailable: boolean | null;
  /**
   * Include hibernating machines
   */
  hibernation: boolean | null;
}

export interface GetWebCurrentUser_currentUser {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
  /**
   * Users GDPR
   */
  gdpr: GetWebCurrentUser_currentUser_gdpr | null;
  /**
   * permissions of user
   */
  permissions: Permissions[];
  /**
   * External user
   */
  external: boolean;
  /**
   * User saved case filters
   */
  caseFilters: GetWebCurrentUser_currentUser_caseFilters[];
  /**
   * User saved driving slip filters
   */
  drivingSlipFilters: GetWebCurrentUser_currentUser_drivingSlipFilters[];
  /**
   * User saved driving slip overview filters
   */
  drivingSlipOverviewFilters: GetWebCurrentUser_currentUser_drivingSlipOverviewFilters[];
  /**
   * User saved sanitizer filters
   */
  sanitizerFilters: GetWebCurrentUser_currentUser_sanitizerFilters[];
  /**
   * User saved car filters
   */
  carFilters: GetWebCurrentUser_currentUser_carFilters[];
  /**
   * User saved material filters
   */
  materialFilters: GetWebCurrentUser_currentUser_materialFilters[];
  /**
   * User saved movable filters
   */
  movableFilters: GetWebCurrentUser_currentUser_movableFilters[];
  /**
   * User saved job filters
   */
  jobFilters: GetWebCurrentUser_currentUser_jobFilters[];
  /**
   * User saved machine filters
   */
  machineFilters: GetWebCurrentUser_currentUser_machineFilters[];
  /**
   * Employee hire start date
   */
  employeeHireStartDate: GQL_DateTime | null;
  /**
   * Employee salary type
   */
  employeeSalaryType: string | null;
  /**
   * List of managed users
   */
  managerFor: string[];
}

export interface GetWebCurrentUser {
  currentUser: GetWebCurrentUser_currentUser | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveUserCarFilter
// ====================================================

export interface RemoveUserCarFilter_removeUserVehicleFilter_carFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
}

export interface RemoveUserCarFilter_removeUserVehicleFilter {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
  /**
   * User saved car filters
   */
  carFilters: RemoveUserCarFilter_removeUserVehicleFilter_carFilters[];
}

export interface RemoveUserCarFilter {
  removeUserVehicleFilter: RemoveUserCarFilter_removeUserVehicleFilter;
}

export interface RemoveUserCarFilterVariables {
  filterKey: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveUserCaseFilter
// ====================================================

export interface RemoveUserCaseFilter_removeUserCaseFilter_caseFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
}

export interface RemoveUserCaseFilter_removeUserCaseFilter {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
  /**
   * User saved case filters
   */
  caseFilters: RemoveUserCaseFilter_removeUserCaseFilter_caseFilters[];
}

export interface RemoveUserCaseFilter {
  removeUserCaseFilter: RemoveUserCaseFilter_removeUserCaseFilter;
}

export interface RemoveUserCaseFilterVariables {
  filterKey: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveUserDrivingSlipFilter
// ====================================================

export interface RemoveUserDrivingSlipFilter_removeUserDrivingSlipFilter_drivingSlipFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
}

export interface RemoveUserDrivingSlipFilter_removeUserDrivingSlipFilter {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
  /**
   * User saved driving slip filters
   */
  drivingSlipFilters: RemoveUserDrivingSlipFilter_removeUserDrivingSlipFilter_drivingSlipFilters[];
}

export interface RemoveUserDrivingSlipFilter {
  removeUserDrivingSlipFilter: RemoveUserDrivingSlipFilter_removeUserDrivingSlipFilter;
}

export interface RemoveUserDrivingSlipFilterVariables {
  filterKey: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveUserDrivingSlipOverviewFilter
// ====================================================

export interface RemoveUserDrivingSlipOverviewFilter_removeUserDrivingSlipOverviewFilter_drivingSlipOverviewFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
}

export interface RemoveUserDrivingSlipOverviewFilter_removeUserDrivingSlipOverviewFilter {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
  /**
   * User saved driving slip overview filters
   */
  drivingSlipOverviewFilters: RemoveUserDrivingSlipOverviewFilter_removeUserDrivingSlipOverviewFilter_drivingSlipOverviewFilters[];
}

export interface RemoveUserDrivingSlipOverviewFilter {
  removeUserDrivingSlipOverviewFilter: RemoveUserDrivingSlipOverviewFilter_removeUserDrivingSlipOverviewFilter;
}

export interface RemoveUserDrivingSlipOverviewFilterVariables {
  filterKey: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveUserJobFilter
// ====================================================

export interface RemoveUserJobFilter_removeUserJobFilter_jobFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
}

export interface RemoveUserJobFilter_removeUserJobFilter {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
  /**
   * User saved job filters
   */
  jobFilters: RemoveUserJobFilter_removeUserJobFilter_jobFilters[];
}

export interface RemoveUserJobFilter {
  removeUserJobFilter: RemoveUserJobFilter_removeUserJobFilter;
}

export interface RemoveUserJobFilterVariables {
  filterKey: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveUserMachineFilter
// ====================================================

export interface RemoveUserMachineFilter_removeUserMachineFilter_machineFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
}

export interface RemoveUserMachineFilter_removeUserMachineFilter {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
  /**
   * User saved machine filters
   */
  machineFilters: RemoveUserMachineFilter_removeUserMachineFilter_machineFilters[];
}

export interface RemoveUserMachineFilter {
  removeUserMachineFilter: RemoveUserMachineFilter_removeUserMachineFilter;
}

export interface RemoveUserMachineFilterVariables {
  filterKey: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveUserMaterialFilter
// ====================================================

export interface RemoveUserMaterialFilter_removeUserMaterialFilter_materialFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
}

export interface RemoveUserMaterialFilter_removeUserMaterialFilter {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
  /**
   * User saved material filters
   */
  materialFilters: RemoveUserMaterialFilter_removeUserMaterialFilter_materialFilters[];
}

export interface RemoveUserMaterialFilter {
  removeUserMaterialFilter: RemoveUserMaterialFilter_removeUserMaterialFilter;
}

export interface RemoveUserMaterialFilterVariables {
  filterKey: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveUserMovableFilter
// ====================================================

export interface RemoveUserMovableFilter_removeUserMovableFilter_movableFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
}

export interface RemoveUserMovableFilter_removeUserMovableFilter {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
  /**
   * User saved movable filters
   */
  movableFilters: RemoveUserMovableFilter_removeUserMovableFilter_movableFilters[];
}

export interface RemoveUserMovableFilter {
  removeUserMovableFilter: RemoveUserMovableFilter_removeUserMovableFilter;
}

export interface RemoveUserMovableFilterVariables {
  filterKey: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveUserSanitizerFilter
// ====================================================

export interface RemoveUserSanitizerFilter_removeUserSanitizerFilter_sanitizerFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
  /**
   * Departments to filter sanitizers by
   */
  departments: string[];
  /**
   * Locations to filter sanitizers by
   */
  locations: string[];
  /**
   * Job functions to filter sanitizers by
   */
  jobFunctions: string[];
  /**
   * Pre selected sanitizers on filter
   */
  sanitizers: string[];
}

export interface RemoveUserSanitizerFilter_removeUserSanitizerFilter {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User saved sanitizer filters
   */
  sanitizerFilters: RemoveUserSanitizerFilter_removeUserSanitizerFilter_sanitizerFilters[];
}

export interface RemoveUserSanitizerFilter {
  removeUserSanitizerFilter: RemoveUserSanitizerFilter_removeUserSanitizerFilter;
}

export interface RemoveUserSanitizerFilterVariables {
  filterKey: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SearchWebUsers
// ====================================================

export interface SearchWebUsers_searchUsers {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
  /**
   * Employee hire start date
   */
  employeeHireStartDate: GQL_DateTime | null;
  /**
   * Employee salary type
   */
  employeeSalaryType: string | null;
}

export interface SearchWebUsers {
  searchUsers: SearchWebUsers_searchUsers[];
}

export interface SearchWebUsersVariables {
  searchText: string;
  onlyEmployees?: boolean | null;
  checkInBc?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetUserCarFilter
// ====================================================

export interface SetUserCarFilter_setUserVehicleFilter_carFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
  /**
   * Locations to filter cars by
   */
  locations: string[];
  /**
   * Pre selected cars on filter
   */
  cars: string[];
}

export interface SetUserCarFilter_setUserVehicleFilter {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User saved car filters
   */
  carFilters: SetUserCarFilter_setUserVehicleFilter_carFilters[];
}

export interface SetUserCarFilter {
  setUserVehicleFilter: SetUserCarFilter_setUserVehicleFilter;
}

export interface SetUserCarFilterVariables {
  filter: UserVehicleFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetUserCaseFilter
// ====================================================

export interface SetUserCaseFilter_setUserCaseFilter_caseFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
  /**
   * Filter view type
   */
  view: UserCaseFilterViewType;
  /**
   * Case awaiting status toggle
   */
  awaiting: boolean | null;
  /**
   * Case damage service completed status toggle
   */
  damageServiceCompleted: boolean | null;
  /**
   * Case called back status toggle
   */
  calledBack: boolean | null;
  /**
   * Case machines on case status toggle
   */
  machinesOnCase: boolean | null;
  /**
   * Toggle to show economy fields
   */
  showEconomyField: boolean;
  /**
   * Toggle to show business area fields
   */
  showBusinessAreaField: boolean;
  /**
   * Toggle to show applied closed cases
   */
  showAppliedClosedCases: boolean;
  /**
   * Toggle to show closed cases
   */
  showClosedCases: boolean;
  /**
   * Debitors to filter cases by
   */
  debitors: string[];
  /**
   * Postal code input text
   */
  postalCodeText: string;
  /**
   * Postal codes to filter cases by
   */
  postalCodes: string[];
  /**
   * Track to filter cases by
   */
  track: number[];
  /**
   * Departments to filter cases by
   */
  departments: string[];
  /**
   * Locations to filter cases by
   */
  locations: string[];
  /**
   * Damage categories to filter cases by
   */
  damageCategories: string[];
  /**
   * Damage causes to filter cases by
   */
  damageCauses: string[];
  /**
   * Priority to filter cases by
   */
  priority: CasePriority[];
  /**
   * Case managers to filter cases by
   */
  caseManagers: string[];
  /**
   * Project managers to filter cases by
   */
  projectManagers: string[];
}

export interface SetUserCaseFilter_setUserCaseFilter {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User saved case filters
   */
  caseFilters: SetUserCaseFilter_setUserCaseFilter_caseFilters[];
}

export interface SetUserCaseFilter {
  setUserCaseFilter: SetUserCaseFilter_setUserCaseFilter;
}

export interface SetUserCaseFilterVariables {
  filter: UserCaseFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetUserDefaultLanguage
// ====================================================

export interface SetUserDefaultLanguage_setUserDefaultLanguage {
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetUserDefaultLanguage {
  setUserDefaultLanguage: SetUserDefaultLanguage_setUserDefaultLanguage;
}

export interface SetUserDefaultLanguageVariables {
  language: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetUserDrivingSlipFilter
// ====================================================

export interface SetUserDrivingSlipFilter_setUserDrivingSlipFilter_drivingSlipFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
  /**
   * Debitors to filter driving slips by
   */
  debitors: string[];
  /**
   * Postal code input text
   */
  postalCodeText: string;
  /**
   * Postal codes to filter driving slips by
   */
  postalCodes: string[];
  /**
   * Departments to filter driving slips by
   */
  departments: string[];
  /**
   * Locations to filter driving slips by
   */
  locations: string[];
  /**
   * Damage categories to filter driving slips by
   */
  damageCategories: string[];
  /**
   * Damage causes to filter driving slips by
   */
  damageCauses: string[];
  /**
   * ERP reference nos to filter driving slips by
   */
  erpNos: string[];
  /**
   * Project managers to filter driving slips by
   */
  projectManagers: string[];
}

export interface SetUserDrivingSlipFilter_setUserDrivingSlipFilter {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User saved driving slip filters
   */
  drivingSlipFilters: SetUserDrivingSlipFilter_setUserDrivingSlipFilter_drivingSlipFilters[];
}

export interface SetUserDrivingSlipFilter {
  setUserDrivingSlipFilter: SetUserDrivingSlipFilter_setUserDrivingSlipFilter;
}

export interface SetUserDrivingSlipFilterVariables {
  filter: UserDrivingSlipFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetUserDrivingSlipOverviewFilter
// ====================================================

export interface SetUserDrivingSlipOverviewFilter_setUserDrivingSlipOverviewFilter_drivingSlipOverviewFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
  /**
   * Status to filter by
   */
  status: DrivingSlipStatus | null;
  /**
   * Urgent filter toggle
   */
  urgent: boolean | null;
  /**
   * Exceeded filter toggle
   */
  exceeded: boolean | null;
  /**
   * Departments to filter by
   */
  departments: string[];
  /**
   * Locations to filter by
   */
  locations: string[];
  /**
   * Damage causes to filter by
   */
  damageCauses: string[];
  /**
   * Damage categories to filter by
   */
  damageCategories: string[];
  /**
   * Drivers to filter by
   */
  drivers: string[];
  /**
   * Filter for no drivers
   */
  noDriver: boolean | null;
}

export interface SetUserDrivingSlipOverviewFilter_setUserDrivingSlipOverviewFilter {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User saved driving slip overview filters
   */
  drivingSlipOverviewFilters: SetUserDrivingSlipOverviewFilter_setUserDrivingSlipOverviewFilter_drivingSlipOverviewFilters[];
}

export interface SetUserDrivingSlipOverviewFilter {
  setUserDrivingSlipOverviewFilter: SetUserDrivingSlipOverviewFilter_setUserDrivingSlipOverviewFilter;
}

export interface SetUserDrivingSlipOverviewFilterVariables {
  filter: UserDrivingSlipOverviewFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetUserJobFilter
// ====================================================

export interface SetUserJobFilter_setUserJobFilter_jobFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
  /**
   * Location to filter jobs by
   */
  location: string;
  /**
   * Toggle to show only personal jobs
   */
  personalOnly: boolean;
}

export interface SetUserJobFilter_setUserJobFilter {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User saved job filters
   */
  jobFilters: SetUserJobFilter_setUserJobFilter_jobFilters[];
}

export interface SetUserJobFilter {
  setUserJobFilter: SetUserJobFilter_setUserJobFilter;
}

export interface SetUserJobFilterVariables {
  filter: UserJobFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetUserMachineFilter
// ====================================================

export interface SetUserMachineFilter_setUserMachineFilter_machineFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
  /**
   * Status to filter by
   */
  status: MachineStatus | null;
  /**
   * Cases to filter by
   */
  cases: string[] | null;
  /**
   * Locations to filter by
   */
  locations: string[] | null;
  /**
   * Types to filter by
   */
  types: string[] | null;
  /**
   * Include unavailable machines
   */
  unavailable: boolean | null;
  /**
   * Include hibernating machines
   */
  hibernation: boolean | null;
}

export interface SetUserMachineFilter_setUserMachineFilter {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User saved machine filters
   */
  machineFilters: SetUserMachineFilter_setUserMachineFilter_machineFilters[];
}

export interface SetUserMachineFilter {
  setUserMachineFilter: SetUserMachineFilter_setUserMachineFilter;
}

export interface SetUserMachineFilterVariables {
  filter: UserMachineFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetUserMaterialFilter
// ====================================================

export interface SetUserMaterialFilter_setUserMaterialFilter_materialFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
  /**
   * Locations to filter cars by
   */
  locations: string[];
  /**
   * Pre selected cars on filter
   */
  cars: string[];
}

export interface SetUserMaterialFilter_setUserMaterialFilter {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User saved material filters
   */
  materialFilters: SetUserMaterialFilter_setUserMaterialFilter_materialFilters[];
}

export interface SetUserMaterialFilter {
  setUserMaterialFilter: SetUserMaterialFilter_setUserMaterialFilter;
}

export interface SetUserMaterialFilterVariables {
  filter: UserVehicleFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetUserMovableFilter
// ====================================================

export interface SetUserMovableFilter_setUserMovableFilter_movableFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
  /**
   * ERP reference no to filter movables by
   */
  erpNo: string;
  /**
   * Location to filter movables by
   */
  location: string;
  /**
   * Status to filter movables by
   */
  status: string;
  /**
   * Toggle to show completed movables
   */
  includeCompleted: boolean;
  /**
   * Debitors to filter movables by
   */
  debitors: string[] | null;
}

export interface SetUserMovableFilter_setUserMovableFilter {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User saved movable filters
   */
  movableFilters: SetUserMovableFilter_setUserMovableFilter_movableFilters[];
}

export interface SetUserMovableFilter {
  setUserMovableFilter: SetUserMovableFilter_setUserMovableFilter;
}

export interface SetUserMovableFilterVariables {
  filter: UserMovableFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetUserPlannerHelpText
// ====================================================

export interface SetUserPlannerHelpText_setUserPlannerHelpText {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User planner help text
   */
  plannerHelpText: string | null;
}

export interface SetUserPlannerHelpText {
  setUserPlannerHelpText: SetUserPlannerHelpText_setUserPlannerHelpText;
}

export interface SetUserPlannerHelpTextVariables {
  id: string;
  helpText: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetUserSanitizerFilter
// ====================================================

export interface SetUserSanitizerFilter_setUserSanitizerFilter_sanitizerFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
  /**
   * Departments to filter sanitizers by
   */
  departments: string[];
  /**
   * Locations to filter sanitizers by
   */
  locations: string[];
  /**
   * Job functions to filter sanitizers by
   */
  jobFunctions: string[];
  /**
   * Pre selected sanitizers on filter
   */
  sanitizers: string[];
}

export interface SetUserSanitizerFilter_setUserSanitizerFilter {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User saved sanitizer filters
   */
  sanitizerFilters: SetUserSanitizerFilter_setUserSanitizerFilter_sanitizerFilters[];
}

export interface SetUserSanitizerFilter {
  setUserSanitizerFilter: SetUserSanitizerFilter_setUserSanitizerFilter;
}

export interface SetUserSanitizerFilterVariables {
  filter: UserSanitizerFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCauseQuestions
// ====================================================

export interface GetCauseQuestions_causeQuestions_damageCause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface GetCauseQuestions_causeQuestions_damageCategory {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetCauseQuestions_causeQuestions {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question to ask based on damage cause
   */
  question: string;
  /**
   * Which damage cause the question is based on
   */
  damageCause: GetCauseQuestions_causeQuestions_damageCause;
  /**
   * Which damage category the question is based on
   */
  damageCategory: GetCauseQuestions_causeQuestions_damageCategory;
}

export interface GetCauseQuestions {
  causeQuestions: GetCauseQuestions_causeQuestions[];
}

export interface GetCauseQuestionsVariables {
  damageCause: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetQuestions
// ====================================================

export interface GetQuestions_questions_damageCause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface GetQuestions_questions_damageCategory {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetQuestions_questions {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question to ask based on damage cause
   */
  question: string;
  /**
   * Which damage cause the question is based on
   */
  damageCause: GetQuestions_questions_damageCause;
  /**
   * Which damage category the question is based on
   */
  damageCategory: GetQuestions_questions_damageCategory;
}

export interface GetQuestions {
  questions: GetQuestions_questions[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AddressFragment
// ====================================================

export interface AddressFragment {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseAPVFragment
// ====================================================

export interface BaseAPVFragment_damageCategory {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface BaseAPVFragment_damageCause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface BaseAPVFragment {
  /**
   * Unique id of apv
   */
  id: string;
  /**
   * Url to file location
   */
  url: string;
  /**
   * Id of associated category
   */
  damageCategory: BaseAPVFragment_damageCategory;
  /**
   * Id of associated cause
   */
  damageCause: BaseAPVFragment_damageCause;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseBusinessAreaFragment
// ====================================================

export interface BaseBusinessAreaFragment {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseCarFragment
// ====================================================

export interface BaseCarFragment {
  /**
   * Unique id of car
   */
  id: string;
  /**
   * Name of car
   */
  name: string;
  /**
   * Department car is attached to
   */
  department: string | null;
  /**
   * Email of car
   */
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseCaseFragment
// ====================================================

export interface BaseCaseFragment_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseCaseFragment_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseCaseFragment_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: BaseCaseFragment_debitor_shippingAddress_address;
}

export interface BaseCaseFragment_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseCaseFragment_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: BaseCaseFragment_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: BaseCaseFragment_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: BaseCaseFragment_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface BaseCaseFragment_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface BaseCaseFragment_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface BaseCaseFragment_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface BaseCaseFragment_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface BaseCaseFragment_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface BaseCaseFragment_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: BaseCaseFragment_damage_cause_departments_location;
}

export interface BaseCaseFragment_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: BaseCaseFragment_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: BaseCaseFragment_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (BaseCaseFragment_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface BaseCaseFragment_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseCaseFragment_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: BaseCaseFragment_damage_contact_address;
}

export interface BaseCaseFragment_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface BaseCaseFragment_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: BaseCaseFragment_damage_businessArea;
  /**
   * Damage category
   */
  category: BaseCaseFragment_damage_category;
  /**
   * Cause behind damage
   */
  cause: BaseCaseFragment_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: BaseCaseFragment_damage_contact;
  /**
   * contacts for damage
   */
  contacts: BaseCaseFragment_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface BaseCaseFragment_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseCaseFragment_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: BaseCaseFragment_policyHolder_address;
}

export interface BaseCaseFragment_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface BaseCaseFragment_visitation_awaiting {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface BaseCaseFragment_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
  /**
   * The question
   */
  title: string;
}

export interface BaseCaseFragment_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: BaseCaseFragment_visitation_calledBack;
  /**
   * If case is awaiting
   */
  awaiting: BaseCaseFragment_visitation_awaiting;
  /**
   * A set of answers related to the damage cause
   */
  answers: BaseCaseFragment_visitation_answers[] | null;
  /**
   * If case needs a moisture report
   */
  isMoistureReportRequired: boolean | null;
  /**
   * If case needs an SP phase one report
   */
  isSpPhaseOneReportCreated: boolean | null;
}

export interface BaseCaseFragment_requisitioner {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Requisitioner relation
   */
  relation: string;
}

export interface BaseCaseFragment_adviser {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface BaseCaseFragment_referrer {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface BaseCaseFragment_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseCaseFragment_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseCaseFragment_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseCaseFragment_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: BaseCaseFragment_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface BaseCaseFragment_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseCaseFragment_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: BaseCaseFragment_ssgDepartment_location_address;
}

export interface BaseCaseFragment_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: BaseCaseFragment_ssgDepartment_location;
}

export interface BaseCaseFragment_caseAgreements_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseCaseFragment_caseAgreements {
  /**
   * Agreement content
   */
  content: string;
  /**
   * Agreement category
   */
  category: CaseAgreementCategory;
  /**
   * User who created agreement
   */
  user: BaseCaseFragment_caseAgreements_user;
  /**
   * Timestamp of when agreement was created
   */
  timestamp: GQL_DateTime;
  /**
   * Whether case agreement should be sent to ScalePoint
   */
  shouldSaveInScalePoint: boolean;
}

export interface BaseCaseFragment_caseEmails {
  /**
   * Email content
   */
  content: string;
  /**
   * List of email attachments
   */
  attachments: string[];
  /**
   * List of email receivers
   */
  receivers: string[];
  /**
   * Timestamp of when email was created
   */
  timestamp: GQL_DateTime;
}

export interface BaseCaseFragment_userFavorite {
  /**
   * Unique user id
   */
  user: string;
  /**
   * Color code set by user
   */
  color: string | null;
  /**
   * Favorite active status
   */
  active: boolean;
}

export interface BaseCaseFragment_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseCaseFragment_jobs_assignedTo {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
}

export interface BaseCaseFragment_jobs_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface BaseCaseFragment_jobs_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
}

export interface BaseCaseFragment_jobs_movable_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface BaseCaseFragment_jobs_movable {
  /**
   * Unique id of movable
   */
  id: string;
  /**
   * Description of movable
   */
  description: string;
  /**
   * Movable status
   */
  status: string;
  /**
   * Movable placement
   */
  placement: BaseCaseFragment_jobs_movable_placement;
}

export interface BaseCaseFragment_jobs {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Type of case task
   */
  type: JobType;
  /**
   * Description of the case task
   */
  description: string;
  /**
   * Deadline of the case task
   */
  deadline: GQL_DateTime;
  /**
   * The user responsible for the task
   */
  assignedTo: BaseCaseFragment_jobs_assignedTo | null;
  /**
   * The location responsible to the task
   */
  location: BaseCaseFragment_jobs_location | null;
  /**
   * The case the task belongs to
   */
  case: BaseCaseFragment_jobs_case | null;
  /**
   * The movable responsible to the task
   */
  movable: BaseCaseFragment_jobs_movable | null;
  /**
   * Whether the task is completed
   */
  completed: boolean;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: BaseCaseFragment_drivingSlipSeries_drivingSlips_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: BaseCaseFragment_drivingSlipSeries_drivingSlips_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: BaseCaseFragment_drivingSlipSeries_drivingSlips_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_user;
  /**
   * Value before
   */
  before: BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_before;
  /**
   * Value after
   */
  after: BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_after;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: BaseCaseFragment_drivingSlipSeries_drivingSlips_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: BaseCaseFragment_drivingSlipSeries_drivingSlips_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: BaseCaseFragment_drivingSlipSeries_drivingSlips_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: BaseCaseFragment_drivingSlipSeries_drivingSlips_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: BaseCaseFragment_drivingSlipSeries_drivingSlips_changes[];
  /**
   * Location of driving slip
   */
  location: BaseCaseFragment_drivingSlipSeries_drivingSlips_location;
  /**
   * Department of driving slip
   */
  department: BaseCaseFragment_drivingSlipSeries_drivingSlips_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
}

export interface BaseCaseFragment_drivingSlipSeries {
  /**
   * Unique id of driving slip series
   */
  id: string;
  /**
   * Driving slip series completed status
   */
  completed: boolean;
  /**
   * Driving slips on series
   */
  drivingSlips: BaseCaseFragment_drivingSlipSeries_drivingSlips[];
}

export interface BaseCaseFragment_alternativeContact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Comment on person
   */
  comment: string | null;
}

export interface BaseCaseFragment_riskEvaluationAnswers_question {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question
   */
  question: string;
}

export interface BaseCaseFragment_riskEvaluationAnswers {
  /**
   * Id of question answer is linked to
   */
  question: BaseCaseFragment_riskEvaluationAnswers_question;
  /**
   * Answer given
   */
  answer: boolean;
}

export interface BaseCaseFragment_requisitions_case_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
}

export interface BaseCaseFragment_requisitions_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
  /**
   * Id of damage category
   */
  id: string;
}

export interface BaseCaseFragment_requisitions_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of damage cause
   */
  id: string;
}

export interface BaseCaseFragment_requisitions_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseCaseFragment_requisitions_case_damage_contact {
  /**
   * Contact address information
   */
  address: BaseCaseFragment_requisitions_case_damage_contact_address;
}

export interface BaseCaseFragment_requisitions_case_damage {
  /**
   * Damage category
   */
  category: BaseCaseFragment_requisitions_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: BaseCaseFragment_requisitions_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: BaseCaseFragment_requisitions_case_damage_contact;
}

export interface BaseCaseFragment_requisitions_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: BaseCaseFragment_requisitions_case_debitor;
  /**
   * Case damage details
   */
  damage: BaseCaseFragment_requisitions_case_damage;
}

export interface BaseCaseFragment_requisitions_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
}

export interface BaseCaseFragment_requisitions_drivingSlip_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
}

export interface BaseCaseFragment_requisitions_drivingSlip_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
}

export interface BaseCaseFragment_requisitions_drivingSlip_case_damage {
  /**
   * Damage category
   */
  category: BaseCaseFragment_requisitions_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: BaseCaseFragment_requisitions_drivingSlip_case_damage_cause;
}

export interface BaseCaseFragment_requisitions_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: BaseCaseFragment_requisitions_drivingSlip_case_damage;
}

export interface BaseCaseFragment_requisitions_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Case which driving slip is related to
   */
  case: BaseCaseFragment_requisitions_drivingSlip_case;
}

export interface BaseCaseFragment_requisitions_vendor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseCaseFragment_requisitions_vendor {
  /**
   * ERP reference no for vendor
   */
  erpReferenceNo: string;
  /**
   * The name of the vendor
   */
  company: string | null;
  /**
   * The address of the vendor
   */
  address: BaseCaseFragment_requisitions_vendor_address | null;
  /**
   * The email of the vendor
   */
  email: string | null;
  /**
   * The phone number of the vendor
   */
  phone: string | null;
  /**
   * The posting group of the vendor
   */
  vendorPostingGroup: string;
  /**
   * Attached SSG department of the vendor
   */
  ssgDepartment: string;
  /**
   * Attached SSG location of the vendor
   */
  ssgLocation: string;
  /**
   * Vendor type
   */
  type: string;
}

export interface BaseCaseFragment_requisitions_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface BaseCaseFragment_requisitions {
  /**
   * id of the requisition
   */
  id: string;
  /**
   * case owning the requisition
   */
  case: BaseCaseFragment_requisitions_case | null;
  /**
   * location owning the requisition
   */
  department: BaseCaseFragment_requisitions_department | null;
  /**
   * driving slip owning the requisition
   */
  drivingSlip: BaseCaseFragment_requisitions_drivingSlip | null;
  /**
   * description of the requisition
   */
  description: string;
  /**
   * orderNumber of the requisition
   */
  orderNumber: string;
  /**
   * the type of the requisition
   */
  type: RequisitionType;
  /**
   * Requisition status
   */
  status: boolean;
  /**
   * the vendor of the requisition
   */
  vendor: BaseCaseFragment_requisitions_vendor | null;
  /**
   * Timestamp of when the requisition was created
   */
  createdAt: GQL_DateTime;
  /**
   * User who created the requisition
   */
  createdBy: BaseCaseFragment_requisitions_createdBy;
  /**
   * email of the vendor
   */
  vendorEmail: string | null;
  /**
   * phone number of the vendor
   */
  vendorPhoneNumber: string;
  /**
   * name of the vendor
   */
  vendorName: string;
}

export interface BaseCaseFragment {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * ReportSent
   */
  reportSent: boolean;
  /**
   * Distance from ssg location to damage address
   */
  distance: string | null;
  /**
   * Whether notifications regarding machines on the case should be sent
   */
  machineNotification: boolean | null;
  /**
   * Whether time registration is allowed on the case
   */
  timeRegistration: boolean | null;
  /**
   * Case debitor details
   */
  debitor: BaseCaseFragment_debitor;
  /**
   * Case damage details
   */
  damage: BaseCaseFragment_damage;
  /**
   * Case policy holder details
   */
  policyHolder: BaseCaseFragment_policyHolder;
  /**
   * Case visitation details
   */
  visitation: BaseCaseFragment_visitation;
  /**
   * Case requisitioner details
   */
  requisitioner: BaseCaseFragment_requisitioner;
  /**
   * Case advisor details
   */
  adviser: BaseCaseFragment_adviser | null;
  /**
   * Case referer details
   */
  referrer: BaseCaseFragment_referrer | null;
  /**
   * Project manager attached to case
   */
  projectManager: BaseCaseFragment_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: BaseCaseFragment_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: BaseCaseFragment_ssgLocation;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: BaseCaseFragment_ssgDepartment;
  /**
   * Case ready for billing
   */
  billingReady: boolean;
  /**
   * Case work complete
   */
  workComplete: boolean;
  /**
   * List of case agreements
   */
  caseAgreements: BaseCaseFragment_caseAgreements[];
  /**
   * List of case emails
   */
  caseEmails: BaseCaseFragment_caseEmails[];
  /**
   * List of user favorite entries
   */
  userFavorite: BaseCaseFragment_userFavorite | null;
  /**
   * User who created the case
   */
  createdBy: BaseCaseFragment_createdBy | null;
  /**
   * Timestamp of when the case was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the case was created
   */
  createdAt: GQL_DateTime;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * The tasks associated with the task
   */
  jobs: BaseCaseFragment_jobs[] | null;
  /**
   * The driving slips series associated with the case
   */
  drivingSlipSeries: BaseCaseFragment_drivingSlipSeries[];
  /**
   * Important info on the case
   */
  importantInfo: string | null;
  /**
   * Alternative contact
   */
  alternativeContact: BaseCaseFragment_alternativeContact | null;
  /**
   * Risk evaluation answers
   */
  riskEvaluationAnswers: BaseCaseFragment_riskEvaluationAnswers[] | null;
  /**
   * Optional comment for risk evaluation
   */
  riskEvaluationComment: string | null;
  /**
   * Requisition of the case
   */
  requisitions: BaseCaseFragment_requisitions[];
  scalePointStatus: ScalePointStatus;
  scalePointErrorMessage: string | null;
  arrived: boolean;
  arrivedDate: GQL_DateTime | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseCaseNoDrivingSlipsFragment
// ====================================================

export interface BaseCaseNoDrivingSlipsFragment_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseCaseNoDrivingSlipsFragment_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseCaseNoDrivingSlipsFragment_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: BaseCaseNoDrivingSlipsFragment_debitor_shippingAddress_address;
}

export interface BaseCaseNoDrivingSlipsFragment_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseCaseNoDrivingSlipsFragment_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: BaseCaseNoDrivingSlipsFragment_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: BaseCaseNoDrivingSlipsFragment_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: BaseCaseNoDrivingSlipsFragment_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface BaseCaseNoDrivingSlipsFragment_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface BaseCaseNoDrivingSlipsFragment_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface BaseCaseNoDrivingSlipsFragment_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface BaseCaseNoDrivingSlipsFragment_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface BaseCaseNoDrivingSlipsFragment_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface BaseCaseNoDrivingSlipsFragment_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: BaseCaseNoDrivingSlipsFragment_damage_cause_departments_location;
}

export interface BaseCaseNoDrivingSlipsFragment_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: BaseCaseNoDrivingSlipsFragment_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: BaseCaseNoDrivingSlipsFragment_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (BaseCaseNoDrivingSlipsFragment_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface BaseCaseNoDrivingSlipsFragment_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseCaseNoDrivingSlipsFragment_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: BaseCaseNoDrivingSlipsFragment_damage_contact_address;
}

export interface BaseCaseNoDrivingSlipsFragment_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface BaseCaseNoDrivingSlipsFragment_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: BaseCaseNoDrivingSlipsFragment_damage_businessArea;
  /**
   * Damage category
   */
  category: BaseCaseNoDrivingSlipsFragment_damage_category;
  /**
   * Cause behind damage
   */
  cause: BaseCaseNoDrivingSlipsFragment_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: BaseCaseNoDrivingSlipsFragment_damage_contact;
  /**
   * contacts for damage
   */
  contacts: BaseCaseNoDrivingSlipsFragment_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface BaseCaseNoDrivingSlipsFragment_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface BaseCaseNoDrivingSlipsFragment_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: BaseCaseNoDrivingSlipsFragment_visitation_calledBack;
}

export interface BaseCaseNoDrivingSlipsFragment_requisitioner {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Requisitioner relation
   */
  relation: string;
}

export interface BaseCaseNoDrivingSlipsFragment_adviser {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface BaseCaseNoDrivingSlipsFragment_referrer {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface BaseCaseNoDrivingSlipsFragment_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseCaseNoDrivingSlipsFragment_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseCaseNoDrivingSlipsFragment_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseCaseNoDrivingSlipsFragment_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: BaseCaseNoDrivingSlipsFragment_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface BaseCaseNoDrivingSlipsFragment_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseCaseNoDrivingSlipsFragment_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: BaseCaseNoDrivingSlipsFragment_ssgDepartment_location_address;
}

export interface BaseCaseNoDrivingSlipsFragment_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: BaseCaseNoDrivingSlipsFragment_ssgDepartment_location;
}

export interface BaseCaseNoDrivingSlipsFragment_caseAgreements_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseCaseNoDrivingSlipsFragment_caseAgreements {
  /**
   * Agreement content
   */
  content: string;
  /**
   * Agreement category
   */
  category: CaseAgreementCategory;
  /**
   * User who created agreement
   */
  user: BaseCaseNoDrivingSlipsFragment_caseAgreements_user;
  /**
   * Timestamp of when agreement was created
   */
  timestamp: GQL_DateTime;
  /**
   * Whether case agreement should be sent to ScalePoint
   */
  shouldSaveInScalePoint: boolean;
}

export interface BaseCaseNoDrivingSlipsFragment_caseEmails {
  /**
   * Email content
   */
  content: string;
  /**
   * List of email attachments
   */
  attachments: string[];
  /**
   * List of email receivers
   */
  receivers: string[];
  /**
   * Timestamp of when email was created
   */
  timestamp: GQL_DateTime;
}

export interface BaseCaseNoDrivingSlipsFragment_caseMachineUsages {
  /**
   * Machine identifier
   */
  machine: string;
  /**
   * Start time
   */
  startTime: GQL_DateTime;
  /**
   * End time
   */
  endTime: GQL_DateTime | null;
  /**
   * Meter reading at start time
   */
  startMeterReading: GQL_Decimal;
  /**
   * Meter reading at end time
   */
  endMeterReading: GQL_Decimal | null;
}

export interface BaseCaseNoDrivingSlipsFragment_userFavorite {
  /**
   * Unique user id
   */
  user: string;
  /**
   * Color code set by user
   */
  color: string | null;
  /**
   * Favorite active status
   */
  active: boolean;
}

export interface BaseCaseNoDrivingSlipsFragment_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseCaseNoDrivingSlipsFragment_jobs_assignedTo {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
}

export interface BaseCaseNoDrivingSlipsFragment_jobs_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface BaseCaseNoDrivingSlipsFragment_jobs_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
}

export interface BaseCaseNoDrivingSlipsFragment_jobs_movable_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface BaseCaseNoDrivingSlipsFragment_jobs_movable {
  /**
   * Unique id of movable
   */
  id: string;
  /**
   * Description of movable
   */
  description: string;
  /**
   * Movable status
   */
  status: string;
  /**
   * Movable placement
   */
  placement: BaseCaseNoDrivingSlipsFragment_jobs_movable_placement;
}

export interface BaseCaseNoDrivingSlipsFragment_jobs {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Type of case task
   */
  type: JobType;
  /**
   * Description of the case task
   */
  description: string;
  /**
   * Deadline of the case task
   */
  deadline: GQL_DateTime;
  /**
   * The user responsible for the task
   */
  assignedTo: BaseCaseNoDrivingSlipsFragment_jobs_assignedTo | null;
  /**
   * The location responsible to the task
   */
  location: BaseCaseNoDrivingSlipsFragment_jobs_location | null;
  /**
   * The case the task belongs to
   */
  case: BaseCaseNoDrivingSlipsFragment_jobs_case | null;
  /**
   * The movable responsible to the task
   */
  movable: BaseCaseNoDrivingSlipsFragment_jobs_movable | null;
  /**
   * Whether the task is completed
   */
  completed: boolean;
}

export interface BaseCaseNoDrivingSlipsFragment_policyHolder {
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
}

export interface BaseCaseNoDrivingSlipsFragment_alternativeContact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Comment on person
   */
  comment: string | null;
}

export interface BaseCaseNoDrivingSlipsFragment {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * Case debitor details
   */
  debitor: BaseCaseNoDrivingSlipsFragment_debitor;
  /**
   * Case damage details
   */
  damage: BaseCaseNoDrivingSlipsFragment_damage;
  /**
   * Case visitation details
   */
  visitation: BaseCaseNoDrivingSlipsFragment_visitation;
  /**
   * Case requisitioner details
   */
  requisitioner: BaseCaseNoDrivingSlipsFragment_requisitioner;
  /**
   * Case advisor details
   */
  adviser: BaseCaseNoDrivingSlipsFragment_adviser | null;
  /**
   * Case referer details
   */
  referrer: BaseCaseNoDrivingSlipsFragment_referrer | null;
  /**
   * Project manager attached to case
   */
  projectManager: BaseCaseNoDrivingSlipsFragment_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: BaseCaseNoDrivingSlipsFragment_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: BaseCaseNoDrivingSlipsFragment_ssgLocation;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: BaseCaseNoDrivingSlipsFragment_ssgDepartment;
  /**
   * Case ready for billing
   */
  billingReady: boolean;
  /**
   * Case work complete
   */
  workComplete: boolean;
  /**
   * List of case agreements
   */
  caseAgreements: BaseCaseNoDrivingSlipsFragment_caseAgreements[];
  /**
   * List of case emails
   */
  caseEmails: BaseCaseNoDrivingSlipsFragment_caseEmails[];
  /**
   * List of case machine usages
   */
  caseMachineUsages: BaseCaseNoDrivingSlipsFragment_caseMachineUsages[];
  /**
   * List of user favorite entries
   */
  userFavorite: BaseCaseNoDrivingSlipsFragment_userFavorite | null;
  /**
   * User who created the case
   */
  createdBy: BaseCaseNoDrivingSlipsFragment_createdBy | null;
  /**
   * Timestamp of when the case was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the case was created
   */
  createdAt: GQL_DateTime;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * The tasks associated with the task
   */
  jobs: BaseCaseNoDrivingSlipsFragment_jobs[] | null;
  /**
   * Case policy holder details
   */
  policyHolder: BaseCaseNoDrivingSlipsFragment_policyHolder;
  /**
   * Important info on the case
   */
  importantInfo: string | null;
  /**
   * Alternative contact
   */
  alternativeContact: BaseCaseNoDrivingSlipsFragment_alternativeContact | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseCatalogAddressFragment
// ====================================================

export interface BaseCatalogAddressFragment {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseCatalogContactFragment
// ====================================================

export interface BaseCatalogContactFragment_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface BaseCatalogContactFragment_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface BaseCatalogContactFragment {
  /**
   * Id for catalog contact
   */
  id: string;
  /**
   * Customer
   */
  customer: BaseCatalogContactFragment_customer;
  /**
   * Contact changed by
   */
  changedBy: string;
  /**
   * Contact name
   */
  contactName: string;
  /**
   * Contact type
   */
  type: string | null;
  /**
   * Contact collection type
   */
  contactType: CatalogContacts;
  /**
   * Contact email
   */
  email: string | null;
  /**
   * Informations for Contacts
   */
  informations: (BaseCatalogContactFragment_informations | null)[];
  /**
   * Is contact global
   */
  global: boolean;
  /**
   * Global type for contact
   */
  globalType: CatalogGlobalType;
  /**
   * Is contact active
   */
  active: boolean;
  /**
   * Catalog ids
   */
  catalogIds: string[];
  /**
   * Version for catalog contact
   */
  version: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseCatalogCraftsmanFragment
// ====================================================

export interface BaseCatalogCraftsmanFragment_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface BaseCatalogCraftsmanFragment_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface BaseCatalogCraftsmanFragment {
  /**
   * Id for catalog call contact
   */
  id: string;
  /**
   * Customer
   */
  customer: BaseCatalogCraftsmanFragment_customer;
  /**
   * Craftsman changed by
   */
  changedBy: string;
  /**
   * Craftsman type
   */
  type: string;
  /**
   * Craftsman name
   */
  contactName: string;
  /**
   * Informations for Craftsman
   */
  informations: BaseCatalogCraftsmanFragment_informations[];
  /**
   * Craftsman email
   */
  email: string | null;
  /**
   * Craftsman priority
   */
  priority: string;
  /**
   * Is contact global
   */
  global: boolean;
  /**
   * Global type for craftsman
   */
  globalType: CatalogGlobalType;
  /**
   * Is contact active
   */
  active: boolean;
  /**
   * Version for catalog craftsman
   */
  version: number;
  /**
   * Catalog ids
   */
  catalogIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseCatalogCustomerFragment
// ====================================================

export interface BaseCatalogCustomerFragment_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface BaseCatalogCustomerFragment_users {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
}

export interface BaseCatalogCustomerFragment {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
  /**
   * AD user
   */
  user: BaseCatalogCustomerFragment_user | null;
  /**
   * AD users connected to the customer
   */
  users: BaseCatalogCustomerFragment_users[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseCatalogFragment
// ====================================================

export interface BaseCatalogFragment_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface BaseCatalogFragment_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface BaseCatalogFragment_debitor {
  /**
   * Company identifier
   */
  company: string;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Sales Perseon Code
   */
  salesPersonCode: string;
  /**
   * Sales Perseon Name
   */
  salesPersonName: string;
}

export interface BaseCatalogFragment {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Contact number for the Catalog
   */
  contactNumber: string | null;
  /**
   * Hours contact number can be reached
   */
  contactHours: string | null;
  /**
   * Customer
   */
  customer: BaseCatalogFragment_customer;
  /**
   * Catalog changed by
   */
  changedBy: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: BaseCatalogFragment_addresses[];
  /**
   * Is sms service used for Catalog
   */
  smsService: boolean;
  /**
   * Catalog debitor details
   */
  debitor: BaseCatalogFragment_debitor | null;
  /**
   * Is catalog verified
   */
  verified: boolean;
  /**
   * Is catalog debitor verified
   */
  debitorVerified: boolean;
  /**
   * Version for catalog
   */
  version: number;
  /**
   * Timestamp of when the catalog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the catalog was created
   */
  createdAt: GQL_DateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseCatalogFragmentChanges
// ====================================================

export interface BaseCatalogFragmentChanges_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface BaseCatalogFragmentChanges_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface BaseCatalogFragmentChanges_debitor {
  /**
   * Company identifier
   */
  company: string;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
}

export interface BaseCatalogFragmentChanges_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseCatalogFragmentChanges_changes_before_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface BaseCatalogFragmentChanges_changes_before_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface BaseCatalogFragmentChanges_changes_before_contacts_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface BaseCatalogFragmentChanges_changes_before_contacts_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface BaseCatalogFragmentChanges_changes_before_contacts_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface BaseCatalogFragmentChanges_changes_before_contacts_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: BaseCatalogFragmentChanges_changes_before_contacts_catalogs_addresses[];
}

export interface BaseCatalogFragmentChanges_changes_before_contacts {
  customer: BaseCatalogFragmentChanges_changes_before_contacts_customer | null;
  changedBy: string | null;
  /**
   * Contact name
   */
  contactName: string | null;
  type: string | null;
  contactType: CatalogContacts | null;
  /**
   * Contact email
   */
  email: string | null;
  /**
   * Informations for Contacts
   */
  informations: BaseCatalogFragmentChanges_changes_before_contacts_informations[] | null;
  /**
   * Is contact global
   */
  global: boolean | null;
  /**
   * Global type for contact
   */
  globalType: CatalogGlobalType | null;
  /**
   * Is contact active
   */
  active: boolean | null;
  catalogs: BaseCatalogFragmentChanges_changes_before_contacts_catalogs[] | null;
}

export interface BaseCatalogFragmentChanges_changes_before_craftsmen_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface BaseCatalogFragmentChanges_changes_before_craftsmen_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface BaseCatalogFragmentChanges_changes_before_craftsmen_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface BaseCatalogFragmentChanges_changes_before_craftsmen_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: BaseCatalogFragmentChanges_changes_before_craftsmen_catalogs_addresses[];
}

export interface BaseCatalogFragmentChanges_changes_before_craftsmen {
  customer: BaseCatalogFragmentChanges_changes_before_craftsmen_customer | null;
  changedBy: string | null;
  /**
   * Type for call contact
   */
  type: string | null;
  /**
   * Call contact name
   */
  contactName: string | null;
  /**
   * Informations for Craftsman
   */
  informations: BaseCatalogFragmentChanges_changes_before_craftsmen_informations[] | null;
  /**
   * Call contact email
   */
  email: string | null;
  /**
   * Priority for call contact
   */
  priority: string | null;
  /**
   * Is call contact global
   */
  global: boolean | null;
  /**
   * Global type for craftsman
   */
  globalType: CatalogGlobalType | null;
  /**
   * Is call contact active
   */
  active: boolean | null;
  catalogs: BaseCatalogFragmentChanges_changes_before_craftsmen_catalogs[] | null;
}

export interface BaseCatalogFragmentChanges_changes_before_timedMessages_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface BaseCatalogFragmentChanges_changes_before_timedMessages_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface BaseCatalogFragmentChanges_changes_before_timedMessages_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: BaseCatalogFragmentChanges_changes_before_timedMessages_catalogs_addresses[];
}

export interface BaseCatalogFragmentChanges_changes_before_timedMessages {
  customer: BaseCatalogFragmentChanges_changes_before_timedMessages_customer | null;
  changedBy: string | null;
  startTime: GQL_DateTime | null;
  endTime: GQL_DateTime | null;
  message: string | null;
  global: boolean | null;
  globalType: CatalogGlobalType | null;
  active: boolean | null;
  catalogs: BaseCatalogFragmentChanges_changes_before_timedMessages_catalogs[] | null;
}

export interface BaseCatalogFragmentChanges_changes_before_debitor {
  /**
   * Company identifier
   */
  company: string;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
}

export interface BaseCatalogFragmentChanges_changes_before {
  ssgAccountable: string | null;
  propertyNumber: string | null;
  customer: BaseCatalogFragmentChanges_changes_before_customer | null;
  changedBy: string | null;
  description: string | null;
  contactNumber: string | null;
  contactHours: string | null;
  addresses: BaseCatalogFragmentChanges_changes_before_addresses[] | null;
  contacts: BaseCatalogFragmentChanges_changes_before_contacts | null;
  craftsmen: BaseCatalogFragmentChanges_changes_before_craftsmen | null;
  timedMessages: BaseCatalogFragmentChanges_changes_before_timedMessages | null;
  filenames: string | null;
  smsService: boolean | null;
  /**
   * Is catalog verified
   */
  verified: boolean;
  /**
   * Is catalog debitor verified
   */
  debitorVerified: boolean;
  /**
   * Catalog debitor details
   */
  debitor: BaseCatalogFragmentChanges_changes_before_debitor | null;
}

export interface BaseCatalogFragmentChanges_changes_after_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface BaseCatalogFragmentChanges_changes_after_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface BaseCatalogFragmentChanges_changes_after_contacts_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface BaseCatalogFragmentChanges_changes_after_contacts_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface BaseCatalogFragmentChanges_changes_after_contacts_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface BaseCatalogFragmentChanges_changes_after_contacts_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: BaseCatalogFragmentChanges_changes_after_contacts_catalogs_addresses[];
}

export interface BaseCatalogFragmentChanges_changes_after_contacts {
  customer: BaseCatalogFragmentChanges_changes_after_contacts_customer | null;
  changedBy: string | null;
  /**
   * Contact name
   */
  contactName: string | null;
  type: string | null;
  contactType: CatalogContacts | null;
  /**
   * Contact email
   */
  email: string | null;
  /**
   * Informations for Contacts
   */
  informations: BaseCatalogFragmentChanges_changes_after_contacts_informations[] | null;
  /**
   * Is contact global
   */
  global: boolean | null;
  /**
   * Global type for contact
   */
  globalType: CatalogGlobalType | null;
  /**
   * Is contact active
   */
  active: boolean | null;
  catalogs: BaseCatalogFragmentChanges_changes_after_contacts_catalogs[] | null;
}

export interface BaseCatalogFragmentChanges_changes_after_craftsmen_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface BaseCatalogFragmentChanges_changes_after_craftsmen_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface BaseCatalogFragmentChanges_changes_after_craftsmen_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface BaseCatalogFragmentChanges_changes_after_craftsmen_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: BaseCatalogFragmentChanges_changes_after_craftsmen_catalogs_addresses[];
}

export interface BaseCatalogFragmentChanges_changes_after_craftsmen {
  customer: BaseCatalogFragmentChanges_changes_after_craftsmen_customer | null;
  changedBy: string | null;
  /**
   * Type for call contact
   */
  type: string | null;
  /**
   * Call contact name
   */
  contactName: string | null;
  /**
   * Informations for Craftsman
   */
  informations: BaseCatalogFragmentChanges_changes_after_craftsmen_informations[] | null;
  /**
   * Call contact email
   */
  email: string | null;
  /**
   * Priority for call contact
   */
  priority: string | null;
  /**
   * Is call contact global
   */
  global: boolean | null;
  /**
   * Global type for craftsman
   */
  globalType: CatalogGlobalType | null;
  /**
   * Is call contact active
   */
  active: boolean | null;
  catalogs: BaseCatalogFragmentChanges_changes_after_craftsmen_catalogs[] | null;
}

export interface BaseCatalogFragmentChanges_changes_after_timedMessages_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface BaseCatalogFragmentChanges_changes_after_timedMessages_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface BaseCatalogFragmentChanges_changes_after_timedMessages_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: BaseCatalogFragmentChanges_changes_after_timedMessages_catalogs_addresses[];
}

export interface BaseCatalogFragmentChanges_changes_after_timedMessages {
  customer: BaseCatalogFragmentChanges_changes_after_timedMessages_customer | null;
  changedBy: string | null;
  startTime: GQL_DateTime | null;
  endTime: GQL_DateTime | null;
  message: string | null;
  global: boolean | null;
  globalType: CatalogGlobalType | null;
  active: boolean | null;
  catalogs: BaseCatalogFragmentChanges_changes_after_timedMessages_catalogs[] | null;
}

export interface BaseCatalogFragmentChanges_changes_after_debitor {
  /**
   * Company identifier
   */
  company: string;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
}

export interface BaseCatalogFragmentChanges_changes_after {
  ssgAccountable: string | null;
  propertyNumber: string | null;
  customer: BaseCatalogFragmentChanges_changes_after_customer | null;
  changedBy: string | null;
  description: string | null;
  contactNumber: string | null;
  contactHours: string | null;
  addresses: BaseCatalogFragmentChanges_changes_after_addresses[] | null;
  contacts: BaseCatalogFragmentChanges_changes_after_contacts | null;
  craftsmen: BaseCatalogFragmentChanges_changes_after_craftsmen | null;
  timedMessages: BaseCatalogFragmentChanges_changes_after_timedMessages | null;
  filenames: string | null;
  smsService: boolean | null;
  /**
   * Is catalog verified
   */
  verified: boolean;
  /**
   * Is catalog debitor verified
   */
  debitorVerified: boolean;
  /**
   * Catalog debitor details
   */
  debitor: BaseCatalogFragmentChanges_changes_after_debitor | null;
}

export interface BaseCatalogFragmentChanges_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: BaseCatalogFragmentChanges_changes_user;
  /**
   * Changed by
   */
  changedBy: string | null;
  /**
   * Value before
   */
  before: BaseCatalogFragmentChanges_changes_before;
  /**
   * Value after
   */
  after: BaseCatalogFragmentChanges_changes_after;
}

export interface BaseCatalogFragmentChanges {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Customer
   */
  customer: BaseCatalogFragmentChanges_customer;
  /**
   * Catalog changed by
   */
  changedBy: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: BaseCatalogFragmentChanges_addresses[];
  /**
   * SGGAccountable for catalog
   */
  ssgAccountable: string | null;
  /**
   * Description for catalog
   */
  description: string | null;
  /**
   * Contact number for the Catalog
   */
  contactNumber: string | null;
  /**
   * Hours contact number can be reached
   */
  contactHours: string | null;
  /**
   * Is sms service used for Catalog
   */
  smsService: boolean;
  /**
   * Catalog debitor details
   */
  debitor: BaseCatalogFragmentChanges_debitor | null;
  /**
   * Is catalog verified
   */
  verified: boolean;
  /**
   * Is catalog debitor verified
   */
  debitorVerified: boolean;
  /**
   * Version for catalog
   */
  version: number;
  /**
   * Timestamp of when the catalog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the catalog was created
   */
  createdAt: GQL_DateTime;
  /**
   * Catalog Change log
   */
  changes: BaseCatalogFragmentChanges_changes[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseCollectionItemFragment
// ====================================================

export interface BaseCollectionItemFragment {
  /**
   * Id of collection item
   */
  id: string;
  /**
   * Name of collection item
   */
  name: string;
  /**
   * Type of collection item
   */
  type: CollectionItemType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseDamageCategoryFragment
// ====================================================

export interface BaseDamageCategoryFragment {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseDamageCauseFragment
// ====================================================

export interface BaseDamageCauseFragment_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface BaseDamageCauseFragment_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface BaseDamageCauseFragment_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface BaseDamageCauseFragment_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: BaseDamageCauseFragment_departments_location;
}

export interface BaseDamageCauseFragment {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: BaseDamageCauseFragment_businessArea;
  /**
   * Id of associated category
   */
  category: BaseDamageCauseFragment_category;
  /**
   * Departments associated to damage cause
   */
  departments: (BaseDamageCauseFragment_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseDebitorFragment
// ====================================================

export interface BaseDebitorFragment_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseDebitorFragment_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseDebitorFragment_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: BaseDebitorFragment_shippingAddress_address;
}

export interface BaseDebitorFragment_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseDebitorFragment {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: BaseDebitorFragment_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: BaseDebitorFragment_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: BaseDebitorFragment_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseDepartmentFragment
// ====================================================

export interface BaseDepartmentFragment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseDepartmentFragment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: BaseDepartmentFragment_location_address;
}

export interface BaseDepartmentFragment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: BaseDepartmentFragment_location;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseDrivingSlipCaseFragment
// ====================================================

export interface BaseDrivingSlipCaseFragment_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseDrivingSlipCaseFragment_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseDrivingSlipCaseFragment_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: BaseDrivingSlipCaseFragment_debitor_shippingAddress_address;
}

export interface BaseDrivingSlipCaseFragment_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseDrivingSlipCaseFragment_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: BaseDrivingSlipCaseFragment_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: BaseDrivingSlipCaseFragment_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: BaseDrivingSlipCaseFragment_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface BaseDrivingSlipCaseFragment_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseDrivingSlipCaseFragment_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: BaseDrivingSlipCaseFragment_damage_contact_address;
}

export interface BaseDrivingSlipCaseFragment_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface BaseDrivingSlipCaseFragment_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface BaseDrivingSlipCaseFragment_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface BaseDrivingSlipCaseFragment_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface BaseDrivingSlipCaseFragment_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: BaseDrivingSlipCaseFragment_damage_cause_departments_location;
}

export interface BaseDrivingSlipCaseFragment_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: BaseDrivingSlipCaseFragment_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: BaseDrivingSlipCaseFragment_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (BaseDrivingSlipCaseFragment_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface BaseDrivingSlipCaseFragment_damage {
  /**
   * Damage location contact information
   */
  contact: BaseDrivingSlipCaseFragment_damage_contact;
  /**
   * Damage category
   */
  category: BaseDrivingSlipCaseFragment_damage_category;
  /**
   * Cause behind damage
   */
  cause: BaseDrivingSlipCaseFragment_damage_cause;
}

export interface BaseDrivingSlipCaseFragment_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseDrivingSlipCaseFragment_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: BaseDrivingSlipCaseFragment_policyHolder_address;
}

export interface BaseDrivingSlipCaseFragment_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseDrivingSlipCaseFragment_caseManager {
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface BaseDrivingSlipCaseFragment_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseDrivingSlipCaseFragment_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: BaseDrivingSlipCaseFragment_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface BaseDrivingSlipCaseFragment_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseDrivingSlipCaseFragment_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: BaseDrivingSlipCaseFragment_ssgDepartment_location_address;
}

export interface BaseDrivingSlipCaseFragment_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: BaseDrivingSlipCaseFragment_ssgDepartment_location;
}

export interface BaseDrivingSlipCaseFragment_riskEvaluationAnswers_question {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question
   */
  question: string;
}

export interface BaseDrivingSlipCaseFragment_riskEvaluationAnswers {
  /**
   * Id of question answer is linked to
   */
  question: BaseDrivingSlipCaseFragment_riskEvaluationAnswers_question;
  /**
   * Answer given
   */
  answer: boolean;
}

export interface BaseDrivingSlipCaseFragment_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface BaseDrivingSlipCaseFragment_visitation_awaiting {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface BaseDrivingSlipCaseFragment_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
  /**
   * The question
   */
  title: string;
}

export interface BaseDrivingSlipCaseFragment_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: BaseDrivingSlipCaseFragment_visitation_calledBack;
  /**
   * If case is awaiting
   */
  awaiting: BaseDrivingSlipCaseFragment_visitation_awaiting;
  /**
   * A set of answers related to the damage cause
   */
  answers: BaseDrivingSlipCaseFragment_visitation_answers[] | null;
}

export interface BaseDrivingSlipCaseFragment {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * Case debitor details
   */
  debitor: BaseDrivingSlipCaseFragment_debitor;
  /**
   * Case damage details
   */
  damage: BaseDrivingSlipCaseFragment_damage;
  /**
   * Case policy holder details
   */
  policyHolder: BaseDrivingSlipCaseFragment_policyHolder;
  /**
   * Project manager attached to case
   */
  projectManager: BaseDrivingSlipCaseFragment_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: BaseDrivingSlipCaseFragment_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: BaseDrivingSlipCaseFragment_ssgLocation;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: BaseDrivingSlipCaseFragment_ssgDepartment;
  /**
   * Risk evaluation answers
   */
  riskEvaluationAnswers: BaseDrivingSlipCaseFragment_riskEvaluationAnswers[] | null;
  /**
   * Optional comment for risk evaluation
   */
  riskEvaluationComment: string | null;
  /**
   * Case visitation details
   */
  visitation: BaseDrivingSlipCaseFragment_visitation;
  /**
   * Whether time registration is allowed on the case
   */
  timeRegistration: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseDrivingSlipFragment
// ====================================================

export interface BaseDrivingSlipFragment_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface BaseDrivingSlipFragment_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface BaseDrivingSlipFragment_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseDrivingSlipFragment_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface BaseDrivingSlipFragment_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: BaseDrivingSlipFragment_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface BaseDrivingSlipFragment_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: BaseDrivingSlipFragment_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface BaseDrivingSlipFragment_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: BaseDrivingSlipFragment_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface BaseDrivingSlipFragment_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseDrivingSlipFragment_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseDrivingSlipFragment_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface BaseDrivingSlipFragment_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface BaseDrivingSlipFragment_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: BaseDrivingSlipFragment_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: BaseDrivingSlipFragment_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: BaseDrivingSlipFragment_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface BaseDrivingSlipFragment_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseDrivingSlipFragment_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface BaseDrivingSlipFragment_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface BaseDrivingSlipFragment_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: BaseDrivingSlipFragment_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: BaseDrivingSlipFragment_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: BaseDrivingSlipFragment_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface BaseDrivingSlipFragment_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: BaseDrivingSlipFragment_changes_user;
  /**
   * Value before
   */
  before: BaseDrivingSlipFragment_changes_before;
  /**
   * Value after
   */
  after: BaseDrivingSlipFragment_changes_after;
}

export interface BaseDrivingSlipFragment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface BaseDrivingSlipFragment_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface BaseDrivingSlipFragment {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: BaseDrivingSlipFragment_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: BaseDrivingSlipFragment_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: BaseDrivingSlipFragment_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: BaseDrivingSlipFragment_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: BaseDrivingSlipFragment_changes[];
  /**
   * Location of driving slip
   */
  location: BaseDrivingSlipFragment_location;
  /**
   * Department of driving slip
   */
  department: BaseDrivingSlipFragment_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseDrivingSlipQuestionnaireFragment
// ====================================================

export interface BaseDrivingSlipQuestionnaireFragment_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface BaseDrivingSlipQuestionnaireFragment_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: BaseDrivingSlipQuestionnaireFragment_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface BaseDrivingSlipQuestionnaireFragment_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: BaseDrivingSlipQuestionnaireFragment_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface BaseDrivingSlipQuestionnaireFragment {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: BaseDrivingSlipQuestionnaireFragment_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseDrivingSlipQuestionnaireTemplateFragment
// ====================================================

export interface BaseDrivingSlipQuestionnaireTemplateFragment_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface BaseDrivingSlipQuestionnaireTemplateFragment_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: BaseDrivingSlipQuestionnaireTemplateFragment_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface BaseDrivingSlipQuestionnaireTemplateFragment {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: BaseDrivingSlipQuestionnaireTemplateFragment_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseGDPRFragment
// ====================================================

export interface BaseGDPRFragment_userId {
  /**
   * Unique id of user
   */
  id: string;
}

export interface BaseGDPRFragment {
  /**
   * Unique id of gdpr accept
   */
  id: string;
  /**
   * Timestamp of acceptance
   */
  timestamp: GQL_DateTime;
  /**
   * User who accepted GDPR
   */
  userId: BaseGDPRFragment_userId;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseGroupPermissionFragment
// ====================================================

export interface BaseGroupPermissionFragment_groupDebitors {
  /**
   * List item value
   */
  value: string;
  /**
   * List item label
   */
  label: string;
}

export interface BaseGroupPermissionFragment {
  /**
   * Unique id of group permission
   */
  id: string;
  /**
   * Unique name of AD permission group
   */
  name: string;
  /**
   * Unique id of AD permission group
   */
  groupPermissionId: string;
  /**
   * Group permissions
   */
  groupPermissions: Permissions[];
  /**
   * AD permission group is New
   */
  isNew: boolean;
  /**
   * Limited debitors this group can pick from
   */
  groupDebitors: BaseGroupPermissionFragment_groupDebitors[];
  /**
   * Limited debitor groups this group can pick from
   */
  groupDebitorGroups: string[];
  /**
   * Limited locations this group can pick from
   */
  groupLocations: string[];
  /**
   * Limited departments this group can pick from
   */
  groupDepartments: string[];
  /**
   * Limited damage categories this group can pick from
   */
  groupDamageCategories: string[];
  /**
   * Limited damage causes this group can pick from
   */
  groupDamageCauses: string[];
  /**
   * Limeted departments this group can make requisitions for
   */
  groupRequisitionDepartments: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseInspectionReportFormDataFragment
// ====================================================

export interface BaseInspectionReportFormDataFragment_facadeImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface BaseInspectionReportFormDataFragment_floorplanImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface BaseInspectionReportFormDataFragment_otherImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface BaseInspectionReportFormDataFragment {
  /**
   * Famage image info
   */
  facadeImage: BaseInspectionReportFormDataFragment_facadeImage | null;
  /**
   * Information about what has happened until now
   */
  backgroundStory: string | null;
  /**
   * Observations about the site
   */
  observations: string | null;
  /**
   * Assessment and conclusion
   */
  assessmentAndConclusion: string | null;
  /**
   * Suggested action plan
   */
  suggestedActionPlan: string | null;
  /**
   * Any other comments from inspection
   */
  comments: string | null;
  /**
   * Floorplan image info
   */
  floorplanImage: BaseInspectionReportFormDataFragment_floorplanImage | null;
  /**
   * List of other relevant images
   */
  otherImages: BaseInspectionReportFormDataFragment_otherImages[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseJobFragment
// ====================================================

export interface BaseJobFragment_assignedTo {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
}

export interface BaseJobFragment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface BaseJobFragment_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
}

export interface BaseJobFragment_movable_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface BaseJobFragment_movable {
  /**
   * Unique id of movable
   */
  id: string;
  /**
   * Description of movable
   */
  description: string;
  /**
   * Movable status
   */
  status: string;
  /**
   * Movable placement
   */
  placement: BaseJobFragment_movable_placement;
}

export interface BaseJobFragment {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Type of case task
   */
  type: JobType;
  /**
   * Description of the case task
   */
  description: string;
  /**
   * Deadline of the case task
   */
  deadline: GQL_DateTime;
  /**
   * The user responsible for the task
   */
  assignedTo: BaseJobFragment_assignedTo | null;
  /**
   * The location responsible to the task
   */
  location: BaseJobFragment_location | null;
  /**
   * The case the task belongs to
   */
  case: BaseJobFragment_case | null;
  /**
   * The movable responsible to the task
   */
  movable: BaseJobFragment_movable | null;
  /**
   * Whether the task is completed
   */
  completed: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseLocationFragment
// ====================================================

export interface BaseLocationFragment_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseLocationFragment {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: BaseLocationFragment_address;
  /**
   * Email attached to location
   */
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseMachineFragment
// ====================================================

export interface BaseMachineFragment {
  /**
   * BC identifier
   */
  erpReferenceNo: string;
  /**
   * Name of machine
   */
  name: string;
  /**
   * Start of machineUsage
   */
  dateStart: GQL_Date;
  /**
   * end of machineUsage
   */
  dateEnd: GQL_Date;
  /**
   * Case number if case
   */
  eRPCaseReference: string | null;
  /**
   * location number if location
   */
  eRPLocationReference: string | null;
  /**
   * Consumption
   */
  consumption: GQL_Decimal;
  /**
   * Name of user
   */
  userName: string;
  /**
   * Ressource type/allocation Type
   */
  type: string;
  /**
   * bool to determine if machine is active
   */
  active: boolean;
  /**
   * Current placement of machine
   */
  placement: string;
  /**
   * Original location of machine
   */
  originLocation: string;
  /**
   * Hibernation state
   */
  hibernate: boolean;
  /**
   * Hibernation state
   */
  hibernationReason: string;
  /**
   * Does the machine require specific education
   */
  educationPrerequisit: string[] | null;
  /**
   * Start time
   */
  startTime: string;
  /**
   * Date of next service
   */
  serviceDate: GQL_DateTime;
  /**
   * Factor for date to kwh conversion
   */
  consumptionFactor: GQL_Decimal;
  /**
   * Whether the type is days, kwh
   */
  consumptionType: MachineConsumptionType;
  /**
   * machines model number
   */
  modelNumber: string;
  /**
   * Reservation Start
   */
  reservationStart: GQL_DateTime | null;
  /**
   * Reservation End
   */
  reservationEnd: GQL_DateTime | null;
  /**
   * reservation description
   */
  reservationDescription: string | null;
  /**
   * Reservation by ressource
   */
  reservationByRessource: string | null;
  /**
   * reservation on Case
   */
  reservationOnCase: string | null;
  /**
   * Is the machine blocked
   */
  blocked: boolean;
  /**
   * Where the machine has been placed, e.g. Basement, 1st floor bedroom etc.
   */
  placementDescription: string | null;
  /**
   * The total consumption after machine has been used
   */
  usageMeterEnd: GQL_Decimal;
  /**
   * Unavailable reason
   */
  unavailableReason: string;
  /**
   * Marks that machine has been discontinued
   */
  discontinued: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseMachineLocationFragment
// ====================================================

export interface BaseMachineLocationFragment {
  /**
   * Identifier of location
   */
  locationCode: string;
  /**
   * address line
   */
  address: string;
  /**
   * City
   */
  city: string;
  /**
   * Contact person
   */
  contact: string;
  /**
   * Name
   */
  name: string;
  /**
   * Post code
   */
  postCode: string;
  /**
   * True if location is unavaliable
   */
  unavaliable: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseMoistureReportFormDataFragment
// ====================================================

export interface BaseMoistureReportFormDataFragment_facadeImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface BaseMoistureReportFormDataFragment_damageCauseImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface BaseMoistureReportFormDataFragment_floorReport_observations {
  /**
   * Describes where the damage is
   */
  roomOrApartment: string;
  /**
   * Construction observations
   */
  construction: string | null;
  /**
   * Ceiling observations
   */
  ceiling: string | null;
  /**
   * Walls observations
   */
  walls: string | null;
  /**
   * Floor observations
   */
  floor: string | null;
  /**
   * Observed scope of damage
   */
  scope: string;
  /**
   * Recommendations
   */
  recommendations: string;
}

export interface BaseMoistureReportFormDataFragment_floorReport_moistureMeassurements {
  /**
   * Construction
   */
  construction: string | null;
  /**
   * Material
   */
  material: string | null;
  /**
   * Description
   */
  description: string | null;
  /**
   * Method
   */
  method: string | null;
  /**
   * Value
   */
  value: string;
  /**
   * Expected final value
   */
  expectedFinalValue: string | null;
  /**
   * Reference value
   */
  referenceValue: string | null;
  /**
   * Reference date
   */
  referenceDate: string | null;
  /**
   * Control value
   */
  controlValue: string | null;
  /**
   * Control date
   */
  controlDate: string | null;
}

export interface BaseMoistureReportFormDataFragment_floorReport_floorplanImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface BaseMoistureReportFormDataFragment_floorReport_otherImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface BaseMoistureReportFormDataFragment_floorReport {
  /**
   * Floor Name
   */
  name: string;
  /**
   * Report observations
   */
  observations: BaseMoistureReportFormDataFragment_floorReport_observations;
  /**
   * Moisture meassurements
   */
  moistureMeassurements: BaseMoistureReportFormDataFragment_floorReport_moistureMeassurements[] | null;
  /**
   * Floorplan image info
   */
  floorplanImage: BaseMoistureReportFormDataFragment_floorReport_floorplanImage | null;
  /**
   * List of other relevant images
   */
  otherImages: BaseMoistureReportFormDataFragment_floorReport_otherImages[] | null;
}

export interface BaseMoistureReportFormDataFragment {
  /**
   * Famage image info
   */
  facadeImage: BaseMoistureReportFormDataFragment_facadeImage | null;
  /**
   * Date of damage
   */
  inspectionDate: GQL_Date;
  /**
   * Conclusions
   */
  conclusion: string;
  /**
   * Damage cause description
   */
  damageCause: string;
  /**
   * Damage scope
   */
  scope: string;
  /**
   * Damage site contacts
   */
  contacts: string;
  /**
   * Images of damage cause
   */
  damageCauseImages: BaseMoistureReportFormDataFragment_damageCauseImages[] | null;
  /**
   * Gulvfugt info
   */
  floorReport: BaseMoistureReportFormDataFragment_floorReport[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseMoistureTrygReportFormDataFragment
// ====================================================

export interface BaseMoistureTrygReportFormDataFragment_facadeImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface BaseMoistureTrygReportFormDataFragment_floorReport_floorplanImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface BaseMoistureTrygReportFormDataFragment_floorReport_moistureMeassurements {
  /**
   * Construction
   */
  construction: string | null;
  /**
   * Material
   */
  material: string | null;
  /**
   * Description
   */
  description: string | null;
  /**
   * Method
   */
  method: string | null;
  /**
   * Paints
   */
  paints: string;
  /**
   * Paints Date
   */
  paintsDate: string | null;
  /**
   * Expected final value
   */
  expectedFinalValue: string | null;
  /**
   * Expected final date
   */
  expectedFinalDate: string | null;
  /**
   * Reference value
   */
  referenceValue: string | null;
  /**
   * Reference date
   */
  referenceDate: string | null;
  /**
   * Control value
   */
  controlValue: string | null;
  /**
   * Control date
   */
  controlDate: string | null;
}

export interface BaseMoistureTrygReportFormDataFragment_floorReport_airMeassurements {
  /**
   * Temperature inside (celcius)
   */
  temperatureInside: number;
  /**
   * Temperature outside (celcius)
   */
  temperatureOutside: number;
  /**
   * Relative humidity inside (percentage)
   */
  relativeHumidityInside: number;
  /**
   * Relative humidity outside (percentage)
   */
  relativeHumidityOutside: number;
  /**
   * Water amount inside (g/m3)
   */
  waterAmountInside: number;
  /**
   * Water amount outside (g/m3)
   */
  waterAmountOutside: number;
}

export interface BaseMoistureTrygReportFormDataFragment_floorReport_damageDescriptionImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface BaseMoistureTrygReportFormDataFragment_floorReport_damageDrawingImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface BaseMoistureTrygReportFormDataFragment_floorReport_otherImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface BaseMoistureTrygReportFormDataFragment_floorReport {
  /**
   * Name of floor
   */
  name: string | null;
  /**
   * Damage cause description
   */
  damageCause: string | null;
  /**
   * Multiple damage causes description
   */
  multipleDamageCauses: string | null;
  /**
   * Description of relation between damage cause and moisture
   */
  moistureDamageCause: string | null;
  /**
   * Damage description
   */
  damageDescription: string | null;
  /**
   * Floorplan image info
   */
  floorplanImage: BaseMoistureTrygReportFormDataFragment_floorReport_floorplanImage | null;
  /**
   * Moisture meassurements
   */
  moistureMeassurements: BaseMoistureTrygReportFormDataFragment_floorReport_moistureMeassurements[] | null;
  /**
   * Air meassurements
   */
  airMeassurements: BaseMoistureTrygReportFormDataFragment_floorReport_airMeassurements;
  /**
   * Damage description image info
   */
  damageDescriptionImage: BaseMoistureTrygReportFormDataFragment_floorReport_damageDescriptionImage[] | null;
  /**
   * Damage drawing image info
   */
  damageDrawingImage: BaseMoistureTrygReportFormDataFragment_floorReport_damageDrawingImage | null;
  /**
   * Recommendations (if any)
   */
  recommendations: string | null;
  /**
   * Urgent actions (if any)
   */
  urgentActions: string | null;
  /**
   * List of other relevant images
   */
  otherImages: BaseMoistureTrygReportFormDataFragment_floorReport_otherImages[] | null;
}

export interface BaseMoistureTrygReportFormDataFragment {
  /**
   * Famage image info
   */
  facadeImage: BaseMoistureTrygReportFormDataFragment_facadeImage | null;
  /**
   * Date of damage
   */
  inspectionDate: GQL_Date | null;
  /**
   * Gulvfugt info
   */
  floorReport: BaseMoistureTrygReportFormDataFragment_floorReport[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseMovableFragment
// ====================================================

export interface BaseMovableFragment_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
}

export interface BaseMovableFragment_placement_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseMovableFragment_placement_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: BaseMovableFragment_placement_location_address;
}

export interface BaseMovableFragment_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: BaseMovableFragment_placement_location;
}

export interface BaseMovableFragment_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
}

export interface BaseMovableFragment_changes_before_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface BaseMovableFragment_changes_before {
  /**
   * Description of movable
   */
  description: string | null;
  /**
   * Movable volume in m3
   */
  volume: GQL_Decimal | null;
  /**
   * Movable completed indicator
   */
  completed: boolean | null;
  /**
   * Movable status
   */
  status: string | null;
  /**
   * Movable placement
   */
  placement: BaseMovableFragment_changes_before_placement | null;
}

export interface BaseMovableFragment_changes_after_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface BaseMovableFragment_changes_after {
  /**
   * Description of movable
   */
  description: string | null;
  /**
   * Movable volume in m3
   */
  volume: GQL_Decimal | null;
  /**
   * Movable completed indicator
   */
  completed: boolean | null;
  /**
   * Movable status
   */
  status: string | null;
  /**
   * Movable placement
   */
  placement: BaseMovableFragment_changes_after_placement | null;
}

export interface BaseMovableFragment_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: BaseMovableFragment_changes_user;
  /**
   * Value before
   */
  before: BaseMovableFragment_changes_before;
  /**
   * Value after
   */
  after: BaseMovableFragment_changes_after;
}

export interface BaseMovableFragment {
  /**
   * Unique id of movable
   */
  id: string;
  /**
   * Description of movable
   */
  description: string;
  /**
   * Movable volume in m3
   */
  volume: GQL_Decimal;
  /**
   * Case which movable is attached to
   */
  case: BaseMovableFragment_case;
  /**
   * Movable completed indicator
   */
  completed: boolean;
  /**
   * Movable status
   */
  status: string;
  /**
   * Movable placement
   */
  placement: BaseMovableFragment_placement;
  /**
   * Movable change version number
   */
  version: number;
  /**
   * Movable change log
   */
  changes: BaseMovableFragment_changes[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseMovablesLocationFragment
// ====================================================

export interface BaseMovablesLocationFragment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseMovablesLocationFragment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: BaseMovablesLocationFragment_location_address;
}

export interface BaseMovablesLocationFragment {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
  /**
   * Movable location volume in m3
   */
  volume: GQL_Decimal;
  /**
   * Accumulated location volume in m3
   */
  accVolume: GQL_Decimal;
  /**
   * Location which movables location is attached to
   */
  location: BaseMovablesLocationFragment_location;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseNotificationFragment
// ====================================================

export interface BaseNotificationFragment {
  /**
   * Unique id of notification
   */
  id: string;
  /**
   * Trigger of the notification
   */
  trigger: NotificationTrigger;
  /**
   * Id of the email body template used from SharePoint
   */
  emailBodyTemplateId: number | null;
  /**
   * Id of the sms template used from SharePoint
   */
  smsTemplateId: number | null;
  /**
   * Template of the email subject
   */
  emailSubjectTemplate: string | null;
  /**
   * Display name of the notification
   */
  displayName: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseNotificationItemFragment
// ====================================================

export interface BaseNotificationItemFragment {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Trigger for notification
   */
  trigger: NotificationTrigger;
  /**
   * Receivers of notification
   */
  sendTo: string | null;
  /**
   * Object type for notification
   */
  objectType: string;
  /**
   * Object id for notification
   */
  objectId: string;
  /**
   * type of notification
   */
  type: string;
  /**
   * Is notification sent successfully
   */
  sentSuccessfully: boolean;
  /**
   * Error for notification
   */
  error: string | null;
  /**
   * Notification sent at
   */
  sentAt: GQL_DateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseOfferFragment
// ====================================================

export interface BaseOfferFragment_lines {
  /**
   * document nr / e.g Screening
   */
  documentNo: string;
  /**
   * Item / resource reference id
   */
  no: string;
  /**
   * Date of planning
   */
  planningDate: string;
  /**
   * Description
   */
  description: string;
  /**
   * Quantity
   */
  quantity: GQL_Decimal;
  /**
   * Price of unit
   */
  unitPrice: GQL_Decimal;
  /**
   * new Price of unit
   */
  newUnitPrice: GQL_Decimal | null;
  /**
   * Line amount
   */
  lineAmount: GQL_Decimal;
  /**
   * Currencycode
   */
  currencyCode: string | null;
  /**
   * Type of work
   */
  workType: string | null;
  /**
   * Id of entity
   */
  systemId: string | null;
  /**
   * ERP Reference task
   */
  eRPReferenceTask: string;
  /**
   * Assortment type
   */
  type: OfferRessourceType;
  /**
   * option
   */
  option: boolean;
  /**
   * hideSum
   */
  hideSum: boolean;
  /**
   * is line a header
   */
  header: boolean;
  /**
   * Refrence id to line in screening template
   */
  screeningLineId: string | null;
  /**
   * Sorting index (header and lines are grouped by sorting index)
   */
  sortingIndex: number;
  /**
   * Line number
   */
  lineNo: number;
  /**
   * Unit Cost
   */
  unitCost: GQL_Decimal;
}

export interface BaseOfferFragment {
  /**
   * BC job number
   */
  jobNo: string;
  /**
   * Offer lines
   */
  lines: BaseOfferFragment_lines[];
  /**
   * current status on Quote
   */
  opportunityStatus: string | null;
  /**
   * header text
   */
  headerText: string | null;
  /**
   * footer text
   */
  footerText: string | null;
  /**
   * Quote Type
   */
  quoteType: OfferQuoteTypeEnum | null;
  /**
   * is offer competition offer
   */
  competitionOffer: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseOfferItemFragment
// ====================================================

export interface BaseOfferItemFragment {
  /**
   * item no
   */
  no: string;
  /**
   * description
   */
  description: string;
  /**
   * line type
   */
  type: string;
  /**
   * Base unit of messure
   */
  baseUOM: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseOfferJobTaskFragment
// ====================================================

export interface BaseOfferJobTaskFragment {
  /**
   * case no
   */
  erpRefrenceNo: string;
  /**
   * JobTask No
   */
  jobTaskNo: string;
  /**
   * Description
   */
  description: string;
  /**
   * Price of the JobTask
   */
  price: GQL_Decimal;
  /**
   * Work type for the job task
   */
  workTypeCode: string;
  /**
   * Exclude In the enviromental fee
   */
  excludeInEnvFee: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseOfferResourceFragment
// ====================================================

export interface BaseOfferResourceFragment {
  /**
   * Resource No
   */
  no: string;
  /**
   * Resource Name
   */
  name: string;
  /**
   * Resource type
   */
  type: string;
  /**
   * baseUOM e.g Time 
   */
  baseUOM: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseQuestionnaireTemplateFragment
// ====================================================

export interface BaseQuestionnaireTemplateFragment_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface BaseQuestionnaireTemplateFragment_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: BaseQuestionnaireTemplateFragment_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface BaseQuestionnaireTemplateFragment {
  /**
   * Unique id of questionnaire
   */
  id: string;
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: BaseQuestionnaireTemplateFragment_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire driving slip categories
   */
  drivingSlipCategories: string[];
  /**
   * Questionnaire change version number
   */
  version: number;
  /**
   * Timestamp of when the questionnaire was created
   */
  createdAt: GQL_DateTime;
  /**
   * Timestamp of when the questionnaire was last updated
   */
  updatedAt: GQL_DateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseReportFile
// ====================================================

export interface BaseReportFile {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseRiskEvaluationQuestionFragment
// ====================================================

export interface BaseRiskEvaluationQuestionFragment_category {
  /**
   * Id of risk category
   */
  id: string;
  /**
   * Name of risk category
   */
  categoryName: string;
}

export interface BaseRiskEvaluationQuestionFragment {
  /**
   * Id of question
   */
  id: string;
  /**
   * Id of category question is linked to
   */
  category: BaseRiskEvaluationQuestionFragment_category;
  /**
   * Question
   */
  question: string;
  /**
   * Help text for question
   */
  helpText: string;
  /**
   * If a drivingslip is made during casecreation this question will be marked true on risk evaluation
   */
  defaultChecked: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseScreeningPositionTextFragment
// ====================================================

export interface BaseScreeningPositionTextFragment {
  /**
   * Unique id of entry
   */
  id: string;
  /**
   * Screening template code
   */
  templateCode: string;
  /**
   * Screening line position
   */
  position: string;
  /**
   * Screening line position help text
   */
  text: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseTimedMessageFragment
// ====================================================

export interface BaseTimedMessageFragment_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface BaseTimedMessageFragment {
  /**
   * id to display
   */
  id: string;
  /**
   * Customer
   */
  customer: BaseTimedMessageFragment_customer;
  /**
   * Craftsman changed by
   */
  changedBy: string;
  /**
   * Time at which the message should show from
   */
  startTime: GQL_DateTime;
  /**
   * Time at which the message should stop showing
   */
  endTime: GQL_DateTime;
  /**
   * message to display
   */
  message: string;
  /**
   * Is timed message global
   */
  global: boolean;
  /**
   * Global type for timed message
   */
  globalType: CatalogGlobalType;
  /**
   * Is timed message active
   */
  active: boolean;
  /**
   * Version for timed message
   */
  version: number;
  /**
   * Catalog ids
   */
  catalogIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseTimeTrackingFragment
// ====================================================

export interface BaseTimeTrackingFragment_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface BaseTimeTrackingFragment_case_debitor {
  /**
   * Company identifier
   */
  company: string;
}

export interface BaseTimeTrackingFragment_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface BaseTimeTrackingFragment_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface BaseTimeTrackingFragment_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
}

export interface BaseTimeTrackingFragment_case_damage_contact {
  /**
   * Contact address information
   */
  address: BaseTimeTrackingFragment_case_damage_contact_address;
}

export interface BaseTimeTrackingFragment_case_damage {
  /**
   * Damage category
   */
  category: BaseTimeTrackingFragment_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: BaseTimeTrackingFragment_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: BaseTimeTrackingFragment_case_damage_contact;
}

export interface BaseTimeTrackingFragment_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: BaseTimeTrackingFragment_case_debitor;
  /**
   * Case damage details
   */
  damage: BaseTimeTrackingFragment_case_damage;
}

export interface BaseTimeTrackingFragment_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface BaseTimeTrackingFragment_addonLines_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface BaseTimeTrackingFragment_addonLines_case_debitor {
  /**
   * Company identifier
   */
  company: string;
}

export interface BaseTimeTrackingFragment_addonLines_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: BaseTimeTrackingFragment_addonLines_case_debitor;
}

export interface BaseTimeTrackingFragment_addonLines_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface BaseTimeTrackingFragment_addonLines {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * AD user
   */
  user: BaseTimeTrackingFragment_addonLines_user;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: BaseTimeTrackingFragment_addonLines_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: BaseTimeTrackingFragment_addonLines_drivingSlip | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
}

export interface BaseTimeTrackingFragment {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: BaseTimeTrackingFragment_user;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Case for time tracking
   */
  case: BaseTimeTrackingFragment_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: BaseTimeTrackingFragment_drivingSlip | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
  /**
   * List of addon lines
   */
  addonLines: BaseTimeTrackingFragment_addonLines[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseUserFragment
// ====================================================

export interface BaseUserFragment {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseVisitationQuestionFragment
// ====================================================

export interface BaseVisitationQuestionFragment_damageCause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface BaseVisitationQuestionFragment_damageCategory {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface BaseVisitationQuestionFragment {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question to ask based on damage cause
   */
  question: string;
  /**
   * Which damage cause the question is based on
   */
  damageCause: BaseVisitationQuestionFragment_damageCause;
  /**
   * Which damage category the question is based on
   */
  damageCategory: BaseVisitationQuestionFragment_damageCategory;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CalendarEventFragment
// ====================================================

export interface CalendarEventFragment_timeRange {
  /**
   * From date
   */
  from: GQL_DateTime;
  /**
   * To date
   */
  to: GQL_DateTime;
}

export interface CalendarEventFragment_drivingSlip_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CalendarEventFragment_drivingSlip_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CalendarEventFragment_drivingSlip_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CalendarEventFragment_drivingSlip_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface CalendarEventFragment_drivingSlip_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: CalendarEventFragment_drivingSlip_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface CalendarEventFragment_drivingSlip_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: CalendarEventFragment_drivingSlip_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface CalendarEventFragment_drivingSlip_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: CalendarEventFragment_drivingSlip_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface CalendarEventFragment_drivingSlip_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CalendarEventFragment_drivingSlip_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CalendarEventFragment_drivingSlip_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CalendarEventFragment_drivingSlip_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CalendarEventFragment_drivingSlip_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: CalendarEventFragment_drivingSlip_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: CalendarEventFragment_drivingSlip_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: CalendarEventFragment_drivingSlip_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface CalendarEventFragment_drivingSlip_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CalendarEventFragment_drivingSlip_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CalendarEventFragment_drivingSlip_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CalendarEventFragment_drivingSlip_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: CalendarEventFragment_drivingSlip_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: CalendarEventFragment_drivingSlip_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: CalendarEventFragment_drivingSlip_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface CalendarEventFragment_drivingSlip_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: CalendarEventFragment_drivingSlip_changes_user;
  /**
   * Value before
   */
  before: CalendarEventFragment_drivingSlip_changes_before;
  /**
   * Value after
   */
  after: CalendarEventFragment_drivingSlip_changes_after;
}

export interface CalendarEventFragment_drivingSlip_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface CalendarEventFragment_drivingSlip_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface CalendarEventFragment_drivingSlip_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CalendarEventFragment_drivingSlip_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: CalendarEventFragment_drivingSlip_case_damage_contact_address;
}

export interface CalendarEventFragment_drivingSlip_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface CalendarEventFragment_drivingSlip_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface CalendarEventFragment_drivingSlip_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface CalendarEventFragment_drivingSlip_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface CalendarEventFragment_drivingSlip_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: CalendarEventFragment_drivingSlip_case_damage_cause_departments_location;
}

export interface CalendarEventFragment_drivingSlip_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: CalendarEventFragment_drivingSlip_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: CalendarEventFragment_drivingSlip_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (CalendarEventFragment_drivingSlip_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface CalendarEventFragment_drivingSlip_case_damage {
  /**
   * Damage location contact information
   */
  contact: CalendarEventFragment_drivingSlip_case_damage_contact;
  /**
   * Damage category
   */
  category: CalendarEventFragment_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: CalendarEventFragment_drivingSlip_case_damage_cause;
}

export interface CalendarEventFragment_drivingSlip_case_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CalendarEventFragment_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: CalendarEventFragment_drivingSlip_case_damage;
  /**
   * Project manager attached to case
   */
  projectManager: CalendarEventFragment_drivingSlip_case_projectManager | null;
}

export interface CalendarEventFragment_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: CalendarEventFragment_drivingSlip_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: CalendarEventFragment_drivingSlip_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: CalendarEventFragment_drivingSlip_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: CalendarEventFragment_drivingSlip_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: CalendarEventFragment_drivingSlip_changes[];
  /**
   * Location of driving slip
   */
  location: CalendarEventFragment_drivingSlip_location;
  /**
   * Department of driving slip
   */
  department: CalendarEventFragment_drivingSlip_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
  /**
   * Case which driving slip is related to
   */
  case: CalendarEventFragment_drivingSlip_case;
}

export interface CalendarEventFragment {
  /**
   * Unique calendar reference id
   */
  referenceId: string;
  /**
   * Calendar event title
   */
  title: string;
  /**
   * Type of calendar event
   */
  type: CalendarEventType;
  /**
   * Calendar event time range
   */
  timeRange: CalendarEventFragment_timeRange;
  /**
   * Calendar event time range
   */
  isAllDay: boolean;
  /**
   * Indicates if calendar event was created through 5C
   */
  systemCreatedEvent: boolean;
  /**
   * Optional unique driving slip id
   */
  drivingSlip: CalendarEventFragment_drivingSlip | null;
  __typename: "CalendarEvent";
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CaseChangesFragment
// ====================================================

export interface CaseChangesFragment_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CaseChangesFragment_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor billing address
   */
  billingAddress: CaseChangesFragment_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
}

export interface CaseChangesFragment_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface CaseChangesFragment_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface CaseChangesFragment_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface CaseChangesFragment_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface CaseChangesFragment_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface CaseChangesFragment_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: CaseChangesFragment_damage_cause_departments_location;
}

export interface CaseChangesFragment_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: CaseChangesFragment_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: CaseChangesFragment_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (CaseChangesFragment_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface CaseChangesFragment_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CaseChangesFragment_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: CaseChangesFragment_damage_contact_address;
}

export interface CaseChangesFragment_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface CaseChangesFragment_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: CaseChangesFragment_damage_businessArea;
  /**
   * Damage category
   */
  category: CaseChangesFragment_damage_category;
  /**
   * Cause behind damage
   */
  cause: CaseChangesFragment_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: CaseChangesFragment_damage_contact;
  /**
   * contacts for damage
   */
  contacts: CaseChangesFragment_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface CaseChangesFragment_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CaseChangesFragment_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: CaseChangesFragment_policyHolder_address;
}

export interface CaseChangesFragment_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface CaseChangesFragment_visitation_awaiting {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface CaseChangesFragment_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
}

export interface CaseChangesFragment_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: CaseChangesFragment_visitation_calledBack;
  /**
   * If case is awaiting
   */
  awaiting: CaseChangesFragment_visitation_awaiting;
  /**
   * A set of answers related to the damage cause
   */
  answers: CaseChangesFragment_visitation_answers[] | null;
}

export interface CaseChangesFragment_requisitioner {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Requisitioner relation
   */
  relation: string;
}

export interface CaseChangesFragment_drivingSlips_driver {
  /**
   * Name of user
   */
  name: string;
}

export interface CaseChangesFragment_drivingSlips_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
}

export interface CaseChangesFragment_drivingSlips {
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: CaseChangesFragment_drivingSlips_driver | null;
  /**
   * Car added to the drivingslip
   */
  car: CaseChangesFragment_drivingSlips_car | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
}

export interface CaseChangesFragment_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CaseChangesFragment_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CaseChangesFragment_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CaseChangesFragment_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: CaseChangesFragment_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface CaseChangesFragment_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CaseChangesFragment_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: CaseChangesFragment_ssgDepartment_location_address;
}

export interface CaseChangesFragment_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: CaseChangesFragment_ssgDepartment_location;
}

export interface CaseChangesFragment_caseEmails {
  /**
   * Email content
   */
  content: string;
  /**
   * List of email attachments
   */
  attachments: string[];
  /**
   * List of email receivers
   */
  receivers: string[];
  /**
   * Timestamp of when email was created
   */
  timestamp: GQL_DateTime;
}

export interface CaseChangesFragment_caseMachineUsages {
  /**
   * Machine identifier
   */
  machine: string;
  /**
   * Start time
   */
  startTime: GQL_DateTime;
  /**
   * End time
   */
  endTime: GQL_DateTime | null;
  /**
   * Meter reading at start time
   */
  startMeterReading: GQL_Decimal;
  /**
   * Meter reading at end time
   */
  endMeterReading: GQL_Decimal | null;
}

export interface CaseChangesFragment_alternativeContact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Comment on person
   */
  comment: string | null;
}

export interface CaseChangesFragment_riskEvaluationAnswers_question {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question
   */
  question: string;
}

export interface CaseChangesFragment_riskEvaluationAnswers {
  /**
   * Id of question answer is linked to
   */
  question: CaseChangesFragment_riskEvaluationAnswers_question;
  /**
   * Answer given
   */
  answer: boolean;
}

export interface CaseChangesFragment {
  /**
   * Case status
   */
  status: CaseStatus | null;
  /**
   * Case track
   */
  track: number | null;
  /**
   * Case debitor details
   */
  debitor: CaseChangesFragment_debitor | null;
  /**
   * Case damage details
   */
  damage: CaseChangesFragment_damage | null;
  /**
   * Case policy holder details
   */
  policyHolder: CaseChangesFragment_policyHolder | null;
  /**
   * Case visitation details
   */
  visitation: CaseChangesFragment_visitation | null;
  /**
   * Case requisitioner details
   */
  requisitioner: CaseChangesFragment_requisitioner | null;
  /**
   * The driving slip changed on case
   */
  drivingSlips: CaseChangesFragment_drivingSlips | null;
  /**
   * Project manager attached to case
   */
  projectManager: CaseChangesFragment_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: CaseChangesFragment_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: CaseChangesFragment_ssgLocation | null;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: CaseChangesFragment_ssgDepartment | null;
  /**
   * List of case emails
   */
  caseEmails: CaseChangesFragment_caseEmails[] | null;
  /**
   * List of case machine usages
   */
  caseMachineUsages: CaseChangesFragment_caseMachineUsages[] | null;
  /**
   * Important info on case
   */
  importantInfo: string | null;
  /**
   * Requisition from fire department
   */
  skafor: boolean | null;
  /**
   * Alternative contact person
   */
  alternativeContact: CaseChangesFragment_alternativeContact | null;
  /**
   * Risk evaluation answers
   */
  riskEvaluationAnswers: CaseChangesFragment_riskEvaluationAnswers[] | null;
  /**
   * Name of files
   */
  fileNames: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CaseUsageFragment
// ====================================================

export interface CaseUsageFragment {
  /**
   * ERP system reference id
   */
  erpId: string;
  /**
   * ERP system reference key
   */
  erpKey: string;
  /**
   * Usage line title
   */
  title: string;
  /**
   * Usage line quantity
   */
  quantity: GQL_Decimal;
  /**
   * Usage line unit
   */
  unit: string;
  /**
   * Usage line cost per unit
   */
  lineCost: GQL_Decimal;
  /**
   * Usage line total cost
   */
  totalCost: GQL_Decimal;
  /**
   * Usage line attachments
   */
  attachments: string[];
  /**
   * Name of ressource
   */
  ressourceName: string;
  /**
   * Date of posting
   */
  postingDate: string;
  /**
   * Original date of posting
   */
  originalPostingDate: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CatalogChangesFragment
// ====================================================

export interface CatalogChangesFragment_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface CatalogChangesFragment_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface CatalogChangesFragment_contacts_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface CatalogChangesFragment_contacts_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface CatalogChangesFragment_contacts_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface CatalogChangesFragment_contacts_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: CatalogChangesFragment_contacts_catalogs_addresses[];
}

export interface CatalogChangesFragment_contacts {
  customer: CatalogChangesFragment_contacts_customer | null;
  changedBy: string | null;
  /**
   * Contact name
   */
  contactName: string | null;
  type: string | null;
  contactType: CatalogContacts | null;
  /**
   * Contact email
   */
  email: string | null;
  /**
   * Informations for Contacts
   */
  informations: CatalogChangesFragment_contacts_informations[] | null;
  /**
   * Is contact global
   */
  global: boolean | null;
  /**
   * Global type for contact
   */
  globalType: CatalogGlobalType | null;
  /**
   * Is contact active
   */
  active: boolean | null;
  catalogs: CatalogChangesFragment_contacts_catalogs[] | null;
}

export interface CatalogChangesFragment_craftsmen_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface CatalogChangesFragment_craftsmen_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface CatalogChangesFragment_craftsmen_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface CatalogChangesFragment_craftsmen_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: CatalogChangesFragment_craftsmen_catalogs_addresses[];
}

export interface CatalogChangesFragment_craftsmen {
  customer: CatalogChangesFragment_craftsmen_customer | null;
  changedBy: string | null;
  /**
   * Type for call contact
   */
  type: string | null;
  /**
   * Call contact name
   */
  contactName: string | null;
  /**
   * Informations for Craftsman
   */
  informations: CatalogChangesFragment_craftsmen_informations[] | null;
  /**
   * Call contact email
   */
  email: string | null;
  /**
   * Priority for call contact
   */
  priority: string | null;
  /**
   * Is call contact global
   */
  global: boolean | null;
  /**
   * Global type for craftsman
   */
  globalType: CatalogGlobalType | null;
  /**
   * Is call contact active
   */
  active: boolean | null;
  catalogs: CatalogChangesFragment_craftsmen_catalogs[] | null;
}

export interface CatalogChangesFragment_timedMessages_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface CatalogChangesFragment_timedMessages_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface CatalogChangesFragment_timedMessages_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: CatalogChangesFragment_timedMessages_catalogs_addresses[];
}

export interface CatalogChangesFragment_timedMessages {
  customer: CatalogChangesFragment_timedMessages_customer | null;
  changedBy: string | null;
  startTime: GQL_DateTime | null;
  endTime: GQL_DateTime | null;
  message: string | null;
  global: boolean | null;
  globalType: CatalogGlobalType | null;
  active: boolean | null;
  catalogs: CatalogChangesFragment_timedMessages_catalogs[] | null;
}

export interface CatalogChangesFragment_debitor {
  /**
   * Company identifier
   */
  company: string;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
}

export interface CatalogChangesFragment {
  ssgAccountable: string | null;
  propertyNumber: string | null;
  customer: CatalogChangesFragment_customer | null;
  changedBy: string | null;
  description: string | null;
  contactNumber: string | null;
  contactHours: string | null;
  addresses: CatalogChangesFragment_addresses[] | null;
  contacts: CatalogChangesFragment_contacts | null;
  craftsmen: CatalogChangesFragment_craftsmen | null;
  timedMessages: CatalogChangesFragment_timedMessages | null;
  filenames: string | null;
  smsService: boolean | null;
  /**
   * Is catalog verified
   */
  verified: boolean;
  /**
   * Is catalog debitor verified
   */
  debitorVerified: boolean;
  /**
   * Catalog debitor details
   */
  debitor: CatalogChangesFragment_debitor | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CatalogContactChangesFragment
// ====================================================

export interface CatalogContactChangesFragment_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface CatalogContactChangesFragment_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface CatalogContactChangesFragment_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface CatalogContactChangesFragment_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: CatalogContactChangesFragment_catalogs_addresses[];
}

export interface CatalogContactChangesFragment {
  customer: CatalogContactChangesFragment_customer | null;
  changedBy: string | null;
  /**
   * Contact name
   */
  contactName: string | null;
  type: string | null;
  contactType: CatalogContacts | null;
  /**
   * Contact email
   */
  email: string | null;
  /**
   * Informations for Contacts
   */
  informations: CatalogContactChangesFragment_informations[] | null;
  /**
   * Is contact global
   */
  global: boolean | null;
  /**
   * Global type for contact
   */
  globalType: CatalogGlobalType | null;
  /**
   * Is contact active
   */
  active: boolean | null;
  catalogs: CatalogContactChangesFragment_catalogs[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CatalogCraftsmanChangesFragment
// ====================================================

export interface CatalogCraftsmanChangesFragment_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface CatalogCraftsmanChangesFragment_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface CatalogCraftsmanChangesFragment_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface CatalogCraftsmanChangesFragment_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: CatalogCraftsmanChangesFragment_catalogs_addresses[];
}

export interface CatalogCraftsmanChangesFragment {
  customer: CatalogCraftsmanChangesFragment_customer | null;
  changedBy: string | null;
  /**
   * Type for call contact
   */
  type: string | null;
  /**
   * Call contact name
   */
  contactName: string | null;
  /**
   * Informations for Craftsman
   */
  informations: CatalogCraftsmanChangesFragment_informations[] | null;
  /**
   * Call contact email
   */
  email: string | null;
  /**
   * Priority for call contact
   */
  priority: string | null;
  /**
   * Is call contact global
   */
  global: boolean | null;
  /**
   * Global type for craftsman
   */
  globalType: CatalogGlobalType | null;
  /**
   * Is call contact active
   */
  active: boolean | null;
  catalogs: CatalogCraftsmanChangesFragment_catalogs[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CatalogFragment
// ====================================================

export interface CatalogFragment_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface CatalogFragment_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface CatalogFragment_debitor {
  /**
   * Company identifier
   */
  company: string;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Sales Perseon Code
   */
  salesPersonCode: string;
  /**
   * Sales Perseon Name
   */
  salesPersonName: string;
}

export interface CatalogFragment_craftsmen_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface CatalogFragment_craftsmen_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface CatalogFragment_craftsmen {
  /**
   * Id for catalog call contact
   */
  id: string;
  /**
   * Customer
   */
  customer: CatalogFragment_craftsmen_customer;
  /**
   * Craftsman changed by
   */
  changedBy: string;
  /**
   * Craftsman type
   */
  type: string;
  /**
   * Craftsman name
   */
  contactName: string;
  /**
   * Informations for Craftsman
   */
  informations: CatalogFragment_craftsmen_informations[];
  /**
   * Craftsman email
   */
  email: string | null;
  /**
   * Craftsman priority
   */
  priority: string;
  /**
   * Is contact global
   */
  global: boolean;
  /**
   * Global type for craftsman
   */
  globalType: CatalogGlobalType;
  /**
   * Is contact active
   */
  active: boolean;
  /**
   * Version for catalog craftsman
   */
  version: number;
  /**
   * Catalog ids
   */
  catalogIds: string[];
}

export interface CatalogFragment_contacts_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface CatalogFragment_contacts_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface CatalogFragment_contacts {
  /**
   * Id for catalog contact
   */
  id: string;
  /**
   * Customer
   */
  customer: CatalogFragment_contacts_customer;
  /**
   * Contact changed by
   */
  changedBy: string;
  /**
   * Contact name
   */
  contactName: string;
  /**
   * Contact type
   */
  type: string | null;
  /**
   * Contact collection type
   */
  contactType: CatalogContacts;
  /**
   * Contact email
   */
  email: string | null;
  /**
   * Informations for Contacts
   */
  informations: (CatalogFragment_contacts_informations | null)[];
  /**
   * Is contact global
   */
  global: boolean;
  /**
   * Global type for contact
   */
  globalType: CatalogGlobalType;
  /**
   * Is contact active
   */
  active: boolean;
  /**
   * Catalog ids
   */
  catalogIds: string[];
  /**
   * Version for catalog contact
   */
  version: number;
}

export interface CatalogFragment_timedMessages_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface CatalogFragment_timedMessages {
  /**
   * id to display
   */
  id: string;
  /**
   * Customer
   */
  customer: CatalogFragment_timedMessages_customer;
  /**
   * Craftsman changed by
   */
  changedBy: string;
  /**
   * Time at which the message should show from
   */
  startTime: GQL_DateTime;
  /**
   * Time at which the message should stop showing
   */
  endTime: GQL_DateTime;
  /**
   * message to display
   */
  message: string;
  /**
   * Is timed message global
   */
  global: boolean;
  /**
   * Global type for timed message
   */
  globalType: CatalogGlobalType;
  /**
   * Is timed message active
   */
  active: boolean;
  /**
   * Version for timed message
   */
  version: number;
  /**
   * Catalog ids
   */
  catalogIds: string[];
}

export interface CatalogFragment {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Contact number for the Catalog
   */
  contactNumber: string | null;
  /**
   * Hours contact number can be reached
   */
  contactHours: string | null;
  /**
   * Customer
   */
  customer: CatalogFragment_customer;
  /**
   * Catalog changed by
   */
  changedBy: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: CatalogFragment_addresses[];
  /**
   * Is sms service used for Catalog
   */
  smsService: boolean;
  /**
   * Catalog debitor details
   */
  debitor: CatalogFragment_debitor | null;
  /**
   * Is catalog verified
   */
  verified: boolean;
  /**
   * Is catalog debitor verified
   */
  debitorVerified: boolean;
  /**
   * Version for catalog
   */
  version: number;
  /**
   * Timestamp of when the catalog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the catalog was created
   */
  createdAt: GQL_DateTime;
  /**
   * SGGAccountable for catalog
   */
  ssgAccountable: string | null;
  /**
   * Description for catalog
   */
  description: string | null;
  /**
   * Craftsmen attached to catalog
   */
  craftsmen: CatalogFragment_craftsmen[];
  /**
   * Contacs attached to catalog
   */
  contacts: CatalogFragment_contacts[];
  /**
   * Timed messages attached to catalog
   */
  timedMessages: CatalogFragment_timedMessages[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DamageFragment
// ====================================================

export interface DamageFragment_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface DamageFragment_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface DamageFragment_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface DamageFragment_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface DamageFragment_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface DamageFragment_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: DamageFragment_cause_departments_location;
}

export interface DamageFragment_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: DamageFragment_cause_businessArea;
  /**
   * Id of associated category
   */
  category: DamageFragment_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (DamageFragment_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface DamageFragment_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface DamageFragment_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: DamageFragment_contact_address;
}

export interface DamageFragment_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface DamageFragment {
  /**
   * BusinessArea for damage
   */
  businessArea: DamageFragment_businessArea;
  /**
   * Damage category
   */
  category: DamageFragment_category;
  /**
   * Cause behind damage
   */
  cause: DamageFragment_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: DamageFragment_contact;
  /**
   * contacts for damage
   */
  contacts: DamageFragment_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DraftInvoiceFragment
// ====================================================

export interface DraftInvoiceFragment_validationRuleBypasses {
  /**
   * Rule that was bypassed
   */
  rule: string;
  /**
   * Reason code id
   */
  reasonCode: string;
  /**
   * User comment
   */
  comment: string | null;
}

export interface DraftInvoiceFragment_lines {
  /**
   * System Id
   */
  systemId: string;
  /**
   * Sort index
   */
  sortIndex: number;
  /**
   * Case number
   */
  caseNo: string;
  /**
   * Document number (planningline reference)
   */
  documentNo: string;
  /**
   * Unique identifier
   */
  no: string;
  /**
   * ERP Reference Task
   */
  erpReferenceTask: string;
  /**
   * Line amount
   */
  lineAmount: GQL_Decimal | null;
  /**
   * Line Type
   */
  lineType: string;
  /**
   * Type
   */
  type: string;
  /**
   * Tells ERP system to attach document for subcontractor lines
   */
  attachDocument: boolean | null;
  /**
   * If allow attach document checkbox should be shown
   */
  allowAttachDocument: boolean;
  /**
   * Description
   */
  description: string;
  /**
   * Quantity
   */
  quantity: GQL_Decimal | null;
  /**
   * Quote Line Id
   */
  quoteLineId: string;
  /**
   * Name of screening this line belongs to
   */
  screeningTitle: string;
  /**
   * Screening line
   */
  screeningLine: boolean;
  /**
   * Screening Line Id
   */
  screeningLineId: string;
  /**
   * Screening Line position no
   */
  screeningLinePosition: string | null;
  /**
   * Price of unit
   */
  unitPrice: GQL_Decimal | null;
  /**
   * Cost of unit
   */
  unitCost: GQL_Decimal | null;
  /**
   * New price of unit
   */
  newUnitPrice: GQL_Decimal | null;
  /**
   * Planning date
   */
  planningDate: GQL_Date;
  /**
   * Work type code
   */
  workTypeCode: string;
  /**
   * Created by
   */
  createdBy: string;
}

export interface DraftInvoiceFragment {
  /**
   * Reference no invoice
   */
  erpReferenceNo: string;
  /**
   * Category of invoice
   */
  category: InvoiceCategory;
  /**
   * Invoice status
   */
  status: InvoiceStatus;
  /**
   * Date the invoice is posted
   */
  invoicePostingDate: GQL_Date;
  /**
   * Start date of the invoicing period
   */
  invoiceStartDate: GQL_Date;
  /**
   * End date of the invoicing period
   */
  invoiceEndDate: GQL_Date;
  /**
   * Invoice validation rule bypasses
   */
  validationRuleBypasses: DraftInvoiceFragment_validationRuleBypasses[];
  /**
   * Invoice lines
   */
  lines: DraftInvoiceFragment_lines[];
  /**
   * Determines whether draft lines have been suggested for this invoice
   */
  hasSuggestedLines: boolean;
  /**
   * Description to put on the invoice
   */
  invoiceDescription: string;
  /**
   * Rejection reason from manager
   */
  rejectionReason: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DraftInvoiceLineFragment
// ====================================================

export interface DraftInvoiceLineFragment {
  /**
   * System Id
   */
  systemId: string;
  /**
   * Sort index
   */
  sortIndex: number;
  /**
   * Case number
   */
  caseNo: string;
  /**
   * Document number (planningline reference)
   */
  documentNo: string;
  /**
   * Unique identifier
   */
  no: string;
  /**
   * ERP Reference Task
   */
  erpReferenceTask: string;
  /**
   * Line amount
   */
  lineAmount: GQL_Decimal | null;
  /**
   * Line Type
   */
  lineType: string;
  /**
   * Type
   */
  type: string;
  /**
   * Tells ERP system to attach document for subcontractor lines
   */
  attachDocument: boolean | null;
  /**
   * If allow attach document checkbox should be shown
   */
  allowAttachDocument: boolean;
  /**
   * Description
   */
  description: string;
  /**
   * Quantity
   */
  quantity: GQL_Decimal | null;
  /**
   * Quote Line Id
   */
  quoteLineId: string;
  /**
   * Name of screening this line belongs to
   */
  screeningTitle: string;
  /**
   * Screening line
   */
  screeningLine: boolean;
  /**
   * Screening Line Id
   */
  screeningLineId: string;
  /**
   * Screening Line position no
   */
  screeningLinePosition: string | null;
  /**
   * Price of unit
   */
  unitPrice: GQL_Decimal | null;
  /**
   * Cost of unit
   */
  unitCost: GQL_Decimal | null;
  /**
   * New price of unit
   */
  newUnitPrice: GQL_Decimal | null;
  /**
   * Planning date
   */
  planningDate: GQL_Date;
  /**
   * Work type code
   */
  workTypeCode: string;
  /**
   * Created by
   */
  createdBy: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DrivingSlipChangesFragment
// ====================================================

export interface DrivingSlipChangesFragment_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface DrivingSlipChangesFragment_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface DrivingSlipChangesFragment_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface DrivingSlipChangesFragment {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: DrivingSlipChangesFragment_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: DrivingSlipChangesFragment_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: DrivingSlipChangesFragment_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ESDHFileFragment
// ====================================================

export interface ESDHFileFragment_metadata {
  /**
   * KVP key
   */
  key: string;
  /**
   * KVP value
   */
  value: string;
}

export interface ESDHFileFragment {
  /**
   * File name
   */
  name: string;
  /**
   * File url from ESDH
   */
  url: string;
  /**
   * File thumbnail URL
   */
  thumbnail: string;
  /**
   * File extension
   */
  extension: string;
  /**
   * File grouping key
   */
  groupingKey: string;
  /**
   * File created
   */
  created: GQL_DateTime;
  /**
   * File metadata from ESDH
   */
  metadata: ESDHFileFragment_metadata[];
  /**
   * File comments from ESDH
   */
  comments: string | null;
  /**
   * File extension
   */
  fileId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IndoorClimateReportFormDataFragment
// ====================================================

export interface IndoorClimateReportFormDataFragment_facadeImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface IndoorClimateReportFormDataFragment_floorplanImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface IndoorClimateReportFormDataFragment_otherImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface IndoorClimateReportFormDataFragment_indoorClimateMeassurements {
  /**
   * Room
   */
  room: string | null;
  /**
   * Construction
   */
  construction: string | null;
  /**
   * Material
   */
  material: string | null;
  /**
   * Description
   */
  description: string | null;
  /**
   * Instrument
   */
  instrument: string | null;
  /**
   * Value
   */
  value: string | null;
  /**
   * Reference value
   */
  referenceValue: string | null;
  /**
   * Calculated value
   */
  calculatedValue: string | null;
}

export interface IndoorClimateReportFormDataFragment {
  /**
   * Famage image info
   */
  facadeImage: IndoorClimateReportFormDataFragment_facadeImage | null;
  /**
   * Date of damage
   */
  inspectionDate: GQL_Date | null;
  /**
   * Purpose of the report
   */
  purpose: string | null;
  /**
   * Year of the building being built
   */
  buildYear: string | null;
  /**
   * Square meters of the area
   */
  squareMeters: string | null;
  /**
   * Daily users of the building
   */
  dailyUsers: string | null;
  /**
   * Other participants on the scene
   */
  participants: string | null;
  /**
   * Information about what has happened until now
   */
  backgroundStory: string | null;
  /**
   * Observations
   */
  observations: string | null;
  /**
   * Refer to appendix for lab answers
   */
  showLabAnswers: boolean;
  /**
   * Refer to appendix for the data log
   */
  showDataLog: boolean | null;
  /**
   * Assessment and conclusion
   */
  assessmentAndConclusion: string | null;
  /**
   * Suggested action plan
   */
  suggestedActionPlan: string | null;
  /**
   * Floorplan image info
   */
  floorplanImage: IndoorClimateReportFormDataFragment_floorplanImage | null;
  /**
   * List of other relevant images
   */
  otherImages: IndoorClimateReportFormDataFragment_otherImages[] | null;
  /**
   * Meassurements of indoor climate
   */
  indoorClimateMeassurements: IndoorClimateReportFormDataFragment_indoorClimateMeassurements[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InvoiceReasonCodeFragment
// ====================================================

export interface InvoiceReasonCodeFragment {
  /**
   * Id of invoice reason code
   */
  id: string;
  /**
   * Name of invoice reason code
   */
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RequisitionFragment
// ====================================================

export interface RequisitionFragment_case_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
}

export interface RequisitionFragment_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
  /**
   * Id of damage category
   */
  id: string;
}

export interface RequisitionFragment_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of damage cause
   */
  id: string;
}

export interface RequisitionFragment_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface RequisitionFragment_case_damage_contact {
  /**
   * Contact address information
   */
  address: RequisitionFragment_case_damage_contact_address;
}

export interface RequisitionFragment_case_damage {
  /**
   * Damage category
   */
  category: RequisitionFragment_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: RequisitionFragment_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: RequisitionFragment_case_damage_contact;
}

export interface RequisitionFragment_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: RequisitionFragment_case_debitor;
  /**
   * Case damage details
   */
  damage: RequisitionFragment_case_damage;
}

export interface RequisitionFragment_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
}

export interface RequisitionFragment_drivingSlip_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
}

export interface RequisitionFragment_drivingSlip_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
}

export interface RequisitionFragment_drivingSlip_case_damage {
  /**
   * Damage category
   */
  category: RequisitionFragment_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: RequisitionFragment_drivingSlip_case_damage_cause;
}

export interface RequisitionFragment_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: RequisitionFragment_drivingSlip_case_damage;
}

export interface RequisitionFragment_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Case which driving slip is related to
   */
  case: RequisitionFragment_drivingSlip_case;
}

export interface RequisitionFragment_vendor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface RequisitionFragment_vendor {
  /**
   * ERP reference no for vendor
   */
  erpReferenceNo: string;
  /**
   * The name of the vendor
   */
  company: string | null;
  /**
   * The address of the vendor
   */
  address: RequisitionFragment_vendor_address | null;
  /**
   * The email of the vendor
   */
  email: string | null;
  /**
   * The phone number of the vendor
   */
  phone: string | null;
  /**
   * The posting group of the vendor
   */
  vendorPostingGroup: string;
  /**
   * Attached SSG department of the vendor
   */
  ssgDepartment: string;
  /**
   * Attached SSG location of the vendor
   */
  ssgLocation: string;
  /**
   * Vendor type
   */
  type: string;
}

export interface RequisitionFragment_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface RequisitionFragment {
  /**
   * id of the requisition
   */
  id: string;
  /**
   * case owning the requisition
   */
  case: RequisitionFragment_case | null;
  /**
   * location owning the requisition
   */
  department: RequisitionFragment_department | null;
  /**
   * driving slip owning the requisition
   */
  drivingSlip: RequisitionFragment_drivingSlip | null;
  /**
   * description of the requisition
   */
  description: string;
  /**
   * orderNumber of the requisition
   */
  orderNumber: string;
  /**
   * the type of the requisition
   */
  type: RequisitionType;
  /**
   * Requisition status
   */
  status: boolean;
  /**
   * the vendor of the requisition
   */
  vendor: RequisitionFragment_vendor | null;
  /**
   * Timestamp of when the requisition was created
   */
  createdAt: GQL_DateTime;
  /**
   * User who created the requisition
   */
  createdBy: RequisitionFragment_createdBy;
  /**
   * email of the vendor
   */
  vendorEmail: string | null;
  /**
   * phone number of the vendor
   */
  vendorPhoneNumber: string;
  /**
   * name of the vendor
   */
  vendorName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SalesInvoiceLineFragment
// ====================================================

export interface SalesInvoiceLineFragment {
  /**
   * System Id
   */
  systemId: string;
  /**
   * Document number (planningline reference)
   */
  documentNo: string | null;
  /**
   * Line identifier
   */
  lineNo: number | null;
  /**
   * Unique identifier
   */
  no: string | null;
  /**
   * line type
   */
  type: string | null;
  /**
   * Description
   */
  description: string | null;
  /**
   * Quantity
   */
  quantity: GQL_Decimal | null;
  /**
   * Unit of measure
   */
  uom: string | null;
  /**
   * Price of unit
   */
  unitPrice: GQL_Decimal | null;
  /**
   * Line discount amount
   */
  lineDiscountAmount: GQL_Decimal | null;
  /**
   * Line discount percentage
   */
  lineDiscountPercentage: GQL_Decimal | null;
  /**
   * Amount including vat Tax
   */
  amountIncludingVAT: GQL_Decimal | null;
  /**
   * Vat tax base amount
   */
  vatBaseAmount: GQL_Decimal | null;
  /**
   * Vat tax percentage
   */
  vatPercentage: GQL_Decimal | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TimedMessageChangesFragment
// ====================================================

export interface TimedMessageChangesFragment_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface TimedMessageChangesFragment_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface TimedMessageChangesFragment_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: TimedMessageChangesFragment_catalogs_addresses[];
}

export interface TimedMessageChangesFragment {
  customer: TimedMessageChangesFragment_customer | null;
  changedBy: string | null;
  startTime: GQL_DateTime | null;
  endTime: GQL_DateTime | null;
  message: string | null;
  global: boolean | null;
  globalType: CatalogGlobalType | null;
  active: boolean | null;
  catalogs: TimedMessageChangesFragment_catalogs[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Vehicle
// ====================================================

export interface Vehicle {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: VendorFragment
// ====================================================

export interface VendorFragment_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface VendorFragment {
  /**
   * ERP reference no for vendor
   */
  erpReferenceNo: string;
  /**
   * The name of the vendor
   */
  company: string | null;
  /**
   * The address of the vendor
   */
  address: VendorFragment_address | null;
  /**
   * The email of the vendor
   */
  email: string | null;
  /**
   * The phone number of the vendor
   */
  phone: string | null;
  /**
   * The posting group of the vendor
   */
  vendorPostingGroup: string;
  /**
   * Attached SSG department of the vendor
   */
  ssgDepartment: string;
  /**
   * Attached SSG location of the vendor
   */
  ssgLocation: string;
  /**
   * Vendor type
   */
  type: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DrivingSlipNewPlanner
// ====================================================

export interface DrivingSlipNewPlanner_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface DrivingSlipNewPlanner_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface DrivingSlipNewPlanner_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface DrivingSlipNewPlanner_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface DrivingSlipNewPlanner_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface DrivingSlipNewPlanner_case_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface DrivingSlipNewPlanner_case_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface DrivingSlipNewPlanner_case_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: DrivingSlipNewPlanner_case_debitor_shippingAddress_address;
}

export interface DrivingSlipNewPlanner_case_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface DrivingSlipNewPlanner_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: DrivingSlipNewPlanner_case_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: DrivingSlipNewPlanner_case_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: DrivingSlipNewPlanner_case_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface DrivingSlipNewPlanner_case_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface DrivingSlipNewPlanner_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface DrivingSlipNewPlanner_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface DrivingSlipNewPlanner_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface DrivingSlipNewPlanner_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface DrivingSlipNewPlanner_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: DrivingSlipNewPlanner_case_damage_cause_departments_location;
}

export interface DrivingSlipNewPlanner_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: DrivingSlipNewPlanner_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: DrivingSlipNewPlanner_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (DrivingSlipNewPlanner_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface DrivingSlipNewPlanner_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface DrivingSlipNewPlanner_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: DrivingSlipNewPlanner_case_damage_contact_address;
}

export interface DrivingSlipNewPlanner_case_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface DrivingSlipNewPlanner_case_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: DrivingSlipNewPlanner_case_damage_businessArea;
  /**
   * Damage category
   */
  category: DrivingSlipNewPlanner_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: DrivingSlipNewPlanner_case_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: DrivingSlipNewPlanner_case_damage_contact;
  /**
   * contacts for damage
   */
  contacts: DrivingSlipNewPlanner_case_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface DrivingSlipNewPlanner_case_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface DrivingSlipNewPlanner_case_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface DrivingSlipNewPlanner_case_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface DrivingSlipNewPlanner_case_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface DrivingSlipNewPlanner_case_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: DrivingSlipNewPlanner_case_policyHolder_address;
}

export interface DrivingSlipNewPlanner_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * Case debitor details
   */
  debitor: DrivingSlipNewPlanner_case_debitor;
  /**
   * Case damage details
   */
  damage: DrivingSlipNewPlanner_case_damage;
  /**
   * Project manager attached to case
   */
  projectManager: DrivingSlipNewPlanner_case_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: DrivingSlipNewPlanner_case_caseManager | null;
  /**
   * User who created the case
   */
  createdBy: DrivingSlipNewPlanner_case_createdBy | null;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * Case policy holder details
   */
  policyHolder: DrivingSlipNewPlanner_case_policyHolder;
}

export interface DrivingSlipNewPlanner {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: DrivingSlipNewPlanner_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: DrivingSlipNewPlanner_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: DrivingSlipNewPlanner_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Location of driving slip
   */
  location: DrivingSlipNewPlanner_location;
  /**
   * Department of driving slip
   */
  department: DrivingSlipNewPlanner_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Case which driving slip is related to
   */
  case: DrivingSlipNewPlanner_case;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Event
// ====================================================

export interface Event_timeRange {
  /**
   * From date
   */
  from: GQL_DateTime;
  /**
   * To date
   */
  to: GQL_DateTime;
}

export interface Event_drivingSlip_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface Event_drivingSlip_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface Event_drivingSlip_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface Event_drivingSlip_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface Event_drivingSlip_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface Event_drivingSlip_case_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface Event_drivingSlip_case_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface Event_drivingSlip_case_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: Event_drivingSlip_case_debitor_shippingAddress_address;
}

export interface Event_drivingSlip_case_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface Event_drivingSlip_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: Event_drivingSlip_case_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: Event_drivingSlip_case_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: Event_drivingSlip_case_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface Event_drivingSlip_case_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface Event_drivingSlip_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface Event_drivingSlip_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface Event_drivingSlip_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface Event_drivingSlip_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface Event_drivingSlip_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: Event_drivingSlip_case_damage_cause_departments_location;
}

export interface Event_drivingSlip_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: Event_drivingSlip_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: Event_drivingSlip_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (Event_drivingSlip_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface Event_drivingSlip_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface Event_drivingSlip_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: Event_drivingSlip_case_damage_contact_address;
}

export interface Event_drivingSlip_case_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface Event_drivingSlip_case_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: Event_drivingSlip_case_damage_businessArea;
  /**
   * Damage category
   */
  category: Event_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: Event_drivingSlip_case_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: Event_drivingSlip_case_damage_contact;
  /**
   * contacts for damage
   */
  contacts: Event_drivingSlip_case_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface Event_drivingSlip_case_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface Event_drivingSlip_case_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface Event_drivingSlip_case_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface Event_drivingSlip_case_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface Event_drivingSlip_case_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: Event_drivingSlip_case_policyHolder_address;
}

export interface Event_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * Case debitor details
   */
  debitor: Event_drivingSlip_case_debitor;
  /**
   * Case damage details
   */
  damage: Event_drivingSlip_case_damage;
  /**
   * Project manager attached to case
   */
  projectManager: Event_drivingSlip_case_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: Event_drivingSlip_case_caseManager | null;
  /**
   * User who created the case
   */
  createdBy: Event_drivingSlip_case_createdBy | null;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * Case policy holder details
   */
  policyHolder: Event_drivingSlip_case_policyHolder;
}

export interface Event_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: Event_drivingSlip_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: Event_drivingSlip_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: Event_drivingSlip_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Location of driving slip
   */
  location: Event_drivingSlip_location;
  /**
   * Department of driving slip
   */
  department: Event_drivingSlip_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Case which driving slip is related to
   */
  case: Event_drivingSlip_case;
}

export interface Event {
  /**
   * Unique calendar reference id
   */
  referenceId: string;
  /**
   * Calendar event title
   */
  title: string;
  /**
   * Type of calendar event
   */
  type: CalendarEventType;
  /**
   * Calendar event time range
   */
  timeRange: Event_timeRange;
  /**
   * Calendar event time range
   */
  isAllDay: boolean;
  /**
   * Indicates if calendar event was created through 5C
   */
  systemCreatedEvent: boolean;
  /**
   * Optional unique driving slip id
   */
  drivingSlip: Event_drivingSlip | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseTimeTrackingEntry
// ====================================================

export interface BaseTimeTrackingEntry_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface BaseTimeTrackingEntry_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Company identifier
   */
  company: string;
}

export interface BaseTimeTrackingEntry_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface BaseTimeTrackingEntry_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface BaseTimeTrackingEntry_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
}

export interface BaseTimeTrackingEntry_case_damage_contact {
  /**
   * Contact address information
   */
  address: BaseTimeTrackingEntry_case_damage_contact_address;
}

export interface BaseTimeTrackingEntry_case_damage {
  /**
   * Damage category
   */
  category: BaseTimeTrackingEntry_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: BaseTimeTrackingEntry_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: BaseTimeTrackingEntry_case_damage_contact;
}

export interface BaseTimeTrackingEntry_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: BaseTimeTrackingEntry_case_debitor;
  /**
   * Case damage details
   */
  damage: BaseTimeTrackingEntry_case_damage;
}

export interface BaseTimeTrackingEntry_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface BaseTimeTrackingEntry_lastModifiedBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface BaseTimeTrackingEntry_addonLines_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface BaseTimeTrackingEntry_addonLines_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Company identifier
   */
  company: string;
}

export interface BaseTimeTrackingEntry_addonLines_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: BaseTimeTrackingEntry_addonLines_case_debitor;
}

export interface BaseTimeTrackingEntry_addonLines {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: BaseTimeTrackingEntry_addonLines_user;
  userId: string;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: BaseTimeTrackingEntry_addonLines_case | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Was the task on callback
   */
  onCallback: boolean;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * bcStatus
   */
  bcStatus: TimeTrackingStatus;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
}

export interface BaseTimeTrackingEntry {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: BaseTimeTrackingEntry_user;
  userId: string;
  /**
   * Start date for time tracking
   */
  startDate: GQL_Date;
  /**
   * End date for time tracking
   */
  endDate: GQL_Date;
  /**
   * Start time for time tracking
   */
  startTime: string;
  /**
   * End time for time tracking
   */
  endTime: string;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: BaseTimeTrackingEntry_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: BaseTimeTrackingEntry_drivingSlip | null;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Was the task on callback
   */
  onCallback: boolean;
  /**
   * Does the entry include lunch
   */
  includesLunch: boolean;
  /**
   * task
   */
  task: TimeTrackingEntryTask;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * BC status
   */
  bcStatus: TimeTrackingStatus | null;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Timestamp of when the entry was last modified
   */
  lastModifiedAt: GQL_DateTime | null;
  /**
   * User who last modified the entry
   */
  lastModifiedBy: BaseTimeTrackingEntry_lastModifiedBy | null;
  /**
   * Add on supplementLines
   */
  addonLines: BaseTimeTrackingEntry_addonLines[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseSupplement
// ====================================================

export interface BaseSupplement_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface BaseSupplement_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Company identifier
   */
  company: string;
}

export interface BaseSupplement_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: BaseSupplement_case_debitor;
}

export interface BaseSupplement {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: BaseSupplement_user;
  userId: string;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: BaseSupplement_case | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Was the task on callback
   */
  onCallback: boolean;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * bcStatus
   */
  bcStatus: TimeTrackingStatus;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TimeTrackingDay
// ====================================================

export interface TimeTrackingDay_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User employee id
   */
  employeeId: string | null;
}

export interface TimeTrackingDay_timeTrackingEntries_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface TimeTrackingDay_timeTrackingEntries_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Company identifier
   */
  company: string;
}

export interface TimeTrackingDay_timeTrackingEntries_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface TimeTrackingDay_timeTrackingEntries_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface TimeTrackingDay_timeTrackingEntries_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
}

export interface TimeTrackingDay_timeTrackingEntries_case_damage_contact {
  /**
   * Contact address information
   */
  address: TimeTrackingDay_timeTrackingEntries_case_damage_contact_address;
}

export interface TimeTrackingDay_timeTrackingEntries_case_damage {
  /**
   * Damage category
   */
  category: TimeTrackingDay_timeTrackingEntries_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: TimeTrackingDay_timeTrackingEntries_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: TimeTrackingDay_timeTrackingEntries_case_damage_contact;
}

export interface TimeTrackingDay_timeTrackingEntries_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: TimeTrackingDay_timeTrackingEntries_case_debitor;
  /**
   * Case damage details
   */
  damage: TimeTrackingDay_timeTrackingEntries_case_damage;
}

export interface TimeTrackingDay_timeTrackingEntries_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface TimeTrackingDay_timeTrackingEntries_lastModifiedBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface TimeTrackingDay_timeTrackingEntries_addonLines_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface TimeTrackingDay_timeTrackingEntries_addonLines_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Company identifier
   */
  company: string;
}

export interface TimeTrackingDay_timeTrackingEntries_addonLines_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: TimeTrackingDay_timeTrackingEntries_addonLines_case_debitor;
}

export interface TimeTrackingDay_timeTrackingEntries_addonLines {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: TimeTrackingDay_timeTrackingEntries_addonLines_user;
  userId: string;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: TimeTrackingDay_timeTrackingEntries_addonLines_case | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Was the task on callback
   */
  onCallback: boolean;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * bcStatus
   */
  bcStatus: TimeTrackingStatus;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
}

export interface TimeTrackingDay_timeTrackingEntries {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: TimeTrackingDay_timeTrackingEntries_user;
  userId: string;
  /**
   * Start date for time tracking
   */
  startDate: GQL_Date;
  /**
   * End date for time tracking
   */
  endDate: GQL_Date;
  /**
   * Start time for time tracking
   */
  startTime: string;
  /**
   * End time for time tracking
   */
  endTime: string;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: TimeTrackingDay_timeTrackingEntries_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: TimeTrackingDay_timeTrackingEntries_drivingSlip | null;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Was the task on callback
   */
  onCallback: boolean;
  /**
   * Does the entry include lunch
   */
  includesLunch: boolean;
  /**
   * task
   */
  task: TimeTrackingEntryTask;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * BC status
   */
  bcStatus: TimeTrackingStatus | null;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Timestamp of when the entry was last modified
   */
  lastModifiedAt: GQL_DateTime | null;
  /**
   * User who last modified the entry
   */
  lastModifiedBy: TimeTrackingDay_timeTrackingEntries_lastModifiedBy | null;
  /**
   * Add on supplementLines
   */
  addonLines: TimeTrackingDay_timeTrackingEntries_addonLines[];
}

export interface TimeTrackingDay {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * Type of open, closed or rejected
   */
  timeTrackingDayStatus: TimeTrackingDayStatus;
  /**
   * AD user
   */
  user: TimeTrackingDay_user;
  userId: string;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Total hours
   */
  totalHours: number;
  /**
   * Total Overtime hours
   */
  overtimeHours: number;
  /**
   * Indicates if day is part of range
   */
  isPartOfRange: boolean;
  /**
   * Start date for range
   */
  rangeStartDate: GQL_Date | null;
  /**
   * End date for range
   */
  rangeEndDate: GQL_Date | null;
  /**
   * Time Tracking Entries
   */
  timeTrackingEntries: TimeTrackingDay_timeTrackingEntries[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CalendarEventType {
  DRIVING_SLIP = "DRIVING_SLIP",
  EDUCATION = "EDUCATION",
  ON_CALL = "ON_CALL",
  SICK_LEAVE = "SICK_LEAVE",
  SLEEP = "SLEEP",
  UNKNOWN = "UNKNOWN",
  VACATION = "VACATION",
}

export enum CaseAgreementCategory {
  CAUSE = "CAUSE",
  FURTHER_ACTION = "FURTHER_ACTION",
  OTHER = "OTHER",
  PRIVATE = "PRIVATE",
  SCOPE = "SCOPE",
  WORK_COMPLETED = "WORK_COMPLETED",
}

export enum CasePriority {
  LOSS_OF_BUSINESS_EARNING_CAPACITY = "LOSS_OF_BUSINESS_EARNING_CAPACITY",
  RESIDENTIAL_AREA = "RESIDENTIAL_AREA",
  SOCIAL_IMPACT = "SOCIAL_IMPACT",
}

export enum CaseStatus {
  APPLIED_CLOSED = "APPLIED_CLOSED",
  CLOSED = "CLOSED",
  OPEN = "OPEN",
  _NULL_ = "_NULL_",
}

export enum CatalogContacts {
  CLOSED_CONTACTS = "CLOSED_CONTACTS",
  CLOSED_CONTACTS_MAIL_CONTACTS = "CLOSED_CONTACTS_MAIL_CONTACTS",
  MAIL_CONTACTS = "MAIL_CONTACTS",
  OPEN_CONTACTS = "OPEN_CONTACTS",
  OPEN_CONTACTS_CLOSED_CONTACTS = "OPEN_CONTACTS_CLOSED_CONTACTS",
  OPEN_CONTACTS_CLOSED_CONTACTS_MAIL_CONTACTS = "OPEN_CONTACTS_CLOSED_CONTACTS_MAIL_CONTACTS",
  OPEN_CONTACTS_MAIL_CONTACTS = "OPEN_CONTACTS_MAIL_CONTACTS",
}

export enum CatalogGlobalType {
  CATALOGS_GLOBAL = "CATALOGS_GLOBAL",
  CUSTOMER_GLOBAL = "CUSTOMER_GLOBAL",
  NOT_GLOBAL = "NOT_GLOBAL",
}

export enum CollectionItemType {
  ACCESS_CONDITION = "ACCESS_CONDITION",
  CATALOG_CRAFTSMEN_PRIORITY = "CATALOG_CRAFTSMEN_PRIORITY",
  CATALOG_CRAFTSMEN_TYPE = "CATALOG_CRAFTSMEN_TYPE",
  DAMAGE_BOX_DESCRIPTION = "DAMAGE_BOX_DESCRIPTION",
  DAMAGE_BOX_REQUESTER_RELATIONSHIP = "DAMAGE_BOX_REQUESTER_RELATIONSHIP",
  DAMAGE_CONTACTS_ROLE = "DAMAGE_CONTACTS_ROLE",
  NOT_CASE_RELATED_WORK = "NOT_CASE_RELATED_WORK",
  VISITATION_DESCRIPTION_AWAIT = "VISITATION_DESCRIPTION_AWAIT",
  VISITATION_DESCRIPTION_CALL_BACK = "VISITATION_DESCRIPTION_CALL_BACK",
}

export enum DebitorType {
  AGRICULTURE = "AGRICULTURE",
  BUSINESS = "BUSINESS",
  PRIVATE = "PRIVATE",
  UNKNOWN = "UNKNOWN",
}

export enum DrivingSlipStatus {
  ARRIVED = "ARRIVED",
  COMPLETED = "COMPLETED",
  CONVERTED_TO_REQUISITION = "CONVERTED_TO_REQUISITION",
  OBSOLETE = "OBSOLETE",
  ON_HOLD = "ON_HOLD",
  PLANNED = "PLANNED",
  STARTED = "STARTED",
  UNPLANNED = "UNPLANNED",
  _NULL_ = "_NULL_",
}

export enum FileActionType {
  ADD = "ADD",
  REMOVE = "REMOVE",
  UPDATE = "UPDATE",
}

/**
 * Metadata fields that can be set in Sharepoint.
 */
export enum FileMetadataField {
  Comments = "Comments",
  DocumentType = "DocumentType",
  IsFacadePicture = "IsFacadePicture",
  QuestionnaireSection = "QuestionnaireSection",
  ReportName = "ReportName",
  ReportSection = "ReportSection",
}

export enum HouseNumbers {
  BOTH = "BOTH",
  EQUAL = "EQUAL",
  ODD = "ODD",
}

export enum InvoiceCategory {
  ACCOUNT = "ACCOUNT",
  FINAL = "FINAL",
  SCREENING = "SCREENING",
  SUB = "SUB",
  TRACK_ONE = "TRACK_ONE",
}

export enum InvoiceStatus {
  APPROVED = "APPROVED",
  AWAITING_APPROVAL = "AWAITING_APPROVAL",
  DRAFT = "DRAFT",
  POSTED = "POSTED",
  REJECTED = "REJECTED",
}

export enum JobType {
  CASE = "CASE",
  GENERAL = "GENERAL",
  MOVABLE = "MOVABLE",
}

export enum MachineConsumptionType {
  DAYS = "DAYS",
  HOURS = "HOURS",
  KWH = "KWH",
  NONE = "NONE",
}

export enum MachineStatus {
  ALL = "ALL",
  AVAILABLE = "AVAILABLE",
  DEPLOYED = "DEPLOYED",
}

export enum NotificationTrigger {
  CASE_CLOSED = "CASE_CLOSED",
  CASE_CLOSED_REQUEST = "CASE_CLOSED_REQUEST",
  CASE_CREATED = "CASE_CREATED",
  CASE_CREATED_ANONYMOUS = "CASE_CREATED_ANONYMOUS",
  CASE_CREATED_EXTERNAL_PARTNER = "CASE_CREATED_EXTERNAL_PARTNER",
  CASE_ESTIMATE_EDITED = "CASE_ESTIMATE_EDITED",
  CASE_MASTER_DATA_EDITED = "CASE_MASTER_DATA_EDITED",
  CASE_MATERIAL_PURCHASED = "CASE_MATERIAL_PURCHASED",
  CASE_REQUISITION_CREATED = "CASE_REQUISITION_CREATED",
  CASE_SEND_EMAIL = "CASE_SEND_EMAIL",
  CATALOG_CREATED = "CATALOG_CREATED",
  CATALOG_DELETED = "CATALOG_DELETED",
  CATALOG_EDITED = "CATALOG_EDITED",
  DEPARTMENT_REQUISITION_CREATED = "DEPARTMENT_REQUISITION_CREATED",
  DRIVING_SLIP_ASSIGNED = "DRIVING_SLIP_ASSIGNED",
  DRIVING_SLIP_EDITED = "DRIVING_SLIP_EDITED",
  DRIVING_SLIP_REMOVED = "DRIVING_SLIP_REMOVED",
  INVOICE_APPROVAL_REQUESTED = "INVOICE_APPROVAL_REQUESTED",
  INVOICE_APPROVED = "INVOICE_APPROVED",
  INVOICE_REJECTED = "INVOICE_REJECTED",
  JOB_PERSONAL_ASSIGNED = "JOB_PERSONAL_ASSIGNED",
  JOB_PERSONAL_DEADLINE = "JOB_PERSONAL_DEADLINE",
  MACHINE_HIBERNATION = "MACHINE_HIBERNATION",
  MACHINE_MOVED = "MACHINE_MOVED",
  TIME_TRACKING_ENTRY_DECLINED = "TIME_TRACKING_ENTRY_DECLINED",
  WARNING_CASE_ESTIMATE_EXCEEDED = "WARNING_CASE_ESTIMATE_EXCEEDED",
  WARNING_CASE_MILESTONE_MISSED = "WARNING_CASE_MILESTONE_MISSED",
  WARNING_MACHINE_OVERDUE = "WARNING_MACHINE_OVERDUE",
}

export enum OfferLineStatus {
  CREATE = "CREATE",
  DELETE = "DELETE",
  NONE = "NONE",
  UPDATE = "UPDATE",
}

export enum OfferQuoteTypeEnum {
  ESTIMATE = "ESTIMATE",
  GUESSTIMATE = "GUESSTIMATE",
  QUOTE = "QUOTE",
}

export enum OfferRessourceType {
  GL_ACCOUNT = "GL_ACCOUNT",
  ITEM = "ITEM",
  RESOURCE = "RESOURCE",
  TEXT = "TEXT",
}

export enum Permissions {
  ADMINISTRATION_CATALOGS_OWNER = "ADMINISTRATION_CATALOGS_OWNER",
  ADMINISTRATION_GDPR = "ADMINISTRATION_GDPR",
  ADMINISTRATION_SITE_VIEW = "ADMINISTRATION_SITE_VIEW",
  ADMINISTRATION_USER_MANAGEMENT = "ADMINISTRATION_USER_MANAGEMENT",
  ADMINISTRATION_WORKPLACE_ASSESSMENT = "ADMINISTRATION_WORKPLACE_ASSESSMENT",
  ALL_MACHINE_TOKEN = "ALL_MACHINE_TOKEN",
  CASES_AGREEMENTS_EDIT = "CASES_AGREEMENTS_EDIT",
  CASES_AGREEMENTS_PRIVATE = "CASES_AGREEMENTS_PRIVATE",
  CASES_CLOSE = "CASES_CLOSE",
  CASES_CREATE = "CASES_CREATE",
  CASES_DOCUMENTS_DELETE = "CASES_DOCUMENTS_DELETE",
  CASES_DOCUMENTS_VIEW = "CASES_DOCUMENTS_VIEW",
  CASES_DRIVING_SLIPS_EDIT = "CASES_DRIVING_SLIPS_EDIT",
  CASES_ECONOMY_INVOICES_EDIT = "CASES_ECONOMY_INVOICES_EDIT",
  CASES_ECONOMY_INVOICES_VIEW = "CASES_ECONOMY_INVOICES_VIEW",
  CASES_ECONOMY_VIEW = "CASES_ECONOMY_VIEW",
  CASES_EMAIL_EDIT = "CASES_EMAIL_EDIT",
  CASES_ESTIMATE_CALCULATION = "CASES_ESTIMATE_CALCULATION",
  CASES_EXTERNAL_SYSTEM_CREATE = "CASES_EXTERNAL_SYSTEM_CREATE",
  CASES_MASTER_DATA_EDIT = "CASES_MASTER_DATA_EDIT",
  CASES_MASTER_DATA_INVOICES_ADDRESS_VIEW = "CASES_MASTER_DATA_INVOICES_ADDRESS_VIEW",
  CASES_ONGOING_WORK_VIEW = "CASES_ONGOING_WORK_VIEW",
  CASES_OPEN = "CASES_OPEN",
  CASES_OWN_CASES_ONLY = "CASES_OWN_CASES_ONLY",
  CASES_RELATED_CASE_CREATE = "CASES_RELATED_CASE_CREATE",
  CASES_REPORT_EDIT = "CASES_REPORT_EDIT",
  CASES_USAGE_VIEW = "CASES_USAGE_VIEW",
  CASES_VIEW = "CASES_VIEW",
  CASES_VIEW_EXTERNAL = "CASES_VIEW_EXTERNAL",
  CASES_WORKPLACE_ASSESSMENT = "CASES_WORKPLACE_ASSESSMENT",
  CATALOGS_DEBITOR_VERIFY = "CATALOGS_DEBITOR_VERIFY",
  CATALOGS_DELETE = "CATALOGS_DELETE",
  CATALOGS_EDIT = "CATALOGS_EDIT",
  CATALOGS_INFORMATION_BOX_EDIT = "CATALOGS_INFORMATION_BOX_EDIT",
  CATALOGS_VERIFY = "CATALOGS_VERIFY",
  CATALOGS_VIEW = "CATALOGS_VIEW",
  DRIVING_SLIPS_VIEW = "DRIVING_SLIPS_VIEW",
  KP_IS_VIEW = "KP_IS_VIEW",
  MACHINES_EDIT = "MACHINES_EDIT",
  MACHINES_VIEW = "MACHINES_VIEW",
  MOVABLES_EDIT = "MOVABLES_EDIT",
  MOVABLES_VIEW = "MOVABLES_VIEW",
  MY_PAGE_VIEW = "MY_PAGE_VIEW",
  NONE = "NONE",
  PLANNING_EDIT = "PLANNING_EDIT",
  PLANNING_VIEW = "PLANNING_VIEW",
  REQUISITION_EDIT = "REQUISITION_EDIT",
  REQUISITION_VIEW = "REQUISITION_VIEW",
  TASKS_EDIT = "TASKS_EDIT",
  TASKS_VIEW = "TASKS_VIEW",
  TIME_TRACKING = "TIME_TRACKING",
  TIME_TRACKING_APPROVER = "TIME_TRACKING_APPROVER",
  TIME_TRACKING_DATE_PICKER = "TIME_TRACKING_DATE_PICKER",
  TIME_TRACKING_RESPONSIBLE = "TIME_TRACKING_RESPONSIBLE",
}

export enum QuestionnaireChoiceType {
  DROPDOWN = "DROPDOWN",
  FREE_TEXT = "FREE_TEXT",
  NUMBER = "NUMBER",
  RADIO_GROUP = "RADIO_GROUP",
  SELECT_OPTION = "SELECT_OPTION",
  _NULL_ = "_NULL_",
}

export enum QuestionnaireCompletionStatus {
  COMPLETED = "COMPLETED",
  NOT_COMPLETE = "NOT_COMPLETE",
  _NULL_ = "_NULL_",
}

export enum ReportFormatType {
  PDF = "PDF",
  WORD = "WORD",
}

export enum RequisitionType {
  MATERIAL = "MATERIAL",
  SUBCONTRACTOR = "SUBCONTRACTOR",
}

export enum RiskEvaluationStatus {
  ACCEPTED = "ACCEPTED",
  NOT_ACCEPTED = "NOT_ACCEPTED",
  _NULL_ = "_NULL_",
}

export enum SalesHeaderEnum {
  ACTIVATE = "ACTIVATE",
  CANCEL = "CANCEL",
  UPDATE = "UPDATE",
}

export enum ScalePointStatus {
  APPROVED = "APPROVED",
  CONNECTED = "CONNECTED",
  ERRORED = "ERRORED",
  NOT_CONNECTED = "NOT_CONNECTED",
  REJECTED = "REJECTED",
}

export enum ScreeningLineType {
  AMOUNT = "AMOUNT",
  BOOLEAN = "BOOLEAN",
  DECIMAL = "DECIMAL",
  FEE = "FEE",
  HEADING = "HEADING",
  INTEGER = "INTEGER",
  RADIO = "RADIO",
  RESOURCE = "RESOURCE",
}

export enum SupplementType {
  PIECEWORK = "PIECEWORK",
  STAND_ALONE = "STAND_ALONE",
  SURCHARGE = "SURCHARGE",
  TIMEREGISTRATION = "TIMEREGISTRATION",
}

export enum TimeTrackingDayStatus {
  CLOSED = "CLOSED",
  OPEN = "OPEN",
  OPEN_REJECTED = "OPEN_REJECTED",
  REJECTED = "REJECTED",
}

export enum TimeTrackingEntryTask {
  CASE = "CASE",
  DRIVINGSLIP = "DRIVINGSLIP",
  INTERN = "INTERN",
}

export enum TimeTrackingStatus {
  APPROVED = "APPROVED",
  AWAITING = "AWAITING",
  OPEN = "OPEN",
  REJECTED = "REJECTED",
  SUBMITTED = "SUBMITTED",
}

export enum TrackingType {
  DAYCLOSED = "DAYCLOSED",
  REJECTEDDAY = "REJECTEDDAY",
  TIMEREGISTRATION = "TIMEREGISTRATION",
}

export enum UserCaseFilterViewType {
  ALL = "ALL",
  FAVORITES = "FAVORITES",
  INVOICE_READY = "INVOICE_READY",
  NOT_PLANNED = "NOT_PLANNED",
  OWN = "OWN",
}

export interface AIInvoicePredictionInputType {
  caseErpNo: string;
  caseId: string;
  invoiceERPNo: string;
  profitability: number;
  drivingSlipComments: string;
  screeningTemplateId: string;
  screeningTemplateName: string;
  screeningTemplateCode: string;
  damageCauseName: string;
  damageDescription: string;
  caseAgreementCause: string;
  caseAgreementFurtherAction: string;
  caseAgreementOther: string;
  caseAgreementScope: string;
  caseAgreementWorkCompleted: string;
  invoiceLinesJobTaskDescription: (string | null)[];
  invoiceLinesInfo: (InvoiceLinesInfoInputType | null)[];
  distance: boolean;
  urgent: boolean;
}

export interface AddressInput {
  road: string;
  houseNumber: string;
  floor?: string | null;
  addressLine?: string | null;
  addressLineAlt?: string | null;
  postalCode: string;
  city: string;
  country?: string | null;
}

export interface AirMeassurementDetailsInput {
  temperatureInside: number;
  temperatureOutside: number;
  relativeHumidityInside: number;
  relativeHumidityOutside: number;
  waterAmountInside: number;
  waterAmountOutside: number;
}

export interface AnswerInput {
  question: string;
  answer: string;
}

export interface BoolWithCommentInput {
  value: boolean;
  comment?: string | null;
}

export interface CalendarEventInput {
  title: string;
  type: CalendarEventType;
  drivingSlip?: string | null;
  timeRange: DateTimeRangeInput;
  isAllDay?: boolean | null;
}

export interface CaseAgreementInput {
  content: string;
  category: CaseAgreementCategory;
  shouldSaveInScalePoint: boolean;
}

export interface CaseInput {
  track?: number | null;
  debitor: DebitorInput;
  damage: DamageInput;
  policyHolder: ContactInput;
  visitation: VisitationInput;
  requisitioner: RequisitionerInput;
  adviser?: CompanyContactInput | null;
  referrer?: CompanyContactInput | null;
  alternativeContact?: PersonInput | null;
  projectManager?: string | null;
  caseManager?: string | null;
  ssgLocation: string;
  ssgDepartment: string;
  reportSent?: boolean | null;
  skafor: boolean;
  importantInfo?: string | null;
  riskEvaluationAnswers?: RiskEvaluationAnswerInput[] | null;
  riskEvaluationComment?: string | null;
  machineNotification?: boolean | null;
  timeRegistration?: boolean | null;
}

export interface CaseMigration {
  erpNo: string;
  createdAt: GQL_DateTime;
  createdBy: string;
  arrived?: boolean | null;
  arrivedDate?: GQL_DateTime | null;
}

export interface CatalogAddressInput {
  addressLine: string;
  houseNumbers: HouseNumbers;
  startNumber: string;
  endNumber: string;
  zipCode: string;
  city: string;
  comment: string;
}

export interface CatalogBatchInput {
  propertyNumber: string;
  customer: string;
  changedBy: string;
  ssgAccountable?: string | null;
  description?: string | null;
  contactNumber?: string | null;
  contactHours?: string | null;
  addresses?: CatalogAddressInput[] | null;
  smsService: boolean;
  debitor?: string | null;
  verified: boolean;
  debitorVerified: boolean;
  id: string;
}

export interface CatalogContactInput {
  customer: string;
  changedBy: string;
  type?: string | null;
  contactType: CatalogContacts;
  contactName: string;
  informations: (CatalogPhoneInformationInput | null)[];
  email?: string | null;
  global: boolean;
  active: boolean;
  globalType: CatalogGlobalType;
  catalogs?: string[] | null;
}

export interface CatalogCraftsmanInput {
  customer: string;
  changedBy: string;
  type: string;
  priority: string;
  contactName: string;
  informations: (CatalogPhoneInformationInput | null)[];
  email?: string | null;
  globalType: CatalogGlobalType;
  global: boolean;
  active: boolean;
  catalogs?: string[] | null;
}

export interface CatalogInput {
  propertyNumber: string;
  customer: string;
  changedBy: string;
  ssgAccountable?: string | null;
  description?: string | null;
  contactNumber?: string | null;
  contactHours?: string | null;
  addresses?: CatalogAddressInput[] | null;
  smsService: boolean;
  debitor?: string | null;
  verified: boolean;
  debitorVerified: boolean;
}

export interface CatalogPhoneInformationInput {
  phoneNumber: string;
  remark?: string | null;
}

export interface CompanyContactInput {
  name: string;
  phone: string;
  email: string;
  comment?: string | null;
  companyName?: string | null;
  companyPhone?: string | null;
}

export interface ContactInput {
  name: string;
  phone: string;
  email: string;
  comment?: string | null;
  address: AddressInput;
}

export interface DamageContactInput {
  name: string;
  role: string;
  phone?: string | null;
  email?: string | null;
}

export interface DamageInput {
  businessArea: string;
  category: string;
  cause: string;
  date: GQL_Date;
  description: string;
  contact: ContactInput;
  accessConditions?: string | null;
  contacts?: DamageContactInput[] | null;
}

export interface DateRangeInput {
  from: GQL_Date;
  to: GQL_Date;
}

export interface DateTimeRangeInput {
  from: GQL_DateTime;
  to: GQL_DateTime;
}

export interface DebitorInput {
  type: DebitorType;
  company: string;
  attention?: string | null;
  shippingAddressReference?: string | null;
  billingAddress?: AddressInput | null;
  excess?: GQL_Decimal | null;
  policeNumber: string;
  cvrNumber: string;
  debitorId: string;
}

export interface DraftInvoiceLineInputType {
  systemId?: string | null;
  sortIndex: number;
  caseNo: string;
  documentNo: string;
  taskNo: string;
  type: string;
  attachDocument?: boolean | null;
  no?: string | null;
  description: string;
  quantity?: GQL_Decimal | null;
  newUnitPrice?: GQL_Decimal | null;
  planningDate: GQL_Date;
  workTypeCode: string;
  excludeInEnvFee?: boolean | null;
  createdBy: string;
}

export interface DriverCarInput {
  driver: string;
  car: string;
}

export interface DrivingSlipHelperInput {
  input: DrivingSlipInput;
  immediatelyPlan: boolean;
  additionalDays: GQL_Date[];
  additionalDrivers: string[];
  driverCars: DriverCarInput[];
}

export interface DrivingSlipInput {
  series?: string | null;
  urgent: boolean;
  driver?: string | null;
  estimatedHours?: GQL_Decimal | null;
  start: GQL_DateTime;
  end: GQL_DateTime;
  deadline?: GQL_DateTime | null;
  comment?: string | null;
  location: string;
  department: string;
  category?: string | null;
  facadePictureTaken?: boolean | null;
  plannerMessage?: string | null;
  car?: string | null;
  materials?: string[] | null;
  halfDay?: boolean | null;
  passenger?: boolean | null;
}

export interface FileFilterInput {
  filterName: string;
  filterQuery: string;
}

export interface FileInput {
  filename: string;
  fileData: GQL_Byte[];
  fileMetadata?: FileMetadataInput[] | null;
}

export interface FileMetadataInput {
  key: FileMetadataField;
  value: string;
}

export interface FloorMoistureReportFormDataInput {
  name: string;
  observations: MoistureReportObservationsInput;
  moistureMeassurements?: MoistureMeassurementInput[] | null;
  floorplanImage?: ReportFileInput | null;
  otherImages?: ReportFileInput[] | null;
}

export interface FloorMoistureTrygReportFormDataInput {
  name?: string | null;
  damageCause?: string | null;
  multipleDamageCauses?: string | null;
  moistureDamageCause?: string | null;
  damageDescription?: string | null;
  floorplanImage?: ReportFileInput | null;
  moistureMeassurements?: TrygMoistureMeassurementInput[] | null;
  airMeassurements: AirMeassurementDetailsInput;
  damageDescriptionImage?: ReportFileInput[] | null;
  damageDrawingImage?: ReportFileInput | null;
  recommendations?: string | null;
  urgentActions?: string | null;
  otherImages?: ReportFileInput[] | null;
}

export interface GDPRInputType {
  userId: string;
  timestamp: GQL_DateTime;
}

export interface IndoorClimateMeassurementInput {
  room?: string | null;
  construction?: string | null;
  material?: string | null;
  description?: string | null;
  instrument?: string | null;
  value?: string | null;
  referenceValue?: string | null;
  calculatedValue?: string | null;
}

export interface IndoorClimateReportFormDataInput {
  facadeImage?: ReportFileInput | null;
  inspectionDate?: GQL_Date | null;
  purpose?: string | null;
  buildYear?: string | null;
  squareMeters?: string | null;
  dailyUsers?: string | null;
  participants?: string | null;
  backgroundStory?: string | null;
  observations?: string | null;
  showLabAnswers?: boolean | null;
  showDataLog?: boolean | null;
  indoorClimateMeassurements?: IndoorClimateMeassurementInput[] | null;
  assessmentAndConclusion?: string | null;
  suggestedActionPlan?: string | null;
  floorplanImage?: ReportFileInput | null;
  otherImages?: ReportFileInput[] | null;
}

export interface InspectionReportFormDataInput {
  facadeImage?: ReportFileInput | null;
  backgroundStory?: string | null;
  observations?: string | null;
  assessmentAndConclusion?: string | null;
  suggestedActionPlan?: string | null;
  comments?: string | null;
  floorplanImage?: ReportFileInput | null;
  otherImages?: ReportFileInput[] | null;
}

export interface InvoiceLinesInfoInputType {
  id: string;
  description: string;
}

export interface JobInput {
  type: JobType;
  caseId?: string | null;
  description: string;
  deadline: GQL_DateTime;
  assignedToId?: string | null;
  locationId?: string | null;
  movableId?: string | null;
}

export interface MachineInput {
  erpReferenceNo: string;
  startingDate: string;
  startingTime: string;
  erpCaseReference: string;
  consumption: GQL_Decimal;
  hibernate: boolean;
  hibernationReason: string;
  unavailableReason: string;
  erpLocationReference: string;
  allocationType: string;
  reservationByRessource?: string | null;
  reservationDescription?: string | null;
  reservationOnCase?: string | null;
  reservationStart?: string | null;
  reservationEnd?: string | null;
  placementDescription?: string | null;
}

export interface MoistureMeassurementInput {
  construction?: string | null;
  material?: string | null;
  description?: string | null;
  method?: string | null;
  value: string;
  expectedFinalValue?: string | null;
  referenceValue?: string | null;
  referenceDate?: string | null;
  controlValue?: string | null;
  controlDate?: string | null;
}

export interface MoistureReportFormDataInput {
  floorMoistureReportFormDataInput?: FloorMoistureReportFormDataInput[] | null;
  facadeImage?: ReportFileInput | null;
  inspectionDate: GQL_Date;
  damageCause: string;
  scope: string;
  contacts: string;
  damageCauseImages?: ReportFileInput[] | null;
  conclusion?: string | null;
}

export interface MoistureReportObservationsInput {
  roomOrApartment: string;
  construction?: string | null;
  ceiling?: string | null;
  walls?: string | null;
  floor?: string | null;
  scope: string;
  recommendations: string;
}

export interface MoistureTrygReportFormDataInput {
  facadeImage?: ReportFileInput | null;
  inspectionDate?: GQL_Date | null;
  floorMoistureTrygReportData?: FloorMoistureTrygReportFormDataInput[] | null;
}

export interface MovableInput {
  id: string;
  description: string;
  volume: GQL_Decimal;
  case: string;
  status: string;
  placement: string;
}

export interface MovableSearchFiltersInputType {
  debitors?: (string | null)[] | null;
  offset?: number | null;
  includeCompleted: boolean;
  case: string;
  excludeTotalCount: boolean;
  fromDate?: GQL_DateTime | null;
  toDate?: GQL_DateTime | null;
  limit?: number | null;
  location: string;
  status: string;
}

export interface OfferInput {
  jobNo: string;
  lines: OfferLineInput[];
  oppertunityStatusCode?: string | null;
  headerText?: string | null;
  footerText?: string | null;
  quoteType: OfferQuoteTypeEnum;
  competitionOffer: boolean;
}

export interface OfferLineInput {
  caseERPReferenceNo: string;
  planningDate: string;
  description: string;
  quantity: GQL_Decimal;
  unitPrice: GQL_Decimal;
  newUnitPrice: GQL_Decimal;
  no: string;
  workType: string;
  documentNo?: string | null;
  systemId?: string | null;
  offerLineStatus: OfferLineStatus;
  eRPReferenceTask: string;
  type: OfferRessourceType;
  option: boolean;
  hideSum: boolean;
  header: boolean;
  sortingIndex: number;
  lineNo?: number | null;
}

export interface PersonInput {
  name: string;
  phone: string;
  email: string;
  comment?: string | null;
}

export interface ReportFileInput {
  fileName: string;
  description?: string | null;
}

export interface RequisitionInput {
  description: string;
  type: RequisitionType;
  vendorName: string;
  vendorPhoneNumber: string;
  vendorNo?: string | null;
  vendorEmail?: string | null;
}

export interface RequisitionerInput {
  name: string;
  phone: string;
  email: string;
  comment?: string | null;
  relation: string;
}

export interface RiskEvaluationAnswerInput {
  question: string;
  answer: boolean;
}

export interface ScreeningLineInputType {
  erpReferenceId: string;
  title: string;
  type: string;
  account: string;
  jobTask: string;
  price: GQL_Decimal;
  value: GQL_Decimal;
  movableVolume?: GQL_Decimal | null;
  movableDays?: GQL_Decimal | null;
  movableDateFrom?: string | null;
  movableDateTo?: string | null;
  excludeFromEnvFee?: boolean | null;
}

export interface TimeTrackingDayInputType {
  user: string;
  date: GQL_DateTime;
  isPartOfRange: boolean;
  rangeEndDate?: GQL_DateTime | null;
}

export interface TimeTrackingEntryInputType {
  user: string;
  trackingType: TrackingType;
  startDate: GQL_DateTime;
  endDate: GQL_DateTime;
  hours: number;
  startTime: string;
  endTime: string;
  case?: string | null;
  jobNo?: string | null;
  drivingSlip?: string | null;
  remark?: string | null;
  jobTaskNo?: string | null;
  onCall?: boolean | null;
  onCallback?: boolean | null;
  includesLunch?: boolean | null;
  jobTaskName?: string | null;
  workTypeCode?: string | null;
  timeTrackingStatus?: TimeTrackingStatus | null;
  supplementType?: SupplementType | null;
  pieceworkAmount?: number | null;
  paymentSupplementCode?: string | null;
  paymentSupplementName?: string | null;
  supplementUom?: string | null;
  addonLines?: TimeTrackingSupplementInputType[] | null;
}

export interface TimeTrackingEntryUpdateInputType {
  id: string;
  parentId: string;
  user: string;
  trackingType: TrackingType;
  date: GQL_DateTime;
  startDate: GQL_DateTime;
  endDate: GQL_DateTime;
  hours: number;
  startTime: string;
  endTime: string;
  case?: string | null;
  jobNo?: string | null;
  drivingSlip?: string | null;
  remark?: string | null;
  jobTaskNo?: string | null;
  onCall?: boolean | null;
  onCallback?: boolean | null;
  includesLunch?: boolean | null;
  jobTaskName?: string | null;
  workTypeCode?: string | null;
  timeTrackingStatus?: TimeTrackingStatus | null;
  supplementType?: SupplementType | null;
  pieceworkAmount?: number | null;
  paymentSupplementCode?: string | null;
  paymentSupplementName?: string | null;
  supplementUom?: string | null;
  addonLines?: TimeTrackingSupplementUpdateInputType[] | null;
}

export interface TimeTrackingInputType {
  user: string;
  trackingType: TrackingType;
  date: GQL_Date;
  hours: number;
  case?: string | null;
  jobNo?: string | null;
  drivingSlip?: string | null;
  remark?: string | null;
  jobTaskNo?: string | null;
  onCall?: boolean | null;
  jobTaskName?: string | null;
  workTypeCode?: string | null;
  timeTrackingStatus?: TimeTrackingStatus | null;
  supplementType?: SupplementType | null;
  pieceworkAmount?: number | null;
  paymentSupplementCode?: string | null;
  paymentSupplementName?: string | null;
  supplementUom?: string | null;
  addonLines?: TimeTrackingInputType[] | null;
}

export interface TimeTrackingSupplementInputType {
  user: string;
  date: GQL_Date;
  hours: number;
  case?: string | null;
  jobNo?: string | null;
  drivingSlip?: string | null;
  remark?: string | null;
  jobTaskNo?: string | null;
  onCall?: boolean | null;
  onCallback?: boolean | null;
  jobTaskName?: string | null;
  workTypeCode?: string | null;
  timeTrackingStatus?: TimeTrackingStatus | null;
  supplementType?: SupplementType | null;
  pieceworkAmount?: number | null;
  paymentSupplementCode?: string | null;
  paymentSupplementName?: string | null;
  supplementUom?: string | null;
}

export interface TimeTrackingSupplementUpdateInputType {
  id?: string | null;
  parentId: string;
  user: string;
  date: GQL_Date;
  hours: number;
  case?: string | null;
  jobNo?: string | null;
  drivingSlip?: string | null;
  remark?: string | null;
  jobTaskNo?: string | null;
  onCall?: boolean | null;
  onCallback?: boolean | null;
  jobTaskName?: string | null;
  workTypeCode?: string | null;
  timeTrackingStatus?: TimeTrackingStatus | null;
  supplementType?: SupplementType | null;
  pieceworkAmount?: number | null;
  paymentSupplementCode?: string | null;
  paymentSupplementName?: string | null;
  supplementUom?: string | null;
}

export interface TimedMessageInput {
  customer: string;
  changedBy: string;
  message: string;
  startTime: GQL_DateTime;
  endTime: GQL_DateTime;
  global: boolean;
  active: boolean;
  globalType: CatalogGlobalType;
  catalogs?: string[] | null;
}

export interface TrygMoistureMeassurementInput {
  construction?: string | null;
  material?: string | null;
  description?: string | null;
  method?: string | null;
  paints?: string | null;
  paintsDate?: string | null;
  expectedFinalValue?: string | null;
  expectedFinalDate?: string | null;
  referenceValue?: string | null;
  referenceDate?: string | null;
  controlValue?: string | null;
  controlDate?: string | null;
}

export interface UserCaseFilterInput {
  name: string;
  view: UserCaseFilterViewType;
  awaiting?: boolean | null;
  damageServiceCompleted?: boolean | null;
  calledBack?: boolean | null;
  machinesOnCase?: boolean | null;
  showEconomyField: boolean;
  showBusinessAreaField: boolean;
  showAppliedClosedCases: boolean;
  showClosedCases: boolean;
  debitors: string[];
  postalCodeText: string;
  postalCodes: string[];
  track: number[];
  departments: string[];
  locations: string[];
  damageCategories: string[];
  damageCauses: string[];
  priority: CasePriority[];
  caseManagers: string[];
  projectManagers: string[];
}

export interface UserDrivingSlipFilterInput {
  name: string;
  locations: string[];
  departments: string[];
  debitors: string[];
  postalCodeText: string;
  postalCodes: string[];
  damageCategories: string[];
  damageCauses: string[];
  erpNos: string[];
  projectManagers: string[];
}

export interface UserDrivingSlipOverviewFilterInput {
  name: string;
  status?: DrivingSlipStatus | null;
  urgent?: boolean | null;
  exceeded?: boolean | null;
  departments: string[];
  locations: string[];
  damageCauses: string[];
  damageCategories: string[];
  drivers: string[];
  noDriver?: boolean | null;
}

export interface UserJobFilterInput {
  name: string;
  location: string;
  personalOnly: boolean;
}

export interface UserMachineFilterInput {
  name: string;
  status?: MachineStatus | null;
  cases?: string[] | null;
  locations?: string[] | null;
  types?: string[] | null;
  unavailable?: boolean | null;
  hibernation?: boolean | null;
}

export interface UserMovableFilterInput {
  name: string;
  erpNo: string;
  location: string;
  status: string;
  includeCompleted: boolean;
  debitors?: string[] | null;
}

export interface UserSanitizerFilterInput {
  name: string;
  locations: string[];
  departments: string[];
  jobFunctions: string[];
  sanitizers: string[];
}

export interface UserVehicleFilterInput {
  name: string;
  locations: string[];
  departments: string[];
  cars: string[];
}

export interface ValidationRuleBypassInput {
  rule: string;
  reasonCode: string;
  comment?: string | null;
}

export interface VisitationInput {
  urgent: boolean;
  priorities: CasePriority[];
  regress?: boolean | null;
  isMoistureReportRequired?: boolean | null;
  isSpPhaseOneReportCreated?: boolean | null;
  calledBack: BoolWithCommentInput;
  awaiting: BoolWithCommentInput;
  answers?: AnswerInput[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
