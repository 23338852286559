import React from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { faFileExcel } from '@fortawesome/pro-regular-svg-icons';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { CaseUsageFragment, GetCaseUsage, GetCaseUsageExcelOverview, GetCaseUsageExcelOverviewVariables, GetCaseUsageVariables, GetSingleCase_case_drivingSlipSeries_drivingSlips, GetSingleCase_case_drivingSlipSeries_drivingSlips_materials } from '../../../GraphQL';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import Table from '@ssg/common/Components/Table';
import Tabs from '@ssg/common/Components/Tabs';
import addThousandSeperator from '@ssg/common/Helpers/addThousandSeperator';
import useSaveFile from '@ssg/common/Hooks/useSaveFile';
import Box from '../../../Components/Layout/Box';

const GET_USAGE_EXCEL = loader('src/GraphQL/Cases/GetCaseUsageExcelOverview.gql');
const GET_CASE_USAGE = loader('src/GraphQL/Cases/GetCaseUsage.gql');

interface Props {
	caseErpNo: string;
	caseViewExternal: boolean;
	drivingSlipsWithMaterials: GetSingleCase_case_drivingSlipSeries_drivingSlips[];
}

type MaterialAndDrivingSlip = { material: GetSingleCase_case_drivingSlipSeries_drivingSlips_materials, drivingSlip: Pick<GetSingleCase_case_drivingSlipSeries_drivingSlips, 'id' | 'driver' | 'start' | 'end'> };

const tabs = ['case.usage.resources', 'common.materiale', 'common.machines', 'common.movables', 'common.subcontracts'];

const CaseUsageBox: React.FC<Props> = ({ caseErpNo, caseViewExternal, drivingSlipsWithMaterials }): React.ReactElement => {
	const { t } = useTranslation();
	const [activeTab, setActiveTab] = React.useState(tabs[0]);
	const newPlannerVehiclesFlag = useFlag(FeatureFlagEnums.NEW_PLANNER_VEHICLES);
	const registerTrashWithUnits = useFlag(FeatureFlagEnums.REGISTER_TRASH_WITH_UNITS);

	const [fetchData, { data, loading }] = useLazyQuery<GetCaseUsageExcelOverview, GetCaseUsageExcelOverviewVariables>(GET_USAGE_EXCEL, {
		variables: { erpNo: caseErpNo },
	});

	const { data: caseUsage, loading: loadingCaseUsage } = useQuery<GetCaseUsage, GetCaseUsageVariables>(GET_CASE_USAGE, {
		context: { debatch: true },
		variables: {
			erpNo: caseErpNo,
		},
	});

	useSaveFile(data?.caseUsageExcelOverview, `${t('case.usage.overviewTitle')} ${caseErpNo}.xlsx`);

	const count = [
		caseUsage?.caseUsage.persons.length ?? 0,
		caseUsage?.caseUsage.items.length ?? 0,
		caseUsage?.caseUsage.machines.length ?? 0,
		caseUsage?.caseUsage.movables.length ?? 0,
		caseUsage?.caseUsage.subcontracts.length ?? 0,
		drivingSlipsWithMaterials.flatMap(ds => ds.materials).length,
		caseUsage?.caseUsage.trash.length ?? 0,
	];

	const materials: MaterialAndDrivingSlip[] = React.useMemo(() => {

		const materialAndDrivingSlips: MaterialAndDrivingSlip[] = [];

		drivingSlipsWithMaterials.forEach(ds => {
			const materials = ds.materials;

			materials.forEach(m => {
				materialAndDrivingSlips.push({
					drivingSlip: ds,
					material: m,
				});
			});
		});

		return materialAndDrivingSlips;

	}, [drivingSlipsWithMaterials]);
	const activeData: CaseUsageFragment[] = React.useMemo(() => {
		switch (activeTab) {
			case 'common.subcontracts':
				return caseUsage?.caseUsage.subcontracts ?? [];
			case 'common.materiale':
				return caseUsage?.caseUsage.items ?? [];
			case 'common.machines':
				return caseUsage?.caseUsage.machines ?? [];
			case 'common.movables':
				return caseUsage?.caseUsage.movables ?? [];
			case 'case.usage.resources':
				return caseUsage?.caseUsage.persons ?? [];
			case 'common.trash':
				return caseUsage?.caseUsage.trash ?? [];
			default:
				return [];
		}
	}, [activeTab, caseUsage?.caseUsage.subcontracts, caseUsage?.caseUsage.items, caseUsage?.caseUsage.machines, caseUsage?.caseUsage.movables, caseUsage?.caseUsage.persons, caseUsage?.caseUsage.trash]);

	const [summedLineCost, summedQuantity, summedTotalCost]: string[] = React.useMemo(() => {
		return [
			addThousandSeperator(
				activeData
					.slice()
					.map(d => d.lineCost)
					.reduce((partailSum, a) => partailSum + a, 0),
			),
			addThousandSeperator(
				activeData
					.slice()
					.map(d => d.quantity)
					.reduce((partailSum, a) => partailSum + a, 0),
			),
			addThousandSeperator(
				activeData
					.slice()
					.map(d => d.totalCost)
					.reduce((partailSum, a) => partailSum + a, 0),
			),
		];
	}, [activeData]);

	return (
		<Box title="case.usage.overviewTitle" className="text-blue mb-8" full icon={caseViewExternal ? undefined : faFileExcel} onClick={() => fetchData()} loading={loading}>
			<Tabs
				titles={[
					...tabs,
					...(newPlannerVehiclesFlag ? ['planner.materials'] : []),
					...(registerTrashWithUnits ? ['common.trash'] : []),
				]}
				active={activeTab}
				onClick={setActiveTab}
				count={count}
			>
				{(activeTab !== 'planner.materials' && activeTab !== 'common.trash') &&
					<>
						<Table
							data={activeData}
							keySelector={d => d.erpId}
							noDataFoundText={`${t('common.none')} ${t(activeTab).toLowerCase()} ${t('case.usage.lines')} `}
							loading={loadingCaseUsage}
							columns={[
								{
									label: 'common.date',
									selectFn: d => d.originalPostingDate === '0001-01-01' ? d.postingDate : d.originalPostingDate,
									sortFn: (a, b) => a.originalPostingDate === '0001-01-01' ? a.postingDate.localeCompare(b.postingDate) : a.originalPostingDate.localeCompare(b.originalPostingDate),
								},
								{
									label: 'common.name',
									selectFn: d => d.ressourceName,
									sortFn: (a, b) => a.ressourceName.localeCompare(b.ressourceName),
								},
								{
									label: 'common.description',
									selectFn: d =>
										activeTab === 'common.subcontracts' ? (
											<a href={d.attachments[0]} className="cursor-pointer underline">
												{d?.title ?? 'common.noTitel'}
											</a>
										) : (
											d.title
										),
									sortFn: (a, b) => a.title.localeCompare(b.title),
								},
								{
									label: 'common.quantity',
									selectFn: d => (
										<p>
											{d.quantity} {d.unit}
										</p>
									),
									numeric: true,
									sortFn: (a, b) => a.quantity - b.quantity,
								},
								{
									label: 'case.economy.costPrice',
									selectFn: d => addThousandSeperator(d.totalCost),
									numeric: true,
									align: 'RIGHT',
									sortFn: (a, b) => a.totalCost - b.totalCost,
								},
								{
									label: 'common.salePrice',
									selectFn: d => addThousandSeperator(d.lineCost),
									numeric: true,
									align: 'RIGHT',
									sortFn: (a, b) => a.lineCost - b.lineCost,
								},
								{
									label: 'common.attachments',
									selectFn: d => (
										<div>
											{d.attachments.map(a => (
												<p>{a}</p>
											))}
										</div>
									),
									hideColumn: activeTab !== 'common.subcontractor',
								},
							]}
						/>
						<div className="border-t-1 bg-blue -mb-2 mt-auto flex w-full flex-row items-center justify-end py-1 pr-10 text-white">
							<p>
								{t('case.economy.sale')} <span className="mr-2 font-medium">{summedLineCost}</span> {t('common.quantity')} <span className="mr-2 font-medium">{summedQuantity}</span>{' '}
								{t('case.economy.cost')} <span className="font-medium">{summedTotalCost}</span>
							</p>
						</div>
					</>
				}
				{activeTab === 'planner.materials' &&
					<Table
						data={materials}
						keySelector={d => d.drivingSlip.id + d.material.vehicleNumber}
						noDataFoundText={`${t('common.none')} ${t(activeTab).toLowerCase()} ${t('case.usage.lines')} `}
						loading={loadingCaseUsage}
						columns={[
							{
								label: 'drivingSlips.',
								selectFn: d => d.material.vehicleNumber,
								sortFn: (a, b) => a.material.vehicleNumber.localeCompare(b.material.vehicleNumber),
							},
							{
								label: 'common.date',
								selectFn: d => <p>{d.drivingSlip.start}-{d.drivingSlip.end}</p>,
								sortFn: (a, b) => a.drivingSlip.start.localeCompare(b.drivingSlip.start),
							},
						]}
					/>
				}
				{activeTab === 'common.trash' &&
					<Table
						data={activeData}
						keySelector={t => t.erpId}
						noDataFoundText={`${t('common.none')} ${t(activeTab).toLowerCase()} ${t('case.usage.lines')} `}
						loading={loadingCaseUsage}
						columns={[
							{
								label: 'common.date',
								selectFn: t => t.postingDate,
								sortFn: (a, b) => ''.localeCompare(''),
							},
							{
								label: 'Ressource',
								selectFn: t => t.ressourceName,
								sortFn: (a, b) => ''.localeCompare(''),
							},
							{
								label: 'Affaldstype',
								selectFn: t => t.title,
								sortFn: (a, b) => ''.localeCompare(''),
							},
							{
								label: 'Enhed',
								selectFn: t => t.unit,
								sortFn: (a, b) => ''.localeCompare(''),
							},
							{
								label: 'Mængde',
								selectFn: t => t.quantity,
								sortFn: (a, b) => ''.localeCompare(''),
							},
						]}
					/>
				}
			</Tabs>
		</Box>
	);
};

export default CaseUsageBox;
